import React from 'react';
import { Stack, Flex, TextV1, Box, TextV2 } from '@withjoy/joykit';
import {
  StyledColumnsContribution,
  StyledHeadText,
  StyledNoteBox,
  StyledBoldText,
  StyledSuccessGuestButton,
  StyledSuccessGuestLink,
  StyledContributeStack,
  StyledCompletePurchaseImage,
  StyledCancelLink,
  StyledLinkBox
} from '../Purchase.styles';
import { DonationFundPaymentMethodEnum, RegistryItemFragment } from '@graphql/generated';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { useTranslation } from '@shared/core';
import { useContribute } from './useContribute';
import { Address } from '../../../components/Address';
import { ShippingAddressFragment } from '@graphql/aliases';
import { LaunchEmailSupportLink } from '@apps/registry/guest/components';
import { GiveGiftDialog } from '../../GuestRegistry/components/GiveGiftDialog';

interface ContributeProps
  extends Readonly<{
    handleClickProduct: () => void;
    handleUndoClick: () => void;
    quantity: number;
    isMobile: boolean;
    item?: RegistryItemFragment;
    productImage?: string;
    shippingAddress: ShippingAddressFragment | null;
    handleConfirmationClick: () => void;
    isVenmo?: boolean;
    isInModal: boolean;
    orderDonationPlatform?: DonationFundPaymentMethodEnum;
    orderId?: string;
  }> {}

export const Contribute: React.FC<ContributeProps> = ({
  handleClickProduct,
  handleUndoClick,
  item,
  quantity,
  productImage,
  isMobile,
  shippingAddress,
  handleConfirmationClick,
  isVenmo,
  isInModal,
  orderId,
  orderDonationPlatform
}) => {
  const {
    cookedProduct,
    priceString,
    platformUsername,
    shouldContributeOnWebsite,
    contributeSuggestedNoteDisplay,
    platformDisplayName,
    renderButtonText,
    copyText,
    onCopyClick,
    isCashOrCheck,
    giveGiftDialogProps
  } = useContribute({
    item,
    quantity,
    isMobile,
    orderId,
    orderDonationPlatform
  });

  const { getContributeToFundTranslations } = useRegistryGuestTranslations();
  const { contributeTitle, contributeSuggestedNoteLabel, reservedCashOrCheckDonationInstructions } = getContributeToFundTranslations();
  const { t } = useTranslation('guestRegistry');
  const { contributeDetails, contributeDetailsPayment, needHelp, contributeCancelText } = t('contributeToFund');
  const { /*changeGiftingMethod,*/ itsReserved } = t('confirmationSection');

  const onActionButtonClick = isCashOrCheck ? handleConfirmationClick : handleClickProduct;

  const renderPaymentDetailsText = () => {
    if (isCashOrCheck) {
      return (
        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" marginTop={5}>
          <TextV1 as="p" textAlign="center" marginBottom={7} width="90%">
            {reservedCashOrCheckDonationInstructions}
          </TextV1>
          {shippingAddress && (
            <Box width="400px">
              <Address address={shippingAddress} showWarning={false} />
            </Box>
          )}
        </Box>
      );
    }
    if (!shouldContributeOnWebsite && !isCashOrCheck) {
      return (
        <StyledBoldText component="p" textAlign="center" marginRight={3} color={'typeTertiaryDark'}>
          {contributeDetailsPayment({ handle: `@${platformUsername}`, platform: platformDisplayName })}
        </StyledBoldText>
      );
    }
    return null;
  };

  return (
    <>
      <StyledColumnsContribution isMobile={isMobile} isInModal={isInModal}>
        <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={isInModal ? undefined : 'mono1'} marginBottom={isInModal ? '24px' : undefined}>
          <Box width={isInModal ? ['120px'] : ['100%', null, '100%']} height={isInModal ? ['120px'] : ['254px', null, '100%']}>
            <StyledCompletePurchaseImage backgroundSize="cover" productImage={productImage} />
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={isInModal ? undefined : 'mono1'} padding={['24px', null, '0px']}>
          <Box width={['100%', null, '375px']}>
            <StyledContributeStack spacing={7}>
              <Stack spacing={5}>
                <StyledHeadText textAlign="center">{itsReserved()}</StyledHeadText>
                <Box>
                  <StyledBoldText component="p" textAlign="center" marginRight={3}>
                    {contributeDetails({ amount: priceString, fundTitle: item?.donationFund?.title })}
                  </StyledBoldText>
                  {renderPaymentDetailsText()}
                </Box>
                <StyledHeadText textAlign="center">{!isCashOrCheck && contributeTitle}</StyledHeadText>
              </Stack>
              {!isCashOrCheck && (
                <StyledNoteBox backgroundColor={'white'}>
                  <Flex alignItems="center" justifyContent="space-between" marginBottom={4}>
                    <TextV2 typographyVariant="hed1">{contributeSuggestedNoteLabel}</TextV2>
                    <CopyToClipboard text={contributeSuggestedNoteDisplay || ''}>
                      <StyledSuccessGuestLink typographyVariant="body1" onClick={onCopyClick}>
                        {copyText}
                      </StyledSuccessGuestLink>
                    </CopyToClipboard>
                  </Flex>
                  <TextV1>{contributeSuggestedNoteDisplay}</TextV1>
                </StyledNoteBox>
              )}

              <Box paddingTop={3}>
                <StyledSuccessGuestButton intent="primary" fill={true} onClick={onActionButtonClick}>
                  {renderButtonText()}
                </StyledSuccessGuestButton>
              </Box>
              <Box paddingTop={3}>
                <Stack spacing={6}>
                  {/*
                    // hiding change gifting method button for now, will be fixed in future (is supposed to be shown only once multiple payment methods are actually available)
                  <StyledCancelLink component="p" onClick={giveGiftDialogProps.handleOnOpen}>
                    {changeGiftingMethod()}
                  </StyledCancelLink>*/}
                  <StyledCancelLink component="p" onClick={handleUndoClick}>
                    {contributeCancelText()}
                  </StyledCancelLink>
                  <StyledLinkBox>
                    <LaunchEmailSupportLink>{needHelp()}</LaunchEmailSupportLink>
                  </StyledLinkBox>
                </Stack>
              </Box>
            </StyledContributeStack>
          </Box>
        </Flex>
      </StyledColumnsContribution>
      <GiveGiftDialog {...giveGiftDialogProps} product={cookedProduct} existingOrderId={orderId} mySessionEmail={null} shippingAddress={shippingAddress} />
    </>
  );
};
