import { styled, Box } from '@withjoy/joykit';

export const StyledAddressSectionWrapper = styled(Box)``;
const addressSectionWrapperStyles = {
  paddingTop: 7,
  paddingX: 6
};

export const styles = {
  addressSectionWrapperStyles
};
