import React from 'react';
import { noop } from 'lodash-es';

import { Box, Divider, Flex, IconV2, SpacingStack, TextV2, TooltipV2 } from '@withjoy/joykit';
import { Help } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { Checkbox } from '@shared/components';
import { ShippingAddressFragment } from '@graphql/aliases';

import { AddressAtomCheckbox, AddressAtomWrapper, styles, TooltipIcon } from './AddressAtoms.style';
import { AddressMetadata } from '../MetadataAtoms';

type AddressAtomBaseProps = {
  isSelected: boolean;
  title: string;
  subtitle?: string;
  tooltip?: string;
  onCheckboxChange: () => void;
  address?: ShippingAddressFragment;
};

export const AddressAtomBase = ({ isSelected, title, subtitle, tooltip, onCheckboxChange, address }: AddressAtomBaseProps) => {
  return (
    <AddressAtomWrapper isSelected={isSelected} onClick={onCheckboxChange}>
      <Flex alignItems="center">
        <AddressAtomCheckbox __css={styles.addressAtomCheckboxStyles}>
          <Checkbox checked={isSelected} onChange={noop} id="test" />
        </AddressAtomCheckbox>
        <SpacingStack spacing={1}>
          <Flex alignItems="center">
            <TextV2 typographyVariant="button1">{title}</TextV2>
            {tooltip && (
              <TooltipV2 content={tooltip} placement="topEnd">
                <TooltipIcon>
                  <IconV2 size="sm" color="mono8" marginLeft={3}>
                    <Help />
                  </IconV2>
                </TooltipIcon>
              </TooltipV2>
            )}
          </Flex>
          {subtitle && (
            <TextV2 typographyVariant="label2" color="mono10" textAlign="left">
              {subtitle}
            </TextV2>
          )}
        </SpacingStack>
      </Flex>
      {address && (
        <>
          <Divider marginY={5} />
          <Box marginLeft={pxToRem(40)}>
            <AddressMetadata address={address} />
          </Box>
        </>
      )}
    </AddressAtomWrapper>
  );
};
