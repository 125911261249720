import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { AddressFormFields } from '../types';

export const useAddressFormFields = (initialValues: AddressFormFields, handleSubmit: (address: AddressFormFields) => void) => {
  const { t: translateForm } = useTranslation('generalFormValidation');

  const formik = useFormik<AddressFormFields>({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: Yup.object<AddressFormFields>({
      country: Yup.string(),
      validated: Yup.boolean(),
      name: Yup.string().required(translateForm('required')).min(1),
      address1: Yup.string().required(translateForm('required')),
      address2: Yup.string(),
      city: Yup.string().required(translateForm('required')),
      state: Yup.string().required(translateForm('required')),
      postalCode: Yup.string().required(translateForm('required'))
    })
  });

  const getFormFieldError = useCallback(
    (fieldName: keyof AddressFormFields) => {
      const { errors, touched } = formik;
      return touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined;
    },
    [formik]
  );

  return {
    getFormFieldError,
    saveAddress: formik.submitForm,
    formik
  };
};
