import { useTranslation } from '@shared/core';
import globalWindow from '@shared/core/globals';
import React from 'react';

import { StyledLaunchEmailSupportLink } from './LaunchEmailSupportLink.styles';

export const LaunchEmailSupportLink: React.FC<{}> = ({ children }) => {
  const { t2 } = useTranslation('sharedRegistry');
  const { contactSupportMessage } = t2('contact');
  return (
    <StyledLaunchEmailSupportLink target="__blank" href={`mailto:support@withjoy.com?body=${globalWindow?.location?.href} ${contactSupportMessage}`}>
      {children}
    </StyledLaunchEmailSupportLink>
  );
};
