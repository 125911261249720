import React from 'react';
import { TextV2, ButtonV2, SpacingStack, Flex, TooltipV2 } from '@withjoy/joykit';
import { useSpring, animated } from 'react-spring';
import { usePurchaseDialogTranslations } from '../../../../hooks';
import { StyledWarningBox, StyledWhiteBox, styles } from './MissingAddress.styles';
import { RedTriangle, CheckedGreenProductive, Help } from '@assets/index';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';

interface MissingAddressProps {
  isIn: boolean;
  handleOnMissingAddress: () => void;
}

export const MissingAddress = ({ handleOnMissingAddress, isIn }: MissingAddressProps) => {
  const {
    title,
    warningTitle,
    warningBody,
    shipTo,
    anotherAddressTitle,
    anotherAddressTooltip,
    anotherAddressBody,
    button
  } = usePurchaseDialogTranslations().missingCoupleAddressTranslations;

  const styleProps = useSpring({
    opacity: isIn ? 1 : 0,
    display: isIn ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    config: { duration: isIn ? 350 : 250, easing: cubicBezierEasingFn }
    // fadeIn: 350 milliseconds | fadeOut: 250 milliseconds
  });

  return (
    <animated.div style={styleProps}>
      <TextV2 typographyVariant="hed5" marginBottom={6}>
        {title}
      </TextV2>
      <TextV2 typographyVariant="label3" marginBottom={3}>
        {shipTo}
      </TextV2>
      <StyledWarningBox __css={styles.warningBox} marginBottom={5}>
        <Flex columnGap={5}>
          <RedTriangle />
          <SpacingStack spacing={1}>
            <TextV2 typographyVariant="hed1">{warningTitle}</TextV2>
            <TextV2 typographyVariant="label1">{warningBody}</TextV2>
          </SpacingStack>
        </Flex>
      </StyledWarningBox>
      <StyledWhiteBox __css={styles.whiteBox} marginBottom={7}>
        <Flex columnGap={5} alignItems="center">
          <CheckedGreenProductive />
          <SpacingStack spacing={1}>
            <Flex>
              <TextV2 typographyVariant="hed1" marginRight={1}>
                {anotherAddressTitle}
              </TextV2>
              <TooltipV2 content={<TextV2 typographyVariant="label2">{anotherAddressTooltip}</TextV2>} placement="topStart">
                <Flex alignItems="center">
                  <Help margin="auto" />
                </Flex>
              </TooltipV2>
            </Flex>
            <TextV2 typographyVariant="label1">{anotherAddressBody}</TextV2>
          </SpacingStack>
        </Flex>
      </StyledWhiteBox>
      <ButtonV2 intent="productive" onClick={handleOnMissingAddress}>
        {button}
      </ButtonV2>
    </animated.div>
  );
};
