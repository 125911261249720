import { TextV2 } from '@withjoy/joykit';
import React, { useMemo } from 'react';

export interface GiftNoteDisplayProps {
  /*
   * This can be a simple string or JSON object saved in string with a root level message attribute.
   */
  note: string;
}

/**
 * getGiftNoteDisplayText figures out if note is a simple string or JSON object saved in string
 * with a message attribute containing the note
 * @param {string} note this can be a simple string or JSON saved in string.
 * @example
 * // returns 'Congratulations!  We hope this gift brings you joy!'
 * getGiftNoteDisplayText('{ "message": "Congratulations! \\n\\n We hope this gift brings you joy!" }'});
 * @example
 * // returns 'We hope you enjoy this gift!'
 * getGiftNoteDisplayText('We hope you enjoy this gift!');
 * @example
 * // returns ''
 * getGiftNoteDisplayText(null);
 * @returns string
 */
export const getGiftNoteDisplayText = (note: string): string => {
  let parsedNoteText = typeof note === 'string' ? note : '';
  if (note) {
    try {
      const noteObject = JSON.parse(note);
      if (noteObject?.message) {
        parsedNoteText = noteObject.message;
      }
    } catch (e) {
      // Do nothing, note was not a JSON object and the basic text will be returned
    }
  }
  return parsedNoteText;
};

/**
 * GiftNoteDisplay determines if note is a simple string or JSON saved in string and displays the note as a string
 *
 * @component
 * @example
 * const note = 'Congratulations!  We hope this gift brings you joy!'
 * return (
 *   <GiftNoteDisplay note={note} />
 * )
 */
export const GiftNoteDisplay: React.FC<GiftNoteDisplayProps> = ({ note }) => {
  const displayNote = useMemo(() => getGiftNoteDisplayText(note), [note]);
  return (
    <TextV2 typographyVariant="body1" padding={6} whiteSpace="pre-line">
      {displayNote}
    </TextV2>
  );
};

export default GiftNoteDisplay;
