import { useTranslation } from '@shared/core';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';

export const useCancelGiftConfirmationController = () => {
  const { toNext } = useCheckoutFunnel(({ toNext }) => [toNext]);
  const { t2 } = useTranslation('guestRegistry');
  const translations = t2('checkoutDialog', 'screens', 'cancelGiftConfirmation');

  return { toNext, translations };
};
