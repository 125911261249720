import React from 'react';
import { StyledButton } from './DashboardButton.styles';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { useGuestRegistryTelemetry } from '../../GuestRegistry.telemetry';
import { ReactComponent as GiftIcon } from '@assets/icons/icon-gift.svg';
import { Box } from '@withjoy/joykit';
import { useEventTypeConfig } from '@shared/utils/eventType';
interface Props {
  website: string;
}

export const DashboardButton: React.FC<Props> = ({ website }) => {
  const { registryDashboardButton } = useGuestRegistryTelemetry();

  const { getCoupleHeaderTranslations } = useRegistryGuestTranslations();
  const { dashboardBtn } = getCoupleHeaderTranslations();
  const { config } = useEventTypeConfig();
  return (
    <StyledButton
      data-testid="dashboard-button"
      width={['100%', '200px']}
      onClick={() => {
        registryDashboardButton(website);
        const url = window.location.origin + `/${website}${config?.registryAdminManageLink}`;
        window.open(url, '_blank');
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box marginRight={3} display="flex" justifyContent="center" alignItems="center">
          <GiftIcon />
        </Box>
        {dashboardBtn}
      </Box>
    </StyledButton>
  );
};
