import React from 'react';
import { ShippingAddressFragment } from '@graphql/generated';

import { AddressAtomBase } from './AddressAtomBase';

type ShippingAddressAtomProps = { label: string; note: string; isSelected: boolean; onSelect: () => void; tooltip?: string; address?: ShippingAddressFragment };

export const ShippingAddressAtom = ({ label, note, isSelected, tooltip, onSelect, address }: ShippingAddressAtomProps) => {
  return <AddressAtomBase isSelected={isSelected} title={label} subtitle={note} tooltip={tooltip} onCheckboxChange={onSelect} address={address} />;
};
