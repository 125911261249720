import { animationTransition } from '@shared/utils/animationTransition';
import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const PaymentMethodSelectorRoot = styled(Box)``;
export const PaymentMethodSelectorInputContainer = styled(Flex)``;
export const PaymentMethodSelectorCheck = styled(Box)``;
export const PaymentMethodSelectorInput = styled(Box)``;
export const PaymentMethodSelectorLabel = styled(Box)``;

const paymentMethodSelectorInputContainerStyles: StyleSystemProps = {
  padding: pxToRem(16),
  backgroundColor: 'white',
  border: `${pxToRem(1)} solid`,
  borderColor: 'mono5',
  borderRadius: 2,
  alignItems: 'center',
  transition: animationTransition('background-color', 'border-color'),
  _checked: {
    padding: pxToRem(15),
    border: `${pxToRem(2)} solid`,
    borderColor: 'mono14',
    _hover: {
      borderColor: 'mono14'
    }
  },
  _notLast: {
    marginBottom: 5
  },
  _hover: {
    borderColor: 'mono14',
    cursor: 'pointer'
  },
  _disabled: {
    color: 'mono5',
    pointerEvents: 'none',
    borderColor: 'mono5'
  },
  _active: {
    backgroundColor: 'gray5'
  }
};

const paymentMethodSelectorCheckStyles: StyleSystemProps = {
  opacity: 0,
  position: 'absolute',
  transition: animationTransition('opacity', 'color'),
  _groupChecked: {
    color: 'mono14',
    opacity: 1
  },
  _groupActive: {
    color: 'buttonProductiveActive'
  },
  _groupDisabled: {
    color: 'mono5'
  }
};

const paymentMethodSelectorInputStyles: StyleSystemProps = {
  appearance: 'none',
  width: 6,
  height: 6,
  borderRadius: 5,
  border: `${pxToRem(1)} solid`,
  borderColor: 'mono8',
  marginRight: 5,
  _groupHover: {
    borderColor: 'mono14',
    cursor: 'pointer',
    _groupChecked: {
      borderColor: 'mono14'
    }
  },
  _disabled: {
    borderColor: 'mono5'
  }
};

const paymentMethodSelectorLabelStyles: StyleSystemProps = {
  typographyVariant: 'body2',
  _groupHover: { cursor: 'pointer' }
};

export const styles = {
  paymentMethodSelectorInputContainer: paymentMethodSelectorInputContainerStyles,
  paymentMethodSelectorCheck: paymentMethodSelectorCheckStyles,
  paymentMethodSelectorInput: paymentMethodSelectorInputStyles,
  paymentMethodSelectorLabel: paymentMethodSelectorLabelStyles
};
