import { styled, TextV2 } from '@withjoy/joykit';

export const StyledTitleText = styled(TextV2)``;

export const StyledSubText = styled(TextV2)`
  text-align: center;
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const formControlOverrides = {
  Label: {
    props: {
      visibility: 'hidden'
    }
  }
};
