import { keyframes } from '@withjoy/joykit';

export const trembleAnimation = keyframes`
0% {
  transform: rotate(-0.6deg);
}
3% {
  transform: rotate(0deg);
}
6% {
  transform: rotate(-0.6deg);
}
9% {
  transform: rotate(-1.2deg);
}
12% {
  transform: rotate(0deg);
}
15% {
  transform: rotate(-1.2deg);
}
18% {
  transform: rotate(0deg);
}
21% {
  transform: rotate(-0.6deg);
}
24% {
  transform: rotate(0deg);
}
27% {
  transform: rotate(-0.6deg);
}
30% {
  transform: rotate(0deg);
}
`;

export const wiggleAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
10% {
  transform: rotate(-1deg);
}

15% {
  transform: rotate(0.5deg);
}
20% {
  transform: rotate(0deg);
}
22% {
  transform: rotate(-0.2deg);
}
23% {
  transform: rotate(0deg);
}
30% {
  transform: rotate(-0.1deg);
}
35% {
  transform: rotate(0.5deg);
}
40% {
  transform: rotate(0deg);
}
42% {
  transform: rotate(-0.2deg);
}
43% {
  transform: rotate(0deg);
}
44% {
  transform: rotate(0.1deg);
}
45% {
  transform: rotate(0deg);
}
`;
