import { useCheckoutFunnel, useCheckoutFunnelContext } from '../../hooks/useCheckoutFunnel';
import { useTranslation } from '@shared/core';
import { CustomItemDataType, DonationFundPaymentMethodEnum } from '@graphql/generated';
import { useCallback, useMemo, useState } from 'react';
import { useCheckoutInitDataContext } from '@apps/registry/guest/components/CheckoutDialog/hooks/useCheckoutInitData';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { addAction } from '@shared/utils/logger';
import { getDonationPaymentMethodFromPlatformType } from '../../machines/externalCheckout/utils';

export const usePaymentMethodController = () => {
  const {
    formValues,
    selectPaymentMethod,
    context: { isGroupGifting },
    route
  } = useCheckoutFunnel(({ formValues, selectPaymentMethod, context }) => [formValues, selectPaymentMethod, context.isGroupGifting]);
  const { registryItem } = useCheckoutInitDataContext('paymentMethod');
  const [paymentMethod, setPaymentMethod] = useState<Maybe<DonationFundPaymentMethodEnum>>(formValues.paymentMethod);
  const { eventInfo } = useEventInfo();
  const { selectPayment } = useGuestRegistryTelemetry();
  const { creditCardError } = useCheckoutFunnelContext();

  const onUpdatePaymentMethod = useCallback((paymentMethodNew: DonationFundPaymentMethodEnum) => setPaymentMethod(paymentMethodNew), [setPaymentMethod]);

  const { t } = useTranslation('guestRegistry');

  const handleSubmit = useCallback(() => {
    const selectPaymentData = {
      paymentMethod,
      gifterEmail: formValues.email,
      gifterName: formValues.name,
      gifterNote: formValues.note,
      giftAmount: formValues.amount,
      productTitle: registryItem.productData.title || eventInfo?.eventDisplayName || 'cash fund',
      eventId: eventInfo?.eventFirebaseId,
      registryItemId: registryItem.id,
      donationFundId: registryItem.donationFund?.id,
      externalUrl: registryItem.productData.externalUrl,
      isGroupGiftingEnabled: isGroupGifting
    };
    selectPayment(selectPaymentData);
    addAction('selectPayment', selectPaymentData);
    selectPaymentMethod({ paymentMethod: paymentMethod as Exclude<DonationFundPaymentMethodEnum, DonationFundPaymentMethodEnum.other> }), [paymentMethod, selectPaymentMethod];
  }, [
    paymentMethod,
    formValues.email,
    formValues.name,
    formValues.note,
    formValues.amount,
    registryItem.productData.title,
    registryItem.productData.externalUrl,
    registryItem.id,
    registryItem.donationFund?.id,
    eventInfo?.eventDisplayName,
    eventInfo?.eventFirebaseId,
    isGroupGifting,
    selectPayment,
    selectPaymentMethod
  ]);
  const { productData, donationFund } = registryItem || {};
  const { enableCashOrCheck, platform, fundType, enableAppPlatform, enableJoyCredit } = donationFund || {};

  const isCashFundType = fundType === CustomItemDataType.cash;

  const options = useMemo(() => {
    const { cashCheck, creditCard } = t('checkoutDialog', 'screens', 'paymentMethod');
    const CASH_CHECK = {
      label: cashCheck(),
      value: DonationFundPaymentMethodEnum.cashOrCheck
    };
    const CREDIT_CARD = {
      label: creditCard(),
      value: DonationFundPaymentMethodEnum.joyCredit
    };
    const list = [];
    if (enableCashOrCheck) {
      list.push(CASH_CHECK);
    }
    if (enableAppPlatform && platform) {
      list.push({
        label: platform.name,
        value: getDonationPaymentMethodFromPlatformType(platform.type)!
      });
    }
    if (enableJoyCredit) {
      list.push(CREDIT_CARD);
    }

    return list;
  }, [t, enableCashOrCheck, platform, enableAppPlatform, enableJoyCredit]);
  const translations = t('checkoutDialog', 'screens', 'paymentMethod');

  return {
    handleSubmit,
    options,
    currentPaymentMethod: paymentMethod,
    onUpdatePaymentMethod,
    isCashFundType,
    productTitle: productData,
    translations,
    isLoading: route === 'creditCardCheckoutIdle',
    eventDisplayName: eventInfo?.eventDisplayName,
    creditCardError
  };
};
