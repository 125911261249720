import { useDeliverRequestForPasswordMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useRegistryGuestTranslations } from '../../../GuestRegistry.i18n';

export interface ContactHostFields extends Readonly<{ name: string; email: string }> {}

export const useContactHostController = (eventId: string = '', eventHandle: string, setShowContactHost: (val: boolean) => void) => {
  const [contactSuccess, setContactSuccess] = useState(false);
  const { getContactTheHostTranslations } = useRegistryGuestTranslations();
  const { title, subtitle, namePlaceholder, emailPlaceholder, actionButton, emailInputError } = getContactTheHostTranslations();
  const { t: translateForm } = useTranslation('generalFormValidation');

  const [sendRequest, { loading }] = useDeliverRequestForPasswordMutation({
    onCompleted: data => {
      setContactSuccess(true);
    }
  });

  const contactHostFormik = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validationSchema: Yup.object<ContactHostFields>({
      name: Yup.string().required(translateForm('required')),
      email: Yup.string().email().required(translateForm('required'))
    }),
    validateOnBlur: true,
    onSubmit: values => {}
  });

  const contactHost = () => {
    sendRequest({
      variables: {
        payload: { eventId, guest: { firstName: contactHostFormik.values.name, email: contactHostFormik.values.email } }
      }
    });
  };

  const goBack = () => {
    setShowContactHost(false);
  };

  return {
    contactHost,
    contactSuccess,
    contactHostFormik,
    loading,
    goBack,
    title,
    subtitle,
    namePlaceholder,
    emailPlaceholder,
    actionButton,
    emailInputError,
    emailError: contactHostFormik.errors.email && contactHostFormik.touched.email
  };
};
