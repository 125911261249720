import React from 'react';
import { PublicBoxProps } from '@withjoy/joykit';
import { IntegratedTopNav } from '../TopNav';
import { styles, StyledShellRoot, StyledHeaderRoot, StyledBodyRoot, StyledMediaPanelRoot, StyledContentPanelRoot, StyledSingleColumnPanelRoot } from './LayoutShell.styles';

type LayoutShellType = React.FC<PublicBoxProps> & {
  Header: typeof LayoutShellHeader;

  /**
   * The `Body` does several things:
   *  - Arranges its children different depending on viewport
   *  - Applies column/row gap depending on viewport
   *  - Applies web padding
   */
  Body: typeof LayoutShellBody;
  MediaPanel: typeof LayoutShellMediaPanel;

  /**
   * The `ContentPanel` applies mobile padding.
   */
  ContentPanel: typeof LayoutShellContentPanel;

  /**
   * The `SingleColumnPanel` applies for views that only have a single column with centered content.
   */
  SingleColumnPanel: typeof LayoutShellSingleColumnPanel;
};

/**
 * This Layout component exposes 5 components to help you structure the checkout dialog.
 *  - `LayoutShell`: A simple wrapper.
 *  - `LayoutShell.Header`: This will contain the dialog's connected `TopNav`.
 *  - `LayoutShell.Body`: A wrapper that will house the dialog's main content.
 *  - `LayoutShell.MediaPanel`: A container that houses media content -- should precede the `ContentPanel`.
 *  - `LayoutShell.ContentPanel`: The container that houses the dialog's content.
 *
 * @example
 * // Full Dialog
 *
 * <LayoutShell>
 *  <LayoutShell.Header />
 *  <LayoutShell.Body>
 *    <LayoutShell.MediaPanel>
 *       <GiftCard />
 *    </LayoutShell.MediaPanel>
 *
 *    <LayoutShell.ContentPanel>
 *      <ButtonV2>Give Now</ButtonV2>
 *    </LayoutShell.ContentPanel>
 *  </LayoutShell.Body>
 * </LayoutShell>
 *
 * @example
 * // Partial Dialog -- only uses some of the shell.
 *
 * <LayoutShell>
 *  <LayoutShell.Body>
 *    <LayoutShell.ContentPanel>
 *        Thank you!
 *    </LayoutShell.ContentPanel>
 *  </LayoutShell.Body>
 * </LayoutShell>
 */
const LayoutShell: LayoutShellType = props => {
  return <StyledShellRoot __css={styles.shellRoot} {...props} />;
};

interface LayoutShellHeaderProps extends Omit<PublicBoxProps, 'children'>, Readonly<{}> {
  /**
   * On web, the header is always placed at the top of the dialog and it never overlaps the dialog content.
   *
   * On mobile, there are some screens where the header is either statically placed before content **OR**
   * it is stacked on top of the content.
   *
   * @default 'static'
   */
  mobilePlacement?: 'static' | 'float';
}

const LayoutShellHeader = ({ mobilePlacement = 'static', ...restProps }: LayoutShellHeaderProps) => {
  return (
    <StyledHeaderRoot __css={styles.headerRoot(mobilePlacement)} {...restProps}>
      <IntegratedTopNav />
    </StyledHeaderRoot>
  );
};

const LayoutShellBody = (props: PublicBoxProps) => {
  return <StyledBodyRoot __css={styles.bodyRoot} {...props} />;
};

const LayoutShellMediaPanel = (props: PublicBoxProps) => {
  return <StyledMediaPanelRoot __css={styles.mediaPanelRoot} {...props} />;
};

const LayoutShellContentPanel = (props: PublicBoxProps) => {
  return <StyledContentPanelRoot __css={styles.contentPanelRoot} {...props} />;
};

const LayoutShellSingleColumnPanel = (props: PublicBoxProps) => {
  return <StyledSingleColumnPanelRoot __css={styles.singleColumnPanelRoot} {...props} />;
};

LayoutShell.Header = LayoutShellHeader;
LayoutShell.Body = LayoutShellBody;
LayoutShell.MediaPanel = LayoutShellMediaPanel;
LayoutShell.ContentPanel = LayoutShellContentPanel;
LayoutShell.SingleColumnPanel = LayoutShellSingleColumnPanel;

export { LayoutShell };
