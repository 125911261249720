// https://en.wikipedia.org/wiki/List_of_state_and_territorial_capitols_in_the_United_States

export const US_STATE_NAME_TO_ABBREVIATION = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  'WEST VIRGINIA': 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY'
};

// Alaska and Hawaii are not supported by the registry service so they need to stay commented out
export const US_STATE_CAPITALS = {
  AL: {
    address1: '600 Dexter Avenue',
    city: 'Montgomery',
    state: 'Alabama',
    postalCode: '36104'
  },
  /*
  AK: {
    address1: '120 4th Street',
    city: 'Juneau',
    state: 'Alaska',
    postalCode: '99801'
  },
  */
  AZ: {
    address1: '1700 West Washington Street',
    city: 'Phoenix',
    state: 'Arizona',
    postalCode: '85007'
  },
  AR: {
    address1: '500 Woodlane Street',
    city: 'Little Rock',
    state: 'Arkansas',
    postalCode: '72201'
  },
  CA: {
    address1: '1010 L Street',
    city: 'Sacramento',
    state: 'California',
    postalCode: '95814'
  },
  CO: {
    address1: '200 East Colfax Avenue',
    city: 'Denver',
    state: 'Colorado',
    postalCode: '80203'
  },
  CT: {
    address1: '210 Capitol Avenue',
    city: 'Hartford',
    state: 'Connecticut',
    postalCode: '06106'
  },
  DE: {
    address1: '410 Legislative Avenue',
    city: 'Dover',
    state: 'Delaware',
    postalCode: '19901'
  },
  FL: {
    address1: '400 South Monroe Street',
    city: 'Tallahassee',
    state: 'Florida',
    postalCode: '32301'
  },
  GA: {
    address1: '206 Washington Street Southwest',
    city: 'Atlanta',
    state: 'Georgia',
    postalCode: '30334'
  },
  /*
  HI: {
    address1: '415 South Beretania Street',
    city: 'Honolulu',
    state: 'Hawaii',
    postalCode: '96813'
  },
  */
  ID: {
    address1: '700 West Jefferson Street',
    city: 'Boise',
    state: 'Idaho',
    postalCode: '83702'
  },
  IL: {
    address1: '401 South 2nd Street',
    city: 'Springfield',
    state: 'Illinois',
    postalCode: '62701'
  },
  IN: {
    address1: '200 West Washington Street',
    city: 'Indianapolis',
    state: 'Indiana',
    postalCode: '46204'
  },
  IA: {
    address1: '1007 E Grand Ave',
    city: 'Des Moines',
    state: 'Iowa',
    postalCode: '50319'
  },
  KS: {
    address1: '300 West 10th Avenue',
    city: 'Topeka',
    state: 'Kansas',
    postalCode: '66612'
  },
  KY: {
    address1: '700 Capitol Avenue',
    city: 'Frankfort',
    state: 'Kentucky',
    postalCode: '40601'
  },
  LA: {
    address1: '900 North Third Street',
    city: 'Baton Rouge',
    state: 'Louisiana',
    postalCode: '70802'
  },
  ME: {
    address1: '210 State Street',
    city: 'Augusta',
    state: 'Maine',
    postalCode: '04330'
  },
  MD: {
    address1: '100 State Circle',
    city: 'Annapolis',
    state: 'Maryland',
    postalCode: '21401'
  },
  MA: {
    address1: '24 Beacon Street',
    city: 'Boston',
    state: 'Massachusetts',
    postalCode: '02133'
  },
  MI: {
    address1: '100 North Capitol Avenue',
    city: 'Lansing',
    state: 'Michigan',
    postalCode: '48933'
  },
  MN: {
    address1: '75 Rev. Martin Luther King Jr Boulevard',
    city: 'St. Paul',
    state: 'Minnesota',
    postalCode: '55155'
  },
  MS: {
    address1: '400 High Street',
    city: 'Jackson',
    state: 'Mississippi',
    postalCode: '39205'
  },
  MO: {
    address1: '201 West Capitol Avenue',
    city: 'Jefferson City',
    state: 'Missouri',
    postalCode: '65101'
  },
  MT: {
    address1: '1301 East 6th Avenue',
    city: 'Helena',
    state: 'Montana',
    postalCode: '59601'
  },
  NE: {
    address1: '1445 K Street',
    city: 'Lincoln',
    state: 'Nebraska',
    postalCode: '68509'
  },
  NV: {
    address1: '101 North Carson Street',
    city: 'Carson City',
    state: 'Nevada',
    postalCode: '89701'
  },
  NH: {
    address1: '107 North Main Street',
    city: 'Concord',
    state: 'New Hampshire',
    postalCode: '03301'
  },
  NJ: {
    address1: '125 West State Street',
    city: 'Trenton',
    state: 'New Jersey',
    postalCode: '08625'
  },
  NM: {
    address1: '490 Old Santa Fe Trail',
    city: 'Santa Fe',
    state: 'New Mexico',
    postalCode: '87501'
  },
  NY: {
    address1: 'State Street and Washington Avenue',
    city: 'Albany',
    state: 'New York',
    postalCode: '12224'
  },
  NC: {
    address1: '1 East Edenton Street',
    city: 'Raleigh',
    state: 'North Carolina',
    postalCode: '27601'
  },
  ND: {
    address1: '600 East Boulevard Avenue',
    city: 'Bismarck',
    state: 'North Dakota',
    postalCode: '58505'
  },
  OH: {
    address1: '1 Capitol Square',
    city: 'Columbus',
    state: 'Ohio',
    postalCode: '43215'
  },
  OK: {
    address1: '2300 North Lincoln Boulevard',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    postalCode: '73105'
  },
  OR: {
    address1: '900 Court Street Northeast',
    city: 'Salem',
    state: 'Oregon',
    postalCode: '97301'
  },
  PA: {
    address1: '501 North 3rd Street',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    postalCode: '17120'
  },
  RI: {
    address1: '82 Smith Street',
    city: 'Providence',
    state: 'Rhode Island',
    postalCode: '02903'
  },
  SC: {
    address1: '1100 Gervais Street',
    city: 'Columbia',
    state: 'South Carolina',
    postalCode: '29201'
  },
  SD: {
    address1: '500 East Capitol Avenue',
    city: 'Pierre',
    state: 'South Dakota',
    postalCode: '57501'
  },
  TN: {
    address1: '600 Dr. M.L.K. Jr. Boulevard',
    city: 'Nashville',
    state: 'Tennessee',
    postalCode: '37243'
  },
  TX: {
    address1: '1100 Congress Avenue',
    city: 'Austin',
    state: 'Texas',
    postalCode: '78701'
  },
  UT: {
    address1: '350 State Street',
    city: 'Salt Lake City',
    state: 'Utah',
    postalCode: '84114'
  },
  VT: {
    address1: '115 State Street',
    city: 'Montpelier',
    state: 'Vermont',
    postalCode: '05633'
  },
  VA: {
    address1: '1000 Bank Street',
    city: 'Richmond',
    state: 'Virginia',
    postalCode: '23219'
  },
  WA: {
    address1: '416 Sid Snyder Avenue Southwest',
    city: 'Olympia',
    state: 'Washington',
    postalCode: '98504'
  },
  WV: {
    address1: '1900 Kanawha Boulevard East',
    city: 'Charleston',
    state: 'West Virginia',
    postalCode: '25305'
  },
  WI: {
    address1: '2 East Main Street',
    city: 'Madison',
    state: 'Wisconsin',
    postalCode: '53703'
  },
  WY: {
    address1: '200 West 24th Street',
    city: 'Cheyenne',
    state: 'Wyoming',
    postalCode: '82002'
  }
};
