import { Box, StyleSystemProps, styled } from '@withjoy/joykit';
import { GL_ASSET_CONTAINER_VERTICAL_MARGIN } from '../../GalleryLightbox';

export const StyledContainer = styled(Box)``;
export const StyledMediaContainer = styled(Box)``;

const containerStyles: StyleSystemProps = {
  display: 'flex !important',
  width: '100%',
  alignContent: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
};

const mediaContainerStyles = (screenHeight: number): StyleSystemProps => {
  return {
    width: { _: '100vw', sm2: 'auto' },
    height: { _: 'auto', sm2: `calc(${screenHeight}px - ${GL_ASSET_CONTAINER_VERTICAL_MARGIN}px)` }, // sticky CTA + thumbnails + padding top + padding bottom + distance between image and thumbnails
    maxHeight: { _: `calc(${screenHeight}px - ${GL_ASSET_CONTAINER_VERTICAL_MARGIN}px)`, sm2: 'calc(100vw - 64px)' }, // padding left + padding right
    paddingX: { _: '0', sm2: '32px' },
    zIndex: 0,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'relative'
  };
};

export const styles = {
  container: containerStyles,
  mediaContainer: mediaContainerStyles
};
