import { styled, Flex, StyleSystemProps } from '@withjoy/joykit';
import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledFlex = styled(Flex)`
  > a {
    padding: 0 1rem;
    color: #767676;
  }
`;

export const ParticleJoyLogoWithPadding = styled(ParticleJoyLogo)`
  padding: ${pxToRem(3)} 3rem 0 3rem;
  & > div {
    & > svg {
      width: ${pxToRem(40)};
    }
  }
`;

export const linkStyles: StyleSystemProps = {
  typographyVariant: 'label3',
  textDecoration: 'none',
  fontSize: 'inherit',
  color: 'mono10',
  letterSpacing: '-0.03em',
  fontWeight: 'normal',
  _visited: {
    color: 'mono10'
  },
  _active: {
    color: 'mono10'
  },
  _hover: {
    color: 'mono14'
  }
};
