import { EventType } from '@graphql/generated';

type NetNewEventTypes = Exclude<EventType, 'general' | 'babyRegistry' | 'wedding'>;
type ExistingConfig<Value> = Record<Exclude<EventType, NetNewEventTypes>, Value>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEventTypeTranslationWithGeneralFallback = <Value extends any>(
  translations: ExistingConfig<Value> & Partial<Record<NetNewEventTypes, Value>>,
  eventType: EventType | undefined,
  fallbackKey: keyof ExistingConfig<Value> = EventType.general
): Value => {
  // The ts-ignore is intentional -- TSC doesn't understand eventType exists in the object despite the check
  // @ts-ignore
  return eventType && eventType in translations ? translations[eventType] : translations[fallbackKey];
};
