import { useGuestRegistryTelemetry, GiftWrapType } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useEventInfo } from '@shared/utils/eventInfo';
import { SpacingStack } from '@withjoy/joykit';
import React, { useEffect, useRef } from 'react';
import { prepareECardPayload, useGiftWrapECardPrice, useSaveCardDraft } from '@shared/components/GiftWrap';
import { CartFooterSubmitButton } from '../../../components';
import GiftWrapOffer from '@apps/registry/guest/components/CheckoutDialog/components/GiftWrapOffer';
import { ECard } from '@graphql/generated';

interface MessageStepProps {
  saveData: () => void;
  disableNextStep?: boolean;
  setMessage: (message: string) => void;
  setECard: (eCard: ECard | undefined) => void;
  onEditMessage?: (isEdited: boolean) => void;
  eventId: string;
  product: {
    title: string;
    store?: string;
    image: string;
    extraProductsCount?: number;
    registryItemType?: string;
  };
  senderName: string;
  onContinue: () => void;
}

const MessageStep: React.FC<MessageStepProps> = ({ saveData, disableNextStep, senderName, setMessage, setECard, product, onContinue, eventId }) => {
  const { eventHandle } = useEventInfo();
  const defaultThemeIDRef = useRef<string>('');
  const themeHistoryRef = useRef<string[]>([]);
  const themeOrderRef = useRef<string[]>([]);
  const {
    giftWrapOfferViewed,
    giftWrapPromptSent,
    giftWrapMessageEdited,
    addCardGiftWrapClicked,
    giftWrapDraftSaveError,
    giftWrapEditMessageClicked,
    viewMoreDesignsClicked,
    uneditedWarningShown
  } = useGuestRegistryTelemetry();

  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();
  const PRICE = `$${registryGiftWrapEcardPrice}`;
  const offerViewedSent = useRef(false);
  const [saveCardDraft, isSaving] = useSaveCardDraft(giftWrapDraftSaveError);

  useEffect(() => {
    if (offerViewedSent.current !== true && defaultThemeIDRef.current && themeOrderRef.current.length > 0) {
      giftWrapOfferViewed({
        eventHandle: eventHandle!,
        type: GiftWrapType.CART,
        defaultDesign: defaultThemeIDRef.current ?? 'none',
        designOrder: themeOrderRef.current.toString()
      });
      offerViewedSent.current = true;
    }
  }, [giftWrapOfferViewed, eventHandle]);

  const handleSaveData = async ({ message, themeId, currentTab }: { message: string; themeId: string; currentTab: string }) => {
    setMessage(message);

    if (currentTab === 'wrap') {
      addCardGiftWrapClicked({
        eventHandle: eventHandle || 'EVENT_HANDLE_UNDEFINED',
        design: themeId ?? 'none',
        giftName: product?.title,
        giftType: product?.registryItemType || 'giftType',
        message,
        price: PRICE,
        defaultDesign: defaultThemeIDRef.current ?? 'none',
        designsPreviewed: themeHistoryRef.current.toString(),
        designOrder: themeOrderRef.current.toString()
      });

      let productLink;
      try {
        const origin = window?.location?.origin ?? 'https://withjoy.com';
        productLink = new URL(`${eventHandle}/edit/registry/track`, origin)?.href;
      } catch (e) {}

      const payload = {
        message,
        themeId,
        image: product?.image,
        title: product?.title,
        giftLink: productLink ?? '' // Or make GiftWrapPayload.giftLink optional.
      };
      const draftId = await saveCardDraft(eventId, payload);

      if (draftId && typeof draftId === 'string') {
        const eCard = prepareECardPayload({
          price: registryGiftWrapEcardPrice,
          draftId,
          theme: themeId ?? 'none',
          imageURL: product?.image,
          offerType: GiftWrapType.CART
        });
        setECard(eCard);
      } else {
        alert('Oops! Something went wrong while creating your eCard. The eCard has been removed from your order and you will continue to checkout.');
        throw new Error(`Returned Email Draft ID is not a string: ${draftId}`);
      }
    }
    return saveData();
  };

  const handleSetThemeId = (themeId: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => {
    defaultThemeIDRef.current = defaultThemeID;
    themeHistoryRef.current = themeHistory;
    themeOrderRef.current = themeOrder;
  };

  return (
    <SpacingStack spacing={6} width="100%">
      <GiftWrapOffer
        giverName={senderName}
        giftName={product.title}
        giftWrapEditMessageClicked={giftWrapEditMessageClicked}
        giftWrapPromptSent={giftWrapPromptSent}
        giftWrapMessageEdited={giftWrapMessageEdited}
        giftWrapUneditedMessageShown={uneditedWarningShown}
        onSetThemeId={handleSetThemeId}
        viewMoreDesignsClicked={viewMoreDesignsClicked}
        skip={!senderName}
        product={product}
        onButtonClick={handleSaveData}
        onContinue={onContinue}
      >
        {({ onClick }) => (
          <SpacingStack marginBottom={6}>
            <CartFooterSubmitButton disabled={disableNextStep || isSaving} saveData={onClick} />
          </SpacingStack>
        )}
      </GiftWrapOffer>
    </SpacingStack>
  );
};

export default MessageStep;
