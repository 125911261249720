import { styled, StyleSystemProps, Box, Flex, TextV2, LinkV2 } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledQuantityContainer = styled(Flex)``;
const quantityContainerStyles: StyleSystemProps = {
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const StyledDescriptionTitleContainer = styled(Box)``;
const descriptionStyles = (isDescriptionShowed: boolean): CSSObject => {
  return {
    marginBottom: 3,
    height: isDescriptionShowed ? 'fit-content' : pxToRem(24),
    overflowY: 'hidden',
    position: 'relative',
    color: 'mono14',
    _after: {
      content: '" "',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      ...(!isDescriptionShowed ? { background: 'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%)' } : {})
    }
  };
};

export const StyledSelectWrapper = styled(Flex)``;
const selectWrapperStyles: StyleSystemProps = {
  flex: '1 2 auto',
  marginLeft: 6
};

export const StyledQuantityInfoWrapper = styled(Flex)``;
const quantityInfoWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  flex: '2 0 120px',
  rowGap: 1
};

export const StyledCTAContainer = styled(Flex)``;
const ctaContainerStyles: StyleSystemProps = {
  flexDirection: 'column',
  alignItems: 'stretch',
  rowGap: 5
};

export const StyledPriceSummaryContainer = styled(Flex)``;
const priceSummaryContainerStyles: StyleSystemProps = {
  flexDirection: 'column',
  rowGap: 12
};

const productLinkStyles: StyleSystemProps = {
  color: 'mono10',
  marginBottom: pxToRem(18),
  display: 'flex',
  alignItems: 'center'
};

export const StyledProductTitle = styled(TextV2)`
  overflow-wrap: anywhere;
`;

export const StyledProductPrice = styled(TextV2)`
  font-weight: 500;
`;
export const StyledProductDescriptionContainer = styled(Box)``;
export const StyledPurchasableMessage = styled(TextV2)``;
export const StyledOtherGiftLink = styled(LinkV2)``;

export const styles = {
  quantityContainer: quantityContainerStyles,
  description: descriptionStyles,
  selectWrapper: selectWrapperStyles,
  quantityInfoWrapper: quantityInfoWrapperStyles,
  ctaContainer: ctaContainerStyles,
  priceSummaryContainer: priceSummaryContainerStyles,
  productLinkStyles: productLinkStyles
};
