import { useEffect, useMemo, useState } from 'react';
import { CatalogProductMediaAsset, MinimalCatalogProductItemDetails } from '../../../../Catalog.types';

import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export type UseProductImageGalleryReturn = ReturnType<typeof useProductImageGallery>;

type UseProductImageGalleryArgs = {
  initialMediaAsset: Maybe<CatalogProductMediaAsset>;
  selectedProduct: Maybe<MinimalCatalogProductItemDetails>;
};

export const useProductImageGallery = ({ initialMediaAsset, selectedProduct }: UseProductImageGalleryArgs) => {
  const [selectedMediaAsset, setSelectedMediaAsset] = useState<CatalogProductMediaAsset | null>(initialMediaAsset || null);

  const handleOnClickMediaAsset = useEventCallback((mediaAsset: CatalogProductMediaAsset) => {
    setSelectedMediaAsset(mediaAsset);
  });

  const mediaAssets: CatalogProductMediaAsset[] = useMemo(() => {
    return selectedProduct?.mediaAssets.length ? (selectedProduct?.mediaAssets.filter(Boolean) as CatalogProductMediaAsset[]) : [];
  }, [selectedProduct]);

  useEffect(() => {
    setSelectedMediaAsset(selectedProduct?.mediaAssets[0] || null);
  }, [selectedProduct]);

  return {
    handleOnClickMediaAsset,
    mediaAssets,
    selectedMediaAsset
  } as const;
};
