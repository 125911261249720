import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useTranslation } from '@shared/core';
import { useCheckoutInitDataContext } from '@apps/registry/guest/components/CheckoutDialog/hooks/useCheckoutInitData';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { useCheckoutTelemetryData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { addAction } from '@shared/utils/logger';

type GifterDetailsFormFields = {
  email: string;
  name: string;
  note: string;
};

/**
 * TODO: Handle email authenticated sessions
 */
export const useGifterDetailsController = () => {
  const {
    formValues,
    submitGuestDetails,
    context: { flowVariant, isAffiliate, isGroupGifting }
  } = useCheckoutFunnel(({ formValues, submitGuestDetails, context }) => [formValues, submitGuestDetails, context.flowVariant, context.isAffiliate, context.isGroupGifting]);
  const { registryItem } = useCheckoutInitDataContext('gifterDetails');
  const { profile, mySessionEmail } = useCheckoutDialogContext();

  const { addGifterDetails, reserveAndPurchase, authenticatedSession, affiliateAddGifterDetails } = useGuestRegistryTelemetry();
  const { t } = useTranslation('guestRegistry');
  const [useDefaultEmail, setUseDefaultEmail] = useState<boolean>(!formValues.email && !!profile?.email);

  const translations = t('checkoutDialog', 'screens', 'gifterDetails');
  const reserveItemTranslation = t('alreadyReserveSection');
  const { fieldEmailMissing, fieldNameMissing } = translations;
  const isCashFundType = registryItem?.donationFund?.fundType === 'cash';
  const isDeferred = flowVariant === 'deferred';
  const userEmail = formValues.email || mySessionEmail || profile?.email || '';

  const valueInMinorUnits = registryItem.productData.price?.valueInMinorUnits;

  const showCheckMailDialog = (emailId: string) => {
    authenticatedSession(emailId, 'AuthenticatedSessionChangePrompt');
    setUseDefaultEmail(false);
  };

  const { eventId, registryItemId, reservedQty, priceValueInMinorUnits, typeOfItem, productId, priceCurrencyCode, destinationUrl, productTitle } = useCheckoutTelemetryData();

  const { errors, touched, getFieldProps: _getFieldProps, handleSubmit } = useFormik<GifterDetailsFormFields>({
    initialValues: {
      email: userEmail,
      name: formValues.name || '',
      note: formValues.note || ''
    },
    validationSchema: Yup.object<GifterDetailsFormFields>({
      email: Yup.string().email().required(fieldEmailMissing()),
      name: Yup.string().required(fieldNameMissing()),
      note: Yup.string()
    }),
    onSubmit: values => {
      const { amount, email, name, note } = formValues;

      const telemetryData = {
        eventId,
        registryItemId,
        productId,
        gifterEmail: email,
        gifterName: name,
        gifterNote: note,
        buttonLabel: translations.cta(),
        productTitle
      };
      if (isAffiliate) {
        const affiliateAddGifterDetailsData = {
          ...telemetryData,
          typeOfItem: 'product',
          externalUrl: registryItem?.productData.externalUrl,
          giftAmount: (formValues.quantity || 1) * (valueInMinorUnits || 0),
          giftQuantity: formValues.quantity
        };
        affiliateAddGifterDetails(affiliateAddGifterDetailsData);
        addAction('affiliateAddGifterDetails', affiliateAddGifterDetailsData);
      } else {
        if (isDeferred) {
          const reserveAndPurchaseData = {
            ...telemetryData,
            reservedQty,
            priceValueInMinorUnits,
            priceCurrencyCode,
            destinationUrl,
            typeOfItem,
            isGroupGiftingEnabled: isGroupGifting
          };
          reserveAndPurchase(reserveAndPurchaseData);
          addAction('reserveAndPurchase', reserveAndPurchaseData);
        } else {
          const addGifterDetailsData = {
            ...telemetryData,
            giftAmount: amount,
            donationFundId: registryItem.donationFund?.id,
            externalUrl: registryItem.productData.externalUrl,
            flowVariant,
            isGroupGiftingEnabled: isGroupGifting
          };
          addGifterDetails(addGifterDetailsData);
          addAction('addGifterDetails', addGifterDetailsData);
        }
      }

      submitGuestDetails(values);
    }
  });

  const getFieldProps = useCallback(
    (field: keyof GifterDetailsFormFields) => {
      return _getFieldProps(field);
    },
    [_getFieldProps]
  );

  return {
    errors: {
      email: touched.email && errors.email ? errors.email : undefined,
      name: touched.name && errors.name ? errors.name : undefined,
      note: touched.note && errors.note ? errors.note : undefined
    },
    getFieldProps,
    handleSubmit,
    isDeferred,
    isCashFundType,
    translations,
    reserveItemTranslation,
    useDefaultEmail,
    userEmail,
    showCheckMailDialog,
    isAffiliate,
    formValues
  };
};
