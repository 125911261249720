import { styled, StyleSystemProps, Flex, ButtonV2 } from '@withjoy/joykit';
export const StyledGoBackWrapper = styled(Flex)``;
const goBackWrapperStyles: StyleSystemProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10
};

export const StyledGoBackButton = styled(ButtonV2)``;

export const styles = {
  goBackWrapper: goBackWrapperStyles
};
