import { useState } from 'react';
import { fonts } from '@shared/utils/fonts/fonts';
import { createGlobalStyle } from '@withjoy/joykit';
import { windowExists } from '@shared/utils/windowExists';

const registryFontList = [fonts.AkkuratLLSubRegularSubset, fonts.AkkuratLLWebBold, fonts.AustinNewsHeadline];

export const RegistryFontFaces = createGlobalStyle`
  ${registryFontList.map(
    font => `
    @font-face {
      font-family: ${font.fontFamily};
      src: url(${font.url}2) format("${font.format}2"), url(${font.url}) format("${font.format}");
      font-style: ${font.fontStyle};
      font-weight: ${font.fontWeight};
      font-display: fallback;
    }
  `
  )}
`;

const loadFont = async (font: { id: string; url: string }) => {
  return new Promise<void>((resolve, reject) => {
    if (windowExists()) {
      const document = window.document;
      if (document.getElementById(`registryFont-${font.id}`)) {
        resolve();
      } else {
        const head = document.getElementsByTagName('head')[0];
        const fontScriptTag = document.createElement('script');
        fontScriptTag.id = `registryFont-${font.id}`;
        head.appendChild(fontScriptTag);
        fontScriptTag.onload = () => {
          resolve();
        };
        fontScriptTag.src = font.url;
      }
    }
  });
};

const loadFonts = async (fontUrls: Array<{ id: string; url: string }>) => {
  const fontPromises = fontUrls.map(font => loadFont(font));
  return Promise.all(fontPromises);
};

export const useRegistryFonts = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  loadFonts(registryFontList).then(() => setFontLoaded(true));
  return { fontLoaded };
};
