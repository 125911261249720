import React, { useEffect, useRef } from 'react';
import { useTransition, animated } from 'react-spring';
import { StyledDiv, LottieWrapper, Notification, ToastWrapper, styles } from './NotificationToast.styles';
import { BoxProps, TextV1 } from '@withjoy/joykit';
import { LottiePlayer } from '@shared/components';
import ReactDOM from 'react-dom';
import { withWindow } from '@shared/utils/withWindow';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';

export interface NotificationToastProps {
  notificationVisible: boolean;
  setNotificationVisible: (val: boolean) => void;
  notificationText: string;
  isPositionTop?: boolean;
  noAnimation?: boolean;
}

export const NotificationToast: React.FC<NotificationToastProps & BoxProps> = ({
  notificationVisible,
  setNotificationVisible,
  notificationText,
  isPositionTop = false,
  noAnimation = false
}) => {
  const lottieUrl = 'https://withjoy.com/assets/public/apps/registry/lottie/toast-confetti.json';
  const transitions = useTransition(notificationVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true
  });

  useIsMounted();

  const appRootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    withWindow(global => {
      appRootRef.current = global.document.body;
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setNotificationVisible(false), 2000);
    return () => clearTimeout(timer);
  }, [notificationVisible, setNotificationVisible]);

  if (!appRootRef.current) {
    return null;
  }

  const container = appRootRef.current;

  const result = transitions.map(
    ({ item, key, props }) =>
      item &&
      ReactDOM.createPortal(
        <ToastWrapper __css={styles.wrapper(isPositionTop, noAnimation)}>
          <animated.div style={props}>
            <StyledDiv>
              {!noAnimation && (
                <LottieWrapper>
                  <LottiePlayer play={notificationVisible} lottieUrl={lottieUrl} />
                </LottieWrapper>
              )}
              <Notification isPositionTop={isPositionTop}>
                <TextV1 variant="label2" component="span" color={'white'}>
                  {notificationText}
                </TextV1>
              </Notification>
            </StyledDiv>
          </animated.div>
        </ToastWrapper>,
        container,
        key
      )
  );

  return <>{result}</>;
};
