import React from 'react';
import { TextV2 } from '@withjoy/joykit';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useTranslation } from '@shared/core';

import { MetadataBase } from './MetadaBase';
import { MapMarker } from './icons';

const AddressLine = ({ text, isFirstLine }: { text: string; isFirstLine?: boolean }) => {
  return (
    <TextV2 typographyVariant="body1" tagName="p" color={isFirstLine ? 'mono12' : 'mono10'} textAlign="left">
      {text}
    </TextV2>
  );
};

type Props = {
  address: ShippingAddressFragment;
};

export const AddressMetadata = ({ address }: Props) => {
  const { t2 } = useTranslation('guestRegistry');
  const { shippingTo } = t2('shoppingCart', 'metadataAtoms');
  return (
    <MetadataBase
      icon={<MapMarker />}
      text={shippingTo}
      content={
        <>
          {address.name && <AddressLine text={address.name} isFirstLine />}
          <AddressLine text={address.address1} />
          {address.address2 && <AddressLine text={address.address2} />}
          <AddressLine text={`${address.city}, ${address.state} ${address.postalCode}`} />
        </>
      }
    />
  );
};
