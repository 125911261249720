import React, { useCallback, useEffect } from 'react';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { Flex, InputV1, Stack, Box, SelectV1, TextArea, TextV2, OptionType, LinkV2, DialogV2, useDisclosure } from '@withjoy/joykit';
import { useGiveGiftDialogController, GiveGiftDialogFields } from './GiveGiftDialog.controller';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import {
  StyledHeaderText,
  StyledProductPhoto,
  ReserveButton,
  StyledFormField,
  ErrorFiller,
  StyledInputWrapper,
  StyledInputPrefix,
  StyledNumericInput,
  StyledLink
} from './GiveGiftDialog.styles';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { ExpandableMessage } from '@apps/registry/admin/components/CustomItemDetailsDialog/components/ExpandableMessage';
import { addRendition } from '@shared/utils/photoRendition';
import { useTranslation } from '@shared/core';
import { DonationFundPaymentMethodEnum, DonationFundPlatformTypeEnum } from '@graphql/generated';
import { useManagedGiftReservation } from '../../hooks/useManagedGiftReservation';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

export interface GiveGiftDialogProps
  extends Readonly<
    {
      product?: CookedProduct;
      eventId?: string;
      existingOrderId: Maybe<string>;
      mySessionEmail: Maybe<string>;
      shippingAddress?: Maybe<ShippingAddressFragment>;
    } & UseGiveGiftDialogReturn
  > {}

type GiveGiftDialogBodyProps = Merge<GiveGiftDialogProps, Required<Pick<GiveGiftDialogProps, 'product'>>>;

const GiveGiftDialogBody: React.FC<GiveGiftDialogBodyProps> = props => {
  const {
    product,
    eventId,
    existingOrderId,
    isOpen,

    handleOnClose,
    handleOnAfterGiveGift,
    mySessionEmail,
    recentGiftStatus
  } = props;
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { getGiveGiftDialogTranslations } = useRegistryGuestTranslations();
  const { alreadyReservedGiftViewed } = useGuestRegistryTelemetry();
  const {
    formik,
    handlePurchased,
    setAmount,
    suggestedDonation,
    stillNeededOptions,
    preventNonNumericalInput,
    availablePaymentMethods,
    setDonationType,
    currencySymbol,
    currentlySelectedPaymentMethod,
    showCheckMailDialog
  } = useGiveGiftDialogController({
    onSubmitted: handleOnAfterGiveGift,
    product,
    eventId,
    existingOrderId,
    isOpen,
    mySessionEmail,
    onClose: handleOnClose
  });
  const {
    fieldAmountLabel,
    fieldEmailLabel,
    fieldEmailPlaceholder,
    fieldNameLabel,
    fieldNamePlaceholder,
    explanationLink,
    fieldNoteLabel,
    fieldNotePlaceholder,
    paymentMethodLabel,
    paymentMethodSelectPlaceholder,
    formButtonAddCartReserve,
    formButtonUpdateDonation
  } = getGiveGiftDialogTranslations();
  const { t } = useTranslation('guestRegistry');
  const reserveItemTranslation = t('alreadyReserveSection');

  const description = product.donationFund?.fund.description;

  useEffect(() => {
    if (recentGiftStatus?.alreadyReserved) {
      alreadyReservedGiftViewed({ label: recentGiftStatus?.usedEmail });
    }
  }, [alreadyReservedGiftViewed, recentGiftStatus?.alreadyReserved, recentGiftStatus?.usedEmail]);

  return (
    <Flex height="100%" flexDirection={['column', null, 'row']}>
      <Box width={['100%', null, '50%', '60%']}>
        <StyledProductPhoto image={addRendition({ url: product.image, renditionSize: 'large' })} isMobile={isMobile} />
      </Box>
      <Box width={['100%', null, '50%', '40%']} padding={['40px 20px', null, '64px 48px']} style={{ overflowY: 'scroll' }}>
        {recentGiftStatus?.alreadyReserved ? (
          <Box>
            <TextV2 typographyVariant="hed4" paddingRight={9}>
              {reserveItemTranslation.title()}
            </TextV2>
            <TextV2 typographyVariant="body1" marginTop={7} paddingRight={9}>
              {reserveItemTranslation.subTitle()}
            </TextV2>
            <TextV2 typographyVariant="body1" marginTop={7} style={{ wordBreak: 'break-all' }}>
              {reserveItemTranslation.checkEmail({ email: recentGiftStatus.usedEmail })}
            </TextV2>
          </Box>
        ) : (
          <>
            <form>
              <Stack spacing={6} marginBottom={5}>
                <Stack spacing={3}>
                  <StyledHeaderText typographyVariant="hed4" component="p">
                    {product.title}
                  </StyledHeaderText>
                  {description && <ExpandableMessage message={description} />}
                </Stack>
                <Box display="flex" flexDirection="row">
                  <Box width="40%">
                    <DonationAmountInput
                      isDisabled={!!existingOrderId}
                      fieldAmountLabel={fieldAmountLabel()}
                      {...{ suggestedDonation, formik, isMobile, stillNeededOptions, setAmount, preventNonNumericalInput, currencySymbol }}
                    />
                  </Box>
                  {product.donationFund?.fund?.platform?.type !== DonationFundPlatformTypeEnum.other && (
                    <Box flex={1} marginLeft="1rem">
                      <StyledFormField
                        label={paymentMethodLabel()}
                        error={formik.errors.platformType && formik.touched.platformType ? formik.errors.platformType : undefined}
                        isMobile={isMobile}
                        width="100%"
                      >
                        <SelectV1
                          placeholder={paymentMethodSelectPlaceholder()}
                          searchable={false}
                          options={availablePaymentMethods}
                          fluid={false}
                          onChange={e => setDonationType(e?.value!)}
                          value={currentlySelectedPaymentMethod}
                        />
                      </StyledFormField>
                    </Box>
                  )}
                </Box>
                <Stack spacing={4}>
                  <Box>
                    <StyledFormField label={fieldNameLabel()} error={formik.errors.name && formik.touched.name ? formik.errors.name : undefined}>
                      <InputV1 {...formik.getFieldProps('name')} placeholder={fieldNamePlaceholder()} tabIndex={0} disabled={!!existingOrderId} />
                      <ErrorFiller isRendered={!!!formik.errors.name || (!!formik.errors.name && !formik.touched.name)}></ErrorFiller>
                    </StyledFormField>
                  </Box>

                  <StyledFormField label={fieldEmailLabel()} error={formik.errors.email && formik.touched.email ? formik.errors.email : undefined}>
                    {mySessionEmail ? (
                      <Box>
                        <TextV2 typographyVariant="body1">{mySessionEmail}</TextV2>
                        <LinkV2
                          display="inline-block"
                          typographyVariant="body1"
                          textDecoration="none"
                          marginBottom={'22px'}
                          onClick={() => showCheckMailDialog(mySessionEmail)}
                          marginTop={1}
                          fontWeight={600}
                        >
                          {reserveItemTranslation.changeEmail()}
                        </LinkV2>
                      </Box>
                    ) : (
                      <>
                        <InputV1 {...formik.getFieldProps('email')} placeholder={fieldEmailPlaceholder()} tabIndex={0} disabled={!!existingOrderId} />
                        <ErrorFiller isRendered={!!!formik.errors.email || (!!formik.errors.email && !formik.touched.email)}></ErrorFiller>
                      </>
                    )}
                  </StyledFormField>
                  <StyledFormField label={fieldNoteLabel()} error={formik.errors.note && formik.touched.note ? formik.errors.note : undefined}>
                    <TextArea
                      {...formik.getFieldProps('note')}
                      placeholder={fieldNotePlaceholder()}
                      resize={false}
                      minRows={3}
                      maxRows={3}
                      tabIndex={0}
                      disabled={!!existingOrderId}
                    />
                  </StyledFormField>
                </Stack>
                <ReserveButton intent="primary" fill={true} onClick={handlePurchased}>
                  {!existingOrderId ? formButtonAddCartReserve() : formButtonUpdateDonation()}
                </ReserveButton>
              </Stack>
              {!existingOrderId && (
                <StyledLink
                  textDecoration="none"
                  component="a"
                  href="https://help.withjoy.com/knowledge-base/how-do-i-contribute-to-a-honeymoon-or-cash-fund"
                  target="_blank"
                  marginTop={7}
                  allowFollow={true}
                >
                  {explanationLink()}
                </StyledLink>
              )}
            </form>
          </>
        )}
      </Box>
    </Flex>
  );
};

export const GiveGiftDialog: React.FC<GiveGiftDialogProps> = ({ product, isOpen, handleOnClose, ...restProps }) => {
  if (!product) {
    return null;
  }

  return (
    <>
      <DialogV2
        isOpen={isOpen}
        overrides={{
          Content: {
            props: {
              height: [null, '2xl']
            }
          }
        }}
        onClose={handleOnClose}
        disableCloseOnOutsideClick={true}
        size="5xl"
      >
        <GiveGiftDialogBody product={product} isOpen={isOpen} handleOnClose={handleOnClose} {...restProps} />
        <DialogV2.CloseButton />
      </DialogV2>
    </>
  );
};

export type GiveGiftDialogInput = {
  product: Maybe<CookedProduct>;
  showToast?: () => void;
};

export type UseGiveGiftDialogReturn = ReturnType<typeof useGiveGiftDialog>;

export const useGiveGiftDialog = (args: GiveGiftDialogInput) => {
  const { product, showToast } = args;

  const { updateDataProvider } = useGuestRegistryState();
  const { handleOnGuestDetailsSave, recentGiftStatus, setRecentGiftStatus } = useManagedGiftReservation({ product });
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleOnAfterGiveGift = useCallback(
    (values: GiveGiftDialogFields, orderId: string, orderExists?: boolean) => {
      setRecentGiftStatus({ alreadyReserved: !!orderExists, usedEmail: values.email });
      updateDataProvider({
        type: 'initial',
        productId: product?.id,
        order: {
          orderId,
          donationFundPlatformType: values.platformType ? DonationFundPaymentMethodEnum[values.platformType] : undefined
        },
        guest: {
          name: values.name,
          email: values.email
        },
        quantity: values.amount ? values.amount : 0
      });

      handleOnGuestDetailsSave(values.email, values.name);

      if (!orderExists) {
        showToast?.();
      }
    },
    [handleOnGuestDetailsSave, updateDataProvider, product, showToast, setRecentGiftStatus]
  );

  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    isOpen,
    handleOnAfterGiveGift,
    handleOnClose,
    handleOnOpen: onOpen,
    recentGiftStatus
  } as const;
};

GiveGiftDialog.displayName = 'GiveGiftDialog';

type DonationAmountInputProps = {
  suggestedDonation: number | undefined;
  fieldAmountLabel: string;
  formik: ReturnType<typeof useGiveGiftDialogController>['formik'];
  isMobile: boolean | undefined;
  stillNeededOptions: OptionType[];
  setAmount: (amount?: string | undefined) => void;
  preventNonNumericalInput: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  currencySymbol: string;
  isDisabled: boolean;
};

const DonationAmountInput = ({
  suggestedDonation,
  fieldAmountLabel,
  formik,
  isMobile,
  stillNeededOptions,
  setAmount,
  preventNonNumericalInput,
  currencySymbol,
  isDisabled
}: DonationAmountInputProps) => {
  return suggestedDonation ? (
    <StyledFormField label={fieldAmountLabel} error={formik.errors.amount && formik.touched.amount ? formik.errors.amount : undefined} isMobile={isMobile} width="100%">
      <SelectV1 disabled={isDisabled} defaultValue={stillNeededOptions[0]} searchable={false} options={stillNeededOptions} fluid={false} onChange={e => setAmount(e?.value)} />
    </StyledFormField>
  ) : (
    <StyledFormField label={fieldAmountLabel} error={formik.errors.amount && formik.touched.amount ? formik.errors.amount : undefined} isMobile={isMobile} width="100%">
      <StyledInputWrapper>
        <StyledInputPrefix prefixOffset={16}>{currencySymbol}</StyledInputPrefix>
        <StyledNumericInput
          {...formik.getFieldProps('amount')}
          type="number"
          width="100%"
          inputMode="numeric"
          pattern="[0-9]*"
          onKeyDown={preventNonNumericalInput}
          tabIndex={0}
          // eslint-disable-next-line
          autoFocus={true}
          disabled={isDisabled}
        />
      </StyledInputWrapper>
      <ErrorFiller isRendered={!!!formik.errors.amount || (!!formik.errors.amount && !formik.touched.amount)}></ErrorFiller>
    </StyledFormField>
  );
};
