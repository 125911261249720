import { animationTransition, STANDARD_TIMING_FUNCTION } from '@shared/utils/animationTransition';
import { styled, Box, Stack, keyframes, TextV2, TextV2Props } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const growToWidth = keyframes`
  from { width: 0%; }
  to { width: 100%; }
`;

export const StyledProgressBar = styled(Box)<{ isGroupGiftingVariant?: boolean }>`
  background-color: ${props => props.theme.colors.mono3};
  border-radius: 20px;
  height: ${props => (props.isGroupGiftingVariant ? pxToRem(16) : pxToRem(8))};
  position: relative;
`;

export const StyledGiftTrackerContainer = styled(Stack)<{ $progressDecimal: number; $isGuestSite?: boolean; currentDonation?: number }>`
  ${({ $isGuestSite }) => ($isGuestSite ? `width: 100%` : ``)};
  position: relative;
  ${StyledProgressBar} {
    ::after {
      content: ' ';
      display: block;
      position: absolute;
      background-color: ${props => props.theme.colors.green7};
      border-radius: 20px;
      left: 0;
      top: 0;
      bottom: 0;
      min-width: 8px;
      max-width: ${({ $progressDecimal }) => ($progressDecimal === 0 ? '8px' : `${$progressDecimal * 100}%`)};
      animation: ${growToWidth} 2s ${STANDARD_TIMING_FUNCTION} forwards 1s;
      transition: ${animationTransition('max-width')};
    }
  }

  :hover {
    ${StyledProgressBar} {
      &::after {
        ${({ $progressDecimal }) => {
          if ($progressDecimal === 1) {
            return null;
          }

          let maxWidth: string = '10px';
          if ($progressDecimal > 0) {
            const nudgeDelta = Math.min(1 - $progressDecimal, 0.01);
            const expandedProgress = Math.min($progressDecimal + nudgeDelta, 1);
            maxWidth = `max(${expandedProgress * 100}%, 12px)`;
          }

          return {
            maxWidth
          };
        }}
      }
    }
  }
`;

export const StyledLabel = styled(TextV2)``;
const labelStyles: TextV2Props = {
  typographyVariant: 'label2',
  color: 'mono12'
};

export const styles = {
  label: labelStyles
};
