import { ShippingAddressInput } from '@graphql/generated';

/**
 *
 * @param address unknown
 * @returns boolean true if the address evaluates to be a valid ShippingAddressInput
 */
export const isAddressValid = (address: unknown): address is ShippingAddressInput => {
  if (!address || typeof address != 'object') {
    return false;
  }

  if (!('city' in address) || typeof address.city !== 'string' || !address.city) {
    return false;
  }

  if (!('state' in address) || typeof address.state !== 'string' || !address.state) {
    return false;
  }

  if (!('country' in address) || typeof address.country !== 'string' || !address.country) {
    return false;
  }

  if (!('address1' in address) || typeof address.address1 !== 'string' || !address.address1) {
    return false;
  }

  if (!('postalCode' in address) || typeof address.postalCode !== 'string' || !address.postalCode) {
    return false;
  }

  return true;
};

export const isUSAddress = (address: ShippingAddressInput) => address.country === 'United States' || address.countryCode === 'USA';
