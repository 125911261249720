import useLoadGoogleFont from '@apps/saveTheDate/hooks/useLoadGoogleFont';
import MessageLoader from '@shared/components/EmailsAndEcards/components/Emails/components/MessageLoader/MessageLoader';
import { Box, ButtonV2, Flex } from '@withjoy/joykit';
import React, { useState, useRef, useCallback, useLayoutEffect, SyntheticEvent } from 'react';
import { useGenerateGiftNote } from '../GiftWrap.utils';
import { ThemePicker } from './GiftWrapThemePicker';
import BasicCardView from '../views/basic/BasicCardView';
import { IGiftWrapTheme, EGiftWrapType, GiftWrapPremiumDetails } from '../GiftWrapTypes';
import { ReactComponent as PencilIcon } from '@assets/icons/iconPencilSmall.svg';
import { GiftWrapGiftPreview } from './GiftWrapGiftPreview';
import { GiftWrapMessage, giftWrapMessageStyles } from './GiftWrapMessage';
import { GiftWrapFooter } from './GiftWrapFooter';
import { EditMessageSource, GiftWrapProduct } from '../GiftWrapTypes';
import { CardContainer, MessageTextArea } from './GiftWrap.styles';
import { IRevealType, Reveal } from '@apps/greetingCard/components/Reveal/Reveal';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventInfo } from '@shared/utils/eventInfo';
import PremiumView from '@apps/greetingCard/components/Premium';
import { GetMessageComponentContext } from '@apps/greetingCard/components/Premium/PremiumCardTemplate';
import { useWindowSize } from '@apps/greetingCard/components/Reveal/hooks';

interface GiftWrapEditorProps {
  onBlur?: (data: { originalNote: string; message: string }) => void;
  onPromptSent?: (prompt: string) => void;
  onEditMessageClick?: (source: EditMessageSource) => void;
  skip?: boolean;
  prompt: string;
  promptFallback?: string;
  message: string;
  setMessage: (message: string) => void;
  themes?: IGiftWrapTheme[];
  setThemeId: (id: string, defaultThemeID: string, themeHistory: string[], themeOrder: string[]) => void;
  isLoading?: boolean;
  product?: GiftWrapProduct;
  onViewMoreThemesClick?: (show: boolean) => void;
  randomizeThemeOrder?: boolean;
}

export const GiftWrapEditor: React.FC<GiftWrapEditorProps> = ({
  onBlur,
  onPromptSent,
  onEditMessageClick,
  skip,
  themes = [],
  product,
  isLoading: isLoadingProp = false,
  prompt,
  promptFallback,
  message,
  setMessage,
  setThemeId,
  onViewMoreThemesClick,
  randomizeThemeOrder
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [envelopeBackground, setEnvelopeBackground] = useState<string>('rgb(220,220,220');
  const [containerWidth, setContainerWidth] = useState<number>();
  const [selectedTheme, setSelectedTheme] = useState<IGiftWrapTheme | undefined>();

  const themeHistory = useRef<string[]>([]);
  const defaultThemeID = useRef<string | undefined>();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textDisplayRef = useRef<HTMLParagraphElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentThemRef = useRef<IGiftWrapTheme | undefined>(selectedTheme);
  const currentThemeOrder = useRef<string[] | undefined>();
  const firstRun = useRef(true);

  const { value: envelopeRevealEnabled } = useFeatureValue('registryGiftWrapUseEnvelopeInEditor');
  const { width: winWidth } = useWindowSize();

  const PADDING = 32;
  const isRevealReady = !containerWidth;

  useLoadGoogleFont(['Shadows Into Light Two']);

  useLayoutEffect(() => {
    if (winWidth && containerRef.current && containerWidth !== containerRef.current.clientWidth - PADDING && containerRef.current.clientWidth > 0) {
      setContainerWidth(containerRef.current.clientWidth - PADDING);
    }
  }, [setContainerWidth, containerWidth, containerRef?.current?.clientWidth, winWidth]);

  const processThemeSelect = useCallback(
    (theme: IGiftWrapTheme, themeOrder: string[]) => {
      if (!defaultThemeID.current) {
        defaultThemeID.current = theme.id;
      }
      themeHistory.current.push(theme.id);
      setSelectedTheme(theme);
      setThemeId(theme.id, defaultThemeID.current, themeHistory.current, themeOrder);
    },
    [setThemeId, setSelectedTheme]
  );

  const handleThemeSelect = useCallback(
    (theme: IGiftWrapTheme, themeOrder: string[]) => {
      setEnvelopeBackground(theme.background);
      // When we switch between premium to another premium, or premium to basic theme we need to explicitly call processThemeSelect
      // because the theme transition complete event will not fire for components not wrapped in Reveal.
      if (firstRun.current === true || theme?.type === EGiftWrapType.PREMIUM || currentThemRef.current?.type === EGiftWrapType.PREMIUM) {
        processThemeSelect(theme, themeOrder);
      }

      currentThemeOrder.current = themeOrder;
      currentThemRef.current = theme;

      if (firstRun.current === true) {
        firstRun.current = false;
      }
    },
    [processThemeSelect]
  );

  const handleThemeTransitionComplete = () => {
    if (currentThemRef.current && currentThemeOrder.current) {
      processThemeSelect(currentThemRef.current, currentThemeOrder.current);
    }
  };

  const { originalNote, isGeneratedMessageLoading } = useGenerateGiftNote({
    prompt,
    fallback: promptFallback,
    skip,
    onCompleted: (note, prompt) => {
      setMessage(note);
      onPromptSent?.(prompt);
    }
  });

  const handleBlur = () => {
    onBlur?.({ originalNote, message });
    setTimeout(() => {
      textDisplayRef.current?.scrollIntoView({
        behavior: 'smooth'
      });
      setIsEditMode(false);
    });
  };

  const handleEditMessageClick = (source: EditMessageSource, event: SyntheticEvent) => {
    event.stopPropagation();
    onEditMessageClick?.(source);
    setIsEditMode(true);
    setTimeout(() => {
      textareaRef.current?.scrollIntoView({
        behavior: 'smooth'
      });
      textareaRef.current?.focus();
    });
  };

  const { eventInfo } = useEventInfo();
  const details: GiftWrapPremiumDetails = {
    message,
    name1: eventInfo?.fianceeFirstName ?? 'Avery',
    name2: eventInfo?.ownerFirstName ?? 'Parker',
    eventDate: 0, // Next work item, need to plumb in the real event date.
    product
  };

  const getCardViewComponent = () => {
    if (!selectedTheme) return null;

    switch (selectedTheme.type) {
      case EGiftWrapType.BASIC:
        return (
          <Reveal
            width={containerWidth}
            verticalOffset={0}
            type={envelopeRevealEnabled ? IRevealType.Envelope : IRevealType.Default}
            themeColor={envelopeBackground}
            autoPlayDelay={1}
            onThemeTransitionComplete={handleThemeTransitionComplete}
            loading={isRevealReady}
            blockInteraction={true}
          >
            <CardContainer flexDirection="column" alignItems="center" backgroundColor={themeBgColor}>
              <BasicCardView theme={selectedTheme} />
              {getMessageComponent()}
              {product && (
                <GiftWrapGiftPreview image={product.image} store={product.store} title={product.title} extraProductsCount={product.extraProductsCount} textColor={textColor} />
              )}
              <GiftWrapFooter footerImage={selectedTheme?.accent} />
            </CardContainer>
          </Reveal>
        );
      case EGiftWrapType.PREMIUM:
        return (
          <GetMessageComponentContext.Provider value={getMessageComponent}>
            <PremiumView details={details} theme={selectedTheme.id} />
          </GetMessageComponentContext.Provider>
        );
    }
  };

  const getMessageComponent = () => {
    if (isGeneratedMessageLoading) {
      return <MessageLoader key="loader" color={selectedTheme?.text} />;
    }

    if (isEditMode) {
      return (
        <MessageTextArea
          ref={textareaRef}
          fontSize={giftWrapMessageStyles.fontSize}
          lineHeight={giftWrapMessageStyles.lineHeight}
          background={themeBgColor}
          color={textColor}
          value={message}
          onChange={e => setMessage(e.target.value)}
          onBlur={() => handleBlur()}
          disabled={isLoading}
        />
      );
    }

    return (
      <Box position="relative" ref={textDisplayRef}>
        <GiftWrapMessage textColor={textColor} message={message} onClick={handleEditMessageClick} cursor="text" />
        <ButtonV2
          padding="6px"
          minWidth={40}
          width={40}
          height={40}
          borderRadius={20}
          border={'1px solid white'}
          intent="neutral"
          onClick={event => handleEditMessageClick(EditMessageSource.EDIT_PENCIL, event)}
          position="absolute"
          right={0}
          top="50%"
          transform="translateX(50%)"
        >
          <PencilIcon />
        </ButtonV2>
      </Box>
    );
  };

  const isLoading = isLoadingProp || isGeneratedMessageLoading;
  const textColor = selectedTheme?.text || 'mono12';
  const themeBgColor = selectedTheme?.background || 'rgb(220,220,220';

  return (
    <Flex ref={containerRef} flexDirection="column" alignItems="center" marginX={'auto'} width={'100%'} maxWidth={'550px'} marginTop={0}>
      <ThemePicker themes={themes} onThemeSelect={handleThemeSelect} onViewMoreThemesClick={onViewMoreThemesClick} randomize={randomizeThemeOrder} />
      <div style={{ position: 'relative', width: '100%', zIndex: 0 }}>
        {/* The above div contains/prevents premium wallpaper (fixed layout) from exceeding this space. */}
        {getCardViewComponent()}
      </div>
    </Flex>
  );
};
