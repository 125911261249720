import React from 'react';
import { productImageStyles, StyledProductImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/LayoutShell/LayoutShell.styles';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { ProductSummary } from '../ProductSummary';
import { ProductSummaryWrapper } from '../ProductSummary/ProductSummary.styles';

type Props = {
  product?: CookedProduct;
  productImageSrc?: string;
  quantity?: number;
  currency?: string;
};

export const ProductSummaryWithImage = ({ product, quantity, productImageSrc, currency }: Props) => {
  return (
    <>
      {productImageSrc && <StyledProductImage __css={productImageStyles(productImageSrc)} />}
      {product && (
        <ProductSummaryWrapper>
          <ProductSummary isIn totalQuantity={quantity || 1} product={product} transitionDuration={4000} currency={currency} />
        </ProductSummaryWrapper>
      )}
    </>
  );
};
