import { LinkV2, styled } from '@withjoy/joykit';

export const StyledSuccessGuestLink = styled(LinkV2)`
  align-self: center;
  cursor: pointer;
  color: ${props => props.theme.colors.green7} !important;
  &:hover {
    color: ${props => props.theme.colors.green6} !important;
  }
  &:active {
    color: ${props => props.theme.colors.green8} !important;
  }
`;

export const StyledSuccessGuestLinkBold = styled(LinkV2)`
  align-self: center;
  cursor: pointer;
  color: ${props => props.theme.colors.green7} !important;
  &:hover {
    color: ${props => props.theme.colors.green6} !important;
  }
  &:active {
    color: ${props => props.theme.colors.green8} !important;
  }
`;
