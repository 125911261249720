import React from 'react';
import { DialogV2 } from '@withjoy/joykit';
import { ProductListV2, InCartProduct } from './ShoppingCart.types';
import { ShoppingCartInnerV2 } from './ShoppingCartInnerV2';
import { RegistryOrderFragment } from '@graphql/generated';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { useShoppingCart } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/state';

interface ShoppingCartDialogProps
  extends Readonly<{
    isOpen: boolean;
    eventId: string;
    helpDialogOpen: boolean;
    isAddExternalOrderTrackingDialogOpen: boolean;
    productList: ProductListV2;
    reservedOrderList: RegistryOrderFragment[];
    purchasedOrderList: RegistryOrderFragment[];
    orderCount: number;
    cartOrderCount: number;
    cartTotal: string;
    orderForTrackingRequest: Maybe<RegistryOrderFragment>;
    paths: ReturnType<typeof useRoutePaths>;
    inCartProductList: Array<InCartProduct>;
    inCartProductCount: number;
    inCartProductTotal: number;
    showHelpDialog: () => void;
    handleDialogClose: () => void;
    closeAddExternalOrderTrackingDialog: () => void;
    registryCurrencyCode?: string;
  }> {}

const DIALOG_CONTENT_ANIMATIONS = {
  in: { opacity: 1 },
  out: { opacity: 0 }
} as const;

export const ShoppingCartDialogV2: React.FC<ShoppingCartDialogProps> = ({
  isOpen,
  eventId,
  helpDialogOpen,
  isAddExternalOrderTrackingDialogOpen,
  productList,
  reservedOrderList,
  purchasedOrderList,
  orderCount,
  cartOrderCount,
  cartTotal,
  orderForTrackingRequest,
  inCartProductList,
  inCartProductCount,
  inCartProductTotal,
  paths,
  showHelpDialog,
  handleDialogClose,
  closeAddExternalOrderTrackingDialog,
  registryCurrencyCode
}) => {
  const {
    mutators: { closeShoppingCart }
  } = useShoppingCart();
  return (
    <DialogV2 isOpen={isOpen} onClose={closeShoppingCart} contentTransition={DIALOG_CONTENT_ANIMATIONS}>
      <DialogV2.Body __css={{ padding: 0 }}>
        <ShoppingCartInnerV2
          eventId={eventId}
          helpDialogOpen={helpDialogOpen}
          isAddExternalOrderTrackingDialogOpen={isAddExternalOrderTrackingDialogOpen}
          productList={productList}
          reservedOrderList={reservedOrderList}
          purchasedOrderList={purchasedOrderList}
          orderCount={orderCount}
          cartOrderCount={cartOrderCount}
          cartTotal={cartTotal}
          orderForTrackingRequest={orderForTrackingRequest}
          paths={paths}
          inCartProductList={inCartProductList}
          inCartProductCount={inCartProductCount}
          inCartProductTotal={inCartProductTotal}
          showHelpDialog={showHelpDialog}
          handleDialogClose={handleDialogClose}
          closeAddExternalOrderTrackingDialog={closeAddExternalOrderTrackingDialog}
          registryCurrencyCode={registryCurrencyCode}
        />
      </DialogV2.Body>
    </DialogV2>
  );
};

ShoppingCartDialogV2.displayName = 'ShoppingCartDialogV2';
