import { styled, Box } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';
import { pxToRem } from '@withjoy/joykit/theme';
import { responsiveBreakpoints } from '@apps/registry/common/utils/responsiveHelpers';
import { StyledInputContainer } from '@withjoy/joykit/components/InputV1/StyledInputContainer';
import { CSSProperties } from 'react';

const nonZeroProductListStyles: CSSObject = {
  gridTemplateColumns: {
    _: 'repeat(2, 1fr)',
    md2: 'repeat(3, minmax(0, 1fr))',
    lg: 'repeat(4, minmax(0, 1fr))'
  },
  gridTemplateRows: '1fr',
  gridColumnGap: {
    _: pxToRem(10),
    md2: pxToRem(65)
  },
  gridRowGap: {
    _: pxToRem(17),
    md2: pxToRem(65)
  },

  // Grid dividers
  ['& > div']: {
    // Base Row divider
    _after: {
      content: '" "',
      height: '1px',
      width: '100%',
      bottom: {
        _: pxToRem(-8),
        md2: pxToRem(-32)
      },
      backgroundColor: 'mono3',
      position: 'absolute'
    },

    // Base Column divider
    _before: {
      position: 'absolute',
      width: '1px',
      height: {
        _: `calc(100% + ${pxToRem(16)})`, // 16px, aka row gap x2,
        md2: `calc(100% + ${pxToRem(64)})`
      },
      top: '50%',
      right: {
        _: pxToRem(-1 * ((8 - 1) / 2 + 1) - 1), // 8 = column gap; - 1 = divider width; + 1 = to center
        md2: pxToRem(-32)
      },
      backgroundColor: 'mono3',
      transform: 'translateY(-50%)'
    },

    // Show/hide column rules
    _odd: {
      _before: {
        // Default to show
        content: '" "'
      }
    },
    _even: {
      _before: {
        content: {
          // Reveal when 3+ column
          md2: '" "'
        }
      }
    },
    // Targeting 3rd column
    ['&:nth-child(3n+3)']: {
      _before: {
        content: {
          // Hide it when grid is 3 column
          md2: 'unset',
          // Reveal when grid is 4 column
          lg: '" "'
        }
      }
    },

    // NOTE: Commenting out until discussing with merch about 4 columns
    // Targeting 4th column
    ['&:nth-child(4n+4)']: {
      _before: {
        content: {
          // Reveal when grid is 4 column
          lg: 'unset'
        }
      }
    }
  }
};

const selectStyles: CSSProperties = {
  border: 'none',
  outline: 'none',
  height: pxToRem(48),
  textAlign: 'right',
  fontSize: pxToRem(15)
};

const selectWrapperStyles: CSSObject = {
  [`${StyledInputContainer} > input`]: {
    textAlign: 'right',
    paddingRight: '0 !important',
    marginRight: `${pxToRem(48)}`,
    width: `${pxToRem(240)}`,
    background: 'transparent'
  }
};

export const SelectWrapper = styled(Box)``;

export const StyledSelect = styled.select`
  padding: 0 ${pxToRem(24)};
  background: none;
`;

export const StyledGridContainer = styled(Box)<{ numberOfItems: number }>`
  display: grid;

  // Final items in grid should never have a bottom line (see COMMERCE-368).
  // Need to calculate the number of last children for each layout (desktop - 4 columns, tablet - 3 columns. etc.)
  & > div {
    @media (min-width: ${responsiveBreakpoints.SIZE_1440}) {
      &:nth-last-child(-n + ${({ numberOfItems }) => (numberOfItems % 4 === 0 ? 4 : numberOfItems % 4)}):after {
        content: unset;
      }
    }
    @media (max-width: calc(${responsiveBreakpoints.SIZE_1440} - 1px)) {
      &:nth-last-child(-n + ${({ numberOfItems }) => (numberOfItems % 3 === 0 ? 3 : numberOfItems % 3)}):after {
        content: unset;
      }
    }
    @media (max-width: calc(${responsiveBreakpoints.SIZE_1152} - 1px)) {
      &:nth-last-child(-n + ${({ numberOfItems }) => (numberOfItems % 2 === 0 ? 2 : numberOfItems % 2)}):after {
        content: unset;
      }
    }
  }
`;

export const styles = {
  nonZeroProductListStyles: nonZeroProductListStyles,
  selectStyles: selectStyles,
  selectWrapperStyles: selectWrapperStyles
};
