import { useCallback } from 'react';
import SmartySDK, { usAutocompletePro, internationalAddressAutocomplete } from 'smartystreets-javascript-sdk';
import { config } from '../../../../../static/js/env.config';

export type AddressSearchType = usAutocompletePro.Lookup;
export type AddressResponseType = usAutocompletePro.Suggestion;

export type InternationalAddressSearchType = internationalAddressAutocomplete.Lookup;
export type InternationalAddressResponseType = internationalAddressAutocomplete.Suggestion;
export interface InternationalAddressResponseTypeV2 extends InternationalAddressResponseType {
  entries: number;
  addressText: string;
  addressId: string;
}

const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;
const InternationalLookup = SmartySDK.internationalAddressAutocomplete.Lookup;

const SMARTY_API_KEY = config.smartyApiKey;

const credentials = new SmartyCore.SharedCredentials(SMARTY_API_KEY || '');

const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
const client = clientBuilder.buildUsAutocompleteProClient();

const internationalClientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['international-autocomplete-v2-cloud']);
const internationalClient = internationalClientBuilder.buildInternationalAddressAutocompleteClient();

export const useAddressAutocomplete = () => {
  const handleAddressSearch = useCallback(async (lookup: AddressSearchType): Promise<ReadonlyArray<AddressResponseType>> => {
    try {
      const response = await client.send(lookup);
      return response.result;
    } catch (err) {
      return [];
    }
  }, []);

  const handleInternationalAddressSearch = useCallback(async (lookup: InternationalAddressSearchType): Promise<ReadonlyArray<InternationalAddressResponseType>> => {
    lookup.maxResults = 10;

    try {
      const response = await internationalClient.send(lookup);
      return response.result;
    } catch (err) {
      return [];
    }
  }, []);

  const handleInternationalAddressSearchV2 = useCallback(async lookup => {
    lookup.maxResults = 1;

    try {
      const preliminaryResponse = await internationalClient.send(lookup);
      const preliminaryResponseResult = preliminaryResponse?.result?.[0] as InternationalAddressResponseTypeV2;
      const addressId = preliminaryResponseResult.addressId;
      if (!addressId) {
        return [];
      }
      const response = await internationalClient.send({ ...lookup, addressId });
      return response.result;
    } catch (err) {
      return [];
    }
  }, []);

  return { Lookup, handleAddressSearch, InternationalLookup, handleInternationalAddressSearch, handleInternationalAddressSearchV2 };
};
