import { styled, Flex, LinkV1, TextArea } from '@withjoy/joykit';

export const TextAreaWrapper = styled.div`
  > div > div > div::after {
    border: none;
  }

  > * label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

export const StyledFlex = styled(Flex)<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? `
  flex-direction: column;
  > div {
    margin-bottom: 24px;
  }
`
      : undefined}
`;

export const StyledButtonFlex = styled(Flex)<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? `
  flex-direction: column-reverse;
  width: 100%;
  > * {
    margin-bottom: 24px;
  }
`
      : undefined}
`;

export const StyledLink = styled(LinkV1)`
  min-height: 48px;
  min-width: 132px;
  font-weight: 600;
`;

export const StyledTextArea = styled(TextArea)`
  -webkit-appearance: none;
  max-height: 60vh;

  &:focus {
    border-color: ${props => props.theme.colors.purple7};
  }
`;

export const StyledText = styled.span`
  font-weight: 600;
`;
