import React, { useState, useEffect } from 'react';
import { useGifterDetailsController } from './GifterDetails.controller';
import { Alert, ContentHeader, IntegratedGiftCard, LayoutShell, PrimaryCta } from '@apps/registry/guest/components/CheckoutDialog/components';
import { GifterDetailsSelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/GifterDetails/GifterDetails.selectors';
import { useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';
import { Box, SpacingStack, TextArea, TextV2 } from '@withjoy/joykit';
import { FormControl } from '@apps/registry/guest/components/CheckoutDialog/components/FormComponents/FormControl';
import { Input } from '@apps/registry/guest/components/CheckoutDialog/components/FormComponents/Input';
import { DefaultEmailField } from '@apps/registry/guest/components/CheckoutDialog/components/DefaultEmailField';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useRegistryHost } from '@apps/registry/guest/utils/useRegistryHost';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

export const GifterDetails = () => {
  const { value: registryGiftWrapCashOn } = useFeatureValue('registryGiftWrapCashEnabled');
  const { value: registryGiftWrapAffiliateOn } = useFeatureValue('registryGiftWrapAffiliateEnabled');
  const [shouldShowReservedMessage, setShouldShowReservedMessage] = useState(false);
  const { alreadyReservedGiftViewed } = useGuestRegistryTelemetry();
  const {
    errors,
    handleSubmit,
    getFieldProps,
    isDeferred,
    isCashFundType,
    translations,
    reserveItemTranslation,
    useDefaultEmail,
    userEmail,
    showCheckMailDialog,
    isAffiliate,
    formValues
  } = useGifterDetailsController();

  const host = useRegistryHost();
  const { orders, guest } = useGuestRegistryState();
  const { product, productImageSrc, currency } = useGetProductData('gifterDetails');

  const email = getFieldProps('email').value;

  const {
    title,
    cta,
    fieldNameLabel,
    fieldEmailLabel,
    fieldNoteLabel,
    fieldNamePlaceholder,
    fieldEmailPlaceholder,
    fieldNotePlaceholder,
    titleDeferred,
    warningText,
    description
  } = translations;

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    const existingOrder = email ? orders?.find(ord => ord.email === (email ?? guest?.email) && ord.lineItems.some(item => item.registryItem?.id === product?.id)) : undefined;
    if (existingOrder?.id) {
      setShouldShowReservedMessage(true);
    } else {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (shouldShowReservedMessage) {
      alreadyReservedGiftViewed({ label: email });
    }
  }, [alreadyReservedGiftViewed, shouldShowReservedMessage, email]);

  return (
    <LayoutShell {...GifterDetailsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement={isCashFundType ? 'float' : 'static'} />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} quantity={formValues.quantity} currency={currency} />
          ) : (
            <IntegratedGiftCard mobileRenderBehavior={isCashFundType ? 'fullBleed' : 'card'} />
          )}
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          {shouldShowReservedMessage ? (
            <Box>
              <TextV2 typographyVariant="hed4" paddingRight={9}>
                {reserveItemTranslation.title()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} paddingRight={9}>
                {reserveItemTranslation.subTitle()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} style={{ wordBreak: 'break-all' }}>
                {reserveItemTranslation.checkEmail({ email })}
              </TextV2>
            </Box>
          ) : (
            <>
              <ContentHeader>
                <ContentHeader.Hed>{isDeferred ? titleDeferred() : title()}</ContentHeader.Hed>
                {!isDeferred && <ContentHeader.Dek>{description({ host })}</ContentHeader.Dek>}
              </ContentHeader>
              <form onSubmit={handleSubmitForm}>
                <SpacingStack spacing={6} marginTop={8}>
                  <FormControl label={fieldNameLabel()} isInvalid={!!errors.name} error={errors.name}>
                    <Input {...GifterDetailsSelectors.NameInput} isInvalid={!!errors.name} placeholder={fieldNamePlaceholder()} {...getFieldProps('name')} />
                  </FormControl>
                  {useDefaultEmail ? (
                    <DefaultEmailField
                      defaultEmailFieldLabel={userEmail!}
                      changeFieldButtonLabel={reserveItemTranslation.changeEmail()}
                      onLinkClick={() => showCheckMailDialog(userEmail!)}
                    />
                  ) : (
                    <FormControl label={fieldEmailLabel()} isInvalid={!!errors.email} error={errors.email}>
                      <Input {...GifterDetailsSelectors.EmailInput} isInvalid={!!errors.email} placeholder={fieldEmailPlaceholder()} {...getFieldProps('email')} />
                    </FormControl>
                  )}
                  {!(registryGiftWrapCashOn === 'on' && isCashFundType) && !(registryGiftWrapAffiliateOn === 'on' && isAffiliate) && (
                    <FormControl label={fieldNoteLabel()} isInvalid={!!errors.note} error={errors.note}>
                      <TextArea {...GifterDetailsSelectors.NoteInput} placeholder={fieldNotePlaceholder()} resize={false} minRows={3} maxRows={3} {...getFieldProps('note')} />
                    </FormControl>
                  )}
                  {isDeferred && <Alert description={warningText()} />}
                </SpacingStack>
                <PrimaryCta {...GifterDetailsSelectors.SubmitButton} marginTop={7} fullWidth type="submit">
                  {cta()}
                </PrimaryCta>
              </form>
            </>
          )}
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
