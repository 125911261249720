import React from 'react';
import { FormikProps } from 'formik';
import { FormControl, TextV2, SelectV1, InputV2, Spinner } from '@withjoy/joykit';
import { DISABLE_ADDRESS_VALIDATION_KEY, useAddressAutocompleteFieldController } from './AddressAutocompleteField.controller';
import { ShippingAddressFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { AddressDialogFields, AddressUsedEnum } from '../AddressDialog/types';
import { StyledSelectWrapper, styles } from './AddressAutocompleteField.styles';

type AddressAutocompleteFieldProps = Readonly<{
  label: string;
  caption: string;
  placeholderError: string;
  placeholder?: string;
  registryShippingAddress: ShippingAddressFragment | null;
  formik: FormikProps<AddressDialogFields>;
  formikKey: string;
  isNationalAddress: boolean;
  countryDropdownValue: string;
  clearAddressPressed: boolean;
  setSuggestedAddress?: (value: AddressDialogFields | null) => void;
  onCallSuggestionApi?: (isNationalAddress: boolean) => void;
  setAddressUsed?: (addressUsed: AddressUsedEnum) => void;
  onDisableAddressSuggestions?: () => void;
}>;

export const AddressAutocompleteField: React.FC<AddressAutocompleteFieldProps> = props => {
  const { t } = useTranslation('yourRegistry');
  const { addressNotFound, firstOptionAddress1Field, lastOptionAddress1Field, addressWithMoreThanOneSuggestion } = t('addressAutocompleteField');
  const { required } = t('validation');

  const {
    label,
    placeholder = '',
    placeholderError,
    caption,
    registryShippingAddress,
    formik,
    formikKey,
    isNationalAddress,
    countryDropdownValue,
    clearAddressPressed,
    setSuggestedAddress,
    onCallSuggestionApi,
    setAddressUsed,
    onDisableAddressSuggestions
  } = props;
  const {
    error,
    isInvalid,
    address1FieldRef,
    showAddressLoading,
    showAddress1DropdownSuggestionList,
    isAddressValidationEnabled,
    addressSuggestionList,
    address1Dropdown,
    handleAddressSelection,
    handleAddressInputChange
  } = useAddressAutocompleteFieldController({
    addressWithMoreThanOneSuggestion,
    firstOptionAddress1Field,
    lastOptionAddress1Field,
    required,
    placeholderError,
    registryShippingAddress,
    formik,
    formikKey,
    isNationalAddress,
    countryDropdownValue,
    clearAddressPressed,
    setSuggestedAddress,
    onCallSuggestionApi,
    setAddressUsed,
    onDisableAddressSuggestions
  });

  const address1FieldOptions = addressSuggestionList.length
    ? addressSuggestionList
    : [
        {
          value: '-1',
          disabled: true,
          label: addressNotFound()
        },
        {
          value: DISABLE_ADDRESS_VALIDATION_KEY,
          label: lastOptionAddress1Field()
        }
      ];

  return (
    <FormControl
      label={label}
      caption={
        <TextV2 typographyVariant="label2" color="mono10">
          {caption}
        </TextV2>
      }
      isInvalid={isInvalid}
      error={error}
      isRequired
    >
      {isAddressValidationEnabled ? (
        <StyledSelectWrapper __css={styles.selectWrapper({ isVisible: showAddress1DropdownSuggestionList })}>
          <SelectV1
            ref={address1FieldRef}
            iconRight={<></>}
            optionFilter={() => true}
            closeMenuOnSelect={false}
            loading={showAddressLoading}
            options={address1FieldOptions}
            onChange={handleAddressSelection}
            onInputChange={handleAddressInputChange}
            value={address1Dropdown.label || address1Dropdown.value ? address1Dropdown : null}
            loadingMessage={() => <Spinner />}
            isInvalid={isInvalid}
            autoComplete="none"
            placeholder={placeholder}
          />
        </StyledSelectWrapper>
      ) : (
        <InputV2 {...formik.getFieldProps(formikKey)} placeholder={placeholder} />
      )}
    </FormControl>
  );
};
