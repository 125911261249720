import React from 'react';
import { CheckFilled } from '@withjoy/joykit/icons';
import { dataAttr } from '@shared/utils/dom';
import {
  PaymentMethodSelectorCheck,
  PaymentMethodSelectorInput,
  PaymentMethodSelectorInputContainer,
  PaymentMethodSelectorLabel,
  PaymentMethodSelectorRoot,
  styles
} from './PaymentMethodSelector.styles';
import { DonationFundPaymentMethodEnum } from '@graphql/generated';
import { PaymentMethodSelectorSelectors } from './PaymentMethodSelector.selectors';

interface PaymentMethodSelectorProps {
  options: Array<{ label: string; value: DonationFundPaymentMethodEnum; disabled?: boolean }>;
  value: Maybe<DonationFundPaymentMethodEnum>;
  onChange: (nextValue: DonationFundPaymentMethodEnum) => void;
  disabled?: boolean;
}

export const PaymentMethodSelector = (props: PaymentMethodSelectorProps) => {
  const { options, value, onChange } = props;

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) return;
    onChange(e.target.value as DonationFundPaymentMethodEnum);
  };

  return (
    <PaymentMethodSelectorRoot as="fieldset" border={0} {...PaymentMethodSelectorSelectors.OptionsContainerElement}>
      {options.map(option => {
        const checked = option.value === value;
        return (
          <PaymentMethodSelectorInputContainer
            key={option.value}
            __css={styles.paymentMethodSelectorInputContainer}
            data-checked={dataAttr(checked)}
            data-disabled={dataAttr(option.disabled)}
            data-readonly={Boolean(props.disabled)}
            role="group"
            onClick={() => {
              if (props.disabled) return;
              onChange(option.value);
            }}
          >
            <PaymentMethodSelectorCheck __css={styles.paymentMethodSelectorCheck}>
              <CheckFilled />
            </PaymentMethodSelectorCheck>
            <PaymentMethodSelectorInput
              {...PaymentMethodSelectorSelectors[`OptionFor${option.value}Radio`]}
              as="input"
              type="radio"
              id={option.value}
              value={option.value}
              checked={checked}
              data-checked={dataAttr(checked)}
              disabled={option.disabled}
              data-disabled={dataAttr(option.disabled)}
              onChange={handleValueChange}
              __css={styles.paymentMethodSelectorInput}
            />
            <PaymentMethodSelectorLabel
              {...PaymentMethodSelectorSelectors[`OptionFor${option.value}Text`]}
              as="label"
              htmlFor={option.value}
              __css={styles.paymentMethodSelectorLabel}
            >
              {option.label}
            </PaymentMethodSelectorLabel>
          </PaymentMethodSelectorInputContainer>
        );
      })}
    </PaymentMethodSelectorRoot>
  );
};
