import React from 'react';

import { TextV2 } from '@withjoy/joykit';

import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';

import { StyledFlex } from '../ReservedOrder/ReservedItemV2/ReservedItemV2.styles';
import { ItemBase, ItemBaseVariant } from '../../../../../../../components';

type InReceiptItemProps = Readonly<{
  product: Partial<CookedProduct>;
  quantity: number;
  totalPrice: string;
  price: string;
  isDonationFundItem: boolean;
  siteName?: string | null;
  compact?: true;
  variant?: ItemBaseVariant;
  sm?: boolean;
}>;

export const InReceiptItem: React.FC<InReceiptItemProps> = ({ product, quantity, price, totalPrice, isDonationFundItem, siteName, compact, variant, sm }) => {
  const css = compact ? { border: 'none' } : {};

  return (
    <ItemBase registryItemId={product.registryItemId} product={product} siteName={siteName} __css={css} variant={variant}>
      <StyledFlex>
        {!isDonationFundItem && <TextV2 typographyVariant={sm ? 'label2' : 'body1'}>{`${quantity} x ${price}`}</TextV2>}
        <TextV2 textAlign="center" typographyVariant={sm ? 'label5' : 'hed1'}>
          {totalPrice}
        </TextV2>
      </StyledFlex>
    </ItemBase>
  );
};
