import { ButtonV2, styled, css, Box, StyleSystemProps } from '@withjoy/joykit';
import { OptionSelectDown } from '@withjoy/joykit/icons';

export const boxStyles: StyleSystemProps = {
  marginTop: 8,
  paddingX: 5,
  display: 'flex',
  alignContent: 'center',
  flexDirection: 'column'
};

export const StyledBox = styled(Box)``;

export const StyledExpandArrow = styled(OptionSelectDown)`
  transition: transform 200ms ease-in-out;
`;

export const StyledButton = styled(ButtonV2)<{ $show: boolean }>`
  ${({ $show }) =>
    !!$show &&
    css`
      ${StyledExpandArrow} {
        transform: rotate(180deg);
      }
    `}
`;
