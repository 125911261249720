import { styled, css } from '@withjoy/joykit';

export const Breadcrumb = styled.button<{ canClick: boolean; isActive?: boolean }>`
  cursor: default;
  color: ${props => (props.isActive ? props.theme.colors.mono14 : props.theme.colors.gray8)};
  ${props =>
    (props.canClick || props.isActive) &&
    css`
      cursor: pointer;
      &:hover {
        color: ${props.theme.colors.linkHover};
      }
      &:focus {
        color: ${props.theme.colors.linkActive};
      }
    `};
`;
