import { pageSelectorGenerator } from '@test/pageSelectors';
import { ExtraPaymentMethodOptions } from '../../CheckoutDialog.types';

export const PaymentMethodSelectorSelectors = pageSelectorGenerator('PaymentMethodSelector', [
  'OptionsContainerElement',
  `OptionFor${ExtraPaymentMethodOptions.cashOrCheck.value}Radio`,
  `OptionFor${ExtraPaymentMethodOptions.cashOrCheck.value}Text`,
  `OptionFor${ExtraPaymentMethodOptions.cashapp.value}Radio`,
  `OptionFor${ExtraPaymentMethodOptions.cashapp.value}Text`,
  `OptionFor${ExtraPaymentMethodOptions.venmo.value}Radio`,
  `OptionFor${ExtraPaymentMethodOptions.venmo.value}Text`,
  `OptionFor${ExtraPaymentMethodOptions.paypal.value}Radio`,
  `OptionFor${ExtraPaymentMethodOptions.paypal.value}Text`
]);
