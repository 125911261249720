import React from 'react';
import { TextV2, Box, ButtonV2 } from '@withjoy/joykit';
import { StyledPaymentErrorScreenBox, StyledPaymentErrorScreenWrapper, styles } from './PaymentErrorScreen.styles';

type PaymentStepProps = {
  title?: string;
  text?: string;
  action?: string;
  handleAction?: () => void;
  icon?: React.ReactNode;
};

export const PaymentErrorScreen = ({ title, text, action, handleAction, icon }: PaymentStepProps) => (
  <StyledPaymentErrorScreenWrapper __css={styles.paymentErrorScreenWrapper}>
    <StyledPaymentErrorScreenBox __css={styles.paymentErrorScreenBox}>
      <Box marginBottom={7}>{icon}</Box>
      {title && (
        <TextV2 typographyVariant="hed6" marginBottom={5} textAlign="center">
          {title}
        </TextV2>
      )}
      {text && (
        <TextV2 typographyVariant="body1" marginBottom={7} textAlign="center">
          {text}
        </TextV2>
      )}
      {action && (
        <ButtonV2 intent="neutral" onClick={handleAction}>
          {action}
        </ButtonV2>
      )}
    </StyledPaymentErrorScreenBox>
  </StyledPaymentErrorScreenWrapper>
);
