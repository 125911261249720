import React from 'react';
import { DialogV2, TextV2, SpacingStack, Box, FormControl, InputV2, ButtonV2, Flex, OnCloseArg } from '@withjoy/joykit';
import { CheckCircleIcon } from '@assets/icons';
import { useAddOrderTrackingInfoController } from './AddOrderTrackingInfo.controller';
import { ToastVariableProps } from '../../GuestRegistry.controller';
import { useRegistryHost } from '@apps/registry/guest/utils/useRegistryHost';

export interface AddOrderTrackingDialogProps
  extends Readonly<{
    retailer: string;
    orderId: string;
    isOpen: boolean;
    onClose: (args: OnCloseArg) => void;
    openToast: (toast: ToastVariableProps) => void;
    giftName?: string;
  }> {}

/**
 * When `Dialog`s are not open, its children are completely unmounted.
 * This separate internal component allows us to safely initialize/tear down the state.
 */
const DialogInternal: React.FC<AddOrderTrackingDialogProps> = ({ retailer, onClose, orderId, openToast, giftName }) => {
  const host = useRegistryHost();
  const { translations, formik, handleOnCancelReservation, loading, handleOnAddLater } = useAddOrderTrackingInfoController({ onClose, orderId, openToast, giftName });
  const {
    orderTrackingDialogTitle,
    cancelReservation,
    orderTrackingDialogSubTitle,
    submit,
    addLater,
    orderTrackingDialogDetail,
    didntPurchase,
    orderNumberPlaceholder
  } = translations;

  return (
    <DialogV2.Body>
      <SpacingStack spacing={6} paddingX={2}>
        <Flex justifyContent="center" marginTop={2}>
          <CheckCircleIcon />
        </Flex>
        <TextV2 typographyVariant="hed4" textAlign="center">
          {orderTrackingDialogTitle()}
        </TextV2>
        <Box paddingX={6}>
          <SpacingStack spacing={6}>
            <TextV2 typographyVariant="body1" paddingX={4} textAlign="center">
              {orderTrackingDialogSubTitle({ host })}
            </TextV2>
            <form onSubmit={formik.handleSubmit}>
              <SpacingStack spacing={4}>
                <FormControl
                  error={
                    <TextV2 typographyVariant="label2" marginTop={2} color="negative6" hidden={!formik.getFieldMeta('orderNumber').error}>
                      {formik.getFieldMeta('orderNumber').error}
                    </TextV2>
                  }
                  isInvalid={!!formik.getFieldMeta('orderNumber').error}
                >
                  <InputV2 {...formik.getFieldProps('orderNumber')} placeholder={orderNumberPlaceholder()} />
                </FormControl>
                <ButtonV2 intent="productive" type="submit" loading={loading}>
                  {submit()}
                </ButtonV2>
                <ButtonV2 intent="productive" variant="outline" onClick={handleOnAddLater}>
                  {addLater()}
                </ButtonV2>
              </SpacingStack>
            </form>
            <TextV2 typographyVariant="label2" textAlign="center">
              {orderTrackingDialogDetail({ retailer })}
            </TextV2>
          </SpacingStack>
        </Box>
        <Box textAlign="center">
          <TextV2 typographyVariant="body3">{didntPurchase()}</TextV2>
          <ButtonV2 intent="productive" variant="ghost" onClick={handleOnCancelReservation}>
            {cancelReservation()}
          </ButtonV2>
        </Box>
      </SpacingStack>
    </DialogV2.Body>
  );
};

export const AddOrderTrackingDialog: React.FC<AddOrderTrackingDialogProps> = props => {
  const { isOpen, onClose } = props;

  return (
    <DialogV2 id="AddOrderTrackingDialog" onClose={onClose} isOpen={isOpen} size="lg">
      <DialogInternal {...props} />
    </DialogV2>
  );
};
