import { CSSObject, StyleSystemProps } from '@withjoy/joykit/components/Box/Box.types';
import { pxToRem } from '@withjoy/joykit/theme';

const dialogDetailsWrapper: CSSObject = {
  ['& > *:not(:first-child)']: {
    marginTop: 9
  }
};

const detailsAccordionContainerStyles: StyleSystemProps = {
  maxWidth: { _: '100%', sm2: pxToRem(664) },
  paddingX: {
    _: 6,
    sm2: 0
  },
  paddingBottom: pxToRem(96)
};

const detailsAccordionStyles: StyleSystemProps = {
  paddingX: {
    _: 0,
    sm2: 9
  }
};

export const styles = {
  dialogDetailsWrapper: dialogDetailsWrapper,
  detailsAccordionContainer: detailsAccordionContainerStyles,
  detailsAccordionStyles: detailsAccordionStyles
};
