import { styled, TextArea, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const EditGiftMessage = styled(TextV2)`
  font-family: 'Noto Serif', serif;
  font-size: ${pxToRem(14)};
  line-height: 160%;
  text-align: left;
  white-space: pre-line;
`;

export const StyledTextArea = styled(TextArea)`
  font-family: 'Noto Serif', serif;
  font-size: ${pxToRem(14)};
  line-height: 160%;
  text-align: left;
  white-space: pre-line;
`;
