import React, { useCallback, useMemo, useState } from 'react';
import { CookedProduct, ItemWithPrice } from '@apps/registry/common/selectors/ProductListSelector';
import { EmptyFilterState } from '..';
import { SelectWrapper, StyledGridContainer, StyledSelect, styles } from './ProductListView.styles';
import { Flex, SelectV1, TextV2 } from '@withjoy/joykit';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useTranslation } from '@shared/core';
import { isProductStillNeeded, prioritizeCatalogItems } from './helpers';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { ProductTile } from '@apps/registry/guest/routes/GuestRegistry/components/ProductTile/ProductTile';
import { pxToRem } from '@withjoy/joykit/theme';
import { get } from 'lodash-es';

type Props = {
  productList: Array<CookedProduct>;
  handleClickProduct: (product: CookedProduct, isReservedByYou?: boolean, isPurchasedByYou?: boolean) => void;
  reservedOrderList: Array<Maybe<string>>;
  purchasedOrderList: Array<Maybe<string>>;
  isShowCartSection?: boolean;
};

enum SortByOptions {
  None = 'None',
  PriceLowToHigh = 'PriceLowToHigh',
  PriceHighToLow = 'PriceHighToLow'
}

export const ProductListView = ({ productList, handleClickProduct, reservedOrderList, purchasedOrderList, isShowCartSection }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet();

  const [selectedSort, setSelectedSort] = useState<SortByOptions>(SortByOptions.None);

  const { sortByOptionClickedByGuest } = useGuestRegistryTelemetry();

  const { t2 } = useTranslation('guestRegistry');
  const { alreadyGifted } = t2('pageTitle');
  const { sortBy: sortByLabel, featured, priceLowToHigh, priceHighToLow } = t2('sortByOptions');

  const productListPrioritized = prioritizeCatalogItems([...productList]);

  const SortByOptionsMap: Record<SortByOptions, { value: 'fpIndex' | 'numberPrice'; label: string; order: 'asc' | 'desc' }> = useMemo(
    () => ({
      [SortByOptions.None]: {
        label: featured,
        order: 'asc',
        value: 'fpIndex'
      },
      [SortByOptions.PriceLowToHigh]: {
        label: priceLowToHigh,
        order: 'asc',
        value: 'numberPrice'
      },
      [SortByOptions.PriceHighToLow]: {
        label: priceHighToLow,
        order: 'desc',
        value: 'numberPrice'
      }
    }),
    [featured, priceHighToLow, priceLowToHigh]
  );
  const selectedSortOption = SortByOptionsMap[selectedSort ?? SortByOptions.None];

  const compareProducts = useCallback(
    (product1: CookedProduct, product2: CookedProduct) => {
      const selectedSortKey = selectedSortOption.value;
      const product1Price = get(product1, selectedSortKey, 0);
      const product2Price = get(product2, selectedSortKey, 0);
      return !selectedSort || SortByOptionsMap[selectedSort].order === 'asc' ? product1Price - product2Price : product2Price - product1Price;
    },
    [SortByOptionsMap, selectedSort, selectedSortOption.value]
  );

  const productListSorted = !selectedSort || selectedSort === SortByOptions.None ? productListPrioritized : [...productListPrioritized].sort(compareProducts);

  const visibleProductList = useMemo(() => {
    return productListSorted.reduce(
      (acc, product) => {
        if (product.isHidden) {
          return acc;
        }

        if (isProductStillNeeded(product)) {
          return { ...acc, available: [...acc.available, product] };
        }

        return { ...acc, unavailable: [...acc.unavailable, product] };
      },
      { available: [] as CookedProduct[], unavailable: [] as CookedProduct[] }
    );
  }, [productListSorted]);

  const renderProductTile = useCallback(
    (product: CookedProduct) => {
      return (
        <Flex justifyContent={'center'} key={product.id} position="relative">
          <ProductTile product={product as ItemWithPrice} handleClickProduct={handleClickProduct} reservedOrderList={reservedOrderList} purchasedOrderList={purchasedOrderList} />
        </Flex>
      );
    },
    [handleClickProduct, purchasedOrderList, reservedOrderList]
  );

  const renderAvailableProducts = useCallback(() => {
    return visibleProductList.available.length === 0 ? (
      <EmptyFilterState />
    ) : (
      <StyledGridContainer numberOfItems={visibleProductList.available.length} __css={styles.nonZeroProductListStyles} width="100%">
        {visibleProductList.available.map(product => {
          return renderProductTile(product);
        })}
      </StyledGridContainer>
    );
  }, [renderProductTile, visibleProductList.available]);

  const renderUnavailableProducts = useCallback(() => {
    return (
      <>
        <TextV2 typographyVariant="hed4" marginTop={pxToRem(128)} marginBottom={10} textAlign={isMobileOrTablet ? 'center' : 'initial'}>
          {alreadyGifted}
        </TextV2>
        <StyledGridContainer numberOfItems={visibleProductList.unavailable.length} __css={styles.nonZeroProductListStyles} width="100%">
          {visibleProductList.unavailable.map(product => {
            return renderProductTile(product);
          })}
        </StyledGridContainer>
      </>
    );
  }, [alreadyGifted, isMobileOrTablet, renderProductTile, visibleProductList.unavailable]);

  const handleSortSelect = (value: SortByOptions) => {
    setSelectedSort(value);
    sortByOptionClickedByGuest({ sortOption: SortByOptionsMap[value].label });
  };

  return (
    <>
      <Flex justifyContent="end" marginBottom={10}>
        {isMobileOrTablet ? (
          <StyledSelect
            id="sort"
            value={selectedSort}
            onBlur={event => handleSortSelect(event.target.value as SortByOptions)}
            onChange={event => handleSortSelect(event.target.value as SortByOptions)}
            style={styles.selectStyles}
          >
            {Object.entries(SortByOptionsMap).map(([value, { label }]) => {
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            })}
          </StyledSelect>
        ) : (
          <SelectWrapper __css={styles.selectWrapperStyles}>
            <SelectV1
              options={Object.entries(SortByOptionsMap).map(([value, { label }]) => {
                return { label, value };
              })}
              value={{ value: selectedSort ?? SortByOptions.None, label: `${sortByLabel}${selectedSort ? `: ${selectedSortOption.label}` : ''}` }}
              onChange={option => {
                setSelectedSort(option?.value as SortByOptions);
                sortByOptionClickedByGuest({ sortOption: option?.label });
              }}
              searchable={false}
              noBorder
              noBackground
              defaultValue={{ value: SortByOptions.None, label: sortByLabel }}
            />
          </SelectWrapper>
        )}
      </Flex>
      {renderAvailableProducts()}
      {!!visibleProductList.unavailable?.length && renderUnavailableProducts()}
    </>
  );
};

ProductListView.displayName = 'ProductListView';
