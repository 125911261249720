import { useCallback, useMemo } from 'react';
import { useTranslation } from '@shared/core';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { CustomItemDataType, DonationFundPlatformTypeEnum } from '@graphql/generated';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { useCheckoutFunnel, useCheckoutTelemetryData, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { addAction } from '@shared/utils/logger';

export const useExternalCheckoutInstructionsController = () => {
  const {
    formValues,
    toNext,
    context: { isAffiliate, isGroupGifting }
  } = useCheckoutFunnel(({ toNext, formValues, context }) => [toNext, formValues, context.isAffiliate, context.isGroupGifting]);
  const { product, productImageSrc, siteName, currency } = useGetProductData('externalCheckoutInstructions');

  const { registryItem, order } = useCheckoutInitDataContext();
  const checkoutDialogContext = useCheckoutDialogContext();
  const isMobile = useIsMobileScreen();
  const { reserveAndPurchase } = useGuestRegistryTelemetry();
  const { formatCurrency } = useCurrencyFormatter();
  const { t } = useTranslation('guestRegistry');

  const { title, alertTitle, alertText, alertTextAffiliate, steps, gotItButton, copyButton } = t('checkoutDialog', 'screens', 'externalCheckoutInstructions');
  const platformType = formValues.paymentMethod || registryItem?.donationFund?.platform?.type || order?.platformType || DonationFundPlatformTypeEnum.other;
  const isCharity = registryItem?.donationFund?.fundType === CustomItemDataType.charity;

  const formattedAmount = formatCurrency({
    priceFloatingPointDecimalString: `${formValues.amount ?? order?.amount?.floatingPointDecimalString}`,
    priceCurrencyCode: isGroupGifting
      ? DEFAULT_CURRENCY
      : registryItem?.donationFund?.currency?.code || checkoutDialogContext.registryState?.registryCurrencyCode || DEFAULT_CURRENCY,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });

  const affiliateStepsTranslations = {
    step1: steps.step1.affiliate({ retailer: siteName }),
    step2: steps.step2.affiliate({ retailer: siteName }),
    step3: steps.step3.affiliate()
  };

  const cashFundStepsTranslations = useMemo(() => {
    switch (platformType) {
      case DonationFundPlatformTypeEnum.venmo:
        return {
          step1: isMobile ? steps.step1.venmo.mobile() : steps.step1.venmo.desktop(),
          step2: steps.step2.venmo({ couplesAccount: registryItem?.donationFund?.platform?.username }),
          step3: steps.step3.venmo({ amount: formattedAmount })
        };

      case DonationFundPlatformTypeEnum.paypal:
        return {
          step1: steps.step1.paypal(),
          step2: steps.step2.paypal({ couplesAccount: registryItem?.donationFund?.platform?.username }),
          step3: steps.step3.paypal({ amount: formattedAmount })
        };

      case DonationFundPlatformTypeEnum.cashapp:
        return {
          step1: steps.step1.cashapp(),
          step2: steps.step2.cashapp(),
          step3: steps.step3.cashapp()
        };

      case DonationFundPlatformTypeEnum.other:
        return isCharity
          ? {
              step1: steps.step1.charity(),
              step2: steps.step2.charity({ amount: formattedAmount }),
              step3: steps.step3.charity()
            }
          : {};

      default:
        return {};
    }
  }, [platformType, steps.step1, steps.step2, steps.step3, isMobile, registryItem?.donationFund?.platform?.username, formattedAmount, isCharity]);

  const stepsTranslations = isAffiliate ? affiliateStepsTranslations : cashFundStepsTranslations;

  const {
    eventId,
    registryItemId,
    reservedQty,
    priceValueInMinorUnits,
    typeOfItem,
    productId,
    priceCurrencyCode,
    registryId,
    destinationUrl,
    productTitle
  } = useCheckoutTelemetryData();

  const handleGotItButton = useCallback(() => {
    const telemetryData = {
      eventId,
      productId,
      registryItemId,
      reservedQty,
      priceValueInMinorUnits,
      priceCurrencyCode,
      destinationUrl,
      typeOfItem,
      buttonLabel: gotItButton(),
      productTitle,
      registryId,
      giftGiverName: formValues.name,
      giftGiverEmail: formValues.email,
      isGroupGiftingEnabled: isGroupGifting
    };
    reserveAndPurchase(telemetryData);
    addAction('reserveAndPurchase', telemetryData);
    toNext();
  }, [
    destinationUrl,
    eventId,
    formValues.email,
    formValues.name,
    gotItButton,
    isGroupGifting,
    priceCurrencyCode,
    priceValueInMinorUnits,
    productId,
    productTitle,
    registryId,
    registryItemId,
    reserveAndPurchase,
    reservedQty,
    toNext,
    typeOfItem
  ]);

  return {
    registryItem,
    handleToNext: handleGotItButton,
    translations: {
      title,
      alertTitle: isAffiliate ? undefined : alertTitle,
      alertText: isAffiliate ? alertTextAffiliate : alertText,
      ...stepsTranslations,
      gotItButton,
      copyButton
    },
    product,
    productImageSrc,
    isAffiliate,
    formValues,
    currency
  };
};
