import { Flex } from '@withjoy/joykit';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { EGiftWrapType, IGiftWrapTheme } from '../GiftWrapTypes';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ThemeThumbnail, ExpandArrow, ExpandPlus } from './GiftWrap.styles';
import { useTranslation } from '@shared/core';

interface ThemePickerProps {
  themes: IGiftWrapTheme[] | undefined;
  randomize?: boolean;
  onThemeSelect?: (theme: IGiftWrapTheme, themeOrder: string[]) => void;
  onViewMoreThemesClick?: (show: boolean) => void;
}

interface ThemeCountPayload {
  visibleCount: number;
}
/*
 * Type predicate function to validate the registryGiftWrapEcardPricePayload remote feature flag payload.
 * @param {any} payload - The payload to validate.
 * @returns {boolean}
 */
export const isThemeCountPayload = (payload: unknown): payload is ThemeCountPayload => {
  return !!(payload && typeof payload === 'object' && 'visibleCount' in payload && typeof payload.visibleCount === 'number' && payload.visibleCount >= 1);
};

const filterThemes = (arrToRandomize: Array<IGiftWrapTheme>, maxToShow: number, premiumEnabled: boolean, randomize: boolean) => {
  let arr = [...arrToRandomize];
  if (!premiumEnabled) {
    // Filter out premium options when not enabled to get accurate count of available options
    // This helps ensure the while loop does not go on forever
    arr = arr.filter(theme => theme?.type !== EGiftWrapType.PREMIUM);
  }
  let filteredThemeList = [];
  const maxItemLength = maxToShow <= arr.length ? maxToShow : arr.length;
  let iterationCount = 0;
  // Hard limit to prevent infinite loop
  const maxIterations = arr.length * 3;
  if (randomize) {
    while (arr.length > 0 && filteredThemeList.length < maxItemLength && iterationCount < maxIterations) {
      iterationCount += 1;
      const randomIndex = Math.floor(Math.random() * arr.length);
      const currentTheme = arr[randomIndex];

      if (filteredThemeList.length === 0) {
        // The first theme should be a theme with allowAsFirst set to true
        if (!currentTheme?.allowAsFirst) {
          continue;
        }
      }

      filteredThemeList.push(currentTheme);
      arr.splice(randomIndex, 1);
    }
  } else {
    filteredThemeList = arr.splice(0, maxItemLength);
  }

  return filteredThemeList;
};

const THEMES_PER_ROW = 5;
const DEFAULT_VISIBLE_THEME_COUNT = 5;

export const ThemePicker: React.FC<ThemePickerProps> = ({ themes, onThemeSelect, randomize = false, onViewMoreThemesClick }) => {
  const [showMore, setShowMore] = useState(false);
  const [orderedThemeList, setOrderedThemeList] = useState<IGiftWrapTheme[] | undefined>(themes);
  const [selectedTheme, setSelectedTheme] = useState<IGiftWrapTheme | undefined>();
  const pickerRef = useRef<HTMLDivElement>(null);
  const themeOrderRef = useRef<string[]>([]);
  const defaultThemeSet = useRef(false);
  const { payload: themeCountPayload } = useFeatureValue('registryGiftWrapThemeDisplayCount');
  const { value: premiumEnabled } = useFeatureValue('registryGiftWrapPremiumEnabled');
  const { value: expandIconValue } = useFeatureValue('registryGiftWrapThemeExpandIcon');
  const visibleThemeCount = isThemeCountPayload(themeCountPayload) ? themeCountPayload.visibleCount : DEFAULT_VISIBLE_THEME_COUNT;
  const { t } = useTranslation('guestRegistry');
  const tGiftCard = t('checkoutGiftCard');
  const COLLAPSE_TEXT = tGiftCard.collapseThemeList();
  const EXPAND_TEXT = tGiftCard.expandThemeList();

  // This allows picker to collapse when click outside occurs
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setShowMore(false);
      }
    };
    if (pickerRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  useMemo(() => {
    if (themes) {
      const newOrder = filterThemes(themes, visibleThemeCount, premiumEnabled === 'on', randomize);
      const newOrderIdList = newOrder.map(theme => theme.id);
      themeOrderRef.current = newOrderIdList;
      setOrderedThemeList(newOrder);
      setSelectedTheme(newOrder[0]);
    }
  }, [themes, randomize, visibleThemeCount, premiumEnabled]);

  useEffect(() => {
    if (!defaultThemeSet.current && selectedTheme) {
      onThemeSelect?.(selectedTheme, themeOrderRef.current);
      defaultThemeSet.current = true;
    }
  }, [selectedTheme, onThemeSelect]);

  const handleThemeSelectInternal = (theme: IGiftWrapTheme) => {
    setSelectedTheme(theme);
    onThemeSelect?.(theme, themeOrderRef.current);
  };

  const handleShowMoreClick = (show: boolean) => {
    setShowMore(show);
    onViewMoreThemesClick?.(show);
  };

  const expansionButton = () =>
    expandIconValue === 'chevron' ? (
      <ExpandArrow title={showMore ? COLLAPSE_TEXT : EXPAND_TEXT} isOpen={showMore} onClick={() => handleShowMoreClick(!showMore)} />
    ) : (
      <ExpandPlus title={showMore ? COLLAPSE_TEXT : EXPAND_TEXT} isOpen={showMore} onClick={() => handleShowMoreClick(!showMore)} />
    );

  const showExpansionButton = orderedThemeList && orderedThemeList?.length > THEMES_PER_ROW;

  const visibleThemes = !showMore && orderedThemeList && orderedThemeList?.length > THEMES_PER_ROW ? orderedThemeList?.slice(0, THEMES_PER_ROW) : orderedThemeList;
  return (
    <Flex flexDirection={'column'}>
      <Flex marginTop={3} marginBottom={3} width={'100%'} background={'white'} maxWidth={'300px'} flexWrap={'wrap'} justifyContent={'space-evenly'} ref={pickerRef}>
        {visibleThemes?.map(theme => (
          <ThemeThumbnail
            key={theme.id}
            width={52}
            height={72}
            image={theme.thumbnail}
            isActive={selectedTheme?.id === theme.id}
            onClick={() => {
              handleThemeSelectInternal(theme);
            }}
          />
        ))}
      </Flex>
      {showExpansionButton && expansionButton()}
    </Flex>
  );
};
