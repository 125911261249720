import { useMemo } from 'react';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';

export const useGetCatalogItemId = (product: CookedProduct) => {
  return useMemo(() => {
    // Since we don't receive catalog item id - need to retrieve it from pdpUrl
    const pattern = /shop\/item\/(.+)/;
    const matches = product.pdpUrl.match(pattern);

    return matches?.length && matches[1] ? matches[1] : undefined;
  }, [product.pdpUrl]);
};
