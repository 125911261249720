import { EventType } from '@graphql/generated';
import { useTranslation } from '@shared/core/i18n';
import { useEventInfo } from '@shared/utils/eventInfo';

export const useSharedRegistryTranslations = () => {
  const { eventInfo } = useEventInfo();
  const { t, t2 } = useTranslation('sharedRegistry');

  const pageNoteTranslations = t('pageNote');
  const getPageNoteTranslations = () => {
    return {
      title: pageNoteTranslations.title(),
      subtitle: pageNoteTranslations.subtitle(),
      inputPlaceholder: pageNoteTranslations.inputPlaceholder(),
      maxCharacters: pageNoteTranslations.maxCharacters(),
      cancel: pageNoteTranslations.cancel(),
      save: pageNoteTranslations.save()
    };
  };

  const getCashFundDialogTranslations = () => {
    return t2('cashFundDialog', eventInfo?.eventType === EventType.babyRegistry ? 'babyRegistry' : 'wedding');
  };

  const getMustHaveTranslations = () => {
    const { markGiftAs, mostWanted, mostWantedDescription } = t2('giftDialog');
    return { markGiftAs, mostWanted, mostWantedDescription };
  };
  const departmentNavBarTranslations = t('departmentNavBar');
  const getDepartmentNavBarTranslations = () => {
    return {
      navItemTooltip: (departmentName: string) => departmentNavBarTranslations.navItemTooltip({ departmentName })
    };
  };

  return {
    getPageNoteTranslations,
    getCashFundDialogTranslations,
    getDepartmentNavBarTranslations,
    getMustHaveTranslations
  };
};
