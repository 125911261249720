import { createContext, useContext } from 'react';
import { AdminRegistryControllerState } from './AdminRegistry.provider';

export const AdminRegistryStateContext = createContext<AdminRegistryControllerState | undefined>(undefined);

export const useAdminRegistryState = () => {
  const context = useContext(AdminRegistryStateContext);
  if (context === undefined) {
    throw new Error('useAdminRegistryState must be used within a AdminRegistryProvider');
  }
  return context;
};
