import React from 'react';
import { TextV2, Flex, InputV2, TextArea, LinkV2, ButtonV2 } from '@withjoy/joykit';
import { usePurchaseDialogController } from '../../../../PurchaseDialog.controller';
import { useSpring, animated } from 'react-spring';
import { usePurchaseDialogTranslations } from '../../../../hooks';
import { StyledPurchasableMessage } from '../ProductInformation/ProductInformation.styles';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { useRegistryHost } from '@apps/registry/guest/utils/useRegistryHost';

interface GuestPurchaserDetailsProps {
  mySessionEmail: Maybe<string>;
  formik: ReturnType<typeof usePurchaseDialogController>['formik'];
  isLoading: boolean;
  handleOnAddDetails: () => void;
  handleOnChangeEmail: ReturnType<typeof usePurchaseDialogController>['handleOnChangeEmail'];
  isIn: boolean;
  isPurchasable: boolean;
  purchaseErrorContent: string | null;
  isExperimentNewFlowOn: boolean;
}

export const GuestPurchaserDetails = ({
  mySessionEmail,
  formik,
  isLoading,
  handleOnAddDetails,
  handleOnChangeEmail,
  isIn,
  isPurchasable,
  purchaseErrorContent,
  isExperimentNewFlowOn
}: GuestPurchaserDetailsProps) => {
  const {
    checkoutTitle,
    checkoutDescription,
    checkoutCta,
    fieldNameLabel,
    fieldNamePlaceholder,
    fieldEmailLabel,
    changeEmail,
    fieldEmailPlaceholder,
    fieldNoteLabel,
    fieldNotePlaceholder,
    outOfStock
  } = usePurchaseDialogTranslations();
  const host = useRegistryHost();

  const styleProps = useSpring({
    opacity: isIn ? 1 : 0,
    display: isIn ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    config: { duration: isIn ? 350 : 250, easing: cubicBezierEasingFn }
    // fadeIn: 350 milliseconds | fadeOut: 250 milliseconds
  });

  return (
    <animated.div style={styleProps}>
      <TextV2 typographyVariant="hed5" marginBottom={5} paddingTop={[6, 0]}>
        {checkoutTitle()}
      </TextV2>

      <TextV2 typographyVariant={'body2'} color="mono14" marginBottom={6}>
        {checkoutDescription({ host })}
      </TextV2>

      <Flex flexDirection="column" rowGap={5} marginBottom={7}>
        <Flex flexDirection="column" rowGap={3}>
          <TextV2 as="label" htmlFor="name" typographyVariant="label3">
            {fieldNameLabel()}
          </TextV2>
          <InputV2 placeholder={fieldNamePlaceholder()} {...formik.getFieldProps('name')} isInvalid={!!formik.getFieldMeta('name').error && formik.getFieldMeta('name').touched} />
          {formik.errors.name && formik.touched.name && (
            <TextV2 color="red6" typographyVariant="label2">
              {formik.errors.name}
            </TextV2>
          )}
        </Flex>

        <Flex flexDirection="column" rowGap={3}>
          <TextV2 as="label" htmlFor="email" typographyVariant="label3">
            {fieldEmailLabel()}
          </TextV2>
          {mySessionEmail ? (
            <>
              <TextV2 typographyVariant="body1">{mySessionEmail}</TextV2>
              <LinkV2 typographyVariant="body1" textDecoration="none" onClick={() => handleOnChangeEmail(mySessionEmail)} marginTop={1} marginBottom={'22px'} fontWeight={600}>
                {changeEmail()}
              </LinkV2>
            </>
          ) : (
            <>
              <InputV2
                placeholder={fieldEmailPlaceholder()}
                {...formik.getFieldProps('email')}
                isInvalid={!!formik.getFieldMeta('email').error && formik.getFieldMeta('email').touched}
              />
              {formik.errors.email && formik.touched.email && (
                <TextV2 color="red6" typographyVariant="label2">
                  {formik.errors.email}
                </TextV2>
              )}
            </>
          )}
        </Flex>
        {!isExperimentNewFlowOn && (
          <Flex flexDirection="column" rowGap={3}>
            <TextV2 as="label" htmlFor="note" typographyVariant="label3">
              {fieldNoteLabel()}
            </TextV2>
            <TextArea {...formik.getFieldProps('note')} placeholder={fieldNotePlaceholder()} resize={false} minRows={3} maxRows={3} tabIndex={0} />
            {formik.errors.note && formik.touched.note && (
              <TextV2 color="red6" typographyVariant="label2">
                {formik.errors.note}
              </TextV2>
            )}
          </Flex>
        )}
      </Flex>
      <ButtonV2 intent="neutral" shape="rounded" loading={isLoading} onClick={handleOnAddDetails} disabled={!isPurchasable}>
        {!isPurchasable ? outOfStock() : checkoutCta()}
      </ButtonV2>
      {!isPurchasable && <StyledPurchasableMessage typographyVariant="label2">{purchaseErrorContent}</StyledPurchasableMessage>}
    </animated.div>
  );
};
