import React from 'react';
import { Flex, IconV2, TextV2 } from '@withjoy/joykit';
import { ChevronRight } from '@withjoy/joykit/icons';
import { Breadcrumb } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/components/Breadcrumbs/Breadcrumbs.styles';

type Props = {
  atoms: Array<{ label: string; isActive: boolean; onClick?: () => void }>;
};

export const Breadcrumbs = ({ atoms }: Props) => {
  const activeAtomIndex = atoms.findIndex(atom => atom.isActive);
  return (
    <Flex alignItems="center" rowGap={1}>
      {atoms.map((atom, index) => {
        const canClick = index < activeAtomIndex;
        return (
          <React.Fragment key={atom.label}>
            {index !== 0 && (
              <IconV2 size="sm" color="gray8">
                <ChevronRight />
              </IconV2>
            )}
            <Breadcrumb canClick={canClick} isActive={atom.isActive} onClick={canClick ? atom.onClick : undefined}>
              <TextV2 typographyVariant="label1">{atom.label}</TextV2>
            </Breadcrumb>
          </React.Fragment>
        );
      })}
    </Flex>
  );
};
