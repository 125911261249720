import { RegistryList, OrderList } from '@apps/registry/common/state/RegistryProducts';
import { LineItemFragment, RegistryFulfillmentFragment, RegistryOrderFragment } from '@graphql/generated';
import { CookedProductList, CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { OnCloseArg } from '@withjoy/joykit';

export type ShoppingCartCommonProps = Readonly<{
  orders: OrderList;
  eventId: string;
  paths: ReturnType<typeof useRoutePaths>;
}>;

export type ShoppingCartProps = Readonly<
  {
    registries: RegistryList;
    isShoppingPage?: boolean;
    isShowCartSection?: boolean;
  } & Pick<ShoppingCartCommonProps, 'orders' | 'eventId'>
>;

export type ReservedOrderProps = Readonly<
  {
    productList: CookedProductList['products'];
    orderId: string;
    orderLineItems: Array<LineItemFragment>;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'orders' | 'eventId'>
>;

export type ShoppingCartInnerProps = Readonly<
  {
    registries: RegistryList;
    isShoppingPage?: boolean;
    isShowCartSection?: boolean;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'orders' | 'eventId'>
>;

export type CartItemProps = Readonly<
  {
    orders?: OrderList;
    product: CookedProduct;
    isDropship: boolean;
    orderId: string;
    itemQuantity: number;
    lineItem?: LineItemFragment;
    note: Maybe<string>;
    fulfillment: Maybe<RegistryFulfillmentFragment>;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'eventId'>
>;

export type ReservedItemControllerProps = Readonly<
  {
    order?: RegistryOrderFragment;
    product?: CookedProduct;
    itemQuantity: number;
    orderId: string;
    isDropship: boolean;
    lineItem?: LineItemFragment;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'eventId'>
>;

export type RemoveActionProps = Readonly<{
  removeAction: Array<{ label: string; value: string; onChange: () => void }>;
}>;

export type CancelItemConfirmationProps = Readonly<{
  shoppingCartProp: RemoveActionProps;
  handleRemoveClick: () => void;
  handleClickCancel: () => void;
  removeItemReason: string;
  productId: string;
}>;

export type PurchaseHistoryProps = Readonly<
  {
    productList: CookedProductList['products'];
    orderId?: string;
    isDropship?: boolean;
    orderLineItems?: Array<LineItemFragment>;
    note: Maybe<string>;
    fulfillment: Maybe<RegistryFulfillmentFragment>;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'eventId'>
>;

export type HeaderProps = Readonly<{
  giftCount: number;
  cartTotal: string;
  onClose?: (args: OnCloseArg) => void;
}>;

export enum CartSections {
  CART = 'cart',
  HISTORY = 'history',
  ADDRESS = 'address'
}

export type ProductListV2 = {
  counts: {
    everything: number;
    favorites: number;
    stillNeeded: number;
    purchased: number;
    reserved: number;
    over100: number;
    under100: number;
    available: number;
    deliverable: number;
  };
  products: readonly CookedProduct[];
};

export type LineItem = {
  quantity: number;
  registryItemId: string;
  fpIndex?: number;
  inStock?: boolean;
  priceInMinorUnits: number;
};

export type HistoryItem = {
  registryItemId: string;
  orderId: string;
};

export enum CartCheckoutSteps {
  Cart = 'Cart',
  Shipping = 'Shipping',
  Details = 'Details',
  Payment = 'Payment',
  Message = 'Message',
  Confirmation = 'Confirmation'
}

export enum ShippingMethods {
  FreeByJoy = 'FreeByJoy'
}

export interface InCartProduct extends LineItem {
  product: CookedProduct;
}

export enum ShippingAddressOptions {
  CoupleAddress = 'CoupleAddress',
  AnotherAddress = 'AnotherAddress',
  ShipToMe = 'ShipToMe'
}
