import { Box, styled, StyleSystemProps, TextV2 } from '@withjoy/joykit';

export const boxStyles: StyleSystemProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const BoxStyled = styled(Box)``;

export const TextStyled = styled(TextV2)``;
