import React from 'react';
import { CurrencyFormatterArgs } from './types';
import { useCurrencyFormatter } from './useCurrencyFormatter';

export const FormattedCurrency: React.FC<CurrencyFormatterArgs> = props => {
  const { formatCurrency } = useCurrencyFormatter();

  const formattedPrice = formatCurrency(props);

  return <>{formattedPrice}</>;
};
