import { Box, styled, TextV2, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledHeaderBox = styled(Box)<{ isBorderBottom?: boolean }>`
  ${({ isBorderBottom, theme }) => {
    return {
      borderBottom: isBorderBottom ? '1px solid' : 'none',
      borderBottomColor: isBorderBottom ? theme.colors.mono3 : 'none'
    };
  }}
`;

export const StyledPurchaseText = styled(TextV2)`
  padding: 0;
  cursor: pointer;
`;

export const StyleText = styled(TextV2)<{ isBorderRight?: boolean }>`
  padding: ${pxToRem(17)};
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  ${({ isBorderRight, theme }) => {
    return {
      borderRight: isBorderRight ? '1px solid' : 'none',
      borderRightColor: isBorderRight ? theme.colors.mono3 : 'none',
      justifyContent: isBorderRight ? 'center' : 'flex-start'
    };
  }}
  svg {
    margin-right: 8px;
  }
`;

export const StyledNotification = styled(Box)``;
const notificationStyles: StyleSystemProps = {
  height: pxToRem(8),
  width: pxToRem(8),
  backgroundColor: 'negative5',
  borderRadius: '50%',
  marginTop: pxToRem(-20)
};

export const StyledCloseButtonBox = styled(Box)<{ isBorderLeft?: boolean }>`
  ${({ isBorderLeft, theme }) => {
    return {
      borderLeft: isBorderLeft ? '1px solid' : 'none',
      borderLeftColor: isBorderLeft ? theme.colors.mono3 : 'none'
    };
  }};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const styles = {
  notificationStyles
};
