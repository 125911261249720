import React from 'react';
import { StyledColumnsContribution } from './../Purchase.styles';
import { CompletePurchase } from './CompletePurchase';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { RegistryItemFragment } from '@graphql/generated';
import { isAmazonUri } from '@apps/registry/common/util/storeHelpers';
import { ShippingAddressFragment } from '@graphql/aliases';

interface InitialContentProps
  extends Readonly<{
    item?: RegistryItemFragment;
    shippingAddress: ShippingAddressFragment | null;
    showUndoConfirmationScreen: () => void;
    handleClickProduct: () => void;
    productImage: string;
    productImageSmall: string;
    registryImage: string;
    storeName?: string;
  }> {}

export const InitialContent: React.FC<InitialContentProps> = ({
  item,
  shippingAddress,
  showUndoConfirmationScreen,
  handleClickProduct,
  productImage,
  registryImage,
  storeName
}) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const isAmazon = isAmazonUri(item?.productData?.externalUrl);

  return (
    <>
      <StyledColumnsContribution isMobile={isMobile} isInModal={false}>
        <CompletePurchase
          isCustom={item?.productData?.legacyType === 'custom'}
          address={shippingAddress}
          handleClickProduct={handleClickProduct}
          handleUndoClick={showUndoConfirmationScreen}
          registryImage={registryImage}
          isStoreAmazon={isAmazon}
          productImage={productImage}
          storeName={storeName}
        />
      </StyledColumnsContribution>
    </>
  );
};
