import { Box, styled } from '@withjoy/joykit';

export const StyledSelectWrapper = styled(Box)`
  [role='combobox'] {
    div:after,
    input:enabled:hover {
      border-color: #333333;
    }
  }
`;
