import { useRef, useState } from 'react';

// Scene 1 at time 0 is auto-included into startTimesMs.
export function useSceneTimer(...startTimesMs: number[]) {
  const [scene, setScene] = useState(0);
  const timeoutIds = useRef<NodeJS.Timeout[]>([]);

  function restart() {
    for (const timeoutId of timeoutIds.current) {
      clearTimeout(timeoutId);
    }
    setScene(0);
    startTimesMs.unshift(0);
    timeoutIds.current = startTimesMs.map((startTimeMs, i) => {
      return setTimeout(() => setScene(i + 1), startTimeMs);
    });
  }

  return [scene, restart] as const;
}
