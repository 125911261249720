import { useFeatureValue } from '@shared/core/featureFlags';

/*
 * Interface for the registryGiftWrapEcardPricePayload remote feature flag payload.
 */
interface RegistryGiftWrapEcardPricePayload {
  /*
   * eCard price in in US Dollars and up to 2 decimal places. i.e. 5.99
   * A zero price is considered free, however cannot be a negative number. i.e -1.00 is not valid
   */
  price: number;
}

/*
 * Type predicate function to validate the registryGiftWrapEcardPricePayload remote feature flag payload.
 * @param {any} payload - The payload to validate.
 * @returns {boolean}
 */
export const isRegistryGiftWrapEcardPricePayload = (payload: unknown): payload is RegistryGiftWrapEcardPricePayload => {
  return !!(payload && typeof payload === 'object' && 'price' in payload && typeof payload.price === 'number' && payload.price >= 0);
};

// Fallback price if we cannot get a price from the remote feature flag.
const DEFAULT_GW_ECARD_PRICE = 1.99;

/*
 * Hook to retrieve and validate the remote feature flag value for Gift Warp eCard price.
 * @returns {number} the sales price for the eCard.
 */
export function useGiftWrapECardPrice(): number {
  const { payload: ecardPricePayload } = useFeatureValue('registryGiftWrapEcardPrice');
  const registryGiftWrapEcardPrice = isRegistryGiftWrapEcardPricePayload(ecardPricePayload) ? ecardPricePayload.price : DEFAULT_GW_ECARD_PRICE;
  return registryGiftWrapEcardPrice;
}
