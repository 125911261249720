import React from 'react';
import { DialogV2, OnCloseArg } from '@withjoy/joykit';
import { ProductList, ShoppingCartInner } from './ShoppingCart';
import { RegistryOrderFragment, ShippingAddressFragment } from '@graphql/generated';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';

interface ShoppingCartDialogProps
  extends Readonly<{
    isOpen: boolean;
    eventId: string;
    shippingAddress: ShippingAddressFragment | null;
    isShoppingPage?: boolean;
    helpDialogOpen: boolean;
    productList: ProductList;
    reservedOrderList: RegistryOrderFragment[];
    purchasedOrderList: RegistryOrderFragment[];
    orderCount: number;
    cartOrderCount: number;
    cartTotal: string;
    paths: ReturnType<typeof useRoutePaths>;
    onClose?: (args: OnCloseArg) => void;
    showHelpDialog: () => void;
    handleDialogClose: () => void;
  }> {}

const DIALOG_CONTENT_ANIMATIONS = {
  in: { opacity: 1 },
  out: { opacity: 0 }
} as const;

export const ShoppingCartDialog: React.FC<ShoppingCartDialogProps> = ({
  isOpen,
  eventId,
  shippingAddress,
  isShoppingPage,
  helpDialogOpen,
  productList,
  reservedOrderList,
  purchasedOrderList,
  orderCount,
  cartOrderCount,
  cartTotal,
  paths,
  onClose,
  showHelpDialog,
  handleDialogClose
}) => {
  return (
    <DialogV2 isOpen={isOpen} onClose={onClose} contentTransition={DIALOG_CONTENT_ANIMATIONS}>
      <DialogV2.Body __css={{ padding: 0 }}>
        <ShoppingCartInner
          eventId={eventId}
          shippingAddress={shippingAddress}
          isShoppingPage={isShoppingPage}
          helpDialogOpen={helpDialogOpen}
          productList={productList}
          reservedOrderList={reservedOrderList}
          purchasedOrderList={purchasedOrderList}
          orderCount={orderCount}
          cartOrderCount={cartOrderCount}
          cartTotal={cartTotal}
          paths={paths}
          onClose={onClose}
          showHelpDialog={showHelpDialog}
          handleDialogClose={handleDialogClose}
        />
      </DialogV2.Body>
    </DialogV2>
  );
};

ShoppingCartDialog.displayName = 'ShoppingCartDialog';
