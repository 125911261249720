import { getGiftWrapThemeByID, isGiftWrapPremiumDetails } from '@shared/components';
import Error from '@shared/components/EmailsAndEcards/components/Error';
import React from 'react';
import { Props } from '../../index';
import { BeachCard } from './BeachCard';
import { FloralCard } from './FloralCard';
import { GetawayCard } from './GetawayCard';

const PremiumView: React.FC<Props> = ({ details, theme }) => {
  const themeDetails = getGiftWrapThemeByID(theme);

  if (!isGiftWrapPremiumDetails(details)) return <Error />;
  if (!themeDetails) return <Error />;

  const Card = (() => {
    if (theme === 'beach') return BeachCard;
    if (theme === 'getaway_car') return GetawayCard;
    if (theme === 'growing_florals') return FloralCard;
    return Error;
  })();

  return <Card details={details} theme={themeDetails} />;
};

export default PremiumView;
