import React from 'react';
import { Flex } from '@withjoy/joykit';

interface GiftWrapFooterProps {
  footerImage: string;
}

export const GiftWrapFooter: React.FC<GiftWrapFooterProps> = ({ footerImage }) => {
  return (
    <Flex marginTop={7} marginBottom={7} flexDirection="column" alignItems="center" maxWidth={'100px'}>
      <img src={footerImage} alt="Accent" width="100%" />
    </Flex>
  );
};
