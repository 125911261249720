import { ChangeEvent, useMemo, useState } from 'react';

import { useShoppingCart } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/state';
import { ShippingAddressOptions } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';
import { useAddressFormFields } from './hooks';
import { AddressFormFields } from './types';
import { AddressUsedEnum } from '@apps/registry/common/components/AddressDialog/types';
import { FieldInputProps } from 'formik';
import * as Yup from 'yup';
import { ShippingAddressFragment } from '@graphql/generated';

export interface ShippingFormFields {
  getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
  handleChange: (e: ChangeEvent) => void;
  isFormValid: boolean;
  errors: Record<string, string | undefined>;
}

const validatateShippingAddressObject = Yup.object({
  name: Yup.string().required(),
  address1: Yup.string().required(),
  city: Yup.string().required(),
  countryCode: Yup.string().required(),
  country: Yup.string().required(),
  state: Yup.string().required(),
  validated: Yup.boolean().required()
});

function isAddressValid(address: ShippingAddressFragment) {
  try {
    validatateShippingAddressObject.validateSync(address, { abortEarly: false });
    return true;
  } catch (_) {
    return false;
  }
}
export function useShippingStepController() {
  const {
    state: { customShippingAddress, coupleAddress, selectedShippingOption, shippingFee },
    mutators: { updateShippingAddress: updateShippingAddressForm, updateShippingOption, updateCurrentSection }
  } = useShoppingCart();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [shippingOptionSelected, setShippingOptionSelected] = useState<ShippingAddressOptions>(selectedShippingOption);
  const [, setSuggestedAddress] = useState<AddressFormFields | null>(null);
  const [, setAddressUsed] = useState<AddressUsedEnum>(AddressUsedEnum.CUSTOM);
  const [shippingAddressFormValues, setShippingAddressFormValues] = useState<AddressFormFields>(customShippingAddress as AddressFormFields);

  const handleFormSubmit = (formValues: AddressFormFields) => {
    setShippingAddressFormValues(formValues);
    updateShippingAddressForm(formValues);
    updateShippingOption(shippingOptionSelected);
  };
  const { saveAddress, formik, getFormFieldError } = useAddressFormFields(shippingAddressFormValues, handleFormSubmit);

  const formFields: ShippingFormFields = {
    getFieldProps: formik.getFieldProps,
    handleChange: formik.handleChange,
    isFormValid: formik.isValid,
    errors: {
      nameError: getFormFieldError('name'),
      address1Error: getFormFieldError('address1'),
      stateError: getFormFieldError('state'),
      cityError: getFormFieldError('city'),
      postalCodeError: getFormFieldError('postalCode')
    }
  };

  const isCustomShippingAddressValid = useMemo(() => {
    return customShippingAddress ? isAddressValid(customShippingAddress) : false;
  }, [customShippingAddress]);

  const isCoupleAddressValid = useMemo(() => {
    return coupleAddress ? isAddressValid(coupleAddress) : false;
  }, [coupleAddress]);

  return {
    isFormOpen,
    setIsFormOpen,
    formFields,
    formik,
    coupleAddress,
    shippingFee,
    shippingOptionSelected,
    setShippingOptionSelected,
    setSuggestedAddress,
    setAddressUsed,
    updateShippingOption,
    shippingAddressFormValues,
    setShippingAddressFormValues,
    customShippingAddress,
    saveAddress,
    isCustomShippingAddressValid,
    isCoupleAddressValid,
    updateCurrentSection
  };
}
