import { styled, TextV2, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledTextV2 = styled(TextV2)``;
const textV2Styles: StyleSystemProps = {
  textDecoration: 'underline',
  paddingLeft: pxToRem(8),
  cursor: 'pointer'
};

export const styles = { textV2Styles };
