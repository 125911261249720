import React from 'react';
import { GiftMetadata, GiftMetadataProps } from '../GiftMetadata';
import { StyledGiftCardGiftMetadataContainer, StyledGiftCardGradientContainer, StyledGiftCardRoot, styles } from './GiftCard.styles';
import { IntegratedGiftCardProps } from './IntegratedGiftCard';
import { addRendition } from '@shared/utils/photoRendition';
import { GiftCardSelectors } from './GiftCard.selectors';
import { CustomItemDataType } from '@graphql/generated';
import { useCheckoutFunnel } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { GroupGiftingCard } from '@apps/registry/guest/components/CheckoutDialog/components/GiftCard/GroupGiftingCard';

interface GiftCardProps extends Merge<Omit<GiftMetadataProps, 'placement'>, IntegratedGiftCardProps> {
  productImageUrl?: string;
  hideMetadata?: boolean;
  hideGradient?: boolean;
}

export const GiftCard = (props: GiftCardProps) => {
  const {
    productImageUrl,
    mobileRenderBehavior = 'card',
    currencyCode,
    productType,
    paymentMethod,
    paymentMethodUsername,
    title,
    giftAmount,
    hideMetadata,
    hideGradient,
    ...restProps
  } = props;

  const {
    context: { isGroupGifting }
  } = useCheckoutFunnel(({ context }) => [context.isGroupGifting]);

  const isFullBleed = mobileRenderBehavior === 'fullBleed' && productType === 'cash';
  const isHideShadow = productType === CustomItemDataType.charity && hideMetadata;
  const isCharity = productType === CustomItemDataType.charity && !hideMetadata;
  const backgroundImage = productImageUrl ? `url('${addRendition({ url: productImageUrl, renditionSize: 'small' })}')` : undefined;

  if (isGroupGifting) {
    return <GroupGiftingCard hideMetadata={!!hideMetadata} productImageUrl={productImageUrl} giftAmount={giftAmount} />;
  }

  return (
    <StyledGiftCardRoot __css={styles.giftCardRoot(isFullBleed, isHideShadow, isCharity)} backgroundImage={backgroundImage} {...restProps}>
      <StyledGiftCardGradientContainer __css={hideGradient ? styles.giftCardContainer : styles.giftCardGradientContainer}>
        <StyledGiftCardGiftMetadataContainer {...GiftCardSelectors.ContainerElement} __css={styles.giftCardGiftMetadataContainer}>
          {!hideMetadata && (
            <GiftMetadata
              paymentMethod={paymentMethod}
              productType={productType}
              paymentMethodUsername={paymentMethodUsername}
              title={title || ''}
              giftAmount={giftAmount}
              currencyCode={currencyCode}
              placement="bottomAnchor"
            />
          )}
        </StyledGiftCardGiftMetadataContainer>
      </StyledGiftCardGradientContainer>
    </StyledGiftCardRoot>
  );
};
