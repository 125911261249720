import React from 'react';
import { TextV2, ButtonV2, FormControl, InputV2, Flex, SpacingStack } from '@withjoy/joykit';
import { useSpring, animated } from 'react-spring';
import { usePurchaseDialogTranslations } from '../../../../hooks';
import { useOtherAddressController } from './OtherAddress.controller';
import { StyledPurchasableMessage } from '../ProductInformation/ProductInformation.styles';
import { AddressAutocompleteField } from '@apps/registry/common/components/AddressAutocompleteField';
import { AddressDialogFields } from '@apps/registry/common/components/AddressDialog/types';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';

interface OtherAddressProps {
  isIn: boolean;
  isPurchasable: boolean;
  purchaseErrorContent: string | null;
  handleOnAddOtherAddress: (values: AddressDialogFields) => void;
}
// TODO: FormField(V1) displayed an asterisk when the input is required. FormControl(V2) doesn't display it, so it's added temporary until the bug is fixed
const ASTERISK_CHAR = '\u2009*';

export const OtherAddress = ({ isIn, isPurchasable, purchaseErrorContent, handleOnAddOtherAddress }: OtherAddressProps) => {
  const {
    title,
    nameLabel,
    namePlaceholder,
    postalCode,
    addressLine1Label,
    addressLine1Placeholder,
    addressLine2Label,
    cityLabel,
    stateLabel,
    requiredFields,
    poBoxWarningCaption,
    button,
    nameGhost
  } = usePurchaseDialogTranslations().otherAddressTranslations;
  const { outOfStock } = usePurchaseDialogTranslations();
  const { formik, handlePostalCodeFieldChange, address2Error, stateError, cityError, postalCodeError, nameError } = useOtherAddressController({
    handleOnAddOtherAddress
  });

  const styleProps = useSpring({
    opacity: isIn ? 1 : 0,
    display: isIn ? 'flex' : 'none',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    config: { duration: isIn ? 350 : 250, easing: cubicBezierEasingFn }
    // fadeIn: 350 milliseconds | fadeOut: 250 milliseconds
  });

  return (
    <animated.div style={styleProps}>
      <TextV2 typographyVariant="hed5" marginBottom={6}>
        {title}
      </TextV2>
      <Flex flexDirection="column">
        <form onSubmit={formik.handleSubmit}>
          <SpacingStack spacing={6}>
            <FormControl
              label={`${nameLabel}${ASTERISK_CHAR}`}
              caption={
                <TextV2 typographyVariant="label2" color="mono10">
                  {namePlaceholder}
                </TextV2>
              }
              isInvalid={!!nameError}
              error={nameError}
              isRequired
            >
              <InputV2 {...formik.getFieldProps('name')} placeholder={nameGhost} />
            </FormControl>
            <AddressAutocompleteField
              label={`${addressLine1Label}${ASTERISK_CHAR}`}
              caption={addressLine1Placeholder}
              placeholderError={poBoxWarningCaption}
              registryShippingAddress={null}
              formik={formik}
              formikKey="address1"
              isNationalAddress
              countryDropdownValue="USA"
              clearAddressPressed={false}
            />
            <FormControl label={addressLine2Label} isInvalid={!!address2Error} error={address2Error} marginTop={[32, 32, 0]}>
              <InputV2 {...formik.getFieldProps('address2')} autoComplete="none" />
            </FormControl>
            <FormControl label={`${cityLabel}${ASTERISK_CHAR}`} isInvalid={!!cityError} error={cityError} isRequired>
              <InputV2 {...formik.getFieldProps('city')} />
            </FormControl>
            <FormControl label={`${stateLabel}${ASTERISK_CHAR}`} isInvalid={!!stateError} error={stateError} isRequired marginTop={[32, 32, 0]}>
              <InputV2 {...formik.getFieldProps('state')} />
            </FormControl>
            <FormControl label={`${postalCode}${ASTERISK_CHAR}`} isInvalid={!!postalCodeError} error={postalCodeError} isRequired marginTop={[32, 32, 0]}>
              <InputV2 {...formik.getFieldProps('postalCode')} onChange={handlePostalCodeFieldChange} />
            </FormControl>
            <TextV2 typographyVariant="label5">{requiredFields}</TextV2>
            <ButtonV2 marginTop={3} intent="productive" type="submit" loading={formik.isSubmitting} disabled={!isPurchasable || !formik.isValid}>
              {!isPurchasable ? outOfStock() : button}
            </ButtonV2>
            {!isPurchasable && <StyledPurchasableMessage typographyVariant="label2">{purchaseErrorContent}</StyledPurchasableMessage>}
          </SpacingStack>
        </form>
      </Flex>
    </animated.div>
  );
};
