import { Box, SpacingStack, styled, StyleSystemProps } from '@withjoy/joykit';
import { PlacementOptions } from './GiftMetadata.types';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledGiftMetadataRoot = styled(Box)``;
export const StyledGiftMetadataTextContainer = styled(SpacingStack)``;
export const StyledCharityBadge = styled(Box)``;

const giftMetadataRootStyles = (placement: PlacementOptions): StyleSystemProps => {
  return placement === 'bottomAnchor'
    ? {
        bottom: 0,
        left: 0,
        position: 'absolute',
        width: '100%'
      }
    : {};
};

const giftMetadataTextContainerStyles = (isCharity: boolean): StyleSystemProps => ({
  alignItems: 'end',
  color: !isCharity ? 'white' : 'mono14',
  justifyContent: 'space-between'
});

const giftMetadataCharityBadgeStyles: StyleSystemProps = {
  paddingY: pxToRem(1),
  paddingX: 1,
  borderRadius: 2,
  border: '1px solid #333333',
  width: 'fit-content'
};

export const styles = {
  giftMetadataRoot: giftMetadataRootStyles,
  giftMetadataTextContainer: giftMetadataTextContainerStyles,
  giftMetadataCharityBadge: giftMetadataCharityBadgeStyles
};
