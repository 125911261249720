import React from 'react';
import { Flex, TextV2, InputV2, SelectV1, DialogV2, DialogV2Props } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { ReactComponent as CheckmarkIcon } from '@assets/icons/checklist-large.svg';
import {
  StyledContentWrapper,
  StyledIconRow,
  StyledIconWrapperBox,
  StyledTitleText,
  StyledQuantityRowWrapper,
  StyledQuantityTextWrapper,
  StyledInputWrapperColumn,
  StyledDoneButton,
  StyledCancelButton,
  StyledCancelReservationWrapper,
  StyledCancelReservationTitle,
  StyledFormControl,
  StyledInputError,
  styles
} from './CreateOrderAndPurchase.styles';
import { RegistryItemFragment } from '@graphql/generated';
import { useCreateOrderAndPurchaseController } from './CreateOrderAndPurchase.controller';

export interface CreateOrderAndPurchaseProps extends DialogV2Props {
  handleCancelReservation: () => void;
  product: Maybe<RegistryItemFragment>;
  handlePurchaseSuccess: () => void;
  eventId: string;
}

export const CreateOrderAndPurchaseDialog: React.FC<CreateOrderAndPurchaseProps> = ({ product, handleCancelReservation, handlePurchaseSuccess, eventId, ...props }) => {
  const { t } = useTranslation('guestRegistry');
  const { title, qtyPurchased, qtyNeeded, nameLabel, namePlaceholder, emailLabel, emailPlaceholder, doneButton, cancelTitle, cancelButton } = t('createOrderAndPurchaseModal');
  const {
    formik,
    stillNeededOptions,
    loading,
    nameInputErrorValue,
    nameInputIsInvalid,
    emailInputErrorValue,
    emailInputIsInvalid,
    handleOnQtyChange
  } = useCreateOrderAndPurchaseController({
    product,
    handlePurchaseSuccess,
    eventId
  });

  return (
    <DialogV2 {...props}>
      <DialogV2.CloseButton />
      <DialogV2.Body paddingX={[24, null, 80]} paddingY={40}>
        <StyledContentWrapper width="100%">
          <StyledIconRow __css={styles.iconRow}>
            <StyledIconWrapperBox __css={styles.iconWrapperBox}>
              <CheckmarkIcon />
            </StyledIconWrapperBox>
          </StyledIconRow>
          <StyledTitleText tagName="h1" typographyVariant="hed4" __css={styles.titleText}>
            {title()}
          </StyledTitleText>
          <form onSubmit={formik.handleSubmit}>
            <StyledQuantityRowWrapper marginBottom={6}>
              <StyledQuantityTextWrapper __css={styles.quantityTextWrapper}>
                <TextV2 tagName="p" typographyVariant="label3" marginBottom={1}>
                  {qtyPurchased()}
                </TextV2>
                <TextV2 tagName="p" typographyVariant="body1" color="monoTwo">
                  {qtyNeeded({ qty: product?.stillNeeded })}
                </TextV2>
              </StyledQuantityTextWrapper>
              <Flex flex="1 2 auto" maxWidth="100px">
                <SelectV1 defaultValue={stillNeededOptions[0]} searchable={false} options={stillNeededOptions} fluid={false} onChange={handleOnQtyChange} />
              </Flex>
            </StyledQuantityRowWrapper>
            <StyledInputWrapperColumn __css={styles.inputWrapperColumn}>
              <StyledFormControl htmlFor="name" label={`${nameLabel()}${'\u2009*'}`} typographyVariant="label3" fontWeight="bold" marginBottom={1}>
                <InputV2 placeholder={namePlaceholder()} {...formik.getFieldProps('name')} isInvalid={nameInputIsInvalid} />
              </StyledFormControl>
              <StyledInputError __css={styles.inputError} typographyVariant="label2" className="inputError">
                {nameInputErrorValue}
              </StyledInputError>
              <StyledFormControl htmlFor="email" label={`${emailLabel()}${'\u2009*'}`} typographyVariant="label3" fontWeight="bold" marginBottom={1}>
                <InputV2 placeholder={emailPlaceholder()} {...formik.getFieldProps('email')} isInvalid={emailInputIsInvalid} />
              </StyledFormControl>
              <StyledInputError __css={styles.inputError} typographyVariant="label2" className="inputError">
                {emailInputErrorValue}
              </StyledInputError>
            </StyledInputWrapperColumn>
            <StyledDoneButton intent="productive" width="100%" marginBottom={8} type="submit" disabled={loading} loading={loading}>
              {doneButton()}
            </StyledDoneButton>
          </form>
          <StyledCancelReservationWrapper __css={styles.cancelReservationWrapper}>
            <StyledCancelReservationTitle tagName="p" typographyVariant="body3" __css={styles.cancelReservationTitle}>
              {cancelTitle()}
            </StyledCancelReservationTitle>
            <StyledCancelButton typographyVariant="button1" variant="link" intent="productive" onClick={handleCancelReservation}>
              {cancelButton()}
            </StyledCancelButton>
          </StyledCancelReservationWrapper>
        </StyledContentWrapper>
      </DialogV2.Body>
    </DialogV2>
  );
};
