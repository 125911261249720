import { styled, Box } from '@withjoy/joykit';

export const Divider = styled(Box)<{ margin?: string }>`
  background: #ebebeb;
  opacity: 0.64;
  height: 1px;
  min-height: 1px;
  width: 100%;
  ${props => props.theme.mediaQueries.down({ viewport: 'sm' })} {
    margin: 0;
  }
`;

Divider.defaultProps = {
  margin: '2.5rem 0'
};
