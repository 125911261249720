import React from 'react';

import { RegistryOrderFragment } from '@graphql/generated';
import { useShoppingCart } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/state';
import { CartSections, InCartProduct, ProductListV2 } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';

import { HeaderV2, InCartSection, ReceiptsSection, AddressSection } from './components';
import { ShippingStepProps } from '../ShippingStep';

type Props = {
  eventId: string;
  cartOrderCount: number;
  inCartProductCount: number;
  inCartProductTotal: number;
  inCartProductList: Array<InCartProduct>;
  registryCurrencyCode?: string;
  helpDialogOpen: boolean;
  showHelpDialog: () => void;
  isAddExternalOrderTrackingDialogOpen: boolean;
  productList: ProductListV2;
  purchasedOrderList: RegistryOrderFragment[];
  reservedOrderList: RegistryOrderFragment[];
  paths: ReturnType<typeof useRoutePaths>;
  closeAddExternalOrderTrackingDialog: () => void;
  orderForTrackingRequest: Maybe<RegistryOrderFragment>;
  handleDialogClose: () => void;
  shippingAddressSectionProps: ShippingStepProps;
};

export const CartReviewStep = ({
  cartOrderCount,
  reservedOrderList,
  registryCurrencyCode,
  inCartProductCount,
  inCartProductList,
  inCartProductTotal,
  eventId,
  helpDialogOpen,
  isAddExternalOrderTrackingDialogOpen,
  productList,
  purchasedOrderList,
  orderForTrackingRequest,
  paths,
  showHelpDialog,
  handleDialogClose,
  closeAddExternalOrderTrackingDialog,
  shippingAddressSectionProps
}: Props) => {
  const {
    state: { currentSection }
  } = useShoppingCart();

  return (
    <>
      {currentSection !== CartSections.ADDRESS && <HeaderV2 cartOrderCount={cartOrderCount} displayNotification={reservedOrderList?.length > 0} />}
      {currentSection === CartSections.CART && (
        <InCartSection
          registryCurrencyCode={registryCurrencyCode}
          inCartProductCount={inCartProductCount}
          inCartProductList={inCartProductList}
          inCartProductTotal={inCartProductTotal}
        />
      )}
      {cartOrderCount > 0 && currentSection === CartSections.HISTORY && (
        <ReceiptsSection
          eventId={eventId}
          helpDialogOpen={helpDialogOpen}
          isAddExternalOrderTrackingDialogOpen={isAddExternalOrderTrackingDialogOpen}
          productList={productList}
          reservedOrderList={reservedOrderList}
          purchasedOrderList={purchasedOrderList}
          cartOrderCount={cartOrderCount}
          orderForTrackingRequest={orderForTrackingRequest}
          paths={paths}
          showHelpDialog={showHelpDialog}
          handleDialogClose={handleDialogClose}
          closeAddExternalOrderTrackingDialog={closeAddExternalOrderTrackingDialog}
        />
      )}
      {currentSection === CartSections.ADDRESS && <AddressSection {...shippingAddressSectionProps} />}
    </>
  );
};
