import React from 'react';
import { PublicBoxProps, SpacingStackProps, useDisclosure } from '@withjoy/joykit';
import { StyledContentHeaderDek, StyledContentHeaderDekButton, StyledContentHeaderDekText, StyledContentHeaderHed, StyledContentHeaderRoot } from './ContentHeader.styles';
import { useTranslation } from '@shared/core';
import { ContentHeaderSelectors } from './ContentHeader.selectors';

interface ContentHeaderHedProps extends PublicBoxProps {
  children: string;
}

interface ContentHeaderDekProps extends PublicBoxProps {
  isCollapsible?: boolean;
  maxNoLines?: number;
  children: string;
}

interface ContentHeaderProps extends PublicBoxProps {
  spacing?: SpacingStackProps['spacing'];
}

const ContentHeaderHed = (props: ContentHeaderHedProps) => <StyledContentHeaderHed {...ContentHeaderSelectors.ContentHeaderHedText} typographyVariant="hed5" {...props} />;

const ContentHeaderDek = ({ isCollapsible, maxNoLines = 2, children }: ContentHeaderDekProps) => {
  const { isOpen, onToggle } = useDisclosure();

  const { t2 } = useTranslation('guestRegistry');
  const { readMoreButton, readLessButton } = t2('checkoutDialog');

  return (
    <StyledContentHeaderDek>
      <StyledContentHeaderDekText {...ContentHeaderSelectors.ContentHeaderDekText} isCollapsed={isCollapsible && !isOpen} maxNoLines={maxNoLines} typographyVariant="body2">
        {children}
      </StyledContentHeaderDekText>
      {isCollapsible ? (
        <StyledContentHeaderDekButton onClick={onToggle} marginTop={3} intent="productive" variant="link">
          {isOpen ? readLessButton : readMoreButton}
        </StyledContentHeaderDekButton>
      ) : null}
    </StyledContentHeaderDek>
  );
};

export const ContentHeader = ({ spacing = 5, ...restProps }: ContentHeaderProps) => <StyledContentHeaderRoot spacing={spacing} {...restProps} />;

ContentHeader.displayName = 'ContentHeader';
ContentHeader.Hed = ContentHeaderHed;
ContentHeader.Dek = ContentHeaderDek;
