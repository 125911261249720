import React from 'react';
import { Box, ButtonV2, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { ContentHeader, CtaGroup, IntegratedGiftCard, LayoutShell, PrimaryCta, SecondaryCta } from '../../components';
import { useExternalCheckoutConfirmAndNotifyController } from './ExternalCheckoutConfirmAndNotify.controller';
import { PLATFORM_LABELS } from '../../CheckoutDialog.constants';
import { ExternalCheckoutConfirmAndNotifySelectors } from './ExternalCheckoutConfirmAndNotify.selectors';
import { usePageTitleConfirmPayment } from '@apps/registry/guest/routes/Purchase/components/titleHandler';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';

export const ExternalCheckoutConfirmAndNotify = () => {
  const {
    formValues,
    initiateCancelProcess,
    toNext,
    getHelp,
    isCharityFundType,
    translations,
    isAffiliate,
    product,
    currency,
    productImageSrc
  } = useExternalCheckoutConfirmAndNotifyController();

  const { title, titleCharity, titleAffiliate, subtitle, affirmativeButton, negativeButton, helpText, helpButton } = translations;

  usePageTitleConfirmPayment();

  const platformLabel = formValues?.paymentMethod ? PLATFORM_LABELS[formValues?.paymentMethod] : '';

  const contentHeaderMarkup = (
    <ContentHeader>
      <ContentHeader.Hed>{isAffiliate ? titleAffiliate() : isCharityFundType ? titleCharity() : title({ platform: platformLabel })}</ContentHeader.Hed>
      <ContentHeader.Dek>{subtitle()}</ContentHeader.Dek>
    </ContentHeader>
  );

  return (
    <LayoutShell {...ExternalCheckoutConfirmAndNotifySelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="static" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          <Box paddingX={6} display={['block', 'none']} paddingTop={8}>
            {contentHeaderMarkup}
          </Box>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} quantity={formValues.quantity} currency={currency} />
          ) : (
            <IntegratedGiftCard mobileRenderBehavior="card" marginTop={[7, 0]} />
          )}
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <Flex flexDirection="column" justifyContent="center" height="100%">
            <Box display={['none', 'block']}>{contentHeaderMarkup}</Box>
            <CtaGroup marginTop={[0, 7]}>
              <PrimaryCta {...ExternalCheckoutConfirmAndNotifySelectors.YesIDidButton} onClick={toNext}>
                {affirmativeButton()}
              </PrimaryCta>
              <SecondaryCta onClick={initiateCancelProcess}>{negativeButton()}</SecondaryCta>
            </CtaGroup>
            <SpacingStack spacing={0} alignItems="center" marginTop={5}>
              <TextV2 typographyVariant="body2">{helpText()}</TextV2>
              <ButtonV2 {...ExternalCheckoutConfirmAndNotifySelectors.GetHelpButton} variant="ghost" intent="neutral" size="sm" onClick={getHelp}>
                {helpButton()}
              </ButtonV2>
            </SpacingStack>
          </Flex>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
