/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Mailbox',
  definitions: {
    '24': {
      viewBox: '0 0 19 23',
      path: (
        <>
          <path
            d="M11.7625 4.93048H15.6458C16.0903 4.93048 16.4814 4.63715 16.6058 4.21048L17.1892 2.21048C17.3758 1.57048 16.8958 0.930481 16.2292 0.930481H11.5625C11.0102 0.930481 10.5625 1.3782 10.5625 1.93048V6.9305H4.875C2.66586 6.9305 0.875 8.72136 0.875 10.9305V18.4305C0.875 18.6894 1.07175 18.9023 1.32388 18.9279C1.34069 18.9296 1.35774 18.9305 1.375 18.9305H7.91025V22.0439C7.91025 22.3752 8.17888 22.6439 8.51025 22.6439C8.84162 22.6439 9.11025 22.3752 9.11025 22.0439V18.9314H10.9103V21.4251C10.9103 21.7565 11.1789 22.0251 11.5103 22.0251C11.8416 22.0251 12.1103 21.7565 12.1103 21.4251V18.9305H17.875C18.4273 18.9305 18.875 18.4828 18.875 17.9305V10.9305C18.875 8.72136 17.0841 6.9305 14.875 6.9305H11.7625V4.93048ZM11.7625 2.13048H15.9625L15.4958 3.73048H11.7625V2.13048ZM10.5625 8.1305V13.3305C10.5625 13.6619 10.8311 13.9305 11.1625 13.9305C11.4939 13.9305 11.7625 13.6619 11.7625 13.3305V8.1305H14.875C16.4214 8.1305 17.675 9.3841 17.675 10.9305V17.7305H9.125V10.9305C9.125 9.84031 8.68887 8.852 7.98157 8.1305H10.5625ZM4.875 8.1305H5.125C6.6714 8.1305 7.925 9.3841 7.925 10.9305V17.7305H2.075V10.9305C2.075 9.3841 3.3286 8.1305 4.875 8.1305Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
