import React from 'react';
import { OptionType, SelectV1, SpacingStack } from '@withjoy/joykit';
import { LayoutShell, ContentHeader, CtaGroup, PrimaryCta, DonationProgressBar, IntegratedGiftCard } from '../../components';
import { useGiftAmountController } from './GiftAmount.controller';
import { preventNonNumericalInput } from '@shared/utils/preventNonNumericalInput';
import { useTranslation } from '@shared/core';
import { StyledSelectWrapper } from '@apps/registry/guest/components/CheckoutDialog/routes/GiftAmount/GiftAmount.styles';
import { Input } from '@apps/registry/guest/components/CheckoutDialog/components/FormComponents/Input';
import { FormControl } from '@apps/registry/guest/components/CheckoutDialog/components/FormComponents/FormControl';
import { GiftAmountSelectors } from './GiftAmount.selectors';
import { CustomItemDataType } from '@graphql/generated';
import MustHaveChip from '../../components/MustHaveChip';

export const GiftAmount = () => {
  const {
    goalAmount,
    amountDonated,
    currencyCode,
    amount,
    formattedAmount,
    suggestedDonation,
    formik,
    setAmount,
    currencySymbol,
    stillNeededOptions,
    registryItem,
    showProgressBar,
    onSubmit
  } = useGiftAmountController();
  const { productData, donationFund, mustHave } = registryItem;

  const isCharity = donationFund?.fundType === CustomItemDataType.charity;

  const { t } = useTranslation('guestRegistry');
  const { fieldAmountLabel } = t('giveGiftDialog');
  const { giveNow, giveNowWithAmount } = t('checkoutDialog', 'screens', 'giftAmount');

  return (
    <LayoutShell {...GiftAmountSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement={isCharity ? 'static' : 'float'} />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          <IntegratedGiftCard mobileRenderBehavior="fullBleed" />
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          {mustHave && <MustHaveChip />}
          <ContentHeader>
            <ContentHeader.Hed>{productData.title}</ContentHeader.Hed>
            {donationFund?.description && <ContentHeader.Dek isCollapsible={true}>{donationFund.description}</ContentHeader.Dek>}
          </ContentHeader>
          <SpacingStack spacing={6} marginTop={6}>
            {showProgressBar && <DonationProgressBar amountDonated={amountDonated} totalAmount={goalAmount || ''} currencyCode={currencyCode} userContribution={amount || 0} />}
            <form>
              <DonationAmountInput
                isDisabled={false}
                fieldAmountLabel={fieldAmountLabel()}
                {...{ suggestedDonation, formik, stillNeededOptions, setAmount, preventNonNumericalInput, currencySymbol }}
              />
              <CtaGroup marginTop={7}>
                <PrimaryCta {...GiftAmountSelectors.GiveNowButton} onClick={() => onSubmit('full')}>
                  {amount ? giveNow({ amount: formattedAmount }) : giveNowWithAmount()}
                </PrimaryCta>
              </CtaGroup>
            </form>
          </SpacingStack>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};

type DonationAmountInputProps = {
  suggestedDonation: number | undefined;
  fieldAmountLabel: string;
  formik: ReturnType<typeof useGiftAmountController>['formik'];
  stillNeededOptions: OptionType[];
  setAmount: (amount?: string | undefined) => void;
  preventNonNumericalInput: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  currencySymbol: string;
  isDisabled: boolean;
};

const DonationAmountInput = ({
  suggestedDonation,
  fieldAmountLabel,
  formik,
  stillNeededOptions,
  setAmount,
  preventNonNumericalInput,
  currencySymbol,
  isDisabled
}: DonationAmountInputProps) => {
  const hasError = !!formik.errors.amount && !!formik.touched.amount;
  return (
    <FormControl label={fieldAmountLabel} isInvalid={hasError} error={hasError ? formik.errors.amount : undefined}>
      {suggestedDonation ? (
        <StyledSelectWrapper>
          <SelectV1
            {...GiftAmountSelectors.AmountInput}
            disabled={isDisabled}
            defaultValue={stillNeededOptions[0]}
            searchable={false}
            options={stillNeededOptions}
            fluid={false}
            onChange={e => setAmount(e?.value)}
          />
        </StyledSelectWrapper>
      ) : (
        <>
          <Input
            {...GiftAmountSelectors.AmountInput}
            startElement={currencySymbol}
            isInvalid={hasError}
            {...formik.getFieldProps('amount')}
            type="number"
            onKeyDown={preventNonNumericalInput}
            tabIndex={0}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={isDisabled}
          />
        </>
      )}
    </FormControl>
  );
};
