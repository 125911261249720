import React from 'react';
import { Box, ButtonV2, FormControl, InputV2, LinkV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { useTrackingSectionController } from './TrackingSection.controller';
import { animated } from 'react-spring';
import { RegistryFulfillmentFragment } from '@graphql/generated';

export type TrackingSectionV2Props = {
  orderId: string;
  fulfillment: Maybe<RegistryFulfillmentFragment>;
};

export const TrackingSectionV2 = ({ orderId, fulfillment }: TrackingSectionV2Props) => {
  const { isTrackingOpen, springProps, trackFormik, openTrackinSection, loading, translations, handleOnCancel } = useTrackingSectionController({ orderId, fulfillment });
  const { addTrackingInfoCta, orderNumberLabel, saveTrakingInfoCta, testLink, trackingNumberLabel, updateCta, cancelCta } = translations;

  return (
    <>
      {!isTrackingOpen ? (
        <Box textAlign="center" margin={5}>
          {fulfillment?.orderNumber ? (
            <ButtonV2 intent="neutral" variant="outline" onClick={openTrackinSection} width="100%">
              {updateCta}
            </ButtonV2>
          ) : (
            <ButtonV2 intent="neutral" onClick={openTrackinSection} width="100%">
              {addTrackingInfoCta}
            </ButtonV2>
          )}
        </Box>
      ) : (
        <Box as={animated.div} padding={5} style={springProps}>
          <form onSubmit={trackFormik.handleSubmit}>
            <SpacingStack spacing={6}>
              <FormControl
                error={
                  <TextV2 typographyVariant="label2" marginTop={2} color={'inputBorderError'} hidden={!trackFormik.getFieldMeta('orderNumber').error}>
                    {trackFormik.getFieldMeta('orderNumber').error}
                  </TextV2>
                }
                label={
                  <TextV2 typographyVariant="label3" as="label">
                    {orderNumberLabel}
                  </TextV2>
                }
                isInvalid={!!trackFormik.getFieldMeta('orderNumber').error}
              >
                <InputV2 {...trackFormik.getFieldProps('orderNumber')} />
              </FormControl>
              <FormControl
                label={
                  <TextV2 typographyVariant="label3" as="label">
                    {trackingNumberLabel}
                  </TextV2>
                }
                caption={
                  trackFormik.getFieldMeta('trackingNumber').value === '' ? (
                    <TextV2 typographyVariant="label2" marginTop={2} color="mono10">
                      {testLink}
                    </TextV2>
                  ) : (
                    <LinkV2
                      target="__blank"
                      typographyVariant="label2"
                      marginTop={2}
                      href={`https://www.google.com/search?q=${trackFormik.getFieldMeta('trackingNumber').value}`}
                      textDecoration="none"
                    >
                      {testLink}
                    </LinkV2>
                  )
                }
                isInvalid={!!trackFormik.getFieldMeta('trackingNumber').error}
                isDisabled={trackFormik.getFieldMeta('orderNumber').value === ''}
              >
                <InputV2 {...trackFormik.getFieldProps('trackingNumber')} />
              </FormControl>
              <SpacingStack spacing={5}>
                <ButtonV2 intent="neutral" type="submit" disabled={loading} loading={loading} width="100%">
                  {saveTrakingInfoCta}
                </ButtonV2>
                <ButtonV2 intent="neutral" variant="ghost" onClick={handleOnCancel} disabled={loading} loading={loading} width="100%">
                  {cancelCta}
                </ButtonV2>
              </SpacingStack>
            </SpacingStack>
          </form>
        </Box>
      )}
    </>
  );
};
