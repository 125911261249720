import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

import { usePurchaseDialogTranslations } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/hooks';

export const ShippingByJoy = () => {
  const { fulfilledByJoyShort } = usePurchaseDialogTranslations();

  return (
    <Flex color="mono12" alignItems="center" justifyContent="center">
      <TextV2 typographyVariant="label1" textAlign="center" fontWeight={500} fontSize={pxToRem(14)}>
        {fulfilledByJoyShort()}
      </TextV2>
    </Flex>
  );
};
