import { animationTransition, STANDARD_TIMING_FUNCTION } from '@shared/utils/animationTransition';
import { Box, styled, StyleSystemProps } from '@withjoy/joykit';
import { keyframes } from '@withjoy/joykit/theme';

const growToWidth = keyframes`
from { width: 0%; }
to { width: 100%; }
`;

export const StyledProgressBar = styled(Box)``;
export const StyledProgressBarFill = styled(Box)`
  ::after {
    // This handles the animation of initial render
    animation: ${growToWidth} 2s ${STANDARD_TIMING_FUNCTION} forwards 1s;
  }
`;

const progressBarStyles: StyleSystemProps = {
  backgroundColor: 'mono3',
  width: '100%',
  height: 3,
  borderRadius: 3,
  position: 'relative'
};

const progressBarFillStyles: StyleSystemProps = {
  position: 'absolute',
  left: 0,
  top: 0,
  maxWidth: '100%',
  width: 0,
  // this transition handles the animation of width change
  transition: animationTransition('width'),
  _after: {
    content: '""',
    display: 'block',
    backgroundColor: 'positive7',
    height: 3,
    borderRadius: 3,
    width: 0
  }
};

export const styles = {
  progressBar: progressBarStyles,
  progressBarFill: progressBarFillStyles
};
