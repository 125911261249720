import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { getSuggestedDonation } from '@apps/registry/guest/routes/GuestRegistry/components/GiveGiftDialog/util';
import { DonationFundFragment } from '@graphql/generated';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';
import { useCallback, useState } from 'react';
import { getStillNeededOptionsInner } from '@apps/registry/guest/routes/GuestRegistry/components/GiveGiftDialog/GiveGiftDialog.interactive';
import { useCurrencyContext, useCurrencyFormatter } from '@shared/utils/currency';
import { FlowVariant } from '@apps/registry/guest/components/CheckoutDialog/machines/externalCheckout';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { addAction } from '@shared/utils/logger';

export type GiveAmountDialogFields = {
  amount?: number | '';
};

export const useGiftAmountController = () => {
  const { submitGiftAmountForm, formValues } = useCheckoutFunnel(({ submitGiftAmountForm, formValues }) => [submitGiftAmountForm, formValues]);
  const { registryItem } = useCheckoutInitDataContext('giftAmount');
  // A lot of properties from DonationFundFragment are redundant
  const donationFundAsFragment = (registryItem.donationFund as unknown) as DonationFundFragment;
  const { goalMonetaryValue, progressToGoal, hideFundingGoal, currency } = registryItem.donationFund || {};
  const { eventInfo } = useEventInfo();
  const { registryState } = useCheckoutDialogContext();
  const { giveNow } = useGuestRegistryTelemetry();

  const suggestedDonation = getSuggestedDonation(donationFundAsFragment);
  const { t } = useTranslation('guestRegistry');
  const { amountEmptyError } = t('checkoutDialog', 'screens', 'giftAmount');

  const [flowVariant, setFlowVariant] = useState<FlowVariant>('full');

  const formik = useFormik<GiveAmountDialogFields>({
    initialValues: {
      amount: formValues.amount || suggestedDonation || ''
    },
    validationSchema: Yup.object<GiveAmountDialogFields>({
      amount: Yup.number()
        .required(amountEmptyError())
        .min(suggestedDonation ? suggestedDonation : 1, amountEmptyError())
    }),
    onSubmit: values => {
      submitGiftAmountForm({ amount: values.amount as number, flowVariant });
    }
  });
  const setAmount = useCallback((amount?: string) => formik.setFieldValue('amount', amount || '1'), [formik]);

  const onSubmit = useCallback(
    (flowVariant: FlowVariant) => {
      const telemetryData = {
        giftAmount: formValues.amount,
        productTitle: registryItem.productData.title || eventInfo?.eventDisplayName || 'cash fund',
        eventId: eventInfo?.eventFirebaseId,
        registryItemId: registryItem.id,
        donationFundId: registryItem.donationFund?.id,
        externalUrl: registryItem.productData.externalUrl,
        flowVariant
      };
      giveNow(telemetryData);
      addAction('giveNow', telemetryData);
      setFlowVariant(flowVariant);
      void formik.submitForm();
    },
    [
      eventInfo?.eventDisplayName,
      eventInfo?.eventFirebaseId,
      formValues.amount,
      formik,
      giveNow,
      registryItem.donationFund?.id,
      registryItem.id,
      registryItem.productData.externalUrl,
      registryItem.productData.title
    ]
  );

  const currencyCode: string = registryItem.donationFund?.enableJoyCredit ? DEFAULT_CURRENCY : currency?.code || registryState?.registryCurrencyCode || DEFAULT_CURRENCY;
  const currencySymbol = useCurrencyContext().getCurrencySymbol(currencyCode).symbol;
  const stillNeededOptions = getStillNeededOptionsInner({ donationFund: donationFundAsFragment, currencySymbol });
  const { formatCurrency } = useCurrencyFormatter();

  const formattedAmount = formatCurrency({
    priceFloatingPointDecimalString: '' + formik.values.amount,
    priceCurrencyCode: currencyCode,
    formatForm: 'short'
  });

  const goalAmount = goalMonetaryValue?.valueInMinorUnits ? goalMonetaryValue?.floatingPointDecimalString : null;
  return {
    submitGiftAmountForm,
    registryItem,
    formValues,
    suggestedDonation,
    formik,
    setAmount,
    formattedAmount,
    currencySymbol,
    stillNeededOptions,
    amount: Number.parseFloat('' + formik.values.amount ?? 0),
    currencyCode,
    amountDonated: progressToGoal?.floatingPointDecimalString || '0.0',
    goalAmount,
    showProgressBar: !hideFundingGoal && !!goalAmount,
    onSubmit
  };
};
