import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';
import { Box, styled } from '@withjoy/joykit';

export const numberStyled: CSSObject = {
  width: '20px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid',
  borderRadius: '25px',
  borderColor: 'positive7',
  color: 'positive7',
  marginTop: 1
};

export const howToListStyled: CSSObject = {
  display: 'grid',
  gridTemplateColumns: '20px 1fr',
  gridGap: 4,
  borderRadius: '4px',
  border: '1px solid',
  borderColor: 'mono3',
  marginTop: 6,
  padding: 6,
  marginBottom: '4rem'
};

export const StyledHowToList = styled(Box)``;
