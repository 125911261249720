import React from 'react';
import { Box, Divider, LinkV2, TextV2 } from '@withjoy/joykit';

import { useTranslation } from '@shared/core';
import { useFeatureValue } from '@shared/core/featureFlags';

import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { CartItemProps } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';
import { useReservedItemController } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/ShoppingCartItem.controller';
import { OrderNumberMetadata, TrackingMetadata, GiftNoteV2, TrackingSectionV2 } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/components';

import { StyledTrackingInfoBox, StyledPurchasedItemBox, StylePurchaseOrderBox, styles } from './PurchasedItem.styles';
import { InReceiptItem } from '../../InReceiptItem';

export const ElsewherePurchasedItem: React.FC<CartItemProps> = ({ orderId, paths, product, itemQuantity, eventId, isDropship, lineItem, note, fulfillment }) => {
  const { siteName, price, totalPrice, isDonationFundItem, toast, toastVisible, setToastVisibility, handleClickOrderButton } = useReservedItemController({
    product,
    itemQuantity,
    orderId,
    eventId,
    paths,
    isDropship,
    lineItem
  });
  const { t } = useTranslation('guestRegistry');
  const { viewOrderDetails, didntPurchase } = t('shoppingCart');
  const enableAddEditGiftNote = useFeatureValue('enableAddEditGiftNote');
  const enableOrderTrackingFeature = useFeatureValue('orderTrackingFeature');

  return (
    <StyledPurchasedItemBox __css={styles.purchasedItemBox}>
      <NotificationToast
        isPositionTop={true}
        noAnimation={!toast.showConfettiAnimation}
        notificationText={toast.text}
        notificationVisible={toastVisible}
        setNotificationVisible={setToastVisibility}
      />
      {product && orderId && (
        <StylePurchaseOrderBox>
          <InReceiptItem product={product} quantity={itemQuantity} price={price} totalPrice={totalPrice} isDonationFundItem={isDonationFundItem} siteName={siteName} />

          <StyledTrackingInfoBox __css={styles.trackingInfoBox}>
            {!isDropship && fulfillment?.orderNumber && <OrderNumberMetadata orderNumber={fulfillment.orderNumber} />}
            {!isDropship && fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber && (
              <Box marginTop={fulfillment?.orderNumber ? 6 : 0}>
                <TrackingMetadata
                  trackingNumber={fulfillment.shipments[0].trackingInformation.trackingNumber}
                  shippingCarrier={fulfillment.shipments[0].trackingInformation.company || undefined}
                />
              </Box>
            )}
          </StyledTrackingInfoBox>
          {enableOrderTrackingFeature && !fulfillment?.orderNumber && !isDropship && <TrackingSectionV2 orderId={orderId} fulfillment={fulfillment} />}
          {enableAddEditGiftNote && <GiftNoteV2 note={note} orderId={orderId} variant={fulfillment?.orderNumber ? 'solid' : 'outline'} />}
          {enableOrderTrackingFeature && fulfillment?.orderNumber && !isDropship && <TrackingSectionV2 orderId={orderId} fulfillment={fulfillment} />}
          <Divider />
          <LinkV2 textDecoration="none" onClick={handleClickOrderButton} color={'typeSecondaryDark'} textAlign="center">
            <TextV2 component="p" textAlign="center" padding={4} color={'typeSecondaryDark'}>
              {isDropship ? viewOrderDetails() : didntPurchase()}
            </TextV2>
          </LinkV2>
        </StylePurchaseOrderBox>
      )}
    </StyledPurchasedItemBox>
  );
};
