import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

export const StyledProductPhoto = styled(Box)``;

export const StyledContainer = styled(Flex)`
  width: 100%;
`;

export const StyledPrimaryPhotoControls = styled(Box)``;

export const StyledDragContainer = styled(Box)``;

export const StyledPhotoSpotlightContainer = styled(Box)``;

const photoGridStyles: StyleSystemProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridColumnGap: 6,
  gridRowGap: 6
};

export const StyledArrowColumn = styled(Box)<{ $dir: 'left' | 'right' }>`
  grid-area: ${props => `${props.$dir}Arrow`};
`;

const containerStyles: StyleSystemProps = {
  position: 'relative',
  flexDirection: 'column'
};

const arrowColumnStyles: CSSObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const productPhotoStyles: StyleSystemProps = {
  width: '100%',
  paddingTop: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
};

const photoSpotlightContainerStyles: StyleSystemProps = {
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center'
};

export const styles = {
  photoSpotlightContainer: photoSpotlightContainerStyles,
  productPhoto: productPhotoStyles,
  container: containerStyles,
  arrowColumn: arrowColumnStyles,
  photoGrid: photoGridStyles
};
