import { Box, ButtonV2, DialogV2, OnCloseArg, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { RegistryItemFragment } from '@graphql/generated';
import { BackButton, itemPhotoStyles, StyledBoxImageNote, StyledItemPhoto } from './PurchaseConfirmationModalContent.styles';
import {
  boxIconWithNoteStyles,
  boxWidthStyled,
  StyledBoxIconWithNote,
  StyledNote,
  titleStyled
} from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/components/ConfirmationModalContent.styles';
import { ReactComponent as ChevronBackIcon } from '@shared/joykit/packages/core/icons/svgs/16/chevron-back.svg';
import { Warning } from '@withjoy/joykit/icons';
import { FormattedCurrency } from '@shared/utils/currency';

type PurchaseConfirmationModalContentProps = {
  onClose: (args: OnCloseArg) => void;
  item?: RegistryItemFragment;
  handleConfirm: () => void;
  onBack: () => void;
  retailer: string;
};

export const PurchaseConfirmationModalContent = ({ retailer, item, onClose, handleConfirm, onBack }: PurchaseConfirmationModalContentProps) => {
  const { t } = useTranslation('guestRegistry');

  const price = item?.productData?.price;
  const image = item?.productData?.photos[0]?.url;
  return (
    <Box position="relative">
      <DialogV2.Header>
        <Box>
          <BackButton startIcon={ChevronBackIcon} variant="link" onClick={onBack} color="black">
            <TextV2 typographyVariant="button1">{t('confirmationPurchaseModal', 'shippingAddress')()}</TextV2>
          </BackButton>
        </Box>
        <Box>
          <DialogV2.CloseButton onClick={e => onClose({ closeSource: 'closeButton', event: e })} color="black" />
        </Box>
        <TextV2 __css={titleStyled} typographyVariant="hed5" paddingTop="8">
          {t('confirmationPurchaseModal', 'title')({ retailer })}
        </TextV2>
      </DialogV2.Header>
      <DialogV2.Body paddingX={[9, 6]} minHeight={300}>
        <Box __css={boxWidthStyled}>
          <StyledBoxIconWithNote __css={boxIconWithNoteStyles}>
            <Box>
              <Warning size={54} />
            </Box>
            <StyledNote typographyVariant="hed3">{t('confirmationPurchaseModal', 'purchaseNote')()}</StyledNote>
          </StyledBoxIconWithNote>
          <StyledBoxImageNote marginTop="8">
            <StyledItemPhoto __css={itemPhotoStyles({ backgroundImage: image || null })} />
            <Box display="flex" flexDirection="column" justifyItems="center">
              <TextV2 typographyVariant="hed1" marginTop="5">
                {item?.productData?.title}
              </TextV2>
              <TextV2 typographyVariant="body1" color="mono12" marginTop="1">
                <FormattedCurrency
                  priceFloatingPointDecimalString={price?.floatingPointDecimalString ?? '0'}
                  priceCurrencyCode={price?.currency?.code}
                  formatForm="short"
                  shouldCalculateCharmPrice={true}
                />
              </TextV2>
            </Box>
          </StyledBoxImageNote>
        </Box>
      </DialogV2.Body>
      <DialogV2.Footer minHeight={122}>
        <Box width="100%">
          <ButtonV2 intent="productive" onClick={handleConfirm} width="100%">
            {t('confirmationPurchaseModal', 'button')({ retailer })}
          </ButtonV2>
          <TextV2 typographyVariant="body1" fontSize={12} marginTop="3" color="mono12" textAlign="center">
            {t('confirmationPurchaseModal', 'legal')()}
          </TextV2>
        </Box>
      </DialogV2.Footer>
    </Box>
  );
};
