import React from 'react';
import { Box } from '@withjoy/joykit';
import { StyledTitle, StyledSubtitle } from './EmptyFilterState.styles';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';

export const EmptyFilterState: React.FC<{}> = () => {
  const { getEmptyFilterTranslations } = useRegistryGuestTranslations();
  const { title, subtitle } = getEmptyFilterTranslations();
  return (
    <Box style={{ maxWidth: '512px', margin: '0 auto', marginBottom: '96px', textAlign: 'center' }}>
      <Box marginBottom={5}>
        <StyledTitle typographyVariant="hed4">{title}</StyledTitle>
      </Box>
      <Box>
        <StyledSubtitle typographyVariant="body1">{subtitle}</StyledSubtitle>
      </Box>
    </Box>
  );
};
