import { styled, TextV2, StyleSystemProps, Box } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledProductSummaryWrapper = styled(Box)``;
const productSummaryWrapperStyles: StyleSystemProps = {
  paddingX: {
    _: 7,
    sm2: 0
  },
  paddingBottom: {
    _: 6,
    sm2: 0
  },
  background: { _: 'linear-gradient(180deg,#f7f7f7 78.65%,#f7f7f7 100%)', sm2: 'none' }
};

export const StyledProductTitle = styled(TextV2)``;
const productTileStyles: StyleSystemProps = {
  marginBottom: 3
};

// Override StyledProductSummaryWrapper styles to use this component in the new Checkout Dialog
export const ProductSummaryWrapper = styled(Box)`
  ${StyledProductSummaryWrapper} {
    background: none;
    padding: ${pxToRem(24)} ${pxToRem(24)} 0;
  }
`;

export const styles = {
  productTile: productTileStyles,
  productSummaryWrapper: productSummaryWrapperStyles
};
