import { Box, StyleSystemProps, styled } from '@withjoy/joykit';

export const StyledProductPhotoContainer = styled(Box)``;
export const StyledProductPhoto = styled(Box)`
  mix-blend-mode: multiply;
`;

const productPhotoWrapperStyles: StyleSystemProps = {
  width: '100%',
  paddingTop: '100%',
  position: 'relative',
  overflow: 'hidden'
};

const productPhotoStyles: StyleSystemProps = {
  width: '100%',
  height: '100%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  transition: 'transform 0.25s',
  justifyContent: 'center',
  display: 'flex',
  transform: 'scale(1.0001)',
  position: 'absolute',
  top: 0
};

export const styles = {
  productPhotoWrapper: productPhotoWrapperStyles,
  productPhoto: productPhotoStyles
};
