import { DonationFundPaymentMethodEnum } from '@graphql/generated';

export const PLATFORM_LABELS: { [key in DonationFundPaymentMethodEnum]: string } = {
  [DonationFundPaymentMethodEnum.venmo]: 'Venmo',
  [DonationFundPaymentMethodEnum.paypal]: 'PayPal',
  [DonationFundPaymentMethodEnum.cashapp]: 'Cash App',
  [DonationFundPaymentMethodEnum.cashOrCheck]: '',
  [DonationFundPaymentMethodEnum.joyCredit]: '',
  [DonationFundPaymentMethodEnum.other]: ''
};
