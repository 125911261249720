import { RoutesType, useCheckoutInitDataContext } from '../useCheckoutInitData';
import { CookedProduct, makeCookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { RegistryItemFragment } from '@graphql/generated';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { first } from 'lodash-es';
import { addRendition } from '@shared/utils/photoRendition';
import { useMemo } from 'react';
import { isValidUrl } from '@shared/utils/urlHelpers';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';

export const useGetProductData = (route: RoutesType): { product?: CookedProduct; productImageSrc?: string; siteName?: string; currency: string } => {
  const { formatCurrency } = useCurrencyFormatter();
  const { registryItem } = useCheckoutInitDataContext(route);

  const { cookedProduct: product } = makeCookedProduct({
    formatCurrency,
    item: (registryItem as unknown) as RegistryItemFragment
  });

  const productImage = first(registryItem?.productData.photos)?.url;
  const productImageSrc = addRendition({ url: productImage, renditionSize: 'medium', isProduct: true });

  const siteName = useMemo(() => {
    if (!isValidUrl(product?.externalUrl)) {
      return product?.externalUrl;
    }
    const domain = new URL(product?.externalUrl || '');
    const siteName = domain.hostname.replace('www.', '');
    return siteName.charAt(0).toUpperCase() + siteName.slice(1);
  }, [product]);

  return { product, productImageSrc, siteName, currency: product?.currencyCode || DEFAULT_CURRENCY };
};
