import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useTranslation } from '@shared/core';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { addAction } from '@shared/utils/logger';

export const usePaperCheckoutConfirmAndNotifyController = () => {
  const {
    toNext,
    initiateCancelProcess,
    formValues,
    context: { isGroupGifting }
  } = useCheckoutFunnel(({ toNext, initiateCancelProcess, formValues, context }) => [toNext, initiateCancelProcess, formValues.amount, context.isGroupGifting]);
  const { registryItem } = useCheckoutInitDataContext();
  const { productId } = useGuestRegistryState();
  const { eventInfo } = useEventInfo();
  const { buyRegistryItem, shoppingCartMakePurchaseClick } = useGuestRegistryTelemetry();
  const { t } = useTranslation('guestRegistry');

  const translations = t('checkoutDialog', 'screens', 'paperCheckoutConfirmAndNotify');
  const { cta } = translations;
  const productTitle = registryItem?.productData.title || eventInfo?.eventDisplayName || 'cash fund';

  const handleAffirmativeButton = () => {
    const buyRegistryItemData = {
      productTitle,
      registryItemId: registryItem?.id,
      reservedQty: 1,
      priceValueInMinorUnits: (formValues.amount || 0) * 100,
      priceCurrencyCode: registryItem?.donationFund?.currency.code,
      buttonLabel: cta(),
      redirectUrl: registryItem?.productData.externalUrl,
      action: 'redirect',
      method: 'purchase',
      isGroupGiftingEnabled: isGroupGifting
    };
    buyRegistryItem(buyRegistryItemData);
    addAction('buyRegistryItem', buyRegistryItemData);

    const shoppingCartMakePurchaseClickData = {
      eventId: eventInfo?.eventFirebaseId,
      productId,
      registryItemId: registryItem?.id,
      reservedQty: 1,
      priceValueInMinorUnits: (formValues.amount || 0) * 100,
      priceCurrencyCode: registryItem?.donationFund?.currency.code,
      destinationUrl: registryItem?.productData.externalUrl,
      typeOfItem: registryItem?.donationFund?.fundType,
      buttonLabel: cta(),
      productTitle,
      isGroupGiftingEnabled: isGroupGifting
    };
    shoppingCartMakePurchaseClick(shoppingCartMakePurchaseClickData);
    addAction('shoppingCartMakePurchaseClick', shoppingCartMakePurchaseClickData);
    toNext();
  };

  return {
    toNext: handleAffirmativeButton,
    initiateCancelProcess,
    translations
  };
};
