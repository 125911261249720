import { Box, styled } from '@withjoy/joykit';

export const StyleReceiptBox = styled(Box)`
  background: ${props => props.theme.colors.mono2};
  svg {
    path {
      fill: ${props => props.theme.colors.mono14};
    }
  }
`;
