import { Colors } from '@withjoy/joykit/theme';

export enum EditMessageSource {
  EDIT_BUTTON = 'EditMessage',
  EDIT_PENCIL = 'PencilIcon',
  EDIT_TEXT = 'TextArea'
}

export interface GiftWrapProduct {
  title: string;
  image?: string;
  store?: string;
  extraProductsCount?: number;
  link?: string;
}

export enum EGiftWrapType {
  BASIC = 'basic',
  PREMIUM = 'premium'
}
/*
draftValuesGreetingCard
*/
export interface IGreetingCardPayload {
  type: string;
  baseUrl: string;
  eventId: string;
  cardDetails: {
    message: string;
    name1?: string;
    name2?: string;
    product: GiftWrapProduct;
  };
  theme: string;
}

export interface IGiftWrapPayload {
  eventId: string;
  message: string;
  theme: string;
  giftTitle?: string;
  giftImage?: string;
  giftLink?: string;
}

export interface IGiftWrapTheme {
  id: string;
  thumbnail: string;
  background: string;
  text: keyof Colors;
  accent: string;
  type: EGiftWrapType;
  gif?: string;
  giftBrand?: string;
  giftTitle?: string;
  allowAsFirst?: boolean;
  screenBackground?: string;
}

export interface GiftWrapBasicDetails {
  message: string;
  product?: GiftWrapProduct;
}

export interface GiftWrapPremiumDetails {
  message: string;
  name1: string;
  name2: string;
  eventDate: number;
  product?: GiftWrapProduct;
}

export const isGiftWrapProduct = (product: unknown): product is GiftWrapProduct => {
  if (!product) return false;
  if (typeof product !== 'object') return false;
  if (!(product as GiftWrapProduct).title || typeof (product as GiftWrapProduct).title !== 'string') return false;
  if ('image' in (product as GiftWrapProduct) && typeof (product as GiftWrapProduct).image !== 'string') return false;
  if ('store' in (product as GiftWrapProduct) && typeof (product as GiftWrapProduct).store !== 'string') return false;
  if ('extraProductsCount' in (product as GiftWrapProduct) && typeof (product as GiftWrapProduct).extraProductsCount !== 'number') return false;
  if ('link' in (product as GiftWrapProduct) && typeof (product as GiftWrapProduct).link !== 'string') return false;
  return true;
};

export const isGiftWrapBasicDetails = (details: unknown): details is GiftWrapBasicDetails => {
  if (!details) return false;
  if (typeof details !== 'object') return false;
  if (typeof (details as GiftWrapBasicDetails).message !== 'string') return false;
  return true;
};

export const isGiftWrapPremiumDetails = (details: unknown): details is GiftWrapPremiumDetails => {
  if (!details) return false;
  if (typeof details !== 'object') return false;
  if (typeof (details as GiftWrapPremiumDetails).message !== 'string') return false;
  if (typeof (details as GiftWrapPremiumDetails).name1 !== 'string') return false;
  if (typeof (details as GiftWrapPremiumDetails).name2 !== 'string') return false;
  return true;
};
