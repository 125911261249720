import React from 'react';
import { Flex, IconV2, TextV2 } from '@withjoy/joykit';
import { CardCheck, Checkmark } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';

export enum ProductStatusFlags {
  Purchased = 'purchased',
  Reserved = 'reserved',
  PurchasedByYou = 'purchasedByYou',
  ReservedByYou = 'reservedByYou',
  Funded = 'funded'
}

const ProductStatusFlagIconsMap = {
  [ProductStatusFlags.Purchased]: <Checkmark />,
  [ProductStatusFlags.PurchasedByYou]: <Checkmark />,
  [ProductStatusFlags.Reserved]: <CardCheck />,
  [ProductStatusFlags.ReservedByYou]: <CardCheck />,
  [ProductStatusFlags.Funded]: <Checkmark />
};

type Props = {
  status: ProductStatusFlags;
  label: string;
};

export const ProductStatusFlag = ({ status, label }: Props) => {
  return (
    <Flex width="fit-content" height={pxToRem(28)} alignItems="center" paddingX={3} backgroundColor="mono2" borderRadius={pxToRem(2)} color={'positive7'}>
      {status in ProductStatusFlagIconsMap && (
        <IconV2 size="sm" marginRight={1}>
          {ProductStatusFlagIconsMap[status]}
        </IconV2>
      )}
      <TextV2 typographyVariant="label1">{label}</TextV2>
    </Flex>
  );
};
