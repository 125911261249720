import { useCallback, useState } from 'react';
import { useDisclosure } from '@withjoy/joykit';
import { useSendTrackingInfoRequestEmailMutation } from '@graphql/generated';
import { useAdminRegistryTelemetry } from '@apps/registry/admin/AdminRegistry.telemetry';

export const usePreviewSendEmailReminderController = (email: string, registryItemId: string, productTitle: string, isPurchase: boolean, orderId: string) => {
  const { sentReservationEmailReminder, sendReminderPrompt, sendReminderConfirmation } = useAdminRegistryTelemetry();
  const [sendTrackingInfoRequestEmail] = useSendTrackingInfoRequestEmailMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleOnOpenDialog = useCallback(() => {
    sendReminderPrompt(productTitle, isPurchase);
    onOpen();
  }, [isPurchase, onOpen, productTitle, sendReminderPrompt]);

  const handleOnSendClick = useCallback(
    async (updateMailStatus?: (wasSent: boolean) => void) => {
      if (emailSent) return;
      let wasSent = false;

      try {
        const result = await sendTrackingInfoRequestEmail({
          variables: {
            orderId: orderId
          }
        });
        sendReminderConfirmation(productTitle, isPurchase);
        wasSent = !!result.data?.sendTrackingInfoRequestEmail;
        if (wasSent) {
          sentReservationEmailReminder(email, registryItemId);
          onClose();
        }
      } catch (e) {}

      setEmailSent(wasSent);
      if (updateMailStatus) {
        updateMailStatus(wasSent);
      }
    },
    [emailSent, sendTrackingInfoRequestEmail, orderId, sendReminderConfirmation, productTitle, isPurchase, sentReservationEmailReminder, email, registryItemId, onClose]
  );

  const handleOnCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    emailSent,
    setEmailSent,
    isOpen,
    handleOnOpenDialog,
    handleOnCancelClick,
    handleOnSendClick
  };
};
