import { Box, FormField, InputV1, styled, TextV2, LinkV2 } from '@withjoy/joykit';
import { SuccessGuestButton } from '../../../../components/SuccessGuestButton';

export const StyledHeaderText = styled(TextV2)``;

const defaultImage = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

export const StyledProductPhoto = styled.div<{ image: string; isMobile?: boolean | false }>`
  width: 100%;
  max-width: 100%;
  height: ${({ isMobile }) => `${isMobile ? 'auto' : '100%'}`};
  ${({ isMobile }) => `${isMobile ? 'min-height: 206px' : ''}`};
  background-image: ${({ image }) => (image ? `url(${image})` : `url(${defaultImage})`)};
  background-repeat: no-repeat;
  background-size: ${({ image }) => (image ? 'cover' : '50%')};
  mix-blend-mode: multiply;
  background-position: center center;
`;

export const ReserveButton = styled(SuccessGuestButton)``;

export const StyledClose = styled.button`
  width: 40px;
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 8px;
  z-index: 1000;
`;

export const StyledFormField = styled(FormField)<{ width?: string; isMobile?: boolean }>`
  width: ${({ width, isMobile }) => (width && !isMobile ? width : isMobile ? '100%' : 'initial')};
`;

export const ErrorFiller = styled(Box)<{ isRendered?: boolean }>`
  height: 20px;
  display: ${({ isRendered }) => (isRendered ? 'inline-block' : 'none')};
`;

export const StyledInputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const StyledNumericInput = styled(InputV1)<{ width?: string }>`
  ${({ width }) => (width ? `width: ${width};` : ``)}
  text-align: left;
  padding-left: 28px !important;
`;

export const StyledInputPrefix = styled.span<{ prefixOffset: number }>`
  line-height: 22px;
  font-size: 15px;
  position: absolute;
  z-index: 1;
  left: ${({ prefixOffset }) => `${prefixOffset}px`};
`;

export const StyledLink = styled(LinkV2)`
  display: block;
  text-align: center;
  color: ${props => props.theme.colors.green7};
  &:hover {
    color: ${props => props.theme.colors.green8} !important;
  }
  &:active {
    color: ${props => props.theme.colors.green9} !important;
  }
`;
