import { ReactComponent as MinusIcon } from '@assets/icons/icon-minus.svg';
import { ReactComponent as PlusIcon } from '@assets/icons/icon-plus.svg';
import { SkeletonText } from '@shared/components/Skeleton';
import { Accordion } from '@withjoy/joykit';
import { DetailSectionItem, ExtraArgs, PDPSection } from '../PdpSection/PdpSections.utils';
import { useDetailSectionItems } from './DetailSections.controller';
import { StyledDetailSectionBody, StyledDetailSectionContainer, StyledDetailSectionHeading, StyledDetailSectionRow, StyledSectionHeader, styles } from './DetailSections.styles';
import React from 'react';

const renderDetail = (sectionItem: DetailSectionItem) => {
  if (sectionItem === undefined) return null;
  const { key: headingKey, html: headingHtml } = sectionItem.heading;
  const { key: bodyKey, html: bodyHtml } = sectionItem.body;

  return (
    <>
      <StyledDetailSectionHeading
        key={headingKey}
        dangerouslySetInnerHTML={{
          __html: headingHtml || ''
        }}
        __css={styles.detailSectionHeading}
      />
      <StyledDetailSectionBody
        key={bodyKey}
        dangerouslySetInnerHTML={{
          __html: bodyHtml || ''
        }}
        __css={styles.detailSectionBody}
      />
    </>
  );
};

export const DetailSections = ({ sections, openFirstSection }: { sections: PDPSection[]; openFirstSection?: boolean; extraArgs?: ExtraArgs }) => {
  return (
    <StyledDetailSectionContainer __css={styles.detailSectionContainer}>
      <Accordion defaultIndex={openFirstSection ? 0 : undefined} __css={styles.accordion}>
        {sections.map((section, idx) => {
          return <DetailSection section={section} key={idx} />;
        })}
      </Accordion>
    </StyledDetailSectionContainer>
  );
};

const DetailSection = ({ section }: { section: PDPSection }) => {
  const detailSectionItem = useDetailSectionItems(section);
  if (!detailSectionItem) return null;

  return (
    <Accordion.Item {...styles.accordionItem}>
      {({ isExpanded }) => {
        return (
          <>
            <Accordion.Button {...styles.accordionButton}>
              <StyledSectionHeader flex="1 1 0%" __css={styles.detailSectionHeader}>
                {detailSectionItem.title}
              </StyledSectionHeader>
              {isExpanded ? <MinusIcon /> : <PlusIcon />}
            </Accordion.Button>
            <Accordion.Panel {...styles.accordionPanel}>
              <>
                {detailSectionItem?.items?.map(item => {
                  return (
                    <StyledDetailSectionRow __css={styles.detailSectionRow} key={item.heading.key}>
                      {renderDetail(item)}
                    </StyledDetailSectionRow>
                  );
                })}
              </>
            </Accordion.Panel>
          </>
        );
      }}
    </Accordion.Item>
  );
};

const DetailSectionSkeleton = () => {
  return (
    <>
      <StyledDetailSectionContainer __css={styles.detailSectionContainer}>
        <StyledDetailSectionRow __css={styles.detailSectionRow}>
          <SkeletonText rows={1} skeletonHeight={'80px'} width={'100%'} />
        </StyledDetailSectionRow>
      </StyledDetailSectionContainer>
      <StyledDetailSectionContainer __css={styles.detailSectionContainer}>
        <StyledDetailSectionRow __css={styles.detailSectionRow}>
          <SkeletonText rows={1} skeletonHeight={'80px'} width={'100%'} />
        </StyledDetailSectionRow>
      </StyledDetailSectionContainer>
      <StyledDetailSectionContainer __css={styles.detailSectionContainer}>
        <StyledDetailSectionRow __css={styles.detailSectionRow}>
          <SkeletonText rows={1} skeletonHeight={'80px'} width={'100%'} />
        </StyledDetailSectionRow>
      </StyledDetailSectionContainer>
    </>
  );
};

DetailSections.Skeleton = DetailSectionSkeleton;
