import { DetailPdpSection, isDefinedPredicate, PDPSection, SectionElementTypes } from '../PdpSection/PdpSections.utils';

const buildDetailSectionBranch = (elements: SectionElementTypes[], rootElementKey: string) => {
  const keyElement = elements?.find((elem: { key: string }) => elem.key === rootElementKey);
  if (!keyElement || !keyElement.key || !keyElement.text) return null;
  const items: NonNullable<DetailPdpSection['items']> = keyElement.childrenKeys
    .map((childKey: string) => {
      const heading = elements.find(element => element.key === childKey);
      const body = elements.find(element => element.key === heading?.childrenKeys[0]);
      if (!(heading && body)) {
        return null;
      }
      return {
        heading,
        body
      };
    })
    .filter(isDefinedPredicate);
  const keyElementWithChildren: DetailPdpSection = { key: keyElement.key, title: keyElement.text, items };
  return keyElementWithChildren;
};

export const useDetailSectionItems = (section: PDPSection): DetailPdpSection | null => {
  if (!(section.sectionTree && section.sectionTree.elements && section.sectionTree.rootElementKey)) return null;
  const branch = buildDetailSectionBranch(section.sectionTree.elements, section.sectionTree.rootElementKey);
  return branch;
};
