import { Link } from '@react-router';
import { PhotoMenuIntent } from './PhotoMenu.types';
import { DrawerV2Props, Flex, StyleSystemProps, styled } from '@withjoy/joykit';

export const ItemList = styled(Flex)<{ isOpen: boolean }>`
  top: calc(100% + 0.375rem);
  z-index: ${({ theme, isOpen }) => (isOpen ? theme.zIndices.dropdown : 'unset')};
`;

export const PhotoMenuRoot = styled(Link)<{ isOpen: boolean }>`
  position: relative;
  cursor: pointer;
  user-select: none;

  button {
    display: ${props => (props.isOpen ? 'block' : '')};
  }
`;

const optionVariants: {
  default: StyleSystemProps;
  destructive: StyleSystemProps;
} = {
  default: {
    _hover: {
      backgroundColor: 'mono2',
      color: 'linkHover'
    },
    _active: {
      backgroundColor: 'mono3',
      color: 'purple8'
    }
  },
  destructive: {
    color: 'negative6',
    _hover: {
      backgroundColor: 'mono2',
      color: 'negative5'
    },
    _active: {
      backgroundColor: 'mono3',
      color: 'negative7'
    }
  }
};

export const optionButtonStyles = (intent: PhotoMenuIntent): StyleSystemProps => ({
  ...optionVariants[intent]
});

export const drawerOverrides: NonNullable<DrawerV2Props['overrides']> = {
  Root: {
    props: {
      background: 'rgb(0 0 0 / 0.5)'
    }
  },
  Content: {
    props: {
      width: '100vw',
      height: 'unset',
      paddingY: 6,
      paddingX: 7,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4
    }
  }
};
