import { styled, Box, StyleSystemProps, Flex } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledShellRoot = styled(Box)``;
export const StyledHeaderRoot = styled(Box)``;
export const StyledBodyRoot = styled(Box)``;

export const StyledMediaPanelRoot = styled(Box)``;
export const StyledContentPanelRoot = styled(Box)``;
export const StyledSingleColumnPanelRoot = styled(Flex)``;

const shellRootStyles: StyleSystemProps = {
  position: 'relative'
};

const DEFAULT_HEADER_ROOT_STYLES = {};
const _floatingMobileHeaderStyles: StyleSystemProps = {
  position: ['absolute', 'static'],
  top: 0,
  left: 0,
  right: 0,
  zIndex: 'skipLink'
};
const headerRootStyles = (mobilePlacement: 'static' | 'float'): StyleSystemProps => {
  return mobilePlacement === 'float' ? _floatingMobileHeaderStyles : DEFAULT_HEADER_ROOT_STYLES;
};

const bodyRootStyles: StyleSystemProps = {
  display: 'flex',
  flexDirection: ['column', 'column', 'row'],
  paddingX: [0, 8],
  paddingBottom: [0, 8],
  columnGap: [0, 0, 8],
  rowGap: [7, 7, 0]
};

const mediaPanelRootStyles: StyleSystemProps = {
  flex: [null, '1']
};

const contentPanelRootStyles: StyleSystemProps = {
  flex: ['1'],
  paddingX: [6, 0],
  paddingBottom: [6, 0]
};

const singleColumnPanelRootStyles: StyleSystemProps = {
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: pxToRem(342),
  marginX: [6, 'auto'],
  justifyContent: 'center',
  height: ['100vh', 'auto'],
  paddingTop: [0, 8],
  marginTop: [0, 8],
  paddingBottom: [0, 8]
};

export const styles = {
  shellRoot: shellRootStyles,
  headerRoot: headerRootStyles,
  bodyRoot: bodyRootStyles,
  mediaPanelRoot: mediaPanelRootStyles,
  contentPanelRoot: contentPanelRootStyles,
  singleColumnPanelRoot: singleColumnPanelRootStyles
};
