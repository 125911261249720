import React, { useState } from 'react';
import { useTranslation } from '@shared/core';
import { ShippingAddressFragment } from '@graphql/aliases';
import {
  StyledBox,
  boxStyles,
  StyledButton,
  StyledExpandArrow
} from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/components/AddressCollapsible/AddressCollapsile.styles';
import { ShippingAddressQuickView } from '@apps/registry/guest/components/ShippingAddressQuickView/ShippingAddressQuickView';

export const AddressCollapsible = ({ shippingAddress }: { shippingAddress: ShippingAddressFragment | null }) => {
  const [show, setShow] = useState(false);

  const showHide = () => setShow(!show);

  const { t } = useTranslation('guestRegistry');

  return (
    <StyledBox __css={boxStyles}>
      <StyledButton intent="productive" variant="link" onClick={showHide} endIcon={StyledExpandArrow} $show={show} marginBottom={show ? 5 : 0}>
        {t('confirmationPurchaseModal', 'shippingAddress')()}
      </StyledButton>
      {show && <ShippingAddressQuickView shippingAddress={shippingAddress} showWarning={false} hideTitle />}
    </StyledBox>
  );
};
