import { AiModelVersion, ECard, useChatGPTTextCompletionQuery } from '@graphql/generated';
import { useState } from 'react';
import { GiftWrapType } from '@apps/registry/guest/GuestRegistry.telemetry';

interface GiftNoteParams {
  prompt: string;
  fallback?: string;
  skip?: boolean;
  onCompleted: (note: string, prompt: string) => void;
}

export const useGenerateGiftNote = ({ prompt, fallback, skip, onCompleted }: GiftNoteParams) => {
  const [originalNote, setOriginalNote] = useState('');

  const { loading: isGeneratedMessageLoading } = useChatGPTTextCompletionQuery({
    variables: {
      prompt,
      modelVersion: AiModelVersion.gpt35turboInstruct
    },
    skip: skip || !!originalNote,
    batchMode: 'off',
    onCompleted(data) {
      const note = data.textCompletion?.substring(2);
      setOriginalNote(note);
      onCompleted(note, prompt);
    },
    onError() {
      setOriginalNote(fallback || '');
      onCompleted(fallback || '', prompt);
    }
  });

  return { originalNote, isGeneratedMessageLoading };
};

export const prepareECardPayload = (payload: { price: number; draftId: string; theme: string; imageURL?: string; offerType: GiftWrapType }): ECard => {
  // eCard processing requires price in minor units (cents with no decimals)
  const priceInMajorUnits = payload.price * 100;
  return {
    price: priceInMajorUnits,
    emailDraftId: payload.draftId,
    quantity: 1,
    type: 'GIFT_WRAP_BASIC',
    details: [{ key: 'theme', value: payload.theme }],
    imageURL: payload.imageURL,
    offerType: payload.offerType
  };
};

export const parseGiftWrapPayload = (payload: string) => {
  try {
    return JSON.parse(payload) as { message: string; themeId: string; image: string; store?: string; title: string; giftLink: string };
  } catch {
    return null;
  }
};

export const generateGiftWrapPrompt = ({
  firstPartner,
  secondPartner,
  context = 'wedding',
  from,
  giftName
}: {
  firstPartner: string;
  secondPartner?: string;
  context?: string;
  from: string;
  giftName: string;
}) => {
  const partners = `${firstPartner}${secondPartner ? ` and ${secondPartner}` : ''}`;

  return `Simple first-person ${context} gift note to ${partners} from ${from} for a ${giftName} in US english. Do not mention the relationship between the gift giver and the recipient`;
};
