import useLoadGoogleFont from '@apps/saveTheDate/hooks/useLoadGoogleFont';
import { GiftWrapDetails, GiftWrapPremiumDetails, IGiftWrapTheme } from '@shared/components';
import { createGlobalStyle, styled } from '@withjoy/joykit';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ReactComponent as ReplayIcon } from './replay.svg';
import { useWidthObserver } from './useWidthObserver';

export const GetMessageComponentContext = createContext<(() => JSX.Element) | undefined>(undefined);

const CommonVars = createGlobalStyle`
  :root {
    --card-width: 1000px; // Typical width is 390px -24px -24px = 342.
    --card-ratio: 1.4;
  }
`;

const ViewerVars = createGlobalStyle`
  :root {
    --card-wallpaper-position: fixed;

    // Making padding bottom be large enough so the viewport does not clip the shadow.
    // When overscrolling, we want to avoid the hard-edged clipping of that shadow.
    --card-wallpaper-padding: 40px 40px 120px 40px;

    --card-wallpaper-radius: 0;
    --card-wallpaper-min-height: 100vh;
    // These will change with media queries:
    --card-outer-width: calc(100vw - 2 * 24px);
    --card-outer-height: calc(var(--card-outer-width) * var(--card-ratio));
  }

  @media (min-aspect-ratio: 5/8) { // For non-mobile wider screens, "5/8" is derived from the 1.5 card-ratio
    :root {
      --card-outer-height: calc(100vh * 0.8);
      --card-outer-width: calc(var(--card-outer-height) / var(--card-ratio));
    }
  }

  body, #root {
    min-height: 100vh; // Unblock wallpaper from stretching to the bottom on very tall/long screens.
  }
`;

const EditorVars = createGlobalStyle`
  :root {
    --card-wallpaper-position: absolute;
    --card-wallpaper-padding: 1rem 0.5rem;
    --card-wallpaper-radius: 1px 0 3px 3px; // Manually matching outer container.
    --card-wallpaper-min-height: unset;
    // These will change with media queries:
    --card-outer-width: 100%;
    --card-outer-height: unset;
  }
`;

// Goal: Force a fixed wallpaper despite iOS Safari's reluctance.
// Reference: https://stackoverflow.com/questions/23236158/how-to-replicate-background-attachment-fixed-on-ios
// Wallpaper attached to a dedicated fixed element, rather than `background-attachment: fixed` on the viewport element.
const CardWallpaper = styled.div<{ $cardId: string; $isTall: boolean }>`
  position: var(--card-wallpaper-position);
  inset: 0;
  z-index: -1; // Counteract fixed positioning rendering above staticly positioned elements.
  background-image: url('https://withjoy.blob.core.windows.net/card/${props => props.$cardId}_wall1x${props => (props.$isTall ? '_tall' : '')}.jpg');
  background-size: cover;
`;

const CardViewport = styled.div`
  width: 100%;
  min-height: var(--card-wallpaper-min-height);
  padding: var(--card-wallpaper-padding);
  border-radius: var(--card-wallpaper-radius);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardChrome = styled.div`
  box-sizing: border-box;
  width: var(--card-outer-width);

  border-radius: 8px;
  box-shadow: 0px 30px 60px -30px rgba(0, 0, 0, 0.3), 0px 50px 100px -20px rgba(44, 41, 37, 0.25);
  background: white;

  // Want to use "overflow: hidden" and "clip-path: view-box" to enforce rounded corners on child elements.
  // However, the edit button needs to break out of bounds.
  // Thus, the child element will enforce rounded corners in a piecemeal fashion.
`;

const CardTop = styled.div`
  position: relative; /* layout parent for CardTopInner */

  // width naturally fills parent
  height: var(--card-outer-height);
  border-radius: 8px 8px 0 0;
  overflow: hidden; // In case the inner transform size has not caught up.

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardTopInner = styled.div`
  // Postition must not be relative.
  // We want to be agnostic of the parent frame.
  // Particularly don't want to be squeezed.
  // Also, layout parent for card visuals.
  position: absolute;

  width: var(--card-width);
  height: calc(var(--card-width) * var(--card-ratio));
  overflow: hidden;

  transform: scale(0.1);

  & * {
    // Careful, all decendants are absolutely positioned.
    position: absolute;
  }
`;

const CenteringContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReplayButton = styled.div`
  position: absolute;
  z-index: 1; // Make sure this sits above any subsquent children like a textarea.
  padding: 8px 16px 8px 16px;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & {
    background: hsla(0, 0%, 20%, 0.8);
  }
  &:hover {
    background: hsla(0, 0%, 35%, 0.8);
  }
  &:active {
    background: hsla(0, 0%, 0%, 0.8);
  }
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);

  color: white;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20.8px;
  word-wrap: break-word;

  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    transition: opacity 1s;
    pointer-events: unset;
  }
`;

const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface CardTemplateProps {
  cardId: string;
  details: GiftWrapPremiumDetails;
  theme: IGiftWrapTheme;
  showRestartDelay?: number;
  restart?: () => void;
  children?: React.ReactNode;
}

export function PremiumCardTemplate(props: CardTemplateProps) {
  useLoadGoogleFont(['Inter', 'Shadows Into Light Two']);

  const { cardId, details, theme, showRestartDelay, restart, children } = props;

  const getMessageComponent = useContext(GetMessageComponentContext);

  const [width, elementRef] = useWidthObserver();
  const scale = width / 1000;

  const [showReplay, setShowReplay] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowReplay(true), showRestartDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the message component is overridden, then we know we're within the editor.
  const isWithinEditor = !!getMessageComponent;

  return (
    <>
      <CommonVars />
      {isWithinEditor ? <EditorVars /> : <ViewerVars />}
      <CardWallpaper $cardId={cardId} $isTall={isWithinEditor} />
      <CardViewport>
        <CardChrome>
          <CardTop ref={elementRef}>
            <img
              alt="transparent aspect ratio placeholder"
              style={{ width: '100%' }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAACMCAYAAACK9Qy7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFGSURBVHgB7dFBAQAgDAChaf9o66Q17gEVOLv7how7pAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRESIyRGSIyQGCExQmKExAiJERIjJEZIjJAYITFCYoTECIkREiMkRkiMkBghMUJihMQIiRES8wGlDQR78ETj9QAAAABJRU5ErkJggg=="
            />
            <CardTopInner style={{ transform: `scale(${scale})` }}>{children}</CardTopInner>
          </CardTop>
          {restart && (
            <CenteringContainer>
              <ReplayButton className={showReplay ? 'visible' : ''} onClick={() => restart()}>
                <ReplayIcon style={{ marginRight: 8 }} />
                Replay
              </ReplayButton>
            </CenteringContainer>
          )}
          <CardBottom>
            <GiftWrapDetails theme={theme} product={details.product} message={details.message} showBranding={true} getMessageComponent={getMessageComponent} />
          </CardBottom>
        </CardChrome>
      </CardViewport>
    </>
  );
}
