import React from 'react';

import { useTranslation } from '@shared/core';
import { Box, ButtonV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { CartSections, ShippingAddressOptions } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';
import { ShippingAddressAtom, WarningAddressAtom } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/components';

import { AddressForm } from '../../../ShippingStep/AddressForm';
import { StyledAddressSectionWrapper, styles } from './AddressSection.styles';
import { StandardShippingOption } from '../../../../../components/AddressAtoms/StandardShipping';
import { ShippingStepProps } from '../../../ShippingStep';
import { useShoppingCart } from '../../../../../state';

export const AddressSection: React.FC<ShippingStepProps> = ({
  isFormOpen,
  setIsFormOpen,
  coupleAddress,
  shippingOptionSelected,
  setShippingOptionSelected,
  setSuggestedAddress,
  setAddressUsed,
  updateShippingOption,
  shippingAddressFormValues,
  customShippingAddress,
  saveAddress,
  formFields,
  formik,
  shippingFee
}) => {
  const { t2 } = useTranslation('guestRegistry');
  const { shippingMethod: shippingMethodLabel, freeShipping, shipTo, giftEmoji, anotherAddress, onlyUSAddress, shipToMe, weWillTellAboutPurchase } = t2(
    'shoppingCart',
    'shippingAddressStep'
  );
  const { addressTitle } = t2('shoppingCart', 'cartStep');
  const { standardShipping } = t2('shoppingCart', 'shippingAddressStep');
  const { editButton, saveButton, cancelButton } = t2('shoppingCart', 'shippingAddressStep', 'addressForm');
  const {
    mutators: { updateCurrentSection }
  } = useShoppingCart();

  const createAddressLabel = (address: string) => {
    return `${giftEmoji} ${address}`;
  };

  return (
    <StyledAddressSectionWrapper __css={styles.addressSectionWrapperStyles}>
      <TextV2 typographyVariant="hed4" textAlign="center" paddingBottom={8}>
        {addressTitle}
      </TextV2>
      <SpacingStack spacing={6} width="100%">
        <div>
          <TextV2 typographyVariant="label3" marginBottom={3}>
            {shippingMethodLabel}
          </TextV2>
          <StandardShippingOption label={shippingFee === '0.00' ? freeShipping : standardShipping} value={shippingFee ? '$' + shippingFee : ''} />
        </div>
        <div>
          <TextV2 typographyVariant="label3" marginBottom={3}>
            {shipTo}
          </TextV2>
          <SpacingStack spacing={3} width="100%">
            {!coupleAddress?.name ? (
              <>
                <WarningAddressAtom />
                <ShippingAddressAtom
                  isSelected={shippingOptionSelected === ShippingAddressOptions.AnotherAddress}
                  label={createAddressLabel(anotherAddress)}
                  note="TO DO"
                  onSelect={() => {
                    setShippingOptionSelected(ShippingAddressOptions.AnotherAddress);
                    setIsFormOpen(true);
                  }}
                  address={shippingAddressFormValues?.name ? shippingAddressFormValues : undefined}
                />
              </>
            ) : (
              <ShippingAddressAtom
                isSelected={shippingOptionSelected === ShippingAddressOptions.CoupleAddress}
                label={createAddressLabel(coupleAddress.name)}
                note={`${coupleAddress.city}, ${coupleAddress.state}, ${coupleAddress.postalCode}`}
                onSelect={() => {
                  updateShippingOption(ShippingAddressOptions.CoupleAddress);
                  setShippingOptionSelected(ShippingAddressOptions.CoupleAddress);
                  setIsFormOpen(false);
                }}
              />
            )}
            <ShippingAddressAtom
              isSelected={shippingOptionSelected === ShippingAddressOptions.ShipToMe}
              label={createAddressLabel(shipToMe)}
              note={weWillTellAboutPurchase}
              tooltip={onlyUSAddress}
              onSelect={() => {
                setShippingOptionSelected(ShippingAddressOptions.ShipToMe);
                if (customShippingAddress?.name) {
                  updateShippingOption(ShippingAddressOptions.ShipToMe);
                } else {
                  setIsFormOpen(true);
                }
              }}
              address={shippingAddressFormValues?.name ? shippingAddressFormValues : undefined}
            />
          </SpacingStack>
          {isFormOpen ? (
            <Box marginTop={6}>
              <AddressForm
                handleCancelClick={() => {
                  setIsFormOpen(false);
                }}
                handleSaveClick={() => {
                  setIsFormOpen(false);
                  saveAddress();
                }}
                formFields={formFields}
                setSuggestedAddress={setSuggestedAddress}
                setAddressUsed={setAddressUsed}
                formik={formik}
              />
            </Box>
          ) : (
            !!shippingAddressFormValues?.name && (
              <ButtonV2 marginTop={6} intent="neutral" variant="outline" onClick={() => setIsFormOpen(true)} fullWidth>
                {editButton}
              </ButtonV2>
            )
          )}
        </div>
      </SpacingStack>
      {!isFormOpen && (
        <SpacingStack spacing={5} marginTop={6}>
          <ButtonV2
            intent="neutral"
            onClick={() => {
              saveAddress();
              updateCurrentSection(CartSections.CART);
            }}
            type="submit"
            fullWidth
          >
            {saveButton}
          </ButtonV2>
          <ButtonV2 intent="neutral" variant="ghost" onClick={() => updateCurrentSection(CartSections.CART)} fullWidth>
            {cancelButton}
          </ButtonV2>
        </SpacingStack>
      )}
    </StyledAddressSectionWrapper>
  );
};
