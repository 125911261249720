import { Box, ButtonV2, styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

const defaultImage = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

export const itemPhotoStyles = ({ backgroundImage }: { backgroundImage: Maybe<string> }): CSSObject => ({
  borderRadius: '4px',
  width: '96px',
  height: '96px',
  alignSelf: 'center',
  position: 'relative',
  display: 'block',
  transition: 'all 0.2s',
  _after: {
    display: 'block',
    position: 'absolute',
    borderRadius: '8px',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    transition: animationTransition('opacity'),

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: 'scale(1.0001)',
    backgroundImage: `url("${backgroundImage || defaultImage}")`,
    backgroundSize: backgroundImage ? 'contain' : '50%'
  }
});

export const StyledItemPhoto = styled(Box)`
  ::after {
    content: ' ';
    mix-blend-mode: multiply;
  }
`;

export const StyledBoxImageNote = styled(Box)`
  display: flex;
  gap: 30px;
`;

export const BackButton = styled(ButtonV2)`
  position: absolute;
  top: 20px;
`;
