import { styled, Box, Flex, LinkV1, ButtonV1, Stack, TextV2, TextV2Props } from '@withjoy/joykit';
import { responsiveBreakpoints } from '@apps/registry/common/utils/responsiveHelpers';
import { SuccessGuestButton } from '../../components/SuccessGuestButton';
import { SuccessGuestLink, SuccessGuestLinkBold } from '../../components/SuccessGuestLink';

export const StyledSuccessGuestButton = SuccessGuestButton;
export const StyledSuccessGuestLink = SuccessGuestLink;
export const StyledSuccessGuestLinkBold = SuccessGuestLinkBold;

export const StyledPhotoWrapper = styled(Flex)<{ isMobile?: boolean | false; isHidden?: boolean | false }>`
  ${props =>
    props.isMobile
      ? `
      margin: auto;
      margin-bottom: 24px;
      width: 100%;
      height: 206px;
    `
      : `
        height: 100%;
        background-color: ${props.theme.colors.mono2};
        top: 0px;
        right: 0px;
        z-index: 1;
      ::before {}

    `}
`;

export const StyledColumns = styled(Box)<{ isMobile?: boolean | false; isDonationFund?: boolean }>`
  ${props =>
    props.isMobile
      ? `
      display: flex;
      flex-direction: column-reverse;
      padding: 0 0 40px;
      `
      : `
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${props.theme.colors.white};
      top: 0px;
      display: grid;
      direction: rtl;
      grid-template-columns: 40% 60%;
  `}
`;

export const StyledColumnsContribution = styled(Box)<{ isMobile?: boolean | false; isInModal: boolean }>`
  ${props =>
    props.isMobile
      ? `
      display: flex;
      flex-direction: column;
      padding: 0px 0px 40px;
      `
      : !props.isInModal
      ? `
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${props.theme.colors.white};
      top: 0px;
      display: grid;
      grid-template-columns: 60% 40%;
  `
      : ``}
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_1920}) {
    grid-template-columns: 50% 50%;
  }
`;

export const StyledBox = styled(Box)`
  border-bottom: 1px solid #f2f2f2;
`;

export const StyledText = styled(TextV2).attrs(() => ({ typographyVariant: 'body1' }))<TextV2Props>``;

export const StyledHeadText = styled(TextV2).attrs(() => ({ typographyVariant: 'hed4' }))<TextV2Props>``;

export const StyledBoldText = styled(TextV2).attrs<TextV2Props>(props => ({ typographyVariant: props.typographyVariant || 'body1' }))<TextV2Props>``;

export const StyledCancelLink = styled(SuccessGuestLinkBold).attrs<TextV2Props>(props => ({ typographyVariant: props.typographyVariant || 'button1' }))<TextV2Props>``;

export const StyledRegistryPhoto = styled(Box)<{ backgroundImage?: string | null }>`
  filter: grayscale(1);
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : undefined)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
  mix-blend-mode: multiply;
`;

export const Notification = styled.span<{ visible: boolean }>`
  color: ${props => props.theme.colors.fillLight};
  background-color: #000000bf;
  border-radius: 30px;
  padding: 12px 18px;
  position: fixed;
  bottom: 50px;
  left: 50vw;
  transform: translate(-50%, 0);
  transition: opacity 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  z-index: 9999;
  width: fit-content;
`;

const defaultImage = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';
export const StyledGiftProductBox = styled(Box)<{ backgroundImage?: string | null; isDonationFund?: boolean }>`
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : `url(${defaultImage})`)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: multiply;
  transform: scale(0.7);
  flex: auto;
  ${props =>
    !!props.backgroundImage
      ? `
    width: 100%;
    height: 100%;`
      : ``};
`;

export const StyledAddressFlex = styled(Flex)`
  font-family: Akkurat LL;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

export const StyleButton = styled(ButtonV1)`
  background: ${props => props.theme.colors.brandGreen5};
`;

export const StyledPhoto = styled(Flex)`
  height: 328px;
  width: 328px;
  background-color: ${props => props.theme.colors.mono2};
  align-self: center;
  align-items: center;
  justify-content: center;
`;

export const StyledGiftPhoto = styled(Box)<{ backgroundImage?: string | null }>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage || defaultImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: multiply;
  flex: auto;
  ${backgroundImage =>
    !!backgroundImage
      ? `
    width: 86%;
    height: 86%;`
      : ``};
`;

export const StyledLink = styled(LinkV1)`
  font-size: 17px;
  color: ${props => props.theme.colors.linkText};
`;

export const StyledContributeImage = styled(Box)<{ productImage?: string }>`
  background-image: ${({ productImage }) => (productImage ? `url(${productImage})` : `url(${defaultImage})`)};
  width: 160px;
  height: 160px;
  border-radius: 4px;
  background-size: cover;
  /* align-self: center; */
`;

export const StyledNoteBox = styled(Box)`
  padding: 24px;
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 8px;
  width: 100%;
`;

export const StyledContributeStack = styled(Stack)`
  display: flex;
  align-items: center;
`;

export const StyledContributeFlex = styled(Flex)<{ bigScreenAlignStart?: boolean }>`
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_1920}) {
    align-items: ${({ bigScreenAlignStart }) => (bigScreenAlignStart ? `flex-start` : `flex-end`)};
    padding: ${({ bigScreenAlignStart }) => (bigScreenAlignStart ? `0 0 0 160px` : `0 160px 0 0`)};
  }
`;

export const StyledCompletePurchaseImage = styled(Box)<{ productImage?: string; backgroundSize?: string }>`
  background-image: ${({ productImage }) => (productImage ? `url(${productImage})` : `url(${defaultImage})`)};
  width: 100%;
  height: 100%;
  border-radius: 2px;
  mix-blend-mode: multiply;
  background-size: ${({ backgroundSize }) => (backgroundSize ? backgroundSize : 'contain')};
  background-repeat: no-repeat;
  background-position: center;
`;

export const StyledLinkBox = styled(Box)`
  > a {
    text-decoration: underline;
  }
`;
