import React from 'react';
import { ButtonV1Props } from '@withjoy/joykit';
import { StyledSuccessGuestButton } from './SuccessGuestButton.styles';

interface Props extends ButtonV1Props {
  intent?: 'primary' | 'secondary';
  fill?: boolean;
  color?: 'string';
  loading?: boolean;
}

export const SuccessGuestButton: React.FC<Props> = ({ fill, intent, children, ...restProps }) => {
  return (
    <StyledSuccessGuestButton typographyVariant="body3" variant="success" intent={intent} fill={fill} {...restProps}>
      {children}
    </StyledSuccessGuestButton>
  );
};
