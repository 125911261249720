import { useCheckoutFunnel, useCheckoutInitDataContext } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useEventInfo } from '@shared/utils/eventInfo';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { CustomItemDataType } from '@graphql/generated';

export const useCheckoutTelemetryData = () => {
  const { formValues } = useCheckoutFunnel(({ confirmGiftCancel, abortGiftCancel, formValues }) => [confirmGiftCancel, abortGiftCancel, formValues]);

  const { productId } = useGuestRegistryState();
  const { registryItem } = useCheckoutInitDataContext();
  const { eventInfo } = useEventInfo();
  const customType = registryItem?.productData.customType ?? CustomItemDataType.gift;
  const valueInMinorUnits = registryItem?.productData.price?.valueInMinorUnits || 1;
  // hack to determine if this is a donation order to a group gift item:
  const isGroupGiftDonationOrder = registryItem?.isGroupGiftingEnabled && formValues.amount !== undefined;
  const isCash = isGroupGiftDonationOrder || customType !== CustomItemDataType.gift;

  return {
    eventId: eventInfo?.eventFirebaseId,
    registryId: eventInfo?.eventFirebaseId,
    registryItemId: registryItem?.id || '',
    productTitle: registryItem?.productData.title || eventInfo?.eventDisplayName || 'cash fund',
    // For cash fund items reservedQty is always 1 and priceValueInMinorUnits is the donation amount * 100
    reservedQty: isCash ? 1 : formValues.quantity || 1,
    priceValueInMinorUnits: isCash ? (formValues.amount || 1) * 100 : valueInMinorUnits,
    priceCurrencyCode: registryItem?.donationFund?.currency.code || DEFAULT_CURRENCY,
    destinationUrl: registryItem?.productData.checkoutUrl,
    typeOfItem: !isCash ? 'product' : registryItem?.donationFund?.fundType, // coerce type gift -> product for legacy telemetry
    productId
  };
};
