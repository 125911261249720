import React from 'react';
import { Box, SpacingStack, TextV2 } from '@withjoy/joykit';
import { StyledProductPhoto, styles, StylesProductPhotoContainer } from './GiftCard.styles';
import { addRendition } from '@shared/utils/photoRendition';
import { useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { useTranslation } from '@shared/core';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';

type Props = { hideMetadata: boolean; productImageUrl?: string; giftAmount: Maybe<string> };

export const GroupGiftingCard = ({ hideMetadata, productImageUrl, giftAmount }: Props) => {
  const { product } = useGetProductData('gifterDetails');

  const { formatCurrency } = useCurrencyFormatter();

  const { t } = useTranslation('guestRegistry');
  const { contributionAmount } = t('checkoutDialog');

  const formattedAmount = formatCurrency({
    priceFloatingPointDecimalString: giftAmount || '',
    priceCurrencyCode: DEFAULT_CURRENCY,
    formatForm: 'short',
    formatPrecision: 0
  });

  return (
    <Box __css={styles.groupGiftingCartStyles}>
      <StylesProductPhotoContainer __css={styles.productPhotoContainerStyles}>
        <StyledProductPhoto photoUrl={addRendition({ url: productImageUrl, renditionSize: 'medium', isProduct: true })} __css={styles.productPhotoStyles} />
      </StylesProductPhotoContainer>
      {!hideMetadata && (
        <SpacingStack spacing={1} marginTop={3}>
          {product?.brand && <TextV2 typographyVariant="hed1">{product.brand}</TextV2>}
          <TextV2 typographyVariant="hed1">{product?.title}</TextV2>
          <TextV2 typographyVariant="label2" color="mono10">
            {contributionAmount({ amount: formattedAmount })}
          </TextV2>
        </SpacingStack>
      )}
    </Box>
  );
};
