import { fontMapping } from '@shared/utils/fonts/fontMap';
import { withWindow } from '@shared/utils/withWindow';
import { TextV2 } from '@withjoy/joykit';
import React, { FC, useEffect } from 'react';
import { styles } from '../../EventPreview.styles';

type Props = {
  title?: string | null;
  font?: string;
};

export const EventTitle: FC<Props> = ({ font, title = '' }) => {
  let mappedFont = font;
  if (font && mappedFont) {
    if (fontMapping[font]) {
      mappedFont = fontMapping[font];
    }
  }

  useEffect(() => {
    // Check if window is defined to ensure code runs only in the client side
    withWindow(() => {
      // Dynamically import the webfontloader library
      import('webfontloader').then(WebFont => {
        WebFont?.load({
          google: {
            families: [mappedFont || '']
          }
        });
      });
    });
  }, [font, mappedFont]);
  return (
    <TextV2 fontFamily={mappedFont} fontSize={1600} lineHeight="normal" __css={styles.eventName}>
      {title}
    </TextV2>
  );
};
