import React, { SyntheticEvent } from 'react';
import { TextV2 } from '@withjoy/joykit';
import { EditMessageSource } from '../GiftWrapTypes';

interface GiftWrapMessageProps {
  message: string;
  textColor: string | undefined;
  onClick?: (source: EditMessageSource, event: SyntheticEvent) => void;
  cursor?: string;
}

export const giftWrapMessageStyles = {
  fontSize: '19px',
  lineHeight: '160%'
};

export const GiftWrapMessage: React.FC<GiftWrapMessageProps> = ({ message, textColor, onClick, cursor }) => {
  return (
    <TextV2
      key="area"
      padding={'2.5rem'}
      paddingTop={9}
      paddingBottom={0}
      color={textColor ?? 'mono12'}
      fontFamily="'Shadows Into Light Two', cursive"
      whiteSpace="pre-line"
      fontSize={giftWrapMessageStyles.fontSize}
      lineHeight={giftWrapMessageStyles.lineHeight}
      letterSpacing={'0.03em'}
      width="100%"
      userSelect={'none'}
      onClick={event => onClick?.(EditMessageSource.EDIT_TEXT, event)}
      cursor={cursor}
    >
      {message}
    </TextV2>
  );
};
