import { CustomItemDataType, DonationFundFragment, DonationFundPaymentMethodEnum } from '@graphql/generated';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { paymentMethodOptions } from '@shared/utils/paymentMethodOptions';
import { getCurrencySymbolFromCurrencyCode } from '@shared/utils/currency/utils';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { getDonationPaymentMethodFromPlatformType } from '@apps/registry/guest/components/CheckoutDialog/machines/externalCheckout/utils';

export const getFormattedPrice = (price: number) => (price % 1 === 0 ? price : price.toFixed(2));
export const getCurrencySymbol = (product?: CookedProduct) => getCurrencySymbolFromCurrencyCode(product?.donationFund?.fund?.currency?.code || DEFAULT_CURRENCY);
export const getDonationFundFund = (product?: CookedProduct) => product?.donationFund?.fund;
export const getIsCashFund = (fund?: DonationFundFragment) => fund?.fundType === CustomItemDataType.cash;
export const getPlatformType = (fund?: DonationFundFragment) => getDonationPaymentMethodFromPlatformType(fund?.platform?.type) || '';
export const getSuggestedDonation = (fund: Maybe<DonationFundFragment>) =>
  fund?.enforceSuggestedDonationAmount && fund?.suggestedDonationMonetaryValue && fund?.suggestedDonationMonetaryValue?.valueInMinorUnits!
    ? fund?.suggestedDonationMonetaryValue?.valueInMinorUnits! / 100
    : undefined;
export const getIsDonationPlatformTypeOther = (platformType: DonationFundPaymentMethodEnum | '') => platformType === DonationFundPaymentMethodEnum.other;
export const getSuggestedQuantityStillNeeded = (fund: Maybe<DonationFundFragment>) => fund?.suggestedQuantityStillNeeded || 0;
export const getDonationFundGoal = (fund: Maybe<DonationFundFragment>) => (fund?.goalMonetaryValue?.valueInMinorUnits ? fund?.goalMonetaryValue?.valueInMinorUnits / 100 : 0);
export const getDonationFundProgressToGoal = (fund: Maybe<DonationFundFragment>) => (fund?.progressToGoal?.valueInMinorUnits ? fund?.progressToGoal?.valueInMinorUnits / 100 : 0);
export const getIsMismatchedSuggestions = (fund: Maybe<DonationFundFragment>) =>
  getSuggestedQuantityStillNeeded(fund) * (getSuggestedDonation(fund) ?? 0) + getDonationFundProgressToGoal(fund) > getDonationFundGoal(fund);
export const getEnableCashOrCheck = (fund?: DonationFundFragment) => fund?.enableCashOrCheck;
export const getPaymentMethodFormattedName = (platformType: string) => paymentMethodOptions.find(option => option.value === platformType)?.label;
