import { styled, Box, StyleSystemProps, TextV2 } from '@withjoy/joykit';

export const StyledProductTitle = styled(TextV2)`
  word-break: break-word;
`;
export const StyledProductPhoto = styled(Box)``;

const productPhotoStyles: StyleSystemProps = {
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  alignSelf: 'center',
  mixBlendMode: 'multiply'
};

export const styles = {
  productPhoto: productPhotoStyles
};
