import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

export const StyledDetailSectionContainer = styled(Box)``;
export const StyledSectionHeader = styled(Box)``;
export const StyledDetailSectionRow = styled(Flex)``;
export const StyledDetailSectionHeading = styled(Box)``;
export const StyledDetailSectionBody = styled(Box)`
  & ul > li {
    list-style: disc outside;
  }
`;

const detailSectionContainerStyles: CSSObject = {
  maxWidth: '100%',
  position: 'relative',

  _after: {
    position: 'absolute',
    content: '" "',
    height: '1px',
    width: '100%',
    bottom: 0,
    boxShadow: '0 -1px 0 0 black'
  }
};

const accordionStyles: StyleSystemProps = {};

const accordionItemStyles: StyleSystemProps = {
  // Changes to `marginX` must be synced with `accordionButtonStyles.paddingX`
  marginX: -6,
  position: 'relative',
  paddingY: [7, 5],
  _after: {
    position: 'absolute',
    content: ' ',
    height: '1px',
    width: 'calc(100% - 48px) !important', // 48px = 2 * 24px = 2 * accordionButtonStyles.paddingX
    left: 6, // accordionButtonStyles.paddingX
    bottom: 0,
    boxShadow: '0 -1px 0 0 black'
  }
};

const accordionButtonStyles: StyleSystemProps = {
  paddingX: 6,
  paddingY: [3, 5]
};

const accordionPanel: StyleSystemProps = {
  paddingTop: [3],
  paddingX: 6
};

const detailSectionRow: CSSObject = {
  flexDirection: 'column',
  paddingX: 1,
  _notFirst: {
    marginTop: 6
  }
};

const detailSectionHeader: StyleSystemProps = {
  typographyVariant: 'button1'
};

const detailSectionHeading: StyleSystemProps = {
  typographyVariant: 'button1',
  marginRight: [0, null, 6]
};

const detailSectionBody: CSSObject = {
  typographyVariant: 'body1',
  marginTop: 5,
  marginLeft: 1,
  ['& p:not(:first-of-type)']: {
    marginTop: 7
  },
  ['& strong']: {
    display: 'block',
    typographyVariant: 'hed1',
    marginBottom: [3]
  },
  ['& ul']: {
    paddingLeft: 5
  },
  ['& a']: {
    color: 'linkText',
    _hover: {
      color: 'linkHover'
    },
    _active: {
      color: 'linkActive'
    }
  }
};

export const styles = {
  detailSectionContainer: detailSectionContainerStyles,
  accordion: accordionStyles,
  accordionButton: accordionButtonStyles,
  accordionItem: accordionItemStyles,
  accordionPanel,
  detailSectionRow,
  detailSectionHeader,
  detailSectionHeading,
  detailSectionBody
};
