export type PhotoMenuIntent = 'default' | 'destructive';

export interface PhotoMenuItem {
  value: PhotoOperation;
  icon: JSX.Element;
  intent: PhotoMenuIntent;
  color?: string;
}

export enum PhotoOperation {
  delete = 'delete',
  update = 'update',
  reset = 'reset'
}

export interface PhotoMenuProps {
  children?: JSX.Element;
  photoExists: boolean;
  hasDefaultPhoto?: boolean;
  handleUpdatePhoto?: () => void;
  handleDeletePhoto?: () => void;
  handleResetPhoto?: () => void;
}
