import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSpring } from 'react-spring';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { useAnnotateRegistryOrderMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { TrackingSectionProps } from './TrackingSection';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

export interface ReserveItemFields {
  orderNumber: Maybe<string>;
  trackingNumber: Maybe<string>;
}

export const useTrackingSectionController = ({ orderId, fulfillment }: TrackingSectionProps) => {
  const [isTrackingOpen, setIsTrackingOpen] = useState<boolean>(false);
  const { trackingInfoAddOnShoppingCart, trackingInfoSubmitOnShoppingCart } = useGuestRegistryTelemetry();
  const { t2 } = useTranslation('guestRegistry');
  const translations = t2('trackingSection');

  const [annotateRegistryOrder, { loading }] = useAnnotateRegistryOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });

  const handleOnClickSave = async ({ trackingNumber, orderNumber }: ReserveItemFields) => {
    await annotateRegistryOrder({ variables: { id: orderId, payload: { orderAnnotations: { trackingNumber, orderNumber } } } });
    trackingInfoSubmitOnShoppingCart();
    setIsTrackingOpen(false);
  };

  const springProps = useSpring({
    opacity: isTrackingOpen ? 1 : 0,
    display: isTrackingOpen ? 'block' : 'none',
    config: {
      duration: 1000,
      easing: cubicBezierEasingFn
    }
  });

  const trackFormik = useFormik<ReserveItemFields>({
    initialValues: {
      orderNumber: fulfillment?.orderNumber || '',
      trackingNumber: fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber || ''
    },
    validationSchema: Yup.object<ReserveItemFields>({
      orderNumber: Yup.string().required(translations.orderNumberRequiredError),
      trackingNumber: Yup.string()
    }),
    onSubmit: values => handleOnClickSave(values)
  });

  const handleOnCancel = () => {
    trackFormik.setValues({
      orderNumber: fulfillment?.orderNumber || '',
      trackingNumber: fulfillment?.shipments?.[0]?.trackingInformation?.trackingNumber || ''
    });
    setIsTrackingOpen(false);
  };

  const openTrackinSection = () => {
    trackingInfoAddOnShoppingCart();
    setIsTrackingOpen(true);
  };

  return {
    isTrackingOpen,
    springProps,
    trackFormik,
    openTrackinSection,
    handleOnCancel,
    loading,
    translations
  };
};
