import { Flex, styled, StyleSystemProps, css, Box } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledQuantityStepperInput = styled(Box)`
  /* Disable up/down arrows from input[type='number'] */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type='number'] {
    -moz-appearance: textfield;
  }
`;

const quantityStepperInputStyles: StyleSystemProps = {
  textAlign: 'center',
  border: 'none',
  outline: 'none',
  typographyVariant: 'label2',
  _disabled: {
    backgroundColor: 'mono2',
    color: 'mono8',
    cursor: 'not-allowed'
  },
  height: pxToRem(48),
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  fontSize: pxToRem(15),
  lineHeight: pxToRem(23)
};

export const StyledQuantityToggleContainer = styled.button<{ hasError?: boolean }>`
  -moz-appearance: textfield;
  font-size: 16px;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${props => props.theme.colors.gray5};
  flex-direction: row;
  display: flex;
  width: ${pxToRem(140)};

  &:hover {
    border-color: ${props => props.theme.colors.green7};
  }

  ${props =>
    props.hasError &&
    css`
      border-color: ${props => props.theme.colors.inputBorderError};
      &:hover {
        border-color: ${props => props.theme.colors.inputBorderError};
      }
    `}
`;

export const IconWrapper = styled(Flex)``;

const iconWrapperStyles: StyleSystemProps = {
  height: pxToRem(48),
  alignItems: 'center',
  paddingX: 3
};

export const styles = {
  iconWrapperStyles,
  quantityStepperInputStyles
};
