import React from 'react';
import { Stack, Flex } from '@withjoy/joykit';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { StyledHeadText, StyledText, StyledCancelLink, StyledSuccessGuestButton } from '../../Purchase.styles';
import { ReactComponent as CheckListIcon } from '@assets/icons/icon-check-circle.svg';

interface ContributionConfirmationProps
  extends Readonly<{
    onBackClick: () => void;
    handleUndoClick: () => void;
    isDonationFund: boolean;
    isInModal?: boolean;
  }> {}

export const ContributionConfirmation: React.FC<ContributionConfirmationProps> = ({ onBackClick, handleUndoClick, isDonationFund, isInModal }) => {
  const { getContributeToFundTranslations } = useRegistryGuestTranslations();
  const {
    contributeConfirmationTitle,
    contributeConfirmationTitleProduct,
    contributeCancelQuestion,
    contributeCancelText,
    contributeBackToRegistryText
  } = getContributeToFundTranslations();
  return (
    <>
      <Stack spacing={6} style={{ alignItems: 'center', width: '100%' }} marginBottom={7}>
        <Flex alignSelf="center">
          <CheckListIcon />
        </Flex>
        <StyledHeadText textAlign="center">{isDonationFund ? contributeConfirmationTitle : contributeConfirmationTitleProduct}</StyledHeadText>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          <StyledText textAlign="center" marginRight={0} marginBottom={4}>
            {contributeCancelQuestion}
          </StyledText>
          <StyledCancelLink onClick={handleUndoClick}>{contributeCancelText}</StyledCancelLink>
        </Flex>
      </Stack>
      {!isInModal && (
        <StyledSuccessGuestButton intent="secondary" fill={true} onClick={onBackClick}>
          {contributeBackToRegistryText}
        </StyledSuccessGuestButton>
      )}
    </>
  );
};
