import React from 'react';
import { MinusIcon, PlusIcon, TrashIcon } from '@assets/icons';
import { StyledFlex, StyledButtonV2, StyledInput, styles } from './ItemCounter.styles';

interface ItemCounterProps {
  increaseCounter: () => void;
  decreaseCounter: () => void;
  disableIncrease: boolean;
  counter: number;
  disableRemoveOption?: boolean;
}

export const ItemCounter: React.FC<ItemCounterProps> = ({ increaseCounter, decreaseCounter, disableIncrease, counter, disableRemoveOption = false }) => {
  return (
    <StyledFlex __css={styles.flex}>
      <StyledButtonV2 onClick={decreaseCounter} isDeleteIcon={counter === 1} disabled={counter === 1 && disableRemoveOption}>
        {counter === 1 && !disableRemoveOption ? <TrashIcon /> : <MinusIcon />}
      </StyledButtonV2>
      <StyledInput __css={styles.box}>{counter}</StyledInput>
      <StyledButtonV2 onClick={increaseCounter} disabled={disableIncrease}>
        <PlusIcon />
      </StyledButtonV2>
    </StyledFlex>
  );
};
