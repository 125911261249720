import { DonationFundPaymentMethodEnum } from '@graphql/generated';

export type GiveGiftDialogFields = {
  email: string;
  name: string;
  amount?: number | '';
  quantity?: number;
  note?: string;
  platformType?: DonationFundPaymentMethodEnum | '';
  paymentMethod?: DonationFundPaymentMethodEnum;
  orderNumber?: string;
  trackingNumber?: string;
};

export const donationFundPlatformTypes = Object.values(DonationFundPaymentMethodEnum);
