import { IconV2 } from '@withjoy/joykit';
import { ChevronBack } from '@withjoy/joykit/icons';
import React from 'react';
import { StyledGoBackButton, StyledGoBackWrapper, styles } from './BackButton.styles';

interface BackButtonProps {
  handleBackClick: () => void;
  text: React.ReactNode;
  disabled?: boolean;
}

export const BackButton = ({ handleBackClick, text, disabled }: BackButtonProps) => {
  return (
    <StyledGoBackWrapper __css={styles.goBackWrapper}>
      <StyledGoBackButton
        color="mono14"
        typographyVariant="body1"
        variant="link"
        startIcon={
          <IconV2 size="sm">
            <ChevronBack />
          </IconV2>
        }
        onClick={handleBackClick}
        _hover={{ color: 'black', textDecoration: 'none' }}
        _focus={{ color: 'black' }}
        _active={{ color: 'black' }}
        paddingTop={{ _: 4, sm2: 6, sm4: 5 }}
        paddingLeft={{ _: 5, sm2: 6 }}
        disabled={disabled}
      >
        {text}
      </StyledGoBackButton>
    </StyledGoBackWrapper>
  );
};
