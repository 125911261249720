import { StyleSystemProps, TextV2, Box, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledPurchaseInfoText = styled(TextV2)``;
const purchaseInfoTextStyles: StyleSystemProps = {
  wordBreak: 'break-word'
};

export const StyledTrackingInfoBox = styled(Box)``;
const trackingInfoBoxStyles: StyleSystemProps = {
  marginX: 5,
  marginY: 6
};

export const StyledPurchasedItemBox = styled(Box)``;
const purchasedItemBoxStyles: StyleSystemProps = {
  borderRadius: `${pxToRem(8)}`,
  overflow: 'hidden',
  paddingBottom: `${pxToRem(8)}`
};

export const StylePurchaseOrderBox = styled(Box)`
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
`;

export const styles = {
  purchaseInfoText: purchaseInfoTextStyles,
  trackingInfoBox: trackingInfoBoxStyles,
  purchasedItemBox: purchasedItemBoxStyles
};
