import { StyleSystemProps, TextV2, styled } from '@withjoy/joykit';

export const StyledPurchaseInfoText = styled(TextV2)``;

const purchaseInfoTextStyles: StyleSystemProps = {
  wordBreak: 'break-word'
};

export const styles = {
  purchaseInfoText: purchaseInfoTextStyles
};
