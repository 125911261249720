import { useMemo } from 'react';
import { useGetDataForGreetingCardQuery } from '@graphql/generated';
import { withWindow } from '@shared/utils/withWindow';

export const greetingCardBaseUrl = new URL(withWindow(global => `${global.location.origin}/card`, 'https://withjoy.com/card')).toString();

export enum GreetingCardType {
  RSVP = 'rsvp',
  RSVP_BASIC = 'rsvp-basic',
  GIFT_WRAP = 'giftWrap'
}

export const useGreetingCardController = (ecardId: string) => {
  const { data, loading: dataLoading, error: dataError } = useGetDataForGreetingCardQuery({
    variables: {
      ecardId: ecardId as string
    },
    skip: !ecardId,
    batchMode: 'fast',
    fetchPolicy: 'no-cache'
  });
  const ecardDataFromQuery = useMemo(() => data?.getGreetingCardById, [data]);

  const { type, theme, details } = useMemo(() => {
    const type = ecardDataFromQuery?.type || '';
    const theme = ecardDataFromQuery?.theme || '';
    const details = ecardDataFromQuery?.cardDetails || {};

    return {
      type,
      theme,
      details
    };
  }, [ecardDataFromQuery]);

  const loading = dataLoading;
  const error = !!dataError;

  const ecardData = {
    type,
    theme,
    details
  };

  return {
    ecardData,
    loading,
    error
  };
};
