import { styled } from '@withjoy/joykit';

interface CheckboxProps {
  checkedBackgroundColor?: string;
}

interface LabelProps {}

export const Input = styled.input<CheckboxProps>`
  display: none;
  :checked + label .checkbox_svg__checkmark {
    opacity: 1;
  }

  :checked + label .checkbox_svg__check-circle {
    fill: ${({ checkedBackgroundColor }) => checkedBackgroundColor || '#66bab5'};
    stroke: ${({ checkedBackgroundColor }) => checkedBackgroundColor || '#66bab5'};
  }
`;

export const Label = styled.label<LabelProps>`
  width: 24px;
  height: 24px;
  display: block;
  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
  .checkbox_svg__checkmark {
    opacity: 0;
    transition: opacity 0.25s ease-out;
  }
  :hover {
    cursor: pointer;
  }
`;
