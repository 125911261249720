import { useCookedProductList } from '@apps/registry/common/selectors/ProductListSelector';
import { getDonationFundFund } from '@apps/registry/guest/routes/GuestRegistry/components/GiveGiftDialog/util';
import {
  DonationFundDonationFragment,
  DonationFundPaymentMethodEnum,
  ECard,
  useAnnotateRegistryOrderMutation,
  useCreateDonationFundDonationWithResultMutation,
  useCreateRegistryPurchaseContextMutation,
  useCreateSingleRegistryItemOrderMutation,
  useDeleteRegistryOrderMutation,
  useGetDonationFundDonationByIdLazyQuery,
  usePurchaseRegistryOrderMutation,
  useUpdateRegistryItemMutation
} from '@graphql/generated';
import { parseGiftWrapPayload, prepareECardPayload, useGiftWrapECardPrice, useSaveCardDraft } from '@shared/components';
import { withWindow } from '@shared/utils/withWindow';
import { useCallback, useEffect, useState } from 'react';
import { useRoutePaths } from '../../GuestRegistry.routes';
import { removeItemFromPurchaseClickHistory } from '../../routes/GuestRegistry/components/ShoppingCart/utils/trackPurchaseClicks';
import { useGuestRegistryState } from '../../state';
import { CheckoutDialogControllerProps, CheckoutDialogFields } from './CheckoutDialog.types';
import { useCheckoutAllowGWByLocation } from './hooks/useCheckoutAllowGWByLocation/useCheckoutAllowGWByLocation';
import { GiftWrapType } from '../../GuestRegistry.telemetry';

const defaultDataFetchingConfig = {
  refetchQueries: ['GetEventRegistriesAndOrders']
};

const useCheckoutDialogController = ({ registryState, registryItemId, currentOrderId, isAffiliate, isGroupGifting, eventId }: CheckoutDialogControllerProps) => {
  const [currentOrderIdState, setCurrentOrderIdState] = useState<string | undefined | null>(currentOrderId ?? null);
  const { guest, productId, orders } = useGuestRegistryState();
  const cookedProductList = useCookedProductList(registryState?.registry, orders, 'everything');
  const product = cookedProductList.products.filter(({ id }) => id === registryItemId)[0];
  const donationFund = getDonationFundFund(product);
  const routes = useRoutePaths();
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();
  const giftWrapLocationInfo = useCheckoutAllowGWByLocation();
  const [creditCardError, setCreditCardError] = useState('');

  const [getRegistryOrderById, { data: existingDonationFundDonationResponse }] = useGetDonationFundDonationByIdLazyQuery();
  const [createDonationResult] = useCreateDonationFundDonationWithResultMutation(defaultDataFetchingConfig);
  const [createRegistryOrder] = useCreateSingleRegistryItemOrderMutation(defaultDataFetchingConfig);
  const [deleteRegistryOrder] = useDeleteRegistryOrderMutation(defaultDataFetchingConfig);
  const [purchaseRegistryOrder] = usePurchaseRegistryOrderMutation(defaultDataFetchingConfig);
  const [annotateRegistryOrder, { loading: annotateRegistryOrderLoading }] = useAnnotateRegistryOrderMutation(defaultDataFetchingConfig);
  const [updateRegistryItem] = useUpdateRegistryItemMutation();
  const [createPurchaseContext] = useCreateRegistryPurchaseContextMutation();
  const [saveCardDraft] = useSaveCardDraft();

  const existingDonation = existingDonationFundDonationResponse?.getDonationFundDonationById as DonationFundDonationFragment;

  useEffect(() => {
    if (!currentOrderId) return;
    setCurrentOrderIdState(currentOrderId);
  }, [currentOrderId]);

  useEffect(() => {
    if (!currentOrderIdState) return;
    getRegistryOrderById({ variables: { registryOrderId: currentOrderIdState } });
  }, [currentOrderIdState, getRegistryOrderById]);

  const handleCompletePurchase = async (
    { name: nameField, email: emailField, amount, quantity, note, paymentMethod }: CheckoutDialogFields,
    callback: (() => void) | undefined | null = undefined
  ) => {
    const name = guest?.name || nameField;
    const email = guest?.email || emailField;
    const isProductInCart = !!currentOrderIdState;

    if (registryItemId && name && email) {
      if (!currentOrderIdState) {
        const orderId = await handleAddToCart({ name: nameField, email: emailField, amount, quantity, note, paymentMethod });
        if (orderId) {
          purchaseRegistryOrder({ variables: { id: orderId } });
        }
      }
      if (currentOrderIdState) {
        await purchaseRegistryOrder({ variables: { id: currentOrderIdState } });
      }

      // NOTE: This step is being proceeded only if the product has been in cart
      if (!isProductInCart || !productId) return;
      removeItemFromPurchaseClickHistory({ reservedOrderId: currentOrderIdState, productId });
    }

    callback?.();
  };

  const handleAddToCart = useCallback(
    async (values: CheckoutDialogFields) => {
      const { email, name, amount, note, paymentMethod, quantity } = values;
      if (!registryItemId || !name || !email || currentOrderIdState) {
        return;
      }
      let orderId;

      if (quantity && (isAffiliate || (isGroupGifting && !amount && productId))) {
        if (isGroupGifting && productId) {
          await updateRegistryItem({
            variables: {
              id: productId,
              payload: { totalRequested: 1, isGroupGiftingEnabled: false }
            }
          });
        }
        const result = await createRegistryOrder({
          variables: {
            payload: {
              registryItemId,
              name,
              email,
              quantity,
              note,
              isPurchased: false
            }
          }
        });
        orderId = result.data?.createSingleRegistryItemOrder.model?.id || '';
        const orderExists = !!result.data?.createSingleRegistryItemOrder.alreadyExists;
        if (orderExists) {
          const existingOrder = orders?.find(ord => ord.email === email && ord.lineItems.some(item => item.registryItem?.id === product.id));
          orderId = existingOrder?.id;
        }
        setCurrentOrderIdState(orderId);
        return orderId;
      } else {
        if (paymentMethod === DonationFundPaymentMethodEnum.joyCredit) return;
        const result = await createDonationResult({
          variables: {
            payload: {
              fundId: donationFund!.id,
              amountMonetaryMinorValue: amount ? amount * 100 : 0,
              name,
              email,
              note,
              platformType: paymentMethod ? DonationFundPaymentMethodEnum[paymentMethod] : null
            }
          }
        });

        orderId = result.data?.createDonationFundDonationWithResult.registryOrder?.id;
        const orderExists = result.data?.createDonationFundDonationWithResult.alreadyExists;
        if (orderExists) {
          const existingOrder = orders?.find(ord => ord.email === email && ord.lineItems.some(item => item.registryItem?.id === product.id));
          orderId = existingOrder?.id;
        }
        setCurrentOrderIdState(orderId);
        return orderId;
      }
    },
    [createDonationResult, createRegistryOrder, currentOrderIdState, donationFund, isAffiliate, isGroupGifting, orders, product.id, productId, registryItemId, updateRegistryItem]
  );

  const handleRemoveFromCart = useCallback(async () => {
    if (!currentOrderIdState) return;

    await deleteRegistryOrder({
      variables: {
        id: currentOrderIdState
      }
    });

    if (product?.id) {
      removeItemFromPurchaseClickHistory({ reservedOrderId: currentOrderIdState, productId: product.id });
    }
  }, [currentOrderIdState, deleteRegistryOrder, product.id]);

  const handleAnnotateRegistryOrder = useCallback(
    async (values: CheckoutDialogFields, callback?: () => void) => {
      if (!currentOrderIdState) return;
      const result = await annotateRegistryOrder({
        variables: { id: currentOrderIdState, payload: { orderAnnotations: { orderNumber: values.orderNumber, trackingNumber: values.trackingNumber } } }
      });
      callback?.();
      return { result, loading: annotateRegistryOrderLoading };
    },
    [annotateRegistryOrder, annotateRegistryOrderLoading, currentOrderIdState]
  );

  const handleCreatePurchaseContext = useCallback(
    async (values: CheckoutDialogFields, registryItemId: string, isAffiliate: boolean, giftName: string, giftPrice: number) => {
      const failureUrlTemplate = withWindow(global => global.location.origin + routes.guestRegistry.path, '');
      const successUrlTemplate = new URL(
        `${withWindow(
          global => global.location.origin + routes.guestRegistry.path,
          ''
        )}?pcId=\${purchaseContextId}&item_id=${registryItemId}&order_id=${currentOrderIdState}&is_affiliate=${isAffiliate.toString()}&item_name=${giftName}&item_price=${giftPrice}`
      ).toString();

      const payload = parseGiftWrapPayload(values.note || '');
      if (!payload) {
        return;
      }

      const draftId = await saveCardDraft(eventId, payload);

      if (!draftId) {
        return;
      }
      const checkoutUrl = await createPurchaseContext({
        variables: {
          payload: {
            ...(giftWrapLocationInfo?.address ? { address: giftWrapLocationInfo.address } : {}),
            eventId: eventId || '',
            email: values.email,
            name: values.name,
            failureUrlTemplate,
            successUrlTemplate,
            note: values.note,
            eCard: prepareECardPayload({
              price: registryGiftWrapEcardPrice,
              draftId,
              theme: payload.themeId,
              imageURL: payload?.image,
              offerType: isAffiliate ? GiftWrapType.AFFILIATE : GiftWrapType.CASH_FUND
            })
          }
        }
      });

      if (!checkoutUrl.data?.createRegistryPurchaseContext.checkoutUrl) {
        return;
      }

      window.open(checkoutUrl.data.createRegistryPurchaseContext.checkoutUrl, '_self');
    },
    [createPurchaseContext, currentOrderIdState, eventId, giftWrapLocationInfo.address, registryGiftWrapEcardPrice, routes.guestRegistry.path, saveCardDraft]
  );

  const handleCreditCardPurchase = useCallback(
    async (values: CheckoutDialogFields, registryItemId: string) => {
      const failureUrlTemplate = withWindow(global => global.location.origin + routes.guestRegistry.path, '');
      const successUrlTemplate = `${withWindow(global => global.location.origin + routes.guestRegistry.path + '/purchase', '')}?pcId=\${purchaseContextId}`;

      const payload = parseGiftWrapPayload(values.note || '');
      let eCard: ECard | undefined = undefined;
      if (payload) {
        const draftId = await saveCardDraft(eventId, payload);

        if (!draftId) {
          return;
        }

        eCard = prepareECardPayload({
          price: registryGiftWrapEcardPrice,
          draftId,
          theme: payload.themeId,
          imageURL: payload?.image,
          offerType: isAffiliate ? GiftWrapType.AFFILIATE : GiftWrapType.CASH_FUND
        });
      }

      const parsedAmount = Number(values.amount) * 100;
      const checkoutUrl = await createPurchaseContext({
        variables: {
          payload: {
            ...(giftWrapLocationInfo.address ? { address: giftWrapLocationInfo.address } : {}),
            eventId: eventId || '',
            email: values.email,
            name: values.name,
            note: values.note,
            failureUrlTemplate,
            successUrlTemplate,
            eCard,
            lineItems: [{ registryItemId, quantity: parsedAmount }]
          }
        }
      });
      if (!checkoutUrl.data?.createRegistryPurchaseContext.checkoutUrl) {
        return setCreditCardError('serverError');
      }

      window.open(checkoutUrl.data.createRegistryPurchaseContext.checkoutUrl, '_self');
    },
    [createPurchaseContext, eventId, routes.guestRegistry.path, giftWrapLocationInfo.address, registryGiftWrapEcardPrice, isAffiliate, saveCardDraft]
  );

  return {
    existingDonation,
    handleAddToCart,
    handleCompletePurchase,
    handleRemoveFromCart,
    currentOrderIdState,
    handleAnnotateRegistryOrder,
    handleCreatePurchaseContext,
    giftWrapLocationInfo,
    handleCreditCardPurchase,
    creditCardError
  } as const;
};

export default useCheckoutDialogController;
