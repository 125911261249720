import React from 'react';
import { Box, PublicBoxProps } from '@withjoy/joykit';
import { styles, StyledLayoutShellContainer, StyledContentPanelContainer, StyledProductImage, StyledMediaPanelContainer } from './LayoutShell.styles';
import { useProductImageGallery } from '@apps/registry/common/components/Catalog/components/ProductDetails/components/ProductImageGallery/useProductImageGallery';
import { SPUR_EXPERIENCES_BRAND } from '@apps/registry/common/util/constants';
import { MinimalCatalogProductItemDetails } from '@apps/registry/common/components/Catalog/Catalog.types';
import { ProductImageGallery } from '@apps/registry/common/components/Catalog/components/ProductDetails/components/ProductImageGallery';
import { ShippingByJoy } from '../DropshipProductDialogBody/components/ProductInformation/components';

type LayoutShellType = React.FC<PublicBoxProps> & {
  MediaPanel: typeof LayoutShellMediaPanel;
  ContentPanel: typeof LayoutShellContentPanel;
};

interface MediaPanelProps {
  productImageSrc?: string;
  children: React.ReactNode;
  backButtonContent?: React.ReactNode;
  fullBleedBackground?: boolean;
  pdpProduct?: Maybe<MinimalCatalogProductItemDetails>;

  renderJoyFulfillment?: boolean;
}

interface ContentPanelProps extends PublicBoxProps, Readonly<{}> {}

const LayoutShell: LayoutShellType = props => {
  return <StyledLayoutShellContainer __css={styles.layoutShellContainer} {...props} />;
};

const LayoutShellMediaPanel = ({ productImageSrc, backButtonContent, fullBleedBackground = false, pdpProduct, renderJoyFulfillment, children }: MediaPanelProps) => {
  const { handleOnClickMediaAsset: handleOnClickImage, mediaAssets, selectedMediaAsset: selectedImage } = useProductImageGallery({
    initialMediaAsset: pdpProduct?.mediaAssets?.[0],
    selectedProduct: pdpProduct
  });

  const isImageGallery = pdpProduct?.mediaAssets.length && pdpProduct.mediaAssets.length > 1;
  const isCoverImage = pdpProduct?.brand?.name === SPUR_EXPERIENCES_BRAND;

  return (
    <StyledMediaPanelContainer __css={styles.mediaPanelContainer(fullBleedBackground)}>
      {backButtonContent}
      {isImageGallery ? (
        <Box marginBottom={8} width="100%">
          <ProductImageGallery
            mediaAssets={mediaAssets}
            selectedMediaAsset={selectedImage}
            handleClickMediaAsset={handleOnClickImage}
            isCoverImage={isCoverImage}
            maxAmountOfStepsVisible={4}
            isInlineVariant
          />
        </Box>
      ) : productImageSrc ? (
        <StyledProductImage __css={styles.productImage(productImageSrc)} />
      ) : null}
      {renderJoyFulfillment && (
        <Box marginTop={6}>
          <ShippingByJoy />
        </Box>
      )}
      {children}
    </StyledMediaPanelContainer>
  );
};

const LayoutShellContentPanel = (props: ContentPanelProps) => {
  return <StyledContentPanelContainer __css={styles.contentPanelContainer} {...props} />;
};

LayoutShell.MediaPanel = LayoutShellMediaPanel;
LayoutShell.ContentPanel = LayoutShellContentPanel;

export { LayoutShell };
