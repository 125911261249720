import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '@apps/registry/guest/components/CheckoutDialog/hooks/useCheckoutInitData';
import { useHistory, useLocation, useTranslation } from '@shared/core';
import { CustomItemDataType } from '@graphql/generated';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useCallback } from 'react';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { addAction } from '@shared/utils/logger';

export const usePaperCheckoutInstructionsController = () => {
  const {
    toNext,
    formValues,
    route,
    context: { isGroupGifting, isGiftWrapPurchased },
    currentOrderIdState
  } = useCheckoutFunnel(({ toNext, formValues, context, route, currentOrderIdState }) => [
    toNext,
    formValues,
    context.isGroupGifting,
    context.isGiftWrapPurchased,
    route,
    currentOrderIdState
  ]);
  const { registryState } = useCheckoutDialogContext();
  const { registryItem } = useCheckoutInitDataContext('paperCheckoutInstructions');
  const { productId } = useGuestRegistryState();
  const { eventInfo } = useEventInfo();
  const { reserveAndPurchase } = useGuestRegistryTelemetry();
  const { t } = useTranslation('guestRegistry');
  const reserveItemTranslation = t('alreadyReserveSection');
  const location = useLocation();
  const history = useHistory();

  const translations = t('checkoutDialog', 'screens', 'paperCheckoutInstructionsGiftWrap');
  const isCashFundType = registryItem?.donationFund?.fundType === CustomItemDataType.cash;

  const handleGotItButton = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('pcId');
    queryParams.delete('order_id');
    queryParams.delete('item_id');
    queryParams.delete('is_affiliate');
    queryParams.delete('item_name');
    queryParams.delete('item_price');
    history.replace({
      search: queryParams.toString()
    });

    const reserveAndPurchaseData = {
      eventId: eventInfo?.eventFirebaseId,
      productId,
      registryItemId: registryItem?.id,
      reservedQty: 1,
      priceValueInMinorUnits: (formValues.amount || 0) * 100,
      destinationUrl: registryItem?.productData.externalUrl,
      typeOfItem: registryItem?.donationFund?.fundType,
      buttonLabel: translations.cta(),
      productTitle: registryItem?.productData.title || eventInfo?.eventDisplayName || 'cash fund',
      isGroupGiftingEnabled: isGroupGifting
    };
    reserveAndPurchase(reserveAndPurchaseData);
    addAction('reserveAndPurchase', reserveAndPurchaseData);
    toNext();
  }, [
    location.search,
    history,
    eventInfo?.eventFirebaseId,
    eventInfo?.eventDisplayName,
    productId,
    registryItem?.id,
    registryItem?.productData.externalUrl,
    registryItem?.productData.title,
    registryItem?.donationFund?.fundType,
    formValues.amount,
    translations,
    isGroupGifting,
    reserveAndPurchase,
    toNext
  ]);

  const steps = [translations.stepsGiftWrap.step1(), translations.stepsGiftWrap.step2(), translations.stepsGiftWrap.step3()];

  return {
    toNext: handleGotItButton,
    registryItem,
    translations,
    isCashFundType,
    shippingAddress: registryState?.shippingAddress,
    steps,
    isGiftWrapPurchased,
    isLoading: route === 'paperCheckoutInstructionsGiftWrapLoading',
    currentOrderIdState,
    reserveItemTranslation,
    formValues
  };
};
