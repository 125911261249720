import React from 'react';
import { TextV2, StackProps, Flex, Divider, SpacingStack, Box } from '@withjoy/joykit';
import {
  isProductFullyPurchased,
  ItemWithPrice,
  isCustomDonationFundItemNoGoal,
  isCustomDonationFundItemHideGoal,
  isCustomDonationFundItem,
  isPricedProduct
} from '@apps/registry/common/selectors/ProductListSelector';
import { useProgressTrackerTranslations } from '../RegistryItemProgressTracker/RegistryItemProgressTracker.i18n';
import { OrderList } from '@apps/registry/common/state/RegistryProducts';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

type CashFundProgressTrackerProps = Readonly<{
  product: ItemWithPrice;
  orders: OrderList;
}>;

export const RegistryItemProgressTrackerV2 = ({ product, orders }: CashFundProgressTrackerProps & Omit<StackProps, 'children'>) => {
  const { reserved, requested, donationFund, purchased, totalPrice } = product;
  const { getProductTileTranslations } = useRegistryGuestTranslations();
  const { contributionAnyAmountText, goalReachedText } = getProductTileTranslations();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const translations = useProgressTrackerTranslations(product, orders);
  const { t } = useTranslation('guestRegistry');
  const { remaining: getRemainingText, requested: getRequestedText, goalReachedWithPriceText } = t('productTile');

  const { enforceSuggestedDonationAmount, suggestedDonationMonetaryValue, goalMonetaryValue } = donationFund?.fund ?? {};

  const isCompleted = isProductFullyPurchased(product);
  const isCashOrCharityFund = isCustomDonationFundItem(product);
  const isFixedAmount = enforceSuggestedDonationAmount && suggestedDonationMonetaryValue && goalMonetaryValue?.valueInMinorUnits;
  const isHideGoal = isCustomDonationFundItemNoGoal(product) || isCustomDonationFundItemHideGoal(product);
  const isShowProgressBar = !product.externallyOwned && isPricedProduct(product) && isCashOrCharityFund;
  const isShowProductProgressBar = !product.externallyOwned && !isCashOrCharityFund && requested > 1;

  const getProgressPercentage = () => {
    if (donationFund) {
      return donationFund.goal ? Math.round((donationFund.received / donationFund.goal) * 100) : 0;
    }
    return requested ? Math.round(((reserved + purchased) / requested) * 100) : 0;
  };

  const getProgressText = () => {
    if (isCompleted) {
      return isHideGoal || isFixedAmount ? goalReachedText : goalReachedWithPriceText({ goal: product.totalPrice });
    }
    return isFixedAmount ? translations.contributionText : contributionAnyAmountText;
  };

  if (isShowProgressBar) {
    return (
      <SpacingStack stack="vertical" spacing={5} width="100%" alignItems="center" paddingTop={5}>
        <>
          <TextV2 typographyVariant="label1" color="mono10" lineHeight={pxToRem(18)}>
            {getProgressText()}
          </TextV2>
          {!isHideGoal && !isFixedAmount && (
            <>
              <Flex width="100%" height={pxToRem(8)} borderRadius={pxToRem(4)} backgroundColor="mono4">
                <Box width={`${getProgressPercentage()}%`} height={pxToRem(8)} borderRadius={pxToRem(4)} backgroundColor="green7" />
              </Flex>
              {!isCompleted && (
                <Flex color="mono10" justifyContent="space-between" width="100%">
                  <TextV2 typographyVariant="label1">{isMobile ? translations.formattedProgressAmount : translations.contributedText}</TextV2>
                  <TextV2 typographyVariant="label1" textAlign="right">
                    {isMobile ? totalPrice : translations.goalText}
                  </TextV2>
                </Flex>
              )}
            </>
          )}
        </>
      </SpacingStack>
    );
  }

  if (isShowProductProgressBar && !isCompleted) {
    return (
      <SpacingStack stack="vertical" spacing={5} width="100%" alignItems="center" paddingTop={5}>
        <Flex color="mono10" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" textAlign="center">
          {requested === product.stillNeeded ? (
            <TextV2 typographyVariant="label1">{getRequestedText({ amount: requested })}</TextV2>
          ) : (
            <>
              <TextV2 typographyVariant="label1">{getRequestedText({ amount: requested })}</TextV2>
              {!isMobile && <Divider orientation="vertical" color="mono3" marginX={1} />}
              <TextV2 typographyVariant="label1">{getRemainingText({ amount: product.stillNeeded })}</TextV2>
            </>
          )}
        </Flex>
      </SpacingStack>
    );
  }

  return null;
};
