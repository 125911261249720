import { useTranslation } from '@shared/core';

export const usePurchaseDialogTranslations = () => {
  const { t } = useTranslation('guestRegistry');
  const { t: translateForm } = useTranslation('generalFormValidation');

  const {
    hideDescription,
    readMore,
    howManyRequest,
    needed,
    outOfStock,
    formButtonBuyNow,
    formButtonAddToCart,
    formButtonMarkAsPurchased,
    fulfilledByJoy,
    fulfilledByExternal,
    questionLinkText,
    questionV2LinkText,
    purchaseErrorMsg,
    otherGifts,
    quantityTitle,
    subtotalTitle,
    quantityTimesPrice,
    isNotPurchasableText,
    fieldNameLabel,
    fieldEmailLabel,
    fieldNamePlaceholder,
    fieldEmailPlaceholder,
    fieldNoteLabel,
    fieldNotePlaceholder,
    changeEmail,
    requiredNameWarning,
    requiredEmailWarning,
    validEmailWarning,
    formButtonReservePurchase,
    formButtonAlreadyPurchase,
    itemAddedSuccess,
    descriptionLabel,
    detailsLinkText,
    noteLabel,
    formButtonAddCartReserve,
    freeShippingByJoy,
    everyJoyRegistryDeserves,
    freeShipping,
    priceMatchPromise,
    easyReturns,
    totalPriceTitleText,
    upgradeGiftNote,
    next,
    giftNoteTitle,
    shippingInfo,
    learnMore,
    freeShippingDescription,
    priceMatchPromiseDescription,
    easyReturnsDescription,
    fulfilledByJoyShort
  } = t('purchaseDialog');
  const { title, warningTitle, warningBody, shipTo, anotherAddressTitle, anotherAddressTooltip, anotherAddressBody, button } = t('purchaseDialog', 'missingCoupleAddress');
  const {
    title: otherAddressTitle,
    nameGhost,
    nameLabel,
    namePlaceholder,
    addressLine1Label,
    addressLine1Placeholder,
    addressLine2Label,
    cityLabel,
    stateLabel,
    postalCode,
    requiredFields,
    poBoxWarningCaption,
    button: otherAddressButton
  } = t('purchaseDialog', 'otherAddress');
  const { title: checkoutTitle, description: checkoutDescription, cta: checkoutCta } = t('checkoutDialog', 'screens', 'gifterDetails');
  const { title: alreadyReservedTitle, subTitle: alreadyReservedSubtitle, checkEmail: alreadyReservedCheckEmail } = t('alreadyReserveSection');
  const requiredLabel = translateForm('required');

  const missingCoupleAddressTranslations = {
    title: title(),
    warningTitle: warningTitle(),
    warningBody: warningBody(),
    shipTo: shipTo(),
    anotherAddressTitle: anotherAddressTitle(),
    anotherAddressTooltip: anotherAddressTooltip(),
    anotherAddressBody: anotherAddressBody(),
    button: button()
  };

  const otherAddressTranslations = {
    title: otherAddressTitle(),
    nameGhost: nameGhost(),
    nameLabel: nameLabel(),
    namePlaceholder: namePlaceholder(),
    addressLine1Label: addressLine1Label(),
    addressLine1Placeholder: addressLine1Placeholder(),
    addressLine2Label: addressLine2Label(),
    cityLabel: cityLabel(),
    stateLabel: stateLabel(),
    postalCode: postalCode(),
    requiredFields: requiredFields(),
    button: otherAddressButton(),
    poBoxWarningCaption: poBoxWarningCaption()
  };
  return {
    checkoutTitle,
    checkoutDescription,
    checkoutCta,
    fieldNameLabel,
    fieldNamePlaceholder,
    fieldEmailLabel,
    changeEmail,
    fieldEmailPlaceholder,
    fieldNoteLabel,
    fieldNotePlaceholder,
    alreadyReservedTitle,
    alreadyReservedSubtitle,
    alreadyReservedCheckEmail,
    hideDescription,
    readMore,
    howManyRequest,
    needed,
    outOfStock,
    formButtonBuyNow,
    formButtonAddToCart,
    formButtonMarkAsPurchased,
    fulfilledByJoy,
    fulfilledByExternal,
    questionLinkText,
    questionV2LinkText,
    purchaseErrorMsg,
    otherGifts,
    quantityTitle,
    subtotalTitle,
    quantityTimesPrice,
    isNotPurchasableText,
    requiredNameWarning,
    requiredEmailWarning,
    validEmailWarning,
    formButtonReservePurchase,
    formButtonAlreadyPurchase,
    itemAddedSuccess,
    requiredLabel,
    descriptionLabel,
    detailsLinkText,
    noteLabel,
    formButtonAddCartReserve,
    freeShippingByJoy,
    everyJoyRegistryDeserves,
    freeShipping,
    priceMatchPromise,
    easyReturns,
    totalPriceTitleText,
    missingCoupleAddressTranslations,
    otherAddressTranslations,
    upgradeGiftNote,
    next,
    giftNoteTitle,
    shippingInfo,
    learnMore,
    freeShippingDescription,
    priceMatchPromiseDescription,
    easyReturnsDescription,
    fulfilledByJoyShort
  };
};
