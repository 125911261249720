import { InterFonts } from '@shared/utils/fonts/interFonts';
import { animationTransition } from '@shared/utils/animationTransition';
import { styled } from '@withjoy/joykit';

export const StyledExpandedMessage = styled.p<{ $lineClamp: number | undefined }>`
  ${InterFonts.body};
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.$lineClamp};
  hyphens: auto;
`;

export const StyledExpandButton = styled.button`
  align-self: flex-start;
  color: ${props => props.theme.colors.linkText};
  transition: ${animationTransition('color')};
  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }
  &:active {
    color: ${props => props.theme.colors.linkActive};
  }
`;
