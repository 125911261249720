import { Box, DialogV2, ModalProps, OnCloseArg } from '@withjoy/joykit';
import React, { ReactNode } from 'react';
import { useTranslation } from '@shared/core';
import { PurchaseConfirmationInner } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationInner';
import { UndoneContent } from '../UndoneContent';
import { UndoContent } from '../UndoContent';
import { DonationFundPlatformTypeEnum } from '@graphql/generated';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { Contribute } from '../Contribute';

import { PurchaseConfirmationModalContent } from './components/PurchaseConfirmationModalContent';
import { ModalBase } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/ModalBase';
import {
  ModalStates,
  usePurchaseConfirmationModalController
} from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { ContributionConfirmation } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/ContributionConfirmation';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { ShippingAddressFragment } from '@graphql/aliases';
import { AddressConfirmationModalContent } from './components/AddressConfirmationModalContent';
import { getStoreName } from '@apps/registry/common/util/storeHelpers';
import { IntentToPurchaseModalContent } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/components/IntentToPurchaseModalContent';
import { useContribute } from '@apps/registry/guest/routes/Purchase/components/useContribute';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { CreateOrderAndPurchaseDialog } from './components/CreateOrderAndPurchase';
import { AddOrderTrackingDialog } from '../../../GuestRegistry/components/AddOrderOrderTrackingInfo';
import { getDonationPaymentMethodFromPlatformType } from '@apps/registry/guest/components/CheckoutDialog/machines/externalCheckout/utils';

type PurchaseConfirmationModalProps = {
  eventHandle: string;
  eventId: string;
  registry: RegistryList;
  isAdmin: boolean;
  shippingAddress: ShippingAddressFragment | null;
  onClose: (args: OnCloseArg) => void;
  productId: string;
  orderId: string;
  openToast: (toast: ToastVariableProps) => void;
  modalStateToBeOpened?: ModalStates;
};

export const PurchaseConfirmationModal = ({
  isOpen,
  onClose,
  registry,
  eventId,
  isAdmin,
  shippingAddress,
  eventHandle,
  productId,
  orderId,
  openToast,
  modalStateToBeOpened
}: ModalProps & PurchaseConfirmationModalProps) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { t } = useTranslation('guestRegistry');
  const {
    isDonation,
    platformType,
    handleCancelUndo,
    confirmAddressClick,
    backToAddressConfirmation,
    confirmationClick,
    showUndoConfirmationScreenLocal,
    onCloseModal,
    handleConfirm,
    handleHasPurchased,
    handleHasNotPurchased,
    handleUndo,
    handlePurchaseSuccess,
    item,
    productImage,
    quantity,
    modalState,
    modalStatePrevious,
    donationPlatformDomain,
    onCloseIntentModal,
    purchaseConfirmationDisplayedTelemetry,
    handlePurchaseLater
  } = usePurchaseConfirmationModalController({
    onClose,
    isAdmin,
    registry,
    eventId,
    eventHandle,
    productId,
    modalStateToBeOpened,
    openToast,
    orderId
  });
  const { priceString } = useContribute({
    item,
    quantity,
    isMobile: !!isMobile,
    orderId,
    orderDonationPlatform: getDonationPaymentMethodFromPlatformType(platformType) || undefined
  });

  const { thanksForBuying, contribute, purchaseGift } = t('undoPurchase');
  const retailer = item?.storeName || getStoreName(item?.productData?.externalUrl);

  let modalContent: ReactNode;
  if (modalState === ModalStates.INTENT_TO_PURCHASE) {
    return (
      <DialogV2 onClose={onCloseIntentModal} isOpen={isOpen} size="lg" enableDividers={false}>
        <DialogV2.CloseButton width="3rem" color="black" />
        <IntentToPurchaseModalContent
          openToast={openToast}
          handleConfirm={handlePurchaseLater}
          handleUndo={showUndoConfirmationScreenLocal}
          donationPlatformDomain={donationPlatformDomain}
          isDonation={isDonation}
        />
      </DialogV2>
    );
  } else if (modalState === ModalStates.CONFIRM_PURCHASED) {
    return (
      <DialogV2 onClose={onCloseModal} isOpen={isOpen} size="lg" enableDividers={false} disableCloseOnOutsideClick={true}>
        <DialogV2.CloseButton width="3rem" color="black" />
        <DialogV2.Body paddingX={[24, null, 80]} paddingY={40}>
          <Box>
            <PurchaseConfirmationInner
              shippingAddress={shippingAddress}
              handleConfirmationClick={handleHasPurchased}
              handleUndoClick={showUndoConfirmationScreenLocal}
              handleNotPurchased={handleHasNotPurchased}
              onBackClick={handleCancelUndo}
              productImage={productImage}
              productTitle={item?.productData?.title || null}
              isInModal
              isDonation={isDonation}
              priceString={priceString}
              fundTitle={item?.donationFund?.title}
              donationPlatformDomain={donationPlatformDomain}
              purchaseConfirmationDisplayedTelemetry={purchaseConfirmationDisplayedTelemetry}
            />
          </Box>
        </DialogV2.Body>
      </DialogV2>
    );
  } else if (modalState === ModalStates.CREATE_ORDER_AND_PURCHASE) {
    return (
      <CreateOrderAndPurchaseDialog
        onClose={onCloseModal}
        isOpen={isOpen}
        size="lg"
        disableCloseOnOutsideClick={true}
        product={item}
        handleCancelReservation={handleCancelUndo}
        handlePurchaseSuccess={handlePurchaseSuccess}
        eventId={eventId}
      />
    );
  } else if (modalState === ModalStates.THANK_YOU) {
    modalContent = <ContributionConfirmation onBackClick={handleCancelUndo} handleUndoClick={showUndoConfirmationScreenLocal} isDonationFund={isDonation} isInModal />;
  } else if (modalState === ModalStates.UNDO) {
    const undoMethodText = modalStatePrevious === ModalStates.CONFIRM_PURCHASED ? thanksForBuying() : isDonation ? contribute() : purchaseGift();
    modalContent = <UndoContent methodText={undoMethodText} handleCancelUndo={handleCancelUndo} handleUndo={handleUndo} />;
  } else if (modalState === ModalStates.UNDONE) {
    modalContent = <UndoneContent onBackClick={handleCancelUndo} isInModal />;
  } else if (
    platformType === DonationFundPlatformTypeEnum.other ||
    platformType === DonationFundPlatformTypeEnum.venmo ||
    platformType === DonationFundPlatformTypeEnum.cashapp ||
    isDonation
  ) {
    modalContent = (
      <Contribute
        handleClickProduct={handleConfirm}
        handleUndoClick={showUndoConfirmationScreenLocal}
        handleConfirmationClick={confirmationClick}
        quantity={quantity}
        item={item}
        productImage={productImage}
        isMobile={!!isMobile}
        shippingAddress={shippingAddress}
        isVenmo={platformType === DonationFundPlatformTypeEnum.venmo}
        orderId={orderId}
        orderDonationPlatform={getDonationPaymentMethodFromPlatformType(platformType) || undefined}
        isInModal
      />
    );
  } else if (modalState === ModalStates.TRACKING_ORDER) {
    return <AddOrderTrackingDialog giftName={item?.productData?.title} retailer={retailer} orderId={orderId} onClose={onCloseModal} isOpen={isOpen} openToast={openToast} />;
  }

  if (modalContent) {
    return (
      <ModalBase onCloseModal={onCloseModal} isOpen={isOpen}>
        {modalContent}
      </ModalBase>
    );
  }

  if (modalState === ModalStates.PURCHASE) {
    return (
      <DialogV2 onClose={onCloseModal} isOpen={isOpen} size="lg">
        <PurchaseConfirmationModalContent retailer={retailer} handleConfirm={handleConfirm} onClose={onCloseModal} item={item} onBack={backToAddressConfirmation} />
      </DialogV2>
    );
  }

  return (
    <DialogV2 onClose={onCloseModal} isOpen={isOpen} size="lg">
      <AddressConfirmationModalContent retailer={retailer} handleConfirm={confirmAddressClick} onClose={onCloseModal} shippingAddress={shippingAddress} item={item} />
    </DialogV2>
  );
};
