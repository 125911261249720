import React, { useMemo } from 'react';
import { InCartProduct } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';
import { InCartItem, EmptyCart } from './components';

interface InCartSectionProps
  extends Readonly<{
    inCartProductList: Array<InCartProduct>;
    inCartProductCount: number;
    inCartProductTotal: number;
    registryCurrencyCode?: string;
  }> {}

export const InCartSection: React.FC<InCartSectionProps> = ({ inCartProductList, inCartProductCount }) => {
  const sortedInCartProductList = useMemo(() => {
    const [inStockProductList, outOfStockProductList] = inCartProductList.reduce(
      (acc, product) => (product.inStock ? [[...acc[0], product], acc[1]] : [acc[0], [...acc[1], product]]),
      [[], []] as Array<Array<InCartProduct>>
    );
    return [...inStockProductList, ...outOfStockProductList];
  }, [inCartProductList]);

  return inCartProductList.length > 0 ? (
    <>
      {sortedInCartProductList.map(product => (
        <InCartItem key={product.registryItemId} inCartProduct={product} />
      ))}
    </>
  ) : (
    <EmptyCart />
  );
};
