import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useMemo } from 'react';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { ShippingAddressInput } from '@graphql/generated';
import { EAddressType, canShowGW } from './utils';
import { isAddressValid } from '@apps/registry/common/utils/shippingAddressValidations';

interface BillingAddressPayload {
  address: ShippingAddressInput;
}

export const isBillingAddressValid = (payload: unknown): payload is BillingAddressPayload => {
  if (!payload || typeof payload != 'object' || !('address' in payload) || typeof payload.address !== 'object') {
    return false;
  }

  return isAddressValid(payload.address);
};

export const useCheckoutAllowGWByLocation = () => {
  const { value: registryGiftWrapUseEventAddressOn } = useFeatureValue('registryGiftWrapUseEventAddress');
  const { value: registryGiftWrapDefaultUSABillingAddress, payload: registryGiftWrapDefaultUSABillingAddressPayload } = useFeatureValue('registryGiftWrapDefaultUSABillingAddress');
  const { registryState } = useCheckoutDialogContext();
  const { eventInfo } = useEventInfo();
  const { giftWrapAddressCheck } = useGuestRegistryTelemetry();

  return useMemo(() => {
    const eventLocation = eventInfo?.locationInfo;
    const registryAddress = registryState?.shippingAddress;

    // We will go through series of checks to see if we have a valid US address
    // that can be used for eCard purchase via the registry service
    const { address, allow, type } = canShowGW(
      registryAddress,
      // We only attempt to use event location if feature flag is on
      registryGiftWrapUseEventAddressOn ? eventLocation : undefined,
      // We only use default US address if feature flag is on and the address in the payload is valid
      registryGiftWrapDefaultUSABillingAddress && isBillingAddressValid(registryGiftWrapDefaultUSABillingAddressPayload)
        ? registryGiftWrapDefaultUSABillingAddressPayload.address
        : undefined
    );

    // log telemetry for outcome of this address check
    giftWrapAddressCheck({
      allow,
      label: type,
      registryAddress: registryAddress,
      eventLocation: eventLocation
    });
    let addressResponse = address;

    // if allow is false, or we are using the configured registry address,
    // addressResponse should be undefined so that the address is not used for purchaseContext
    // registry_service uses the configured shipping address by default so we don't need to send it in the payload
    if (!allow || type === EAddressType.US_REGISTRY) {
      addressResponse = undefined;
    }
    return {
      address: addressResponse,
      allow
    };
  }, [
    eventInfo?.locationInfo,
    registryGiftWrapDefaultUSABillingAddress,
    registryGiftWrapDefaultUSABillingAddressPayload,
    registryGiftWrapUseEventAddressOn,
    registryState?.shippingAddress,
    giftWrapAddressCheck
  ]);
};
