import { Box, StyleSystemProps, styled } from '@withjoy/joykit';

export const StyledVideoContainer = styled(Box)``;
export const StyledFallbackImage = styled(Box)``;

const videoContainerStyles: StyleSystemProps = {
  position: 'relative',
  overflow: 'hidden'
};

const fallbackImageStyles: StyleSystemProps = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'white',
  backgroundSize: 'contain',
  transition: 'opacity 1s ease-out'
};

export const styles = {
  videoContainer: videoContainerStyles,
  fallbackImage: fallbackImageStyles
};
