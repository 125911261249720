import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';
import { useAnnotateRegistryOrderMutation, useDeleteRegistryOrderMutation } from '@graphql/generated';
import { AddOrderTrackingDialogProps } from './AddOrderTrackingDialog';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

interface AddOrderTrackingInfoControllerProps extends Pick<AddOrderTrackingDialogProps, 'onClose' | 'orderId' | 'openToast' | 'giftName'> {}

export interface ReserveItemFields {
  orderNumber: string;
}

export const useAddOrderTrackingInfoController = ({ onClose, orderId, openToast, giftName }: AddOrderTrackingInfoControllerProps) => {
  const { t } = useTranslation('guestRegistry');
  const translations = t('orderTrackingDialog');
  const { addOrderTracking, cancelOrderTracking } = useGuestRegistryTelemetry();

  const [annotateRegistryOrder, { loading }] = useAnnotateRegistryOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });
  const [deleteRegistryOrder] = useDeleteRegistryOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });

  const handleOnClickSave = async ({ orderNumber }: ReserveItemFields) => {
    try {
      await annotateRegistryOrder({ variables: { id: orderId, payload: { orderAnnotations: { orderNumber } } } });
      addOrderTracking(orderNumber);
      openToast({ notificationText: translations.successMessage(), isPositionTop: true });
      onClose({ closeSource: 'custom' });
    } catch (error) {
      formik.setFieldError('orderNumber', translations.errorMessage());
    }
  };

  const handleOnCancelReservation = () => {
    cancelOrderTracking(giftName);
    deleteRegistryOrder({ variables: { id: orderId } });
    openToast({ notificationText: translations.removeSuccess(), noAnimation: true, isPositionTop: true });
    onClose({ closeSource: 'custom' });
  };

  const formik = useFormik<ReserveItemFields>({
    initialValues: {
      orderNumber: ''
    },
    validationSchema: Yup.object<ReserveItemFields>({
      orderNumber: Yup.string().required(translations.requiredMessage())
    }),
    onSubmit: values => handleOnClickSave(values)
  });

  const handleOnAddLater = () => {
    addOrderTracking();
    onClose({ closeSource: 'custom' });
  };

  return {
    translations,
    formik,
    loading,
    handleOnCancelReservation,
    handleOnAddLater
  };
};
