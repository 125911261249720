import { useMemo } from 'react';
import { shouldUseEventDesignDraft, useEventDesignTheming } from '@apps/guest/routes/GuestSite/GuestSite.utils';
import { DesignLayoutType, EventDesignPurpose, useGuestSiteNavBarQuery } from '@graphql/generated';
import { isPast } from 'date-fns';
import { useMediaQuery } from '@withjoy/joykit/utils';

interface NavBarControllerProps {
  eventHandle: string;
  layout?: DesignLayoutType;
}

export const useNavBarController = ({ eventHandle, layout }: NavBarControllerProps) => {
  const { data } = useGuestSiteNavBarQuery({
    variables: {
      eventHandle,
      eventDesignPurpose: shouldUseEventDesignDraft() ? EventDesignPurpose.draft : EventDesignPurpose.live
    },
    batchMode: 'fast'
  });
  const event = data?.eventByName;
  const eventDesign = data?.eventByName?.eventDesign;
  const info = data?.eventByName?.info;
  const finalizedDateMs = info?.finalizedDate?.milliseconds || 0;
  const isEventPast = isPast(finalizedDateMs);
  const { theme } = useEventDesignTheming({ eventDesign });
  const isSmallScreen = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm4' }));
  const navBarVariant: 'menu' | 'inline' = isSmallScreen || layout === DesignLayoutType.aloha ? 'menu' : 'inline';

  const navbarEventProps = useMemo(() => {
    return {
      eventId: event?.id || '',
      layoutType: event?.eventDesign?.websiteLayout.layoutType || DesignLayoutType.aloha,
      dateInMilliseconds: info?.dateV0918?.milliseconds || 0,
      hasEventUnlocked: event?.activeSession.hasUnlocked || false,
      fullNamePerson: event?.activeSession?.person?.fullName || '',
      avatarNamePerson: event?.activeSession?.person?.avatarName || '',
      emailPerson: event?.activeSession?.person?.email || '',
      pages: event?.pages || []
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  return {
    event,
    info,
    isEventPast,
    navBarVariant,
    theme,
    navbarEventProps
  } as const;
};
