import { styled, Flex, StyleSystemProps, keyframes } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledLoadingWrapper = styled(Flex)``;
const loadingWrapperStyles: StyleSystemProps = {
  width: '100%',
  height: '60%',
  backgroundColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: pxToRem(68)
};

const spinnerSize = 36 + 4 * 4;
const loading = keyframes`
    0% {
        transform: rotate(0deg);
        stroke-dashoffset: ${0.66 * spinnerSize};
    } 50% {
        transform: rotate(720deg);
        stroke-dashoffset: ${3.14 * spinnerSize};
    } 100% {
        transform: rotate(1080deg);
        stroke-dashoffset:  ${0.66 * spinnerSize};
    }
`;

export const StyledLoader = styled.circle`
  fill: transparent;
  stroke: ${props => props.theme.colors.mono14};
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: ${3.14 * spinnerSize};
  transform-origin: ${0.5 * spinnerSize}px ${0.5 * spinnerSize}px;
  animation: ${loading} 2s linear infinite;
`;

export const styles = {
  loadingWrapperStyles
};
