import { clampsText } from '@shared/utils/style/text';
import { Box, ButtonV2, SpacingStack, styled, TextV2 } from '@withjoy/joykit';

export const StyledContentHeaderHed = styled(Box)``;
export const StyledContentHeaderDek = styled(Box)``;
export const StyledContentHeaderDekText = styled(TextV2)<{ isCollapsed?: boolean | null; maxNoLines?: number | null }>`
  ${({ isCollapsed, maxNoLines }) => isCollapsed && maxNoLines && clampsText(maxNoLines)}
`;
export const StyledContentHeaderDekButton = styled(ButtonV2)``;
export const StyledContentHeaderRoot = styled(SpacingStack)``;
