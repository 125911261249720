import React from 'react';
import { useTranslation } from '@shared/core/i18n';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { ChevronBack, Close } from '@withjoy/joykit/icons';
import { styles, StyledRoot, StyledBackButton, StyledCloseButton } from './TopNav.styles';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { CustomItemDataType, DonationFundPlatformTypeEnum } from '@graphql/generated';
import { TranslateFunction } from '@locales/generated';

/**
 * Low-level component -- prefer to use `IntegratedTopNav`.
 */
export const TopNav = ({ children }: { children: React.ReactNode }) => {
  if (!children) {
    return null;
  }

  return <StyledRoot __css={styles.root}>{children}</StyledRoot>;
};

TopNav.BackButton = ({ children, onClick }: { children: React.ReactNode; onClick: React.MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <StyledBackButton startIcon={<ChevronBack size="sm" />} height={8} {...styles.navButton} onClick={onClick}>
      {children}
    </StyledBackButton>
  );
};

TopNav.CloseButton = ({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) => {
  return (
    <StyledCloseButton aria-label="Exit checkout dialog" marginLeft="auto" shape="rounded" size="md" {...styles.navButton} onClick={onClick}>
      <Close />
    </StyledCloseButton>
  );
};

/**
 * This connects to the checkout funnel to smartly render content based on the state machine.
 */
export const IntegratedTopNav = () => {
  const {
    canShowBackButton,
    canShowCloseDialogButton,
    closeDialog,
    route,
    toBack,
    context: { isAffiliate }
  } = useCheckoutFunnel(({ canShowBackButton, canShowCloseDialogButton, closeDialog, route, toBack, context }) => [
    canShowBackButton,
    canShowCloseDialogButton,
    closeDialog,
    route,
    toBack,
    context.isAffiliate
  ]);
  const { t } = useTranslation('guestRegistry');
  const { registryItem } = useCheckoutInitDataContext();

  const backLabels: Record<string, TranslateFunction> = t('checkoutDialog', 'stateBackLabels');
  const isCharity = registryItem?.donationFund?.fundType === CustomItemDataType.charity && registryItem?.donationFund?.platform?.type === DonationFundPlatformTypeEnum.other;
  const backLabelIndex: string = isCharity ? `${route}Charity` : isAffiliate ? `${route}Affiliate` : route || '';
  const maybeBackLabel = route && (backLabels[backLabelIndex] || backLabels[route]);
  const showBackButton = canShowBackButton && maybeBackLabel;

  return (
    <TopNav>
      {showBackButton && <TopNav.BackButton onClick={toBack}>{maybeBackLabel()}</TopNav.BackButton>}
      {canShowCloseDialogButton && <TopNav.CloseButton onClick={closeDialog} />}
    </TopNav>
  );
};
