import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledToastRoot = styled(Flex)``;
export const StyledToastMessage = styled(Flex)``;
export const StyledShippingAddressReferenceRoot = styled(Box)``;
export const StyledNoShippingAddressReferenceRoot = styled(Box)``;

const toastRootStyles: StyleSystemProps = {
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  left: '0',
  position: 'absolute',
  top: '0',
  width: '100%'
};

const toastMessageStyles: StyleSystemProps = {
  alignItems: 'center',
  backgroundColor: 'mono14',
  borderRadius: 5,
  color: 'white',
  paddingX: 7,
  paddingY: 5
};

const referenceBaseStyles: StyleSystemProps = {
  border: `${pxToRem(1)} solid`,
  borderRadius: 3,
  padding: 6
};

const shippingAddressReferenceRootStyles: StyleSystemProps = {
  ...referenceBaseStyles,
  backgroundColor: 'white',
  borderColor: 'mono3',
  position: 'relative'
};

const noShippingAddressReferenceRootStyles: StyleSystemProps = {
  ...referenceBaseStyles,
  backgroundColor: 'info1',
  borderColor: 'warning3'
};

export const styles = {
  toastRoot: toastRootStyles,
  toastMessage: toastMessageStyles,
  shippingAddressReferenceRoot: shippingAddressReferenceRootStyles,
  noShippingAddressReferenceRoot: noShippingAddressReferenceRootStyles
};
