import { Flex, styled, keyframes, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { JoyLogo } from '@assets/index';

const slideIn = keyframes`
  100% { transform: translateX(0%); }
`;

export const StyledContainer = styled(Flex)`
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: ${slideIn} 0.5s forwards;
  transition: width 0.5s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 6;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 50px 100px -24px rgba(44, 41, 37, 0.25), 0px 30px 60px -24px rgba(0, 0, 0, 0.3);
`;

export const StyledScrollableFlex = styled(Flex)`
  flex-grow: 1;
  overflow: auto;
  flex-direction: column;
`;

export const ResizedLogo = styled(JoyLogo)`
  width: auto;
  height: ${pxToRem(38)};
`;

export const ContentFlex = styled(Flex)``;
export const ContentContainer = styled(Flex)``;
export const ContentWrapper = styled(Flex)``;

const contentWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  paddingX: { _: 6, md2: 0 },
  paddingTop: {
    _: 9,
    md2: 10
  },
  width: '100%',
  maxWidth: {
    _: '100%',
    sm: pxToRem(450)
  },
  alignItems: 'center'
};

const contentContainerStyles: StyleSystemProps = {
  width: '100%',
  flex: 1,
  justifyContent: 'center'
};

const contentFlexStyles: StyleSystemProps = {
  height: '100%',
  flexDirection: { _: 'column', md1: 'row' }
};

export const styles = {
  contentWrapperStyles,
  contentContainerStyles,
  contentFlexStyles
};
