import React, { useEffect } from 'react';
import { StyledCancelLink, StyledCompletePurchaseImage, StyledSuccessGuestButton } from '../../Purchase.styles';
import { ReactComponent as ChatIcon } from '@assets/icons/chat.svg';
import { Address } from '../../../../components/Address';
import { ShippingAddressFragment } from '@graphql/aliases';
import { LaunchEmailSupportLink } from '@apps/registry/guest/components';
import { useTranslation } from '@shared/core';
import {
  StyledBoxTitle,
  StyledHeadText,
  StyledText,
  StyledChangeMindBox,
  StyledSthWentWrongBox,
  StyledEmailBox,
  styles,
  StyledImageWrapper,
  StyledImageWrapperInner,
  confirmBoxStyled,
  StyledActionsBox,
  StyledConfirmBox,
  wrapperStyled,
  wrapperContentStyled
} from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationInner/PurchaseConfirmationInner.styles';
import { usePageTitleConfirmPayment } from '@apps/registry/guest/routes/Purchase/components/titleHandler';
import { Stack, Box, ButtonV2, TextV2 } from '@withjoy/joykit';

interface PurchaseConfirmationProps
  extends Readonly<{
    handleUndoClick: () => void;
    handleNotPurchased: () => void;
    onBackClick: () => void;
    shippingAddress: ShippingAddressFragment | null;
    handleConfirmationClick: () => void;
    purchaseConfirmationDisplayedTelemetry?: () => void;
    productImage: string;
    productTitle: Maybe<string>;
    isInModal: boolean;
    isDonation: boolean;
    fundTitle?: string;
    priceString?: string;
    donationPlatformDomain?: string;
  }> {}

interface ImageBlockProps
  extends Readonly<{
    isInModal: boolean;
    productImage: string;
    productTitle: Maybe<string>;
  }> {}

const ImageBlock = ({ isInModal, productImage, productTitle }: ImageBlockProps) => {
  return (
    <StyledImageWrapper __css={styles.imageWrapper(isInModal)}>
      <StyledImageWrapperInner __css={styles.imageWrapperInner(isInModal)}>
        <StyledCompletePurchaseImage productImage={productImage} />
      </StyledImageWrapperInner>
      {productTitle && (
        <TextV2 typographyVariant="hed1" marginTop={5}>
          {productTitle}
        </TextV2>
      )}
    </StyledImageWrapper>
  );
};

interface TitleBlockProps
  extends Readonly<{
    isInModal: boolean;
    isDonation: boolean;
  }> {}

const TitleBlock = ({ isInModal, isDonation }: TitleBlockProps) => {
  const { t } = useTranslation('guestRegistry');
  const { confirmQuestion, confirmSubtitle } = t('confirmationPage');
  const { contributeConfirmationHeader } = t('contributeToFund');
  return (
    <StyledBoxTitle marginTop={7}>
      <StyledHeadText typographyVariant="hed4" component="h1" marginBottom={isInModal ? undefined : 5}>
        {isDonation ? contributeConfirmationHeader() : confirmQuestion()}
      </StyledHeadText>
      {!isInModal && (
        <StyledText typographyVariant="body1" component="h1">
          {confirmSubtitle()}
        </StyledText>
      )}
    </StyledBoxTitle>
  );
};

interface ModalActionsProps
  extends Readonly<{
    handleNotPurchased: () => void;
    handleConfirmationClick: () => void;
    isDonation: boolean;
  }> {}

const ModalActions = ({ handleNotPurchased, handleConfirmationClick, isDonation }: ModalActionsProps) => {
  const { t } = useTranslation('guestRegistry');
  const { justToBeSafe, confirmButton, noButton } = t('confirmationPage');
  return (
    <>
      <StyledActionsBox __css={confirmBoxStyled}>
        <ButtonV2 intent="productive" onClick={handleConfirmationClick} flex="1 0">
          {confirmButton()}
        </ButtonV2>
        <ButtonV2 intent="productive" variant="outline" onClick={handleNotPurchased} flex="1 0">
          {noButton()}
        </ButtonV2>
      </StyledActionsBox>
      {!isDonation && (
        <TextV2 typographyVariant="hed1" marginTop={9} textAlign="center">
          {justToBeSafe()}
        </TextV2>
      )}
    </>
  );
};

interface PageActionsProps
  extends Readonly<{
    handleConfirmationClick: () => void;
  }> {}

const PageActions = ({ handleConfirmationClick }: PageActionsProps) => {
  const { t } = useTranslation('guestRegistry');
  const { confirmButtonPage } = t('confirmationPage');
  return (
    <StyledConfirmBox paddingTop={3}>
      <StyledSuccessGuestButton intent="primary" fill={true} onClick={handleConfirmationClick}>
        {confirmButtonPage()}
      </StyledSuccessGuestButton>
    </StyledConfirmBox>
  );
};

interface ChangeMindBlockProps
  extends Readonly<{
    handleUndoClick: () => void;
  }> {}

const ChangeMindBlock = ({ handleUndoClick }: ChangeMindBlockProps) => {
  const { t } = useTranslation('guestRegistry');
  const { changedMindTitle, changedMindAction } = t('confirmationPage');
  return (
    <StyledChangeMindBox paddingTop={3}>
      <StyledText component="p" marginBottom={3}>
        {changedMindTitle()}
      </StyledText>
      <StyledCancelLink component="p" onClick={handleUndoClick}>
        {changedMindAction()}
      </StyledCancelLink>
    </StyledChangeMindBox>
  );
};

interface WentWrongBlockProps
  extends Readonly<{
    onBackClick: () => void;
  }> {}

const WentWrongBlock = ({ onBackClick }: WentWrongBlockProps) => {
  const { t } = useTranslation('guestRegistry');
  const { somethingWrongTitle, somethingWrongAction } = t('confirmationPage');
  return (
    <StyledSthWentWrongBox display="flex" justifyContent="center" alignItems="center">
      <StyledText component="p" marginRight={1}>
        {somethingWrongTitle()}
      </StyledText>
      <StyledCancelLink component="p" onClick={onBackClick}>
        {somethingWrongAction()}
      </StyledCancelLink>
    </StyledSthWentWrongBox>
  );
};

const SupportBlock = () => {
  const { t } = useTranslation('guestRegistry');
  const { haveQuestions } = t('confirmationPage');
  return (
    <StyledEmailBox marginTop={8}>
      <LaunchEmailSupportLink>
        <ChatIcon />
        {haveQuestions()}
      </LaunchEmailSupportLink>
    </StyledEmailBox>
  );
};

export const PurchaseConfirmationInner: React.FC<PurchaseConfirmationProps> = ({
  productTitle,
  isInModal,
  onBackClick,
  shippingAddress,
  handleUndoClick,
  handleConfirmationClick,
  handleNotPurchased,
  productImage,
  isDonation,
  priceString,
  fundTitle,
  donationPlatformDomain,
  purchaseConfirmationDisplayedTelemetry
}) => {
  usePageTitleConfirmPayment();

  useEffect(() => {
    if (isInModal && purchaseConfirmationDisplayedTelemetry) {
      purchaseConfirmationDisplayedTelemetry();
    }
  }, [isInModal, purchaseConfirmationDisplayedTelemetry]);

  const { t } = useTranslation('guestRegistry');
  const { contributeDetails } = t('contributeToFund');

  const donationTitle = !!priceString && !!fundTitle ? contributeDetails({ amount: priceString, fundTitle }) : null;

  return (
    <Box __css={wrapperStyled(isInModal)}>
      <ImageBlock productImage={productImage} isInModal={isInModal} productTitle={productTitle ? productTitle : donationTitle} />
      {!!donationPlatformDomain && (
        <StyledText typographyVariant="label2" color="mono10" marginTop={1}>
          {t('contributeToFund', 'viaPlatform')({ platform: donationPlatformDomain })}
        </StyledText>
      )}
      <Stack __css={wrapperContentStyled(isInModal)} spacing={isInModal ? undefined : 7}>
        <TitleBlock isInModal={isInModal} isDonation={isDonation} />
        {isInModal ? (
          <ModalActions handleNotPurchased={handleNotPurchased} handleConfirmationClick={handleConfirmationClick} isDonation={isDonation} />
        ) : (
          <PageActions handleConfirmationClick={handleConfirmationClick} />
        )}
        {!isDonation && (
          <Box marginTop={isInModal ? 4 : undefined}>
            <Address address={shippingAddress} showWarning={false} />
          </Box>
        )}
        {!isInModal && (
          <>
            <ChangeMindBlock handleUndoClick={handleUndoClick} />
            <WentWrongBlock onBackClick={onBackClick} />
            <SupportBlock />
          </>
        )}
      </Stack>
    </Box>
  );
};
