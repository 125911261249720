import { useEffect, useMemo, useCallback, useRef } from 'react';
import { useFormik } from 'formik';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { DonationFundDonationFragment, DonationFundFragment, DonationFundPlatformTypeEnum } from '@graphql/generated';
import * as Yup from 'yup';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { OptionType } from '@withjoy/joykit';
import { GiveGiftDialogFields, donationFundPlatformTypes } from './GiveGiftDialog.types';
import {
  getCurrencySymbol,
  getDonationFundFund,
  getSuggestedDonation,
  getSuggestedQuantityStillNeeded,
  getDonationFundGoal,
  getIsMismatchedSuggestions,
  getDonationFundProgressToGoal,
  getFormattedPrice,
  getPlatformType,
  getEnableCashOrCheck,
  getIsCashFund,
  getPaymentMethodFormattedName,
  getIsDonationPlatformTypeOther
} from './util';

const getStillNeededOptions = (product?: CookedProduct): OptionType[] => {
  const currencySymbol = getCurrencySymbol(product);

  const donationFund = getDonationFundFund(product);

  return product ? getStillNeededOptionsInner({ currencySymbol, donationFund }) : [];
};

export const getStillNeededOptionsInner = ({ currencySymbol, donationFund }: { currencySymbol: string; donationFund: Maybe<DonationFundFragment> }): OptionType[] => {
  const suggestedDonation = getSuggestedDonation(donationFund);

  const suggestedQuantityStillNeeded = getSuggestedQuantityStillNeeded(donationFund);
  const donationFundGoal = getDonationFundGoal(donationFund);
  const isMismatchedSuggestions = getIsMismatchedSuggestions(donationFund);
  const donationFundProgressToGoal = getDonationFundProgressToGoal(donationFund);

  return donationFund && suggestedDonation
    ? suggestedQuantityStillNeeded && suggestedQuantityStillNeeded > 1
      ? Array.from(Array(suggestedQuantityStillNeeded).keys()).map(i => {
          let label;
          if (i === suggestedQuantityStillNeeded - 1 && isMismatchedSuggestions) {
            label = donationFundGoal - donationFundProgressToGoal;
          } else {
            label = (i + 1) * suggestedDonation;
          }
          const formattedLabel = getFormattedPrice(label);
          return {
            label: `${currencySymbol}${formattedLabel}`,
            value: `${formattedLabel}`
          };
        })
      : [
          {
            label: `${currencySymbol}${getFormattedPrice(suggestedDonation)}`,
            value: `${suggestedDonation}`
          }
        ]
    : [];
};

export type GiveGiftDialogTranslations = Pick<
  ReturnType<ReturnType<typeof useRegistryGuestTranslations>['getGiveGiftDialogTranslations']>,
  'nameEmptyError' | 'emailEmptyError' | 'emailNotValidError' | 'amountEmptyError' | 'paymentMethodError' | 'cashOrCheckGuestSelectLabel'
>;

export type GiveGiftDialogFormInput = {
  product?: CookedProduct;
  initialValues?: GiveGiftDialogFields;
  existingDonation?: DonationFundDonationFragment;
  onSave: (values: GiveGiftDialogFields) => void;
  onClose?: (e: React.SyntheticEvent<HTMLElement, Event>) => void;
  translations: GiveGiftDialogTranslations;
  shouldRequireEmail: boolean;
  providedGuestEmail: Maybe<string>;
  providedGuestName: Maybe<string>;
};

const ALLOWED_CHARS_REGEXP = /[0-9.]+/;

export const useGiveGiftDialogInteractive = ({
  product,
  onSave,
  onClose,
  existingDonation,
  translations: { nameEmptyError, emailEmptyError, emailNotValidError, amountEmptyError, paymentMethodError, cashOrCheckGuestSelectLabel: cashOrCheckLabelFn },
  providedGuestEmail,
  providedGuestName,
  shouldRequireEmail
}: GiveGiftDialogFormInput) => {
  const fund = getDonationFundFund(product);
  const suggestedDonation = getSuggestedDonation(fund);
  const platformType = getPlatformType(fund);
  const enableCashOrCheck = getEnableCashOrCheck(fund);
  const isCashFund = getIsCashFund(fund);
  const updatedOnceOnEmailChangeRef = useRef(false);

  const cashOrCheckGuestSelectLabel = cashOrCheckLabelFn();

  const formik = useFormik<GiveGiftDialogFields>({
    initialValues: existingDonation
      ? {
          name: existingDonation.name,
          email: existingDonation.email,
          amount: existingDonation.amount.valueInMinorUnits / 100,
          note: existingDonation.note || '',
          platformType: existingDonation.platformType ?? ''
        }
      : {
          name: providedGuestName || '',
          email: providedGuestEmail || '',
          note: '',
          platformType: platformType ?? '',
          amount: ''
        },
    validationSchema: Yup.object<GiveGiftDialogFields>({
      name: Yup.string().required(nameEmptyError()),
      amount: Yup.number()
        .required(amountEmptyError())
        .min(suggestedDonation ? suggestedDonation : 1, amountEmptyError()),
      note: Yup.string(),
      email: shouldRequireEmail ? Yup.string().email(emailNotValidError()).required(emailEmptyError()) : Yup.string(),

      ...(isCashFund
        ? {
            platformType: Yup.mixed().oneOf(donationFundPlatformTypes).required(paymentMethodError())
          }
        : {})
    }),
    onSubmit: async values => {
      await onSave(values);
    }
  });

  useEffect(() => {
    if (!updatedOnceOnEmailChangeRef.current && providedGuestEmail !== formik.values.email) {
      updatedOnceOnEmailChangeRef.current = true;
      formik.setFieldValue('email', providedGuestEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providedGuestEmail]);

  useEffect(() => {
    if (!suggestedDonation) return;

    formik.setFieldValue('amount', suggestedDonation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedDonation]);

  const setAmount = useCallback((amount?: string) => formik.setFieldValue('amount', amount || '1'), [formik]);

  const setDonationType = useCallback((type: string) => formik.setFieldValue('platformType', type), [formik]);

  const handlePurchased = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (formik.isSubmitting) return;

      formik.submitForm();
    },
    [formik]
  );

  const currencySymbol = getCurrencySymbol(product)!;

  const stillNeededOptions = getStillNeededOptions(product);

  const paymentMethodFormattedName = useMemo(() => getPaymentMethodFormattedName(platformType), [platformType]);

  const isDonationPlatformTypeOther = getIsDonationPlatformTypeOther(platformType);

  const availablePaymentMethods: OptionType[] = useMemo(
    () => [
      { value: platformType, label: paymentMethodFormattedName! },
      ...(enableCashOrCheck
        ? [
            {
              value: DonationFundPlatformTypeEnum.cashOrCheck,
              label: cashOrCheckGuestSelectLabel
            }
          ]
        : [])
    ],
    [platformType, paymentMethodFormattedName, enableCashOrCheck, cashOrCheckGuestSelectLabel]
  );

  const currentlySelectedPaymentMethod: OptionType = useMemo(
    () => ({ label: getPaymentMethodFormattedName(formik.values.platformType!) || '', value: formik.values.platformType || '' }),
    [formik.values.platformType]
  );

  // safari and firefox do not respect type="number" and pattern="[0-9]*"
  const preventNonNumericalInput = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.key;
    if (
      keyCode === 'Backspace' ||
      keyCode === 'Delete' ||
      keyCode === 'ArrowRight' ||
      keyCode === 'ArrowLeft' ||
      keyCode === 'Tab' ||
      keyCode === 'a' ||
      keyCode === 'Control' ||
      keyCode === 'Meta'
    ) {
      return;
    } else if (!ALLOWED_CHARS_REGEXP.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  const onDialogClose = useCallback(
    (e: React.SyntheticEvent<HTMLElement, Event>) => {
      onClose?.(e);
      formik.resetForm();
    },
    [formik, onClose]
  );

  return {
    formik,
    setAmount,
    setDonationType,
    handlePurchased,
    formValues: formik.values,
    currencySymbol,
    stillNeededOptions,
    availablePaymentMethods,
    currentlySelectedPaymentMethod,
    paymentMethodFormattedName,
    preventNonNumericalInput,
    isDonationPlatformTypeOther,
    onDialogClose
  };
};
