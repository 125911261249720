import React from 'react';
import { TextV2, SpacingStack, InputV1, Flex, Box, DialogV2 } from '@withjoy/joykit';
import { ReserveButton, StyledLink, ErrorFiller, StyledFormField } from './ReserveItemDialog.styles';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useReserveItemDialogController } from './ReserveItemDialog.controller';
import { ReactComponent as EmailSuccessSvg } from '@assets/icons/email-success-icon.svg';
import ClampedText from '@apps/registry/common/components/ClampedText';
import { Divider } from '@apps/registry/common/components';

export interface ReserveItemDialogProps
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    product?: CookedProduct;
    eventId?: string;
  }> {}

export const ReserveItemDialog: React.FC<ReserveItemDialogProps> = ({ product, isOpen, onClose }) => {
  const {
    formik,
    successState,
    successEmail,
    onDialogClose,
    isMobile,
    translations,
    itemTitle,
    hasError,
    handleOnFocus,
    handleConfirm,
    sendRegistryOrderLoading
  } = useReserveItemDialogController({
    product,
    isOpen,
    onClose
  });

  return (
    <DialogV2 id="allowGiftReservation" onClose={onClose} isOpen={isOpen} size={'lg'}>
      {isMobile && (
        <>
          <DialogV2.Header>
            <DialogV2.CloseButton />
            <TextV2 typographyVariant="hed2" paddingRight={[8, 0]} paddingTop={[4, 0]}>
              {itemTitle}
            </TextV2>
          </DialogV2.Header>
          <Divider />
        </>
      )}
      <DialogV2.Body marginTop={[6, 6, 0]}>
        {successState ? (
          <SpacingStack spacing={7} alignItems="center" textAlign="center">
            <Box padding={'46px'}>
              <EmailSuccessSvg />
              <TextV2 typographyVariant="body1" marginTop={7}>
                {translations.successMessage({ email: successEmail })}
              </TextV2>
            </Box>
          </SpacingStack>
        ) : (
          <SpacingStack spacing={5}>
            {!isMobile ? (
              <Box>
                <ClampedText wordBreak="unset" component="h2" fontWeight={600} fontSize="17px" lineHeight="26px" letterSpacing="0.2px" clampAt={2}>
                  {itemTitle}
                </ClampedText>
                <Divider margin={'0px'} marginTop={6} />
              </Box>
            ) : null}
            <TextV2 typographyVariant="body1">{translations.subTitle()}</TextV2>
            <form onSubmit={formik.handleSubmit}>
              <SpacingStack spacing={3} marginBottom={1}>
                <StyledFormField marginBottom={hasError ? 32 : undefined} label={translations.inputLabel()} error={hasError ? formik.errors.email : undefined}>
                  <InputV1 {...formik.getFieldProps('email')} onFocus={handleOnFocus} placeholder="" tabIndex={0} />
                  <ErrorFiller isRendered={!hasError}></ErrorFiller>
                </StyledFormField>
              </SpacingStack>
              <Flex justifyContent="flex-end" flexDirection={['column-reverse', null, 'row']}>
                <StyledLink underline="none" onClick={onDialogClose} marginY={[7, null, 0]} marginX={['auto', null, 7]}>
                  {translations.cancelText()}
                </StyledLink>
                <div>
                  <ReserveButton type="submit" fill intent="primary" disabled={sendRegistryOrderLoading} onClick={handleConfirm}>
                    {translations.buttonLabel()}
                  </ReserveButton>
                </div>
              </Flex>
            </form>
          </SpacingStack>
        )}
      </DialogV2.Body>
    </DialogV2>
  );
};
