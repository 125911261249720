import { styled, ButtonV2 } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const StyledButton = styled(ButtonV2)`
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  transition: ${animationTransition('background')};

  :hover:enabled {
    background: black;
  }
`;
