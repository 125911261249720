import React from 'react';
import { Box, Flex, StyleSystemProps, TextV2, styled } from '@withjoy/joykit';
import { Checkbox } from '@shared/components';
import { noop } from 'lodash-es';
import { pxToRem } from '@withjoy/joykit/theme';
import { AddressAtomCheckbox, styles } from './AddressAtoms.style';

export const StandardShippingOption: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <Wrapper __css={wrapperStyles}>
      <Box>
        <AddressAtomCheckbox __css={styles.addressAtomCheckboxStyles}>
          <Checkbox checked={true} onChange={noop} id={`${value}-shipping-option`} />
        </AddressAtomCheckbox>
      </Box>
      <Box flex={1}>
        <TextV2 typographyVariant="body2" color="mono14">
          {label}
        </TextV2>
      </Box>
      <Box>
        <TextV2 typographyVariant="body2" color="mono14">
          {value}
        </TextV2>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)``;

const wrapperStyles: StyleSystemProps = {
  alignItems: 'center',
  borderRadius: 1,
  borderWidth: pxToRem(2),
  borderStyle: 'solid',
  borderColor: 'mono14',
  padding: 5,
  gap: 3
};
