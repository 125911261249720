import React from 'react';
import { FieldInputProps } from 'formik';
import { useTranslation } from '@shared/core';
import { FormControl, InputV2, SpacingStack, TextArea } from '@withjoy/joykit';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventInfo } from '@shared/utils/eventInfo';
import { getGiftWrapThemeListByEventType } from '@shared/components';

type Props = {
  formFields: {
    getFieldProps: (nameOrOptions: string) => FieldInputProps<string>;
    errors: Record<string, string | undefined>;
  };
};

export const DetailsForm = ({ formFields }: Props) => {
  const { getFieldProps, errors } = formFields;
  const { nameError, emailError } = errors;
  const { t2 } = useTranslation('guestRegistry');
  const { nameLabel, namePlaceholder, emailLabel, emailPlaceholder, messageLabel, messagePlaceholder } = t2('shoppingCart', 'detailsStep', 'detailsForm');
  const { value: giftWrapOn } = useFeatureValue('registryGiftWrapExperimentEnabled');
  const registryGiftWrapExperimentEnabled = giftWrapOn === 'on';
  const { value: gwRegCartEnabled } = useFeatureValue('registryGiftWrapCartEnabled');
  const registryGiftWrapCartEnabled = gwRegCartEnabled === 'on';
  const { eventInfo } = useEventInfo();
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const eventTypeHasThemes = themes && themes.length > 0;
  // Verify the registry item is gift wrap experiment is enabled, and gift wrap for cart is enabled
  const isGiftWrapEnabled = registryGiftWrapExperimentEnabled && registryGiftWrapCartEnabled && eventTypeHasThemes;

  return (
    <form>
      <SpacingStack spacing={6}>
        <FormControl label={emailLabel} isInvalid={!!emailError} error={emailError} isRequired>
          <InputV2 {...getFieldProps('email')} placeholder={emailPlaceholder} />
        </FormControl>
        <FormControl label={nameLabel} isInvalid={!!nameError} error={nameError} isRequired>
          <InputV2 {...getFieldProps('name')} placeholder={namePlaceholder} />
        </FormControl>
        {!isGiftWrapEnabled && (
          <FormControl label={messageLabel}>
            <TextArea {...getFieldProps('message')} placeholder={messagePlaceholder} />
          </FormControl>
        )}
      </SpacingStack>
    </form>
  );
};
