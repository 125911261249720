import React from 'react';
import { Stack, Flex, Box } from '@withjoy/joykit';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { Address } from '../../../components/Address';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useTranslation } from '@shared/core';
import { StyledHeadText, StyledCancelLink, StyledText, StyledSuccessGuestButton, StyledCompletePurchaseImage } from '../Purchase.styles';
import { ReactComponent as ChatIcon } from '@assets/icons/chat.svg';
import { LaunchEmailSupportLink } from '@apps/registry/guest/components';

interface CompletePurchaseProps
  extends Readonly<{
    isCustom: boolean;
    registryImage: string;
    handleClickProduct: () => void;
    handleUndoClick: () => void;
    purchased?: boolean;
    address: ShippingAddressFragment | null;
    isStoreAmazon: boolean;
    productImage: string;
    storeName?: string;
  }> {}

export const CompletePurchase: React.FC<CompletePurchaseProps> = ({ isCustom, address, handleClickProduct, handleUndoClick, isStoreAmazon, productImage, storeName }) => {
  const { getPurchaseGiftTranslations } = useRegistryGuestTranslations();
  const { customPurchaseTitle, customPurchaseDescriptionAmazon, reservedTitle } = getPurchaseGiftTranslations();

  const { t } = useTranslation('guestRegistry');
  const purchaseGiftTranslations = t('purchaseGift');
  const { buyOn, purchaseDescription } = purchaseGiftTranslations;
  const { cancelQuestion, cancelText, haveQuestions } = t('confirmationSection');

  const storeText = isStoreAmazon ? customPurchaseDescriptionAmazon() : purchaseDescription({ retailer: storeName });

  return (
    <>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={'mono1'} padding={[7, 3]}>
        <Box width={['205px', null, '290px']} height={['205px', null, '290px']}>
          <StyledCompletePurchaseImage productImage={productImage} />
        </Box>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={[6, null, '0px 48px']}>
        <Box>
          <Stack alignItems="center" spacing={7} display="flex">
            <Box maxWidth="340px">
              <StyledHeadText component="h1" textAlign="center" marginBottom={5}>
                {isCustom ? customPurchaseTitle() : reservedTitle()}
              </StyledHeadText>
              <StyledText component="h1" textAlign="center">
                {storeText}
              </StyledText>
            </Box>
            <Box width="400px">
              <Address address={address} showWarning={false} />
            </Box>
            <StyledSuccessGuestButton intent="primary" fill={true} onClick={handleClickProduct}>
              {buyOn({ storeText: storeName })}
            </StyledSuccessGuestButton>
            <Box display="flex" flexDirection="column" alignItems="center">
              <StyledText component="p" marginBottom={3}>
                {cancelQuestion()}
              </StyledText>
              <StyledCancelLink component="p" onClick={handleUndoClick}>
                {cancelText()}
              </StyledCancelLink>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={6}>
            <LaunchEmailSupportLink>
              <ChatIcon />
              {haveQuestions()}
            </LaunchEmailSupportLink>
          </Box>
        </Box>
      </Flex>
    </>
  );
};
