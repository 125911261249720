import React from 'react';
import { DialogV2, TextV2, Flex, InputV2, FormControl, LinkV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { Divider } from '@apps/registry/common/components';
import { useChangeEmailDialogController } from './ChangeEmailDialog.controller';
import { StyledSuccessGuestButton } from './ChangeEmailDialog.styles';

interface Props
  extends Readonly<{
    isOpen: boolean;
    onClose: () => void;
    onSubmitEmail: (email: string) => void;
    mySessionEmail: string;
  }> {}

export interface ReserveItemFields {
  email: string;
}

export const ChangeEmailDialog: React.FC<Props> = props => {
  const { isOpen, onClose, onSubmitEmail, mySessionEmail } = props;
  const { t } = useTranslation('guestRegistry');
  const { title, subTitle, changeEmail, cancel, email } = t('changeEmailSection');
  const { formik, handleOnSubmit } = useChangeEmailDialogController({ onSubmitEmail, mySessionEmail });

  return (
    <DialogV2 id="ChangeEmailDialog" onClose={onClose} isOpen={isOpen} size="lg">
      <DialogV2.CloseButton />
      <DialogV2.Header>
        <TextV2 typographyVariant="hed2">{title()}</TextV2>
      </DialogV2.Header>
      <DialogV2.Body>
        <TextV2 typographyVariant="body1" fontWeight={400}>
          {subTitle()}
        </TextV2>
        <FormControl
          label={<TextV2 typographyVariant="hed1">{email()}</TextV2>}
          marginTop={6}
          error={
            <TextV2 typographyVariant="body2" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('email').error}>
              {formik.getFieldMeta('email').error}
            </TextV2>
          }
        >
          <InputV2 key="email" {...formik.getFieldProps('email')} placeholder="" tabIndex={0} {...formik.getFieldProps('email')} isInvalid={!!formik.getFieldMeta('email').error} />
        </FormControl>
      </DialogV2.Body>
      <Divider margin={'0px'} marginTop={2} />
      <DialogV2.Footer>
        <Flex justifyContent="flex-end" flexDirection={['column-reverse', null, 'row']}>
          <LinkV2 color="gray12" textDecoration="none" onClick={onClose} marginY={[7, null, 4]} marginX={['auto', null, 7]}>
            {cancel()}
          </LinkV2>
          <div>
            <StyledSuccessGuestButton fill={true} intent="primary" onClick={handleOnSubmit}>
              {changeEmail()}
            </StyledSuccessGuestButton>
          </div>
        </Flex>
      </DialogV2.Footer>
    </DialogV2>
  );
};
