import { useContext } from 'react';
import { GuestRegistryContext } from './';

export const useGuestRegistryState = () => {
  const context = useContext(GuestRegistryContext);
  if (context === undefined) {
    throw new Error('useGuestRegistryState must be used with a GuestRegistryContext');
  }
  return context;
};
