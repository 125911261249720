import { Box, StyleSystemProps, styled } from '@withjoy/joykit';

export const StyledPhotoContainer = styled(Box)``;
export const StyledProductPhoto = styled.img<{ isMobile?: boolean; maxHeight: number }>`
  ${({ isMobile, maxHeight }) =>
    isMobile ? `width: 100%;  object-fit: contain; aspect-ratio: 1;; max-height: ${maxHeight}px` : `width: auto; height: 100%; max-height: ${maxHeight}px`};
`;
export const StyledCustomCursor = styled(Box)``;

const photoContainerStyles: StyleSystemProps = {
  width: 'inherit',
  height: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
};

const customCursorStyles: StyleSystemProps = {
  position: 'absolute'
};

export const styles = {
  photoContainer: photoContainerStyles,
  customCursor: customCursorStyles
};
