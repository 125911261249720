import { Box, styled } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

type SelectWrapperContainerProps = { isVisible: boolean };

export const StyledSelectWrapper = styled(Box)``;
const selectWrapperStyles = (props: SelectWrapperContainerProps): CSSObject => {
  return {
    ['& > div > div > div:nth-child(2)']: {
      display: props.isVisible ? 'initial' : 'none'
    },
    ['& ul > li:last-of-type > span']: {
      color: 'mono14',
      fontWeight: 600
    }
  };
};

export const styles = {
  selectWrapper: selectWrapperStyles
};
