import React from 'react';
import { GuestRegistryState } from '../../GuestRegistry.controller';
import { GiveGiftDialogFields } from '../../routes/GuestRegistry/components/GiveGiftDialog/GiveGiftDialog.types';
import { MockedItem } from './__mocks__';
import { ToastVariableProps } from '../../routes/GuestRegistry/GuestRegistry.controller';

interface InteractiveCheckoutFlowProps
  extends Readonly<{
    /**
     * Actual implementation stubbed out for now -- need updates to BE.
     */
    onReserveGift: (() => void) | null;

    /**
     * Actual implementation stubbed out for now -- need updates to BE.
     */
    onPurchaseGift: (() => void) | null;
    registryState: GuestRegistryState;
    isAdmin: boolean;
    eventId: string;
  }> {}

export interface CheckoutFunnelProviderProps
  extends Readonly<{
      onClose: () => void;
      children: React.ReactNode;
      registryItemId: Maybe<string>;
      currentOrderId: Maybe<string>;
      isAffiliate: boolean;
      isGiftWrapPurchased: boolean;
      isGroupGifting: boolean;
      donationAmount?: number;
    }>,
    Partial<InteractiveCheckoutFlowProps> {}

export interface CheckoutDialogProps
  extends Readonly<{
      isOpen: boolean;
      onClose: () => void;
      registryItemId: Maybe<string>;
      currentOrderId: Maybe<string>;
      isAffiliate: boolean;
      isGroupGifting: boolean;
      isGiftWrapPurchased: boolean;
      donationAmount?: number;
    }>,
    Partial<InteractiveCheckoutFlowProps> {}

export interface CheckoutDialogProviderProps extends Partial<InteractiveCheckoutFlowProps> {
  children: React.ReactNode;
  openToast?: (toast: ToastVariableProps) => void;
}

export type CheckoutDialogControllerProps = Pick<
  CheckoutFunnelProviderProps,
  'eventId' | 'registryState' | 'registryItemId' | 'currentOrderId' | 'isAdmin' | 'isAffiliate' | 'isGroupGifting'
>;

export type CheckoutDialogFields = GiveGiftDialogFields;

export enum PAYMENT_OPTIONS {
  CASH_OR_CHECK = 'cashOrCheck',
  CASH_APP = 'cashapp',
  VENMO = 'venmo',
  PAYPAL = 'paypal'
}

export interface ExtraPaymentMethodOptionType {
  name: string;
  value: string;
  label: string;
}

export const ExtraPaymentMethodOptions = {
  cashOrCheck: { name: 'CashOrCheck', value: 'cashOrCheck', label: 'Cash / Check' },
  cashapp: { name: 'Cashapp', value: 'cashapp', label: 'Cash App' },
  venmo: { name: 'Venmo', value: 'venmo', label: 'Venmo' },
  paypal: { name: 'Paypal', value: 'paypal', label: 'PayPal' }
};

export interface CustomCashFundGiveNowPathProps {
  mockedItem: MockedItem;
  extraPaymentOption: ExtraPaymentMethodOptionType;
  useFixedPriceFlow?: boolean;
  useExtraPaymentOptionPath?: boolean;
}
