import { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useSendRegistryOrderConfirmationMutation, RegistryOrderEmailIntent } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

interface ReserveItemDialogControllerProps {
  product?: CookedProduct;
  isOpen: boolean;
  onClose: () => void;
}

export interface ReserveItemFields {
  email: string;
}

export const useReserveItemDialogController = ({ product, isOpen, onClose }: ReserveItemDialogControllerProps) => {
  const [successState, setSuccessState] = useState(false);
  const [successEmail, setSuccessEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const telemetry = useGuestRegistryTelemetry();

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const itemId = product?.registryItemId || '';

  const { t } = useTranslation('guestRegistry');
  const { t2: t2Form } = useTranslation('generalFormValidation');
  const { successMessage, title, cashFundTitle, subTitle, buttonLabel, cancelText, inputLabel, errorMessage } = t('reserveItem');
  const invalidEmail = t2Form('invalidEmail');

  let itemTitle = product?.title || '';
  itemTitle = product?.donationFund ? cashFundTitle({ itemName: itemTitle }) : title({ itemName: itemTitle });

  const [sendRegistryOrder, { loading: sendRegistryOrderLoading }] = useSendRegistryOrderConfirmationMutation();

  const handleSubmit = async () => {
    const emailId = formik.values.email;
    setSuccessEmail(emailId);
    const result = await sendRegistryOrder({
      variables: {
        payload: {
          registryItemId: itemId,
          email: emailId,
          emailIntent: RegistryOrderEmailIntent.find
        }
      }
    });
    const sentStatus = result.data?.sendRegistryOrderConfirmation;
    telemetry.reservedRegistryItem({
      productTitle: product?.title || '',
      registryItemId: product?.registryItemId || '',
      registryId: product?.registry.id,
      isEmailMatched: !!sentStatus
    });
    if (!sentStatus) {
      formik.setFieldError('email', errorMessage());
      setHasError(true);
    } else {
      setSuccessState(true);
    }
  };

  const formik = useFormik<ReserveItemFields>({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object<ReserveItemFields>({
      email: Yup.string().email(invalidEmail).required()
    }),
    onSubmit: handleSubmit
  });

  const handleOnFocus = () => {
    if (!formik.isValid) {
      setHasError(false);
    }
  };

  const handleConfirm = () => {
    if (!formik.isValid) {
      setHasError(true);
    } else {
      !formik.isSubmitting && formik.submitForm();
    }
  };

  const onDialogClose = useCallback(() => {
    onClose();
    formik.resetForm();
    setSuccessState(false);
  }, [formik, onClose, setSuccessState]);

  return {
    formik,
    successState,
    setSuccessState,
    successEmail,
    isMobile,
    onDialogClose,
    itemTitle,
    hasError,
    handleOnFocus,
    handleConfirm,
    sendRegistryOrderLoading,
    translations: {
      subTitle,
      buttonLabel,
      cancelText,
      inputLabel,
      errorMessage,
      successMessage
    }
  };
};
