import React, { useEffect, useMemo } from 'react';
import { PurchaseDialogProps } from '../../PurchaseDialog';
import { GuestPurchaserDetails, ProductInformation, BackButton } from './components';
import { LayoutShell } from '../LayoutShell';
import { CheckoutMechanisms, FormStep, usePurchaseDialogController } from '../../PurchaseDialog.controller';
import { MinimalCatalogProductItemDetails } from '@apps/registry/common/components/Catalog/Catalog.types';
import { MissingAddress } from './components/MissingAddress';
import { useTranslation } from '@shared/core';
import { OtherAddress } from './components/OtherAddress';
import { ProductSummary } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';
import { useShoppingCart } from '../../../ShoppingCart/state';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { CheckoutGiftCard } from '@apps/registry/guest/components/CheckoutGiftCard';
import MustHaveChip from '@apps/registry/guest/components/CheckoutDialog/components/MustHaveChip';

type DropshipProductDialogBodyProps = Merge<Omit<PurchaseDialogProps, 'isDialogOpen' | 'eventPhoto'>, Required<Pick<PurchaseDialogProps, 'product' | 'shippingAddress'>>> &
  CheckoutMechanisms & {
    pdpProduct?: Maybe<MinimalCatalogProductItemDetails>;
    purchaseErrorContent: string | null;
    formStep: FormStep;
    setFormStep: (formStep: FormStep) => void;
    eventHandle: string;
    setDisableInteraction: (value: boolean) => void;
    canRenderProductValues: boolean;
  };

export const DropshipProductDialogBody: React.FC<DropshipProductDialogBodyProps> = ({
  closeDialog,
  product,
  eventId,
  eventInfoState,
  openToast,
  mySessionEmail,
  isOutOfStock,
  pdpProduct,
  shippingAddress,
  purchaseErrorContent,
  checkoutMechanismsWithAltAddress,
  formStep,
  setFormStep,
  eventHandle,
  setDisableInteraction,
  canRenderProductValues,
  msrpTranslations
}) => {
  const {
    mutators: { addItem, buyNowFromShoppingCart }
  } = useShoppingCart();

  const {
    formik,
    isPurchasable,
    goBackToRegistry,
    isPurchaseError,
    handleOnChangeEmail,
    recentGiftStatus,
    productImageSrc,
    handleOnBackClick,
    handleOnMarkAsPurchasedClick,
    handleOnAddDetails,
    handleOnMissingAddress,
    handleOnAddOtherAddress,
    preparingForDropshipPurchaseLoading,
    changeSubmitType,
    handleOnAddGreetingCardClick,
    handleOnAddGiftNoteClick,
    isExperimentNewFlowOn,
    isNotAnExperimentStep,
    updateRegistryItemLoading,
    handleOnBuyNowClick
  } = usePurchaseDialogController({
    closeDialog,
    product,
    itemId: product?.registryItemId || '',
    eventId,
    eventInfoState,
    openToast,
    shouldRequireEmail: !mySessionEmail,
    shouldRequireName: false,
    isDropshipable: true,
    isOutOfStock,
    shippingAddress,
    checkoutMechanismsWithAltAddress,
    formStep,
    setFormStep
  });

  const { shoppingCartAddToCartClick, registryItemBuyNowClicked } = useGuestRegistryTelemetry();

  const { t } = useTranslation('guestRegistry');

  const { giftTitle, backToDetails, backToMissingCoupleAddress, backToGiftDetails, backToGreetingCard, itemAddedSuccess } = t('purchaseDialog');
  const isGuestStep = formStep === FormStep.Guest;
  const isMissingAddressStep = formStep === FormStep.MissingAddress;
  const backButtonText = isGuestStep ? giftTitle() : isMissingAddressStep ? backToDetails() : backToMissingCoupleAddress();
  const isGuestOrMissingAddressStep = isGuestStep || isMissingAddressStep || formStep === FormStep.AddOtherAddress;

  const backButtonContent = isGuestOrMissingAddressStep ? <BackButton handleBackClick={handleOnBackClick} text={backButtonText} /> : null;

  // We need a valid eventID to be able to save the gift wrap message drafts, but is optional on props.
  // If we don't have one, we'll disable the gift wrap flow.
  const registryGiftWrapDropshipEnabled = !!(isExperimentNewFlowOn && eventId && typeof eventId === 'string');

  const handleNewBuyNowForDropship = () => {
    if (!canRenderProductValues) {
      handleOnBuyNowClick();
    } else {
      registryItemBuyNowClicked({
        productTitle: product.title,
        registryItemId: product.registryItemId,
        registryId: product.registry.id,
        isGroupGiftingEnabled: !!product.isGroupGiftingEnabled
      });
      buyNowFromShoppingCart(
        { registryItemId: product.registryItemId, quantity: formik.values.quantity, fpIndex: product.fpIndex, priceInMinorUnits: (product.numberPrice || 0) * 100 },
        product.stillNeeded
      );
      //closing item detail dialog
      closeDialog();
    }
  };

  const handleOnAddToCartClick = () => {
    shoppingCartAddToCartClick({
      registryItemId: product.registryItemId,
      quantity: formik.values.quantity,
      productTitle: product.title,
      price: product.numberPrice,
      eventId,
      registryId: product.registry.id
    });
    addItem(
      { registryItemId: product.registryItemId, quantity: formik.values.quantity, fpIndex: product.fpIndex, priceInMinorUnits: (product.numberPrice || 0) * 100 },
      product.stillNeeded
    );

    if (openToast) {
      openToast({ notificationText: itemAddedSuccess(), isPositionTop: false, noAnimation: false });
    }
    closeDialog();

    changeSubmitType('');
    formik.resetForm();
  };

  const isLoading = useMemo(() => updateRegistryItemLoading || preparingForDropshipPurchaseLoading, [preparingForDropshipPurchaseLoading, updateRegistryItemLoading]);
  useEffect(() => {
    setDisableInteraction(isLoading);
  }, [isLoading, setDisableInteraction]);

  return (
    <>
      <LayoutShell {...(!isNotAnExperimentStep && { flexDirection: 'column' })}>
        {isNotAnExperimentStep ? (
          <LayoutShell.MediaPanel
            fullBleedBackground={isGuestOrMissingAddressStep}
            productImageSrc={productImageSrc}
            backButtonContent={backButtonContent}
            pdpProduct={pdpProduct}
            renderJoyFulfillment={canRenderProductValues}
          >
            <ProductSummary isIn={isGuestOrMissingAddressStep} totalQuantity={formik.values.quantity} product={product} showShippingAndHandlingCta={isGuestStep} />
          </LayoutShell.MediaPanel>
        ) : (
          <BackButton disabled={isLoading} handleBackClick={handleOnBackClick} text={formStep === FormStep.GreetingCard ? backToGiftDetails() : backToGreetingCard()} />
        )}
        <LayoutShell.ContentPanel>
          {product.mustHave && <MustHaveChip />}
          <ProductInformation
            closeDialog={closeDialog}
            product={product}
            handleOnBuyNowClick={handleNewBuyNowForDropship}
            handleOnMarkAsPurchasedClick={handleOnMarkAsPurchasedClick}
            handleOnAddToCartClick={handleOnAddToCartClick}
            formik={formik}
            isOutOfStock={isOutOfStock}
            isPurchasable={isPurchasable}
            isPurchaseError={isPurchaseError}
            goBackToRegistry={goBackToRegistry}
            recentGiftStatus={recentGiftStatus}
            isIn={formStep === FormStep.Product}
            canRenderProductValues={canRenderProductValues}
            msrpTranslations={msrpTranslations}
          />
          <GuestPurchaserDetails
            handleOnChangeEmail={handleOnChangeEmail}
            mySessionEmail={mySessionEmail}
            formik={formik}
            handleOnAddDetails={handleOnAddDetails}
            isLoading={isLoading}
            isIn={isGuestStep}
            isPurchasable={isPurchasable}
            purchaseErrorContent={purchaseErrorContent}
            isExperimentNewFlowOn={registryGiftWrapDropshipEnabled}
          />
          <MissingAddress handleOnMissingAddress={handleOnMissingAddress} isIn={formStep === FormStep.MissingAddress} />
          <OtherAddress
            handleOnAddOtherAddress={handleOnAddOtherAddress}
            isIn={formStep === FormStep.AddOtherAddress}
            isPurchasable={isPurchasable}
            purchaseErrorContent={purchaseErrorContent}
          />
        </LayoutShell.ContentPanel>
        {registryGiftWrapDropshipEnabled && (
          <CheckoutGiftCard
            eventId={eventId as string}
            eventHandle={eventHandle}
            formik={formik}
            isLoading={isLoading}
            isIn={formStep === FormStep.GreetingCard}
            handleOnAddGreetingCardClick={handleOnAddGreetingCardClick}
            handleOnAddGiftNoteClick={handleOnAddGiftNoteClick}
            product={product}
          />
        )}
      </LayoutShell>
    </>
  );
};
