import { Box, styled, TextV2, LinkV1, Radio, ButtonV1, keyframes, DialogV2, StyleSystemProps, ButtonV2 } from '@withjoy/joykit';
import { SuccessGuestButton } from '../../../../components/SuccessGuestButton';
import { pxToRem } from '@withjoy/joykit/theme';

const slideIn = keyframes`
  100% { transform: translateX(0%); }
`;

export const StyledBox = styled(Box)`
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  animation: ${slideIn} 0.5s forwards;
  position: fixed;
  top: 0;
  z-index: 6;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  background: ${props => props.theme.colors.mono2};
  box-shadow: 0 50px 100px -24px rgba(44, 41, 37, 0.25), 0px 30px 60px -24px rgba(0, 0, 0, 0.3);
`;

export const StyledBoxMob = styled(Box)`
  position: fixed;
  z-index: 6;
  bottom: 0;
  right: 0;
  width: 100%;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px -16px 60px -12px rgba(44, 41, 37, 0.25), 0px -8px 36px -18px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  border-top: solid 1px;
  border-color: ${props => props.theme.colors.mono4};
  padding: 24px;
`;

export const StyledItemBox = styled(Box)`
  background: ${props => props.theme.colors.white};
`;

export const StyledHederBox = styled(Box)`
  background: ${props => props.theme.colors.white};
  & > div {
    background: linear-gradient(180deg, rgba(247, 247, 247, 0) 71.35%, #f7f7f7 100%);
  }
`;

export const StyleText = styled(TextV2)`
  svg {
    margin-right: 8px;
    path {
      color: ${props => props.theme.colors.green7};
    }
  }
`;

export const StyleButton = styled(SuccessGuestButton)`
  width: 100%;
  max-width: 100%;
  svg {
    margin-right: ${pxToRem(8)};
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;
export const StyledCheckoutButton = styled(ButtonV2)`
  background-color: black;
  svg {
    margin-right: 8px;
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export const StyleLink = styled(LinkV1)<{ isCancel?: boolean }>`
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    path {
      fill: ${({ theme, isCancel }) => (isCancel ? theme.colors.typeSecondaryDark : theme.colors.green7)};
    }
  }
`;

export const StyleActionText = styled(TextV2)<{ isCancel?: boolean }>`
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    path {
      fill: ${({ theme, isCancel }) => (isCancel ? theme.colors.gray12 : theme.colors.green7)};
    }
  }
  :hover {
    color: ${({ theme, isCancel }) => (isCancel ? theme.colors.linkHover : theme.colors.green6)};
    svg {
      path {
        fill: ${({ theme, isCancel }) => (isCancel ? theme.colors.linkHover : theme.colors.green6)};
      }
    }
  }
  &:active {
    color: ${({ theme, isCancel }) => (isCancel ? theme.colors.linkActive : theme.colors.green8)};
    svg {
      path {
        fill: ${({ theme, isCancel }) => (isCancel ? theme.colors.linkActive : theme.colors.green8)};
      }
    }
  }
`;
export const StylePurchaseBox = styled(Box)`
  background: ${props => props.theme.colors.mono2};
`;

export const StylePurchaseOrderBox = styled(Box)`
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
`;

export const StyleCheckbox = styled(Radio)`
  margin: 8px 0px;
  & > span {
    border-radius: 100%;
  }
`;

export const StyleRemoveButton = styled(ButtonV1)`
  width: 100%;
  background-color: ${props => props.theme.colors.buttonDestructiveActive};
  :hover {
    background-color: ${props => props.theme.colors.buttonDestructiveHover} !important;
  }
`;

const defaultImage = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

export const StyledProductPhoto = styled.div<{ backgroundImage?: string | null }>`
  align-self: center;
  position: relative;
  height: 100%;
  width: 100%;
  ::after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.25s;
    transform: scale(1.0001);
    ${({ backgroundImage }) => {
      return {
        backgroundImage: `url("${backgroundImage || defaultImage}")`,
        backgroundSize: backgroundImage ? 'contain' : '50%'
      };
    }}
  }
`;

export const StyledHelpBox = styled(Box)`
  margin-bottom: 2rem;
  > p {
    margin: 0.5rem 0;
    svg {
      margin-right: 8px;
      height: 22px;
      width: 22px;
      path {
        fill: ${props => props.theme.colors.green6};
      }
    }
    padding-right: 16px;
  }
`;

export const StyledHelpDialog = styled(DialogV2)`
  overflow: auto;
  height: 421px;
  width: 375px;
  max-width: 375px;
  min-width: 375px;
  > div > div {
    max-width: 375px;
    min-width: 375px;
  }
  > div {
    padding: 1.5rem;
  }
`;

export const HelpText = styled(TextV2)`
  svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }
`;

export const StyledTitleText = styled(TextV2)`
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const titleTextStyles: StyleSystemProps = {
  marginY: 1,
  display: '-webkit-box',
  overflow: 'hidden'
};

const hideBoxStyles: StyleSystemProps = {
  background: 'info1',
  border: '1px solid',
  borderColor: 'info3',
  borderRadius: '8px'
};

export const styles = {
  hideBoxStyles: hideBoxStyles,
  titleText: titleTextStyles
};
