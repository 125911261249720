import React, { useMemo } from 'react';
import { Box, BoxProps, JoyKitThemeProvider } from '@withjoy/joykit';
import { DesignLayoutType, EventPageFragment } from '@graphql/generated';
import { UserProfile } from '@shared/components/AuthProvider/AuthProvider.reducer';
import { isSupportedEventPage } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import { withWindow } from '@shared/utils/withWindow';
import { NavBar as NewNavBar } from '@apps/guest/packages/layout-engine/layouts/LayoutBrannan/components/NavBar';
import { useNavBarController } from './NavBar.controller';
import { UnlockDialogProvider } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';

export interface NavBarProps
  extends Readonly<{
      eventHandle: string;
      pages?: ReadonlyArray<EventPageFragment>;
      layout?: DesignLayoutType;
      currentUser: UserProfile;
      onHandleLogout: () => void;
      primaryTextColor?: string;
      font: string;
    }>,
    BoxProps {}

export const isActive = (pageSlug: string) => {
  let isActive = false;
  withWindow(window => {
    const pathnames = window.location.pathname.split('/');
    if (pageSlug === pathnames[pathnames.length - 1]) {
      isActive = true;
    }
  });
  return isActive;
};

export const NavBar: React.FC<NavBarProps> = ({ eventHandle, pages, layout }) => {
  const { event, info, navBarVariant, theme, navbarEventProps } = useNavBarController({ eventHandle, layout });

  const filteredPages = useMemo(() => {
    return (
      pages?.filter(page => {
        return isSupportedEventPage(page.type) && !page.disabled;
      }) || []
    );
  }, [pages]);

  if (!filteredPages.length || !event) {
    return null;
  }

  return (
    <JoyKitThemeProvider theme={theme}>
      <UnlockDialogProvider>
        <Box marginTop={-56} width="100%" marginBottom={[0, null, 9]}>
          <NewNavBar
            title={info?.eventDisplayName || ''}
            variant={navBarVariant}
            data-testid={'header-nav'}
            eventHandle={eventHandle}
            pages={filteredPages}
            eventProps={navbarEventProps}
          />
        </Box>
      </UnlockDialogProvider>
    </JoyKitThemeProvider>
  );
};
