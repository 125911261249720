import React from 'react';
import { getEventTypeConfig } from '@config';
import { Seo } from '@shared/components/Seo';
import { generateDescriptionKeywords } from '@shared/utils/seo';
import { EventType } from '@graphql/generated';

/**
 * Adds a query param to the image url to optimize the image from Media Service and add a source query param
 */
const addOptimizationQueryParam = (url: Maybe<string>): string | undefined => {
  if (!url) return undefined;
  const hasQueryParam = url.includes('?');
  const separator = hasQueryParam ? '&' : '?';
  return `${url}${separator}rendition=small&source=registrypreview`;
};

type PageData = {
  id: string;
  disabled: boolean;
  type: string;
  pageSlug: string;
};

interface GuestRegistryHeadProps
  extends Readonly<{
    handle: Maybe<string>;
    eventDisplayName: Maybe<string>;
    imageUrl: Maybe<string>;
    ownerFirstName: Maybe<string>;
    ownerLastName: Maybe<string>;
    fianceeFirstName: Maybe<string>;
    pageData: PageData[];
    eventType: EventType;
  }> {}

export const GuestRegistryHead: React.FC<GuestRegistryHeadProps> = props => {
  const { handle, eventDisplayName, imageUrl, ownerFirstName, ownerLastName, fianceeFirstName, pageData, eventType } = props;
  const keywords = generateDescriptionKeywords(pageData);
  const userInfo = { partnerOneFirstName: ownerFirstName || '', partnerOneLastName: ownerLastName || '', partnerTwoFirstName: fianceeFirstName || '' };
  const pageTitle = getEventTypeConfig(eventType)?.guestSitePageTitle(eventDisplayName || '', userInfo) || '';
  const pageDescription = getEventTypeConfig(eventType)?.guestSitePageDescription(eventDisplayName || '', userInfo, keywords);
  const pageUrl = `https://withjoy.com/${handle}/`;

  return (
    <Seo
      title={pageTitle}
      description={pageDescription}
      canonicalURL={pageUrl}
      name="Joy"
      url={pageUrl}
      ogImage={addOptimizationQueryParam(imageUrl)}
      author="@JoytheApp"
      twitterSite="@JoytheApp"
    />
  );
};
