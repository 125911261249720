import type { CheckoutEventTypes, CheckoutEvent, CheckoutMachineInterpreterSend, CheckoutMachineInterpreterState } from './externalCheckout.types';

/**
 * Get a subset of a discriminated union based on a subset of the discriminator
 */
type SubsetOfCheckoutEventTypes<Type extends CheckoutEventTypes> = Extract<CheckoutEvent, { type: Type }>;
type DerivedEventData<Type extends CheckoutEventTypes> = SubsetOfCheckoutEventTypes<Type> extends { type: CheckoutEventTypes; data: infer R } ? R : never;
type CurriedSendToMachineReturn<Type extends CheckoutEventTypes> = DerivedEventData<Type> extends never ? () => void : (data: DerivedEventData<Type>) => void;

interface CheckoutSendEventAliases extends ReturnType<typeof getSendEventAliases> {}
interface ServiceContextFacade extends ReturnType<typeof getServiceContextFacade> {}

export interface CheckoutServiceFacade extends CheckoutSendEventAliases, ServiceContextFacade {}

/**
 * Creates public facing checkout helpers that abstracts out xstate implementation
 * detail. Enables developers to send events without having to learn internals.
 *
 * ```
 * const [state, send] = useActor(...);
 * const { submitGuestDetails } = getSendEventAliases(send);
 * submitGuestDetails({ username, password})
 * ```
 */
const getSendEventAliases = (send: CheckoutMachineInterpreterSend) => {
  const sendToMachine = <Type extends CheckoutEventTypes>(type: Type) => {
    return ((data: DerivedEventData<Type>) => {
      send((data ? { type, data } : { type }) as CheckoutEvent);
    }) as CurriedSendToMachineReturn<Type>;
  };

  return {
    //----------------------------
    // Setup state
    setupMachine: sendToMachine('INIT'),

    //----------------------------
    // Gift Amount state
    submitGiftAmountForm: sendToMachine('SUBMIT_GIFT_AMOUNT_FORM'),

    // Gift Pdp state
    submitGiftPdpForm: sendToMachine('SUBMIT_GIFT_PDP_FORM'),
    updateDonationAmount: sendToMachine('UPDATE_DONATION_AMOUNT'),
    //----------------------------
    // Gifter Details state

    /**
     * Sending this event will transition the state depending on which flow you're in:
     *  - flow = 'full' -> `paymentMethod` stage
     */
    submitGuestDetails: sendToMachine('SUBMIT_GUEST_DETAILS'),

    //----------------------------
    // Payment Method state
    /**
     * Sending this event will transition the state to a checkout flow that corresponds
     * to the payment method: paper, or external
     */
    selectPaymentMethod: sendToMachine('SELECT_PAYMENT_METHOD'),
    addGiftWrap: sendToMachine('SUBMIT_GIFT_WRAP'),
    skipGiftWrap: sendToMachine('SKIP_GIFT_WRAP'),
    submitGiftNote: sendToMachine('SUBMIT_GIFT_NOTE'),
    upgradeGiftCard: sendToMachine('UPGRADE_GIFT_CARD'),

    /**
     * Sending this event will transition to get help.
     */
    getHelp: sendToMachine('GET_HELP'),

    submitOrderNumberForm: sendToMachine('SUBMIT_TRACKING_DETAILS_FORM'),
    addTrackingLater: sendToMachine('ADD_TRACKING_LATER'),

    //----------------------------
    // Cancel Gift state

    /**
     * Sending this event will return the gift to the shopping cart.
     */
    sendGiftLater: sendToMachine('SEND_GIFT_LATER'),
    /**
     * Sending this event will transition to the the cancel gift warning flow.
     */
    noIntentToSendLater: sendToMachine('NO_INTENT_TO_SEND'),

    confirmGiftCancel: sendToMachine('CONFIRM_CANCEL'),
    /**
     * Sending this event will transition the state to the most relevant state from
     * right before where the user initiated the cancel flow.
     */
    abortGiftCancel: sendToMachine('DISPROVE_CANCEL'),

    closeDialog: sendToMachine('CLOSE_DIALOG'),

    //----------------------------
    // General transitions
    toBack: sendToMachine('BACK'),
    toNext: sendToMachine('NEXT'),

    /**
     * Sending this event will transition to the cancel flow.
     */
    initiateCancelProcess: sendToMachine('INITIATE_CANCEL_PROCESS')
  };
};

const getServiceContextFacade = (state: CheckoutMachineInterpreterState) => {
  const { formValues, ...context } = state.context;

  // Although there are many transition states, we're only rendering a subset of them.
  const route = (() => {
    switch (true) {
      case state.matches('setup'):
        return 'setup';
      case state.matches('giftAmount'):
        return 'giftAmount';
      case state.matches('giftPdp'):
        return 'giftPdp';
      case state.matches('gifterDetails'):
        return 'gifterDetails';
      case state.matches('giftWrap'):
        return 'giftWrap';
      case state.matches('giftWrapNote'):
        return 'giftWrapNote';
      case state.matches('paymentMethod'):
        return 'paymentMethod';

      // Paper Checkout
      case state.matches('checkout.creditCardCheckout.initCheckout'):
        return 'creditCardCheckoutIdle';
      case state.matches('checkout.paperCheckout.instructions'):
        return 'paperCheckoutInstructions';
      case state.matches('checkout.paperCheckout.instructionsGiftWrap.idle'):
        return 'paperCheckoutInstructionsGiftWrapIdle';
      case state.matches('checkout.paperCheckout.instructionsGiftWrap.loading'):
        return 'paperCheckoutInstructionsGiftWrapLoading';
      case state.matches('checkout.paperCheckout.confirmAndNotify'):
        return 'paperCheckoutConfirmAndNotify';

      // External Checkout
      case state.matches('checkout.externalCheckout.instructions'):
        return 'externalCheckoutInstructions';
      case state.matches('checkout.externalCheckout.instructionsGiftWrap.idle'):
        return 'externalCheckoutInstructionsGiftWrapIdle';
      case state.matches('checkout.externalCheckout.instructionsGiftWrap.loading'):
        return 'externalCheckoutInstructionsGiftWrapLoading';
      case state.matches('checkout.externalCheckout.review'):
        return 'externalCheckoutReview';
      case state.matches('checkout.externalCheckout.confirmAndNotify'):
        return 'externalCheckoutConfirmAndNotify';
      case state.matches('checkout.externalCheckout.getHelp'):
        return 'externalCheckoutGetHelp';
      case state.matches('checkout.externalCheckout.provideTrackingDetails'):
        return 'provideTrackingDetails';
      case state.matches('checkout.externalCheckout.externalCheckoutCopyAddress'):
        return 'externalCheckoutCopyAddress';

      // Cancel states
      case state.matches('cancelGift.intent'):
        return 'cancelGiftIntent';
      case state.matches('cancelGift.warning'):
        return 'cancelGiftWarning';
      case state.matches('cancelGift.cancellationConfirmed'):
        return 'cancelGiftConfirmation';

      // Final states
      case state.matches('alreadyReserved'):
        return 'alreadyReservedWarning';
      case state.matches('confirmedGift'):
        return 'confirmedPurchase';
      case state.matches('registryItemNotFound'):
        return 'registryItemNotFound';

      default:
        // eslint-disable-next-line no-console
        console.debug(`Cannot infer route from CheckoutFunnel state`);
        return null;
    }
  })();

  return {
    context,
    /**
     * In the state machine, we can specify that no state transition should occur on the `BACK` event
     *
     * This is considered a "forbidden" transition.
     *
     * https://xstate.js.org/docs/guides/transitions.html#forbidden-transitions
     */
    canShowBackButton: state.can({ type: 'BACK' }),
    /**
     * In the state machine, we can specify that no state transition should occur on the `CLOSE_DIALOG` event.
     *
     * This is considered a "forbidden" transition.
     *
     * https://xstate.js.org/docs/guides/transitions.html#forbidden-transitions
     */
    canShowCloseDialogButton: state.can({ type: 'CLOSE_DIALOG' }),

    /**
     * Form values that are provided by the user.
     */
    formValues,
    route: route as typeof route
  };
};

/**
 * Intended to only be used with `xstate`'s `useSelector` [hook](https://xstate.js.org/docs/packages/xstate-react/#quick-start).
 */
export const getServiceFacade = ({ send, state }: { send: CheckoutMachineInterpreterSend; state: CheckoutMachineInterpreterState }): CheckoutServiceFacade => {
  const sendEventAliases = getSendEventAliases(send);
  const serviceContextFacade = getServiceContextFacade(state);
  return {
    ...sendEventAliases,
    ...serviceContextFacade
  };
};
