import React, { useState } from 'react';
import { TextV2, Flex, SpacingStack } from '@withjoy/joykit';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { StyledBoxAddress, StyledAddressLink, StyledBoxWarning, boxAddressStyles } from './Address.styles';
import { ShippingAddressFragment } from '@graphql/aliases';
import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { WarningTriangle } from '@withjoy/joykit/icons';

interface AddressProps
  extends Readonly<{
    address: ShippingAddressFragment | null;
    showWarning: boolean;
    hideTitle?: boolean;
  }> {}

export const Address: React.FC<AddressProps> = ({ showWarning, address, hideTitle = false }) => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const { getPurchaseGiftTranslations } = useRegistryGuestTranslations();
  const { shippingAddress, copyAddress, copyToClipboard, missingShippingAddress, addressWarning } = getPurchaseGiftTranslations();

  const shippingAddressText = address
    ? `${address.name}\n${address.address1}\n${address.address2}${address.address2 ? '\n' : ''}${address.city}, ${address.state} ${address.postalCode}`
    : '';

  return (
    <>
      <StyledBoxAddress __css={boxAddressStyles(showWarning)}>
        <SpacingStack spacing={4}>
          <Flex alignItems="flex-start" justifyContent={hideTitle ? 'flex-end' : 'space-between'}>
            {!hideTitle && (
              <TextV2 typographyVariant="hed1" tagName="p" maxWidth="242px" marginRight={4}>
                {shippingAddress()}
              </TextV2>
            )}
            {address ? (
              <>
                <CopyToClipboard text={shippingAddressText} onCopy={() => setNotificationVisible(true)}>
                  <StyledAddressLink underline="none">{copyAddress()}</StyledAddressLink>
                </CopyToClipboard>
                <NotificationToast
                  notificationText={copyToClipboard()}
                  notificationVisible={notificationVisible}
                  setNotificationVisible={setNotificationVisible}
                  isPositionTop
                  noAnimation
                />
              </>
            ) : null}
          </Flex>
          {address ? (
            <CopyToClipboard text={shippingAddressText} onCopy={() => setNotificationVisible(true)}>
              <div>
                <TextV2 typographyVariant="body1" tagName="p">
                  {address.name}
                </TextV2>
                <TextV2 typographyVariant="body1" tagName="p">
                  {address.address1}
                </TextV2>
                {address.address2 && (
                  <TextV2 typographyVariant="body1" tagName="p">
                    {address.address2}
                  </TextV2>
                )}
                <TextV2 typographyVariant="body1" tagName="p">
                  {`${address.city}, ${address.state} ${address.postalCode}`}
                </TextV2>
              </div>
            </CopyToClipboard>
          ) : (
            <TextV2 tagName="p" typographyVariant="body1">
              {missingShippingAddress()}
            </TextV2>
          )}
        </SpacingStack>
      </StyledBoxAddress>
      {showWarning && (
        <StyledBoxWarning padding="4px 16px" typographyVariant="label2">
          <WarningTriangle marginRight="5px" size={16} color="orange6" />
          {addressWarning()}
        </StyledBoxWarning>
      )}
    </>
  );
};
