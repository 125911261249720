import * as React from 'react';
import { Label, Input } from './checkbox.styles';
import { ReactComponent as Check } from '@assets/icons/checkbox.svg';

export interface CheckboxProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  checkedBackgroundColor?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, id, checkedBackgroundColor }) => {
  return (
    <div>
      <Input type="checkbox" id={id} checked={checked} onChange={onChange} data-id={id} checkedBackgroundColor={checkedBackgroundColor} />
      <Label htmlFor={id}>
        <Check />
      </Label>
    </div>
  );
};
