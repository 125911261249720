import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledItemContainer = styled(Flex)``;

export const StyledPill = styled(Box)``;

const itemContainerStyles: StyleSystemProps = {
  color: 'white',
  alignItems: 'center'
};

const pillStyles: StyleSystemProps = {
  padding: `${pxToRem(2)} ${pxToRem(5)}`,
  borderRadius: pxToRem(5),
  color: 'mono14',
  border: 'solid 1px',
  display: 'inline-block',
  typographyVariant: 'label2'
};

const pillLightStyles: StyleSystemProps = {
  ...pillStyles,
  borderColor: 'white',
  backgroundColor: 'white'
};

const pillDarkStyles: StyleSystemProps = {
  ...pillStyles,
  borderColor: 'mono14'
};

export const styles = {
  itemContainer: itemContainerStyles,
  pill: pillStyles,
  pillLight: pillLightStyles,
  pillDark: pillDarkStyles
};
