import React, { useEffect } from 'react';
import { StyledTitleText, StyledSubText } from '../RegistryProtected.styles';
import { LinkV1 } from '@withjoy/joykit';
import globalWindow from '@shared/core/globals';
import { useRegistryGuestTranslations } from '../../../GuestRegistry.i18n';

interface ContactSuccessProps {
  website: string;
}
export const ContactSuccess: React.FC<ContactSuccessProps> = ({ website }) => {
  const { getContactTheHostTranslations } = useRegistryGuestTranslations();
  const { successTitle, sucessSubtitle, successLink } = getContactTheHostTranslations();
  const redirectUrl = `${window.location.origin}/${website}`;

  useEffect(() => {
    const timerId = globalWindow.setTimeout(() => {
      window.location.href = redirectUrl;
    }, 5000);
    return () => {
      globalWindow.clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledTitleText marginBottom={8}>{successTitle}</StyledTitleText>
      <StyledSubText>
        {sucessSubtitle}
        <LinkV1 color={'linkText'} href={redirectUrl}>
          {successLink}
        </LinkV1>
      </StyledSubText>
    </>
  );
};
