import React, { useMemo } from 'react';
import { StyledGiftNoteWrapperV2, StyledAddNoteButtonV2, StyledAddGiftNoteWrapperV2, styles } from './GiftNoteV2.styles';
import { TextV2, Flex } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useGiftNoteController } from './GiftNote.controller';
import { ReactComponent as GiftNoteIcon } from '@assets/icons/gift-note.svg';
import { GiftNoteMetadata } from '../MetadataAtoms';
import { ButtonVariant } from '@withjoy/joykit/components/ButtonV2/Button.types';
import { getGiftNoteDisplayText } from '@apps/registry/admin/components/GiftNoteDisplay/GiftNoteDisplay';

export interface GiftNoteV2Props {
  note: Maybe<string>;
  orderId: string;
  variant: ButtonVariant;
}

export const GiftNoteV2: React.FC<GiftNoteV2Props> = ({ note, orderId, variant }) => {
  const { t } = useTranslation('guestRegistry');
  const { addGiftNote } = t('shoppingCart');
  // Temporary solution to avoid displaying Gift Wrap message JSON as a string
  // Once gift Wrap as a lineItem on transaction is ready, we can remove this and use the giftNote prop directly
  const displayNote = useMemo(() => getGiftNoteDisplayText(note ?? ''), [note]);

  const {
    isInEditMode,
    formik,
    handleOnClickEdit,
    handleOnClickSave,
    handleCancel,
    loading,
    giftNoteNotificationText,
    giftNoteNotificationIsVisible,
    giftNoteError
  } = useGiftNoteController(displayNote, orderId);
  const currentNote = formik.values.note;

  if (currentNote || isInEditMode) {
    return (
      <StyledGiftNoteWrapperV2 __css={styles.giftNoteWrapper}>
        {giftNoteNotificationIsVisible && (
          <Flex marginBottom={3}>
            <TextV2 typographyVariant="label3" htmlFor="note" as="label" color={giftNoteError ? 'negative7' : 'positive7'}>
              {giftNoteNotificationText}
            </TextV2>
          </Flex>
        )}

        <GiftNoteMetadata
          giftNote={currentNote || ''}
          handleOnClickEdit={handleOnClickEdit}
          handleOnClickSave={handleOnClickSave}
          handleCancel={handleCancel}
          loading={loading}
          isEditMode={isInEditMode}
          formik={formik}
        />
      </StyledGiftNoteWrapperV2>
    );
  }

  return (
    <StyledAddGiftNoteWrapperV2 __css={styles.addGiftNoteWrapper}>
      <StyledAddNoteButtonV2 startIcon={<GiftNoteIcon />} intent="neutral" variant={variant} onClick={handleOnClickEdit}>
        {addGiftNote()}
      </StyledAddNoteButtonV2>
    </StyledAddGiftNoteWrapperV2>
  );
};
