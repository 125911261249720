import { Alert, ContentHeader, CtaGroup, PrimaryCta, SecondaryCta } from '@apps/registry/guest/components/CheckoutDialog/components';
import {
  StyledListContainer,
  StyledListItemContainer,
  StyledListItemNumber,
  styles
} from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutInstructions/ExternalCheckoutInstructions.styles';
import { TextV2 } from '@withjoy/joykit';
import { ExternalCheckoutInstructionsSelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutInstructions/ExternalCheckoutInstructions.selectors';
import React from 'react';
import { TranslateFunction } from '@locales/generated';
import { getPaymentMethodTypeName } from '@shared/utils/paymentMethodOptions';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DonationFundPaymentMethodEnum } from '@graphql/generated';

type Props = {
  translations: {
    title: TranslateFunction;
    alertTitle?: TranslateFunction;
    alertText: TranslateFunction;
    gotItButton: TranslateFunction;
    step1?: string;
    step2?: string;
    step3?: string;
    copyButton: TranslateFunction;
  };
  toNext: () => void;
  paymentMethod?: DonationFundPaymentMethodEnum;
  account?: string;
};

export const ExternalCheckoutInstructionsContent = ({ translations, toNext, paymentMethod, account }: Props) => {
  const { title, alertTitle, alertText, step1, step2, step3, gotItButton, copyButton } = translations;

  return (
    <>
      <ContentHeader>
        <ContentHeader.Hed>{title()}</ContentHeader.Hed>
      </ContentHeader>

      <StyledListContainer spacing={5} __css={styles.listContainer}>
        {[step1, step2, step3].map((step, index) => (
          <StyledListItemContainer __css={styles.listItemContainer} key={index}>
            <StyledListItemNumber __css={styles.listItemNumber}>{index + 1}</StyledListItemNumber>
            <TextV2
              typographyVariant="body1"
              dangerouslySetInnerHTML={{
                __html: step || ''
              }}
            />
          </StyledListItemContainer>
        ))}
      </StyledListContainer>

      <Alert title={alertTitle?.()} description={alertText()} />

      <CtaGroup marginTop={7}>
        {!!paymentMethod && !!account && (
          <CopyToClipboard text={account}>
            <SecondaryCta>{copyButton({ account: getPaymentMethodTypeName(paymentMethod) })}</SecondaryCta>
          </CopyToClipboard>
        )}
        <PrimaryCta {...ExternalCheckoutInstructionsSelectors.GotItButton} onClick={toNext}>
          {gotItButton()}
        </PrimaryCta>
      </CtaGroup>
    </>
  );
};
