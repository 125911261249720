import { PDPSectionType } from '@graphql/generated';
import { CatalogCollectionProductItemDetails, CatalogProductItemDetails } from '../../../../Catalog.types';

export type SectionElementTypes = Pick<
  NonNullable<NonNullable<CatalogProductItemDetails['pdpSections']>[number]['sectionTree']>['elements'][number],
  'key' | 'elementType' | 'childrenKeys' | 'html' | 'text' | 'photo'
>;
export type SectionItemType = SectionElementTypes & {
  children: SectionItemType[];
  html: NonNullable<SectionElementTypes['html']>;
};

export type PDPSection = Pick<NonNullable<CatalogProductItemDetails['pdpSections']>[number], 'sectionType' | 'collectionType' | 'sectionTree'>;

export type SharedPdpElementFields = NonNullable<Pick<SectionElementTypes, 'key' | 'html' | 'text'>>;

export type DetailSectionItem = {
  heading: SharedPdpElementFields;
  body: SharedPdpElementFields;
};

export type DetailPdpSection = {
  key: SharedPdpElementFields['key'];
  title: SharedPdpElementFields['text'];
  items: Array<DetailSectionItem>;
};

export type CollectionDisplayInfo = {
  __typename?: 'ProductCatalogCollectionDisplayInfo' | undefined;
  displayInfoId: string;
  pdpSectionType?: Maybe<PDPSectionType> | undefined;
};

export interface RelatedProductCollectionsSectionExtraArgs {
  collectionDisplayInfos: CollectionDisplayInfo[];
  isMobile: boolean;
  isDialog: boolean;
  selectedProductId?: string;
  handleQuickAddCollectionItem?: (_product: CatalogCollectionProductItemDetails, onAddComplete: () => void, sectionType: Maybe<PDPSectionType>) => void;
  handleViewDetailsCollectionItem?: (_product: CatalogCollectionProductItemDetails) => void;
}

// Conditional type depending on PDPSectionType and getSectionExtraArgsByType return types
// We can add more extraArgs types in the future for further sections
export type ExtraArgs = RelatedProductCollectionsSectionExtraArgs | null;
export type ExtraArgsByType<T> = T extends PDPSectionType.relatedProductCollections ? RelatedProductCollectionsSectionExtraArgs : null;

export function isDefinedPredicate<T>(x: Maybe<T>): x is T {
  return typeof x !== 'undefined';
}

export const isDetailsSectionType = (section: PDPSection) => section?.sectionType === 'details';
