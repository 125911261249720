import { useMemo } from 'react';
import {
  CustomItemDataType,
  MonetaryValueFragment,
  DonationFundFragment,
  DonationFundDonationFragment,
  DonationFundPlatformFragment,
  RegistryItemFragment,
  AdminRegistryFragment,
  PDPSectionType,
  DonationFundPaymentMethodEnum
} from '@graphql/generated';
import { AdminProductFilter, AdminRegistryList, AdminOrderMinimumList, ProductFilter, RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { RegistryMonetaryValue } from '@graphql/aliases';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { CurrencyFormatterArgs } from '@shared/utils/currency/types';
import { ProductStatusFlags } from '@apps/registry/guest/routes/GuestRegistry/components/ProductTile';
import { useFeatureValue } from '@shared/core/featureFlags';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';

export enum ProductStatusFlagsDynamic {
  LabelTrending = 'labelTrending'
}

export type CookedRegistry = {
  id: string;
  name: string | null | undefined;
};

type ProductType = 'canned' | 'product' | 'custom' | undefined;

export type DerivedDonationFund = {
  goal: number;
  received: number;
  remaining: number;
  fund: DonationFundFragment;
};

export type FundCatalogItem = {
  goalMonetaryValue: Pick<MonetaryValueFragment, 'valueInMinorUnits' | 'floatingPointDecimalString'>;
  platform?: DonationFundPlatformFragment;
  description?: string;
  suggestedDonationMonetaryValue?: Pick<MonetaryValueFragment, 'valueInMinorUnits'>;
  hideFundingGoal?: boolean;
  enforceSuggestedDonationAmount?: boolean;
  url?: string;
};

export type DonationFundCatalogItem = {
  fund: FundCatalogItem;
  received?: number;
};

export type CookedProductCatalogItem = Pick<CookedProduct, 'id' | 'title' | 'image' | 'requested' | 'custom'> & {
  numberPrice: number;
  donationFund: DonationFundCatalogItem;
  currencyCode: string;
  mustHave?: boolean;
  isGroupGiftingEnabled?: Maybe<boolean>;
  collectionDisplayInfos?: Array<
    Maybe<{
      displayInfoId: string;
      pdpSectionType?: PDPSectionType | null;
    }>
  >;
};

type MainReducedItem = {
  id: string;
  registryItemId: string;
  externallyOwned: boolean;
  canEditProductData: boolean;
  registryItemType: ProductType;
  title: string;
  description: string;
  note: string;
  image: string;
  storeName?: string | null;
  externalUrl: string;
  checkoutUrl: string;
  pdpUrl: string;
  isBeingRemoved?: boolean;
  isRemoved?: boolean;
  custom?: {
    type: Maybe<CustomItemDataType>;
  };
  isHidden?: boolean;
  mustHave: boolean;
  createdAtMilliseconds: number;
  brand?: string | null;
  isGroupGiftingEnabled?: Maybe<boolean>;
  isGetCashEquivalent?: Maybe<boolean>;
  collectionDisplayInfos?: Array<
    Maybe<{
      displayInfoId: string;
      pdpSectionType?: PDPSectionType | null;
    }>
  >;
  __typename?: string;
};

type CalculatedItemPrice = {
  currencyCode?: string;
  floatingPointDecimalString: string;
  price: string;
  totalPrice: string;
  numberPrice: number;
  fullPrice?: string;
  fullPriceCurrencyCode?: string;
  valueInMinorUnits: number;
  wholeNumPrice: string;
  charmPrice: string;
  isFullPrice?: boolean;
};

type CalculatedItem = {
  favorite: boolean;
  requested: number;
  purchased: number;
  reserved: number;
  fpIndex: number;
  stillNeeded: number;
  totalRequested?: number;
  donationFund: DerivedDonationFund | null;
  registry: {
    id?: string | null;
    logo?: string | null;
    name?: string | null;
    url?: string | null;
    isTransferredType?: boolean;
    isSyncingType?: boolean;
  };
};

export type ItemBase = Readonly<MainReducedItem & CalculatedItem>;
export type ItemWithPrice = Readonly<MainReducedItem & CalculatedItem & CalculatedItemPrice>;

export type CookedProduct = (ItemBase | ItemWithPrice) & {
  generatedLabel?: Maybe<ProductStatusFlagsDynamic>;
  numberPrice?: number;
  floatingPointDecimalString?: string;
  fullPrice?: string;
  fullPriceCurrencyCode?: string;
  currencyCode?: string;
  charmPrice?: string;
  isFullPrice?: boolean;
};

export type CookedProductList = Readonly<{
  counts: {
    everything: number;
    favorites: number;
    stillNeeded: number;
    purchased: number;
    purchasedDeliverable: number;
    reserved: number;
    reservedDeliverable: number;
    over100: number;
    under100: number;
    available: number;
    deliverable: number;
  };
  products: ReadonlyArray<CookedProduct>;
}>;

export type FormattedRegistryOrderDetails = Readonly<{
  id: string;
  name?: Maybe<string>;
  email?: Maybe<string>;
  note?: Maybe<string>;
  formattedAmount: string | number;
  formattedOrderedAtDate: string;
  isCancelled?: boolean;
  isPurchased?: boolean;
  donationFundPlatformType?: Maybe<DonationFundPaymentMethodEnum>;
}>;

type CookedProductOrCookedProductCatalogItem = Maybe<CookedProduct | CookedProductCatalogItem>;

export function isPricedProduct(product: CookedProductOrCookedProductCatalogItem): product is ItemWithPrice {
  return !!product && !!(product as ItemWithPrice).numberPrice;
}

export const isCustomCashFundItem = (product: CookedProductOrCookedProductCatalogItem): product is CookedProduct => {
  return product?.custom?.type === CustomItemDataType.cash && !!product.donationFund && typeof product.isGroupGiftingEnabled !== 'boolean';
};

export const isCustomPhysicalItem = (product: CookedProduct) => {
  return product.custom?.type === CustomItemDataType.gift;
};

export const isCustomCharityFundItem = (product: CookedProductOrCookedProductCatalogItem): product is CookedProduct => {
  return product?.custom?.type === CustomItemDataType.charity && !!product.donationFund;
};

export const isProductFullyPurchased = (product: CookedProduct) => {
  return product.donationFund ? product.donationFund.goal !== 0 && product.donationFund.remaining === 0 : product.stillNeeded <= 0;
};

export function isCustomDonationFundItem(product: CookedProductOrCookedProductCatalogItem): product is CookedProductCatalogItem;
export function isCustomDonationFundItem(product: CookedProduct): product is CookedProduct;
export function isCustomDonationFundItem(product: CookedProductOrCookedProductCatalogItem): product is CookedProduct {
  return !!product && (isCustomCashFundItem(product) || isCustomCharityFundItem(product));
}

export const isCustomDonationFundItemNoGoal = (product: CookedProductOrCookedProductCatalogItem) => {
  return !!product && isCustomDonationFundItem(product) && !product.donationFund?.fund.goalMonetaryValue?.valueInMinorUnits;
};

export const isCustomDonationFundItemHideGoal = (product: Maybe<CookedProduct>) => {
  return !!product && isCustomDonationFundItem(product) && !!product.donationFund?.fund.hideFundingGoal;
};

export const isGroupGiftPurchasedAsPhysical = (product: Maybe<CookedProduct>) => {
  return !!(product && product.isGroupGiftingEnabled && product.purchased === 1);
};
export const isGroupGiftReservedAsPhysical = (product: Maybe<CookedProduct>) => {
  return !!(product && product.isGroupGiftingEnabled && product.reserved === 1);
};
export const hasCustomProducts = (registries?: RegistryList, eventId?: string) => !!registries?.some(registry => registry.id === eventId && registry.items?.length);
export const hasProducts = (registries?: RegistryList) => !!registries?.some(registry => registry.items?.length);

export const productStatus = (product: CookedProduct, orders: AdminOrderMinimumList, isReservedByYou?: boolean, isPurchasedByYou?: boolean) => {
  const orderByProductId = orders?.find(order => order.lineItems.some(lineItem => lineItem.registryItem?.id === product.id));

  if (isGroupGiftPurchasedAsPhysical(product)) {
    return ProductStatusFlags.Purchased;
  }

  if (isGroupGiftReservedAsPhysical(product)) {
    return ProductStatusFlags.Reserved;
  }

  if (product.stillNeeded <= 0 && !isCustomDonationFundItemNoGoal(product)) {
    if (product.externallyOwned || orderByProductId?.isPurchased) {
      return product.isGroupGiftingEnabled ? ProductStatusFlags.Funded : ProductStatusFlags.Purchased;
    } else if (isReservedByYou) {
      return ProductStatusFlags.ReservedByYou;
    } else if (isPurchasedByYou) {
      return ProductStatusFlags.PurchasedByYou;
    } else {
      return product.reserved > 0 ? ProductStatusFlags.Reserved : ProductStatusFlags.Purchased;
    }
  } else {
    return null;
  }
};

export const productStatusDynamic = (product: CookedProduct, orders: AdminOrderMinimumList, isReservedByYou?: boolean, isPurchasedByYou?: boolean, tileDisplay?: boolean) =>
  tileDisplay ? product.generatedLabel : null;

export const contributedPercentage = (product: Maybe<CookedProduct>) => {
  if (product && product.donationFund && product.donationFund.remaining !== 0 && product.donationFund.received !== 0) {
    const contributed = (product.donationFund.received * 100) / product.donationFund.goal;
    return Number(contributed).toFixed() + '%';
  }
  return null;
};

const shouldAdd = (cookedProduct: CookedProduct, orders: AdminOrderMinimumList, filter: ProductFilter) => {
  const orderByProductId = orders?.find(order => order.lineItems.some(lineItem => lineItem.registryItem?.id === cookedProduct.id));

  switch (filter) {
    case 'everything':
      return true;
    case 'favorites':
      return false;
    case 'purchased':
      return cookedProduct.purchased > 0 || orderByProductId?.isPurchased;
    case 'reserved':
      return cookedProduct.reserved > 0 && !orderByProductId?.isPurchased;
    case 'stillNeeded':
      return cookedProduct.stillNeeded > 0 || isCustomDonationFundItemNoGoal(cookedProduct);
    case 'under100':
      return isPricedProduct(cookedProduct) && cookedProduct.numberPrice < 100;
    case 'over100':
      return isPricedProduct(cookedProduct) && cookedProduct.numberPrice >= 100;
    case 'priceDescending':
      return isPricedProduct(cookedProduct);
    case 'reservedAndPurchased':
      return cookedProduct.purchased > 0 || cookedProduct.reserved > 0;
    default:
      return shouldAddAdmin(cookedProduct, orders, filter);
  }
};

const shouldAddAdmin = (cookedProduct: CookedProduct, orders: AdminOrderMinimumList, filters: AdminProductFilter[]) => {
  const filteredItems: AdminProductFilter[] = filters.filter(item => {
    switch (item.key) {
      case 'registry':
        return cookedProduct.registry.id === item.value;
      case 'product':
        return shouldAdd(cookedProduct, orders, item.value as ProductFilter);
    }
  });
  return filteredItems.length === filters.length;
};

export const calculateNumberPrice = (price: RegistryMonetaryValue | MonetaryValueFragment) => {
  return price.valueInMinorUnits / 10 ** (price.currency?.places || 0);
};

const calculatePricing = ({
  price,
  totalRequested = 0,
  formatCurrency,
  isFullPrice,
  displayDefaultCurrency
}: {
  price: RegistryMonetaryValue | MonetaryValueFragment;
  totalRequested: number;
  formatCurrency: (args: CurrencyFormatterArgs) => string;
  isFullPrice?: boolean;
  displayDefaultCurrency?: boolean;
}): CalculatedItemPrice => {
  const numberPrice = calculateNumberPrice(price);

  const { floatingPointDecimalString, currency } = price;
  const currencyCode = displayDefaultCurrency ? DEFAULT_CURRENCY : currency?.code || DEFAULT_CURRENCY;

  const formattedPrice = formatCurrency({
    priceFloatingPointDecimalString: floatingPointDecimalString,
    priceCurrencyCode: currencyCode,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });
  const formattedtotalPrice = formatCurrency({
    priceFloatingPointDecimalString: `${numberPrice * (totalRequested || 0)}`,
    priceCurrencyCode: currencyCode,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });
  const formattedWholeNumPrice = formatCurrency({
    priceFloatingPointDecimalString: floatingPointDecimalString,
    priceCurrencyCode: currencyCode,
    formatForm: 'short'
  });
  const charmPrice = formatCurrency({
    priceFloatingPointDecimalString: floatingPointDecimalString,
    priceCurrencyCode: currencyCode,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });

  return {
    floatingPointDecimalString,
    price: formattedPrice,
    numberPrice,
    totalPrice: formattedtotalPrice,
    wholeNumPrice: formattedWholeNumPrice,
    charmPrice,
    currencyCode,
    valueInMinorUnits: price.valueInMinorUnits,
    isFullPrice: isFullPrice ?? true
  };
};

export const _formatDonationsReceivedSoFar = (formatCurrency: (args: CurrencyFormatterArgs) => string, donationFund?: DerivedDonationFund) => {
  return formatCurrency({
    priceFloatingPointDecimalString: donationFund?.received?.toString() ?? '',
    priceCurrencyCode: donationFund?.fund?.currency?.code,
    formatForm: 'short'
  });
};

// Randomly assign labels to the first 10 shop products to analyze user behviour
const _generateRandomLabel = (product: CookedProduct) => {
  // TO DO: Move into constants file if feature proves value
  const shopItemsIds = [
    'b0f65ab3-66c2-5525-9d4e-e882c96fa35b',
    'c71b3a90-ac4f-5527-b7d7-d39fe603eb8e',
    '965d3999-1049-5ab4-ba17-0890a63f4512',
    'd0cae20b-40ff-597c-bd8a-7752aadefd18',
    'c00d70ca-88fb-58ee-99a4-cc77b2f14a54',
    '53193ad5-9a8e-572a-be3b-581753012966',
    '8aedd1c2-e62e-57d5-bedc-2eb216b3791f',
    'ad1aee2b-957e-5158-bb2e-8ab923011062',
    'eab93309-a906-5dea-8935-53fe2d3005d0',
    '8084ae3c-3603-56fe-9675-df06866f3da1',
    '1b05332b-3e26-5c6a-b729-a559b26a5889',
    'c13c3ba9-7d0f-52e1-88e4-387e2fe7fc8a',
    '5bb38ba7-da84-534f-9577-f7ef40d03fba',
    'fa9acf85-557d-5d7c-9e84-b9eeda720345',
    '4baf9ec0-70ff-5cd6-8e02-a740a3b5ce6d',
    '19d447da-24a2-524b-9745-666ed2562878',
    'ea1f99f4-bdab-5913-bdd5-454b3066089c',
    'eff1f41a-e511-5707-a6ba-cdf7caedff5a',
    'bd5f3ef9-2191-588e-882f-97f9e1f39c1c',
    'fbf381e1-5668-5aff-953e-e1dd9af990da',
    '46712b01-7b5c-5ce3-b120-37037aa35962',
    'ac4a5758-78ad-59c0-9cf5-c4597d689d83',
    '2ae1896e-5cb2-5021-b46c-d935e17a8e0e',
    'b3e56701-0e62-5b98-a9dd-e13b0365f1e2',
    'fdbadd84-39bf-5bcf-a873-ffca35fd9cbd',
    '0681aeb5-96e9-5b30-bc62-da2c22faca47',
    'b843296f-90d0-5e5a-8ce6-75cf2a59ed33',
    '0f7152ed-985e-5370-b7c5-1b14760b2d7d',
    '8a8195d9-daf1-57fc-b89d-245918cbacff',
    '88b33f1a-972e-5298-90ce-7c5c61dd4b78',
    '0acde007-ff89-5da2-a4e2-7db391525785'
  ];

  const itemIndex = shopItemsIds.findIndex(itemId => product.pdpUrl.includes(itemId));

  if (itemIndex === -1) {
    return;
  }
  return ProductStatusFlagsDynamic.LabelTrending;
};

const _calculateDonationsTotalInMinorUnits = (donations: DonationFundDonationFragment[]) => {
  if (!donations || !donations.length) {
    return 0;
  }
  return donations.reduce((acc, donation) => {
    return acc + donation.amount.valueInMinorUnits;
  }, 0);
};

export const useCookedRegistryList = (registries: AdminRegistryList) => {
  const registrytoCook = registries ? registries : [];

  const cookedRegistryList: Array<CookedRegistry> = registrytoCook.map((registry: AdminRegistryFragment) => ({
    id: registry.id,
    name: registry.nameV0204
  }));
  return cookedRegistryList;
};

export type CookedProductPostProcessor = (product: UnsealedReadonly<CookedProduct>, idx: number) => CookedProduct;

export const useCookedProductList = (
  registries: AdminRegistryList | RegistryList,
  orders: AdminOrderMinimumList,
  productFilter: ProductFilter,
  postProcessor?: CookedProductPostProcessor
) => {
  const { formatCurrency } = useCurrencyFormatter();

  const enableRandomLabelsGuestRegistryProducts = useFeatureValue('enableRandomLabelsGuestRegistryProducts');

  // deliverable products: Products that are not cash&fund, not donation and not eDelivery.
  // deliverable count is useful to know how many products are going to be delivery to the shipping address
  const cookedProductList: CookedProductList = useMemo(() => {
    const counts = {
      everything: 0,
      favorites: 0,
      stillNeeded: 0,
      purchased: 0,
      purchasedDeliverable: 0,
      reserved: 0,
      reservedDeliverable: 0,
      over100: 0,
      under100: 0,
      available: 0,
      deliverable: 0
    };
    const products: Array<CookedProduct> = [];
    const result = {
      counts,
      products
    };
    if (!registries) {
      return result;
    }

    for (let i = 0; i < registries.length; i++) {
      const registry = registries[i];
      const items = registry.items;

      if (!items || registry.wantSync === false) {
        continue;
      }

      const registryInfo = {
        id: registry.id,
        logo: registry.imageUrlV0204,
        name: registry.nameV0204,
        url: registry.urlV0204,
        isTransferredType: registry.isTransferredType,
        isSyncingType: registry.isSyncingType
      };

      for (let j = 0; j < items.length; j++) {
        const item = items[j];
        const { donationFund, alreadyReserved, alreadyPurchased } = item;

        const { cookedProduct, derivedDonationFund, priceRelatedItems, totalRequested, stillNeeded } = makeCookedProduct({
          formatCurrency,
          item,
          registryInfo
        });

        if (donationFund) {
          if (stillNeeded > 0) {
            result.counts.stillNeeded += 1;
          } else if (alreadyPurchased) {
            result.counts.purchased += 1;
          }
          if (alreadyReserved) {
            result.counts.reserved += 1;
          }

          const available = derivedDonationFund?.goal === 0 && derivedDonationFund?.remaining === 0 ? 1 : 0;
          result.counts.available += available;
        } else {
          if (stillNeeded > 0) {
            result.counts.stillNeeded += stillNeeded;
            result.counts.deliverable += stillNeeded;
          }
          if (alreadyPurchased) {
            result.counts.purchased += alreadyPurchased;
            result.counts.purchasedDeliverable += alreadyPurchased;

            if (cookedProduct) {
              const orderByProductId = orders?.find(order => order.lineItems.some(lineItem => lineItem.registryItem?.id === cookedProduct.id));
              result.counts.deliverable += orderByProductId?.lineItems[0].annotations.isEdelivery ? 0 : alreadyPurchased;
            } else {
              result.counts.deliverable += alreadyPurchased;
            }
          }
          if (alreadyReserved) {
            result.counts.reserved += alreadyReserved;
            result.counts.reservedDeliverable += alreadyReserved;
            result.counts.deliverable += alreadyReserved;
          }
        }

        if (priceRelatedItems) {
          if (priceRelatedItems.numberPrice < 100) {
            counts['under100']++;
          } else {
            counts['over100']++;
          }
        }

        if (!donationFund && totalRequested) {
          result.counts.everything += totalRequested;
        } else {
          result.counts.everything++;
        }

        if (cookedProduct && shouldAdd(cookedProduct, orders, productFilter)) {
          products.push(postProcessor?.(cookedProduct, j) || cookedProduct);
        }
      }
    }
    if (productFilter === 'priceDescending') {
      products.sort((item, item2) => {
        // not pushing products that have a price of undefined, so numberPrice always exists
        return (isPricedProduct(item2) ? item2.numberPrice : 0) - (isPricedProduct(item) ? item.numberPrice : 0);
      });
    } else {
      products.sort((item, item2) => {
        return item.fpIndex - item2.fpIndex;
      });
    }

    if (enableRandomLabelsGuestRegistryProducts)
      products.forEach(product => {
        product.generatedLabel = _generateRandomLabel(product);
      });

    return result;
  }, [registries, orders, productFilter, postProcessor, formatCurrency, enableRandomLabelsGuestRegistryProducts]);
  return cookedProductList;
};

export const makeCookedProduct = ({
  formatCurrency,
  item,
  registryInfo = {}
}: {
  formatCurrency: (args: CurrencyFormatterArgs) => string;
  item?: RegistryItemFragment;
  registryInfo?: CookedProduct['registry'];
}): {
  cookedProduct?: CookedProduct;
  priceRelatedItems?: CalculatedItemPrice;
  derivedDonationFund: Maybe<DerivedDonationFund>;
  totalRequested?: Maybe<number>;
  stillNeeded: number;
} => {
  if (!item) {
    return { cookedProduct: undefined, priceRelatedItems: undefined, derivedDonationFund: null, totalRequested: 0, stillNeeded: 0 };
  }

  const {
    id: registryItemId,
    productData,
    note,
    fpIndex,
    externallyOwned,
    canEditProductData,
    donationFund,
    storeName,
    alreadyReserved,
    alreadyPurchased,
    isHidden,
    mustHave,
    createAt,
    isGroupGiftingEnabled,
    isGetCashEquivalent,
    __typename
  } = item;
  let { totalRequested, stillNeeded } = item;
  const price = productData.price;

  const reducedObject: MainReducedItem = {
    id: registryItemId,
    description: productData.description || '',
    note: note || '',
    externallyOwned,
    canEditProductData,
    externalUrl: productData.externalUrl || '',
    checkoutUrl: productData.checkoutUrl || '',
    pdpUrl: productData.pdpUrl || '',
    registryItemId,
    image: productData.photos && productData.photos.length ? productData.photos[0].url : '',
    title: productData.title,
    registryItemType: productData.legacyType,
    custom: {
      type: productData.customType
    },
    isHidden,
    mustHave,
    createdAtMilliseconds: createAt.milliseconds,
    brand: productData.brand,
    isGroupGiftingEnabled,
    isGetCashEquivalent,
    __typename
  };

  let priceRelatedItems: CalculatedItemPrice | undefined = undefined;

  let derivedDonationFund: DerivedDonationFund | null = null;

  if (donationFund) {
    let reservedPurchasedCount: Maybe<number> = 0;
    if (alreadyPurchased && alreadyReserved) {
      reservedPurchasedCount = alreadyPurchased + alreadyReserved;
    } else {
      reservedPurchasedCount = alreadyPurchased || alreadyReserved;
    }
    reducedObject.description = reducedObject.description || donationFund.description || '';
    const { donations, goalMonetaryValue, suggestedDonationMonetaryValue, enforceSuggestedDonationAmount, progressToGoalPercent } = donationFund;
    const donationsTotalInMinorUnits = _calculateDonationsTotalInMinorUnits(donations); //guests can donate more than one 'suggestedDonationMonetaryValue.valueInMinorUnits'
    const donationsReceived = (externallyOwned && reservedPurchasedCount ? reservedPurchasedCount : donationsTotalInMinorUnits) / 100;

    if (enforceSuggestedDonationAmount && suggestedDonationMonetaryValue && goalMonetaryValue?.valueInMinorUnits) {
      // When true, users can only donate fixed amounts
      totalRequested = goalMonetaryValue.valueInMinorUnits / suggestedDonationMonetaryValue.valueInMinorUnits;
      stillNeeded = externallyOwned ? stillNeeded : Math.ceil(totalRequested - donationsTotalInMinorUnits / suggestedDonationMonetaryValue.valueInMinorUnits);
      priceRelatedItems = calculatePricing({
        price: suggestedDonationMonetaryValue,
        totalRequested,
        formatCurrency,
        displayDefaultCurrency: donationFund?.enableJoyCredit
      });
    } else {
      // When false, users can donate arbitrary amounts.
      // These type of cash funds properties are determined as such:
      //  - already purchased: the entire fund goal has been met.
      //  - total requested: the entire fund goal as a concept -- so 1.
      //  - still needed: at most 1 or 0, depending on completion
      let isCompleted: Boolean;
      if (goalMonetaryValue) {
        isCompleted = goalMonetaryValue.valueInMinorUnits - donationsTotalInMinorUnits <= 0; // funds with `donationsAreCapped = false` (anyAmount, noGoal) don't have `progressToGoalPercent`
      } else {
        isCompleted = progressToGoalPercent === 1;
      }

      // alreadyPurchased = donationsTotalInMinorUnits;
      totalRequested = 1;
      if (!externallyOwned) {
        stillNeeded = isCompleted ? 0 : 1;
      }
      if (goalMonetaryValue) {
        priceRelatedItems = calculatePricing({
          price: goalMonetaryValue,
          totalRequested,
          formatCurrency,
          displayDefaultCurrency: donationFund?.enableJoyCredit
        });
      }
    }

    if (goalMonetaryValue) {
      const goal = calculateNumberPrice(goalMonetaryValue);
      let remaining = donationsReceived > goal ? 0 : goal - donationsReceived;
      if (externallyOwned) {
        remaining = stillNeeded;
      }
      derivedDonationFund = {
        goal,
        received: donationsReceived,
        remaining,
        fund: donationFund
      };
    } else {
      derivedDonationFund = {
        goal: 0,
        received: donationsReceived,
        remaining: 1,
        fund: donationFund
      };
    }
  } else {
    if (price) {
      priceRelatedItems = calculatePricing({
        price,
        totalRequested: totalRequested || 0,
        formatCurrency,
        displayDefaultCurrency: !!isGetCashEquivalent
      });
    }
  }

  const cookedProduct: CookedProduct = {
    ...reducedObject,
    ...priceRelatedItems,
    fullPrice: productData.fullPrice?.floatingPointDecimalString,
    fullPriceCurrencyCode: productData?.fullPrice?.currency?.code,
    donationFund: derivedDonationFund,
    requested: totalRequested === null ? stillNeeded || 0 : totalRequested || 0,
    purchased: alreadyPurchased || 0,
    reserved: alreadyReserved || 0,
    stillNeeded: stillNeeded,
    favorite: false,
    storeName,
    fpIndex,
    registry: registryInfo,
    totalRequested: totalRequested ?? derivedDonationFund ? 0 : undefined,
    numberPrice: priceRelatedItems?.numberPrice,
    isFullPrice: productData.isFullPrice ?? true
  };

  return { cookedProduct, priceRelatedItems, derivedDonationFund, totalRequested, stillNeeded };
};
