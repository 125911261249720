import { SkeletonGroup, SkeletonThumbnail } from '@shared/components/Skeleton';
import { Box, Flex } from '@withjoy/joykit';
import React, { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { StyledArrow, StyledSliderContainer, styles } from './GalleryLightbox.styles';
import { GalleryThumbnails } from '../GalleryThumbnails';
import { ChevronLeftSquare, ChevronRightSquare } from '@withjoy/joykit/icons';
import { SliderContent } from './components/SliderContent/SliderContent';
import { useWindowResizeObserver, useWindowResizeSubscriber } from '@shared/utils/hooks/useWindowResize';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';

export const GL_ASSET_CONTAINER_VERTICAL_MARGIN = 220;

interface GalleryLightboxProps {
  isMobile?: boolean;
  assets: CatalogProductMediaAsset[];
  showSkeleton?: boolean;
  selectedAssetIndex: number;
  onClickAsset: (mediaAsset: CatalogProductMediaAsset) => void;
}

const PrevButton = ({ onClick }: { onClick: MouseEventHandler }) => (
  <StyledArrow __css={styles.arrow(true)} onClick={onClick}>
    <ChevronLeftSquare />
  </StyledArrow>
);

const NextButton = ({ onClick }: { onClick: MouseEventHandler }) => (
  <StyledArrow __css={styles.arrow()} onClick={onClick}>
    <ChevronRightSquare />
  </StyledArrow>
);

const GalleryLightbox: React.FC<GalleryLightboxProps> = props => {
  const { isMobile, assets, showSkeleton, onClickAsset, selectedAssetIndex } = props;

  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(selectedAssetIndex);
  const [enableSwipe, setEnableSwipe] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [wasZoomedIn, setWasZoomedIn] = useState(false);

  const thumbnailsCount = isMobile ? Math.min(3, assets.length) : Math.min(5, assets.length);

  useWindowResizeObserver();
  useWindowResizeSubscriber(size => {
    setScreenHeight(size.height || window.innerHeight);
  });

  const handleClickThumbnail = useCallback(
    (asset: CatalogProductMediaAsset, index: number) => {
      onClickAsset(asset);
      sliderRef?.slickGoTo(index);
    },
    [onClickAsset, sliderRef]
  );

  const sliderSettings = useMemo(() => {
    return {
      dots: true,
      arrows: false,
      swipe: enableSwipe,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: selectedAssetIndex,
      useTransform: false, // necessary for Safari. If set to true the overlapping text will be rendered too slow
      afterChange: (currentIndex: number) => {
        onClickAsset(assets[currentIndex]);
        setCurrentSlide(currentIndex);
      },
      appendDots: () => {
        return <GalleryThumbnails assets={assets} handleClickThumbnail={handleClickThumbnail} activeThumbnailIndex={currentSlide} isMobile={isMobile} enablePeek={true} />;
      }
    };
  }, [assets, currentSlide, enableSwipe, handleClickThumbnail, isMobile, onClickAsset, selectedAssetIndex]);

  return (
    <SkeletonGroup
      isReady={!showSkeleton && !!assets.length}
      display={'flex'}
      alignItems={'center'}
      height={{ _: screenHeight - 106, sm2: 'unset' }} // sticky CTA + 6px padding
      placeholder={<GalleryLightboxSkeleton thumbnailsCount={thumbnailsCount} screenHeight={screenHeight} />}
    >
      <StyledSliderContainer __css={styles.sliderContainer}>
        {!isMobile && assets.length > 1 && <PrevButton onClick={() => sliderRef?.slickPrev()} />}
        <Slider {...sliderSettings} ref={c => setSliderRef(c)}>
          {assets.map((item, idx) => {
            return (
              <SliderContent
                key={idx}
                item={item}
                isMobile={isMobile}
                isSelected={idx === currentSlide}
                setEnableSwipe={setEnableSwipe}
                wasZoomedIn={wasZoomedIn}
                setWasZoomedIn={setWasZoomedIn}
                screenHeight={screenHeight}
              />
            );
          })}
        </Slider>
        {!isMobile && assets.length > 1 && <NextButton onClick={() => sliderRef?.slickNext()} />}
      </StyledSliderContainer>
    </SkeletonGroup>
  );
};

const GalleryLightboxSkeleton = ({ thumbnailsCount, screenHeight }: { thumbnailsCount: number; screenHeight: number }) => {
  return (
    <Box {...styles.sliderContainer} backgroundColor="mono1">
      {/* sticky CTA + thumbnails + padding top + padding bottom */}
      <SkeletonThumbnail size={`calc(${screenHeight}px - ${GL_ASSET_CONTAINER_VERTICAL_MARGIN}px)`} margin={'auto'} />
      <Flex marginTop={4} justifyContent={'center'}>
        {Array.from(Array(thumbnailsCount).keys()).map((_, i) => (
          <SkeletonThumbnail key={i} size={['54px', null, '60px']} marginRight={'12px'} />
        ))}
      </Flex>
    </Box>
  );
};

export { GalleryLightbox };
