import { Box, styled, StyleSystemProps } from '@withjoy/joykit';
export const StyledWrapper = styled(Box)``;
export const StyledMainSection = styled(Box)``;

const wrapperNewMvpStyles: StyleSystemProps = {
  width: '100%',
  maxWidth: '100%',
  paddingX: {
    _: 5,
    sm2: 0
  }
};

const mainSectionStyles: StyleSystemProps = {
  flex: '1 auto',
  marginX: {
    _: 0,
    sm2: 8
  }
};

export const styles = {
  wrapperNewMvp: wrapperNewMvpStyles,
  mainSection: mainSectionStyles
};
