import React, { useMemo } from 'react';

import { RegistryOrderFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Box } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';

import { CartReviewStep, DetailsStep, ShippingStep, PaymentStep } from './steps';
import { ContentContainer, ContentFlex, ContentWrapper, ResizedLogo, StyledContainer, StyledScrollableFlex, styles } from './ShoppingCartInnerV2.styles';
import { useShoppingCart } from '../state';
import { CartCheckoutSteps, CartSections, InCartProduct, ProductListV2, ShippingAddressOptions } from '../ShoppingCart.types';
import { Breadcrumbs, CartFooterAtoms, SummaryItemListContainer } from '../components';
import { noop } from 'lodash-es';
import { DetailsFormFields } from './steps/DetailsStep/types';
import { useDetailsFormFields } from './steps/DetailsStep/hooks';
import MessageStep from './steps/MessageStep';
import { useFeatureValue } from '@shared/core/featureFlags';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { useShippingStepController } from './steps/ShippingStep/ShippingStep.controller';

type ShoppingCartInnerProps = {
  eventId: string;
  helpDialogOpen: boolean;
  isAddExternalOrderTrackingDialogOpen: boolean;
  productList: ProductListV2;
  reservedOrderList: RegistryOrderFragment[];
  purchasedOrderList: RegistryOrderFragment[];
  orderCount: number;
  cartOrderCount: number;
  cartTotal: string;
  inCartProductList: Array<InCartProduct>;
  inCartProductCount: number;
  inCartProductTotal: number;
  orderForTrackingRequest: Maybe<RegistryOrderFragment>;
  paths: ReturnType<typeof useRoutePaths>;
  showHelpDialog: () => void;
  handleDialogClose: () => void;
  closeAddExternalOrderTrackingDialog: () => void;
  registryCurrencyCode?: string;
};

const getIsDisabledNextStep = (
  inCartProductTotal: number,
  inCartProductCount: number,
  isFormValid: boolean,
  currentCheckoutStep: CartCheckoutSteps,
  dirty: boolean,
  addressOption: ShippingAddressOptions,
  isCustomShippingAddressValid: boolean,
  isCoupleAddressValid: boolean
) => {
  switch (currentCheckoutStep) {
    case CartCheckoutSteps.Payment:
      return inCartProductTotal === 0 || inCartProductCount === 0;
    case CartCheckoutSteps.Details:
      return inCartProductTotal === 0 || inCartProductCount === 0 || !isFormValid || !dirty;
    case CartCheckoutSteps.Shipping:
      return addressOption === ShippingAddressOptions.ShipToMe ? !isCustomShippingAddressValid : !isCoupleAddressValid;
    default:
      return false;
  }
};

export const ShoppingCartInnerV2: React.FC<ShoppingCartInnerProps> = ({
  eventId,
  helpDialogOpen,
  isAddExternalOrderTrackingDialogOpen,
  productList,
  inCartProductList,
  inCartProductCount,
  inCartProductTotal,
  reservedOrderList,
  purchasedOrderList,
  cartOrderCount,
  orderForTrackingRequest,
  paths,
  registryCurrencyCode,
  showHelpDialog,
  handleDialogClose,
  closeAddExternalOrderTrackingDialog
}) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { formatCurrency } = useCurrencyFormatter();
  const { value: gwRegCartEnabled } = useFeatureValue('registryGiftWrapCartEnabled');
  const registryGiftWrapCartEnabled = gwRegCartEnabled === 'on';

  const {
    state: { currentCheckoutStep: currentStep, details, currentSection, shippingFeeInUnits },
    mutators: { updateCurrentCheckoutStep, updateDetails }
  } = useShoppingCart();

  const { t } = useTranslation('guestRegistry');
  const { cart, shipping, details: detailsText, message, payment } = t('shoppingCart', 'steps');

  const subtotal = registryCurrencyCode
    ? formatCurrency({
        priceFloatingPointDecimalString: `${inCartProductTotal}`,
        priceCurrencyCode: DEFAULT_CURRENCY,
        formatForm: 'short'
      })
    : inCartProductTotal.toString();

  const total = registryCurrencyCode
    ? formatCurrency({
        priceFloatingPointDecimalString: `${inCartProductTotal + shippingFeeInUnits}`,
        priceCurrencyCode: DEFAULT_CURRENCY,
        formatForm: 'short'
      })
    : (inCartProductTotal + shippingFeeInUnits).toString();

  const handleStepClick = (step: CartCheckoutSteps) => {
    if (currentStep !== step) {
      updateCurrentCheckoutStep(step);
    }
  };

  const handleFormSubmit = (formValues: DetailsFormFields) => {
    updateDetails(formValues);
  };

  const { saveDetails, formik, ...formFields } = useDetailsFormFields(details, handleFormSubmit);

  const {
    isFormOpen,
    setIsFormOpen,
    formFields: shippingFormFields,
    formik: shippingFormik,
    coupleAddress,
    shippingFee,
    shippingOptionSelected,
    setShippingOptionSelected,
    setSuggestedAddress,
    setAddressUsed,
    updateShippingOption,
    shippingAddressFormValues,
    customShippingAddress,
    saveAddress,
    isCustomShippingAddressValid,
    isCoupleAddressValid
  } = useShippingStepController();

  const isDisabledNextStep = useMemo(
    () =>
      getIsDisabledNextStep(
        inCartProductTotal,
        inCartProductCount,
        formFields.isFormValid,
        currentStep,
        formFields.dirty,
        shippingOptionSelected,
        isCustomShippingAddressValid,
        isCoupleAddressValid
      ),
    [inCartProductTotal, inCartProductCount, formFields.isFormValid, currentStep, formFields.dirty, shippingOptionSelected, isCustomShippingAddressValid, isCoupleAddressValid]
  );
  const hasECard = useMemo(() => {
    const eCard = formik.values.eCard;
    return !!eCard;
  }, [formik]);

  const onContinueMessageStep = () => {
    updateCurrentCheckoutStep(CartCheckoutSteps.Payment);
  };

  return (
    <StyledContainer width={{ _: '100%', xs: currentStep === CartCheckoutSteps.Cart ? pxToRem(374) : '100%' }}>
      <StyledScrollableFlex>
        {currentStep === CartCheckoutSteps.Cart ? (
          <CartReviewStep
            eventId={eventId}
            cartOrderCount={cartOrderCount}
            inCartProductCount={inCartProductCount}
            inCartProductTotal={inCartProductTotal}
            inCartProductList={inCartProductList}
            helpDialogOpen={helpDialogOpen}
            showHelpDialog={showHelpDialog}
            registryCurrencyCode={registryCurrencyCode}
            isAddExternalOrderTrackingDialogOpen={isAddExternalOrderTrackingDialogOpen}
            productList={productList}
            purchasedOrderList={purchasedOrderList}
            reservedOrderList={reservedOrderList}
            paths={paths}
            closeAddExternalOrderTrackingDialog={closeAddExternalOrderTrackingDialog}
            orderForTrackingRequest={orderForTrackingRequest}
            handleDialogClose={handleDialogClose}
            shippingAddressSectionProps={{
              isFormOpen,
              setIsFormOpen,
              formFields: shippingFormFields,
              formik: shippingFormik,
              coupleAddress,
              shippingFee,
              shippingOptionSelected,
              setShippingOptionSelected,
              setSuggestedAddress,
              setAddressUsed,
              updateShippingOption,
              shippingAddressFormValues,
              customShippingAddress,
              saveAddress
            }}
          />
        ) : (
          <ContentFlex __css={styles.contentFlexStyles}>
            <ContentContainer __css={styles.contentContainerStyles}>
              <ContentWrapper __css={styles.contentWrapperStyles}>
                <Box>
                  <ResizedLogo />
                </Box>
                <Box marginBottom={7} marginTop={5}>
                  <Breadcrumbs
                    atoms={[
                      { label: cart(), isActive: false, onClick: () => updateCurrentCheckoutStep(CartCheckoutSteps.Cart) },
                      {
                        label: shipping(),
                        isActive: currentStep === CartCheckoutSteps.Shipping,
                        onClick: () => handleStepClick(CartCheckoutSteps.Shipping)
                      },
                      {
                        label: detailsText(),
                        isActive: currentStep === CartCheckoutSteps.Details,
                        onClick: () => handleStepClick(CartCheckoutSteps.Details)
                      },
                      ...(registryGiftWrapCartEnabled
                        ? [
                            {
                              label: message(),
                              isActive: currentStep === CartCheckoutSteps.Message,
                              onClick: () => handleStepClick(CartCheckoutSteps.Message)
                            }
                          ]
                        : []),
                      { label: payment(), isActive: currentStep === CartCheckoutSteps.Payment }
                    ]}
                  />
                </Box>
                {currentStep === CartCheckoutSteps.Shipping && (
                  <ShippingStep
                    isFormOpen={isFormOpen}
                    setIsFormOpen={setIsFormOpen}
                    formFields={shippingFormFields}
                    formik={shippingFormik}
                    coupleAddress={coupleAddress}
                    shippingFee={shippingFee}
                    shippingOptionSelected={shippingOptionSelected}
                    setShippingOptionSelected={setShippingOptionSelected}
                    setSuggestedAddress={setSuggestedAddress}
                    setAddressUsed={setAddressUsed}
                    updateShippingOption={updateShippingOption}
                    shippingAddressFormValues={shippingAddressFormValues}
                    customShippingAddress={customShippingAddress}
                    saveAddress={saveAddress}
                    disableNextStep={isDisabledNextStep}
                  />
                )}
                {currentStep === CartCheckoutSteps.Details && <DetailsStep saveData={saveDetails} formFields={formFields} disableNextStep={isDisabledNextStep} />}
                {currentStep === CartCheckoutSteps.Message && (
                  <MessageStep
                    eventId={eventId}
                    senderName={formik.values.name}
                    saveData={saveDetails}
                    setMessage={message => formik.setFieldValue('message', message)}
                    setECard={eCard => formik.setFieldValue('eCard', eCard)}
                    disableNextStep={isDisabledNextStep}
                    onContinue={onContinueMessageStep}
                    product={{
                      title: inCartProductList[0].product.title,
                      image: inCartProductList[0].product.image,
                      store: inCartProductList[0].product.storeName || inCartProductList[0].product.brand || undefined,
                      extraProductsCount: inCartProductList.length - 1
                    }}
                  />
                )}
                {currentStep === CartCheckoutSteps.Payment && <PaymentStep eventId={eventId} />}
              </ContentWrapper>
            </ContentContainer>
            {!isMobileOrTablet && [CartCheckoutSteps.Shipping, CartCheckoutSteps.Details, CartCheckoutSteps.Message].includes(currentStep) && (
              <SummaryItemListContainer
                inCartProductList={inCartProductList}
                inCartProductTotal={inCartProductTotal}
                hasECard={hasECard}
                registryCurrencyCode={registryCurrencyCode}
                setIsOpen={noop}
              />
            )}
          </ContentFlex>
        )}
      </StyledScrollableFlex>
      {(currentSection === CartSections.CART && currentStep === CartCheckoutSteps.Cart && (
        <CartFooterAtoms subtotal={subtotal} inCartProductList={inCartProductList} inCartProductTotal={inCartProductTotal} registryCurrencyCode={registryCurrencyCode} />
      )) ||
        (isMobileOrTablet &&
          currentSection !== CartSections.HISTORY &&
          currentSection !== CartSections.ADDRESS &&
          currentStep !== CartCheckoutSteps.Payment &&
          currentStep !== CartCheckoutSteps.Message && (
            <CartFooterAtoms
              total={total}
              inCartProductList={inCartProductList}
              inCartProductTotal={inCartProductTotal}
              registryCurrencyCode={registryCurrencyCode}
              saveData={saveDetails}
              hasECard={hasECard}
              disableNextStep={isDisabledNextStep}
            />
          ))}
    </StyledContainer>
  );
};
