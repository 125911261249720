import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Box, ButtonV2, Flex, FormControl, InputV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';
import { CheckCircleIcon } from '@assets/icons';

import { useCheckoutFunnel, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { ExternalCheckoutConfirmAndNotifySelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutConfirmAndNotify/ExternalCheckoutConfirmAndNotify.selectors';

import { LayoutShell } from '../../components';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';

export const ExternalCheckoutProvideTrackingDetails = () => {
  const { t } = useTranslation('guestRegistry');
  const translations = t('orderTrackingDialog');
  const trackingTranslations = t('trackingSection');
  const { orderTrackingDialogTitle, addLater, orderTrackingDialogDetail } = translations;
  const { addTrackingTooltip, orderNumberRequiredError, orderNumberLabel, trackingNumberLabel, saveCta } = trackingTranslations;

  const { siteName } = useGetProductData('provideTrackingDetails');

  const { submitOrderNumberForm, addTrackingLater } = useCheckoutFunnel(({ submitOrderNumberForm, addTrackingLater }) => [submitOrderNumberForm, addTrackingLater]);
  const { openToast } = useCheckoutDialogContext();

  const formik = useFormik<{ orderNumber: string; trackingNumber: string }>({
    initialValues: {
      orderNumber: '',
      trackingNumber: ''
    },
    validationSchema: Yup.object<{ orderNumber: string; trackingNumber: string }>({
      orderNumber: Yup.string().required(orderNumberRequiredError),
      trackingNumber: Yup.string()
    }),
    onSubmit: values => {
      submitOrderNumberForm(values);
      if (openToast) openToast({ notificationText: translations.successMessage(), isPositionTop: true });
    }
  });

  const handleAddTrackingLater = () => {
    addTrackingLater();
    if (openToast) openToast({ notificationText: translations.purchaseSuccess(), isPositionTop: true, noAnimation: false });
  };

  return (
    <LayoutShell {...ExternalCheckoutConfirmAndNotifySelectors.ContainerElement}>
      <LayoutShell.Body>
        <Flex justifyContent="center" width="100%" paddingTop={5}>
          <SpacingStack spacing={6} paddingX={2} maxWidth={pxToRem(480)}>
            <Flex justifyContent="center" marginTop={2}>
              <CheckCircleIcon />
            </Flex>
            <TextV2 typographyVariant="hed4" textAlign="center">
              {orderTrackingDialogTitle()}
            </TextV2>
            <Box paddingX={6}>
              <SpacingStack spacing={6}>
                <TextV2 typographyVariant="body1" paddingX={4} textAlign="center">
                  {addTrackingTooltip()}
                </TextV2>
                <form onSubmit={formik.handleSubmit}>
                  <SpacingStack spacing={4}>
                    <FormControl
                      error={
                        <TextV2 typographyVariant="label2" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('orderNumber').error}>
                          {formik.getFieldMeta('orderNumber').error}
                        </TextV2>
                      }
                      label={
                        <TextV2 typographyVariant="label3" as="label">
                          {orderNumberLabel()}
                        </TextV2>
                      }
                      isInvalid={!!formik.getFieldMeta('orderNumber').error}
                    >
                      <InputV2 {...formik.getFieldProps('orderNumber')} />
                    </FormControl>
                    <FormControl
                      label={
                        <TextV2 typographyVariant="label3" as="label">
                          {trackingNumberLabel()}
                        </TextV2>
                      }
                      isInvalid={!!formik.getFieldMeta('trackingNumber').error}
                      isDisabled={formik.getFieldMeta('orderNumber').value === ''}
                    >
                      <InputV2 {...formik.getFieldProps('trackingNumber')} />
                    </FormControl>
                    <ButtonV2 intent="productive" type="submit">
                      {saveCta()}
                    </ButtonV2>
                    <ButtonV2 intent="productive" variant="outline" onClick={handleAddTrackingLater}>
                      {addLater()}
                    </ButtonV2>
                  </SpacingStack>
                </form>
                <TextV2 typographyVariant="label2" textAlign="center">
                  {orderTrackingDialogDetail({ retailer: siteName })}
                </TextV2>
              </SpacingStack>
            </Box>
          </SpacingStack>
        </Flex>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
