import { Box, styled, StyleSystemProps, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const CartFooterAtomsWrapper = styled(Box)``;
export const CartFooterAtomsContainer = styled(Box)``;
export const ShippingAddressContainer = styled(Box)``;
export const ShippingAddressText = styled(TextV2)`
  font-size: ${pxToRem(15)};
`;
export const SubtotalTextContainer = styled(Box)`
  font-size: ${pxToRem(15)};
`;
export const TotalTextContainer = styled(Box)`
  font-size: ${pxToRem(15)};
`;

const cartFooterAtomsWrapperStyles = (isMobileOrTablet?: boolean): StyleSystemProps => ({
  paddingX: 5,
  paddingTop: 5,
  paddingBottom: 6,
  borderColor: 'mono3',
  zIndex: 10,
  backgroundColor: isMobileOrTablet ? 'white' : 'inherit'
});

const cartFooterAtomsContainerStyles = (isOpen?: boolean): StyleSystemProps => ({
  position: isOpen ? 'fixed' : 'initial',
  top: 0,
  left: 0,
  bottom: 0,
  height: isOpen ? '100%' : 'auto',
  display: isOpen ? 'flex' : 'block',
  flexDirection: 'column',
  width: '100%'
});

const shippingAddressContainerStyles: StyleSystemProps = {
  marginBottom: 6,
  paddingY: 6,
  paddingX: 6,
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'mono1',
  marginX: '-1rem',
  borderTop: '1px solid',
  borderColor: 'mono3'
};

const shippingAddressTextStyles: StyleSystemProps = {
  typographyVariant: 'body1'
};

const shippingAddressButtonStyles: StyleSystemProps = {
  ...shippingAddressTextStyles,
  textDecoration: 'underline',
  cursor: 'pointer'
};

const subtotalTextContainerStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'space-between'
};

const totalTextContainerStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'space-between'
};

export const styles = {
  cartFooterAtomsContainerStyles,
  cartFooterAtomsWrapperStyles,
  shippingAddressContainerStyles,
  shippingAddressTextStyles,
  shippingAddressButtonStyles,
  subtotalTextContainerStyles,
  totalTextContainerStyles
};
