import { styled, Flex, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledBox = styled(Flex)``;
const boxStyles: StyleSystemProps = {
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  flexBasis: 'auto',
  flexDirection: 'column',
  fontWeight: 'medium',
  height: '100%',
  rowGap: pxToRem(6)
};

export const styles = {
  box: boxStyles
};
