import React from 'react';
import { IconWrapper, StyledButtonContainer, StyledButtonWrapper, StyledCountContainer, styles } from './ShoppingCartOpenButton.styles';
import { IconV2, TextV2 } from '@withjoy/joykit';
import { CartIcon } from '@assets/icons';

type Props = {
  onClick: () => void;
  orderCount: number;
};

export const ShoppingCartOpenButton = ({ onClick, orderCount }: Props) => {
  return (
    <StyledButtonContainer onClick={onClick} __css={styles.buttonContainerStyles}>
      <StyledButtonWrapper __css={styles.buttonWrapperStyles}>
        <IconWrapper>
          <IconV2 size={20}>
            <CartIcon />
          </IconV2>
        </IconWrapper>
        <StyledCountContainer __css={styles.countContainerStyles}>
          <TextV2 typographyVariant="label3">{orderCount > 99 ? '99+' : orderCount}</TextV2>
        </StyledCountContainer>
      </StyledButtonWrapper>
    </StyledButtonContainer>
  );
};
