import { Box, StyleSystemProps, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledWarningBox = styled(Box)``;
export const StyledWhiteBox = styled(Box)``;

const warningBoxStyle: StyleSystemProps = {
  width: '100%',
  padding: 6,
  marginBottom: 2,
  borderRadius: `${pxToRem(8)}`,
  backgroundColor: 'negative1',
  border: `${pxToRem(1)} solid`,
  borderColor: 'negative2'
};

const whiteBoxStyle: StyleSystemProps = {
  width: '100%',
  padding: 6,
  marginBottom: 2,
  borderRadius: `${pxToRem(8)}`,
  backgroundColor: 'white',
  border: `${pxToRem(1)} solid`,
  borderColor: 'mono3'
};
export const styles = {
  warningBox: warningBoxStyle,
  whiteBox: whiteBoxStyle
};
