import { Box, StyleSystemProps, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledPaymentErrorScreenBox = styled(Box)``;
const paymentErrorScreenBoxStyles: StyleSystemProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: pxToRem(400)
};

export const StyledPaymentErrorScreenWrapper = styled(Box)``;
const paymentErrorScreenWrapperStyles: StyleSystemProps = {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  paddingX: pxToRem(36)
};

export const styles = { paymentErrorScreenBox: paymentErrorScreenBoxStyles, paymentErrorScreenWrapper: paymentErrorScreenWrapperStyles };
