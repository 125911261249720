import { Alert, ContentHeader, CtaGroup, PrimaryCta, SecondaryCta } from '@apps/registry/guest/components/CheckoutDialog/components';
import {
  StyledListContainer,
  StyledListItemContainer,
  StyledListItemNumber,
  styles
} from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutInstructions/ExternalCheckoutInstructions.styles';
import { TextV2 } from '@withjoy/joykit';
import { ExternalCheckoutInstructionsSelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutInstructions/ExternalCheckoutInstructions.selectors';
import React from 'react';
import { TranslateFunction } from '@locales/generated';
import { CheckIcon } from '@assets/icons';
import { DonationFundPaymentMethodEnum } from '@graphql/generated';
import { getPaymentMethodTypeName } from '@shared/utils/paymentMethodOptions';
import CopyToClipboard from 'react-copy-to-clipboard';

type Props = {
  translations: {
    title: TranslateFunction;
    alertTitle?: TranslateFunction;
    alertText: TranslateFunction;
    gotItButton: TranslateFunction;
    copyButton: TranslateFunction;
    step1?: string;
    step2?: string;
    step3?: string;
  };
  toNext: () => void;
  isGiftWrapPurchased?: boolean;
  isLoading: boolean;
  paymentMethod?: DonationFundPaymentMethodEnum;
  account?: string;
};

export const ExternalCheckoutInstructionsContent = ({ translations, isLoading, toNext, isGiftWrapPurchased, paymentMethod, account }: Props) => {
  const { title, alertTitle, alertText, step1, step2, step3, copyButton, gotItButton } = translations;

  return (
    <>
      <ContentHeader>
        <ContentHeader.Hed>{title()}</ContentHeader.Hed>
      </ContentHeader>

      <StyledListContainer spacing={5} __css={styles.listContainer}>
        {[step1, step2, step3].map((step, index) => {
          const isCompleted = isGiftWrapPurchased && index === 0;

          return (
            <StyledListItemContainer __css={styles.listItemContainer} key={index}>
              <StyledListItemNumber __css={{ ...styles.listItemNumber, ...(isCompleted ? styles.listItemNumberCompleted : {}) }}>
                {isCompleted ? <CheckIcon /> : index + 1}
              </StyledListItemNumber>
              <TextV2
                typographyVariant="body1"
                textDecoration={isCompleted ? 'line-through' : undefined}
                dangerouslySetInnerHTML={{
                  __html: step || ''
                }}
              />
            </StyledListItemContainer>
          );
        })}
      </StyledListContainer>

      <Alert title={alertTitle?.()} description={alertText()} />

      <CtaGroup marginTop={7}>
        {!!paymentMethod && !!account && (
          <CopyToClipboard text={account}>
            <SecondaryCta>{copyButton({ account: getPaymentMethodTypeName(paymentMethod) })}</SecondaryCta>
          </CopyToClipboard>
        )}
        <PrimaryCta {...ExternalCheckoutInstructionsSelectors.GotItButton} onClick={toNext} loading={isLoading}>
          {gotItButton()}
        </PrimaryCta>
      </CtaGroup>
    </>
  );
};
