import React from 'react';
import { SpacingStack } from '@withjoy/joykit';
import { LayoutShell } from '../../components/LayoutShell';
import { ContentHeader, IntegratedGiftCard, PrimaryCta, ShippingAddressReference } from '@apps/registry/guest/components/CheckoutDialog/components';
import { usePaperCheckoutInstructionsController } from './PaperCheckoutInstructions.controller';
import { PaperCheckoutInstructionsSelectors } from './PaperCheckoutInstructions.selectors';

export const PaperCheckoutInstructions = () => {
  const { toNext, translations, isCashFundType, shippingAddress } = usePaperCheckoutInstructionsController();
  const { title, cta } = translations;

  return (
    <LayoutShell {...PaperCheckoutInstructionsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="float" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          <IntegratedGiftCard mobileRenderBehavior={isCashFundType ? 'fullBleed' : 'card'} />
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <ContentHeader>
            <ContentHeader.Hed>{title()}</ContentHeader.Hed>
          </ContentHeader>
          <SpacingStack spacing={7} marginTop={7}>
            <ShippingAddressReference shippingAddress={shippingAddress} />
            <PrimaryCta {...PaperCheckoutInstructionsSelectors.GotItButton} onClick={toNext}>
              {cta()}
            </PrimaryCta>
          </SpacingStack>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
