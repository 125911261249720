import { styled, css, TextV2, Box, ButtonV2, LinkV2, StyleSystemProps } from '@withjoy/joykit';
import { responsiveBreakpoints } from '@apps/registry/common/utils/responsiveHelpers';
import { Linkify } from '@shared/components';
import { animationTransition } from '@shared/utils/animationTransition';
import { keyframes } from '@withjoy/joykit';

export const StyledLink = styled(LinkV2)<{ primaryTextColor?: string }>`
  :hover {
    color: ${props => props.theme.colors.typeTertiaryDark};
  }
`;

export const DateText = styled(TextV2)``;

export const EditPhotoBox = styled.div`
  text-align: center;
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_960}) {
    text-align: left;
  }
`;

export const EditNoteBox = styled(Box)`
  text-align: center;
  position: relative;
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_960}) {
    text-align: left;
  }
`;

export const EditButton = styled(ButtonV2)<{ isMobileOrTablet: boolean }>`
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  transition: ${animationTransition('background')};

  :hover:enabled {
    background: black;
  }
`;

// always show on mobile/tablet because there is no hover
export const EditNoteButton = styled(EditButton)<{ emptyNote: boolean; isMobileOrTablet: boolean }>`
  display: 'block';
  ${props =>
    props.emptyNote && !props.isMobileOrTablet
      ? `
        top: 15px;
        left: 50%;
        transform: translate(-50%, -50%);
  `
      : undefined}
`;

export const EditPhotoButton = styled(EditButton)<{ isMobileOrTablet: boolean }>`
  display: ${props => (props.isMobileOrTablet ? 'block' : 'none')};
`;

export const StyledLinkify = styled(Linkify)<{ primaryTextColor?: string }>`
  a {
    text-decoration: underline;
    color: ${({ theme, primaryTextColor }) => (primaryTextColor ? primaryTextColor : theme.colors.mono14)};

    :hover {
      color: ${props => props.theme.colors.typeTertiaryDark};
    }
  }
`;

export const CoupleWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
`;

const fullWidthCss = css`
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_640}) {
    flex: unset;
    max-width: 575px;
    margin: 0 auto;
  }
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_769}) {
    max-width: unset;
    flex: 0 0 632px;
  }
`;
export const NoteAndPhotoRow = styled(Box)<{ emptyNote?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 2rem;
  flex-direction: column-reverse;
  row-gap: 1rem;

  @media screen and (min-width: ${responsiveBreakpoints.SIZE_769}) {
    flex-direction: row;
    justify-content: center;
    padding: 0 2.5rem;
  }

  ${props =>
    props.emptyNote
      ? `
  align-items: center;
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_769}) {
    flex-direction: column;
  }`
      : undefined}
`;

const photoTextCss = css`
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_640}) {
    flex: unset;
    max-width: 36rem;
  }

  @media screen and (min-width: ${responsiveBreakpoints.SIZE_769}) {
    flex: 0 0 20.5rem;
    margin: unset;
    margin-right: 4rem;
  }
`;

export const StyledTextBox = styled(Box)<{ fullWidth?: boolean; emptyNote?: boolean }>`
  flex: 1 0 100%;
  max-width: 100%;

  ${props => {
    if (props.emptyNote) {
      return undefined;
    } else if (props.fullWidth) {
      return fullWidthCss;
    } else {
      return photoTextCss;
    }
  }}
  :hover {
    ${EditNoteButton} {
      display: block;
    }

    ${EditPhotoButton} {
      display: block;
    }
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  background-color: ${props => props.theme.colors.mono5};
  border-radius: ${props => props.theme.radii[2]};
`;

export const EmptyImage = styled.div`
  width: 410px;
  height: 273px;
  background-color: ${props => props.theme.colors.mono2};
  border-radius: ${props => props.theme.radii[2]};
`;

export const StyledPhotoBox = styled(Box)<{ hide?: boolean }>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  margin-bottom: ${props => props.theme.space[7]};

  @media screen and (min-width: ${responsiveBreakpoints.SIZE_640}) {
    max-width: 36rem;
  }

  @media screen and (min-width: ${responsiveBreakpoints.SIZE_769}) {
    width: 25.6rem;
    margin-bottom: 0;
  }

  :hover {
    ${EditPhotoButton} {
      display: block;
    }
  }
`;

const open = keyframes`
  from {
    line-clamp: 11;
    -webkit-line-clamp: 11;
  }
  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
`;

const close = keyframes`
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
  to {
    line-clamp: 11;
    -webkit-line-clamp: 11;
  }
`;

export const NoteTextWrapper = styled.div<{ isOpen: boolean; maxHeight?: number }>`
  max-height: 297px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  ${props =>
    props.isOpen
      ? `  max-height: 100rem;
  transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);`
      : undefined}
`;

const animationOpen = css`
  animation: ${open} 0.1s linear 0s forwards;
`;

const animationClose = css`
  animation: ${close} 0.1s linear 0.1s forwards;
`;

export const NoteText = styled(TextV2)<{ isOpen: boolean }>`
  text-align: left;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ isOpen }) => (isOpen ? animationOpen : animationClose)};
`;

export const DashboardButtonWrapper = styled(Box)``;

const dashboardButtonWrapperStylesAloha: StyleSystemProps = {
  margin: [8, 0],
  position: ['unset', 'absolute'],
  top: ['unset', '72px'],
  right: ['unset', 9],
  width: ['100%', 'unset'],
  paddingX: 6
};

const dashboardButtonWrapperStylesBrannan: StyleSystemProps = {
  ...dashboardButtonWrapperStylesAloha,
  top: ['unset', 6],
  right: ['unset', 1]
};

export const StyledRoot = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.mediaQueries.between(0, { breakpointAlias: 'sm4' })]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    };
  }}
`;

export const StyledTitle = styled(Box)``;

const titleStyles: StyleSystemProps = {
  maxWidth: ['90%', null, null, 720],
  marginX: [null, 'auto'],
  marginY: [70, 70, 'unset'],
  textAlign: 'center',
  position: 'relative'
};

export const styles = {
  dashboardButtonWrapperAloha: dashboardButtonWrapperStylesAloha,
  dashboardButtonWrapperBrannan: dashboardButtonWrapperStylesBrannan,
  title: titleStyles
};
