import { styled, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledEventPhoto = styled.img`
  min-width: ${pxToRem(80)};
  min-height: ${pxToRem(80)};
  max-width: ${pxToRem(80)};
  max-height: ${pxToRem(80)};
  border-radius: 50%;
  margin-right: ${pxToRem(16)};
`;

export const StyledNote = styled(TextV2)`
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
`;
