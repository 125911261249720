import { Box, styled, Flex, css } from '@withjoy/joykit';
import { createResponsiveCSS, responsiveBreakpoints } from '@apps/registry/common/utils/responsiveHelpers';

export const StyledFlex = styled(Flex)`
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  > *:not(:nth-child(2n)) {
    margin-right: 25px;
  }
  > *:last-child {
    margin-right: 0 !important;
  }

  > *:only-child {
    margin-right: 0 !important;
  }
`;
const logoBoxCss = {
  [responsiveBreakpoints.SIZE_640]: css`
    width: 98px;
    height: 98px;
    padding: 12px;
  `,
  [responsiveBreakpoints.SIZE_768]: css`
    width: 112px;
    height: 112px;
  `,
  [responsiveBreakpoints.SIZE_960]: css`
    width: 125px;
    height: 118px;
  `,
  [responsiveBreakpoints.SIZE_1024]: css`
    width: 133px;
    height: 120px;
  `,
  [responsiveBreakpoints.SIZE_1152]: css`
    width: 140px;
    height: 120px;
  `,
  [responsiveBreakpoints.SIZE_1280]: css`
    width: 155px;
    height: 120px;
  `,
  [responsiveBreakpoints.SIZE_1680]: css`
    width: 170px;
    height: 120px;
  `,
  [responsiveBreakpoints.SIZE_1920]: css`
    width: 220px;
    height: 120px;
  `
};

export const StyledLogoBox = styled(Box)<{ backgroundImage?: string }>`
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  word-wrap: anywhere;
  margin-bottom: 24px;
  padding: 16px;
  ${createResponsiveCSS(logoBoxCss)}
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    filter: grayscale(1);
  }
  cursor: pointer;
  :hover {
    border-color: ${props => props.theme.colors.stroke};
  }
  > div > div > p {
    word-break: break-word;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    @media screen and (max-width: 769px) {
      line-height: 21px;
      letter-spacing: 0.1px;
    }
  }
`;

export const StyledBox = styled(Box)`
  margin-bottom: 16px;
  text-align: center;
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_960}) {
    margin-bottom: 16px;
  }
`;
