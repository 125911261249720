import React from 'react';
import { Box, Flex, TextV2, TooltipV2 } from '@withjoy/joykit';
import { ReactComponent as Help } from '@assets/icons/help.svg';

type MsrpTooltipProps = {
  msrpTitle: string;
  msrpDescription: string;
};

export const MsrpTooltip: React.FC<MsrpTooltipProps> = ({ msrpDescription, msrpTitle }) => {
  return (
    <TooltipV2
      overrides={{
        Content: { props: { backgroundColor: 'mono2' } },
        Arrow: { props: { backgroundColor: 'mono2' } }
      }}
      content={
        <Box width="280px" color="mono14">
          <TextV2 typographyVariant="label3">{msrpTitle}</TextV2>
          <br />
          <TextV2 typographyVariant="label2">{msrpDescription}</TextV2>
        </Box>
      }
      placement="topStart"
    >
      <Flex alignItems="center">
        <Help margin="auto" />
      </Flex>
    </TooltipV2>
  );
};
