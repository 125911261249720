import { useEffect, useCallback } from 'react';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import {
  useCreateDonationFundDonationWithResultMutation,
  useUpdateDonationFundDonationMutation,
  useGetDonationFundDonationByIdLazyQuery,
  DonationFundDonationFragment,
  DonationFundPaymentMethodEnum
} from '@graphql/generated';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useGuestRegistryTelemetry } from '../../../../GuestRegistry.telemetry';
import { useGiveGiftDialogInteractive } from './GiveGiftDialog.interactive';
import { getDonationFundFund, getSuggestedDonation } from './util';
import { useStoredGiftReservationFieldsContext } from '../StoredGiftReservationFieldsProvider';

interface GiveGiftDialogControllerProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitted: (guest: GiveGiftDialogFields, orderId: string, orderExists?: boolean) => void;
  product?: CookedProduct;
  eventId?: string;
  existingOrderId: Maybe<string>;
  mySessionEmail: Maybe<string>;
}

export interface GiveGiftDialogFields {
  email: string;
  name: string;
  amount?: number | '';
  note?: string;
  platformType?: keyof typeof DonationFundPaymentMethodEnum | '';
}

const defaultDataFetchingConfig = {
  refetchQueries: ['GetEventRegistriesAndOrders']
};

type GiveGiftDialogDataDecouplerInput = {
  onClose: () => void;
  existingOrderId: Maybe<string>;
  providedGuestEmail: Maybe<string>;
  providedGuestName: Maybe<string>;
} & Pick<GiveGiftDialogControllerProps, 'onSubmitted' | 'eventId' | 'product'>;

const useGiveGiftDialogDataAdapter = ({ product, onClose, onSubmitted, eventId, existingOrderId }: GiveGiftDialogDataDecouplerInput) => {
  const { reserveAndPurchase: reserveAndPurchaseTrack, reservedRegistryItem } = useGuestRegistryTelemetry();

  const donationFund = getDonationFundFund(product);

  const [createDonationResult] = useCreateDonationFundDonationWithResultMutation(defaultDataFetchingConfig);

  const [updateDonation] = useUpdateDonationFundDonationMutation(defaultDataFetchingConfig);

  const [getRegistryOrderById, { data: existingDonationFundDonationResponse }] = useGetDonationFundDonationByIdLazyQuery();

  const existingDonation = existingDonationFundDonationResponse?.getDonationFundDonationById as DonationFundDonationFragment;

  useEffect(() => {
    if (!existingOrderId) return;

    getRegistryOrderById({ variables: { registryOrderId: existingOrderId } });
  }, [existingOrderId, getRegistryOrderById]);

  const onCreateDonation = useCallback(
    async (values: GiveGiftDialogFields) => {
      reserveAndPurchaseTrack({
        eventId,
        productId: product?.id,
        registryItemId: product?.registryItemId,
        registryId: product?.registry.id,
        reservedQty: 1,
        priceValueInMinorUnits: values.amount ? values.amount * 100 : 0,
        priceCurrencyCode: donationFund?.goalMonetaryValue?.currency?.code || undefined,
        destinationUrl: product?.externalUrl || product?.registry.url,
        typeOfItem: product?.registryItemType,
        buttonLabel: 'Reserve and Send On',
        productTitle: product?.title || 'cash fund'
      });

      const result = await createDonationResult({
        variables: {
          payload: {
            fundId: donationFund!.id,
            amountMonetaryMinorValue: values.amount ? values.amount * 100 : 0,
            name: values.name,
            email: values.email,
            note: values.note,
            platformType: values.platformType ? DonationFundPaymentMethodEnum[values.platformType] : null
          }
        }
      });

      // Sometimes `registryOrder` is null when `alreadyExists` is true? ask BE.
      const orderId = result.data?.createDonationFundDonationWithResult.registryOrder?.id || '';
      const orderExists = result.data?.createDonationFundDonationWithResult.alreadyExists;
      onSubmitted(values, orderId, orderExists);

      if (orderExists) {
        reservedRegistryItem({
          productTitle: product?.title || 'cash fund',
          registryItemId: product?.registryItemId || '',
          registryId: product?.registry.id,
          isEmailMatched: true
        });
      } else {
        onClose();
      }
    },
    [createDonationResult, donationFund, eventId, onClose, onSubmitted, product, reserveAndPurchaseTrack, reservedRegistryItem]
  );

  const onUpdateDonation = useCallback(
    async (orderId: string, values: GiveGiftDialogFields) => {
      const { amount, note, platformType } = values;
      if (!amount) return;

      await updateDonation({
        variables: {
          payload: {
            amountMonetaryMinorValue: Math.floor(amount * 100),
            note,
            platformType: platformType as DonationFundPaymentMethodEnum,
            orderId: orderId
          }
        }
      });
      await getRegistryOrderById({ variables: { registryOrderId: orderId } });

      onSubmitted(values, orderId);

      onClose();
    },
    [onClose, onSubmitted, getRegistryOrderById, updateDonation]
  );

  const onSave = useCallback(
    (values: GiveGiftDialogFields) => {
      if (!existingOrderId) {
        onCreateDonation(values);
      } else {
        onUpdateDonation(existingOrderId, values);
      }
    },
    [existingOrderId, onCreateDonation, onUpdateDonation]
  );

  return {
    onSave,
    existingDonation
  } as const;
};

export const useGiveGiftDialogController = ({
  onSubmitted,
  product,
  eventId,
  isOpen,
  onClose,

  existingOrderId,
  mySessionEmail
}: GiveGiftDialogControllerProps) => {
  const { getGiveGiftDialogTranslations } = useRegistryGuestTranslations();
  const translations = getGiveGiftDialogTranslations();

  const { handleOnChangeEmailDialogOpen, providedGuestEmail, providedGuestName } = useStoredGiftReservationFieldsContext();

  const donationFundFund = getDonationFundFund(product);
  const suggestedDonation = getSuggestedDonation(donationFundFund);
  const { authenticatedSession } = useGuestRegistryTelemetry();

  const { onSave, existingDonation } = useGiveGiftDialogDataAdapter({
    product,
    onClose,
    onSubmitted,
    eventId,
    existingOrderId,
    providedGuestEmail,
    providedGuestName
  });
  const showCheckMailDialog = (emailId: string) => {
    authenticatedSession(emailId, 'AuthenticatedSessionChangePrompt');
    handleOnChangeEmailDialogOpen();
  };

  const {
    formik,
    formValues,
    handlePurchased,
    stillNeededOptions,
    preventNonNumericalInput,
    setAmount,
    setDonationType,
    availablePaymentMethods,
    currencySymbol,
    paymentMethodFormattedName,
    isDonationPlatformTypeOther,
    onDialogClose,
    currentlySelectedPaymentMethod
  } = useGiveGiftDialogInteractive({
    product,
    onSave,
    translations,
    existingDonation,
    onClose,
    shouldRequireEmail: !mySessionEmail,
    providedGuestEmail,
    providedGuestName
  });

  return {
    formik,
    formValues,
    handlePurchased,
    setAmount,
    isDonationPlatformTypeOther,
    paymentMethodFormattedName,
    suggestedDonation,
    stillNeededOptions,
    preventNonNumericalInput,
    availablePaymentMethods,
    currentlySelectedPaymentMethod,
    setDonationType,
    currencySymbol,
    onDialogClose,
    showCheckMailDialog,
    providedGuestEmail
  } as const;
};
