import { animationTransition } from '@shared/utils/animationTransition';
import { styled, Box, Flex, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledThumbnailContainer = styled(Box)``;
export const StyledCarouselContainer = styled(Box)``;
export const StyledThumbnailWrapper = styled(Flex)``;
export const StyledThumbnailIcon = styled(Box)``;
export const StyledSliderContainer = styled(Box)`
  .slick-track {
    margin: 0 auto;
    width: max-content !important;
    height: unset;
  }
  .slick-list {
    padding-bottom: 10px;
  }
  .slick-slider {
    height: unset;
  }
  .slick-next {
    transform: translate(0, -50%) rotate(180deg);
    right: -15px;
  }
  .slick-prev {
    left: -15px;
  }
  .slick-next,
  .slick-prev {
    color: #330066;
    height: 12px;
    width: 12px;
  }
  .slick-next:hover,
  .slick-prev:hover {
    color: #767676;
  }
  .slick-next:active,
  .slick-prev:active {
    color: #000;
  }
  .slick-next:before,
  .slick-prev:before {
    color: #000;
  }
`;
const productThumbnailStyles: StyleSystemProps = {
  display: 'flex',
  size: '100%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'transparent',
  borderRadius: pxToRem(4)
};

const thumbnailWrapperStyles = (enablePeek?: boolean, isActive?: boolean): StyleSystemProps => {
  return {
    size: ['54px !important', null, '60px !important'],
    position: 'relative',
    cursor: 'pointer',
    transition: animationTransition('background-color'),
    marginRight: 4,
    marginLeft: enablePeek ? 6 : 0,
    _hover: {
      _after: {
        backgroundColor: isActive ? 'mono5' : { _: 'transparent', sm: 'mono3' }
      }
    },
    _after: {
      width: '100%',
      height: pxToRem(2),
      content: '" "',
      position: 'absolute',
      bottom: '-10px',
      left: 0,
      borderRadius: 1,
      backgroundColor: isActive ? 'mono5' : 'transparent',
      transition: animationTransition('background-color')
    }
  };
};

const thumbnailIconStyles: StyleSystemProps = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 0,
  right: 0,
  margin: '0 auto',
  zIndex: 1,
  width: { _: '16px', sm: '24px' },
  height: { _: '16px', sm: '24px' },
  backgroundColor: 'mono2',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0.8,
  cursor: 'pointer'
};

const sliderContainerStyles: StyleSystemProps = {
  width: '100%',
  margin: '0 auto',
  position: 'sticky',
  marginTop: 7
};

export const styles = {
  productThumbnail: productThumbnailStyles,
  thumbnailWrapper: thumbnailWrapperStyles,
  thumbnailIcon: thumbnailIconStyles,
  sliderContainer: sliderContainerStyles
};
