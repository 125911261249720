import * as Yup from 'yup';
import { useState, useContext, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { AnalyticsContext } from '@shared/core';
import { useGuestRegistryTelemetry, ReserveJoyRegistryProps } from '@apps/registry/guest/GuestRegistry.telemetry';
import { withWindow } from '@shared/utils/withWindow';
import { CookedProduct, isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { ECard, ShippingAddressInput, useCreateRegistryPurchaseContextMutation, useCreateSingleRegistryItemOrderMutation, useUpdateRegistryItemMutation } from '@graphql/generated';
import { useManagedGiftReservation } from '../../hooks/useManagedGiftReservation';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { usePurchaseConfirmationProvider } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { addItemToPurchaseClickHistory } from '../ShoppingCart/utils/trackPurchaseClicks';
import { useFeatureValue } from '@shared/core/featureFlags';
import { addRendition } from '@shared/utils/photoRendition';
import { usePurchaseDialogTranslations } from './hooks';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { getGiftWrapThemeListByEventType } from '@shared/components';
import { useEventInfo } from '@shared/utils/eventInfo';

export enum FormStep {
  Product = 'product',
  Guest = 'guest',
  GreetingCard = 'greetingCard',
  MissingAddress = 'missingAddress',
  AddOtherAddress = 'addOtherAddress'
}
type SubmitType = '' | 'reserve' | 'markAsPurchased';

export interface PurchaseFirstStepFields {
  quantity: number;
}

export interface PurchaseDialogFields {
  email: string;
  name: string;
  quantity: number;
  note?: string;
  eCard?: ECard;
}

export interface CheckoutMechanisms {
  isDropshipable: boolean;
  isOutOfStock: boolean;
  checkoutMechanismsWithAltAddress: (altAddress: ShippingAddressInput) => Promise<boolean>;
}
interface PurchaseDialogControllerProps extends CheckoutMechanisms {
  closeDialog: () => void;
  product: CookedProduct;
  itemId: string;
  eventId?: string;
  eventInfoState?: string;
  openToast: (toast: ToastVariableProps) => void;
  shouldRequireEmail: boolean;
  shouldRequireName: boolean;
  shippingAddress?: ShippingAddressFragment | null;
  formStep: FormStep;
  setFormStep: (formStep: FormStep) => void;
}

// TODO: once dropship dialog is released (enableSplitPurchaseDialog is removed) this can be split into an AffiliateDialogController and a DropshipDialogController
// the dialogs are beginning to diverge - I don't think this logic needs to be coupled
export const usePurchaseDialogController = ({
  closeDialog,
  product,
  itemId,
  eventId,
  eventInfoState,
  openToast,
  shouldRequireEmail,
  shouldRequireName,
  shippingAddress,
  isDropshipable,
  checkoutMechanismsWithAltAddress,
  formStep,
  setFormStep
}: PurchaseDialogControllerProps) => {
  const { updateDataProvider } = useGuestRegistryState();
  const { eventInfo } = useEventInfo();
  const enableHaveYouPurchasedModalExternalLinkClick = useFeatureValue('enableHaveYouPurchasedModalExternalLinkClick');
  const { value: giftWrapOn } = useFeatureValue('registryGiftWrapExperimentEnabled');
  const registryGiftWrapExperimentEnabled = giftWrapOn === 'on';
  const { value: gwRegDSEnabled } = useFeatureValue('registryGiftWrapDropshipEnabled');
  const registryGiftWrapDropshipEnabled = gwRegDSEnabled === 'on';
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const eventTypeHasThemes = themes && themes.length > 0;
  // Verify the registry item is dropshipable, gift wrap experiment is enabled, and gift wrap for dropship is enabled
  const isExperimentNewFlowOn = isDropshipable && registryGiftWrapExperimentEnabled && eventTypeHasThemes && registryGiftWrapDropshipEnabled;
  const isNotAnExperimentStep = !(FormStep.GreetingCard === formStep);

  const {
    requiredNameWarning,
    requiredEmailWarning,
    validEmailWarning,
    formButtonReservePurchase,
    formButtonAlreadyPurchase,
    itemAddedSuccess,
    requiredLabel
  } = usePurchaseDialogTranslations();

  const [createRegistryOrder, { loading: createOrderLoading }] = useCreateSingleRegistryItemOrderMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });

  const [createPurchaseContext, { loading: preparingForDropshipPurchaseLoading }] = useCreateRegistryPurchaseContextMutation();
  const [updateRegistryItem, { loading: updateRegistryItemLoading }] = useUpdateRegistryItemMutation();

  const analytics = useContext(AnalyticsContext);
  const { viewItemDetailsClick, reserveAndPurchase, shoppingCartMakePurchaseClick, authenticatedSession, registryItemBuyNowClicked } = useGuestRegistryTelemetry();
  const { openModal } = usePurchaseConfirmationProvider();
  const [submitType, setSubmitType] = useState<SubmitType>('');
  const [isPurchasable, setIsPurchasable] = useState<boolean>(true);

  const [isPurchaseError, setIsPurchaseError] = useState<boolean>(false);
  const updatedOnceOnEmailChangeRef = useRef(false);
  const { handleOnChangeEmailDialogOpen, handleOnGuestDetailsSave, providedGuestEmail, providedGuestName, setRecentGiftStatus, recentGiftStatus } = useManagedGiftReservation({
    product
  });

  const productImageSrc = addRendition({ url: product?.image, renditionSize: 'medium', isProduct: true });

  const hasProductAffiliateURL: boolean = product?.checkoutUrl !== '';
  const showDetailsLink: boolean = Boolean(product?.checkoutUrl && !isDropshipable);

  const routes = useRoutePaths();
  const failureUrlTemplate = withWindow(global => global.location.origin + routes.guestRegistry.path, '');
  const successUrlTemplate = withWindow(global => global.location.origin + routes.purchase.path, '');

  const goBackToRegistry = () => {
    formik.resetForm();
    closeDialog();
  };

  const changeFormStep = (formStep: FormStep) => {
    if (!formik.isSubmitting) {
      setFormStep(formStep);
    }
  };

  const changeSubmitType = (type: SubmitType) => {
    if (!formik.isSubmitting) {
      setSubmitType(type);
    }
  };

  const handleViewItemDetails = () => {
    if (product) {
      if (enableHaveYouPurchasedModalExternalLinkClick) {
        addItemToPurchaseClickHistory({ reservedOrderId: null, productId: product.id });
      }
      withWindow(window => {
        window.open(product.checkoutUrl, '__blank');
      });

      viewItemDetailsClick({
        productTitle: product?.title || '',
        registryItemId: product?.registryItemId || '',
        registryId: product?.registry.id
      });
    }
  };

  const createContextToPurchase = async (values: PurchaseDialogFields, otherAddress?: ShippingAddressInput) => {
    return createPurchaseContext({
      variables: {
        payload: {
          eventId: eventId!,
          name: values.name,
          email: values.email,
          note: values.note,
          lineItems: [
            {
              registryItemId: itemId,
              quantity: values.quantity
            }
          ],
          failureUrlTemplate,
          successUrlTemplate: `${successUrlTemplate}?pcId=\${purchaseContextId}`,
          address: otherAddress,
          eCard: values.eCard ?? undefined
        }
      }
    });
  };

  const createRegistryOrderId = async (values: PurchaseDialogFields) => {
    const record = await createRegistryOrder({
      variables: {
        payload: {
          registryItemId: itemId,
          name: values.name,
          email: values.email,
          quantity: values.quantity,
          note: values.note
        }
      }
    });
    const orderId = record.data?.createSingleRegistryItemOrder.model?.id || '';
    const orderExists = !!record.data?.createSingleRegistryItemOrder.alreadyExists;

    return { orderId, orderExists };
  };

  const fireGuestRedirection = (checkoutUrl: Maybe<string>) => {
    if (checkoutUrl) {
      withWindow(global => (global.location.href = checkoutUrl!));
    }
  };

  const fireTelemetryReserveJoyRegistryItemEvent = () => {
    const telemArgs: ReserveJoyRegistryProps = {
      eventId: eventInfoState,
      productId: product?.id,
      registryItemId: product?.registryItemId,
      registryId: product?.registry.id,
      reservedQty: formik.values.quantity,
      priceValueInMinorUnits: isPricedProduct(product) ? product.valueInMinorUnits : undefined,
      priceCurrencyCode: isPricedProduct(product) ? product.currencyCode : undefined,
      destinationUrl: product?.checkoutUrl || product?.registry.url,
      typeOfItem: product?.registryItemType,
      buttonLabel: submitType === 'reserve' ? formButtonReservePurchase() : formButtonAlreadyPurchase(),
      productTitle: product?.title,
      checkoutMechanism: isDropshipable ? 'joyWeb' : 'externalNavigation',
      giftGiverName: formik.values.name,
      giftGiverEmail: formik.values.email,
      generatedLabel: product.generatedLabel,
      isGroupGiftingEnabled: !!product.isGroupGiftingEnabled
    };
    submitType === 'markAsPurchased' ? shoppingCartMakePurchaseClick(telemArgs) : reserveAndPurchase(telemArgs);
  };

  const onSubmitted = (values: PurchaseDialogFields, submitType: SubmitType, orderId: string, orderExists: boolean) => {
    setRecentGiftStatus({ alreadyReserved: orderExists, usedEmail: values.email });
    updateDataProvider({
      type: submitType,
      productId: product?.id,
      order: {
        orderId
      },
      guest: {
        name: values.name,
        email: values.email
      },
      quantity: values.quantity
    });

    handleOnGuestDetailsSave(values.email, values.name);

    if (!orderExists) {
      if (openToast && submitType !== 'markAsPurchased') {
        openToast({ notificationText: itemAddedSuccess(), isPositionTop: false, noAnimation: false });
      }
      closeDialog();
    }
  };

  const handleRegistryOrder = async (values: PurchaseDialogFields) => {
    if (submitType === 'markAsPurchased') {
      onSubmitted(values, submitType, '', false);
      openModal({
        orderId: '',
        productId: product.id,
        modalStateToBeOpened: ModalStates.CONFIRM_PURCHASED
      });
    } else if (submitType === 'reserve') {
      const { orderId, orderExists } = await createRegistryOrderId(values);
      onSubmitted(values, submitType, orderId, orderExists);
      if (orderExists) {
        const telemArgs = {
          registryItemId: product?.registryItemId || '',
          registryId: product?.registry.id || null
        };
        analytics.track({
          category: 'guest.registry',
          action: 'ReservedRegistryItem-EmailMatched',
          label: product?.title || '',
          extraInfo: telemArgs
        });
      }
    }
  };

  const convertGroupGiftToPhysicalItem = async () => {
    if (product.isGroupGiftingEnabled) {
      await updateRegistryItem({
        variables: {
          id: product.id,
          payload: {
            totalRequested: 1,
            isGroupGiftingEnabled: false
          }
        }
      });
    }
  };

  const handleAffiliateWorkflow = async (values: PurchaseDialogFields) => {
    // Disable group gifting feature before purchasing group gift as physical item
    await convertGroupGiftToPhysicalItem();
    if (!hasProductAffiliateURL && submitType !== 'markAsPurchased') {
      setIsPurchasable(false);
      fireTelemetryReserveJoyRegistryItemEvent();
    } else {
      fireTelemetryReserveJoyRegistryItemEvent();
      await handleRegistryOrder(values);
    }
  };

  const handleDropshipWorkflow = async (values: PurchaseDialogFields, otherAddress?: ShippingAddressInput) => {
    // Disable group gifting feature before purchasing group gift as physical item
    await convertGroupGiftToPhysicalItem();
    try {
      const { data: contextResult } = await createContextToPurchase(values, otherAddress);
      const checkoutUrl = contextResult?.createRegistryPurchaseContext.checkoutUrl;

      if (checkoutUrl === null && !hasProductAffiliateURL) {
        setIsPurchasable(false);
        fireTelemetryReserveJoyRegistryItemEvent();
      } else {
        fireTelemetryReserveJoyRegistryItemEvent();

        if (checkoutUrl === null) {
          await handleRegistryOrder(values);
        } else {
          fireGuestRedirection(checkoutUrl);
        }
      }
    } catch (e) {
      if (hasProductAffiliateURL) {
        handleAffiliateWorkflow(values);
      } else {
        setIsPurchaseError(true);
      }
    }
  };

  const handleSubmit = async (values: PurchaseDialogFields) => {
    if (!isDropshipable || submitType === 'markAsPurchased') {
      handleAffiliateWorkflow(values);
    } else if (submitType) {
      handleDropshipWorkflow(values);
    }
  };

  const handleOnChangeEmail = (emailId: string) => {
    authenticatedSession(emailId, 'AuthenticatedSessionChangePrompt');
    handleOnChangeEmailDialogOpen();
  };

  const formik = useFormik<PurchaseDialogFields>({
    initialValues: {
      quantity: 1,
      name: providedGuestName ?? '',
      email: providedGuestEmail ?? '',
      note: ''
    },
    validationSchema: Yup.object<PurchaseDialogFields>({
      ...(shouldRequireName ? { name: Yup.string().required(requiredNameWarning()) } : { name: Yup.string() }),
      quantity: Yup.number().min(1).required(requiredLabel()),
      note: Yup.string(),
      ...(shouldRequireEmail
        ? {
            email: Yup.string().email(validEmailWarning()).required(requiredEmailWarning())
          }
        : { email: Yup.string().email(validEmailWarning()) })
    }),
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (!updatedOnceOnEmailChangeRef.current && providedGuestEmail !== formik.values.email) {
      updatedOnceOnEmailChangeRef.current = true;
      if (providedGuestEmail) {
        formik.setFieldValue('email', providedGuestEmail);
      }
    }
  }, [providedGuestEmail, formik]);

  // Dropship handlers
  const handleOnBackClick = () => {
    switch (formStep) {
      case FormStep.AddOtherAddress:
        changeFormStep(FormStep.MissingAddress);
        break;
      case FormStep.MissingAddress:
        changeFormStep(FormStep.Guest);
        break;
      case FormStep.GreetingCard:
        changeFormStep(FormStep.Guest);
        break;
      case FormStep.Guest:
      default:
        changeFormStep(FormStep.Product);
        break;
    }
  };

  const handleOnBuyNowClick = () => {
    registryItemBuyNowClicked({
      productTitle: product.title,
      registryItemId: product.registryItemId,
      registryId: product.registry.id,
      isGroupGiftingEnabled: !!product.isGroupGiftingEnabled
    });
    changeSubmitType('reserve');
    changeFormStep(FormStep.Guest);
  };

  const handleOnMarkAsPurchasedClick = () => {
    changeSubmitType('markAsPurchased');
    changeFormStep(FormStep.Guest);
  };

  const handleOnAddDetails = () => {
    // formik is behaving oddly here and saying it is valid even if email and name are empty so adding explicit check
    if (!formik.isSubmitting && formik.isValid && formik.values.email && formik.values.name) {
      if (!shippingAddress && submitType !== 'markAsPurchased') {
        // If there is no shipping address, we cannot proceed with dropship items
        changeFormStep(FormStep.MissingAddress);
      } else if (isExperimentNewFlowOn && submitType === 'reserve') {
        // Gift wrap adds alternative workflow enabling user to purchase an eCard with the gift
        changeFormStep(FormStep.GreetingCard);
      } else {
        // Original flow before gift wrap submits order and moves to stripe checkout
        formik.handleSubmit();
      }
    }
  };

  const handleOnMissingAddress = () => {
    changeFormStep(FormStep.AddOtherAddress);
  };

  const handleOnAddOtherAddress = async (otherAddress: ShippingAddressInput) => {
    const isDropshipableWithAltAddress = await checkoutMechanismsWithAltAddress(otherAddress);
    if (!isDropshipableWithAltAddress) {
      setIsPurchasable(false);
      return;
    }
    await handleDropshipWorkflow(formik.values, otherAddress);
  };

  const handleOnAddGreetingCardClick = () => {
    formik.handleSubmit();
  };

  const handleOnAddGiftNoteClick = () => {
    formik.handleSubmit();
  };

  // Affiliate handlers
  const handleOnAffiliateMarkAsPurchasedClick = () => {
    changeSubmitType('markAsPurchased');
    formik.handleSubmit();
  };

  const handleOnAffiliatePrimaryCTAClick = () => {
    changeSubmitType('reserve');
    formik.handleSubmit();
  };

  return {
    formStep,
    formik,
    isPurchasable,
    preparingForDropshipPurchaseLoading,
    goBackToRegistry,
    changeFormStep,
    changeSubmitType,
    handleViewItemDetails,
    handleOnChangeEmail,
    isPurchaseError,
    showDetailsLink,
    recentGiftStatus,
    handleOnBackClick,
    handleOnBuyNowClick,
    handleOnMarkAsPurchasedClick,
    handleOnAddDetails,
    createOrderLoading,
    productImageSrc,
    handleOnAffiliateMarkAsPurchasedClick,
    handleOnAffiliatePrimaryCTAClick,
    handleOnMissingAddress,
    handleOnAddOtherAddress,
    handleOnAddGreetingCardClick,
    handleOnAddGiftNoteClick,
    isExperimentNewFlowOn,
    isNotAnExperimentStep,
    updateRegistryItemLoading
  };
};
