/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Check Outline',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M11.3622 6.91392C11.5908 6.71389 11.6139 6.36642 11.4139 6.13782C11.2139 5.90922 10.8664 5.88606 10.6378 6.08608L7.02506 9.24725L5.38891 7.61109C5.17412 7.3963 4.82588 7.3963 4.61109 7.61109C4.3963 7.82588 4.3963 8.17412 4.61109 8.38891L6.61109 10.3889C6.81584 10.5937 7.14427 10.6046 7.36218 10.4139L11.3622 6.91392Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM13.9 8C13.9 11.2585 11.2585 13.9 8 13.9C4.74152 13.9 2.1 11.2585 2.1 8C2.1 4.74152 4.74152 2.1 8 2.1C11.2585 2.1 13.9 4.74152 13.9 8Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M16.8951 10.2015C17.1445 9.98334 17.1698 9.60428 16.9515 9.3549C16.7333 9.10552 16.3543 9.08025 16.1049 9.29845L10.5273 14.1788L7.92426 11.5757C7.68995 11.3414 7.31005 11.3414 7.07574 11.5757C6.84142 11.8101 6.84142 12.1899 7.07574 12.4243L10.0757 15.4243C10.2991 15.6476 10.6574 15.6596 10.8951 15.4515L16.8951 10.2015Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM20.8 12C20.8 16.8601 16.8601 20.8 12 20.8C7.13989 20.8 3.2 16.8601 3.2 12C3.2 7.13989 7.13989 3.2 12 3.2C16.8601 3.2 20.8 7.13989 20.8 12Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            d="M22.3951 13.4515C22.6445 13.2333 22.6698 12.8543 22.4515 12.6049C22.2333 12.3555 21.8543 12.3302 21.6049 12.5485L14.0273 19.1788L10.4243 15.5757C10.1899 15.3414 9.81005 15.3414 9.57574 15.5757C9.34142 15.8101 9.34142 16.1899 9.57574 16.4243L13.5757 20.4243C13.7991 20.6476 14.1574 20.6596 14.3951 20.4515L22.3951 13.4515Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM27.8 16C27.8 22.517 22.517 27.8 16 27.8C9.48304 27.8 4.2 22.517 4.2 16C4.2 9.48304 9.48304 4.2 16 4.2C22.517 4.2 27.8 9.48304 27.8 16Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            d="M33.4939 20.0644C33.8056 19.7917 33.8372 19.3178 33.5644 19.0061C33.2917 18.6944 32.8178 18.6628 32.5061 18.9356L21.0342 28.9735L15.5303 23.4697C15.2374 23.1768 14.7626 23.1768 14.4697 23.4697C14.1768 23.7626 14.1768 24.2374 14.4697 24.5303L20.4697 30.5303C20.7489 30.8095 21.1967 30.8244 21.4939 30.5644L33.4939 20.0644Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM40.5 24C40.5 33.1127 33.1127 40.5 24 40.5C14.8873 40.5 7.5 33.1127 7.5 24C7.5 14.8873 14.8873 7.5 24 7.5C33.1127 7.5 40.5 14.8873 40.5 24Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
