import React from 'react';
import { Box, SpacingStack, TextV2 } from '@withjoy/joykit';
import { LayoutShell, PrimaryCta } from '../../components';
import { useConfirmedPurchaseController } from './ConfirmedPurchase.controller';
import { ReactComponent as Surprise } from '@assets/icons/surprise.svg';
import { ConfirmedPurchaseSelectors } from './ConfirmedPurchase.selectors';

export const ConfirmedPurchase = () => {
  const { toNext, translations, platformLabel, hostName } = useConfirmedPurchaseController();
  const { title, subtitle, subtitleForCashCheckMethod, affirmativeButton } = translations;

  return (
    <LayoutShell {...ConfirmedPurchaseSelectors.ContainerElement}>
      <LayoutShell.Body>
        <LayoutShell.SingleColumnPanel>
          <Box paddingBottom={5}>
            <Surprise />
          </Box>
          <SpacingStack spacing={7}>
            <SpacingStack spacing={5} alignItems="center">
              <TextV2 typographyVariant="display2">{title()}</TextV2>
              <TextV2 typographyVariant="body2" textAlign="center">
                {platformLabel ? subtitle({ host: hostName, platform: platformLabel }) : subtitleForCashCheckMethod({ host: hostName })}
              </TextV2>
            </SpacingStack>
            <PrimaryCta {...ConfirmedPurchaseSelectors.AllDoneButton} onClick={toNext}>
              {affirmativeButton()}
            </PrimaryCta>
          </SpacingStack>
        </LayoutShell.SingleColumnPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
