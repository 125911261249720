import { styled, TextV2, Box, SpacingStack, Flex, LinkV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledPhoto = styled(Box)<{ backgroundImage?: string | null }>`
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url("${backgroundImage}")` : undefined)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
  mix-blend-mode: multiply;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 328px;
  height: 328px;
  margin: 0 auto 10px auto;
`;

export const StyledStackPhoto = styled(SpacingStack)`
  height: 100%;
  justify-content: center;
`;

export const StyledPhotoText = styled(TextV2)`
  width: 100%;
  text-align: center;
  padding: 0px 50px;
`;

export const EditPhotoButton = styled.a<{ disabled?: boolean | false; backgroundImage?: string | null }>`
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};
  width: 48px;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  backdrop-filter: blur(32px);
  border-radius: 280px;

  ${({ backgroundImage }) =>
    backgroundImage
      ? `
      position: absolute;
      bottom: 24px;
      right: 24px;
      margin: 0px;
    `
      : ``}
`;

export const StyledLink = styled.button`
  background-color: transparent;
  text-decoration: underline;
`;

export const StyleQtyBox = styled(Box)`
  #FormField2Error {
    width: 400px;
  }
`;

export const StyledButtonsContainer = styled(Flex)`
  column-gap: 8px;
  row-gap: 8px;
`;

export const StyledFlexPriceHeader = styled(Flex)`
  place-content: space-between;
`;

export const StyledPriceQtyContainer = styled(Flex)`
  column-gap: 24px;
`;

export const TooltipIcon = styled.button`
  padding-bottom: ${pxToRem(2)};
  &:hover > svg {
    color: ${props => props.theme.colors.mono8};
  }
  &:focus > svg {
    color: ${props => props.theme.colors.mono14};
  }
`;

export const StyledLinkV2 = styled(LinkV2)`
  text-decoration-color: ${props => props.theme.colors.mono5};
`;
