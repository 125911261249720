import { usePreviewListener, DesignUpdateEventHandler, LegacyPreviewIframeMessage } from '@shared/utils/previewMessageBus';
import { SupportedEventPages, useEventPageRouting } from '@apps/guest/routes/GuestSite/GuestSite.routes';
import { EventPageFragment } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useHistory } from '@react-router';

type PreviewListenerProps = Readonly<{
  eventHandle: string;
  pages: Maybe<EventPageFragment[]>;
  setRegistryPhoto: React.Dispatch<React.SetStateAction<string | undefined>>;
}>;

export const GuestRegistryPreviewListener = ({ eventHandle, pages, setRegistryPhoto }: PreviewListenerProps) => {
  const history = useHistory();
  const eventPageRoutes = useEventPageRouting(eventHandle, pages || []);

  const handleDesignUpdate = useEventCallback<DesignUpdateEventHandler>(message => {
    if (message.action === 'fromList') {
      message.value.forEach(update => {
        if (update.key === 'currentPageType') {
          const route = eventPageRoutes[update.value as SupportedEventPages];
          if (route) {
            history.push({ pathname: route.path });
          }
        } else {
          _updateFromLegacyPreviewMessage(setRegistryPhoto, update);
        }
      });
    }
  });

  // Subscribe to preview updates
  usePreviewListener({ onDesignUpdate: handleDesignUpdate, onAdminEventPageChange: () => {} });

  // This component is used for side effects, not rendering.
  return null;
};

const _updateFromLegacyPreviewMessage = (setRegistryPhoto: PreviewListenerProps['setRegistryPhoto'], update: LegacyPreviewIframeMessage): void => {
  switch (update.key) {
    case 'coverPhoto': {
      const { asset } = update.value;
      setRegistryPhoto(asset?.url);
      return;
    }
  }
};
