import { OnCloseArg } from '@withjoy/joykit';
import { useCallback, useState } from 'react';
import { usePurchaseController } from '@apps/registry/guest/routes/Purchase/Purchase.Controller';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { DonationFundPlatformTypeEnum } from '@graphql/generated';
import { getDomainNameFromProductExternalUrl } from '@shared/utils/urlHelpers';
import { getPaymentMethodTypeName } from '@shared/utils/paymentMethodOptions';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { useTranslation } from '@shared/core';
import {
  decrementAllowedDismissals,
  getAllowedDissmisalAttemptsSum,
  getClickPurchaseHistoryLength,
  removeItemFromPurchaseClickHistory,
  setAttemptedToGetResponseOnThisLoad,
  addItemToPurchaseClickHistory
} from '../../../GuestRegistry/components/ShoppingCart/utils/trackPurchaseClicks';
import { usePurchaseConfirmationProvider } from './PurchaseConfirmationModal.provider';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useFeatureValue } from '@shared/core/featureFlags';

export enum ModalStates {
  PURCHASE = 'PURCHASE',
  ADDRESS_CONFIRMATION = 'ADDRESS_CONFIRMATION',
  VENDOR_REDIRECT = 'VENDOR_REDIRECT',
  INTENT_TO_PURCHASE = 'INTENT_TO_PURCHASE',
  CREATE_ORDER_AND_PURCHASE = 'CREATE_ORDER_AND_PURCHASE',
  CONFIRM_PURCHASED = 'CONFIRM_PURCHASED',
  THANK_YOU = 'THANK_YOU',
  UNDO = 'UNDO',
  UNDONE = 'UNDONE',
  TRACKING_ORDER = 'TRACKING_ORDER'
}

type PurchaseConfirmationModalControllerProps = {
  eventHandle: string;
  onClose?: (args: OnCloseArg) => void;
  eventId: string;
  registry: RegistryList;
  isAdmin: boolean;
  productId?: string;
  modalStateToBeOpened?: ModalStates;
  openToast: (toast: ToastVariableProps) => void;
  orderId: string;
};

export const usePurchaseConfirmationModalController = ({
  onClose,
  eventId,
  registry,
  isAdmin,
  eventHandle,
  productId,
  modalStateToBeOpened,
  openToast,
  orderId
}: PurchaseConfirmationModalControllerProps) => {
  const [modalStatePrevious, setModalStatePrevious] = useState<ModalStates | null>(null);
  const [modalState, setModalState] = useState<ModalStates>(modalStateToBeOpened || ModalStates.VENDOR_REDIRECT);
  const enableOrderTrackingFeature = useFeatureValue('orderTrackingFeature');
  const {
    isOutOfStock,
    purchaseHistoryData: { setAttemptToShowPurchaseClickHistory, setRemainingPurchaseClickHistoryAttempts, currentPurchaseHistoryItem, setCurrentPurchaseHistoryItem }
  } = usePurchaseConfirmationProvider();

  const { type } = useGuestRegistryState();

  const changeModalState = useCallback(
    (newModalState: ModalStates) => {
      setModalStatePrevious(modalState);
      setModalState(newModalState);
    },
    [setModalStatePrevious, setModalState, modalState]
  );

  const handleReservationUndoneLocal = useCallback(() => {
    changeModalState(ModalStates.UNDONE);
    setModalStatePrevious(null);
  }, [changeModalState, setModalStatePrevious]);

  const {
    handleUndo,
    handleClickProduct,
    handleConfirmationClick,
    showUndoConfirmationScreen,
    item,
    productImage,
    quantity,
    platformType,
    isDonation,
    createOrderAndPurchase
  } = usePurchaseController({
    registry,
    isAdmin,
    eventId,
    isInModal: true,
    eventHandle,
    reservationUndoneCallback: handleReservationUndoneLocal,
    productId
  });
  const productTitle = item?.productData?.title || '';
  const registryItemId = item?.id || '';

  const { viewItemDetailsClick, purchaseConfirmationResponded, purchaseConfirmationDisplayed } = useGuestRegistryTelemetry();

  const handleConfirm = useCallback(() => {
    handleClickProduct();
    if (productId) {
      addItemToPurchaseClickHistory({ reservedOrderId: orderId, productId });
    }
    viewItemDetailsClick({
      productTitle,
      registryItemId,
      registryId: eventId
    });

    setTimeout(() => {
      changeModalState(ModalStates.CONFIRM_PURCHASED);
    });
  }, [handleClickProduct, changeModalState, viewItemDetailsClick, eventId, orderId, productId, productTitle, registryItemId]);

  const handlePurchaseHistoryDataUpdatesOnClose = useCallback(() => {
    // this check handles opening the dialog continually with different items
    if (modalState === ModalStates.CONFIRM_PURCHASED && modalStatePrevious === ModalStates.PURCHASE && productId) {
      const itemRemoved = decrementAllowedDismissals({ reservedOrderId: orderId, productId });
      const numberOfItems = getClickPurchaseHistoryLength();
      if (!itemRemoved && numberOfItems > 1 && currentPurchaseHistoryItem + 1 < numberOfItems) {
        // increment pointer if an item was not removed
        setCurrentPurchaseHistoryItem(currentPurchaseHistoryItem + 1);
      } else if (numberOfItems == 1) {
        // if an item was not removed and there is only 1 item, set the pointer to position 1
        setCurrentPurchaseHistoryItem(0);
      }
      setAttemptToShowPurchaseClickHistory(false);
      setAttemptedToGetResponseOnThisLoad({ reservedOrderId: orderId, productId });
      // this check handles on page load
    } else if (modalState === ModalStates.CONFIRM_PURCHASED && modalStatePrevious === null && productId) {
      const itemRemoved = decrementAllowedDismissals({ reservedOrderId: orderId, productId });
      const numberOfItems = getClickPurchaseHistoryLength();
      if (!itemRemoved && numberOfItems > 1 && currentPurchaseHistoryItem + 1 < numberOfItems) {
        setCurrentPurchaseHistoryItem(currentPurchaseHistoryItem + 1);
      } else if (numberOfItems == 1) {
        setCurrentPurchaseHistoryItem(0);
      }
      if (numberOfItems === 0) {
        setAttemptToShowPurchaseClickHistory(false);
      }
      setAttemptedToGetResponseOnThisLoad({ reservedOrderId: orderId, productId });
    }
    setRemainingPurchaseClickHistoryAttempts(getAllowedDissmisalAttemptsSum());
  }, [
    modalState,
    modalStatePrevious,
    orderId,
    setAttemptToShowPurchaseClickHistory,
    setRemainingPurchaseClickHistoryAttempts,
    setCurrentPurchaseHistoryItem,
    currentPurchaseHistoryItem,
    productId
  ]);

  const onCloseModal = useCallback(
    (args: OnCloseArg) => {
      handlePurchaseHistoryDataUpdatesOnClose();
      onClose && onClose(args);
    },
    [onClose, handlePurchaseHistoryDataUpdatesOnClose]
  );

  const closeAndUpdateRemainingReminderAttempts = useCallback(
    (args: OnCloseArg) => {
      onClose?.(args);
      setRemainingPurchaseClickHistoryAttempts(getAllowedDissmisalAttemptsSum());
    },
    [onClose, setRemainingPurchaseClickHistoryAttempts]
  );

  const handleHasNotPurchased = () => {
    if ((type !== 'markAsPurchased' && !orderId) || isOutOfStock) {
      onCloseModal({ closeSource: 'custom' });
    } else {
      handleNotPurchased();
    }
    if (productId) {
      removeItemFromPurchaseClickHistory({ reservedOrderId: orderId, productId });
    }
    purchaseConfirmationResponded(false, {
      productTitle,
      registryItemId,
      registryId: eventId
    });
  };

  const purchaseConfirmationDisplayedTelemetry = () => {
    purchaseConfirmationDisplayed({
      productTitle,
      registryItemId,
      registryId: eventId
    });
  };

  const showUndoConfirmationScreenLocal = useCallback(() => {
    showUndoConfirmationScreen();
    changeModalState(ModalStates.UNDO);
  }, [showUndoConfirmationScreen, changeModalState]);

  const { t } = useTranslation('guestRegistry');
  const { purchaseSuccess } = t('shoppingCart');

  const handlePurchaseSuccess = useCallback(() => {
    changeModalState(ModalStates.THANK_YOU);
    openToast({ notificationText: purchaseSuccess(), isPositionTop: true, noAnimation: false });
  }, [changeModalState, openToast, purchaseSuccess]);

  const confirmationClick = useCallback(() => {
    handleConfirmationClick();

    if (!enableOrderTrackingFeature) {
      handlePurchaseSuccess();
    } else {
      changeModalState(ModalStates.TRACKING_ORDER);
    }
  }, [handleConfirmationClick, handlePurchaseSuccess, changeModalState, enableOrderTrackingFeature]);

  const handleHasPurchased = useCallback(async () => {
    purchaseConfirmationResponded(true, {
      productTitle,
      registryItemId,
      registryId: eventId
    });
    if ((orderId && productId) || type === 'markAsPurchased') {
      confirmationClick();
      removeItemFromPurchaseClickHistory({ reservedOrderId: orderId, productId: productId || '' });
    } else {
      changeModalState(ModalStates.CREATE_ORDER_AND_PURCHASE);
    }
  }, [changeModalState, eventId, type, orderId, productId, productTitle, purchaseConfirmationResponded, registryItemId, confirmationClick]);

  const confirmAddressClick = useCallback(() => {
    changeModalState(ModalStates.PURCHASE);
  }, [changeModalState]);

  const backToAddressConfirmation = useCallback(() => {
    changeModalState(ModalStates.ADDRESS_CONFIRMATION);
    setModalStatePrevious(null);
  }, [changeModalState, setModalStatePrevious]);

  const handleNotPurchased = useCallback(() => {
    changeModalState(ModalStates.INTENT_TO_PURCHASE);
  }, [changeModalState]);

  const onCloseIntentModal = () => closeAndUpdateRemainingReminderAttempts({ closeSource: 'custom' });

  const handlePurchaseLater = () => {
    if (!orderId) {
      createOrderAndPurchase(false);
    }
    onCloseIntentModal();
  };

  const handleCancelUndo = useCallback(() => {
    if (modalStatePrevious) {
      setModalState(modalStatePrevious);
      setModalStatePrevious(null);
    }
  }, [setModalState, setModalStatePrevious, modalStatePrevious]);

  const donationFundPlatform = item?.donationFund?.platform;
  const donationPlatformDomain =
    donationFundPlatform?.type === DonationFundPlatformTypeEnum.other
      ? getDomainNameFromProductExternalUrl(donationFundPlatform?.link)
      : getPaymentMethodTypeName(donationFundPlatform?.type as string);

  return {
    isDonation,
    platformType,
    handleCancelUndo,
    confirmationClick,
    showUndoConfirmationScreenLocal,
    onCloseModal,
    handleConfirm,
    handleUndo,
    showUndoConfirmationScreen,
    item,
    productImage,
    quantity,
    modalState,
    modalStatePrevious,
    confirmAddressClick,
    backToAddressConfirmation,
    handleNotPurchased,
    donationPlatformDomain,
    handleHasPurchased,
    handleHasNotPurchased,
    onCloseIntentModal,
    purchaseConfirmationDisplayedTelemetry,
    handlePurchaseSuccess,
    handlePurchaseLater
  };
};
