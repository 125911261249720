import React from 'react';
import { IntegratedGiftCard, LayoutShell } from '../../components';
import { useExternalCheckoutInstructionsController } from './ExternalCheckoutInstructions.controller';
import { ExternalCheckoutInstructionsSelectors } from './ExternalCheckoutInstructions.selectors';
import { ExternalCheckoutInstructionsContent } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutInstructions/ExternalCheckoutInstructionsContent';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';

export const ExternalCheckoutInstructions = () => {
  const { registryItem, translations, handleToNext, isAffiliate, product, productImageSrc, formValues, currency } = useExternalCheckoutInstructionsController();

  const isCashFundType = registryItem?.donationFund?.fundType === 'cash';

  return (
    <LayoutShell {...ExternalCheckoutInstructionsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement={isCashFundType ? 'float' : 'static'} />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} quantity={formValues.quantity} currency={currency} />
          ) : (
            <IntegratedGiftCard mobileRenderBehavior={isCashFundType ? 'fullBleed' : 'card'} />
          )}
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <ExternalCheckoutInstructionsContent
            translations={translations}
            toNext={handleToNext}
            paymentMethod={formValues.paymentMethod}
            account={registryItem?.donationFund?.platform?.username || ''}
          />
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
