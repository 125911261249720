import { LayoutShell } from '@apps/registry/guest/components/CheckoutDialog/components';
import { GiftNoteView } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/DropshipProductDialogBody/components/GiftNote/GiftNoteView';

import React from 'react';
import { useCheckoutFunnel } from '../../hooks';

const GiftWrapNote = () => {
  const { submitGiftNote, upgradeGiftCard } = useCheckoutFunnel(({ submitGiftNote, upgradeGiftCard }) => [submitGiftNote, upgradeGiftCard]);

  const handleOnAddGiftNoteClick = (note: string) => {
    submitGiftNote({ note });
  };

  return (
    <LayoutShell>
      <LayoutShell.Header></LayoutShell.Header>
      <LayoutShell.Body display="flex" flexDirection="column" width="100%" paddingBottom={8}>
        <GiftNoteView isLoading={false} handleOnUpgradeMyGiftNoteClick={upgradeGiftCard} handleOnAddGiftNoteClick={handleOnAddGiftNoteClick} />
      </LayoutShell.Body>
    </LayoutShell>
  );
};

export default GiftWrapNote;
