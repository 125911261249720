import { useCallback, useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { isEmptyArray, isEmptyObject } from '@shared/utils/assertions';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { CheckoutMachineInterpreter, CheckoutMachineInterpreterState, CheckoutServiceFacade, getServiceFacade } from '../../machines/externalCheckout';
import { useCheckoutDialogContext } from '@apps/registry/guest/components/CheckoutDialog';

interface FunnelContextValue {
  service: CheckoutMachineInterpreter;
  currentOrderIdState: string | undefined | null;
  creditCardError: string;
}

const [Provider, useCheckoutFunnelContext] = createContext<FunnelContextValue>({ name: 'CashFundCheckoutFunnel' });

/**
 * Inspired from https://xstate.js.org/docs/packages/xstate-react/#useselector-actor-selector-compare-getsnapshot.
 *
 * Selector accepts current facade values and returns an array of
 * desired value(s) that should trigger re-render.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Selector = (context: CheckoutServiceFacade & { currentOrderIdState?: string | undefined | null }) => Array<any>;

const areArrayValuesEqual = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((elem1, index) => {
    const elem2 = arr2[index];
    /**
     * edge cases: if both values are empty object/array, we consider them equal.
     * These can catch empty default values (`[]`, `{}`) that unintentionally point
     * to different refernces.
     *
     * We can consider doing a deep comparison, but left it here for efficiency
     * + practicality for authenticator state comparison purposes.
     */
    if (isEmptyArray(elem1) && isEmptyArray(elem2)) return true;
    if (isEmptyObject(elem1) && isEmptyObject(elem2)) return true;

    return elem1 === elem2;
  });
};

export type UseCheckoutFunnelReturn = CheckoutServiceFacade & { currentOrderIdState?: string | undefined | null };

/**
 * -----
 * Internal API hook for checkout dialog screens.
 * ----
 *
 * To prevent undesired re-renders, you can pass a function to `useCheckoutFunnel` that
 * takes in Checkout Funnel context and returns an array of desired context values.
 * This hook will only trigger re-render if any of the array value changes.
 *
 * @example
 * // The hook below is only reevaluated when `route` changes.
 * const { route, toBack } = useCheckoutFunnel(({ route, toBack }) => [route, toBack]);
 *
 * @param selector Selector accepts current facade values and returns an array of desired value(s) that should trigger re-render.
 * @returns `CheckoutServiceFacade`
 */
export const useCheckoutFunnel = (selector?: Selector): UseCheckoutFunnelReturn => {
  const { service, currentOrderIdState } = useCheckoutFunnelContext();
  const { setCurrentStep } = useCheckoutDialogContext();

  const { send } = service;

  const getFacade = useCallback(
    (state: CheckoutMachineInterpreterState) => {
      return getServiceFacade({ send, state });
    },
    [send]
  );

  const stableSelector = useEventCallback(selector);

  const comparator = useCallback(
    (prevFacade: CheckoutServiceFacade, nextFacade: CheckoutServiceFacade) => {
      if (!stableSelector) {
        return false;
      }

      /**
       * Apply the passed in `selector` to get the value of their desired
       * dependency array.
       */
      const prevDepsArray = stableSelector(prevFacade);
      const nextDepsArray = stableSelector(nextFacade);

      return areArrayValuesEqual(prevDepsArray, nextDepsArray);
    },
    [stableSelector]
  );

  const facade = useSelector(service, getFacade, comparator);

  useEffect(() => {
    setCurrentStep(facade.route);
  }, [facade.route, setCurrentStep]);

  return { ...facade, currentOrderIdState };
};

export { Provider, useCheckoutFunnelContext };
