import React from 'react';
import { isPhotoMediaAsset, isVideoMediaAsset } from '../../../ProductImageGallery/utils';
import { ProductVideo } from '../../../ProductVideo/ProductVideo';
import { GL_ASSET_CONTAINER_VERTICAL_MARGIN } from '../../GalleryLightbox';
import { ZoomablePhoto } from '../ZoomablePhoto/ZoomablePhoto';
import { StyledContainer, StyledMediaContainer, styles } from './SliderContent.styles';
import { CatalogProductMediaAsset } from '@apps/registry/common/components/Catalog/Catalog.types';

export const SliderContent = ({
  item,
  isMobile,
  isSelected,
  setEnableSwipe,
  wasZoomedIn,
  setWasZoomedIn,
  screenHeight
}: {
  item: CatalogProductMediaAsset;
  isMobile?: boolean;
  isSelected?: boolean;
  setEnableSwipe: (state: boolean) => void;
  wasZoomedIn?: boolean;
  setWasZoomedIn?: (state: boolean) => void;
  screenHeight: number;
}) => {
  return (
    <StyledContainer {...styles.container} visibility={isSelected ? 'visible' : 'hidden'}>
      <StyledMediaContainer {...styles.mediaContainer(screenHeight)}>
        {isPhotoMediaAsset(item) && (
          <ZoomablePhoto
            item={item}
            setEnableSwipe={setEnableSwipe}
            isMobile={isMobile}
            isSelected={isSelected}
            wasZoomedIn={wasZoomedIn}
            setWasZoomedIn={setWasZoomedIn}
            maxHeight={screenHeight - GL_ASSET_CONTAINER_VERTICAL_MARGIN}
          />
        )}
        {isVideoMediaAsset(item) && (
          <ProductVideo
            videoUrl={item.video.url}
            fallbackImageUrl={item.video.fallbackImage.url}
            shouldPlay={isSelected}
            style={{ width: { _: '100vw', sm2: '70vw' }, height: { _: '100vw', sm2: 'auto' } }}
          />
        )}
      </StyledMediaContainer>
    </StyledContainer>
  );
};
