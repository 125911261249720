import { LayoutShell, PrimaryCta } from '@apps/registry/guest/components/CheckoutDialog/components';
import { GifterDetailsSelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/GifterDetails/GifterDetails.selectors';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';
import React, { useState } from 'react';
import { useCheckoutFunnel, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { useTranslation } from '@shared/core';
import { Box, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { StyledAddressLink } from '@apps/registry/guest/components/Address.styles';
import { NotificationToast } from '@apps/registry/common/components/NotificationToast';
import { pxToRem } from '@withjoy/joykit/theme';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';

export const ExternalCheckoutCopyAddress = () => {
  const [notificationVisible, setNotificationVisible] = useState(false);

  const { product, productImageSrc, currency } = useGetProductData('externalCheckoutCopyAddress');
  const { registryState } = useCheckoutDialogContext();
  const address = registryState?.shippingAddress;
  const { formValues, toNext } = useCheckoutFunnel(({ formValues, toNext }) => [formValues, toNext]);

  const { t } = useTranslation('guestRegistry');
  const translations = t('checkoutDialog', 'screens', 'externalCheckoutCopyAddress');
  const { title, coupleMailingAddress, copyButton, cta, missingShippingAddress, copyToClipboard } = translations;

  const shippingAddressText = address
    ? `${address.name}\n${address.address1}\n${address.address2}${address.address2 ? '\n' : ''}${address.city}, ${address.state} ${address.postalCode}`
    : '';

  return (
    <LayoutShell {...GifterDetailsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="static" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} quantity={formValues.quantity} currency={currency} />
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <SpacingStack spacing={7}>
            <TextV2 typographyVariant="hed5">{title()}</TextV2>
            <Box padding={6} borderRadius={pxToRem(8)} border="1px solid" borderColor="mono3">
              <SpacingStack spacing={4}>
                <Flex alignItems="flex-start" justifyContent="space-between">
                  <TextV2 typographyVariant="hed1" tagName="p" maxWidth="242px" marginRight={4}>
                    {coupleMailingAddress()}
                  </TextV2>
                  {address ? (
                    <>
                      <CopyToClipboard text={shippingAddressText} onCopy={() => setNotificationVisible(true)}>
                        <StyledAddressLink underline="none">{copyButton()}</StyledAddressLink>
                      </CopyToClipboard>
                      <NotificationToast
                        notificationText={copyToClipboard()}
                        notificationVisible={notificationVisible}
                        setNotificationVisible={setNotificationVisible}
                        isPositionTop
                        noAnimation
                      />
                    </>
                  ) : null}
                </Flex>
                {address ? (
                  <CopyToClipboard text={shippingAddressText} onCopy={() => setNotificationVisible(true)}>
                    <div>
                      <TextV2 typographyVariant="body1" tagName="p">
                        {address.name}
                      </TextV2>
                      <TextV2 typographyVariant="body1" tagName="p">
                        {address.address1}
                      </TextV2>
                      {address.address2 && (
                        <TextV2 typographyVariant="body1" tagName="p">
                          {address.address2}
                        </TextV2>
                      )}
                      <TextV2 typographyVariant="body1" tagName="p">
                        {`${address.city}, ${address.state} ${address.postalCode}`}
                      </TextV2>
                    </div>
                  </CopyToClipboard>
                ) : (
                  <TextV2 tagName="p" typographyVariant="body1">
                    {missingShippingAddress()}
                  </TextV2>
                )}
              </SpacingStack>
            </Box>
            <PrimaryCta fullWidth type="submit" onClick={toNext}>
              {cta()}
            </PrimaryCta>
          </SpacingStack>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
