import { StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const content: StyleSystemProps = {
  padding: 6,
  width: '100%'
};

const root: StyleSystemProps = {
  width: '100%',
  borderRadius: pxToRem(12),
  border: '1px solid',
  borderColor: 'mono3',
  boxShadow: { _: '0px 3px 8px 0px rgba(0, 0, 0, 0.03), 0px 10px 60px -20px rgba(44, 41, 37, 0.03), 0px 9px 36px -30px rgba(0, 0, 0, 0.04)', md: 'none' }
};
const iconCard: StyleSystemProps = {
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'mono1',
  borderRadius: pxToRem(12),
  aspectRatio: '1/1',
  maxHeight: pxToRem(200)
};
const eventName: StyleSystemProps = { fontWeight: 400, textAlign: 'center', marginBottom: 6 };
const collapsibleContent: StyleSystemProps = {
  transition: 'max-height 300ms ease-in-out',
  flexDirection: { _: 'column-reverse', md: 'row' },
  gap: { md: 9, _: 5 },
  overflow: 'hidden'
};

export const styles = {
  root,
  eventName,
  content,
  collapsibleContent,
  iconCard
};
