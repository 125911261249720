import React from 'react';
import { PublicBoxProps } from '@withjoy/joykit';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { GiftCard } from './GiftCard';
import { CustomItemDataType } from '@graphql/generated';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';

export interface IntegratedGiftCardProps extends PublicBoxProps {
  /**
   * This will only be considered if `productType` is 'cash' - otherwise it defaults to `card`
   */
  mobileRenderBehavior: 'fullBleed' | 'card';
}

export const IntegratedGiftCard = (props: IntegratedGiftCardProps) => {
  const {
    formValues,
    route,
    context: { isGroupGifting }
  } = useCheckoutFunnel(({ formValues, route, context }) => [formValues, route, context.isGroupGifting]);
  const { registryItem, order } = useCheckoutInitDataContext('giftAmount');
  const checkoutDialogContext = useCheckoutDialogContext();

  const { amount, paymentMethod } = formValues;
  const { donationFund, productData } = registryItem;
  const currencyCode: string = donationFund?.currency?.code || checkoutDialogContext.registryState?.registryCurrencyCode || DEFAULT_CURRENCY;
  const isCharity = donationFund?.fundType === CustomItemDataType.charity;
  const hideGradient = route === 'giftAmount' || isCharity || isGroupGifting;

  return (
    <GiftCard
      giftAmount={`${amount ?? order?.amount.floatingPointDecimalString}`}
      currencyCode={currencyCode}
      paymentMethodUsername={donationFund?.platform?.username}
      paymentMethod={paymentMethod}
      productType={donationFund?.fundType}
      productImageUrl={productData.photos?.[0]?.url}
      title={productData.title}
      hideGradient={hideGradient}
      hideMetadata={route === 'giftAmount'}
      {...props}
    />
  );
};
