import React from 'react';
import { BaseBoxProps, Box, CloseButton, TextV2 } from '@withjoy/joykit';
import { StyledItemBox, StyledProductPhoto, StyledProductPhotoBox, StyledGridBox, StyledTitleText, StyledOutOfStockMessageBox, styles } from './ItemBase.styles';
import { addRendition } from '@shared/utils/photoRendition';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useTranslation } from '@shared/core';
import { useShoppingCart } from '../../state';

export type ItemBaseVariant = 'normal' | 'transparent';

type ItemBaseProps = Readonly<{
  product: Partial<CookedProduct>;
  siteName?: string | null;
  variant?: ItemBaseVariant;
  inStock?: boolean;
  registryItemId?: string;
}> &
  BaseBoxProps;

export const ItemBase: React.FC<ItemBaseProps> = ({ product, siteName, variant, inStock, registryItemId, __css, children }) => {
  const gridBoxStyles = inStock !== false ? {} : { ...styles.outOfStockGridBox };
  const { t } = useTranslation('guestRegistry');
  const { outOfStockItemText, purchaseOtherGiftText } = t('shoppingCart');

  const {
    mutators: { removeItem }
  } = useShoppingCart();

  return (
    <StyledItemBox variant={variant}>
      <Box __css={gridBoxStyles}>
        {inStock === false && (
          <StyledOutOfStockMessageBox __css={styles.outOfStockMessageBox}>
            <Box>
              <TextV2 typographyVariant="body1">{outOfStockItemText()}</TextV2>
              <TextV2 typographyVariant="body1">{purchaseOtherGiftText()}</TextV2>
            </Box>
            <CloseButton
              height="100%"
              iconSize={16}
              paddingRight={0}
              onClick={ev => {
                ev.stopPropagation();
                removeItem(registryItemId!);
              }}
            />
          </StyledOutOfStockMessageBox>
        )}
        <StyledGridBox display="grid" gridTemplateColumns="0.5fr 1fr 0" gridGap="12px" {...__css} inStock={inStock}>
          <StyledProductPhotoBox>
            <StyledProductPhoto backgroundImage={addRendition({ url: product.image, renditionSize: 'medium', isProduct: true })} />
          </StyledProductPhotoBox>
          <Box>
            <Box>
              {siteName && (
                <TextV2 typographyVariant="label2" color="mono12">
                  {siteName}
                </TextV2>
              )}
              <StyledTitleText typographyVariant="hed1" component="p" __css={styles.titleText}>
                {product.title}
              </StyledTitleText>
            </Box>
            {children}
          </Box>
        </StyledGridBox>
      </Box>
    </StyledItemBox>
  );
};
