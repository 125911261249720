import React from 'react';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { Colors } from '@withjoy/joykit/theme';
import { useTranslation } from '@shared/core';
import { GiftImage } from './GiftWrap.styles';

interface GiftWrapGiftPreviewProps {
  textColor?: keyof Colors;
  image?: string;
  store?: string;
  title?: string;
  extraProductsCount?: number;
  link?: string;
}

export const GiftWrapGiftPreview: React.FC<GiftWrapGiftPreviewProps> = ({ textColor = 'mono14', image, store, title, extraProductsCount, link }) => {
  const { t } = useTranslation('guestRegistry');
  const tGiftCard = t('checkoutGiftCard');

  const imageSize = '110px';
  const storeSize = '13px';
  const storeLineHeight = '140%';
  const titleSize = '15px';
  const titleLineHeight = '140%';

  return (
    <Flex flexDirection="column" alignItems="center" maxWidth="275px" marginTop={10}>
      {image && <GiftImage src={image} alt="gift" size={imageSize} />}
      <TextV2 textAlign="center" marginTop={5} color={textColor} fontSize={storeSize} fontWeight="bold" lineHeight={storeLineHeight} userSelect={'none'}>
        {store}
      </TextV2>
      <TextV2 textAlign="center" marginTop={3} color={textColor} fontSize={titleSize} fontWeight="semibold" lineHeight={titleLineHeight} userSelect={'none'}>
        {title}
      </TextV2>
      {!!extraProductsCount && (
        <TextV2 textAlign="center" marginTop={4} color={textColor} fontSize={storeSize} fontWeight="semibold" lineHeight={titleLineHeight} userSelect={'none'}>
          {extraProductsCount === 1 ? tGiftCard.otherGifts.single({ count: extraProductsCount }) : tGiftCard.otherGifts.plural({ count: extraProductsCount })}
        </TextV2>
      )}
      {link && (
        <ButtonV2
          variant="solid"
          color="white"
          backgroundColor={'mono14'}
          _hover={{ backgroundColor: 'mono13' }}
          minWidth={200}
          marginTop={8}
          userSelect={'none'}
          onClick={event => {
            window?.open?.(link);
            event?.stopPropagation();
          }}
        >
          {'Open My Gift'}
        </ButtonV2>
      )}
    </Flex>
  );
};
