import { Flex, StyleSystemProps, TextV2, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const MustHaveContainer = styled(Flex)``;
const mustHaveContainerStyles: StyleSystemProps = {
  backgroundColor: 'mono14',
  width: 'fit-content',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 24,
  paddingX: 3,
  paddingY: 1,
  marginBottom: 6
};

export const StyledLabel = styled(TextV2)``;
const labelStyles: StyleSystemProps = {
  paddingLeft: 3,
  lineHeight: pxToRem(18),
  fontWeight: 600,
  color: 'white'
};

export const styles = {
  mustHaveContainer: mustHaveContainerStyles,
  label: labelStyles
};
