import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';
import { useAnnotateRegistryOrderMutation } from '@graphql/generated';
import { AddExternalOrderTrackingDialogProps } from './AddExternalOrderTrackingDialog';
import { safeInvoke } from '@shared/utils/safeInvoke';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

export interface ReserveItemFields {
  orderNumber: string;
  trackingNumber: string;
}

interface AddExternalOrderTrackingDialogControllerProps
  extends Pick<AddExternalOrderTrackingDialogProps, 'openToast' | 'orderTitle' | 'onClose' | 'orderId' | 'orderNumber' | 'trackingNumber' | 'guestName'> {}

export const useAddExternalOrderTrackingDialogController = ({
  openToast,
  orderTitle,
  orderId,
  onClose,
  orderNumber,
  trackingNumber,
  guestName
}: AddExternalOrderTrackingDialogControllerProps) => {
  const { orderTrackingInfoSubmit } = useGuestRegistryTelemetry();
  const { t } = useTranslation('sharedRegistry');
  const translations = t('externalOrderTracking');

  const [annotateRegistryOrder, { loading }] = useAnnotateRegistryOrderMutation({
    refetchQueries: () => [guestName ? 'GetAdminRegistryOrders' : 'GetEventRegistriesAndOrders']
  });

  const handleOnClickSave = async ({ orderNumber, trackingNumber }: ReserveItemFields) => {
    const shortenOrderTitle = orderTitle && orderTitle.length > 15 ? `${orderTitle.substring(0, 15)}…` : orderTitle;
    await annotateRegistryOrder({ variables: { id: orderId, payload: { orderAnnotations: { trackingNumber, orderNumber } } } });
    safeInvoke(openToast, { notificationText: translations.successMessage({ orderTitle: shortenOrderTitle }), noAnimation: true, isPositionTop: true });
    orderTrackingInfoSubmit();
    onClose();
  };

  const formik = useFormik<ReserveItemFields>({
    initialValues: {
      orderNumber: orderNumber ?? '',
      trackingNumber: trackingNumber ?? ''
    },
    validationSchema: Yup.object<ReserveItemFields>({
      orderNumber: Yup.string().required(translations.orderNumberRequiredError),
      trackingNumber: Yup.string()
    }),
    onSubmit: values => handleOnClickSave(values)
  });

  return {
    translations,
    formik,
    loading
  };
};
