import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { isEqual } from 'lodash-es';

const orderByDateAdded = (products: Array<CookedProduct>, order: 'asc' | 'desc') => {
  return [...products.sort((a, b) => (order === 'asc' ? a.createdAtMilliseconds - b.createdAtMilliseconds : b.createdAtMilliseconds - a.createdAtMilliseconds))];
};

const orderByPriority = (products: Array<CookedProduct>) => {
  return [...products.sort((a, b) => a.fpIndex - b.fpIndex)];
};

export const prioritizeCatalogItems = (products: Array<CookedProduct>) => {
  const hasCoupleReorderedRegistry = !isEqual(orderByPriority(products), orderByDateAdded(products, 'asc'));

  if (hasCoupleReorderedRegistry) {
    return orderByPriority(products);
  }

  // The idea is to leave the top 5 products untouched
  const topProducts = products.slice(0, 5);
  const restProducts = products.slice(5);

  return [
    ...topProducts,
    // Drop shippable
    ...orderByDateAdded(
      restProducts.filter(p => !p.externallyOwned && !p.canEditProductData),
      'desc'
    ),
    // Transferred items
    ...orderByDateAdded(
      restProducts.filter(p => p.externallyOwned && p.canEditProductData),
      'desc'
    ),
    // Manually added
    ...orderByDateAdded(
      restProducts.filter(p => !p.externallyOwned && p.canEditProductData),
      'desc'
    ),
    // Synced items
    ...orderByDateAdded(
      restProducts.filter(p => p.externallyOwned && !p.canEditProductData),
      'desc'
    )
  ];
};
