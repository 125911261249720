import React from 'react';
import { SpacingStack } from '@withjoy/joykit';
import { LayoutShell } from '../../components/LayoutShell';
import { usePaymentMethodController } from '../PaymentMethod/PaymentMethod.controller';
import { ContentHeader, IntegratedGiftCard, PaymentMethodSelector, PrimaryCta } from '@apps/registry/guest/components/CheckoutDialog/components';
import { PaymentMethodSelectors } from '../PaymentMethod/PaymentMethod.selectors';
import { CreditCardCheckoutError } from '../CreditCardCheckoutError/CreditCardCheckoutError';

export const CreditCardIdle = () => {
  const {
    isCashFundType,
    options,
    handleSubmit,
    onUpdatePaymentMethod,
    currentPaymentMethod,
    translations,
    eventDisplayName,
    isLoading,
    creditCardError
  } = usePaymentMethodController();
  const { title, cta, description, descriptionDonation } = translations;

  return !creditCardError ? (
    <LayoutShell {...PaymentMethodSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement={isCashFundType ? 'float' : 'static'} />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          <IntegratedGiftCard mobileRenderBehavior={isCashFundType ? 'fullBleed' : 'card'} />
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <ContentHeader>
            <ContentHeader.Hed>{title()}</ContentHeader.Hed>
            <ContentHeader.Dek>{isCashFundType ? description({ host: eventDisplayName }) : descriptionDonation({ fundName: eventDisplayName })}</ContentHeader.Dek>
          </ContentHeader>
          <form onSubmit={handleSubmit}>
            <SpacingStack spacing={7} marginTop={6}>
              <PaymentMethodSelector disabled={isLoading} value={currentPaymentMethod} options={options} onChange={onUpdatePaymentMethod} />
              <PrimaryCta {...PaymentMethodSelectors.SelectPaymentMethodButton} disabled={!currentPaymentMethod} type="submit" loading={isLoading}>
                {cta()}
              </PrimaryCta>
            </SpacingStack>
          </form>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  ) : (
    <CreditCardCheckoutError creditCardError={creditCardError} />
  );
};
