import { Box, styled, TextV2 } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

export const blockStyles: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  flex: ['100%', null, 'calc(100% - 40px)'],
  ['& > svg']: {
    marginBottom: [5]
  }
};

export const boxIconWithNoteStyles: CSSObject = {
  display: 'flex',
  marginBottom: '26px',
  alignItems: 'center'
};

export const titleStyled: CSSObject = {
  paddingTop: '1em',
  textAlign: 'center'
};

export const boxWidthStyled: CSSObject = {
  maxWidth: '362px',
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const StyledBoxIconWithNote = styled(Box)`
  gap: 2rem;
`;

export const StyledNote = styled(TextV2)``;
