import { GiftWrapPremiumDetails, IGiftWrapTheme } from '@shared/components';
import { keyframes, styled } from '@withjoy/joykit';
import React, { useEffect, useRef, useState } from 'react';
import { PremiumCardTemplate } from './PremiumCardTemplate';
import { useLoadGoogleFont } from './useLoadGoogleFont';
import { useSceneTimer } from './useSceneTimer';

const Sun = styled.img`
  width: 79px;
  height: 79px;
  left: 605px;
  top: 527px;
  &.t2,
  &.t3 {
    width: 135px;
    height: 135px;
    left: 805px;
    top: 746px;
    transition: all 4s;
  }
`;

const Mountain = styled.img`
  width: 629px;
  height: 131px;
  left: 0px;
  top: 805px;
  &.t2,
  &.t3 {
    top: 895px;
    transition: top 4s;
  }
`;

const Wave1 = styled.img`
  @keyframes Wave1 {
    100% {
      left: -85px;
    } // Keynote has -80, but it's wrong.
  }
  animation: Wave1 5s linear infinite;
  width: 1055px;
  height: 158px;
  left: -55px;
  top: 919px;
  &.t2,
  &.t3 {
    left: -23px;
    top: 1009px;
    transition: all 4s;
  }
`;

const Wave2 = styled.img`
  @keyframes Wave2 {
    100% {
      left: -44px;
    }
  }
  animation: Wave2 5s linear infinite;
  width: 1239px;
  height: 185px;
  left: -147px;
  top: 945px;
  &.t2,
  &.t3 {
    left: -115px;
    top: 1035px;
    transition: all 4s;
  }
`;

const Wave3 = styled.img`
  @keyframes Wave3 {
    100% {
      left: -496px;
    }
  }
  animation: Wave3 5s linear infinite;
  width: 1436px;
  height: 214px;
  left: -269px;
  top: 991px;
  &.t2,
  &.t3 {
    left: -237px;
    top: 1082px;
    transition: all 4s;
  }
`;

const Wave4 = styled.img`
  @keyframes Wave4 {
    100% {
      left: -94px;
    }
  }
  animation: Wave4 5s linear infinite;
  width: 1790px;
  height: 267px;
  left: -446px;
  top: 1066px;
  &.t2,
  &.t3 {
    left: -414px;
    top: 1156px;
    transition: all 4s;
  }
`;

const Cloud1 = styled.img`
  width: 336px;
  height: 90px;
  left: 119px;
  top: 745px;
  &.t2,
  &.t3 {
    width: 464px;
    height: 125px;
    left: -10px;
    top: 727px;
    transition: all 4s;
  }
  &.t3 {
    left: 410px;
    transition: left 20s;
  }
`;

const Cloud2 = styled.img`
  width: 187px;
  height: 75px;
  left: 594px;
  top: 796px;
  &.t2,
  &.t3 {
    width: 255px;
    height: 103px;
    left: 615px;
    top: 762px;
    transition: all 4s;
  }
  &.t3 {
    left: 835px;
    transition: left 20s;
  }
`;

const Sand = styled.img`
  width: 1000px;
  height: 519px;
  left: 0;
  top: 882px;
  &.t2,
  &.t3 {
    top: 1037px;
    transition: top 4s;
  }
`;

const Birds = styled.img`
  width: 464px;
  height: 185px;
  left: 1044px;
  top: 431px;
  transform: rotate(7deg);
  &.t3 {
    @keyframes fly {
      50% {
        left: -573px;
        top: -126px;
      }
      100% {
        left: -573px;
        top: -126px;
      }
    }
    animation: fly 10s ease-in-out infinite;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > * {
    position: static !important; // Override CardTopInner.
  }
  & > * + * {
    margin-top: 40px;
  }

  opacity: 0;
  &.t2,
  &.t3 {
    opacity: 1;
    transition: 3s opacity 1.5s;
  }
`;

const Congratulations = styled.img`
  width: 582px;
  height: 72px;
`;

const LetTheAdventureBegin = styled.img`
  width: 553px;
  height: 48px;
`;

const NameMeasurer = styled.div`
  visibility: hidden;
  font: 140px 'Mr Dafoe';
`;

const Names140 = styled.div`
  left: 0px;
  width: 100%;
  text-align: center;
  top: 384px;
  font: 140px 'Mr Dafoe';
  line-height: 140px;
  color: hsla(5, 75%, 48%, 1);
`;

const Names100 = styled(Names140)`
  font-size: 100px;
  line-height: 100px;
`;

const rotate7c = keyframes`50% { transform: rotate(7deg); }`;

const rotate_5 = keyframes`50% { transform: rotate(-5deg); }`;

const LeafLeftPlain = styled.img`
  // Leaf 3
  animation: ${rotate7c} 4s infinite;
  width: 71px;
  height: 162px;
  left: 230px;
  top: 933px;
  &.t2,
  &.t3 {
    width: 192px;
    height: 436px;
    left: -90px;
    top: 1067px;
    transition: all 4s;
  }
`;

const LeafLeftVeined = styled.img`
  // Leaf 1
  animation: ${rotate_5} 4s infinite;
  width: 148px;
  height: 162px;
  left: 202px;
  top: 964px;
  &.t2,
  &.t3 {
    width: 400px;
    height: 436px;
    left: -166px;
    top: 1150px;
    transition: all 4s;
  }
`;

const LeafLeftStemmed = styled.img`
  // Leaf 2
  animation: ${rotate_5} 4s infinite;
  width: 117px;
  height: 208px;
  left: 189px;
  top: 872px;
  &.t2,
  &.t3 {
    width: 315px;
    height: 560px;
    left: -201px;
    top: 902px;
    transition: all 4s;
  }
`;

const LeafRightPlain = styled.img`
  // Leaf 3
  transform: scale(-1, 1);
  @keyframes LeafRightPlain {
    50% {
      transform: scale(-1, 1) rotate(7deg);
    }
  }
  animation: LeafRightPlain 4s infinite;
  width: 71px;
  height: 162px;
  left: 693px;
  top: 937px;
  &.t2,
  &.t3 {
    width: 192px;
    height: 436px;
    left: 829px;
    top: 1078px;
    transition: all 4s;
  }
`;

const LeafRightVeined = styled.img`
  // Leaf 1
  transform: scale(-1, 1);
  @keyframes LeafRightVeined {
    50% {
      transform: scale(-1, 1) rotate(-5deg);
    }
  }
  animation: LeafRightVeined 4s infinite;
  width: 148px;
  height: 162px;
  left: 635px;
  top: 958px;
  &.t2,
  &.t3 {
    width: 400px;
    height: 436px;
    left: 673px;
    top: 1134px;
    transition: all 4s;
  }
`;

const LeafRightStemmed = styled.img`
  // Leaf 2
  transform: scale(-1, 1) rotate(-21.5deg);
  @keyframes LeafRightStemmed {
    50% {
      transform: scale(-1, 1) rotate(-26.5deg);
    }
  }
  animation: LeafRightStemmed 4s infinite;
  width: 117px;
  height: 208px;
  left: 700px;
  top: 892px;
  &.t2,
  &.t3 {
    width: 315px;
    height: 560px;
    left: 848px;
    top: 956px;
    transition: all 4s;
  }
`;

const LeafTopPlain = styled.img`
  // Leaf 3
  transform: scale(-1, 1) rotate(90deg);
  @keyframes LeafTopPlain {
    50% {
      transform: scale(-1, 1) rotate(92deg);
    }
  }
  animation: LeafTopPlain 4s infinite;
  width: 71px;
  height: 162px;
  left: 995px;
  top: -227px;
  &.t2,
  &.t3 {
    width: 96px;
    height: 219px;
    left: 886px;
    top: -54px;
    transition: all 4s;
  }
`;

const LeafTopVeined = styled.img`
  // Leaf 1
  transform: scale(-1, 1) rotate(90deg);
  @keyframes LeafTopVeined {
    50% {
      transform: scale(-1, 1) rotate(95deg);
    }
  }
  animation: LeafTopVeined 4s infinite;

  &.t0,
  &.t1 {
    width: 148px;
    height: 162px;
    left: 977px;
    top: -208px;
  }

  &.t2,
  &.t3 {
    width: 201px;
    height: 219px;
    left: 862px;
    top: -28px;
    transition: all 4s;
  }
`;

const MaskDiv = styled.div`
  transform: scale(1); // Needed?
  box-shadow: inset 0px 0px 25px hsla(0, 0%, 0%, 0.4);
  box-sizing: border-box;
  pointer-events: none; // For easier selection of layers underneath.

  &.t2,
  &.t3 {
    transform: scale(2.6);
    transition: transform 4s;
  }
`;
function MaskWindow({ className }: { className: string }) {
  const width = 1600; // Must be > 530
  const style: React.CSSProperties = {
    border: `${(width - 530) / 2}px solid hsla(0, 0%, 100%, 1)`,
    left: (1000 - width) / 2,
    top: -6 - (width - 1200) / 2,
    width: width,
    height: width + 212,
    borderRadius: `${width / 2}px ${width / 2}px 0 0`
  };
  return <MaskDiv style={style} className={className} />;
}

// Decsion: Apply to the Inner Div instead?
const MaskFadeIn = styled.div`
  width: 100%;
  height: 100%;
  background: white;

  opacity: 1;
  &.t1,
  &.t2,
  &.t3 {
    opacity: 0;
    transition: opacity 3s;
  }
`;

export const BeachCard: React.FC<{ theme: IGiftWrapTheme; details: GiftWrapPremiumDetails }> = ({ details, theme }) => {
  const isFontLoaded = useLoadGoogleFont('Mr Dafoe');

  const nameMeasurer1 = useRef<HTMLDivElement>(null);
  const nameMeasurer2 = useRef<HTMLDivElement>(null);
  const [numLines, setNumLines] = useState(1);
  useEffect(() => {
    if (!nameMeasurer1.current) return;
    if (!nameMeasurer2.current) return;
    const totalWidth = nameMeasurer1.current.offsetWidth + 199 + nameMeasurer2.current.offsetWidth; // " & " is 199px

    if (totalWidth < 850) {
      // Cutoff is manually/visually determined.
      setNumLines(1);
      return;
    }

    if (nameMeasurer2.current.offsetWidth < 1000) {
      setNumLines(2);
      return;
    }

    setNumLines(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameMeasurer1.current, nameMeasurer2.current, isFontLoaded]);

  const [scene, restart] = useSceneTimer(
    2000, // 2s fade-in
    2000 + 4000 // 4s text appears, clouds move
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => restart(), []);

  return (
    <PremiumCardTemplate cardId="beach" details={details} theme={theme} showRestartDelay={11000} restart={restart}>
      <div style={{ width: '100%', height: '100%', background: 'rgb(212, 233, 233)' }}></div>
      <Sun src="https://withjoy.blob.core.windows.net/card/beach_sun.png" className={`t${scene}`} />
      <Mountain src="https://withjoy.blob.core.windows.net/card/beach_mountain.png" className={`t${scene}`} />
      <Wave1 src="https://withjoy.blob.core.windows.net/card/beach_wave.svg" className={`t${scene}`} />
      <Wave2 src="https://withjoy.blob.core.windows.net/card/beach_wave.svg" className={`t${scene}`} />
      <Wave3 src="https://withjoy.blob.core.windows.net/card/beach_wave.svg" className={`t${scene}`} />
      <Wave4 src="https://withjoy.blob.core.windows.net/card/beach_wave.svg" className={`t${scene}`} />
      <Cloud1 src="https://withjoy.blob.core.windows.net/card/beach_cloud1.png" className={`t${scene}`} />
      <Cloud2 src="https://withjoy.blob.core.windows.net/card/beach_cloud2.png" className={`t${scene}`} />
      <Sand src="https://withjoy.blob.core.windows.net/card/beach_beach.svg" className={`t${scene}`} />
      <Birds src="https://withjoy.blob.core.windows.net/card/beach_birds-flying.gif" className={`t${scene}`} />

      <NameMeasurer ref={nameMeasurer1}>{details.name1}</NameMeasurer>
      <NameMeasurer ref={nameMeasurer2}>{details.name2}</NameMeasurer>
      <TextContainer className={`t${scene}`} style={{ top: numLines === 1 ? 258 : numLines === 2 ? 188 : 168 }}>
        <Congratulations src="https://withjoy.blob.core.windows.net/card/beach_text-congrats.svg" />
        {numLines === 1 ? (
          <Names140>
            {details.name1} & {details.name2}
          </Names140>
        ) : numLines === 2 ? (
          <Names100>
            {details.name1}
            <br />& {details.name2}
          </Names100>
        ) : (
          <Names100>
            {details.name1}
            <br />&<br />
            {details.name2}
          </Names100>
        )}
        <LetTheAdventureBegin src="https://withjoy.blob.core.windows.net/card/beach_text-let.svg" />
      </TextContainer>

      <LeafLeftPlain src="https://withjoy.blob.core.windows.net/card/beach_leaf3.png" className={`t${scene}`} />
      <LeafLeftVeined src="https://withjoy.blob.core.windows.net/card/beach_leaf1.png" className={`t${scene}`} />
      <LeafLeftStemmed src="https://withjoy.blob.core.windows.net/card/beach_leaf2.png" className={`t${scene}`} />
      <LeafRightPlain src="https://withjoy.blob.core.windows.net/card/beach_leaf3.png" className={`t${scene}`} />
      <LeafRightVeined src="https://withjoy.blob.core.windows.net/card/beach_leaf1.png" className={`t${scene}`} />
      <LeafRightStemmed src="https://withjoy.blob.core.windows.net/card/beach_leaf2.png" className={`t${scene}`} />
      <LeafTopPlain src="https://withjoy.blob.core.windows.net/card/beach_leaf3.png" className={`t${scene}`} />
      <LeafTopVeined src="https://withjoy.blob.core.windows.net/card/beach_leaf1.png" className={`t${scene}`} />
      <MaskWindow className={`t${scene}`} />
      <MaskFadeIn className={`t${scene}`} />
    </PremiumCardTemplate>
  );
};
