import { styled, ButtonV2, Flex, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledFlex = styled(Flex)``;
const flexStyles: StyleSystemProps = {
  border: '1px solid',
  borderColor: 'mono5',
  borderRadius: pxToRem(4),
  alignItems: 'center',
  justifyContent: 'space-between'
};

export const StyledInput = styled(Flex)``;
const boxStyles: StyleSystemProps = {
  padding: 0,
  width: `${pxToRem(40)}`,
  justifyContent: 'center',
  fontSize: 13
};

export const StyledButtonV2 = styled(ButtonV2)<{ isDeleteIcon?: boolean }>`
  border: none;
  border-radius: 0;
  background-color: white;
  border-radius: ${pxToRem(4)};
  align-items: center;
  justify-content: center;
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  padding: 0;

  svg {
    width: ${pxToRem(13)};
    height: ${pxToRem(13)};
    path {
      fill: ${props => props.theme.colors.mono14};
    }
  }
  :hover {
    background-color: ${props => props.theme.colors.mono3};
    svg {
      width: ${pxToRem(13)};
      height: ${pxToRem(13)};
      path {
        fill: ${({ theme, isDeleteIcon }) => (isDeleteIcon ? theme.colors.negative5 : theme.colors.mono14)};
      }
    }
  }
  &:active {
    background-color: ${props => props.theme.colors.mono5};
    svg {
      width: ${pxToRem(13)};
      height: ${pxToRem(13)};
      path {
        fill: ${({ theme, isDeleteIcon }) => (isDeleteIcon ? theme.colors.negative7 : theme.colors.mono14)};
      }
    }
  }
`;

export const styles = {
  flex: flexStyles,
  box: boxStyles
};
