import { Box, ButtonV2, useToast } from '@withjoy/joykit';
import React, { FC, useCallback, useRef } from 'react';
import { StyledImage, styles } from './EventPhoto.styles';
import { TempSpinner } from '../../../../../../../shared/components/TempSpinner';
import { PhotoMenu } from '../../../../../../../shared/components/PhotoMenu';
import { ReactComponent as CameraIcon } from '@assets/icons/camera-outline.svg';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { useFilestack } from '@shared/utils/filestack';
import { useRemoveEventPagePhotoMutation, useSetEventPageAssetMutation } from '@graphql/generated';
import { ApolloCache } from '@apollo/client';
import { IconCard } from '../../EventPreview';
import { Photos } from '@withjoy/joykit/icons';

type Props = {
  eventPageId?: string;
  handleImageLoad?(): void;
  onUpdatePhoto?(previewUrl?: string): void;
  onDeletePhoto?(): void;
  photo?: string;
  showEdit?: boolean;
  firebaseId?: string;
  showEmptyCard?: boolean;
};

export const EventPhoto: FC<Props> = ({ eventPageId = '', handleImageLoad, photo, showEdit = false, onDeletePhoto, onUpdatePhoto, firebaseId = '', showEmptyCard = false }) => {
  const photoRef = useRef<HTMLImageElement>(null);
  const { getPhotoMenuTranslations } = useRegistryGuestTranslations();

  const { toast } = useToast();
  const { error, success } = getPhotoMenuTranslations();

  const updatePhotoInCache = useCallback(
    ({ cache, url }: { cache: ApolloCache<unknown>; url: string | null }) => {
      cache.modify({
        id: cache.identify({ __typename: 'EventPage', id: eventPageId }),
        fields: {
          photo(existingPhotoRef) {
            return url
              ? {
                  ...existingPhotoRef,
                  url: url
                }
              : null;
          }
        }
      });
    },
    [eventPageId]
  );

  const [setEventPageAssetMutation, { loading: isUpdatingPhoto }] = useSetEventPageAssetMutation({
    onCompleted: data => {
      onUpdatePhoto?.(data?.setEventPageAsset?.photo?.url);
      toast(success);
    },
    onError: () => {
      toast(error);
    }
  });

  const [removeEventPagePhoto, { loading: isDeletingPhoto }] = useRemoveEventPagePhotoMutation({
    onError: () => {
      toast(error);
    },
    onCompleted: () => {
      toast(success);
      onDeletePhoto?.();
    }
  });
  const isUpdating = isDeletingPhoto || isUpdatingPhoto;

  const handleDeletePhoto = useCallback(() => {
    removeEventPagePhoto({
      variables: { eventPageId },
      update: cache => {
        updatePhotoInCache({ cache, url: null });
      }
    });
  }, [eventPageId, removeEventPagePhoto, updatePhotoInCache]);

  const { open: openFilePicker, getPhotoPreviewUrl } = useFilestack({
    containerId: firebaseId || '',
    onUploadDone: async (res): Promise<void> => {
      const { filename, mimetype, size, url } = res.filesUploaded[0];
      const previewUrl = await getPhotoPreviewUrl({ url });
      const loadImageDimensions = (imageUrl: string): Promise<{ width: number; height: number }> =>
        new Promise(resolve => {
          const image = new Image();

          image.onload = () => {
            const height = image.height;
            const width = image.width;
            resolve({ width, height });
          };

          image.src = imageUrl;
        });

      const dimensions = await loadImageDimensions(previewUrl);

      setEventPageAssetMutation({
        variables: {
          id: eventPageId,
          payload: {
            assetId: filename,
            mimeType: mimetype,
            fileSize: size,
            width: dimensions.width,
            height: dimensions.height
          }
        },
        update: cache => {
          updatePhotoInCache({ cache, url: previewUrl });
        }
      });
    },
    maxFiles: 1
  });

  const handleOnUpdatePhoto = useCallback(() => {
    openFilePicker();
  }, [openFilePicker]);

  const placeholder = showEmptyCard ? <IconCard onClick={handleOnUpdatePhoto} text="Set your photo" icon={<Photos />} /> : <Box __css={styles.emptyImage} />;
  const isEmptyCardVisible = !photo && showEmptyCard;

  return (
    <Box
      maxWidth={{ md: isEmptyCardVisible ? '100%' : '36rem' }}
      width={{ _: '100%', md: isEmptyCardVisible ? '100%' : '25.6rem' }}
      position="relative"
      opacity={isUpdating ? '0.3' : '1'}
    >
      {photo ? <StyledImage ref={photoRef} src={photo} onLoad={handleImageLoad} /> : placeholder}
      {isUpdating && <TempSpinner position={'absolute'} />}
      {!isEmptyCardVisible && showEdit && (
        <Box position="absolute" left={0} top={0} margin={6}>
          <PhotoMenu handleUpdatePhoto={handleOnUpdatePhoto} handleDeletePhoto={handleDeletePhoto} photoExists>
            <ButtonV2 background="rgba(0, 0, 0, 0.80)" padding={4} borderRadius="100%" intent="neutral">
              <CameraIcon />
            </ButtonV2>
          </PhotoMenu>
        </Box>
      )}
    </Box>
  );
};
