import { useTranslation } from '@shared/core';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';

export const useCancelGiftIntentController = () => {
  const {
    sendGiftLater,
    noIntentToSendLater,
    context: { isAffiliate }
  } = useCheckoutFunnel(({ sendGiftLater, noIntentToSendLater, context }) => [sendGiftLater, noIntentToSendLater, context.isAffiliate]);
  const { productImageSrc, product, currency } = useGetProductData('externalCheckoutReview');
  const { registryItem } = useCheckoutInitDataContext('cancelGiftIntent');
  const { t2 } = useTranslation('guestRegistry');

  const translations = t2('checkoutDialog', 'screens', 'cancelGiftIntent');
  const isCashFundType = registryItem?.donationFund?.fundType === 'cash';

  return {
    isCashFundType,
    sendGiftLater,
    noIntentToSendLater,
    translations,
    isAffiliate,
    product,
    currency,
    productImageSrc
  };
};
