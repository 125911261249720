import React, { useEffect } from 'react';
import { IntegratedGiftCard, LayoutShell } from '../../components';
import { useExternalCheckoutInstructionsController } from './ExternalCheckoutInstructions.controller';
import { ExternalCheckoutInstructionsSelectors } from './ExternalCheckoutInstructions.selectors';
import { ExternalCheckoutInstructionsContent } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutInstructionsGiftWrap/ExternalCheckoutInstructionsContent';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';
import { Box, TextV2 } from '@withjoy/joykit';
import { Skeleton } from '@shared/components/Skeleton';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

export const ExternalCheckoutInstructionsGiftWrap = () => {
  const {
    registryItem,
    translations,
    handleToNext,
    isAffiliate,
    product,
    productImageSrc,
    formValues,
    currency,
    isGiftWrapPurchased,
    isLoading,
    currentOrderIdState,
    reserveItemTranslation
  } = useExternalCheckoutInstructionsController();
  const { alreadyReservedGiftViewed } = useGuestRegistryTelemetry();
  const isCashFundType = registryItem?.donationFund?.fundType === 'cash';

  useEffect(() => {
    if (currentOrderIdState === undefined) {
      alreadyReservedGiftViewed({ label: formValues.email ?? '' });
    }
  }, [alreadyReservedGiftViewed, currentOrderIdState, formValues.email]);

  return (
    <LayoutShell {...ExternalCheckoutInstructionsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement={isCashFundType ? 'float' : 'static'} />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} quantity={formValues.quantity} currency={currency} />
          ) : (
            <IntegratedGiftCard mobileRenderBehavior={isCashFundType ? 'fullBleed' : 'card'} />
          )}
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          {currentOrderIdState === null && (
            <Box>
              <Skeleton height={6} paddingRight={9} />
              <Skeleton marginTop={7} height={10} paddingRight={9} />
            </Box>
          )}
          {currentOrderIdState === undefined && (
            <Box>
              <TextV2 typographyVariant="hed4" paddingRight={9}>
                {reserveItemTranslation.title()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} paddingRight={9}>
                {reserveItemTranslation.subTitle()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} style={{ wordBreak: 'break-all' }}>
                {reserveItemTranslation.checkEmail({ email: formValues.email })}
              </TextV2>
            </Box>
          )}
          {typeof currentOrderIdState === 'string' && (
            <ExternalCheckoutInstructionsContent
              paymentMethod={formValues.paymentMethod}
              isLoading={isLoading}
              translations={translations}
              toNext={handleToNext}
              isGiftWrapPurchased={isGiftWrapPurchased}
              account={registryItem?.donationFund?.platform?.username || ''}
            />
          )}
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
