import { Box, LinkV1, styled, StyleSystemProps, TextV2 } from '@withjoy/joykit';

export const boxAddressStyles = (showWarning: boolean): StyleSystemProps => ({
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: 'mono3',
  alignSelf: 'center',
  textAlign: 'left',
  borderRadius: showWarning ? '4px 4px 0 0' : '4px',
  padding: 6,
  width: '100%'
});

export const StyledBoxAddress = styled(Box)``;

export const StyledAddressLink = styled(LinkV1)`
  color: ${props => props.theme.colors.brandGreen5};
  font-weight: 600;
`;

export const StyledBoxWarning = styled(TextV2)`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0 0 4px 4px;
  background-color: ${props => props.theme.colors.mono1};
  border: 1px solid ${props => props.theme.colors.mono3};
  border-top: 0;
  text-align: left;
  gap: 0.25rem;
`;
