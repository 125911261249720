import React from 'react';
import { DonationFundPaymentMethodEnum, CustomItemDataType } from '@graphql/generated';
import { IconV2, TextV2 } from '@withjoy/joykit';
import { DiscountCode } from '@withjoy/joykit/icons';
import { getPaymentMethodTypeName } from '@shared/utils/paymentMethodOptions';
import { StyledItemContainer, StyledPill, styles } from './GiftMethodBadge.styles';
import { useTranslation } from '@shared/core';
import { GiftMethodsBadgeSelectors } from './GiftMethodBadge.selectors';

interface GiftMethodIconWithLabelProps {
  icon: React.ReactNode;
  label: string;
}

interface GiftMethodBadgeProps {
  paymentMethod: DonationFundPaymentMethodEnum;
  paymentMethodUsername?: Maybe<string>;
  productType?: Maybe<CustomItemDataType>;
}

const GiftMethodIconWithLabel = ({ icon, label }: GiftMethodIconWithLabelProps) => {
  return (
    <StyledItemContainer __css={styles.itemContainer}>
      <IconV2 marginRight={3} size="sm">
        {icon}
      </IconV2>
      <TextV2 {...GiftMethodsBadgeSelectors.LabelText} typographyVariant="label2">
        {label}
      </TextV2>
    </StyledItemContainer>
  );
};

export const GiftMethodBadge: React.FC<GiftMethodBadgeProps> = ({ paymentMethod, paymentMethodUsername, productType }) => {
  const { t2 } = useTranslation('guestRegistry');
  const { cashCheckLabel, charityLabel } = t2('checkoutDialog', 'giftMethodBadge');

  switch (paymentMethod) {
    case DonationFundPaymentMethodEnum.cashapp:
    case DonationFundPaymentMethodEnum.venmo:
    case DonationFundPaymentMethodEnum.paypal:
      return (
        <StyledItemContainer __css={styles.itemContainer}>
          <StyledPill {...GiftMethodsBadgeSelectors.PillText} __css={styles.pillLight} marginRight={3}>
            {getPaymentMethodTypeName(paymentMethod)}
          </StyledPill>
          {paymentMethodUsername ? (
            <TextV2 {...GiftMethodsBadgeSelectors.UserNameText} typographyVariant="label2">
              {paymentMethod === DonationFundPaymentMethodEnum.cashapp ? '$' : ''}
              {paymentMethodUsername}
            </TextV2>
          ) : null}
        </StyledItemContainer>
      );

    case DonationFundPaymentMethodEnum.cashOrCheck:
      return <GiftMethodIconWithLabel icon={<DiscountCode />} label={cashCheckLabel} />;

    case DonationFundPaymentMethodEnum.other:
      return productType === CustomItemDataType.charity ? (
        <StyledItemContainer __css={styles.itemContainer}>
          <StyledPill {...GiftMethodsBadgeSelectors.PillText} __css={styles.pillDark}>
            {charityLabel}
          </StyledPill>
        </StyledItemContainer>
      ) : null;

    default:
      return null;
  }
};
