import React, { createContext, useEffect, useMemo } from 'react';
import { useDisclosure } from '@withjoy/joykit';
import { RegistryOrderFragment } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { noop } from 'lodash-es';

interface StateProps {
  isAddExternalOrderTrackingDialogOpen: boolean;
  orderForTrackingRequest?: RegistryOrderFragment;
  closeAddExternalOrderTrackingDialog: () => void;
}

const INITIAL_STATE: StateProps = {
  isAddExternalOrderTrackingDialogOpen: false,
  closeAddExternalOrderTrackingDialog: noop
};

export const OrderTrackingContext = createContext(INITIAL_STATE);

export const OrderTrackingProvider: React.FC<{ registries: RegistryList }> = ({ children, registries }) => {
  const purchasedOrderList = useMemo(() => {
    const purchasedOrders: Array<RegistryOrderFragment> = [];
    registries?.forEach(registry => {
      if (registry && registry.myPurchasedOrders.length > 0) {
        registry.myPurchasedOrders.forEach(order => {
          if (order) {
            purchasedOrders.push(order);
          }
        });
      }
    });
    return purchasedOrders;
  }, [registries]);

  const { isOpen: isAddExternalOrderTrackingDialogOpen, onOpen: openAddExternalOrderTrackingDialog, onClose: closeAddExternalOrderTrackingDialog } = useDisclosure();
  const enableOrderTrackingFeature = useFeatureValue('orderTrackingFeature');
  const { trackingRequestedForOrder } = useQueryParams();

  const orderForTrackingRequest = useMemo(() => {
    if (!trackingRequestedForOrder?.length) {
      return undefined;
    }
    return purchasedOrderList?.find(order => order.id === trackingRequestedForOrder);
  }, [purchasedOrderList, trackingRequestedForOrder]);

  useEffect(() => {
    if (enableOrderTrackingFeature && !!orderForTrackingRequest && orderForTrackingRequest?.lineItems?.length) {
      openAddExternalOrderTrackingDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderTrackingContext.Provider
      value={{
        isAddExternalOrderTrackingDialogOpen,
        orderForTrackingRequest,
        closeAddExternalOrderTrackingDialog
      }}
    >
      {children}
    </OrderTrackingContext.Provider>
  );
};
