import React from 'react';
import { Box, Flex, IconV2, TextV2, ButtonV2 } from '@withjoy/joykit';
import { ReactComponent as Warning } from '@assets/icons/Error-32.svg';
import { useCheckoutFunnel } from '../../hooks';
import { useTranslation } from '@shared/core';
import { CheckoutFailureCode } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/utils/constants';
import { ReactComponent as EmptyCartIcon } from '@apps/registry/admin/assets/empty-cart.svg';
import { ReactComponent as BadAddress } from '@apps/registry/admin/assets/Shipping.svg';

export const CreditCardCheckoutError: React.FC<{ creditCardError: string }> = ({ creditCardError }) => {
  const { closeDialog } = useCheckoutFunnel(({ closeDialog }) => [closeDialog]);
  const { t2 } = useTranslation('guestRegistry');
  const { title, description, close, badAddress, registryError } = t2('checkoutDialog', 'screens', 'creditCardCheckoutError');
  let errorMessage;
  let checkoutErrorIcon;
  switch (creditCardError) {
    case CheckoutFailureCode.BAD_ADDRESS:
      errorMessage = badAddress;
      checkoutErrorIcon = <BadAddress />;
    case CheckoutFailureCode.REGISTRY_ERROR:
      errorMessage = registryError;
      checkoutErrorIcon = <EmptyCartIcon />;
    default:
      errorMessage = description;
      checkoutErrorIcon = (
        <IconV2 size="xxl" color="negative6">
          <Warning />
        </IconV2>
      );
  }
  return (
    <Box paddingY={8}>
      <Flex justifyContent="center">{checkoutErrorIcon}</Flex>
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Flex justifyContent="center" marginBottom={8}>
          <TextV2 textAlign="center" typographyVariant="display1" color="mono14">
            {title}
          </TextV2>
        </Flex>
        <Flex paddingX={8} justifyContent="center" marginBottom={8}>
          <TextV2 textAlign="center" typographyVariant="body3" color="mono12">
            {errorMessage}
          </TextV2>
        </Flex>
        <Box>
          <ButtonV2 onClick={closeDialog} size="lg" intent="neutral" shape="rounded">
            {close}
          </ButtonV2>
        </Box>
      </Flex>
    </Box>
  );
};
