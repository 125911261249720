import React from 'react';
import { Box, Flex, IconV2, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

type Props = {
  icon: React.RefAttributes<SVGSVGElement>;
  text: string;
  content: React.ReactNode;
};

export const MetadataBase = ({ icon, text, content }: Props) => {
  return (
    <Box>
      <Flex alignItems="center" columnGap={3} marginBottom={pxToRem(6)}>
        <IconV2 size="sm">{icon}</IconV2>
        <TextV2 typographyVariant="button1">{text}</TextV2>
      </Flex>
      <Box paddingLeft={6}>{content}</Box>
    </Box>
  );
};
