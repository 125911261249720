import { styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledImage = styled.img`
  width: 100%;
  max-height: 100%;
  min-height: 12.5rem;
  min-width: 100%;
  background-color: ${props => props.theme.colors.mono5};
  border-radius: ${props => props.theme.radii[2]};
  object-fit: contain;
`;

const emptyImage = {
  width: { md: pxToRem(410), _: '100%' },
  height: pxToRem(273),
  borderRadius: 2,
  backgroundColor: 'mono2'
};

export const styles = {
  emptyImage
};
