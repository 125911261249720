import React from 'react';
import { StyledTitleText, StyledSubText, StyledForm, formControlOverrides } from '../RegistryProtected.styles';
import { ButtonV2, CancelButton, DialogV2, Flex, FormControl, InputV2 } from '@withjoy/joykit';
import { ContactSuccess } from './ContactSuccess';
import { useContactHostController } from './ContactHost.controller';

interface Props {
  setShowContactHost: (val: boolean) => void;
  eventId?: string;
  eventHandle: string;
}

export const ContactHost: React.FC<Props> = ({ setShowContactHost, eventId, eventHandle }) => {
  const {
    contactHost,
    contactHostFormik,
    contactSuccess,
    loading,
    goBack,
    title,
    subtitle,
    namePlaceholder,
    emailPlaceholder,
    actionButton,
    emailInputError
  } = useContactHostController(eventId, eventHandle, setShowContactHost);
  return (
    <DialogV2 isOpen={true} size={'sm'}>
      <DialogV2.Body paddingX={[6, 8]} paddingY={8}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          {contactSuccess ? (
            <ContactSuccess website={eventHandle} />
          ) : (
            <>
              <StyledTitleText marginBottom={5} marginTop={5}>
                {title}
              </StyledTitleText>
              <StyledSubText marginBottom={8}>{subtitle}</StyledSubText>
              <StyledForm>
                <FormControl label="name" overrides={formControlOverrides} error={contactHostFormik.getFieldMeta('name').error}>
                  <InputV2 placeholder={namePlaceholder} isInvalid={!!contactHostFormik.getFieldMeta('name').error} {...contactHostFormik.getFieldProps('name')} />
                </FormControl>
                <FormControl label="email" marginBottom={8} error={contactHostFormik.getFieldMeta('email').error ? emailInputError : undefined} overrides={formControlOverrides}>
                  <InputV2 placeholder={emailPlaceholder} isInvalid={!!contactHostFormik.getFieldMeta('email').error} {...contactHostFormik.getFieldProps('email')} />
                </FormControl>
                <ButtonV2
                  loading={loading}
                  intent="primary"
                  fullWidth={true}
                  marginBottom={8}
                  onClick={contactHost}
                  disabled={!contactHostFormik.isValid || !contactHostFormik.dirty || loading}
                >
                  {actionButton}
                </ButtonV2>
                <CancelButton onClick={goBack} fullWidth={true}>
                  Back
                </CancelButton>
              </StyledForm>
            </>
          )}
        </Flex>
      </DialogV2.Body>
    </DialogV2>
  );
};
