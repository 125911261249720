import { FormControl as FormControlJoyKit, StyleSystemProps } from '@withjoy/joykit';
import React, { ComponentProps } from 'react';

const labelStyles: StyleSystemProps = {
  typographyVariant: 'label3'
};

export const FormControl = (props: ComponentProps<typeof FormControlJoyKit>) => (
  <FormControlJoyKit
    overrides={{
      Label: {
        props: labelStyles
      },
      ...props.overrides
    }}
    {...props}
  />
);
