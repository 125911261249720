import { CustomItemDataType } from '@graphql/generated';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useTranslation } from '@shared/core';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { useCheckoutTelemetryData, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { addAction } from '@shared/utils/logger';

export const useExternalCheckoutConfirmAndNotifyController = () => {
  const {
    formValues,
    initiateCancelProcess,
    toNext,
    getHelp,
    context: { isAffiliate, isGroupGifting }
  } = useCheckoutFunnel(({ formValues, initiateCancelProcess, toNext, getHelp, context }) => [
    formValues,
    initiateCancelProcess,
    toNext,
    getHelp,
    context.isAffiliate,
    context.isGroupGifting
  ]);
  const { product, productImageSrc, currency } = useGetProductData('externalCheckoutInstructions');
  const { registryItem } = useCheckoutInitDataContext('externalCheckoutConfirmAndNotify');
  const { purchaseConfirmationResponded, shoppingCartMakePurchaseClick } = useGuestRegistryTelemetry();
  const { t } = useTranslation('guestRegistry');

  const isCharityFundType = CustomItemDataType.charity === registryItem.donationFund?.fundType;
  const translations = t('checkoutDialog', 'screens', 'externalCheckoutConfirmAndNotify');
  const { eventId, registryId, registryItemId, productId, reservedQty, priceValueInMinorUnits, typeOfItem, productTitle, priceCurrencyCode } = useCheckoutTelemetryData();

  const handleAffirmativeButton = () => {
    const purchaseConfirmationRespondedData = {
      productTitle,
      registryItemId,
      registryId,
      isGroupGiftingEnabled: isGroupGifting
    };
    purchaseConfirmationResponded(true, purchaseConfirmationRespondedData);
    addAction('purchaseConfirmationResponded', {
      ...purchaseConfirmationRespondedData,
      yesButtonClicked: true
    });

    const shoppingCartMakePurchaseClickData = {
      eventId,
      productId,
      registryItemId,
      reservedQty,
      priceValueInMinorUnits,
      priceCurrencyCode,
      typeOfItem,
      buttonLabel: translations.affirmativeButton(),
      productTitle,
      registryId,
      giftGiverName: formValues.name,
      giftGiverEmail: formValues.email,
      isGroupGiftingEnabled: isGroupGifting
    };
    shoppingCartMakePurchaseClick(shoppingCartMakePurchaseClickData);
    addAction('shoppingCartMakePurchaseClick', shoppingCartMakePurchaseClickData);
    toNext();
  };

  return {
    formValues,
    initiateCancelProcess,
    toNext: handleAffirmativeButton,
    getHelp,
    isCharityFundType,
    translations,
    isAffiliate,
    product,
    currency,
    productImageSrc
  };
};
