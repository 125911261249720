import { Flex, SpacingStack, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledListContainer = styled(SpacingStack)``;
export const StyledListItemContainer = styled(Flex)``;
export const StyledListItemNumber = styled(Flex)``;

const listContainerStyles: StyleSystemProps = {
  paddingY: 7
};

const listItemContainerStyles: StyleSystemProps = {
  alignItems: 'center'
};

const listItemNumberStyles: StyleSystemProps = {
  alignItems: 'center',
  border: `${pxToRem(2)} solid`,
  borderColor: 'mono14',
  borderRadius: 5,
  color: 'mono14',
  flexShrink: 0,
  height: 8,
  justifyContent: 'center',
  marginRight: 5,
  typographyVariant: 'hed3',
  width: 8
};

const listItemNumberCompletedStyles: StyleSystemProps = {
  backgroundColor: 'mono14',
  color: 'white'
};

export const styles = {
  listContainer: listContainerStyles,
  listItemContainer: listItemContainerStyles,
  listItemNumber: listItemNumberStyles,
  listItemNumberCompleted: listItemNumberCompletedStyles
};
