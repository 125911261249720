import React from 'react';
import { StyledTitleText, StyledSubText, StyledForm } from './RegistryProtected.styles';
import { Flex, Box, Spinner, ButtonV2, InputV2, FormControl, DialogV2 } from '@withjoy/joykit';
import { ReactComponent as LockIcon } from '@assets/icons/heart-lock.svg';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { ContactHost } from './ContactHost';
import { useRegistryProtectedController } from './RegistryProtected.controller';

interface Props {
  eventId?: string;
  eventHandle: string;
}

export const RegistryProtected: React.FC<Props> = ({ eventId, eventHandle }) => {
  const { getRegistryProtectedTranslations } = useRegistryGuestTranslations();
  const { title, subtitle, inputPlaceholder, actionButton, signInButton, contactButton } = getRegistryProtectedTranslations();
  const { showContactHost, setShowContactHost, registryProtectedFormik, handleSubmit, handleSigninClick, joinEventLoading } = useRegistryProtectedController(eventId, eventHandle);
  const inputError = registryProtectedFormik.errors.password;

  return showContactHost ? (
    <ContactHost setShowContactHost={setShowContactHost} eventId={eventId} eventHandle={eventHandle} />
  ) : (
    <DialogV2 id="registryIsPrivate" isOpen={true} size="sm">
      <DialogV2.Body paddingX={[6, 8]} paddingY={8}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Box marginBottom={22} color="#000000">
            <LockIcon />
          </Box>
          <StyledTitleText typographyVariant="hed4" marginBottom={5}>
            {title}
          </StyledTitleText>
          <StyledSubText typographyVariant="body4" marginBottom={8}>
            {subtitle}
          </StyledSubText>
          <StyledForm onSubmit={handleSubmit}>
            <FormControl
              label="password"
              error={inputError}
              overrides={{
                Label: {
                  props: {
                    visibility: 'hidden'
                  }
                }
              }}
              marginBottom={8}
            >
              <InputV2 type="text" placeholder={inputPlaceholder} {...registryProtectedFormik.getFieldProps('password')} />
            </FormControl>
            <ButtonV2 marginBottom={8} intent="primary" fullWidth disabled={!registryProtectedFormik.isValid || !registryProtectedFormik.dirty || joinEventLoading} type="submit">
              {joinEventLoading ? <Spinner /> : actionButton}
            </ButtonV2>
          </StyledForm>
          <Flex width="100%" justifyContent="center">
            <Box marginRight={3}>
              <ButtonV2 variant="ghost" color="black" onClick={handleSigninClick}>
                {signInButton}
              </ButtonV2>
            </Box>
            <ButtonV2
              color="black"
              onClick={() => {
                setShowContactHost(true);
              }}
              variant="ghost"
            >
              {contactButton}
            </ButtonV2>
          </Flex>
        </Flex>
      </DialogV2.Body>
    </DialogV2>
  );
};
