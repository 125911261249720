import { useState } from 'react';

export type ToastVariableProps = {
  notificationText: string;
  isPositionTop?: boolean;
  noAnimation?: boolean;
};

const initValueToastVariable = {
  notificationText: ''
};

export const useGuestRegistryController = () => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [toast, setToast] = useState<ToastVariableProps>(initValueToastVariable);

  const openToast = (toast: ToastVariableProps) => {
    setToast(toast);
    setNotificationVisible(true);
  };

  return {
    toast: { ...toast, notificationVisible, setNotificationVisible },
    openToast
  };
};
