import { Flex, DialogV2, ButtonV2, TextV2, CancelButton } from '@withjoy/joykit';
import React from 'react';
import { useYourRegistryTranslations } from '@apps/registry/admin/routes/YourRegistry/YourRegistry.i18n';
import EmailPreview from './images/email-tracking-info-request.png';

export interface PreviewSendEmailReminderDialogProps
  extends Readonly<{
    isOpen: boolean;
    handleOnCancelClick: () => void;
    handleOnSendClick: () => void;
  }> {}

export const PreviewSendEmailReminderDialog: React.FC<PreviewSendEmailReminderDialogProps> = ({ handleOnCancelClick, handleOnSendClick, isOpen }) => {
  const { getPreviewSendEmailReminderDialogTranslations } = useYourRegistryTranslations();
  const { header, body, sampleText, cancelButton, confirmButton } = getPreviewSendEmailReminderDialogTranslations();
  return (
    <DialogV2 isOpen={isOpen} disableAutoFocus={true} disableCloseOnOutsideClick={true} disableCloseOnEscapeKeyClick={true} enableDividers={true} size="xl">
      <DialogV2.Header typographyVariant="hed2">{header}</DialogV2.Header>
      <DialogV2.Body>
        <Flex flexDirection="column" alignItems="start">
          <TextV2 marginBottom={30} typographyVariant="body1" component="p">
            {body}
          </TextV2>
          <TextV2 marginBottom={30} typographyVariant="hed1" component="p">
            {sampleText}
          </TextV2>
          <Flex flexDirection="column" alignItems="center" width="100%">
            <img src={EmailPreview} alt={sampleText} width={273} />
          </Flex>
        </Flex>
      </DialogV2.Body>
      <DialogV2.Footer flexDirection={['column-reverse', null, 'row']} alignItems={['initial', null, 'center']}>
        <CancelButton onClick={handleOnCancelClick}>{cancelButton}</CancelButton>
        <ButtonV2 intent="primary" marginLeft={[0, null, 6]} marginBottom={[6, null, 0]} onClick={() => handleOnSendClick()}>
          {confirmButton}
        </ButtonV2>
      </DialogV2.Footer>
    </DialogV2>
  );
};
