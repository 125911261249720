import React from 'react';
import { Stack, Flex, Box } from '@withjoy/joykit';
import { StyledHeadText, StyledCancelLink, StyledText, StyledSuccessGuestButton, StyledCompletePurchaseImage, StyledColumnsContribution } from '../Purchase.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useTranslation } from '@shared/core';
import { usePageTitleConfirmPayment } from '@apps/registry/guest/routes/Purchase/components/titleHandler';

interface ContributePurchaseProps
  extends Readonly<{
    handleUndoClick: () => void;
    purchased?: boolean;
    productImage: string;
    handleConfirmationClick: () => void;
    onBackClick: () => void;
    isInModal: boolean;
  }> {}

export const ContributePurchase: React.FC<ContributePurchaseProps> = ({ handleUndoClick, productImage, handleConfirmationClick, onBackClick, isInModal }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { t } = useTranslation('guestRegistry');
  const { somethingWrongTitle, somethingWrongAction } = t('confirmationPage');
  usePageTitleConfirmPayment();
  const { contributeConfirmationHeader, contributeConfirmationBody, contributeConfirmationButtonText, contributeCancelQuestion, contributeCancelText } = t('contributeToFund');

  return (
    <StyledColumnsContribution isMobile={isMobile} isInModal={isInModal}>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={isInModal ? undefined : 'mono1'} marginBottom={isInModal ? '24px' : undefined}>
        <Box width={[isInModal ? '120px' : '100%', null, isInModal ? '120px' : '100%']} height={[isInModal ? '120px' : '254px', null, isInModal ? '120px' : '100%']}>
          <StyledCompletePurchaseImage backgroundSize="cover" productImage={productImage} />
        </Box>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={['24px', null, '0px 48px']}>
        <Box width={['100%', null, '375px']}>
          <Stack spacing={7}>
            <Stack spacing={5}>
              <StyledHeadText component="p" textAlign="center">
                {contributeConfirmationHeader()}
              </StyledHeadText>
              <Box width="312px" style={{ alignSelf: 'center' }}>
                <StyledText component="p" textAlign="center">
                  {contributeConfirmationBody()}
                </StyledText>
              </Box>
              <Box paddingTop={6}>
                <StyledSuccessGuestButton intent="secondary" fill={true} onClick={handleConfirmationClick}>
                  {contributeConfirmationButtonText()}
                </StyledSuccessGuestButton>
              </Box>
            </Stack>
            <Stack spacing={4} paddingTop={3}>
              <StyledText textAlign="center">{contributeCancelQuestion()}</StyledText>
              <StyledCancelLink onClick={handleUndoClick}>{contributeCancelText()}</StyledCancelLink>
            </Stack>
            <Box display="flex" justifyContent="center" alignItems="center">
              <StyledText component="p" marginRight="4px">
                {somethingWrongTitle()}
              </StyledText>
              <StyledCancelLink component="p" onClick={onBackClick}>
                {somethingWrongAction()}
              </StyledCancelLink>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </StyledColumnsContribution>
  );
};
