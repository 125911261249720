import React from 'react';
import { Box } from '@withjoy/joykit';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { PageHeader } from '../../components/PageHeader';
import { usePurchaseController } from './Purchase.Controller';
import { Contribute } from './components/Contribute';
import { ErrorSplash } from '@shared/components';
import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core/i18n';
import { InitialContent } from '@apps/registry/guest/routes/Purchase/components/InitialContent';
import { PurchaseConfirmation } from './components/PurchaseConfirmation';
import { Thanks } from './components/Thanks';
import { Undo } from './components/Undo';
import { ContributePurchase } from './components/ContributePurchase';
import { useNativeState } from '@shared/core/native';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useGuestRegistryState } from '@apps/registry/guest/state';

interface PurchaseProps
  extends Readonly<{
    eventHandle: string;
    registry: RegistryList;
    isAdmin: boolean;
    shippingAddress: ShippingAddressFragment | null;
    isRegistryHidden: boolean;
    layoutOption?: 'customItem' | 'donationFund';
    eventId?: string;
  }> {}

export const Purchase: React.FC<PurchaseProps> = ({ eventId, eventHandle, shippingAddress, registry, isAdmin, isRegistryHidden, layoutOption }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const isDonationFund = !!(layoutOption && layoutOption === 'donationFund');
  const { isNative } = useNativeState();
  const {
    handleUndo,
    handleClickProduct,
    handleConfirmationClick,
    type,
    registryImage,
    storeName,
    productImage,
    productImageSmall,
    item,
    quantity,
    reservationState,
    showUndoConfirmationScreen,
    handleCancelUndo,
    isErrorState
  } = usePurchaseController({ eventId, isAdmin, registry, isDonationFund, eventHandle, isInModal: false });

  const { order } = useGuestRegistryState();
  const onBackClick = () => {
    withWindow(window => {
      window.location.href = window.location.origin + `/${eventHandle}/registry`;
    });
  };

  const { t2 } = useTranslation('errors');

  const getContributionErrorTranslations = () => {
    return t2('contributionError');
  };
  const getRegistryHiddenErrorTranslations = () => {
    return t2('registryHidden');
  };

  const { t2: undoTranslations } = useTranslation('guestRegistry');
  const { thanksForBuying, contribute, purchaseGift } = undoTranslations('undoPurchase');

  const undoMethodText = reservationState === 'purchased' ? thanksForBuying : isDonationFund ? contribute : purchaseGift;

  if (isErrorState) {
    return (
      <ErrorSplash
        customError={isRegistryHidden ? getRegistryHiddenErrorTranslations() : getContributionErrorTranslations()}
        handleAction={isRegistryHidden ? undefined : onBackClick}
      />
    );
  }

  if (!type) {
    onBackClick();
  }

  const isVenmo = item?.donationFund?.platform?.type === 'venmo';

  const renderContent = () => {
    if (reservationState === 'initial' && isDonationFund) {
      return (
        <Contribute
          handleClickProduct={handleClickProduct}
          handleUndoClick={showUndoConfirmationScreen}
          handleConfirmationClick={handleConfirmationClick}
          quantity={quantity}
          item={item}
          productImage={productImage}
          isMobile={!!isMobile}
          shippingAddress={shippingAddress}
          isVenmo={isVenmo}
          isInModal={false}
          orderId={order?.orderId}
          orderDonationPlatform={order?.donationFundPlatformType}
        />
      );
    }
    if (reservationState === 'confirm' && isDonationFund) {
      return (
        <ContributePurchase
          productImage={productImage}
          handleUndoClick={showUndoConfirmationScreen}
          handleConfirmationClick={handleConfirmationClick}
          onBackClick={onBackClick}
          isInModal={false}
        />
      );
    } else if (reservationState === 'initial') {
      return (
        <InitialContent
          showUndoConfirmationScreen={showUndoConfirmationScreen}
          item={item}
          productImageSmall={productImageSmall}
          productImage={productImage}
          registryImage={registryImage}
          shippingAddress={shippingAddress}
          handleClickProduct={handleClickProduct}
          storeName={storeName}
        />
      );
    } else if (reservationState === 'confirm') {
      return (
        <PurchaseConfirmation
          shippingAddress={shippingAddress}
          handleUndoClick={showUndoConfirmationScreen}
          onBackClick={onBackClick}
          productImage={productImage}
          handleConfirmationClick={handleConfirmationClick}
        />
      );
    } else if (reservationState === 'purchased') {
      return (
        <Thanks
          reservationState={reservationState}
          isDonationFund={isDonationFund}
          onBackClick={onBackClick}
          handleUndo={handleUndo}
          handleCancelUndo={handleCancelUndo}
          productImage={productImage}
          showUndoConfirmationScreen={showUndoConfirmationScreen}
        />
      );
    } else if (reservationState === 'undo' || reservationState === 'undoSuccess') {
      return (
        <Undo
          handleUndo={handleUndo}
          handleCancelUndo={handleCancelUndo}
          methodText={undoMethodText}
          isUndoConfirmationShown={reservationState === 'undoSuccess'}
          onBackClick={onBackClick}
          isMobile={!!isMobile}
          productImage={productImage}
        />
      );
    }
    return null;
  };
  return (
    <Box>
      {!isDonationFund && <PageHeader isNative={isNative} isAdmin={isAdmin && !isDonationFund} website={eventHandle} />}
      {renderContent()}
    </Box>
  );
};
