import { useTranslation } from '@shared/core';
import { HeartFilled } from '@withjoy/joykit/icons';
import React, { FC } from 'react';
import { MustHaveContainer, StyledLabel, styles } from './MustHaveChip.styles';
import { BoxProps } from '@withjoy/joykit';

type MusthaveChipProps = {
  style?: BoxProps;
};

const MustHaveChip: FC<MusthaveChipProps> = ({ style = {} }) => {
  const { t } = useTranslation('guestRegistry');
  const { ourMostWanted } = t('productTile');
  return (
    <MustHaveContainer __css={styles.mustHaveContainer} {...style}>
      <HeartFilled color="white" size={13} />
      <StyledLabel typographyVariant="label2" __css={styles.label}>
        {ourMostWanted()}
      </StyledLabel>
    </MustHaveContainer>
  );
};

export default MustHaveChip;
