import React from 'react';
import { DialogV2, TextV2, Box, Flex, LinkV2, ButtonV2, InputV2, FormControl, SpacingStack } from '@withjoy/joykit';
import { useAddExternalOrderTrackingDialogController } from './AddExternalOrderTrackingDialog.controller';
import { StyledProductPhoto, StyledProductPhotoContainer, styles } from './AddExternalOrderTrackingDialog.style';
import { addRendition } from '@shared/utils/photoRendition';
import { pxToRem } from '@withjoy/joykit/theme';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';
import { PreviewSendEmailReminderDialog } from '@apps/registry/admin/routes/YourRegistry/components/PreviewSendEmailReminder/PreviewSendEmailReminderDialog';
import { usePreviewSendEmailReminderController } from '@apps/registry/admin/routes/YourRegistry/components/PreviewSendEmailReminder/PreviewSendEmailReminder.controller';

export interface AddExternalOrderTrackingDialogProps
  extends Readonly<{
    openToast?: (toast: ToastVariableProps) => void;
    isOpen: boolean;
    onClose: () => void;
    orderId: string;
    orderQuantity: string;
    orderTitle: string | undefined;
    orderPhotoUrl: string | undefined;
    guestName?: string;
    orderNumber: Maybe<string>;
    trackingNumber: Maybe<string>;
    email: string;
    isPurchased: boolean;
    registryItemId?: string;
    onEmailSendCallback?: (wasSent: boolean) => void;
  }> {}

/**
 * When `Dialog`s are not open, its children are completely unmounted.
 * This separate internal component allows us to safely initialize/tear down the state.
 */
const DialogInternal: React.FC<AddExternalOrderTrackingDialogProps> = props => {
  const {
    openToast,
    onClose,
    orderId,
    orderQuantity,
    orderTitle,
    orderPhotoUrl,
    guestName,
    orderNumber,
    trackingNumber,
    isPurchased,
    email,
    registryItemId,
    onEmailSendCallback
  } = props;
  const { translations, formik, loading } = useAddExternalOrderTrackingDialogController({
    openToast,
    orderTitle,
    orderId,
    onClose,
    orderNumber,
    trackingNumber,
    guestName
  });
  const { isOpen, handleOnOpenDialog, handleOnCancelClick, handleOnSendClick } = usePreviewSendEmailReminderController(
    email,
    registryItemId || '',
    orderTitle || '',
    isPurchased,
    orderId
  );
  const {
    externalTrackingInfoDialogTitle,
    externalTrackingInfoDialogDetails,
    informationProvided,
    requestOrderTracking,
    cancel,
    save,
    trackingNumber: trackingNumberLabel,
    orderID,
    quantity,
    testLink
  } = translations;

  const onEmailSend = (wasSent: boolean) => {
    if (wasSent) {
      onClose();
    }
    onEmailSendCallback && onEmailSendCallback(wasSent);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogV2.Header>
        <TextV2 typographyVariant="hed4">{externalTrackingInfoDialogTitle()}</TextV2>
      </DialogV2.Header>
      <DialogV2.Body>
        <SpacingStack spacing={5}>
          {guestName && <TextV2 typographyVariant="body2">{informationProvided({ guestName: guestName })}</TextV2>}
          <SpacingStack stack="horizontal" spacing={5} paddingBottom={5}>
            {orderPhotoUrl && (
              <Box textAlign="center" backgroundColor="mono2" width={pxToRem(80)} height={pxToRem(80)} flexShrink={0}>
                <StyledProductPhotoContainer __css={styles.productPhotoWrapper}>
                  <StyledProductPhoto __css={styles.productPhoto} backgroundImage={`url('${addRendition({ url: orderPhotoUrl, renditionSize: 'medium', isProduct: true })}')`} />
                </StyledProductPhotoContainer>
              </Box>
            )}
            <Box>
              <TextV2 typographyVariant="hed2">{orderTitle}</TextV2>
              <TextV2 typographyVariant="body1" color="gray7">
                {quantity({ qty: orderQuantity })}
              </TextV2>
            </Box>
          </SpacingStack>
          <FormControl
            label={<TextV2 typographyVariant="label3">{orderID()}</TextV2>}
            marginTop={4}
            isInvalid={!!formik.getFieldMeta('orderNumber').error}
            error={
              <TextV2 typographyVariant="body2" marginTop={2} color={'inputBorderError'} hidden={!formik.getFieldMeta('orderNumber').error}>
                {formik.getFieldMeta('orderNumber').error}
              </TextV2>
            }
          >
            <InputV2 tabIndex={0} {...formik.getFieldProps('orderNumber')} />
          </FormControl>
          <FormControl
            label={<TextV2 typographyVariant="label3">{trackingNumberLabel()}</TextV2>}
            marginTop={4}
            isDisabled={!formik.getFieldMeta('orderNumber').value}
            isInvalid={!!formik.getFieldMeta('trackingNumber').error}
            caption={
              formik.getFieldMeta('trackingNumber').value === '' ? (
                <TextV2 typographyVariant="label2" marginTop={2} color="mono10">
                  {testLink()}
                </TextV2>
              ) : (
                <LinkV2
                  target="__blank"
                  typographyVariant="label2"
                  marginTop={2}
                  href={`https://www.google.com/search?q=${formik.getFieldMeta('trackingNumber').value}`}
                  textDecoration="none"
                >
                  {testLink()}
                </LinkV2>
              )
            }
          >
            <InputV2 {...formik.getFieldProps('trackingNumber')} />
          </FormControl>
          <>
            <TextV2 typographyVariant="body1" color="gray12">
              {externalTrackingInfoDialogDetails()}
              <LinkV2 typographyVariant="body1" textDecoration="none" onClick={handleOnOpenDialog}>
                {requestOrderTracking()}
              </LinkV2>
            </TextV2>
            <PreviewSendEmailReminderDialog handleOnCancelClick={handleOnCancelClick} handleOnSendClick={() => handleOnSendClick(onEmailSend)} isOpen={isOpen} />
          </>
        </SpacingStack>
      </DialogV2.Body>
      <DialogV2.Footer>
        <Flex justifyContent="flex-end" flexDirection={['column-reverse', null, 'row']} width={'100%'}>
          <ButtonV2 color="mono14" variant="ghost" onClick={onClose} marginY={[4, 'unset']} marginX={['unset', 4]}>
            {cancel()}
          </ButtonV2>
          <ButtonV2 loading={loading} intent="primary" type="submit">
            {save()}
          </ButtonV2>
        </Flex>
      </DialogV2.Footer>
    </form>
  );
};

export const AddExternalOrderTrackingDialog: React.FC<AddExternalOrderTrackingDialogProps> = props => {
  const { isOpen, onClose } = props;

  return (
    <DialogV2 id="AddExternalOrderTrackingDialog" onClose={onClose} isOpen={isOpen} size={'lg'} enableDividers={true}>
      <DialogInternal {...props} />
    </DialogV2>
  );
};
