import { animationTransition } from '@shared/utils/animationTransition';
import { mergeRefs } from '@shared/utils/hooks/setRef';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTransition } from 'react-spring';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';
import { SHOULD_LOOP, SWIPE_BOUNDARY_RATIO, SWIPE_TRANSITION_DURATION } from './ProductImageGallery.constants';
import { getMediaAssetImgUrl } from './utils';

const MAX_THUMBNAIL_COUNT: number = 7;

export interface UseProductGalleryControllerArgs
  extends Readonly<{
    handleClickMediaAsset: (mediaAsset: CatalogProductMediaAsset) => void;
    mediaAssets: Array<CatalogProductMediaAsset>;
    selectedMediaAsset: Maybe<CatalogProductMediaAsset>;
  }> {}

export type UseProductGalleryControllerReturn = ReturnType<typeof useProductGalleryCarouselController>;

export const useProductGalleryCarouselController = ({ handleClickMediaAsset, mediaAssets, selectedMediaAsset }: UseProductGalleryControllerArgs) => {
  const [activeThumbnailIndex, setActiveThumbnailIndex] = useState(0);
  const isMobile = useIsMobileScreen();
  const activeImageIndexRef = useRef<number>(0);
  const imageGalleryContainerRef = useRef<HTMLDivElement>(null);

  const mediaAssetsList = useMemo(() => {
    return mediaAssets.slice(0, MAX_THUMBNAIL_COUNT);
  }, [mediaAssets]);
  const imageCount = mediaAssetsList.length;

  useEffect(() => {
    return () => {
      setActiveThumbnailIndex(0);
    };
  }, [mediaAssetsList]);

  const handleClickThumbnail = useCallback(
    (mediaAsset: CatalogProductMediaAsset, index: number) => {
      handleClickMediaAsset(mediaAsset);
      setActiveThumbnailIndex(index);
    },
    [handleClickMediaAsset]
  );

  const stepToImage = useCallback(
    (step: number) => {
      const rawNextIndex = activeThumbnailIndex + step;
      let nextActiveThumbnailIndex: number;
      if (SHOULD_LOOP) {
        // If approaching trailing edge from left, set index to end
        nextActiveThumbnailIndex = rawNextIndex < 0 ? imageCount - 1 : rawNextIndex;
        // If approaching leading edge from right, set index to start
        nextActiveThumbnailIndex = nextActiveThumbnailIndex >= imageCount ? 0 : nextActiveThumbnailIndex;
      } else {
        // If approaching trailing edge from left, set index to end
        nextActiveThumbnailIndex = rawNextIndex < 0 ? 0 : rawNextIndex;
        // If approaching leading edge from right, set index to start
        nextActiveThumbnailIndex = nextActiveThumbnailIndex >= imageCount ? imageCount - 1 : nextActiveThumbnailIndex;
      }
      const nextMediaAssset = mediaAssetsList[nextActiveThumbnailIndex];
      handleClickMediaAsset(nextMediaAssset);
      setActiveThumbnailIndex(nextActiveThumbnailIndex);

      return SHOULD_LOOP ? rawNextIndex : nextActiveThumbnailIndex;
    },
    [activeThumbnailIndex, mediaAssetsList, handleClickMediaAsset, imageCount]
  );

  return {
    activeThumbnailIndex,
    handleClickThumbnail,
    isMobile,
    mediaAssetsList,
    stepToImage,
    activeImageIndexRef,
    imageGalleryContainerRef
  } as const;
};

export interface UseInlineGalleryArgs
  extends Readonly<
    {
      activeImageIndex: number;
      imageCount: number;
      mediaAssets: CatalogProductMediaAsset[];
    } & Pick<UseProductGalleryControllerReturn, 'stepToImage'>
  > {}

export const useInlineGallery = ({ activeImageIndex, imageCount, stepToImage, mediaAssets }: UseInlineGalleryArgs) => {
  const [isResetting, setIsResetting] = useState(false);
  const [trackedIndex, setTrackedIndex] = useState(() => activeImageIndex);
  const [stopScroll, setStopScroll] = useState(false);
  const [stopSwipe, setStopSwipe] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dragContainerRef = useRef<HTMLDivElement>(null);
  const trackerRefs = useRef<{ resetTimeoutId: number | undefined; isResetting: boolean }>({ resetTimeoutId: undefined, isResetting: false });
  const isSwiped = useRef(false);
  const shouldSwipe = imageCount > 1;

  const handleSwipe = useEventCallback((swipeData: SwipeEventData, containerWidth: number) => {
    const { absX, deltaX } = swipeData;
    const swipeBoundary = containerWidth * SWIPE_BOUNDARY_RATIO;
    const approximatePhotoSkips = Math.round(absX / swipeBoundary);
    let step: number = 0;
    if (deltaX < 0) {
      step = Math.min(1, approximatePhotoSkips);
    } else {
      step = Math.min(1, approximatePhotoSkips) * -1;
    }
    const nextIndex = stepToImage(step);
    return nextIndex;
  });

  const handlers = useSwipeable({
    onSwipeStart: e => {
      e.event.stopPropagation();
      if (e.dir === 'Left' || e.dir === 'Right') {
        setStopScroll(true);
      } else {
        setStopSwipe(true);
      }
    },
    onSwiping: data => {
      if (stopSwipe) return;
      isSwiped.current = true;
      const dragContainer = dragContainerRef.current;
      if (dragContainer && !isResetting) {
        dragContainer.style.willChange = 'transform';
        dragContainer.style.transform = `translateX(${data.deltaX}px)`;
        dragContainer.style.transition = `none`;
      }
    },
    onSwiped: data => {
      if (stopSwipe) {
        setStopSwipe(false);
        return;
      }

      setStopScroll(false);
      const dragContainer = dragContainerRef.current;
      const wrapper = wrapperRef.current;
      if (dragContainer && wrapper && !isResetting) {
        setIsResetting(true);

        const containerWidth = wrapper.clientWidth;
        const nextActiveImageIndex = handleSwipe(data, containerWidth);
        const delta = Math.abs(nextActiveImageIndex - activeImageIndex);
        const transform = containerWidth * delta * (data.dir === 'Left' ? -1 : 1);
        const left = SHOULD_LOOP ? (nextActiveImageIndex + 1) * -100 : nextActiveImageIndex * -100;

        // Snap to the nearest carousel image
        dragContainer.style.transition = animationTransition('transform');
        dragContainer.style.willChange = 'transform';
        dragContainer.style.transform = `translateX(${transform}px)`;

        // Delay resetting transform + left properties until after the current transform animation
        trackerRefs.current.resetTimeoutId = window.setTimeout(() => {
          dragContainer.style.transition = 'none';
          dragContainer.style.willChange = 'auto';
          dragContainer.style.transform = `translateX(0px)`;
          dragContainer.style.left = `${left}%`;
          setIsResetting(false);
          // Setting timeout to same duration as the transition
        }, SWIPE_TRANSITION_DURATION);
      }
    },
    // swipeDuration: SWIPE_TRANSITION_DURATION,
    // preventScrollOnSwipe: true,
    trackMouse: shouldSwipe,
    trackTouch: shouldSwipe
  });

  const transitions = useTransition(mediaAssets[trackedIndex], item => getMediaAssetImgUrl(item) || '', {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1, position: 'relative' },
    leave: { opacity: 0, position: 'absolute' },
    config: { duration: 0 },
    // @ts-ignore
    native: true,
    immediate: isSwiped.current,
    onDestroyed: destroyed => {
      isSwiped.current = false;
    }
  });

  useEffect(() => {
    if (!isResetting) {
      setTrackedIndex(activeImageIndex);
    }
  }, [activeImageIndex, isResetting]);

  return {
    wrapperRef: mergeRefs(handlers.ref, wrapperRef),
    dragContainerRef,
    handlers,
    stopScroll,
    trackedIndex,
    transitions
  } as const;
};

export type UseCarouselGalleryArgs = {
  selectedMediaAsset: Maybe<CatalogProductMediaAsset>;
} & Pick<UseProductGalleryControllerReturn, 'stepToImage'>;

export const useCarouselGallery = (args: UseCarouselGalleryArgs) => {
  const { selectedMediaAsset, stepToImage } = args;
  const activeTransitionRef = useRef(false);

  const transitions = useTransition(selectedMediaAsset, item => getMediaAssetImgUrl(item) || '', {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1, position: 'relative' },
    leave: { opacity: 0, position: 'absolute' },
    config: { duration: 0 },
    // @ts-ignore
    native: true,
    onDestroyed: destroyed => {
      activeTransitionRef.current = false;
    }
  });

  const handleArrowClick = useCallback(
    (dir: 'left' | 'right') => {
      if (activeTransitionRef.current) {
        return;
      }

      activeTransitionRef.current = true;
      stepToImage(dir === 'left' ? -1 : 1);
    },
    [stepToImage]
  );

  return {
    transitions,
    handleArrowClick
  };
};
