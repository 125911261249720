import React from 'react';
import { LinkV1, LinkV2, useTheme } from '@withjoy/joykit';
import { StyledFlex, ParticleJoyLogoWithPadding, linkStyles } from './RegistryFooter.styles';
import { useTranslation } from '@shared/core';

const FOOTER_HELP_URL = 'http://help.withjoy.com/guest-help/?utm_source=guest-site-registry&utm_medium=viral';
const FOOTER_ABOUT_URL = 'https://withjoy.com/?utm_source=guest-site-registry&utm_medium=viral';

export const RegistryFooter: React.FC = () => {
  const { t2 } = useTranslation('guestRegistry');
  const { help, about } = t2('registryFooter');
  const { colors } = useTheme();
  return (
    <StyledFlex justifyContent="center" alignItems="center" marginY={[120]}>
      <LinkV2 href={FOOTER_HELP_URL} target="__blank" {...linkStyles}>
        {help}
      </LinkV2>
      <LinkV1 href={FOOTER_ABOUT_URL} underline="none" target="__blank">
        <ParticleJoyLogoWithPadding id="footer2brand-desktop" logoColor={colors.mono10} />
      </LinkV1>
      <LinkV2 href={FOOTER_ABOUT_URL} target="__blank" {...linkStyles}>
        {about}
      </LinkV2>
    </StyledFlex>
  );
};
