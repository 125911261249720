import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddressDialogFields } from '@apps/registry/common/components/AddressDialog/types';

// Got this from styled-system
const isNumber = (n: unknown): n is number => typeof n === 'number' && !isNaN(n);

const NATIONAL_COUNTRY_LABEL = 'United States';
const NATIONAL_COUNTRY_CODE = 'USA';
export const DISABLE_ADDRESS_VALIDATION_KEY = 'DISABLE_ADDRESS_VALIDATION_KEY';

interface AddressDialogControllerProps {
  handleOnAddOtherAddress: (values: AddressDialogFields) => void;
}

export const useOtherAddressController = ({ handleOnAddOtherAddress }: AddressDialogControllerProps) => {
  const { t: translateForm } = useTranslation('generalFormValidation');

  const formik = useFormik<AddressDialogFields>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: NATIONAL_COUNTRY_LABEL,
      countryCode: NATIONAL_COUNTRY_CODE,
      validated: false
    },
    onSubmit: handleOnAddOtherAddress,
    validationSchema: Yup.object<AddressDialogFields>({
      name: Yup.string().required(translateForm('required')),
      address1: Yup.string().required(translateForm('required')),
      address2: Yup.string(),
      city: Yup.string().required(translateForm('required')),
      state: Yup.string().required(translateForm('required')),
      postalCode: Yup.string().required(translateForm('required')),
      country: Yup.string().required(translateForm('required')),
      validated: Yup.boolean()
    })
  });

  const handlePostalCodeFieldChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    const charParsed = parseInt(value.charAt(value.length - 1));
    if (value === '' || (value.length <= 5 && isNumber(charParsed))) {
      formik.handleChange(event);
    }
  };

  const { errors } = formik || {};
  const nameError = formik.touched.name && !!errors.name ? formik.errors.name : undefined;
  const address2Error = formik.touched.address2 && !!errors.address2 ? formik.errors.address2 : undefined;
  const stateError = formik.touched.state && !!errors.state ? formik.errors.state : undefined;
  const cityError = formik.touched.city && !!errors.city ? formik.errors.city : undefined;
  const postalCodeError = formik.touched.postalCode && !!errors.postalCode ? formik.errors.postalCode : undefined;

  return {
    formik,
    handlePostalCodeFieldChange,
    address2Error,
    stateError,
    cityError,
    postalCodeError,
    nameError
  };
};
