import React, { useMemo } from 'react';
import { Box, TextV2 } from '@withjoy/joykit';
import { InCartProduct } from '../../ShoppingCart.types';
import { InSummaryItem } from './InSummaryItem';
import { useTranslation } from '@shared/core';
import { StyledPriceLine, StyledDivider } from './SummaryItemList.styles';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useGiftWrapECardPrice } from '@shared/components';
import { useShoppingCart } from '../../state';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';

type SummaryItemListProps = Readonly<{
  inCartProductList: Array<InCartProduct>;
  inCartProductTotal: number;
  registryCurrencyCode?: string;
  hasECard?: boolean;
}>;

const PriceLine = ({ label, price, bold }: { label: string; price: string | number; bold?: boolean }) => (
  <StyledPriceLine>
    <TextV2 typographyVariant={bold ? 'hed3' : 'label2'} color={bold ? 'mono14' : 'mono12'}>
      {label}
    </TextV2>
    <TextV2 typographyVariant={bold ? 'hed3' : 'label2'} color={bold ? 'mono14' : 'mono12'}>
      {price}
    </TextV2>
  </StyledPriceLine>
);
export const SummaryItemList: React.FC<SummaryItemListProps> = ({ inCartProductList, inCartProductTotal, registryCurrencyCode, hasECard }) => {
  const { t } = useTranslation('guestRegistry');
  const { subtotal, total, salesTax, delivery, freeDeliveryPriceText, pendingSalesTaxPriceText, eCard } = t('shoppingCart');
  const { formatCurrency } = useCurrencyFormatter();
  const isMobileOrTablet = useIsMobileOrTablet();
  const registryGiftWrapEcardPrice = useGiftWrapECardPrice();
  const PRICE = `$${registryGiftWrapEcardPrice}`;
  const {
    state: { shippingFee, shippingFeeInUnits }
  } = useShoppingCart();

  const subtotalPrice = registryCurrencyCode
    ? formatCurrency({
        priceFloatingPointDecimalString: `${inCartProductTotal}`,
        priceCurrencyCode: DEFAULT_CURRENCY,
        formatForm: 'short',
        shouldCalculateCharmPrice: true
      })
    : inCartProductTotal;

  const totalTotal = inCartProductTotal + (hasECard ? registryGiftWrapEcardPrice : 0);

  const totalPrice = registryCurrencyCode
    ? formatCurrency({
        priceFloatingPointDecimalString: `${totalTotal + shippingFeeInUnits}`,
        priceCurrencyCode: DEFAULT_CURRENCY,
        formatForm: 'short',
        shouldCalculateCharmPrice: true
      })
    : totalTotal + shippingFeeInUnits;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filteredInCartProductList = useMemo(() => inCartProductList.filter(product => product.inStock), inCartProductList);

  return (
    <>
      <Box flex={isMobileOrTablet ? '1' : undefined}>
        {filteredInCartProductList.map(product => (
          <InSummaryItem key={product.registryItemId} inCartProduct={product} />
        ))}
      </Box>
      {!isMobileOrTablet && <StyledDivider />}
      <Box position={isMobileOrTablet ? 'sticky' : 'initial'} bottom="0" backgroundColor={isMobileOrTablet ? 'white' : undefined}>
        <Box>
          <PriceLine label={subtotal()} price={subtotalPrice} />
          <PriceLine label={delivery()} price={shippingFee === '0.00' ? freeDeliveryPriceText() : shippingFee ? '$' + shippingFee : ''} />
          {hasECard && <PriceLine label={eCard()} price={PRICE} />}
          <PriceLine label={salesTax()} price={pendingSalesTaxPriceText()} />
        </Box>
        <Box paddingX={5} paddingY={1}>
          <StyledDivider margin="0 !important" />
        </Box>
      </Box>
      {!isMobileOrTablet && <PriceLine label={total()} price={totalPrice} bold />}
    </>
  );
};
