import { LinkV2, styled } from '@withjoy/joykit';

export const StyledLaunchEmailSupportLink = styled(LinkV2)`
  border-color: transparent;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.typeSecondaryDark};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  svg {
    margin-right: 8px;
  }
  :visited,
  :active {
    color: ${props => props.theme.colors.typeSecondaryDark};
  }
  :hover {
    background-color: transparent;
    cursor: pointer;
    color: ${props => props.theme.colors.typeTertiaryDark};
  }
`;
