import React, { useEffect, useMemo } from 'react';
import { StyledBox, StyledTitleText, StyledSubText } from './RegistryHidden.styles';
import { Flex, Box, LinkV2 } from '@withjoy/joykit';
import { ReactComponent as RegistryTools } from '@assets/icons/registry-tools.svg';
import globalWindow from '@shared/core/globals';
import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { useEventInfo } from '@shared/utils/eventInfo';

interface RegistryHiddenProps {
  website: string;
}
export const RegistryHidden: React.FC<RegistryHiddenProps> = ({ website }) => {
  const { eventInfo } = useEventInfo();
  const { getRegistryHiddenTranslations } = useRegistryGuestTranslations();
  const { title, subtitle, link } = getRegistryHiddenTranslations();
  const redirectUrl = `${window.location.origin}/${website}`;
  const productVerticals = eventInfo?.productVerticals;
  const isStandaloneRegistry = useMemo<boolean>(
    () =>
      productVerticals?.optOutOfRegistry === false &&
      productVerticals?.optOutOfGuestList === true &&
      productVerticals?.optOutOfInvites === true &&
      productVerticals?.optOutOfWebsite === true,
    [productVerticals]
  );

  useEffect(() => {
    const timerId = globalWindow.setTimeout(() => {
      if (!isStandaloneRegistry) {
        window.location.href = redirectUrl;
      }
    }, 5000);
    return () => {
      globalWindow.clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStandaloneRegistry]);

  return (
    <StyledBox paddingX={64} paddingY={120}>
      <Flex>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Box marginBottom={40}>
            <RegistryTools />
          </Box>
          <StyledTitleText typographyVariant="hed6" marginBottom={20}>
            {title}
          </StyledTitleText>
          {!isStandaloneRegistry && (
            <StyledSubText typographyVariant="body4">
              {subtitle}
              <LinkV2 color={'linkText'} href={redirectUrl} textDecoration="none">
                {link}
              </LinkV2>
            </StyledSubText>
          )}
        </Flex>
      </Flex>
    </StyledBox>
  );
};
