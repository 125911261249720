import { GiftWrapPremiumDetails, IGiftWrapTheme } from '@shared/components';
import { keyframes, styled } from '@withjoy/joykit';
import React, { useEffect, useRef, useState } from 'react';
import { PremiumCardTemplate } from './PremiumCardTemplate';
import { useLoadGoogleFont } from './useLoadGoogleFont';

const TextContainer = styled.div`
  width: 100%;
  height: 100%;

  .tf {
    font-family: 'Shadows Into Light Two';
    letter-spacing: 0.2em; // How to scale?
    text-transform: uppercase;
  }
  .tx {
    position: absolute;
    left: 5%; // Use vw?
    width: 90%;

    color: hsl(208, 7%, 38%);
    text-align: center;
  }
  .tm {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }
  .t1 {
    font-size: 50px;
  }
  .t2 {
    font-size: 80px;
    line-height: 80px;
  }
`;

const Horizon = styled.img`
  top: 685px;
`;

const Road = styled.img`
  top: 880px;
`;

const lineKeyframes = (top: number) => keyframes`
	from { top: calc(${top}px + 180px * 0); }
	to   { top: calc(${top}px + 180px * 3); } // 180px/s * 3s	
`;

const Line = styled.img<{ $top: number }>`
  width: 48px;
  height: 169px;
  left: 476px;
  top: ${props => props.$top}px;
  animation: ${props => lineKeyframes(props.$top)} 3s linear infinite;
`;

const Can1 = styled.img`
  left: 138px;
  top: 1029px;
  @keyframes Can1 {
    0% {
      left: 138px;
      top: 1029px;
      transform: rotate(0deg);
    }
    25% {
      left: 128px;
      top: 1029px;
      transform: rotate(-5deg);
    }
    50% {
      left: 128px;
      top: 1029px;
      transform: rotate(10deg);
    }
    75% {
      left: 128px;
      top: 1029px;
      transform: rotate(5deg);
    }
  }
  animation: Can1 2s ease-in-out infinite;
`;

const Can2 = styled.img`
  left: 328px;
  top: 1038px;
  @keyframes Can2 {
    0% {
      left: 328px;
      top: 1038px;
      transform: rotate(0deg);
    }
    25% {
      left: 328px;
      top: 1038px;
      transform: rotate(5deg);
    }
    50% {
      left: 326px;
      top: 1018px;
      transform: rotate(-10deg);
    }
    75% {
      left: 311px;
      top: 1049px;
      transform: rotate(-5deg);
    }
  }
  animation: Can2 2s ease-in-out infinite;
`;

const Can3 = styled.img`
  left: 442px;
  top: 1066px;
  @keyframes Can3 {
    0% {
      left: 442px;
      top: 1066px;
      transform: rotate(0deg);
    }
    25% {
      left: 442px;
      top: 1066px;
      transform: rotate(-20deg);
    }
    50% {
      left: 438px;
      top: 1027px;
      transform: rotate(-10deg);
    }
    75% {
      left: 438px;
      top: 1027px;
      transform: rotate(-5deg);
    }
  }
  animation: Can3 2s ease-in-out infinite;
`;

const Can4 = styled.img`
  left: 566px;
  top: 1033px;
  @keyframes Can4 {
    0% {
      left: 566px;
      top: 1033px;
      transform: rotate(0deg);
    }
    25% {
      left: 576px;
      top: 1023px;
      transform: rotate(-10deg);
    }
    50% {
      left: 566px;
      top: 1033px;
      transform: rotate(10deg);
    }
    75% {
      left: 570px;
      top: 1057px;
      transform: rotate(-15deg);
    }
  }
  animation: Can4 2s ease-in-out infinite;
`;

const Can5 = styled.img`
  left: 642px;
  top: 966px;
  @keyframes Can5 {
    0% {
      left: 642px;
      top: 966px;
      transform: rotate(0deg);
    }
    25% {
      left: 642px;
      top: 966px;
      transform: rotate(-8deg);
    }
    50% {
      left: 642px;
      top: 966px;
      transform: rotate(-20deg);
    }
    75% {
      left: 628px;
      top: 940px;
      transform: rotate(-15deg);
    }
  }
  animation: Can5 2s ease-in-out infinite;
`;

const Bounce = styled.div`
  width: 100%;
  height: 100%;
  top: 0px;
  @keyframes Bounce {
    50% {
      top: 20px;
    }
  }
  animation: Bounce 1s ease-in-out infinite;
`;

const Car = styled.img`
  width: 910px;
  height: 664px;
  left: 45px;
  top: 495px;
`;

const Initials = styled.div`
  width: 312px;
  height: 118px;
  left: 344px;
  top: 590px;

  color: #5b6268;
  font-size: 80px;
  line-height: calc(80px * 1.45);
  font-family: Homemade Apple;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 32px;
  word-wrap: break-word;

  text-align: center;
  transform: translate(20px, 0); // "Homemade Apple" font seems mis-centered. This is to compensate.
`;

const Blinker = styled.img<{ $left: number }>`
  left: ${props => props.$left}px;
  top: 771px;

  opacity: 0;
  @keyframes Blinker {
    75% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
  }
  animation: Blinker 8s infinite;
`;

export const GetawayCard: React.FC<{ theme: IGiftWrapTheme; details: GiftWrapPremiumDetails }> = ({ details, theme }) => {
  useLoadGoogleFont('Homemade Apple'); // This font is not measured, thus no need to track if it is loaded.
  const isFontLoaded = useLoadGoogleFont('Shadows Into Light Two');

  const measuringDiv = useRef<HTMLDivElement>(null);
  const [oneLine, setOneLine] = useState(true);
  useEffect(() => {
    if (!measuringDiv.current) return;
    setOneLine(measuringDiv.current.offsetWidth < 900); // 1000px x 90%
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measuringDiv.current, isFontLoaded]);

  return (
    <PremiumCardTemplate cardId="getaway" details={details} theme={theme}>
      <div style={{ width: '100%', height: '100%', background: 'hsl(203, 31%, 89%)' }}></div>
      <img alt="" src="https://withjoy.blob.core.windows.net/card/getaway_curve.svg" />
      <Horizon src="https://withjoy.blob.core.windows.net/card/getaway_horizon.svg" />
      <Road src="https://withjoy.blob.core.windows.net/card/getaway_road.svg" />
      <Line src="https://withjoy.blob.core.windows.net/card/getaway_line.svg" $top={920} />
      <Can1 src="https://withjoy.blob.core.windows.net/card/getaway_can1.svg" />
      <Can2 src="https://withjoy.blob.core.windows.net/card/getaway_can2.svg" />
      <Can3 src="https://withjoy.blob.core.windows.net/card/getaway_can3.svg" />
      <Can4 src="https://withjoy.blob.core.windows.net/card/getaway_can4.svg" />
      <Can5 src="https://withjoy.blob.core.windows.net/card/getaway_can5.svg" />
      <Bounce>
        <Car src="https://withjoy.blob.core.windows.net/card/getaway_car.svg" />
        <Initials>
          {details.name1[0]}+{details.name2[0]}
        </Initials>
        <Blinker src="https://withjoy.blob.core.windows.net/card/getaway_light.png" $left={89} />
        <Blinker src="https://withjoy.blob.core.windows.net/card/getaway_light.png" $left={615} />
      </Bounce>
      <TextContainer>
        <div className="tf tm t2" ref={measuringDiv}>
          {details.name1} & {details.name2}
        </div>
        {oneLine ? (
          <>
            <div className="tf tx t1" style={{ top: 174 }}>
              Congratulations
            </div>
            <div className="tf tx t2" style={{ top: 286 }}>
              {details.name1} & {details.name2}
            </div>
          </>
        ) : (
          <>
            <div className="tf tx t1" style={{ top: 94 }}>
              Congratulations
            </div>
            <div className="tf tx t2" style={{ top: 196 }}>
              {details.name1}
              <br />&<br />
              {details.name2}
            </div>
          </>
        )}
      </TextContainer>
    </PremiumCardTemplate>
  );
};
