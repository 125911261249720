import React from 'react';
import { LinkV2Props } from '@withjoy/joykit';
import { StyledSuccessGuestLink, StyledSuccessGuestLinkBold } from './SuccessGuestLink.styles';

interface Props extends LinkV2Props {
  onClick?: () => void;
}

// TODO: consider phasing out these components since they're not used in a consistent approach

export const SuccessGuestLink: React.FC<Props> = ({ children, onClick, ...restProps }) => {
  return (
    <StyledSuccessGuestLink typographyVariant={'button2'} textDecoration="none" target="_blank" onClick={onClick ? onClick : undefined} {...restProps}>
      {children}
    </StyledSuccessGuestLink>
  );
};

export const SuccessGuestLinkBold: React.FC<LinkV2Props> = ({ children, onClick, ...restProps }) => {
  return (
    <StyledSuccessGuestLinkBold typographyVariant={'button1'} textDecoration="none" target="_blank" onClick={onClick ? onClick : undefined} {...restProps}>
      {children}
    </StyledSuccessGuestLinkBold>
  );
};
