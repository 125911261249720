/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Heart Filled',
  definitions: {
    '16': {
      viewBox: '0 0 14 13',
      path: (
        <>
          <path
            d="M1.81048 7.063C0.285384 5.56163 0.285384 3.12741 1.81048 1.62603C3.33557 0.124655 5.80823 0.124655 7.33332 1.62603C8.85841 0.124655 11.3311 0.124655 12.8562 1.62603C14.3813 3.12741 14.3813 5.56163 12.8562 7.063L7.33332 12.5L1.81048 7.063Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M3.71573 12.8445C1.42809 10.5924 1.42809 6.94112 3.71573 4.68905C6.00336 2.43698 9.71236 2.43698 12 4.68905C14.2876 2.43698 17.9966 2.43698 20.2843 4.68905C22.5719 6.94112 22.5719 10.5924 20.2843 12.8445L12 21L3.71573 12.8445Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
