import { styled, StyleSystemProps, FabButton, IconButton, Box } from '@withjoy/joykit';

export const StyledRoot = styled(Box)``;
export const StyledBackButton = styled(FabButton)``;
export const StyledCloseButton = styled(IconButton)``;

const rootStyles: StyleSystemProps = { display: 'flex', justifyContent: 'space-between', padding: [5, 6] };

const navButtonStyles: Omit<StyleSystemProps, 'size'> = {
  backgroundColor: 'rgba(255, 255, 255, 0.85)',
  backdropFilter: 'blur(8.72727px)',
  boxShadow: '0px 0px 34.9091px rgba(0, 0, 0, 0.1)',
  color: '#333',
  typographyVariant: 'body1',
  zIndex: 'docked',
  _hover: {
    backgroundColor: 'mono3'
  },
  _active: {
    backgroundColor: 'mono5'
  }
};

export const styles = {
  root: rootStyles,
  navButton: navButtonStyles
};
