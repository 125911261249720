import { useFormik } from 'formik';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { DetailsFormFields } from '../types';
import { useTranslation } from '@shared/core';

export const useDetailsFormFields = (initialValues: DetailsFormFields, handleFormSubmit: (details: DetailsFormFields) => void) => {
  const { t: translateForm } = useTranslation('generalFormValidation');
  const { t2 } = useTranslation('guestRegistry');
  const { validEmailError } = t2('shoppingCart', 'detailsStep', 'detailsForm');

  const formik = useFormik<DetailsFormFields>({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: Yup.object<DetailsFormFields>({
      name: Yup.string().required(translateForm('required')),
      email: Yup.string().email(validEmailError).required(translateForm('required')),
      message: Yup.string()
    })
  });

  const getFormFieldError = useCallback(
    (fieldName: keyof DetailsFormFields) => {
      const { errors, touched } = formik;
      return touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined;
    },
    [formik]
  );

  return {
    formik,
    getFieldProps: formik.getFieldProps,
    errors: {
      nameError: getFormFieldError('name'),
      emailError: getFormFieldError('email')
    },
    isFormValid: formik.isValid,
    saveDetails: formik.submitForm,
    dirty: formik.dirty
  };
};
