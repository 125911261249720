import React from 'react';
import { IGiftWrapTheme } from '../../GiftWrapTypes';

interface BasicCardViewProps {
  theme: IGiftWrapTheme;
}

const BasicCardView: React.FC<BasicCardViewProps> = ({ theme }) => {
  return <img src={theme?.gif} style={{ verticalAlign: 'middle', margin: 'auto', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} alt="Theme Gif" width="100%" />;
};

export default BasicCardView;
