import { useTranslation } from '@shared/core';
import { Flex, ButtonV2, TextV2, IconV2 } from '@withjoy/joykit';
import { WarningTriangle } from '@withjoy/joykit/icons';
import { styles } from './NotificationBar.styles';
import React, { useMemo } from 'react';
import { useShoppingCart } from '../../state';
import { CartCheckoutSteps, CartSections, ProductListV2 } from '../../ShoppingCart.types';
import { RegistryOrderFragment } from '@graphql/generated';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { truncateText } from '@apps/registry/common/util/truncateText';

interface NotificationBarProps {
  reservedOrderList: RegistryOrderFragment[];
  productList: ProductListV2;
}

export const NotificationBar = ({ reservedOrderList, productList }: NotificationBarProps) => {
  const { t } = useTranslation('guestRegistry');
  const { completePurchaseConfirmation, completePurchaseConfirmationAction } = t('shoppingCart');
  const {
    state: { historyItemList },
    mutators: { openShoppingCart, updateCurrentSection, updateCurrentCheckoutStep, markAsVisited }
  } = useShoppingCart();

  const activeProduct = useMemo(() => {
    if (!reservedOrderList || !productList) return null;

    return reservedOrderList.reduce((acc, order) => {
      const registryItemId = order?.lineItems?.filter(lineItem => lineItem.registryItem)[0].registryItem?.id;
      const product = productList.products.find(product => product.id === registryItemId);

      if (product) {
        const visited = historyItemList.find(item => item.registryItemId === product.registryItemId && order.id === item.orderId);
        if (!visited) return product;
      }

      return acc;
    }, null as CookedProduct | null);
  }, [reservedOrderList, productList, historyItemList]);

  const handleOpenCart = () => {
    markAsVisited(reservedOrderList, productList);
    updateCurrentSection(CartSections.HISTORY);
    updateCurrentCheckoutStep(CartCheckoutSteps.Cart);
    openShoppingCart();
  };

  return (
    activeProduct && (
      <Flex __css={styles.notificationBarWrapperFlex}>
        <IconV2 size="lg">
          <WarningTriangle />
        </IconV2>
        <TextV2 typographyVariant="hed2" __css={styles.notificationBarText}>
          {completePurchaseConfirmation({ productName: truncateText(activeProduct.title, 50) })}
        </TextV2>
        <ButtonV2 intent="neutral" onClick={handleOpenCart}>
          {completePurchaseConfirmationAction()}
        </ButtonV2>
      </Flex>
    )
  );
};
