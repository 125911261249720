import React from 'react';
import { CookedProduct, ItemWithPrice } from '@apps/registry/common/selectors/ProductListSelector';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { noop } from 'lodash-es';
import { Box, ButtonV2, Flex, FormControl, TextV2 } from '@withjoy/joykit';
import { ShippingByJoy, QuantityStepper } from './components';
import { RegistryItemProgressTracker } from '@apps/registry/admin/components';
import { pxToRem } from '@withjoy/joykit/theme';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { useTranslation } from '@shared/core';
import { useCheckoutDialogContext } from '@apps/registry/guest/components/CheckoutDialog';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

type Props = {
  product: CookedProduct;
  cannotPurchase: boolean;
  handleOnBuyNowClick: () => void;
  handleCheckoutDialogOpenCallback: (donationAmount: number) => void;
  isDropship?: boolean;
};

const DEFAULT_CONTRIBUTION_AMOUNT = 50;

export const ProductInformationGroupGifting = ({ product, cannotPurchase, handleOnBuyNowClick, handleCheckoutDialogOpenCallback, isDropship }: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { formatCurrency } = useCurrencyFormatter();
  const { openCheckoutDialog } = useCheckoutDialogContext();
  const { groupGiftPartialContribution, groupGiftRemainingContribution } = useGuestRegistryTelemetry();

  const { t } = useTranslation('guestRegistry');
  const { buyEntireGift, contribute, contributeToGiftBtn, contributeToGiftBtnMobile, contributeRemainingAmount, contributePartialAmount, amountRemaining } = t(
    'purchaseDialog',
    'groupGifting'
  );

  const { remaining = 10, received = 0 } = product.donationFund || {};

  const { getFieldProps, setFieldValue, validateForm, errors } = useFormik<{ qty: number }>({
    initialValues: {
      qty: remaining < DEFAULT_CONTRIBUTION_AMOUNT ? remaining : DEFAULT_CONTRIBUTION_AMOUNT
    },
    validationSchema: Yup.object<{ qty: number }>({
      qty: Yup.number().required().min(1)
    }),
    onSubmit: noop
  });

  if (!product.donationFund) {
    return null;
  }

  const formatPrice = (amount: number) =>
    formatCurrency({
      priceFloatingPointDecimalString: amount.toString(),
      priceCurrencyCode: product?.currencyCode || DEFAULT_CURRENCY,
      formatForm: 'short',
      formatPrecision: 0
    });

  const quantity = Number(getFieldProps('qty').value);

  const handleCheckoutDialogOpen = (donationAmount: number) => {
    handleCheckoutDialogOpenCallback?.(donationAmount);
    if (isDropship) {
      openCheckoutDialog({ registryItemId: product.registryItemId, isGroupGiftingEnabled: true, donationAmount });
    }
  };

  return (
    <Box marginBottom={5}>
      {received <= 0 && isDropship && (
        <>
          <ButtonV2 intent="productive" disabled={cannotPurchase} onClick={handleOnBuyNowClick} fullWidth marginTop={8}>
            {buyEntireGift()}
          </ButtonV2>
          <Box marginTop={6}>
            <ShippingByJoy />
          </Box>
        </>
      )}
      <Box marginTop={6}>
        <RegistryItemProgressTracker
          hideProgressText={isMobileOrTablet}
          product={product as ItemWithPrice}
          orders={[]}
          viewContext="guest"
          isGroupGifting
          currentDonation={quantity}
        />
      </Box>
      <Flex marginTop={8} justifyContent="space-between" alignItems="flex-end">
        <FormControl label={<TextV2 typographyVariant="label3">{contribute()}</TextV2>}>
          <QuantityStepper currencyCode={product?.currencyCode} setFieldValue={setFieldValue} qty={quantity} minValue={10} hasError={!!errors.qty} />
        </FormControl>
        <Flex flexDirection="column" rowGap={1} height={pxToRem(48)} justifyContent="center">
          <TextV2 typographyVariant="label3" color={quantity > 0 ? 'gray8' : 'mono14'} textDecoration={quantity > 0 ? 'line-through' : 'none'}>
            {amountRemaining({ amount: formatPrice(remaining) })}
          </TextV2>
          {quantity > 0 && <TextV2 typographyVariant="label3">{amountRemaining({ amount: formatPrice(remaining - quantity > 0 ? remaining - quantity : 0) })}</TextV2>}
        </Flex>
      </Flex>
      {quantity > 0 ? (
        <>
          <ButtonV2
            fullWidth
            intent="neutral"
            shape="rounded"
            marginBottom={5}
            marginTop={8}
            onClick={() => {
              groupGiftPartialContribution({
                productTitle: product.title,
                registryItemId: product.registryItemId,
                registryId: product.registry.id,
                contributionAmount: quantity
              });
              handleCheckoutDialogOpen(quantity);
            }}
          >
            {isMobileOrTablet ? contributeToGiftBtnMobile() : contributeToGiftBtn({ amount: formatPrice(quantity) })}
          </ButtonV2>
          <ButtonV2
            fullWidth
            variant="outline"
            intent="neutral"
            shape="rounded"
            onClick={() => {
              groupGiftRemainingContribution({
                productTitle: product.title,
                registryItemId: product.registryItemId,
                registryId: product.registry.id,
                contributionAmount: remaining
              });
              handleCheckoutDialogOpen(remaining);
            }}
          >
            {contributeRemainingAmount()}
          </ButtonV2>
        </>
      ) : (
        <ButtonV2
          fullWidth
          variant="outline"
          intent="neutral"
          shape="rounded"
          marginTop={5}
          onClick={() => {
            validateForm();
          }}
        >
          {contributePartialAmount()}
        </ButtonV2>
      )}
    </Box>
  );
};
