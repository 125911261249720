import { useEffect, useState, useMemo } from 'react';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { useTranslation } from '@shared/core';
import { RegistryItemFragment, CustomItemDataType, DonationFundPaymentMethodEnum } from '@graphql/generated';
import { getPaymentMethodFormattedName } from '@shared/utils/paymentMethodOptions';
import { withWindow } from '@shared/utils/withWindow';
import { getCurrencySymbolFromCurrencyCode } from '@shared/utils/currency/utils';
import { makeCookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useGiveGiftDialog } from '../../GuestRegistry/components/GiveGiftDialog';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { getDonationPaymentMethodFromPlatformType } from '@apps/registry/guest/components/CheckoutDialog/machines/externalCheckout/utils';

interface useContributeProps
  extends Readonly<{
    item?: RegistryItemFragment;
    quantity: number;
    isMobile: boolean;
    orderDonationPlatform?: DonationFundPaymentMethodEnum;
    orderId?: string;
  }> {}

export const useContribute = ({ item, quantity, isMobile, orderId, orderDonationPlatform }: useContributeProps) => {
  const { getContributeToFundTranslations } = useRegistryGuestTranslations();
  const { contributeButtonTextCharity, contributeCopyText } = getContributeToFundTranslations();
  const { t } = useTranslation('guestRegistry');
  const { contributeButtonSend, contributeSuggestedNoteString, allDone } = t('contributeToFund');
  const { formatCurrency } = useCurrencyFormatter();

  const { cookedProduct } = makeCookedProduct({ formatCurrency, item });
  const giveGiftDialogProps = useGiveGiftDialog({ product: cookedProduct });
  const donationFund = item?.donationFund;

  const donationFundOrder = useMemo(() => {
    const donation = donationFund && donationFund.donations.find(donation => donation.id === orderId);
    return donation;
  }, [donationFund, orderId]);

  const currencyCode = useMemo(() => {
    return donationFund?.currency.code || DEFAULT_CURRENCY;
  }, [donationFund]);

  const platformType =
    donationFundOrder?.platformType ||
    orderDonationPlatform ||
    //failsafe for running this code with the old backend
    (donationFund?.platform?.type as string) ||
    '';

  const isCharityFund = donationFund?.fundType === CustomItemDataType.charity;
  const currencySymbol = getCurrencySymbolFromCurrencyCode(currencyCode);
  const priceString = `${currencySymbol}${quantity}`;
  const isVenmo = platformType === 'venmo';
  const platformUsername = (donationFund?.platform?.username as string) || '';
  const isCashOrCheck = platformType === DonationFundPaymentMethodEnum.cashOrCheck;

  const shouldContributeOnWebsite = isCharityFund && getDonationPaymentMethodFromPlatformType(donationFund?.platform?.type) === DonationFundPaymentMethodEnum.other;
  const contributeSuggestedNoteDisplay = donationFund?.title ? contributeSuggestedNoteString({ fundTitle: donationFund?.title }) : null;

  const [copyText, setCopyText] = useState<string>(contributeCopyText);

  const platformDisplayName = getPaymentMethodFormattedName(platformType!);

  const renderButtonText = () => {
    if (isVenmo) {
      return contributeButtonSend({ donationPlatform: platformDisplayName });
    } else if (isCashOrCheck) {
      return allDone();
    }
    if (isCharityFund && getDonationPaymentMethodFromPlatformType(donationFund?.platform?.type) === DonationFundPaymentMethodEnum.other) {
      return contributeButtonTextCharity;
    } else {
      if (platformDisplayName) {
        return contributeButtonSend({ donationPlatform: platformDisplayName });
      }
    }
    return null;
  };

  const onCopyClick = () => {
    setCopyText('Copied');
  };

  useEffect(() => {
    withWindow(global => {
      const timer = global.setTimeout(() => {
        setCopyText(contributeCopyText);
      }, 1300);
      return () => global.clearTimeout(timer);
    });
  }, [copyText, contributeCopyText]);

  return {
    cookedProduct,
    priceString,
    platformUsername,
    shouldContributeOnWebsite,
    contributeSuggestedNoteDisplay,
    platformDisplayName,
    renderButtonText,
    copyText,
    onCopyClick,
    isCashOrCheck,
    giveGiftDialogProps
  } as const;
};
