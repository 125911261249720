import React from 'react';
import { Box } from '@withjoy/joykit';
import { ContentHeader, CtaGroup, IntegratedGiftCard, LayoutShell, PrimaryCta, SecondaryCta } from '../../components';
import { useExternalCheckoutReviewController } from './ExternalCheckoutReview.controller';
import { ExternalCheckoutReviewSelectors } from './ExternalCheckoutReview.selectors';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';
import { useFeatureValue } from '@shared/core/featureFlags';

export const ExternalCheckoutReview = () => {
  const { value: registryGiftWrapCashOn } = useFeatureValue('registryGiftWrapCashEnabled');
  const { value: registryGiftWrapAffiliateOn } = useFeatureValue('registryGiftWrapAffiliateEnabled');
  const {
    initiateCancelProcess,
    redirectToExternalGateway,
    translations,
    platformLabel,
    isCharity,
    isAffiliate,
    product,
    currency,
    productImageSrc,
    formValues,
    isCashFundType
  } = useExternalCheckoutReviewController();
  const { title, goToButton, cancelButton, goToCharityButton } = translations;

  const contentHeaderMarkup = (
    <ContentHeader>
      <ContentHeader.Hed whiteSpace="pre-line">{title({ platform: platformLabel })}</ContentHeader.Hed>
    </ContentHeader>
  );

  return (
    <LayoutShell {...ExternalCheckoutReviewSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="static" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} quantity={formValues.quantity} currency={currency} />
          ) : (
            <>
              <Box paddingX={6} display={['block', 'none']}>
                {contentHeaderMarkup}
              </Box>
              <IntegratedGiftCard mobileRenderBehavior="card" marginTop={[7, 0]} />
            </>
          )}
        </LayoutShell.MediaPanel>

        {/* Center content that is smaller (shorter) then the GiftCard */}
        <LayoutShell.ContentPanel display={['block', 'flex']} flexDirection="column" justifyContent="center">
          <Box display={['none', 'block']}>{contentHeaderMarkup}</Box>
          <CtaGroup marginTop={[0, 7]}>
            <PrimaryCta {...ExternalCheckoutReviewSelectors.LetsGoToButton} onClick={redirectToExternalGateway}>
              {isCharity ? goToCharityButton() : goToButton({ platform: platformLabel })}
            </PrimaryCta>
            {!(registryGiftWrapCashOn === 'on' && isCashFundType) && !(registryGiftWrapAffiliateOn === 'on' && isAffiliate) && (
              <SecondaryCta {...ExternalCheckoutReviewSelectors.CancelButton} onClick={initiateCancelProcess}>
                {cancelButton()}
              </SecondaryCta>
            )}
          </CtaGroup>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
