import globalWindow from '@shared/core/globals';
import { useEffect, useState } from 'react';

// Variant of src/apps/saveTheDate/hooks/useLoadGoogleFont.ts that returns `true` when the font has loaded.
export const useLoadGoogleFont = (font: string) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    globalWindow.WebFont?.load({
      google: {
        families: [font]
      },
      // `active` has not been reliably calling back,
      // thus falling back to `fontactive` and limit each hook to 1 font
      // to avoid array comparisons.
      fontactive: () => setActive(true)
    });
  }, [font]);

  return active;
};
