import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PagesForTelemetry } from './Registry.types';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { ApolloError } from '@apollo/client';
import { CashFundProduct } from '@apps/registry/common/components/AddCashFund/types';
import { CookedProduct, CookedProductCatalogItem } from '@apps/registry/common/selectors/ProductListSelector';
import { CustomItemDataType, SalesOrderRefundMethod } from '@graphql/generated';
import { RemoveRegistryArgs, OrderLineProps } from './AdminRegistry.telemetry.types';
import { CompletionDiscountDialogSource } from './components/CompletionDiscountDialog';
import { CookedOrderLine } from './routes/YourRegistry/components/GiftTracker/GiftTracker.types';
import { RegistryItemSource } from '../common/components/Catalog/Catalog.controller';

const addCashFundDialogStepToLabel: ReadonlyRecord<number, string> = {
  0: 'title',
  1: 'goal',
  2: 'paymentMethod',
  3: 'description',
  4: 'discard',
  5: 'success'
};

const category = 'admin.registry';

const pageTracking: { [tab in PagesForTelemetry]: PageFunctionType } = {
  build: () => ({
    category: 'registry',
    pagePrefix: 'admin',
    page: 'discover'
  }),
  manage: () => ({
    category: 'registry',
    pagePrefix: 'admin',
    page: 'manage'
  }),
  addRegistry: () => ({
    category: 'registry',
    pagePrefix: 'build',
    page: 'AddEditRegistry',
    action: 'ViewManage'
  }),
  updateRegistry: () => ({
    category: 'registry',
    pagePrefix: 'manage',
    page: 'AddEditRegistry',
    action: 'ViewManage'
  }),
  storeDetails: () => ({
    category: 'registry',
    pagePrefix: 'build',
    page: 'StoreDetails',
    action: 'ViewStoreDetails'
  }),
  cashFundDialogDetails: (step: number) => ({
    category: 'registry',
    pagePrefix: 'admin',
    page: `customCashFundDialog.${addCashFundDialogStepToLabel[step]}`,
    action: 'ViewCustomCashFundDialog'
  }),
  joyButtonDialogLoad: (eventId: string, page: 'install' | 'success' | 'comingSoon' = 'install') => ({
    category: 'build',
    pagePrefix: 'admin.registry',
    page: `browserButtonDialog.${page}`,
    action: 'ViewJoyButtonDialog',
    extraInfo: {
      eventId
    }
  }),
  bookmarkletDialogLoad: (page: 'addGift' | 'addSuccess' | 'addFailed') => ({
    category: 'browserButton',
    pagePrefix: 'admin',
    page: `bookmarkletDialog.${page}`,
    action: 'ViewAddGiftDialog'
  }),
  noAuthDialogLoad: () => ({
    category: 'browserButton',
    pagePrefix: 'iframe',
    page: 'notSignedIn',
    action: 'ViewNoAuthDialog'
  }),
  overview: () => ({
    category: 'registry',
    pagePrefix: 'admin',
    page: 'overview',
    action: 'OverviewLoaded'
  }),
  track: () => ({
    category: 'registry',
    pagePrefix: 'admin',
    page: 'track',
    action: 'TrackLoaded'
  })
};

export const registryTelemetry = createTelemetryObject({
  actions: {
    trackEventPreviewButtonInteraction: (label: string) => ({
      category: 'admin',
      action: 'ButtonInteracted',
      label,
      extraInfo: { fromPage: 'admin.registry.manage' }
    }),
    createRegistry: (eventId: string, id: string) => ({
      category,
      action: 'CreateRegistry',
      actionType: 'click',
      extraInfo: { eventId, id }
    }),
    trackError: (mutation: string, error: ApolloError, extra = {}) => ({
      category,
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),
    addRegistryClick: (normalizedStoreName: string, rawRegistryUrl: string, page: string, label?: string, eventId?: string) => ({
      category: `${category}.${page}`,
      action: 'AddRegistry',
      actionType: 'click',
      page: page,
      pagePrefix: 'admin',
      label,
      extraInfo: { normalizedStoreName, rawRegistryUrl, eventId }
    }),
    addRegistryValidationError: (pagePrefix: string) => ({
      category,
      action: 'Click',
      actionType: 'error',
      page: 'AddEditRegistry',
      pagePrefix
    }),
    learnMoreClick: () => ({
      category,
      action: 'LearnMore',
      actionType: 'click',
      page: 'build',
      pagePrefix: 'admin'
    }),
    registryTileClick: (storeName: string) => ({
      category,
      action: 'RegistryTile',
      actionType: 'click',
      page: 'build',
      pagePrefix: 'admin',
      extraInfo: { normalizedStoreName: storeName }
    }),
    registryAdded: (storeName: string, url: string = '', title: string = '', customCategory: string, email: Maybe<string>, label?: string, origin?: string) => ({
      category: customCategory,
      action: 'RegistryAdded',
      page: 'addEditRegistry',
      pagePrefix: customCategory.split('.')[2] || 'build',
      label: label || 'Add Registry',
      extraInfo: { email, orderId: Date.now(), normalizedStoreName: storeName, rawRegistryUrl: url, registryTitle: title, origin }
    }),
    registryRemovedClick: ({ storeName, url, title, label, registryType, deleted }: RemoveRegistryArgs) => ({
      category,
      action: 'RegistryRemoved',
      actionType: 'click',
      page: 'addEditRegistry',
      pagePrefix: 'manage',
      label,
      extraInfo: { normalizedStoreName: storeName, rawRegistryUrl: url, registryTitle: title, registryType, deleted }
    }),
    updateRegistryClick: (storeName: string, url: string, title: string) => ({
      category,
      action: 'UpdateRegistry',
      actionType: 'click',
      page: 'addEditRegistry',
      pagePrefix: 'manage',
      extraInfo: { normalizedStoreName: storeName, rawRegistryUrl: url, registryTitle: title }
    }),
    addUpdateDialogDismissed: (pagePrefix: string) => ({
      category,
      action: 'DismissAddUpdateDialog',
      actionType: 'click',
      page: 'addEditRegistry',
      pagePrefix
    }),
    dismissStoreDetails: () => ({
      category,
      action: 'DismissStoreDetails',
      actionType: 'click',
      page: 'storeDetails',
      pagePrefix: 'build'
    }),
    registryStoreClicked: (id?: string) => ({
      category,
      action: 'RegistryStore',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin',
      extraInfo: { registryId: id }
    }),
    clickViewAsGuest: (page: string, label?: string) => ({
      category: `${category}.${page}`,
      action: 'ViewAsGuest',
      actionType: 'click',
      pagePrefix: 'admin',
      page,
      label
    }),
    clickBackToAdmin: (page: string) => ({
      category,
      action: 'BackToAdmin',
      actionType: 'click',
      pagePrefix: 'admin',
      page
    }),
    createFromStoreDetails: () => ({
      category,
      action: 'CreateFromStoreDetails',
      actionType: 'click',
      page: 'storeDetails',
      pagePrefix: 'build'
    }),
    addExistingStoreDetails: () => ({
      category,
      action: 'AddExistingStoreDetails',
      actionType: 'click',
      page: 'storeDetails',
      pagePrefix: 'build'
    }),
    enableReorderRegistryStores: () => ({
      category,
      action: 'EnableReorderRegistryStores',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin'
    }),
    finishReorderRegistryStores: () => ({
      category,
      action: 'FinishReorderRegistryStores',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin'
    }),
    registryStoresReordered: () => ({
      category,
      action: 'RegistryReorder',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin'
    }),
    manuallyOrderedRegistryItems: () => ({
      category,
      action: 'ManualReorderRegistryItems',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin'
    }),
    markMostWantedRegistryItem: (method: string, product: CookedProduct | null) => ({
      category: 'admin.registry.manage',
      action: 'ManageGiftsInteraction',
      actionType: undefined,
      extraInfo: {
        method,
        product
      }
    }),
    adminRegistryProductFilterClick: (filter: string) => ({
      category,
      action: 'YourRegsitryProductFilter',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin',
      label: filter
    }),
    publishRegistryClicked: (value: boolean) => ({
      category,
      action: 'PublishRegistry',
      actionType: 'click',
      page: 'manage',
      pagePrefix: 'admin',
      label: value.toString()
    }),
    onChangeCashFundDialogState: (step: number) => ({
      action: 'CustomCashFundDialogNav',
      actionType: 'click',
      category,
      label: addCashFundDialogStepToLabel[step]
    }),
    onCashFundDialogFieldEdit: (field: string, step: number) => ({
      action: 'EditCustomCashFundField',
      actionType: 'click',
      category,
      label: field,
      extraInfo: {
        step: addCashFundDialogStepToLabel[step]
      }
    }),
    onOpenDetailsDialog: (product: CashFundProduct | CookedProduct) => ({
      action: 'ViewRegistryItemDetail',
      actionType: 'click',
      category,
      extraInfo: {
        product: product
      }
    }),
    onOpenExpandRow: (orderLine: OrderLineProps, needsAction: boolean = false) => ({
      action: 'ViewRegistryOrder',
      actionType: 'click',
      category: 'admin.registry.manage.tracking',
      extraInfo: {
        ...orderLine,
        needsAction
      }
    }),
    onClickContactSupportFromGiftTrackerOrderLine: (orderLine: OrderLineProps) => ({
      action: 'ContactSupportFromGiftTrackerOrderLine',
      actionType: 'click',
      category: 'admin.registry.manage.tracking',
      extraInfo: {
        ...orderLine
      }
    }),
    onClickContactSupportFromGiftTrackerEDeliveryDialog: (orderLine: OrderLineProps) => ({
      action: 'ContactSupportFromGiftTrackerEDeliveryDialog',
      actionType: 'click',
      category: 'admin.registry.manage.tracking',
      extraInfo: {
        ...orderLine
      }
    }),
    orderTrackingInfoAdd: () => ({
      action: 'TrackingInfoAdd',
      actionType: 'click',
      category: 'admin.registry.manage.tracking',
      label: 'Gift tracking'
    }),
    onEditRegistryItem: (product: CashFundProduct | CookedProduct) => ({
      action: 'editRegistryItem',
      actionType: 'click',
      category,
      extraInfo: {
        product: product
      }
    }),
    onSaveRegistryItem: (product: CashFundProduct | CookedProduct) => ({
      action: 'saveRegistryItem',
      actionType: 'click',
      category,
      extraInfo: {
        product: product
      }
    }),
    clickInstallBrowserButton: (buttonLabel: string, eventId: string, label?: string) => ({
      category: `${category}.build`,
      pagePrefix: 'admin.registry',
      page: 'build',
      action: 'InstallBrowserButton',
      actionType: 'click',
      label,
      extraInfo: {
        eventId,
        buttonLabel
      }
    }),
    joyButtonInstalled: (eventId: string, page: 'install' | 'success' | 'comingSoon' = 'install') => ({
      category: 'build',
      pagePrefix: 'admin.registry',
      page: `browserButtonDialog.${page}`,
      action: 'BrowserButtonInstalled',
      extraInfo: {
        eventId
      }
    }),
    joyButtonInstallClicked: (eventId: string) => ({
      category: 'build',
      pagePrefix: 'admin.registry',
      page: 'quickAddInstallation',
      action: 'BrowserButtonInstallClicked',
      extraInfo: {
        eventId
      }
    }),
    joyButtonDialogNav: (
      label: string,
      destination: 'success' | 'install' | 'build' | 'help article' | 'test item',
      eventId: string,
      page: 'install' | 'success' | 'comingSoon' = 'install'
    ) => ({
      category: 'build',
      pagePrefix: 'admin.registry',
      page: `browserButtonDialog.${page}`,
      action: 'BrowserButtonDialogNav',
      actionType: 'click',
      label,
      extraInfo: {
        eventId,
        destination
      }
    }),
    clickAddRegistryItem: (
      price: number,
      currency: string,
      total: number,
      url: string,
      title: string,
      type: CustomItemDataType,
      eventId: string,
      email: Maybe<string>,
      orderId: number,
      metadataSource?: Maybe<RegistryItemSource>
    ) => ({
      category: 'registry.browserButton',
      pagePrefix: 'admin',
      page: 'addToJoy',
      action: 'AddRegistryItem',
      actionType: 'click',
      extraInfo: {
        eventId,
        email,
        orderId,
        method: 'browser button',
        priceValueInMinorUnits: price,
        priceCurrencyCode: currency,
        totalRequested: total,
        productURL: url,
        title: title,
        itemType: type,
        metadataSource
      }
    }),
    noAuthDialogNav: (label: string) => ({
      category: 'build', // 'browserButton'?
      pagePrefix: 'iframe',
      page: 'browserButtonDialog.commingSoon', // 'notSignedIn'?
      action: 'BrowserButtonDialogNav',
      actionType: 'click',
      label,
      extraInfo: {
        // eventId, : not able to get eventId on noAuthDialog
        destination: 'Sign In'
      }
    }),
    ClickCopyRegistryLink: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'CopyRegistryLink',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickEditVisibilityButton: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'EditVisibility',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickEditShippingAddress: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'EditShippingAddress',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickDownloadApp: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'AppPlug',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickShopOnJoy: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'Shop',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickCashFundCard: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'CashFund',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickAddGift: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'AddGift',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickFindAndAdd: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'FindAndAdd',
      actionType: 'click'
    }),
    blogCardLoaded: (blogCardPosition: number, blogCardHeadline: string, blogPostUrl: string) => ({
      action: 'BlogCardLoaded',
      category: 'admin.registry.build',
      page: 'build',
      extraInfo: { blogCardPosition, label: blogCardHeadline, blogUrl: blogPostUrl }
    }),
    productPromoLoaded: (productPromoPosition: number, label: string, promoType: string) => ({
      action: 'ProductPromoLoaded',
      category: 'admin.registry.build',
      page: 'build',
      extraInfo: { productPromoPosition, label, promoType }
    }),
    blogCardClicked: (blogCardPosition: number, title: string, cardHeadline: string, blogPostUrl: string) => {
      return {
        action: 'BlogCardClicked',
        category: 'admin.registry.build',
        page: 'build',
        extraInfo: { blogCardPosition, label: title, cardHeadline, blogUrl: blogPostUrl }
      };
    },
    productPromoSeeMoreClick: (promoType: string, productPromoPosition: number, label: string) => ({
      action: 'ProductPromoSeeMoreClick',
      category: 'admin.registry.build',
      page: 'build',
      extraInfo: { promoName: label, promoType, productPromoPosition, label }
    }),
    clickAppStoreButton: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'GoToApp',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickInvitePartner: (label: string, eventId?: string) => ({
      category: `${category}.build`,
      action: 'InvitePartner',
      actionType: 'click',
      pagePrefix: 'admin.registry.build',
      label,
      page: 'build',
      extraInfo: {
        eventId
      }
    }),
    clickModifyRegistry: (eventId?: string) => ({
      category: `${category}.manage`,
      action: 'ManageRegistries',
      actionType: 'click',
      extraInfo: {
        eventId
      }
    }),
    clickAddRegistry: (origin: string, eventId?: string) => ({
      category: `${category}.manage`,
      action: 'AddRegistry',
      actionType: 'click',
      page: 'manage',
      extraInfo: {
        eventId,
        origin: origin
      }
    }),
    clickMaybeLater: () => ({
      category: `${category}.manage`,
      action: 'AddRegistryDismiss',
      actionType: undefined,
      page: 'manage',
      extraInfo: {
        origin: 'Empty State'
      }
    }),
    clickAddGiftDialog: () => ({
      category: `${category}.manage`,
      action: 'OpenAddGiftDialog',
      actionType: undefined,
      page: 'manage',
      extraInfo: {}
    }),
    sentReservationEmailReminder: (email: string, registryItemId: string) => ({
      category: `${category}.manage`,
      action: 'GiftReminderEmailInteraction',
      page: 'manage',
      extraInfo: {
        email,
        registryItemId,
        method: 'SentReservationEmailReminder'
      }
    }),
    sendReminderPrompt: (gift: string, isPurchased: boolean) => ({
      category: `${category}.manage`,
      action: 'GiftReminderEmailInteraction',
      page: 'manage',
      extraInfo: {
        label: isPurchased ? 'Purchased' : 'Reserved',
        gift,
        method: 'SendReminderPromptClicked'
      }
    }),
    sendReminderConfirmation: (gift: string, isPurchased: boolean) => ({
      category: `${category}.manage.track`,
      action: 'GiftReminderEmailInteraction',
      page: 'manage',
      extraInfo: {
        label: isPurchased ? 'Purchased' : 'Reserved',
        method: 'SendReminderConfirmationClicked',
        gift
      }
    }),
    clickShop: (label: string, origin: 'Empty State' | 'Add Gift dialog' | 'PostTransferPrompt', eventId?: string) => ({
      category: `${category}.manage`,
      action: 'AddGiftInteraction',
      actionType: 'click',
      page: 'manage',
      label: label,
      extraInfo: {
        eventId,
        origin: origin
      }
    }),
    clickAddGiftManually: (label: string, origin: 'Empty State' | 'Add Gift dialog', action: string, eventId?: string) => ({
      category: `${category}.manage`,
      action,
      actionType: undefined,
      page: 'manage',
      label: label,
      extraInfo: {
        eventId,
        origin: origin
      }
    }),
    clickShopFromAnyStore: (eventId: string) => ({
      category: `${category}.manage`,
      page: 'manage',
      action: 'ShopFromAnyStoreClick',
      label: 'Shop From Any Store',
      extraInfo: {
        eventId,
        origin: origin
      }
    }),
    clickRegistryNav: (label: string, page: string) => ({
      category: `${category}.${page}`,
      action: 'RegistryNav',
      actionType: 'click',
      pagePrefix: `admin.registry.${page}`,
      label,
      page
    }),
    clickDeparmentNav: (label: string) => ({
      category: 'admin.shop.discover',
      action: 'ShopByDepartment',
      actionType: 'click',
      pagePrefix: 'discover',
      label
    }),
    clickNewRegistryOnboardingShowMore: () => ({
      category: 'admin.registry.build',
      action: 'ShowMoreOnboardingCardClicked'
    }),
    clickNewRegistryOnboardingSuggestionCard: (identifier: string, label: string) => ({
      category: 'admin.registry.build',
      action: 'OnboardingCardClicked',
      suggestionIdentifier: identifier,
      label
    }),
    onboardingSurveyViewed: (questionId: string) => ({
      action: 'OnboardingSurveyViewed',
      category: 'admin.registry.onboardingSurvey',
      page: 'build',
      label: questionId
    }),
    onboardingSurveyQuestionSubmitted: (questionId: string, answer: string | number | string[]) => ({
      action: 'OnboardingSurveyQuestionSubmitted',
      category: 'admin.registry.onboardingSurvey',
      page: 'registry.onboardingSurvey',
      label: questionId,
      extraInfo: {
        answer
      }
    }),
    onboardingSurveyCompleted: () => ({
      action: 'OnboardingSurveyCompleted',
      category: 'admin.registry.onboardingSurvey',
      page: 'registry.onboardingSurvey'
    }),
    // For viewItem event criteo is looking for Product Viewed action in segment criteo-app-web-events
    onQuickActionsClicked: (label: string, page: string | undefined) => ({
      action: 'Product Viewed',
      category,
      pagePrefix: `admin`,
      label,
      page: `${category}.${page}`,
      extraInfo: {
        product_id: 1,
        quantity: 1,
        price: 0
      }
    }),
    // For trackTransaction event criteo is looking for Order Completed action in segment criteo-app-web-events
    onRegistryItemAdded: (label: string, page: string) => ({
      action: 'Order Completed',
      category,
      pagePrefix: `admin`,
      label,
      page,
      extraInfo: {
        order_id: Date.now(),
        products: [{ product_id: 1, quantity: 1, price: 0 }]
      }
    }),
    registryManageTabClicked: (label: 'giftList' | 'giftTracker') => ({
      category: 'registry',
      pagePrefix: 'admin',
      page: 'manage',
      action: 'RegistryManageTab',
      actionType: 'click',
      label,
      extraInfo: {
        label
      }
    }),
    shopHomeNavClicked: () => ({
      category: 'registry',
      pagePrefix: 'admin',
      page: 'manage',
      action: 'RegistryNav',
      actionType: 'click',
      label: 'Shop',
      extraInfo: {
        label: 'Shop',
        method: 'topNav'
      }
    }),
    onHideShowSubmit: (isHide: string) => ({
      category: 'registry',
      pagePrefix: 'admin',
      page: 'manage',
      action: 'HideGift',
      actionType: 'click',
      label: isHide
    }),
    abTestAssigned: ({ page, ...extraInfo }: { page?: string; name: 'RegistryOnboardingSurveyV2'; value: 'experiment' | 'control' }) => ({
      category: 'registry',
      pagePrefix: 'admin',
      page,
      action: 'ABTestAssigned',
      extraInfo: {
        ...extraInfo
      }
    }),
    clickShowPostWeddingDiscountDialog: ({ source }: { source: CompletionDiscountDialogSource }) => ({
      category,
      action: 'ShowPostWeddingDiscountDialog',
      actionType: 'click',
      extraInfo: {
        source
      }
    }),
    postWeddingDiscountDialogLoaded: ({ source, eligibilityStatus }: { source: CompletionDiscountDialogSource; eligibilityStatus: 'preEvent' | 'eligible' | 'expired' }) => ({
      category,
      action: 'PostWeddingDiscountDialog',
      extraInfo: {
        source,
        eligibilityStatus
      }
    }),
    clickPostWeddingDiscountNavigateToShop: () => ({
      category,
      action: 'PostWeddingDiscountNavigateToShop',
      actionType: 'click',
      extraInfo: {
        ref: 'completionDiscountModal'
      }
    }),
    clickPostWeddingDiscountLearnMore: () => ({
      category,
      action: 'PostWeddingDiscountLearnMore',
      actionType: 'click'
    }),
    postWeddingDiscountPurchasedSuccess: () => ({
      category,
      action: 'PostWeddingDiscountPurchasedSuccess',
      extraInfo: {
        ref: 'completionDiscountModal'
      }
    }),
    postWeddingDiscountPurchasedAbandoned: () => ({
      category,
      action: 'PostWeddingDiscountPurchasedAbandoned',
      extraInfo: {
        ref: 'completionDiscountModal'
      }
    }),
    enableGroupGifting: (product: CashFundProduct | CookedProduct | null, isEnabled) => ({
      action: 'EnableGroupGifting',
      actionType: 'click',
      category,
      extraInfo: {
        product,
        isEnabled
      }
    }),
    enableMustHave: (product: CashFundProduct | CookedProduct | CookedProductCatalogItem | null, isEnabled) => ({
      action: 'EnableMustHave',
      actionType: 'click',
      category,
      extraInfo: {
        product: { ...(product ?? {}), mustHave: isEnabled }
      }
    }),
    groupGiftingPaymentMethodSelect: ({ product, paymentMethod }: { product: CashFundProduct | CookedProduct | null; paymentMethod?: string }) => ({
      action: 'groupGiftingPaymentMethodSelect',
      actionType: 'click',
      category,
      extraInfo: {
        product,
        paymentMethod
      }
    }),
    editRegistryItemSaveButton: ({
      product,
      quantity,
      isGroupGiftingEnabled,
      isGetCashEquivalent,
      paymentMethod,
      userName,
      hasAttemptedDataExtraction = false,
      dataExtractionSuccess = false
    }: {
      product: CashFundProduct | CookedProduct | null;
      quantity: number;
      isGroupGiftingEnabled?: boolean;
      paymentMethod?: string;
      userName?: string;
      hasAttemptedDataExtraction?: boolean;
      dataExtractionSuccess?: boolean;
      isGetCashEquivalent?: boolean;
    }) => ({
      action: 'EditRegistryItemSaveButton',
      actionType: 'click',
      category,
      extraInfo: {
        product,
        quantity,
        isGroupGiftingEnabled,
        isGetCashEquivalent,
        paymentMethod,
        userName,
        hasAttemptedDataExtraction,
        dataExtractionSuccess
      }
    }),
    manualProductDataExtractionAttempt: ({ domain, success }: { domain: string; success: boolean }) => ({
      action: 'ManualProductDataExtractionAttempt',
      category,
      extraInfo: {
        domain,
        success
      }
    }),
    clickThankYou: (label: string) => ({
      action: 'ThankBtn',
      actionType: 'click',
      category: 'admin.registry.manage.tracking',
      label
    }),
    trackRegistryNavMenuItemClicked: (label: string) => ({
      category: 'globalnav.registry',
      action: 'OpenPage',
      actionType: 'click',
      label,
      extraInfo: {
        label,
        section: 'RegistryNavMenu'
      }
    }),
    trackRegistryTitleClicked: (label: string) => ({
      category: 'globalnav.registry',
      action: 'RegistryTitle',
      actionType: 'click',
      label,
      extraInfo: {
        label,
        section: 'RegistryHeader'
      }
    }),
    clickExploreAllBenefits: (label: string) => ({
      category: 'admin.registry.overview',
      action: 'RegistryOverviewInteraction',
      actionType: undefined,
      label,
      extraInfo: {
        label,
        section: 'RegistryPerks',
        component: 'Registry Perks',
        method: 'Explore All Benefits'
      }
    }),
    clickBookAnAppointment: () => ({
      category: 'admin.registry.overview',
      action: 'RegistryOverviewInteraction',
      actionType: undefined,
      extraInfo: {
        component: 'Virtual Consultation',
        method: 'Book an Appointment'
      }
    }),
    clickViewRegistry: () => ({
      category,
      action: 'AdminRegistryQuickLinkInteraction',
      label: 'View Registry',
      extraInfo: {
        component: 'Cash Fund Added confirmation',
        method: 'View Registry'
      }
    }),
    shipNowClicked: (orderId: string) => ({
      category,
      action: 'ButtonInteracted',
      label: 'Ship Order Now',
      extraInfo: {
        orderId
      }
    }),
    resolveIssueClicked: (orderId: string) => ({
      category: 'admin.registry.manage.tracking.sg4c',
      action: 'ButtonInteracted',
      label: 'Resolve Issue',
      extraInfo: {
        name: 'resolveIssueClicked',
        orderId
      }
    }),
    refundIssueResolved: (props: { orderId: string; registryItem: CookedOrderLine; method: SalesOrderRefundMethod; amount: number }) => ({
      category: 'admin.registry.manage.tracking.sg4c',
      action: 'ButtonInteracted',
      label: 'Resolve Issue',
      extraInfo: {
        name: 'refundIssueResolved',
        ...props
      }
    }),
    setupJoyWalletDisplayed: (props: { orderId: string; registryItem: CookedOrderLine; method?: SalesOrderRefundMethod; amount: number }) => ({
      category: 'admin.registry.manage.tracking.sg4c',
      action: 'ButtonInteracted',
      label: 'Resolve Issue',
      extraInfo: {
        name: 'setupJoyWalletDisplayed',
        ...props
      }
    }),
    setupJoyWalletStarted: (props: { orderId: string; registryItem: CookedOrderLine; method?: SalesOrderRefundMethod; amount: number }) => ({
      category: 'admin.registry.manage.tracking.sg4c',
      action: 'ButtonInteracted',
      label: 'Resolve Issue',
      extraInfo: {
        name: 'setupJoyWalletDisplayed',
        ...props
      }
    }),
    kycForSgfcCompleted: (props: { orderId: string; registryItem: CookedOrderLine; method?: SalesOrderRefundMethod; amount: number }) => ({
      category: 'admin.registry.manage.tracking.sg4c',
      action: 'ButtonInteracted',
      label: 'Resolve Issue',
      extraInfo: {
        name: 'kycForSgfcCompleted',
        ...props
      }
    })
  },
  pages: pageTracking
});

const { TelemetryProvider, useTelemetry } = createTelemetry(registryTelemetry, { eventId: '', section: '' });

export { TelemetryProvider, useTelemetry as useAdminRegistryTelemetry };

export type RegistryTelemetryObject = ReturnType<typeof useTelemetry>;
