import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledSummary = styled(Flex)``;
const summaryStyles: StyleSystemProps = {
  flexDirection: 'column',
  padding: `0 ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)}`
};

export const StyledSummaryListItemContainer = styled(Flex)``;
const summaryListItemContainerStyles: StyleSystemProps = {
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const StyledWrapper = styled(Box)``;
const wrapperStyles: StyleSystemProps = {
  paddingBottom: `${pxToRem(8)}`
};

export const styles = {
  summaryStyles,
  summaryListItemContainerStyles,
  wrapperStyles
};
