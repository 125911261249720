const writeTestId = <T extends string>(hook: T): WriteTestId<T> => ({ 'data-testid': hook });
export const prefixSymbol = Symbol('prefix');

export interface WithPrefixSymbol {
  [prefixSymbol]: string;
}

interface WriteTestId<T extends string> {
  'data-testid': T;
}

type NamesToSelectorObject<R, Acc extends { [prefixSymbol]?: string } = { [prefixSymbol]?: string }> = R extends [...infer T]
  ? T extends []
    ? Acc
    : T extends [infer First, ...infer Rest]
    ? First extends string
      ? Rest extends string[]
        ? NamesToSelectorObject<Rest, Acc & { [K in First]: WriteTestId<K> }>
        : Acc
      : Acc
    : Acc
  : never;

export type SelectorObject<Prefix, Selectors> = { [prefixSymbol]?: Prefix } & NamesToSelectorObject<Selectors>;

/**
 * CSS selector version of the the data-testid attributes intended to be used in page objects and unit tests
 *
 * @example <caption>This takes in a prefix followed by elementNames like </caption>
 *  ```pageSelectorGenerator('createweddingform', ['addButton', 'someOtherThing'])```
 *
 * the prefix  specifies the grouping, i.e "createweddingform"
 *
 * and turns it into
 *
 * ```{
 *       addButton: '[data-testid="createweddingform-addButton"]',
 *       someOtherThing: '[data-testid="createweddingform-someOtherThing]'
 * }```
 *
 * Usage:
 * - apply the selectors to your component: <ReactComponent {...selectors.addButton} />
 * - `getByTestId(Selectors.addButton)` in Jest/Enzyme unit tests
 * - passing read into pageInteractor object to generate page.clickAddButton() etc. methods
 */
export const pageSelectorGenerator = <P extends string, T extends string[]>(selectorPrefix: P, selectors: readonly [...T]): SelectorObject<P, T> => {
  const writeSelectorsIdMap = new Map(selectors.map(value => [value, writeTestId(selectorPrefix + value)]));
  const selectorObject = Object.fromEntries(writeSelectorsIdMap) as NamesToSelectorObject<T>;
  selectorObject[prefixSymbol] = selectorPrefix;
  return selectorObject as SelectorObject<P, T>;
};
