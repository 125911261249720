import { styled, Box, TextV2 } from '@withjoy/joykit';

export const StyledBox = styled(Box)`
  width: 480px;
  box-shadow: 0 50px 100px rgba(44, 41, 37, 0.25), 0 30px 60px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const StyledTitleText = styled(TextV2)`
  text-align: center;
  color: #000000;
`;

export const StyledSubText = styled(TextV2)`
  text-align: center;
`;
