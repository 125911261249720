import { TextV1Props, TooltipV2 } from '@withjoy/joykit';
import React, { useEffect, useRef, useState } from 'react';
import { StyledText } from './ClampedText.styles';

interface Props
  extends Readonly<{
    clampAt?: number;
    enablePermanentTooltip?: boolean;
    disableTooltip?: boolean;
    tooltipContent?: string | JSX.Element;
  }> {}

interface WrapperProps
  extends Readonly<{
    isTooltiped: boolean;
    content?: string | JSX.Element;
  }> {}

const Wrapper: React.FC<WrapperProps> = ({ children, content, isTooltiped }) => {
  if (isTooltiped && content) {
    return (
      <TooltipV2 content={content} placement="top" triggerType="hover">
        {children}
      </TooltipV2>
    );
  }
  return <div>{children}</div>;
};
const TextWithTooltip: React.FC<Props & TextV1Props> = ({ children, clampAt, enablePermanentTooltip, disableTooltip, tooltipContent, ...props }) => {
  const [isToolTiped, setIsToolTiped] = useState(false);
  const [textContent, setTextContent] = useState<string | JSX.Element>('');
  const textProps = props as TextV1Props;
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (enablePermanentTooltip) {
      setIsToolTiped(true);
      setTextContent(tooltipContent ?? '');
      return;
    }
    if (!disableTooltip) {
      const reference: HTMLElement | undefined = textRef?.current?.children[0] as HTMLElement;

      if ((reference.offsetHeight || 0) < (reference.scrollHeight || 0)) {
        setIsToolTiped(true);
        setTextContent(tooltipContent ?? reference.textContent ?? '');
      }
    }
  }, [children, disableTooltip, enablePermanentTooltip, tooltipContent]);

  return (
    <Wrapper isTooltiped={isToolTiped} content={textContent}>
      <div ref={textRef}>
        <StyledText clampAt={clampAt} {...textProps}>
          {children}
        </StyledText>
      </div>
    </Wrapper>
  );
};

export default TextWithTooltip;
