/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Play',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            d="M11.5703 7.43838C11.982 7.70035 11.982 8.3013 11.5703 8.56327L6.14543 12.0155C5.70162 12.2979 5.12085 11.9791 5.12085 11.453L5.12085 4.54861C5.12085 4.02255 5.70162 3.70374 6.14543 3.98617L11.5703 7.43838Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M17.1055 11.1566C17.723 11.5496 17.723 12.451 17.1055 12.8439L8.96815 18.0222C8.30243 18.4459 7.43127 17.9677 7.43127 17.1786L7.43127 6.82193C7.43127 6.03284 8.30242 5.55463 8.96815 5.97827L17.1055 11.1566Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
