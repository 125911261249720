import React from 'react';
import { TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

import { MetadataBase } from './MetadaBase';
import { Shipping } from './icons';

type Props = {
  trackingNumber: string;
  shippingCarrier?: string;
};

export const TrackingMetadata = ({ trackingNumber, shippingCarrier }: Props) => {
  const { t2 } = useTranslation('guestRegistry');
  const { tracking } = t2('shoppingCart', 'metadataAtoms');
  return (
    <MetadataBase
      icon={<Shipping />}
      text={tracking}
      content={
        <TextV2 typographyVariant="body1" color="mono12" textDecoration="underline">
          {`${shippingCarrier ? shippingCarrier + ': ' : ''}${trackingNumber}`}
        </TextV2>
      }
    />
  );
};
