import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useTranslation } from '@shared/core';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { useCheckoutInitDataContext } from '../../hooks/useCheckoutInitData';
import { DonationFundPlatformTypeEnum } from '@graphql/generated';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { useCheckoutFunnel, useGetProductData } from '@apps/registry/guest/components/CheckoutDialog/hooks';

export const useExternalCheckoutInstructionsController = () => {
  const {
    formValues,
    toNext,
    route,
    context: { isAffiliate, isGroupGifting, isGiftWrapPurchased },
    currentOrderIdState
  } = useCheckoutFunnel(({ toNext, formValues, context, route, currentOrderIdState }) => [
    toNext,
    formValues,
    context.isAffiliate,
    context.isGroupGifting,
    context.isGiftWrapPurchased,
    route,
    currentOrderIdState
  ]);
  const { product, productImageSrc, siteName, currency } = useGetProductData('externalCheckoutInstructions');
  const location = useLocation();
  const history = useHistory();

  const { registryItem, order } = useCheckoutInitDataContext();
  const checkoutDialogContext = useCheckoutDialogContext();
  const { formatCurrency } = useCurrencyFormatter();
  const { t } = useTranslation('guestRegistry');
  const reserveItemTranslation = t('alreadyReserveSection');

  const { title, alertTitle, alertText, alertTextAffiliate, stepsGiftWrap, copyButton, gotItButton } = t('checkoutDialog', 'screens', 'externalCheckoutInstructions');
  const platformType = formValues.paymentMethod || registryItem?.donationFund?.platform?.type || order?.platformType || DonationFundPlatformTypeEnum.other;

  const formattedAmount = formatCurrency({
    priceFloatingPointDecimalString: `${formValues.amount ?? order?.amount?.floatingPointDecimalString}`,
    priceCurrencyCode: isGroupGifting
      ? DEFAULT_CURRENCY
      : registryItem?.donationFund?.currency?.code || checkoutDialogContext.registryState?.registryCurrencyCode || DEFAULT_CURRENCY,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });

  const affiliateStepsTranslations = {
    step1: stepsGiftWrap.step1.affiliate({ retailer: siteName }),
    step2: stepsGiftWrap.step2.affiliate({ retailer: siteName }),
    step3: stepsGiftWrap.step3.affiliate()
  };

  const cashFundStepsTranslations = useMemo(() => {
    switch (platformType) {
      case DonationFundPlatformTypeEnum.venmo:
        return {
          step1: stepsGiftWrap.step1.venmo(),
          step2: stepsGiftWrap.step2.venmo(),
          step3: stepsGiftWrap.step3.venmo({ couplesAccount: registryItem?.donationFund?.platform?.username, amount: formattedAmount })
        };

      case DonationFundPlatformTypeEnum.paypal:
        return {
          step1: stepsGiftWrap.step1.paypal(),
          step2: stepsGiftWrap.step2.paypal(),
          step3: stepsGiftWrap.step3.paypal({ couplesAccount: registryItem?.donationFund?.platform?.username, amount: formattedAmount })
        };

      case DonationFundPlatformTypeEnum.cashapp:
        return {
          step1: stepsGiftWrap.step1.cashapp(),
          step2: stepsGiftWrap.step2.cashapp(),
          step3: stepsGiftWrap.step3.cashapp({ couplesAccount: registryItem?.donationFund?.platform?.username, amount: formattedAmount })
        };

      case DonationFundPlatformTypeEnum.other:
        return {};

      default:
        return {};
    }
  }, [platformType, stepsGiftWrap.step1, stepsGiftWrap.step2, stepsGiftWrap.step3, registryItem?.donationFund?.platform?.username, formattedAmount]);

  const handleGotItButton = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('pcId');
    queryParams.delete('order_id');
    queryParams.delete('item_id');
    queryParams.delete('is_affiliate');
    queryParams.delete('item_name');
    queryParams.delete('item_price');
    history.replace({
      search: queryParams.toString()
    });
    toNext();
  }, [history, location.search, toNext]);

  return {
    isGiftWrapPurchased,
    registryItem,
    handleToNext: handleGotItButton,
    translations: {
      title,
      alertTitle: isAffiliate ? undefined : alertTitle,
      alertText: isAffiliate ? alertTextAffiliate : alertText,
      ...(isAffiliate ? affiliateStepsTranslations : cashFundStepsTranslations),
      copyButton,
      gotItButton
    },
    product,
    productImageSrc,
    isAffiliate,
    formValues,
    currency,
    isLoading: route === 'externalCheckoutInstructionsGiftWrapLoading',
    currentOrderIdState,
    reserveItemTranslation
  };
};
