import { CookedProduct, isCustomDonationFundItem } from '@apps/registry/common/selectors/ProductListSelector';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { usePurchaseConfirmationProvider } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.provider';
import { RegistryOrderFragment } from '@graphql/generated';

export const useProductListContainerController = () => {
  const { updateDataProvider } = useGuestRegistryState();
  const { openModal } = usePurchaseConfirmationProvider();

  const openPurchaseConfirmationModal = ({ product, reservedOrder }: { product: CookedProduct; reservedOrder: RegistryOrderFragment }) => {
    const isDonationFundItem = isCustomDonationFundItem(product);
    const itemQuantity = reservedOrder.lineItems.find(lineItem => {
      return lineItem.quantity;
    })?.quantity;

    updateDataProvider({
      type: isDonationFundItem ? 'initial' : 'purchased',
      productId: product.id,
      quantity: Math.max(itemQuantity ? itemQuantity / 100 : 0, 0),
      order: {
        orderId: reservedOrder.id
      },
      guest: {
        name: '',
        email: ''
      }
    });

    openModal({
      orderId: reservedOrder.id,
      productId: product.id
    });
  };

  return {
    openPurchaseConfirmationModal
  };
};
