import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from '@shared/core';
import { useEndRegistrySessionMutation } from '@graphql/generated';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

interface ChangeEmailDialogControllerProps {
  onSubmitEmail: (email: string) => void;
  mySessionEmail: string;
}

export interface ReserveItemFields {
  email: string;
}

export const useChangeEmailDialogController = ({ onSubmitEmail, mySessionEmail }: ChangeEmailDialogControllerProps) => {
  const { t: tForm } = useTranslation('generalFormValidation');
  const invalidEmail = tForm('invalidEmail');
  const telemetry = useGuestRegistryTelemetry();

  const [endRegistrySession] = useEndRegistrySessionMutation({
    refetchQueries: () => ['GetEventRegistriesAndOrders']
  });

  const formik = useFormik<ReserveItemFields>({
    initialValues: {
      email: mySessionEmail
    },
    validationSchema: Yup.object<ReserveItemFields>({
      email: Yup.string().email(invalidEmail()).required()
    }),
    onSubmit: values => {}
  });

  const handleOnSubmit = async () => {
    const emailToLowercase = formik.values.email.toLowerCase();
    await endRegistrySession();

    telemetry.authenticatedSession(emailToLowercase, 'AuthenticatedSessionClear');
    onSubmitEmail(emailToLowercase);
  };

  return {
    formik,
    handleOnSubmit
  };
};
