import React from 'react';
import { Flex, Stack } from '@withjoy/joykit';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { StyledHeadText, StyledText, StyledCancelLink, StyledSuccessGuestButton } from '../../Purchase.styles';
import { wrapperUndoStyles } from './UndoContent.styles';

interface UndoContent
  extends Readonly<{
    handleUndo: (methodText: string) => void;
    handleCancelUndo: () => void;
    methodText: string;
  }> {}

export const UndoContent: React.FC<UndoContent> = ({ handleUndo, handleCancelUndo, methodText }) => {
  const { getUndoPurchaseTranslations } = useRegistryGuestTranslations();
  const { undoPurchaseTitle, undoPurchaseBody, undoPurchaseButtonText, undoPurchaseCancelText } = getUndoPurchaseTranslations();
  return (
    <Stack __css={wrapperUndoStyles}>
      <StyledHeadText textAlign="center">{undoPurchaseTitle}</StyledHeadText>
      <Flex alignItems="center" justifyContent="center" paddingX={6}>
        <StyledText textAlign="center">{undoPurchaseBody}</StyledText>
      </Flex>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX={[6, null, '0px']}>
        <StyledSuccessGuestButton
          intent="secondary"
          marginBottom={5}
          fill={true}
          onClick={() => {
            handleUndo(methodText);
          }}
        >
          {undoPurchaseButtonText}
        </StyledSuccessGuestButton>
        <StyledCancelLink onClick={handleCancelUndo}>{undoPurchaseCancelText}</StyledCancelLink>
      </Flex>
    </Stack>
  );
};
