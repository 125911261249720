import { Box } from '@withjoy/joykit';
import React from 'react';
import { styles, StyledDefaultEmailText, StyledDefaultEmailLink } from './DefaultEmailField.styles';

export interface DefaultEmailFieldProps {
  defaultEmailFieldLabel: string;
  changeFieldButtonLabel: string;
  onLinkClick: () => void;
}

export const DefaultEmailField = ({ defaultEmailFieldLabel, changeFieldButtonLabel, onLinkClick }: DefaultEmailFieldProps) => (
  <Box>
    <StyledDefaultEmailText typographyVariant="body1">{defaultEmailFieldLabel}</StyledDefaultEmailText>
    <StyledDefaultEmailLink typographyVariant="body1" __css={styles.defaultEmailLink} onClick={onLinkClick}>
      {changeFieldButtonLabel}
    </StyledDefaultEmailLink>
  </Box>
);
