import React from 'react';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { Flex, TextV2, LinkV2 } from '@withjoy/joykit';
import { useSpring, animated } from 'react-spring';
import { StyledProductTitle, StyledProductSummaryWrapper, styles } from './ProductSummary.styles';
import { isPricedProduct as checkIfIsPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { cubicBezierEasingFn } from '@shared/utils/animationTransition';
import { usePurchaseDialogTranslations } from '../../../hooks';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';

interface ProductSummaryProps {
  totalQuantity: number;
  isIn: boolean;
  product: CookedProduct;
  transitionDuration?: number;
  currency?: string;
  showShippingAndHandlingCta?: boolean;
}

export const ProductSummary = ({ product, totalQuantity, isIn, transitionDuration = 250, currency, showShippingAndHandlingCta }: ProductSummaryProps) => {
  const styleProps = useSpring({
    opacity: isIn ? 1 : 0,
    height: isIn ? '100%' : '1px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    config: {
      duration: transitionDuration,
      easing: cubicBezierEasingFn
    }
  });

  const { formatCurrency } = useCurrencyFormatter();

  const totalPrice = formatCurrency({
    priceFloatingPointDecimalString: (totalQuantity * (product.numberPrice || 0)).toString(),
    priceCurrencyCode: currency || DEFAULT_CURRENCY,
    formatForm: 'short',
    shouldCalculateCharmPrice: true
  });

  const { quantityTimesPrice, quantityTitle, totalPriceTitleText, learnMore, shippingInfo } = usePurchaseDialogTranslations();
  return (
    <animated.div style={styleProps}>
      <StyledProductSummaryWrapper __css={styles.productSummaryWrapper}>
        {product.brand && (
          <TextV2 typographyVariant="hed1" marginBottom={1}>
            {product.brand}
          </TextV2>
        )}
        <StyledProductTitle typographyVariant="hed1" __css={styles.productTile}>
          {product.title}
        </StyledProductTitle>
        {checkIfIsPricedProduct(product) && (
          <Flex columnGap={3} alignItems="baseline" marginBottom={3}>
            <TextV2 color="mono13" typographyVariant="label2">
              {totalPriceTitleText({ total: totalPrice })}
            </TextV2>
            <TextV2 color="mono10" typographyVariant="label2">
              {`${quantityTitle()} ${quantityTimesPrice({ quantity: totalQuantity, price: product.price })}`}
            </TextV2>
          </Flex>
        )}
        {showShippingAndHandlingCta && (
          <>
            <TextV2 color="mono12" typographyVariant="label2" as="span">
              {shippingInfo()}{' '}
              <LinkV2 href="https://withjoy.com/shipping-policy" target="__blank" typographyVariant="label2" size="sm" color="mono14">
                {learnMore()}
              </LinkV2>
            </TextV2>
          </>
        )}
      </StyledProductSummaryWrapper>
    </animated.div>
  );
};
