import { useCallback } from 'react';
import { useCurrencyContext } from './currency.provider';
import { CurrencyFormatterArgs } from './types';

export const useCurrencyFormatter = () => {
  const currency = useCurrencyContext();

  const formatCurrency = useCallback(
    (args: CurrencyFormatterArgs) => {
      const { priceFloatingPointDecimalString, priceCurrencyCode, formatForm, formatPrecision, shouldRoundPriceUp, shouldCalculateCharmPrice, canShowFreeLabel } = args;

      const floatingPointDecimalString = priceFloatingPointDecimalString ?? '0';

      if (canShowFreeLabel && ['0', '0.00'].includes(floatingPointDecimalString)) {
        return 'FREE';
      }

      const selectedPrice = shouldRoundPriceUp
        ? Math.ceil(+floatingPointDecimalString)
        : shouldCalculateCharmPrice && floatingPointDecimalString.endsWith('.00')
        ? +floatingPointDecimalString - 0.01
        : +floatingPointDecimalString;

      const formattedPrice = currency.formatCurrency(selectedPrice, { currency: priceCurrencyCode, form: formatForm, precision: formatPrecision });

      return formattedPrice;
    },
    [currency]
  );
  return {
    formatCurrency
  };
};
