import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useTranslation } from '@shared/core';
import { useCallback } from 'react';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useGetProductData, useCheckoutTelemetryData } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { addAction } from '@shared/utils/logger';

export const useCancelGiftWarningController = () => {
  const {
    confirmGiftCancel,
    abortGiftCancel,
    context: { isAffiliate, isGroupGifting }
  } = useCheckoutFunnel(({ confirmGiftCancel, abortGiftCancel, context }) => [confirmGiftCancel, abortGiftCancel, context.isAffiliate, context.isGroupGifting]);
  const { productImageSrc, product, currency } = useGetProductData('externalCheckoutReview');
  const { undoReserveItem } = useGuestRegistryTelemetry();
  const { t2 } = useTranslation('guestRegistry');

  const translations = t2('checkoutDialog', 'screens', 'cancelGiftWarning');
  const { registryId, registryItemId, productTitle, reservedQty, priceValueInMinorUnits, priceCurrencyCode } = useCheckoutTelemetryData();

  const handleConfirmGiftCancel = useCallback(() => {
    const undoReserveItemData = {
      productTitle,
      registryItemId,
      reservedQty,
      priceValueInMinorUnits,
      priceCurrencyCode,
      method: '',
      registryId,
      isGroupGiftingEnabled: isGroupGifting
    };
    undoReserveItem(undoReserveItemData);
    addAction('undoReserveItem', undoReserveItemData);
    confirmGiftCancel();
  }, [confirmGiftCancel, isGroupGifting, priceCurrencyCode, priceValueInMinorUnits, productTitle, registryId, registryItemId, reservedQty, undoReserveItem]);

  return {
    confirmGiftCancel: handleConfirmGiftCancel,
    abortGiftCancel,
    translations,
    isAffiliate,
    productImageSrc,
    product,
    currency
  };
};
