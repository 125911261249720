import { useTranslation } from '@shared/core/i18n/useTranslation';
import { useEventInfo } from '@shared/utils/eventInfo';

export function useRegistryHost(): string {
  const { eventInfo } = useEventInfo();
  const { t2 } = useTranslation('guestRegistry');
  const { defaultValue } = t2('host');

  return eventInfo?.eventDisplayName || defaultValue;
}
