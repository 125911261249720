import { Box, styled, Flex, TextV2, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { ItemBaseVariant } from './ItemBase';

const defaultImage = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

export const StyledItemBox = styled(Box)<{ variant?: ItemBaseVariant }>`
  background: ${props => (props.variant === 'transparent' ? 'transparent' : props.theme.colors.white)};
  padding-top: ${pxToRem(24)};
`;

export const StyledGridBox = styled(Box)<{ inStock?: boolean }>`
  padding: ${({ inStock }) => pxToRem(inStock ? 16 : 4)} 0;
  margin: 0 ${({ inStock }) => pxToRem(inStock ? 16 : 4)};
`;

export const StyledProductPhotoBox = styled(Box)`
  align-items: center;
  height: ${pxToRem(96)};
  width: ${pxToRem(96)};
`;

export const StyledProductPhoto = styled.div<{ backgroundImage?: string | null }>`
  align-self: center;
  position: relative;
  height: 100%;
  width: 100%;
  ::after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.25s;
    transform: scale(1.0001);
    ${({ backgroundImage }) => {
      return {
        backgroundImage: `url("${backgroundImage || defaultImage}")`,
        backgroundSize: backgroundImage ? 'contain' : '50%'
      };
    }}
  }
`;
export const StyledTitleText = styled(TextV2)`
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledFlex = styled(Flex)`
  margin-top: ${pxToRem(8)};
  justify-content: space-between;
  align-items: center;
`;

const titleTextStyles: StyleSystemProps = {
  marginY: 1,
  display: '-webkit-box',
  overflow: 'hidden'
};

const outOfStockGridBoxStyles: StyleSystemProps = {
  border: '1px solid',
  borderColor: 'negative6',
  borderRadius: pxToRem(4),
  display: 'flex',
  flexDirection: 'column',
  margin: pxToRem(16)
};

export const StyledOutOfStockMessageBox = styled(Flex)``;
const outOfStockMessageBoxStyles: StyleSystemProps = {
  backgroundColor: 'negative1',
  color: 'negative6',
  borderRadius: `${pxToRem(4)} ${pxToRem(4)} 0 0`,
  padding: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(16)}`,
  justifyContent: 'space-between'
};

export const styles = {
  titleText: titleTextStyles,
  outOfStockGridBox: outOfStockGridBoxStyles,
  outOfStockMessageBox: outOfStockMessageBoxStyles
};
