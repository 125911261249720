import { useTranslation } from '@shared/core';
import { useEventInfo } from '@shared/utils/eventInfo';
import { PLATFORM_LABELS } from '../../CheckoutDialog.constants';
import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';

export const useConfirmedPurchaseController = () => {
  const { formValues, toNext } = useCheckoutFunnel(({ formValues, toNext }) => [formValues, toNext]);
  const { eventInfo } = useEventInfo();
  const { t } = useTranslation('guestRegistry');
  const translations = t('checkoutDialog', 'screens', 'confirmedPurchase');
  const platformLabel = formValues?.paymentMethod && formValues?.paymentMethod !== 'joyCredit' ? PLATFORM_LABELS[formValues?.paymentMethod] : '';

  return {
    formValues,
    toNext,
    translations,
    platformLabel,
    hostName: eventInfo?.eventDisplayName
  };
};
