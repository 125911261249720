import React, { useEffect } from 'react';
import { Box, SpacingStack, TextV2 } from '@withjoy/joykit';
import { LayoutShell } from '../../components/LayoutShell';
import { ContentHeader, IntegratedGiftCard, PrimaryCta, ShippingAddressReference } from '@apps/registry/guest/components/CheckoutDialog/components';
import { usePaperCheckoutInstructionsController } from './PaperCheckoutInstructionsGiftWrap.controller';
import { PaperCheckoutInstructionsSelectors } from './PaperCheckoutInstructionsGiftWrap.selectors';
import { StyledListContainer, StyledListItemContainer, StyledListItemNumber, styles } from './PaperCheckoutInstructionsGiftWrap.styles';
import { CheckIcon } from '@assets/icons';
import { Skeleton } from '@shared/components/Skeleton';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';

export const PaperCheckoutInstructionsGiftWrap = () => {
  const {
    toNext,
    translations,
    isCashFundType,
    shippingAddress,
    steps,
    isGiftWrapPurchased,
    isLoading,
    currentOrderIdState,
    reserveItemTranslation,
    formValues
  } = usePaperCheckoutInstructionsController();
  const { title, cta, subtitle } = translations;
  const { alreadyReservedGiftViewed } = useGuestRegistryTelemetry();

  useEffect(() => {
    if (currentOrderIdState === undefined) {
      alreadyReservedGiftViewed({ label: formValues.email ?? '' });
    }
  }, [alreadyReservedGiftViewed, currentOrderIdState, formValues.email]);

  return (
    <LayoutShell {...PaperCheckoutInstructionsSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="float" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          <IntegratedGiftCard mobileRenderBehavior={isCashFundType ? 'fullBleed' : 'card'} />
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          {currentOrderIdState === null && (
            <Box>
              <Skeleton height={6} paddingRight={9} />
              <Skeleton marginTop={7} height={10} paddingRight={9} />
            </Box>
          )}
          {currentOrderIdState === undefined && (
            <Box>
              <TextV2 typographyVariant="hed4" paddingRight={9}>
                {reserveItemTranslation.title()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} paddingRight={9}>
                {reserveItemTranslation.subTitle()}
              </TextV2>
              <TextV2 typographyVariant="body1" marginTop={7} style={{ wordBreak: 'break-all' }}>
                {reserveItemTranslation.checkEmail({ email: formValues.email })}
              </TextV2>
            </Box>
          )}
          {typeof currentOrderIdState === 'string' && (
            <>
              <ContentHeader>
                <ContentHeader.Hed>{title()}</ContentHeader.Hed>
              </ContentHeader>
              <StyledListContainer spacing={5} __css={styles.listContainer}>
                {steps.map((step, index) => {
                  const isCompleted = isGiftWrapPurchased && index === 0;

                  return (
                    <StyledListItemContainer __css={styles.listItemContainer} key={index}>
                      <StyledListItemNumber __css={{ ...styles.listItemNumber, ...(isCompleted ? styles.listItemNumberCompleted : {}) }}>
                        {isCompleted ? <CheckIcon /> : index + 1}
                      </StyledListItemNumber>
                      <TextV2
                        typographyVariant="body1"
                        textDecoration={isCompleted ? 'line-through' : undefined}
                        dangerouslySetInnerHTML={{
                          __html: step || ''
                        }}
                      />
                    </StyledListItemContainer>
                  );
                })}
              </StyledListContainer>

              <SpacingStack spacing={7} marginTop={7}>
                <TextV2 typographyVariant="hed4">{subtitle()}</TextV2>
                <ShippingAddressReference shippingAddress={shippingAddress} />
                <PrimaryCta {...PaperCheckoutInstructionsSelectors.GotItButton} onClick={toNext} loading={isLoading}>
                  {cta()}
                </PrimaryCta>
              </SpacingStack>
            </>
          )}
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
