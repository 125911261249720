import { shimmerMixin } from '@shared/components/Skeleton/Skeleton.styles';
import { StyleSystemProps, Box, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const DEFAULT_IMAGE = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

export const StyledLayoutShellContainer = styled(Box)``;
const layoutShellContainerStyles: StyleSystemProps = {
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  backgroundColor: 'white',
  flexDirection: {
    _: 'column',
    sm2: 'row'
  },
  paddingBottom: 8,
  paddingTop: 9
};

export const StyledContentPanelContainer = styled(Box)``;
const contentPanelContainerStyles: StyleSystemProps = {
  position: 'relative',
  flex: {
    _: '1 0 100%',
    sm2: '0 0 calc(50% - 20px)'
  },
  display: 'flex',
  flexDirection: 'column',
  paddingX: 6
};

export const StyledMediaPanelContainer = styled(Box)``;
const mediaPanelContainerStyles = (fullBleedBackground: boolean): StyleSystemProps => ({
  flex: {
    _: '1 0 100%',
    sm2: '0 0 calc(50% - 20px)'
  },
  maxWidth: {
    sm2: 'calc(50% - 20px)'
  },
  display: 'flex',
  alignItems: 'center',
  marginBottom: 7,
  flexDirection: 'column',
  marginRight: { _: 0, sm2: 8 },
  background: fullBleedBackground ? { _: 'linear-gradient(180deg, #f7f7f7 78.65%, #f7f7f7 100%)', sm2: 'white' } : 'white'
});

export const StyledProductImage = styled(Box)``;
export const productImageStyles = (image: string): StyleSystemProps => ({
  margin: {
    _: '0 auto',
    sm2: 6
  },
  minWidth: pxToRem(265),
  minHeight: pxToRem(265),
  maxWidth: pxToRem(275),
  maxHeight: pxToRem(275),
  alignSelf: 'center',
  backgroundImage: image ? `url(${image})` : `url(${DEFAULT_IMAGE})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  mixBlendMode: 'multiply',
  backgroundPosition: 'center center'
});

export const SkeletonImage = styled(Box)`
  ${shimmerMixin}
`;

const skeletonImageStyles: StyleSystemProps = {
  margin: 6,
  minWidth: '265px',
  minHeight: '265px',
  maxWidth: '275px',
  maxHeight: '275px',
  alignSelf: 'center'
};

export const styles = {
  layoutShellContainer: layoutShellContainerStyles,
  contentPanelContainer: contentPanelContainerStyles,
  mediaPanelContainer: mediaPanelContainerStyles,
  productImage: productImageStyles,
  skeletonImage: skeletonImageStyles
};
