import useLoadGoogleFont from '@apps/saveTheDate/hooks/useLoadGoogleFont';
import { Box, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { IGiftWrapTheme } from '../GiftWrapTypes';
import { GiftWrapGiftPreview } from './GiftWrapGiftPreview';
import { GiftWrapMessage } from './GiftWrapMessage';
import { GiftWrapFooter } from './GiftWrapFooter';
import { GiftWrapProduct } from '../GiftWrapTypes';
import { JoyLogo } from '@assets/index';
import { useTranslation } from '@shared/core';
import { isGiftWrapProduct } from '@shared/components';

interface GiftWrapDetailsProps {
  theme: IGiftWrapTheme;
  message: string;
  product?: GiftWrapProduct | undefined;
  showBranding?: boolean;
  getMessageComponent?: () => JSX.Element;
}

export const GiftWrapDetails: React.FC<GiftWrapDetailsProps> = ({ theme, product, message, showBranding, getMessageComponent }) => {
  useLoadGoogleFont(['Shadows Into Light Two']);

  const textColor = theme?.text || 'mono12';
  const giftWrapProduct = isGiftWrapProduct(product) ? product : undefined;
  const { t } = useTranslation('guestRegistry');
  const tGiftCard = t('checkoutGiftCard');

  return (
    <>
      {getMessageComponent?.() ?? (
        <Box position="relative">
          <GiftWrapMessage textColor={textColor} message={message} />
        </Box>
      )}
      {giftWrapProduct && (
        <GiftWrapGiftPreview
          image={giftWrapProduct.image}
          store={giftWrapProduct.store}
          title={giftWrapProduct.title}
          extraProductsCount={giftWrapProduct.extraProductsCount}
          link={giftWrapProduct?.link}
          textColor={textColor}
        />
      )}
      <GiftWrapFooter footerImage={theme?.accent} />
      {showBranding && (
        <TextV2
          verticalAlign="middle"
          onClick={event => {
            window.open('https://www.withjoy.com', '_blank');
            event?.stopPropagation();
          }}
          cursor={'pointer'}
          display="flex"
          marginTop={0}
          marginBottom={9}
          alignItems="end"
          color={theme?.text}
          fontWeight={'semibold'}
          opacity={0.7}
          _hover={{ opacity: 1 }}
          fontSize={'0.85rem'}
          lineHeight="1.43"
        >
          {tGiftCard.sentWith()} <JoyLogo width="40px" height="18px" />
        </TextV2>
      )}
    </>
  );
};
