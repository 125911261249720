import { styled, Box, Flex } from '@withjoy/joykit';
import { responsiveBreakpoints } from '@apps/registry/common/utils/responsiveHelpers';

export const StyledBox = styled(Box)<{ hideBorder?: boolean | false }>`
  z-index: 4;
  ${props =>
    !props.hideBorder &&
    `
      border-bottom: 1px solid #f2f2f2;
    `}

  width: 100%;
`;

export const StyledFlex = styled(Flex)`
  > button {
    margin-top: 24px;
  }
  @media screen and (min-width: ${responsiveBreakpoints.SIZE_640}) {
    > button {
      margin-top: unset;
    }
  }
`;
