import { InputV2, StyleSystemProps } from '@withjoy/joykit';
import React, { ComponentProps } from 'react';

const inputWrapperErrorStyles: StyleSystemProps = {
  color: 'mono8',
  borderColor: 'negative6',
  borderWidth: '2px',
  _hover: {
    color: 'mono14',
    borderColor: 'negative6'
  },
  _focusWithin: {
    color: 'mono14',
    _after: {
      boxShadow: `0px 0 0px 2px #C50020`
    }
  }
};

const inputWrapperStyles: StyleSystemProps = {
  _hover: {
    borderColor: 'mono14'
  },
  _focusWithin: {
    _after: {
      boxShadow: `0px 0 0px 2px #333333`
    }
  }
};

const inputStyles: StyleSystemProps = {
  color: 'mono14'
};

const inputStartElementStyles: StyleSystemProps = { paddingRight: '1px', color: 'inherit' };

export const Input = (props: ComponentProps<typeof InputV2>) => (
  <InputV2
    overrides={{
      Root: {
        props: props.isInvalid ? inputWrapperErrorStyles : inputWrapperStyles
      },
      Input: {
        props: inputStyles
      },
      StartElement: {
        props: inputStartElementStyles
      },
      ...props.overrides
    }}
    isFullWidth
    {...props}
  />
);
