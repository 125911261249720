import { useTranslation } from '@shared/core';
import { useCurrencyFormatter } from '@shared/utils/currency';
import { SpacingStack, TextV2 } from '@withjoy/joykit';
import React, { useMemo } from 'react';
import { StyledProgressBar, StyledProgressBarFill, styles } from './DonationProgressBar.styles';

interface DonationProgressBarProps {
  amountDonated: string;
  totalAmount: string;
  currencyCode: string;
  userContribution?: number;
}

export const DonationProgressBar = (props: DonationProgressBarProps) => {
  const { amountDonated, totalAmount, currencyCode, userContribution = 0 } = props;

  const { formatCurrency } = useCurrencyFormatter();
  const { t } = useTranslation('guestRegistry');
  const { fundedLabelWithGift, fundedLabelWithoutGift } = t('checkoutDialog', 'donationProgressBar');

  const foundedWithGift = useMemo(() => `${Number.parseFloat(amountDonated ?? 0) + userContribution}`, [amountDonated, userContribution]);
  const formattedFundedWithGiftAmount = useMemo(
    () =>
      formatCurrency({
        priceFloatingPointDecimalString: foundedWithGift,
        priceCurrencyCode: currencyCode,
        formatForm: 'short'
      }),
    [foundedWithGift, currencyCode, formatCurrency]
  );
  const totalAmountFloatingPointDecimalString = totalAmount ?? '0';
  const formattedTotalAmount = useMemo(
    () =>
      formatCurrency({
        priceFloatingPointDecimalString: totalAmount,
        priceCurrencyCode: currencyCode,
        formatForm: 'short'
      }),
    [totalAmount, currencyCode, formatCurrency]
  );

  const progressPercentage = useMemo(() => Math.min(100, (+foundedWithGift / +totalAmountFloatingPointDecimalString) * 100), [
    foundedWithGift,
    totalAmountFloatingPointDecimalString
  ]);

  return (
    <SpacingStack spacing={5}>
      <TextV2 as="p">
        <TextV2 as="span" typographyVariant="hed1">
          {formattedFundedWithGiftAmount}
        </TextV2>{' '}
        <TextV2 as="span" typographyVariant="label2" color="mono10">
          {userContribution ? fundedLabelWithGift({ amount: formattedTotalAmount }) : fundedLabelWithoutGift({ amount: formattedTotalAmount })}
        </TextV2>
      </TextV2>
      <StyledProgressBar __css={styles.progressBar}>
        {/* inline style is set to update the width without the creation of a new style rule */}
        <StyledProgressBarFill __css={styles.progressBarFill} style={{ width: `${progressPercentage}%` }} />
      </StyledProgressBar>
    </SpacingStack>
  );
};
