import { DialogV2 } from '@withjoy/joykit';
import React from 'react';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';
import { GalleryLightbox } from '../GalleryLightbox';

interface PdpGalleryLightboxProps {
  isMobile?: boolean;
  assets: CatalogProductMediaAsset[];
  isOpen: boolean;
  onClose: () => void;
  onClickAsset: (asset: CatalogProductMediaAsset) => void;
  selectedAssetIndex: number;
}

export const PdpGalleryLightbox: React.FC<PdpGalleryLightboxProps> = props => {
  const { isMobile, assets, onClickAsset, isOpen, onClose, selectedAssetIndex } = props;

  return (
    <DialogV2
      isOpen={isOpen}
      size="full"
      onClose={onClose}
      overrides={{
        Header: { props: { padding: '0 !important', top: { _: '0', sm2: '56px' } } },
        Body: { props: { padding: '0 !important', marginTop: { _: '0', sm2: '98px' } } },
        CloseButton: { props: { top: 0 } }
      }}
    >
      <DialogV2.Header>
        <DialogV2.CloseButton color={'mono14'} _hover={{ color: 'mono11', _active: { color: '#000' } }} />
      </DialogV2.Header>
      <DialogV2.Body>
        <GalleryLightbox assets={assets} showSkeleton={!assets?.length} isMobile={isMobile} onClickAsset={onClickAsset} selectedAssetIndex={selectedAssetIndex} />
      </DialogV2.Body>
    </DialogV2>
  );
};
