// safari and firebox do not respect type="number" and pattern="[0-9]*"
const ALLOWED_CHARS_REGEXP = /[0-9.]+/;
const ALLOWED_NON_NUMERICAL_KEYS = new Set(['Backspace', 'Delete', 'ArrowRight', 'ArrowLeft', 'Tab', 'Control', 'Meta']);

export const preventNonNumericalInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const key = event.key;

  if (ALLOWED_NON_NUMERICAL_KEYS.has(key)) {
    return;
  } else if (!ALLOWED_CHARS_REGEXP.test(event.key)) {
    event.preventDefault();
  }
};
