import { useTranslation } from '@shared/core/i18n';

export const useRegistryGuestTranslations = () => {
  const { t, t2 } = useTranslation('guestRegistry');

  const coupleHeaderTranslations = t('coupleHeader');
  const getCoupleHeaderTranslations = () => {
    return {
      dashboardBtn: coupleHeaderTranslations.dashboardBtn(),
      readMore: coupleHeaderTranslations.readMore(),
      readLess: coupleHeaderTranslations.readLess(),
      readMoreText: coupleHeaderTranslations.readMoreText(),
      photoButton: coupleHeaderTranslations.photoButton(),
      addPhotoButton: coupleHeaderTranslations.addPhotoButton(),
      noteButton: coupleHeaderTranslations.noteButton(),
      addNoteButton: coupleHeaderTranslations.addNoteButton(),
      menu: coupleHeaderTranslations.menu(),
      editEvent: coupleHeaderTranslations.editEvent(),
      logout: coupleHeaderTranslations.logout(),
      more: coupleHeaderTranslations.more(),
      defaultName: coupleHeaderTranslations.defaultName
    };
  };

  const productTileTranslations = t('productTile');
  const getProductTileTranslations = () => {
    return {
      purchased: productTileTranslations.purchased(),
      needed: productTileTranslations.needed(),
      purchasedByYou: productTileTranslations.purchasedByYou(),
      reservedByYou: productTileTranslations.reservedByYou(),
      contributionAnyAmountText: productTileTranslations.contributionAnyAmountText(),
      goalReachedText: productTileTranslations.goalReachedText()
    };
  };

  const emptyFilterTranslations = t('emptyFilter');
  const getEmptyFilterTranslations = () => {
    return {
      title: emptyFilterTranslations.title(),
      subtitle: emptyFilterTranslations.subtitle()
    };
  };

  const productDialogTranslations = t('productDialog');
  const getProductDialogTranslations = () => {
    return {
      availableToPurchaseOn: productDialogTranslations.availableToPurchaseOn(),
      clickToBuy: productDialogTranslations.clickToBuy()
    };
  };

  const getPurchaseGiftTranslations = () => {
    return t('purchaseGift');
  };

  const getGiveGiftDialogTranslations = () => {
    return t('giveGiftDialog');
  };

  const getPhotoMenuTranslations = () => {
    const photoMenuTranslations = t('photoMenu');

    return {
      updatePhoto: photoMenuTranslations.updatePhoto(),
      deletePhoto: photoMenuTranslations.deletePhoto(),
      error: photoMenuTranslations.error(),
      success: photoMenuTranslations.success()
    };
  };

  const registryHiddenTranslations = t('registryHidden');
  const getRegistryHiddenTranslations = () => {
    return {
      title: registryHiddenTranslations.title(),
      subtitle: registryHiddenTranslations.subtitle(),
      link: registryHiddenTranslations.link()
    };
  };

  const getRegistryProtectedTranslations = () => {
    return t2('registryProtected');
  };

  const getContactTheHostTranslations = () => {
    return t2('contactTheHost');
  };

  const contributeToFundTranslations = t('contributeToFund');
  const getContributeToFundTranslations = () => {
    return {
      contributeTitle: contributeToFundTranslations.contributeTitle(),
      contributeButtonTextCharity: contributeToFundTranslations.contributeButtonTextCharity(),
      contributeSuggestedNoteLabel: contributeToFundTranslations.contributeSuggestedNoteLabel(),
      contributeCopyText: contributeToFundTranslations.contributeCopyText(),
      contributeCopiedText: contributeToFundTranslations.contributeCopiedText(),
      contributeConfirmationHeader: contributeToFundTranslations.contributeConfirmationHeader(),
      contributeConfirmationBody: contributeToFundTranslations.contributeConfirmationBody(),
      contributeConfirmationButtonText: contributeToFundTranslations.contributeConfirmationButtonText(),
      contributeCancelQuestion: contributeToFundTranslations.contributeCancelQuestion(),
      reservedCashOrCheckDonationTitle: contributeToFundTranslations.reservedCashOrCheckDonationTitle(),
      reservedCashOrCheckDonationInstructions: contributeToFundTranslations.reservedCashOrCheckDonationInstructions(),

      contributeCancelText: contributeToFundTranslations.contributeCancelText(),
      contributeConfirmationTitle: contributeToFundTranslations.contributeConfirmationTitle(),
      contributeConfirmationTitleProduct: contributeToFundTranslations.contributeConfirmationTitleProduct(),
      contributeConfirmationDescription: contributeToFundTranslations.contributeConfirmationDescription(),
      contributeBackToRegistryText: contributeToFundTranslations.contributeBackToRegistryText()
    };
  };

  const getUndoPurchaseTranslations = () => {
    return t2('undoPurchase');
  };

  return {
    getCoupleHeaderTranslations,
    getProductTileTranslations,
    getProductDialogTranslations,
    getPurchaseGiftTranslations,
    getEmptyFilterTranslations,
    getGiveGiftDialogTranslations,
    getRegistryHiddenTranslations,
    getContributeToFundTranslations,
    getUndoPurchaseTranslations,
    getRegistryProtectedTranslations,
    getContactTheHostTranslations,
    getPhotoMenuTranslations
  };
};
