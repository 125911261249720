import { useCallback, useEffect, useState } from 'react';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import { addRendition } from '@shared/utils/photoRendition';

interface CoupleHeaderArgs {
  eventId: string;
  firebaseId?: string;
  website: string;
  isAdmin: boolean;
  registryPhoto?: string;
  pages?: ReadonlyArray<EventPageFragment>;
}

export const useCoupleHeaderController = ({ eventId, pages, registryPhoto }: CoupleHeaderArgs) => {
  const { currentUser, provideLogout } = useAuth();
  const {
    registryOpenFilePicker: openFilePickerTrack,
    editPageNote: editPageNoteTrack,
    guestReadMore: guestReadMoreTrack,
    registryPagePhotoUpdated: registryPagePhotoUpdatedTrack
  } = useGuestRegistryTelemetry();

  const [photo, setPhoto] = useState<string | undefined>(registryPhoto);

  useEffect(() => {
    setPhoto(registryPhoto);
  }, [registryPhoto, setPhoto]);

  const handleOnUpdatePhoto = useCallback(
    previewUrl => {
      setPhoto(previewUrl);
      registryPagePhotoUpdatedTrack('Update');
      openFilePickerTrack();
    },
    [openFilePickerTrack, registryPagePhotoUpdatedTrack]
  );

  const handleDeletePhoto = useCallback(() => {
    setPhoto(undefined);
    registryPagePhotoUpdatedTrack('Delete');
  }, [registryPagePhotoUpdatedTrack]);

  const onEditPageNote = useCallback(() => {
    editPageNoteTrack(eventId);
  }, [editPageNoteTrack, eventId]);

  const onContinueReading = useCallback(() => {
    guestReadMoreTrack();
  }, [guestReadMoreTrack]);

  const onHandleLogout = () => {
    provideLogout({ eventId });
  };

  const originalPages = pages?.filter(page => !page.disabled && page.type !== EventPageType.app);
  const isNavBarVisible = (originalPages?.length ?? 0) > 1;

  return {
    handleOnUpdatePhoto,
    handleDeletePhoto,
    onEditPageNote,
    onContinueReading,
    photo: addRendition({ url: photo, renditionSize: 'small' }),
    currentUser,
    onHandleLogout,
    isNavBarVisible
  };
};
