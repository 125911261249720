import React, { useEffect } from 'react';
import { ButtonV1, Box, TextV1, FormField } from '@withjoy/joykit';
import { SpacingStack } from '@withjoy/joykit';
import { useSharedRegistryTranslations } from '@apps/registry/SharedRegistry.i18n';
import { TextAreaWrapper, StyledFlex, StyledButtonFlex, StyledLink, StyledTextArea, StyledText } from './PageNote.styles';
import { Divider } from '../';
import { usePageNoteController } from './PageNote.controller';
export interface PageNoteProps
  extends Readonly<{
    isMobile?: boolean;
    inDialog?: boolean;
    pageNote?: string | null;
    registryPageId?: string;
    onClose?: () => void;
    eventHandle: string;
    onEditPageNote?(): void;
  }> {}

const PageNote: React.FC<PageNoteProps> = ({ isMobile = false, inDialog = false, registryPageId, pageNote, onClose, eventHandle, onEditPageNote }) => {
  const { pageNoteFormik, updateRegistryNote, pageNoteIsDisabled, pageNoteUpdateLoading, updateSuccess } = usePageNoteController(
    eventHandle,
    pageNote,
    registryPageId,
    onEditPageNote
  );
  const noteLength = pageNoteFormik.values.note.length;
  const { getPageNoteTranslations } = useSharedRegistryTranslations();
  const { title, subtitle, inputPlaceholder, maxCharacters, cancel, save } = getPageNoteTranslations();

  const cancelChanges = () => {
    onClose?.();
    pageNoteFormik.resetForm();
  };

  useEffect(() => {
    if (updateSuccess) {
      onClose?.();
    }
  }, [updateSuccess, onClose]);

  const isCancelButtonVisible = inDialog || (!pageNoteIsDisabled && !pageNoteUpdateLoading);
  const stackSpacing = isMobile ? 6 : 7;
  return (
    <Box>
      <SpacingStack spacing={stackSpacing}>
        {!inDialog && (
          <SpacingStack spacing={3}>
            <TextV1 component="h1" variant="hed4">
              {title}
            </TextV1>
            <TextV1 component="p" variant="body1">
              {subtitle}
            </TextV1>
          </SpacingStack>
        )}
        <TextAreaWrapper>
          <FormField label={inputPlaceholder} required={false}>
            <StyledTextArea
              onChange={pageNoteFormik.handleChange}
              placeholder="note"
              value={pageNoteFormik.values.note}
              id="page-note"
              resize={false}
              name="note"
              error={false}
              onBlur={e => {
                pageNoteFormik.handleBlur(e);
              }}
            />
          </FormField>
        </TextAreaWrapper>
        <Divider margin="32px 0px 0px" />
      </SpacingStack>
      <StyledFlex alignItems="center" justifyContent="space-between" isMobile={isMobile} marginTop={[3, null, 7]}>
        <Box>
          <TextV1 component="p" variant="label2" color={noteLength > 2000 ? 'negative6' : undefined}>
            {`${noteLength} ${maxCharacters}`}
          </TextV1>
        </Box>
        <StyledButtonFlex isMobile={isMobile}>
          {isCancelButtonVisible && (
            <Box marginRight={[3, null, 20]} style={{ alignSelf: 'center' }}>
              <StyledLink component="button" intent="secondary" underline="none" onClick={cancelChanges}>
                {cancel}
              </StyledLink>
            </Box>
          )}
          <ButtonV1 disabled={pageNoteIsDisabled} onClick={updateRegistryNote} loading={pageNoteUpdateLoading}>
            <StyledText>{save}</StyledText>
          </ButtonV1>
        </StyledButtonFlex>
      </StyledFlex>
    </Box>
  );
};

PageNote.displayName = 'PageNote';

export { PageNote };
