import { Box, StyleSystemProps, styled } from '@withjoy/joykit';

export const StyledSliderContainer = styled(Box)`
  .slick-dots {
    bottom: -57px;
  }
  .slick-track {
    max-height: inherit !important;
  }
  .slick-slider {
    max-height: inherit !important;
  }
`;
export const StyledArrow = styled(Box)``;
export const StyledArrowsContainer = styled(Box)``;

const sliderContainerStyles: StyleSystemProps = {
  backgroundColor: '#FFF',
  position: 'relative',
  width: '100%'
};

const arrowStyles = (isLeft?: boolean): StyleSystemProps => {
  return {
    position: 'absolute',
    top: '50%',
    left: isLeft ? '10%' : 'auto',
    right: isLeft ? 'auto' : '10%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: '48px',
    height: '48px',
    backgroundColor: 'mono2',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  };
};

export const styles = {
  sliderContainer: sliderContainerStyles,
  arrow: arrowStyles
};
