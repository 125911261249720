import { ProductCatalogPhotoAssetFragment, ProductCatalogVideoAssetFragment } from '@graphql/generated';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';

export const getGridAreaConfiguration = (index: number, imageCount: number): string | undefined => {
  let gridArea = undefined;
  switch (imageCount) {
    case 1:
      gridArea = '1 / 1 / span 2 / span 2';
      break;
    case 2:
      if (index === 0) gridArea = '1 / 1 / span 2 / span 2';
      if (index === 1) gridArea = '3 / 1 / span 2 / span 2';
      break;
    case 3:
      if (index === 0) gridArea = '1 / 1 / span 2 / span 2';
      break;
    case 4:
      // do nothing, all images are equal
      break;
    case 5:
      if (index === 0) gridArea = '1 / 1 / span 2 / span 2';
      break;
    case 6:
      // do nothing, all images are equal
      break;
    case 7:
      if (index === 6) gridArea = '4 / 1 / span 2 / span 2';
      break;
    case 8:
      // do nothing, all images are equal
      break;
  }
  return gridArea;
};

export const isPhotoMediaAsset = (mediaAsset?: CatalogProductMediaAsset | null): mediaAsset is ProductCatalogPhotoAssetFragment => {
  return mediaAsset?.__typename === 'ProductCatalogPhotoAsset';
};

export const isVideoMediaAsset = (mediaAsset?: CatalogProductMediaAsset | null): mediaAsset is ProductCatalogVideoAssetFragment => {
  return mediaAsset?.__typename === 'ProductCatalogVideoAsset';
};

export const getMediaAssetImgUrl = (mediaAsset?: CatalogProductMediaAsset | null): string | undefined => {
  if (isPhotoMediaAsset(mediaAsset)) return mediaAsset.photo.url;
  if (isVideoMediaAsset(mediaAsset)) return mediaAsset.video.fallbackImage.url;
  return undefined;
};

export const getMediaAssetThumbnailUrl = (mediaAsset?: CatalogProductMediaAsset | null): string | undefined => {
  if (isPhotoMediaAsset(mediaAsset)) return mediaAsset.photo.url;
  if (isVideoMediaAsset(mediaAsset)) return mediaAsset.video.thumbnail.url;

  return undefined;
};

export const getMediaAssetUrl = (mediaAsset?: CatalogProductMediaAsset | null): string | undefined => {
  if (isPhotoMediaAsset(mediaAsset)) return mediaAsset.photo.url;
  if (isVideoMediaAsset(mediaAsset)) return mediaAsset.video.url;

  return undefined;
};

export const areEqualMediaAssets = (mediaAsset1?: CatalogProductMediaAsset | null, mediaAsset2?: CatalogProductMediaAsset | null): boolean => {
  if (!mediaAsset1 || !mediaAsset2) return false;
  return (
    mediaAsset1.__typename === mediaAsset2.__typename &&
    ((isPhotoMediaAsset(mediaAsset1) && isPhotoMediaAsset(mediaAsset2) && mediaAsset1.photo?.url === mediaAsset2.photo?.url) ||
      (isVideoMediaAsset(mediaAsset1) && isVideoMediaAsset(mediaAsset2) && mediaAsset1.video?.url === mediaAsset2.video?.url))
  );
};
