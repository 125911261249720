import { Flex, Divider, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledPriceLine = styled(Flex)`
  margin-bottom: ${pxToRem(24)};
  justify-content: space-between;
  margin: ${pxToRem(8)} 1rem;
`;

export const StyledDivider = styled(Divider)`
  margin: ${pxToRem(4)} 1rem;
`;
