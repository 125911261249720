import React from 'react';
import { StyledLogoBox, StyledFlex } from './RegistryLogoGrid.styles';
import { RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { RegistryFragment } from '@graphql/generated';
import { RegistryOpenHandler } from '../types';
import { Box, SpacingStack, TextV2, useTheme } from '@withjoy/joykit';
import { extractHostname } from '@shared/utils/extractHostname';
import ClampedText from '@apps/registry/common/components/ClampedText';
import { ReactComponent as JoyLogo } from '@assets/joy-logo.svg';
import { ReactComponent as LogoDefault } from '@assets/icons/reg-logo-default.svg';

import { useRegistryGuestTranslations } from '../../GuestRegistry.i18n';
import { pxToRem } from '@withjoy/joykit/theme';
import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';

interface Props
  extends Readonly<{
    logosToShow: RegistryList;
    handleClickRegistry?: RegistryOpenHandler;
    isCustomProduct?: boolean;
    onClickCustomItem: () => void;
    product?: CookedProduct;
  }> {}

export const RegistryLogoGrid: React.FC<Props> = ({ logosToShow = [], handleClickRegistry, isCustomProduct, product, onClickCustomItem }) => {
  const { getGiveGiftDialogTranslations } = useRegistryGuestTranslations();
  const { alsoRegisteredAt } = getGiveGiftDialogTranslations();

  const { space } = useTheme();

  return (
    <Box paddingTop={pxToRem(128)}>
      <TextV2 typographyVariant="hed4" marginBottom={space[6]} textAlign="center">
        {alsoRegisteredAt()}
      </TextV2>
      <StyledFlex justifyContent="center" maxWidth={['592px', '687px', '879px', '944px', '1101px', null, '1264px', '1504px', '1745px']}>
        {logosToShow.map((logo: RegistryFragment) => {
          const text = logo.nameV0204 || extractHostname(logo.urlV0204!);
          const syncStatus = logo.wantSync === false ? 'notwanted' : logo.wantSync === true ? 'wanted' : 'default';
          return (
            <Box key={logo.id}>
              <StyledLogoBox
                position="relative"
                onClick={() => {
                  if (logo.syncFinishedAt && syncStatus !== 'notwanted') {
                    onClickCustomItem();
                  } else if (handleClickRegistry && logo.id && logo.urlV0204) {
                    handleClickRegistry({ id: logo.id, url: logo.urlV0204, product });
                  }
                }}
              >
                {logo.imageUrlV0204 ? (
                  <img src={logo.imageUrlV0204} alt="" />
                ) : (
                  <SpacingStack spacing={3} alignItems="center">
                    <LogoDefault />
                    <ClampedText component="p" typographyVariant="button1" textAlign="center" clampAt={2}>
                      {text}
                    </ClampedText>
                  </SpacingStack>
                )}
              </StyledLogoBox>
            </Box>
          );
        })}
        {isCustomProduct && (
          <Box>
            <StyledLogoBox onClick={onClickCustomItem}>
              <JoyLogo />
            </StyledLogoBox>
          </Box>
        )}
      </StyledFlex>
    </Box>
  );
};

RegistryLogoGrid.displayName = 'RegistryLogoGrid';
