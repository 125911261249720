export interface FontDefinition
  extends Readonly<{
    id: string;
    url: string;
    format: 'woff' | 'ttf';
    fontFamily: string;
    fontStyle: string;
    fontWeight: string;
  }> {}

const AustinNewsHeadline: FontDefinition = {
  id: 'AustinNewsHeadline',
  url: 'https://withjoy.com/assets/public/fonts/AustinNewsHeadline/AustinNewsHeadline-Light-Web.woff',
  format: 'woff',
  fontFamily: 'Austin News Headline',
  fontStyle: 'normal',
  fontWeight: 'normal'
};

const AustinNewsHeadlineItalic: FontDefinition = {
  id: 'AustinNewsHeadline-Italic',
  url: 'https://withjoy.com/assets/public/fonts/AustinNewsHeadline/AustinNewsHeadline-LightItalic-Web.woff',
  format: 'woff',
  fontFamily: 'Austin News Headline',
  fontStyle: 'italic',
  fontWeight: 'normal'
};

const AkkuratLLWebBold: FontDefinition = {
  id: 'AkkuratLLWeb-Bold',
  url: 'https://withjoy.com/assets/public/fonts/akkurat/AkkuratLLWeb-Bold.woff',
  format: 'woff',
  fontFamily: 'Akkurat LL',
  fontStyle: 'regular',
  fontWeight: 'bold'
};

const AkkuratLLSubRegularSubset: FontDefinition = {
  id: 'AkkuratLLSub-RegularSubset',
  url: 'https://withjoy.com/assets/public/fonts/akkurat/AkkuratLLSub-RegularSubset.woff',
  format: 'woff',
  fontFamily: 'Akkurat LL',
  fontStyle: 'regular',
  fontWeight: 'normal'
};

const AustinNewsDeckLightWeb: FontDefinition = {
  id: 'AustinNewsDeck-Light-Web',
  url: 'https://withjoy.com/assets/public/fonts/AustinNewsDeck/AustinNewsDeck-Light-Web.woff',
  format: 'woff',
  fontFamily: 'Austin News Deck Web',
  fontStyle: 'regular',
  fontWeight: 'normal'
};

export const fonts = {
  AustinNewsHeadline,
  AustinNewsHeadlineItalic,
  AkkuratLLWebBold,
  AkkuratLLSubRegularSubset,
  AustinNewsDeckLightWeb
} as const;
