import { styled, Box, Flex, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const DEFAULT_IMAGE = 'https://withjoy.com/assets/public/apps/registry/custom-item-default.svg';

const productPhotoStyles: StyleSystemProps = {
  width: '100%',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  transition: 'transform 0.25s',
  justifyContent: 'center',
  display: 'flex',
  position: 'absolute',
  top: 0,
  _after: {
    display: 'block',
    position: 'absolute',
    borderRadius: pxToRem(4),
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: 'scale(1.0001)',
    transition: 'all 0.2s',
    content: `' '`
  }
};

export const StyledProductPhoto = styled(Box)<{ photoUrl?: string; $isCompleted?: boolean }>`
  mix-blend-mode: multiply;
  ::after {
    ${({ $isCompleted, photoUrl }) => {
      return {
        backgroundImage: `url("${photoUrl || DEFAULT_IMAGE}")`,
        backgroundSize: photoUrl ? 'contain' : '50%',
        opacity: $isCompleted ? 0.4 : 1
      };
    }}
  }
`;

export const StyledProductTile = styled(Box)<{ canClick: boolean }>`
  contain: content;
  cursor: ${({ canClick }) => (canClick ? 'pointer' : 'default')};
`;

export const ProductTileContainer = styled(Flex)``;

const productPhotoWrapperStyles: StyleSystemProps = {
  overflow: 'hidden',
  borderRadius: '4px',
  position: 'relative',
  width: '100%',
  paddingTop: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: `${pxToRem(16)}`
};

const ProductTileWrapperStyles: StyleSystemProps = {
  _hover: {
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: '0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07)',
    marginTop: { _: pxToRem(-10), md2: `-${pxToRem(34)}` },
    marginLeft: { _: pxToRem(-4), md2: `-${pxToRem(34)}` },
    marginRight: { _: pxToRem(-4), md2: `-${pxToRem(32)}` },
    padding: { _: pxToRem(16), md2: `${pxToRem(34)} ${pxToRem(32)} 0 ${pxToRem(34)}` },
    // 34 + 32 left and right paddings = 66
    width: { _: `calc(100% + ${pxToRem(14)})`, md2: 'calc(100% + 66px)' },
    height: { _: `calc(100% + ${pxToRem(18)})`, md2: 'calc(100% + 66px)' },
    zIndex: 5
  }
};

export const StyledProductPhotoWrapper = styled(Flex)``;

const ProductTitleStyles: StyleSystemProps = {
  height: { _: pxToRem(40), xs: pxToRem(44) }
};

const ProductTitleTextStyles: StyleSystemProps = {
  textOverflow: 'ellipsis',
  overflow: 'hidden'
};

export const StyledMustHaveWrapper = styled(Flex)``;
const MustHaveWrapperStyles: StyleSystemProps = {
  backgroundColor: 'mono2',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: `${pxToRem(24)}`,
  paddingX: 3,
  paddingY: 1
};

export const styles = {
  productPhoto: productPhotoStyles,
  productPhotoWrapper: productPhotoWrapperStyles,
  productTileWrapper: ProductTileWrapperStyles,
  productTitle: ProductTitleStyles,
  productTitleText: ProductTitleTextStyles,
  mustHaveWrapper: MustHaveWrapperStyles
};

export const ProductTitleWrapper = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledTileWrapper = styled(Flex)<{ canClick: boolean }>`
  &:hover {
    ${StyledProductPhoto}::after {
      transform: ${({ canClick }) => (canClick ? 'scale(1.1)' : undefined)};
      transition: transform 0.7s;
    }
  }
`;

export const ProductSubTitleWrapper = styled(ProductTitleWrapper)`
  -webkit-line-clamp: 1;
`;
