import React, { useMemo } from 'react';
import { Box } from '@withjoy/joykit';
import { useCheckoutFunnel } from '../hooks/useCheckoutFunnel';
import { CheckoutServiceFacade } from '../machines/externalCheckout';
import { GiftAmount } from './GiftAmount';
import { GifterDetails } from './GifterDetails';
import { PaymentMethod } from './PaymentMethod';
import { PaperCheckoutInstructions } from './PaperCheckoutInstructions';
import { PaperCheckoutInstructionsGiftWrap } from './PaperCheckoutInstructionsGiftWrap';
import { Setup } from './Setup';
import { ExternalCheckoutInstructions } from './ExternalCheckoutInstructions';
import { PaperCheckoutConfirmAndNotify } from './PaperCheckoutConfirmAndNotify';
import { ExternalCheckoutReview } from './ExternalCheckoutReview';
import { ExternalCheckoutConfirmAndNotify } from './ExternalCheckoutConfirmAndNotify';
import { CancelGiftIntent } from './CancelGiftIntent';
import { CancelGiftWarning } from './CancelGiftWarning';
import { ConfirmedPurchase } from './ConfirmedPurchase';
import { CancelGiftConfirmation } from './CancelGiftConfirmation';
import { GiftPdp } from '@apps/registry/guest/components/CheckoutDialog/routes/GiftPdp';
import { ExternalCheckoutProvideTrackingDetails } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutProvideTrackingDetails/ExternalCheckoutProvideTrackingDetails';
import { ExternalCheckoutCopyAddress } from '@apps/registry/guest/components/CheckoutDialog/routes/ExternalCheckoutCopyAddress';
import GiftWrap from './GiftWrap';
import GiftWrapNote from './GiftWrapNote';
import { ExternalCheckoutInstructionsGiftWrap } from './ExternalCheckoutInstructionsGiftWrap';
import { CreditCardIdle } from './CreditCardIdle/CreditCardIdle';

const NullRender = (): JSX.Element | null => {
  return null;
};

const MissingRouteNote: React.FC<{ route: CheckoutServiceFacade['route'] }> = props => {
  return (
    <Box
      __css={{
        height: ['100vh', 'lg'],
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        typographyVariant: 'display3'
      }}
    >
      Missing component for route: <Box color="red6">{props.route}</Box>
    </Box>
  );
};

/**
 * We'll want to have a 1-1 mapping of routes defined in the [facade](../machines/externalCheckout/externalCheckout.facade.ts)
 * to a route component.
 */
const getRouteComponent = (route: CheckoutServiceFacade['route']): (() => JSX.Element | null) => {
  switch (route) {
    case 'setup':
      return Setup;
    case 'giftAmount':
      return GiftAmount;
    case 'giftPdp':
      return GiftPdp;
    case 'gifterDetails':
      return GifterDetails;
    case 'giftWrap':
      return GiftWrap;
    case 'giftWrapNote':
      return GiftWrapNote;
    case 'paymentMethod':
      return PaymentMethod;
    case 'creditCardCheckoutIdle':
      return CreditCardIdle;
    case 'paperCheckoutInstructions':
      return PaperCheckoutInstructions;
    case 'paperCheckoutInstructionsGiftWrapIdle':
    case 'paperCheckoutInstructionsGiftWrapLoading':
      return PaperCheckoutInstructionsGiftWrap;
    case 'paperCheckoutConfirmAndNotify':
      return PaperCheckoutConfirmAndNotify;
    case 'externalCheckoutInstructions':
    case 'externalCheckoutGetHelp':
      return ExternalCheckoutInstructions;
    case 'externalCheckoutInstructionsGiftWrapIdle':
    case 'externalCheckoutInstructionsGiftWrapLoading':
      return ExternalCheckoutInstructionsGiftWrap;
    case 'externalCheckoutReview':
      return ExternalCheckoutReview;
    case 'externalCheckoutCopyAddress':
      return ExternalCheckoutCopyAddress;
    case 'externalCheckoutConfirmAndNotify':
      return ExternalCheckoutConfirmAndNotify;
    case 'cancelGiftIntent':
      return CancelGiftIntent;
    case 'cancelGiftWarning':
      return CancelGiftWarning;
    case 'confirmedPurchase':
      return ConfirmedPurchase;
    case 'cancelGiftConfirmation':
      return CancelGiftConfirmation;
    case 'provideTrackingDetails':
      return ExternalCheckoutProvideTrackingDetails;
    default:
      if (__DEV__) {
        return () => <MissingRouteNote route={route} />;
      }
      return NullRender;
  }
};

export const Router = () => {
  const { route } = useCheckoutFunnel(({ route }) => [route]);
  const RouteComponent = useMemo(() => getRouteComponent(route), [route]);
  return <RouteComponent />;
};
