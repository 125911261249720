import React, { useCallback, useRef } from 'react';
import { SpacingStack, SpacingStackProps } from '@withjoy/joykit';
import { StyledExpandButton, StyledExpandedMessage } from './ExpandableMessage.styles';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import { useIsMounted } from '@shared/utils/hooks/useIsMounted';
import { useImmer } from 'use-immer';
import { useTranslation } from '@shared/core';

type ExpandableMessageProps = {
  message: string;

  /** @default 2 */
  lineClamp?: number;
};

export const ExpandableMessage = ({ message, lineClamp = 2, ...restProps }: ExpandableMessageProps & Omit<SpacingStackProps, 'children'>) => {
  const [{ hasDeterminedOverflowOnce, showOverflowButton }, setState] = useImmer<{ hasDeterminedOverflowOnce: boolean; showOverflowButton: boolean }>({
    hasDeterminedOverflowOnce: false,
    showOverflowButton: false
  });
  const isMounted = useIsMounted();
  const ref = useRef<HTMLParagraphElement>(null);
  const { t } = useTranslation('sharedRegistry');
  const readMoreText = t('giftDialog', 'readMoreText')();

  const determineOverflow = useCallback(() => {
    if (isMounted && !hasDeterminedOverflowOnce) {
      const textEl = ref.current;
      if (textEl) {
        const hasOverflow = textEl.scrollHeight > textEl.clientHeight;
        setState(draft => {
          draft.hasDeterminedOverflowOnce = true;
          draft.showOverflowButton = hasOverflow;
        });
      }
    }
  }, [isMounted, setState, hasDeterminedOverflowOnce]);

  useIsomorphicLayoutEffect(() => {
    determineOverflow();
  }, [determineOverflow]);
  return (
    <SpacingStack stack="vertical" spacing={3} {...restProps}>
      <StyledExpandedMessage $lineClamp={hasDeterminedOverflowOnce && !showOverflowButton ? undefined : lineClamp} ref={ref}>
        {message}
      </StyledExpandedMessage>
      {showOverflowButton && (
        <StyledExpandButton
          onClick={() => {
            setState(draft => {
              draft.showOverflowButton = false;
            });
          }}
        >
          {readMoreText}
        </StyledExpandButton>
      )}
    </SpacingStack>
  );
};
