import React, { useRef } from 'react';
import { useCombinedRefs } from '@shared/utils/hooks/useCombinedRefs';
import { useOverrides } from '@shared/utils/overrides';
import { createDisplayName, useStyleConfig } from '@withjoy/joykit/utils';
import { AlertProps } from './Alert.types';
import { forwardStyledComponentRef } from '@shared/utils/forwardRef';
import { alertStyles, StyledContainer, StyledTitle, StyledDescription } from './Alert.styles';

const Alert = forwardStyledComponentRef<'div', AlertProps>((props, ref) => {
  const { children: _, title, description, overrides = {}, ...restProps } = props as AlertProps;

  const {
    Root: [Root, rootProps],
    Title: [Title, titleProps],
    Description: [Description, descriptionProps]
  } = useOverrides({ Root: StyledContainer, Title: StyledTitle, Description: StyledDescription }, overrides);

  const styles = useStyleConfig(alertStyles, props as AlertProps);

  const divRef = useRef<HTMLDivElement>(null);
  const combinedRef = useCombinedRefs(ref, divRef);

  return (
    <Root as="div" ref={combinedRef} __css={styles.root} type="div" {...restProps} {...rootProps}>
      {!!title && (
        <Title __css={styles.title} {...titleProps} data-testid="title">
          {title}
        </Title>
      )}
      <Description __css={styles.description} {...descriptionProps} data-testid="description">
        {description}
      </Description>
    </Root>
  );
});

Alert.displayName = createDisplayName('Alert');

export { Alert };
