import React, { useEffect, useState } from 'react';
import { safeInvoke } from '@shared/utils/safeInvoke';
import { ShippingAddressFragment } from '@graphql/generated';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Box, Flex, LinkV2, TextV2 } from '@withjoy/joykit';
import { Mailbox } from '@withjoy/joykit/icons';
import { useTransition, animated } from 'react-spring';
import globalWindow from '@shared/core/globals';
import { useTranslation } from '@shared/core';
import { StyledNoShippingAddressReferenceRoot, StyledShippingAddressReferenceRoot, StyledToastMessage, StyledToastRoot, styles } from './ShippingAddressReference.styles';

interface ToastProps {
  notificationVisible: boolean;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ShippingAddressReferenceProps {
  shippingAddress?: Maybe<ShippingAddressFragment>;
  onCopyClick?: () => void;
}

const Toast = (props: ToastProps) => {
  const { notificationVisible, setNotificationVisible } = props;

  const { t2 } = useTranslation('guestRegistry');
  const { copied } = t2('checkoutDialog', 'shippingAddress');

  const transitions = useTransition(notificationVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true
  });

  useEffect(() => {
    const timer = globalWindow.setTimeout(() => setNotificationVisible(false), 4000);
    return () => clearTimeout(timer);
  }, [notificationVisible, setNotificationVisible]);

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <StyledToastRoot key={key} __css={styles.toastRoot}>
              <animated.div style={props}>
                <StyledToastMessage __css={styles.toastMessage}>
                  <Mailbox size="md" />
                  <TextV2 typographyVariant="body1" paddingLeft={4}>
                    {copied}
                  </TextV2>
                </StyledToastMessage>
              </animated.div>
            </StyledToastRoot>
          )
      )}
    </>
  );
};

export const ShippingAddressReference = (props: ShippingAddressReferenceProps) => {
  const { shippingAddress, onCopyClick } = props;

  const [notificationVisible, setNotificationVisible] = useState(false);

  const { t2 } = useTranslation('guestRegistry');
  const { noShippingAddress, shippingAddressTitle, copy } = t2('checkoutDialog', 'shippingAddress');

  if (!shippingAddress) {
    return (
      <StyledNoShippingAddressReferenceRoot __css={styles.noShippingAddressReferenceRoot}>
        <TextV2 typographyVariant="body1">{noShippingAddress}</TextV2>
      </StyledNoShippingAddressReferenceRoot>
    );
  }

  const { name, address1, address2, city, state, postalCode } = shippingAddress || {};

  const shippingAddressText = shippingAddress ? `${name}\n${address1}\n${address2}${address2 ? '\n' : ''}${city}, ${state} ${postalCode}` : '';

  const handleOnCopy = () => {
    setNotificationVisible(true);
    safeInvoke(onCopyClick);
  };

  return (
    <StyledShippingAddressReferenceRoot __css={styles.shippingAddressReferenceRoot}>
      <Toast notificationVisible={notificationVisible} setNotificationVisible={setNotificationVisible} />
      <CopyToClipboard text={shippingAddressText} onCopy={handleOnCopy}>
        <Flex justifyContent="space-between">
          <TextV2 typographyVariant="hed1">{shippingAddressTitle}</TextV2>
          <LinkV2 as="button" color="mono14" textDecoration="none" typographyVariant="body1">
            {copy}
          </LinkV2>
        </Flex>
      </CopyToClipboard>
      <Box paddingTop={5}>
        <TextV2 typographyVariant="body1">{name}</TextV2>
        <TextV2 typographyVariant="body1">{address1}</TextV2>
        {address2 && <TextV2 typographyVariant="body1">{address2}</TextV2>}
        <TextV2 typographyVariant="body1">{`${city}, ${state} ${postalCode}`}</TextV2>
      </Box>
    </StyledShippingAddressReferenceRoot>
  );
};
