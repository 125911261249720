import { GetCheckoutDialogDataQuery, useGetCheckoutDialogDataQuery } from '@graphql/generated';
import { createContext } from '@shared/utils/createContext';
import { UseCheckoutFunnelReturn } from '../useCheckoutFunnel';

type UseCheckoutDialogDataArgs = {
  registryItemId: Maybe<string>;
  currentOrderId: Maybe<string>;
  isAffiliate?: boolean;
};

/**
 * Initialize the checkout dialog data
 */
export const useGetCheckoutInitData = ({ registryItemId, currentOrderId, isAffiliate }: UseCheckoutDialogDataArgs) =>
  useGetCheckoutDialogDataQuery({
    batchMode: 'fast',
    variables: {
      registryItemId: registryItemId!,
      registryOrderId: currentOrderId || '',
      skipDonationOrder: !currentOrderId || !!isAffiliate,
      skipOrderQuery: !currentOrderId,
      isAffiliate: !!isAffiliate
    },
    skip: !registryItemId
  });

//----------------------------------------
// Checkout Data Provider

type CheckoutDataContext = GetCheckoutDialogDataQuery | undefined;

const [CheckoutInitDataProvider, _useCheckoutInitDataContext] = createContext<CheckoutDataContext>({ name: 'CheckoutDialogData' });
type CheckoutRoutes = UseCheckoutFunnelReturn['route'];
export type RoutesType = CheckoutRoutes;

type CheckoutRoutesWithNoData = Extract<CheckoutRoutes, 'setup' | 'registryItemNotFound' | null>;
type CheckoutDataByRoute<Route extends RoutesType> = Route extends CheckoutRoutesWithNoData
  ? {
      registryItem: null;
      order: null;
      orderMetadata: null;
    }
  : {
      registryItem: NonNullable<GetCheckoutDialogDataQuery['registryItem']>;
      order: GetCheckoutDialogDataQuery['order'];
      orderMetadata: GetCheckoutDialogDataQuery['orderMetadata'];
    };

/**
 * Intended to be internal to the `CheckoutDialog` component hierarchy.
 *
 * This hook will return the static graphql data that initialized the checkout dialog flow.
 * The shape is strongly typed to reflect what data is present for a particular route.
 *
 *
 * @example
 * const { registryItem } = useCheckoutInitDataContext('registryItemNotFound');
 * console.log(registryItem); // null
 *
 * const { registryItem } = useCheckoutInitDataContext('giftAmount');
 * console.log(registryItem); // {...}
 */

const useCheckoutInitDataContext = <Route extends RoutesType>(route?: Route) => {
  const ctx = _useCheckoutInitDataContext();

  return ctx as CheckoutDataByRoute<Route>;
};

export { CheckoutInitDataProvider, useCheckoutInitDataContext };
