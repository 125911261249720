import { Box, styled, StyleSystemProps } from '@withjoy/joykit';

export const Notification = styled.span<{ isPositionTop?: boolean }>`
  color: ${props => props.theme.colors.fillLight};
  background-color: ${props => props.theme.colors.mono14};
  border-radius: 30px;
  padding: 12px 18px;
  position: relative;
  white-space: nowrap;
  max-width: 560px;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDiv = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

export const LottieWrapper = styled.div`
  transform: translate(-50%, 0);
  left: 50%;
  position: absolute;
  width: 400px;
  height: 400px;
  bottom: -140px;
`;

export const ToastWrapper = styled(Box)``;

const wrapperStyles = (isPositionTop: boolean, noAnimation: boolean): StyleSystemProps => ({
  position: 'fixed',
  zIndex: 'toast',
  left: '50%',
  [isPositionTop ? 'top' : 'bottom']: isPositionTop && !noAnimation ? ['112px'] : [8, '72px'],
  transform: 'translateX(-50%)'
});

export const styles = {
  wrapper: wrapperStyles
};
