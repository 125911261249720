import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledGiftCardRoot = styled(Box)``;
export const StyledGiftCardGradientContainer = styled(Box)``;
export const StyledGiftCardGiftMetadataContainer = styled(Box)``;

const pxToRem15 = pxToRem(15);
const cardDropShadow = 'drop-shadow(0px 7px 27px rgba(44, 41, 37, 0.06)) drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.07))';

const giftCardRootStyles = (isFullBleed: boolean, isHideShadow?: boolean, isCharity?: boolean): StyleSystemProps => ({
  height: ['254px', null, null, '297px'],
  marginX: isFullBleed ? 0 : [6, 0],
  backgroundSize: isCharity ? pxToRem(200) : 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: isCharity ? 'top' : 'center',
  overflow: 'hidden',
  borderRadius: [isFullBleed ? `${pxToRem15} ${pxToRem15} 0 0` : pxToRem15, null, null, pxToRem15],
  filter: isCharity || isHideShadow ? null : [isFullBleed ? null : cardDropShadow, null, null, cardDropShadow],
  boxShadow: isCharity && !isHideShadow ? '0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07)' : null
});

const giftCardContainerStyles: StyleSystemProps = {
  width: '100%',
  height: '100%',
  padding: 6
};

const giftCardGradientContainerStyles: StyleSystemProps = {
  ...giftCardContainerStyles,
  background:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.1) 34.71%, rgba(0, 0, 0, 0) 87.7%), linear-gradient(180deg, rgba(0, 0, 0, 0) 4.17%, rgba(0, 0, 0, 0.19665) 71.35%, rgba(0, 0, 0, 0.375) 89.1%, rgba(0, 0, 0, 0.5) 100%)'
};

const giftCardGiftMetadataContainerStyles: StyleSystemProps = {
  position: 'relative',
  width: '100%',
  height: '100%'
};

const groupGiftingCartStyles: StyleSystemProps = {
  padding: 6,
  boxShadow: '0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07)',
  borderRadius: pxToRem(16)
};

export const StylesProductPhotoContainer = styled(Flex)``;
export const StyledProductPhoto = styled(Box)<{ photoUrl?: string }>`
  mix-blend-mode: multiply;
  ::after {
    ${({ photoUrl }) => {
      return {
        backgroundImage: `url("${photoUrl}")`,
        backgroundSize: photoUrl ? 'contain' : '50%',
        opacity: 1
      };
    }}
  }
`;

const productPhotoContainerStyles: StyleSystemProps = {
  height: pxToRem(200),
  justifyContent: 'center'
};

const productPhotoStyles: StyleSystemProps = {
  width: pxToRem(200),
  height: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  transition: 'transform 0.25s',
  justifyContent: 'center',
  display: 'flex',
  transform: 'scale(1.0001)',
  top: 0,
  _after: {
    display: 'block',
    position: 'absolute',
    borderRadius: pxToRem(4),
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transform: 'scale(1.0001)',
    transition: 'all 0.2s',
    content: `' '`
  }
};

export const styles = {
  giftCardRoot: giftCardRootStyles,
  giftCardContainer: giftCardContainerStyles,
  giftCardGradientContainer: giftCardGradientContainerStyles,
  giftCardGiftMetadataContainer: giftCardGiftMetadataContainerStyles,
  groupGiftingCartStyles,
  productPhotoStyles,
  productPhotoContainerStyles
};
