type Platform = 'venmo' | 'cashapp' | 'paypal' | 'cashOrCheck' | 'other' | 'joyCredit';
type PlatformLabels = ReadonlyArray<{ value: Platform; label: string }>;

export const paymentMethodOptions: PlatformLabels = [
  { value: 'venmo', label: 'Venmo' },
  { value: 'cashapp', label: 'Cash App' },
  { value: 'paypal', label: 'PayPal.Me' },
  { value: 'cashOrCheck', label: 'Cash/Check' },
  { value: 'joyCredit', label: 'Stripe' }
] as const;

export const getPaymentMethodFormattedName = (platformType: string) => {
  return paymentMethodOptions.find(option => option.value === platformType)?.label ?? '';
};

export const paymentMethodOptionList: PlatformLabels = [
  { value: 'venmo', label: 'Venmo' },
  { value: 'cashapp', label: 'Cash App' },
  { value: 'paypal', label: 'PayPal' },
  { value: 'cashOrCheck', label: 'Cash or Check' },
  { value: 'joyCredit', label: 'Stripe' },
  { value: 'other', label: 'Online' }
] as const;

export const getPaymentMethodTypeName = (platformType: string) => {
  return paymentMethodOptionList.find(option => option.value === platformType)?.label ?? '';
};

export type PaymentMethodOption = typeof paymentMethodOptions[number];
