export const responsiveBreakpoints = {
  SIZE_440: '440px',
  SIZE_640: '640px',
  SIZE_768: '768px',
  SIZE_769: '769px',
  SIZE_960: '960px',
  SIZE_1024: '1024px',
  SIZE_1152: '1152px',
  SIZE_1280: '1280px',
  SIZE_1440: '1440px',
  SIZE_1680: '1680px',
  SIZE_1920: '1920px',
  SIZE_2560: '2560px'
};

export function createResponsiveCSS(cssValues: {}) {
  let styles = '';
  Object.keys(cssValues).forEach(key => {
    styles += `
    @media screen and (min-width: ${key}) {
      ${cssValues[key as keyof typeof cssValues]}
    }
  `;
  });
  return styles;
}
