import React, { useMemo, useState } from 'react';

import { createContext } from '@shared/utils/createContext';
import { useDisclosure } from '@withjoy/joykit';

type StoredGiftReservationFieldsContext = {
  providedGuestEmail: Maybe<string>;
  providedGuestName: Maybe<string>;

  handleOnGuestEmailChange: (guestEmail: string) => void;
  handleOnGuestNameChange: (guestName: string) => void;
  handleOnGuestDetailsSave: (guestEmail: string, guestName: string) => void;

  isChangeEmailDialogOpen: boolean;
  handleOnChangeEmailDialogOpen: () => void;
  handleOnChangeEmailDialogClose: () => void;
  handleOnChangeEmailDialogSubmit: (providedGuestEmail: string) => void;
};

const [Provider, useStoredGiftReservationFieldsContext] = createContext<StoredGiftReservationFieldsContext>({ name: 'StoredGiftReservationFieldsProvider' });

const StoredGiftReservationFieldsProvider: React.FC<{ sessionEmail: Maybe<string> }> = props => {
  const { children, sessionEmail } = props;

  const [state, setState] = useState<{ providedGuestEmail: Maybe<string>; providedGuestName: Maybe<string> }>(() => ({
    providedGuestEmail: sessionEmail,
    providedGuestName: null
  }));
  const { isOpen: isChangeEmailDialogOpen, onOpen: onChangeEmailDialogOpen, onClose: onChangeEmailDialogClose } = useDisclosure();

  const context = useMemo<StoredGiftReservationFieldsContext>(() => {
    return {
      ...state,

      handleOnGuestEmailChange: guestEmail => {
        setState(prev => ({ ...prev, providedGuestEmail: guestEmail }));
      },
      handleOnGuestNameChange: guestName => {
        setState(prev => ({ ...prev, providedGuestName: guestName }));
      },
      handleOnGuestDetailsSave: (guestEmail, guestName) => {
        setState(prev => ({ ...prev, providedGuestEmail: guestEmail, providedGuestName: guestName }));
      },
      handleOnChangeEmailDialogClose: onChangeEmailDialogClose,
      handleOnChangeEmailDialogOpen: onChangeEmailDialogOpen,
      handleOnChangeEmailDialogSubmit: updatedEmail => {
        setState(prev => ({ ...prev, providedGuestEmail: updatedEmail }));
        onChangeEmailDialogClose();
      },
      isChangeEmailDialogOpen
    };
  }, [state, isChangeEmailDialogOpen, onChangeEmailDialogClose, onChangeEmailDialogOpen]);

  return <Provider value={context}>{children}</Provider>;
};

export { StoredGiftReservationFieldsProvider, useStoredGiftReservationFieldsContext };
