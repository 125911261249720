import { Box, Flex, SpacingStack, styled, StyleSystemProps, TextV2, TextV2Props } from '@withjoy/joykit';
import { CSSObject } from '@withjoy/joykit/components/Box/Box.types';

export const StyledImageWrapper = styled(Flex)``;
export const StyledImageWrapperInner = styled(Box)``;
export const StyledContent = styled(Box)``;
export const StyledActions = styled(SpacingStack)``;

export const wrapperStyled = (isInModal: boolean): CSSObject =>
  isInModal
    ? {}
    : {
        width: '100%',
        position: 'absolute',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '60% 40%'
      };

export const wrapperContentStyled = (isInModal: boolean): CSSObject =>
  isInModal
    ? {}
    : {
        padding: '0px 48px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex'
      };

export const confirmBoxStyled: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 7
};

export const StyledActionsBox = styled(Box)`
  gap: 1.5rem;
`;

export const StyledBoxTitle = styled(Box)`
  max-width: 340px;
`;

export const StyledHeadText = styled(TextV2)<TextV2Props>`
  text-align: center;
`;

export const StyledText = styled(TextV2)<TextV2Props>`
  text-align: center;
`;

export const StyledConfirmBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const StyledChangeMindBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledSthWentWrongBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledEmailBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const imageWrapper = (isInModal: boolean): StyleSystemProps => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: isInModal ? undefined : 'mono1',
  padding: isInModal ? undefined : [7, null, 3]
});

const imageWrapperInner = (isInModal: boolean): StyleSystemProps => ({
  width: ['205px', null, isInModal ? '96px' : '290px'],
  height: ['205px', null, isInModal ? '96px' : '290px']
});

const content: StyleSystemProps = {
  padding: [6, null, '0px 48px']
};

export const stack: StyleSystemProps = {
  alignItems: 'center',
  display: 'flex'
};

export const styles = {
  imageWrapper,
  imageWrapperInner,
  content,
  stack
};
