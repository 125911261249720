import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useUpdateRegistryNoteMutation } from '@graphql/generated';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

export interface PageNoteFields extends Readonly<{ note: string }> {}

export const usePageNoteController = (eventHandle: string, pageNote?: string | null, registryPageId?: string, onEditPageNote?: () => void) => {
  const [storedPageNote, setStoredPageNote] = useState(pageNote);
  const { registryNoteSet } = useGuestRegistryTelemetry();

  const [updateNote, { loading: pageNoteUpdateLoading }] = useUpdateRegistryNoteMutation({
    refetchQueries: ['GetRegistryOnboardingSuggestions']
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const updatePageNote = async (registryPageId: string, value: string) => {
    const { data } = await updateNote({ variables: { id: registryPageId, payload: { pageSubTitle: value } } });
    if (data?.updateEventPage.pageSubTitleV0918) {
      setUpdateSuccess(true);
    }
  };

  const pageNoteFormik = useFormik({
    initialValues: {
      note: storedPageNote || ''
    },
    validationSchema: Yup.object<PageNoteFields>({
      note: Yup.string().max(2000)
    }),
    onSubmit: values => {}
  });

  const updateRegistryNote = () => {
    if (registryPageId) {
      const pageNoteTrimmed = pageNoteFormik.values.note.trim();

      updatePageNote(registryPageId, pageNoteTrimmed);
      setStoredPageNote(pageNoteTrimmed);
      onEditPageNote?.();
      registryNoteSet(pageNoteTrimmed);
    }
  };

  return { updateRegistryNote, pageNoteIsDisabled: !pageNoteFormik.dirty || !pageNoteFormik.isValid, pageNoteFormik, pageNoteUpdateLoading, updateSuccess };
};
