import { addRendition } from '@shared/utils/photoRendition';
import Player from '@vimeo/player';
import { StyleSystemProps } from '@withjoy/joykit';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useVimeoPlayerContext } from '../../../../utils/vimeoPlayer';
import { StyledFallbackImage, StyledVideoContainer, styles } from './ProductVideo.styles';

const ProductVideo = ({
  videoUrl,
  shouldPlay,
  style,
  useBackgroundMode,
  fallbackImageUrl,
  forceSquareSize
}: {
  videoUrl: string;
  shouldPlay?: boolean;
  style?: StyleSystemProps;
  useBackgroundMode?: boolean;
  fallbackImageUrl?: string;
  forceSquareSize?: boolean;
}) => {
  const { VimeoPlayer, prepareForUse } = useVimeoPlayerContext();
  const [player, setPlayer] = useState<Player | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const [frameDimensions, setFrameDimensions] = useState({ width: '100%', height: '100%' });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const embedUrl = useMemo(() => {
    const url = new URL(videoUrl);
    url.searchParams.append('muted', '1');
    url.searchParams.append('loop', '1');
    url.searchParams.append('autoplay', '1');
    if (useBackgroundMode) url.searchParams.append('background', '1');
    return url.toString();
  }, [useBackgroundMode, videoUrl]);

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  useEffect(() => {
    if (VimeoPlayer && iframeRef?.current) {
      setPlayer(new VimeoPlayer(iframeRef.current));
    }
  }, [VimeoPlayer, iframeRef]);

  useEffect(() => {
    if (player && shouldPlay) {
      player.play();
      player.ready().then(() => {
        setIsReady(true);
        player.setAutopause(false);
        Promise.all([player.getVideoHeight(), player.getVideoWidth()]).then(([videoHeight, videoWidth]) => {
          setVideoDimensions({ width: videoWidth, height: videoHeight });
        });
      });
    } else {
      player?.pause();
    }
  }, [player, shouldPlay]);

  useEffect(() => {
    if (!forceSquareSize || !videoDimensions.height || !videoDimensions.width) return;

    if (videoDimensions.height < videoDimensions.width) {
      const proportion = (videoDimensions.width / videoDimensions.height) * 100;
      setFrameDimensions({ width: proportion + '%', height: '100%' });
      return;
    }

    const proportion = (videoDimensions.height / videoDimensions.width) * 100;
    setFrameDimensions({ width: '100%', height: proportion + '%' });
  }, [forceSquareSize, videoDimensions]);

  return (
    <StyledVideoContainer __css={style} {...styles.videoContainer}>
      <iframe
        ref={iframeRef}
        src={embedUrl}
        title={embedUrl}
        frameBorder="0"
        allow="autoplay"
        height={frameDimensions.height}
        width={frameDimensions.width}
        style={{ position: 'relative', left: '50%', transform: 'translateX(-50%)', zIndex: useBackgroundMode ? -1 : 1 }}
      />
      <StyledFallbackImage
        __css={styles.fallbackImage}
        style={{
          backgroundImage: `url(${addRendition({ url: fallbackImageUrl, renditionSize: 'medium' })})`,
          backgroundColor: 'rgba(0,0,0, .03)',
          opacity: `${isReady ? '0' : '1'}`
        }}
      />
    </StyledVideoContainer>
  );
};

export { ProductVideo };
