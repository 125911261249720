import { styled, LinkV1, FormField, Box } from '@withjoy/joykit';
import { SuccessGuestButton } from '@apps/registry/guest/components/SuccessGuestButton';

export const ReserveButton = styled(SuccessGuestButton)``;

export const StyledFormField = styled(FormField)<{ width?: string; isMobile?: boolean }>`
  width: ${({ width, isMobile }) => (width && !isMobile ? width : isMobile ? '100%' : 'initial')};
`;

export const ErrorFiller = styled(Box)<{ isRendered?: boolean }>`
  height: 20px;
  display: ${({ isRendered }) => (isRendered ? 'inline-block' : 'none')};
`;

export const StyledLink = styled(LinkV1)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.typeSecondaryDark};
  & :hover {
    color: ${props => props.theme.colors.typeTertiaryDark} !important;
  }
  :active {
    color: ${props => props.theme.colors.mono14} !important;
  }
`;
