import React from 'react';
import { useTranslation } from '@shared/core';
import { ShippingAddressFragment } from '@graphql/aliases';
import { Address } from '../Address';
import { ReactComponent as ShippingIcon } from '@assets/icons/shipping.svg';
import { TextStyled, BoxStyled, boxStyles } from '@apps/registry/guest/components/ShippingAddressQuickView/ShippingAddressQuickView.styles';
import { boxAddressStyles } from '@apps/registry/guest/components/Address.styles';

type ShippingAddressQuickViewProps = {
  shippingAddress: Maybe<ShippingAddressFragment>;
  showBox?: boolean;
  showWarning?: boolean;
  hideTitle?: boolean;
};

export const ShippingAddressQuickView = ({ shippingAddress, showWarning = true, hideTitle = false, showBox = true }: ShippingAddressQuickViewProps) => {
  const { t } = useTranslation('guestRegistry');
  const { dontHaveAddress } = t('confirmationPurchaseModal');

  if (shippingAddress) {
    return <Address address={shippingAddress} showWarning={showWarning} hideTitle={!!hideTitle} />;
  }

  const text = <TextStyled typographyVariant="body1">{dontHaveAddress()}</TextStyled>;

  return showBox ? (
    <BoxStyled __css={{ ...boxAddressStyles(showWarning), ...boxStyles }}>
      <ShippingIcon />
      {text}
    </BoxStyled>
  ) : (
    text
  );
};
