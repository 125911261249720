import { styled, css, LinkV2, Flex, Box, BoxProps, ButtonV2, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledProductLink = styled(LinkV2)`
  svg {
    margin-left: 8px;
    path {
      fill: currentColor;
    }
  }
`;

export const StyledEventPhoto = styled.img`
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 50%;
  margin-right: 16px;
  margin-bottom: 24px;
`;

export const StyledDescription = styled(Box)<{ isDescriptionShowed?: boolean | false }>`
  margin-bottom: 8px;
  height: ${({ isDescriptionShowed }) => (isDescriptionShowed ? 'fit-content' : '44px')};
  overflow-y: hidden;
  position: relative;

  ::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    ${({ isDescriptionShowed }) =>
      !isDescriptionShowed &&
      css`
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
      `}
  }
`;

export const StyledHowManyRequestLabel = styled(Box)`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
`;

export const StyledNeededLabel = styled(Box)`
  color: ${props => props.theme.colors.mono10};
`;

export const StyledLabelForm = styled(Box).attrs<BoxProps<'label'>>(() => ({ as: 'label', typographyVariant: 'label3' }))<BoxProps<'label'>>`
  display: flex;
  margin-bottom: 8px;
  font-size: 13px;
`;

export const StyledInputError = styled(Box).attrs<BoxProps>(() => ({ typographyVariant: 'label2' }))<BoxProps>`
  color: ${props => props.theme.colors.red6};
  min-height: 22px;
`;

export const StyledButtonContainer = styled(Flex)`
  gap: 16px;
  button:first-child {
    margin-bottom: 16px;
  }
`;

export const StyledMarkAsPurchasedButton = styled(ButtonV2)``;
export const StyledReserveButton = styled(ButtonV2)``;
export const StyledPurchasableMessage = styled(TextV2)``;
export const StyledProductTitle = styled(TextV2)``;
export const StyledProductPrice = styled(TextV2)`
  font-weight: 500;
  margin-right: ${pxToRem(4)};
`;
