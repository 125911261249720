import React from 'react';
import { IconWrapper, StyledButtonContainer, StyledButtonWrapper, StyledCountContainer, styles } from './ShoppingCartOpenButtonV2.styles';
import { IconV2, TextV2 } from '@withjoy/joykit';
import { CartIcon } from '@assets/icons';

type Props = {
  onClick: () => void;
  inCartProductCount: number;
};

export const ShoppingCartOpenButtonV2 = ({ onClick, inCartProductCount }: Props) => {
  return (
    <StyledButtonContainer onClick={onClick}>
      <StyledButtonWrapper __css={styles.buttonWrapperStyles}>
        <IconWrapper>
          <IconV2 size={20}>
            <CartIcon />
          </IconV2>
        </IconWrapper>
        <StyledCountContainer __css={styles.countContainerStyles}>
          <TextV2 typographyVariant="label3">{inCartProductCount > 99 ? '99+' : inCartProductCount}</TextV2>
        </StyledCountContainer>
      </StyledButtonWrapper>
    </StyledButtonContainer>
  );
};
