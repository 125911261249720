import { ShippingAddressInput } from '@graphql/generated';
import { US_STATE_CAPITALS, US_STATE_NAME_TO_ABBREVIATION } from './stateCapitals';
import { isAddressValid } from '@apps/registry/common/utils/shippingAddressValidations';

interface EventLocationWithState {
  // Two character code for US State
  state: string;
}

const isEvenLocationWithStateValid = (location: unknown): location is EventLocationWithState => {
  if (!location || !(typeof location === 'object') || !('state' in location) || typeof location.state !== 'string' || !location.state) {
    return false;
  }

  return true;
};

const hasCountry = (item: unknown): item is { country: string } => {
  if (!(item && typeof item === 'object' && 'country' in item && typeof item.country === 'string' && item.country)) {
    return false;
  }

  return true;
};

export enum EAddressType {
  US_REGISTRY = 'US_registry',
  US_EVENT = 'US_event',
  US_STATE_CAPITAL = 'US_state_capital',
  US_DEFAULT = 'US_default',
  INTERNATIONAL_REGISTRY = 'International_registry',
  INTERNATIONAL_EVENT = 'International_event',
  NO_ADDRESS = 'NoAddress'
}

/**
 *
 * @param state
 * @returns ShippingAddressInput | undefined
 */
export const getDefaultAddressFromState = (state: string): ShippingAddressInput | undefined => {
  const stateCode = state.toUpperCase();

  const upperCaseState = stateCode?.toUpperCase?.();

  // Assume the state is two letter abbreviation
  // we don't need to over complicate this.  Making the key value lookup type safe defeats the entire purpose.
  // The value comes from backend and may not be a valid US state, could be null, undefined, etc, which we are checking here
  //@ts-ignore
  let stateCapital = US_STATE_CAPITALS[upperCaseState];
  if (!stateCapital) {
    //Assume the state is full name and try again
    //@ts-ignore
    stateCapital = US_STATE_CAPITALS[US_STATE_NAME_TO_ABBREVIATION[upperCaseState]];
  }

  if (!stateCapital) {
    return undefined;
  }

  const stateAddress = {
    ...stateCapital,
    country: 'United States',
    validated: true,
    countryCode: 'USA'
  };

  if (!isAddressValid(stateAddress)) {
    return undefined;
  }

  return stateAddress;
};

interface canShowGWResponse {
  // Either a valid Address or undefined
  address: ShippingAddressInput | undefined;
  // If Gift Wrap offer should be shown
  allow: boolean;
  // Type of address selected
  type: EAddressType;
}

/**
 *
 * @param registryAddress
 * @param eventLocation
 * @param defaultUSABillingAddress
 * @returns canShowGWResponse
 */
export const canShowGW = (registryAddress: unknown, eventLocation?: unknown | undefined, defaultUSABillingAddress?: unknown | undefined): canShowGWResponse => {
  const registryAddressValid = isAddressValid(registryAddress);
  if (registryAddressValid && (registryAddress.country === 'United States' || registryAddress.countryCode === 'USA')) {
    // The registry has a US shipping address we can use this
    // We can set address to undefined because the address is already set in registry_service avoiding unnecessary data in payload sent to API
    return {
      address: registryAddress,
      allow: true,
      type: EAddressType.US_REGISTRY
    };
  }

  const eventLocationValid = isAddressValid(eventLocation);
  if (eventLocationValid && eventLocation.country === 'United States') {
    // The event location has a US location
    // Currently event locations only have city, state, country so we probably wont get a full valid full address here
    // This wil likely fall through to getting default address by state
    return {
      address: eventLocation,
      allow: true,
      type: EAddressType.US_EVENT
    };
  }

  const EventLocationWithStateValid = isEvenLocationWithStateValid(eventLocation);
  const defaultStateAddress = getDefaultAddressFromState(EventLocationWithStateValid ? eventLocation.state : '');
  if (defaultStateAddress) {
    // The event location has a US location
    // Currently event locations only have city, state, country so we actually wont get a valid full address here
    return {
      address: defaultStateAddress,
      allow: true,
      type: EAddressType.US_STATE_CAPITAL
    };
  }

  // This filters based on US IP Address of user in Amplitude so will be false for all non-us IP Addresses
  const defaultUSABillingAddressValid = isAddressValid(defaultUSABillingAddress);
  if (defaultUSABillingAddressValid && !hasCountry(eventLocation) && !hasCountry(registryAddress)) {
    // In the case the user has a US IP Address and we do not have a defined country for the event location or registry address we will fallback to
    // the USA address configured in the feature flag registryGiftWrapDefaultUSABillingAddress
    return {
      address: defaultUSABillingAddress,
      allow: true,
      type: EAddressType.US_DEFAULT
    };
  }

  if (registryAddressValid) {
    // We have no valid US address to use but the registry has a valid non US address
    return {
      address: registryAddress,
      allow: false,
      type: EAddressType.INTERNATIONAL_REGISTRY
    };
  }

  if (eventLocationValid) {
    // We have no valid US address to use, registry has no valid shipping address but we have an event location
    return {
      address: eventLocation,
      allow: false,
      type: EAddressType.INTERNATIONAL_EVENT
    };
  }

  // We have no viable address info
  return {
    address: undefined,
    allow: false,
    type: EAddressType.NO_ADDRESS
  };
};
