import React from 'react';
import { DonationFundPaymentMethodEnum, CustomItemDataType } from '@graphql/generated';
import { GiftMethodBadge } from '../GiftMethodBadge';
import { FormattedCurrency } from '@shared/utils/currency';
import { TextV2 } from '@withjoy/joykit';
import ClampedText from '@apps/registry/common/components/ClampedText';
import { StyledCharityBadge, StyledGiftMetadataRoot, StyledGiftMetadataTextContainer, styles } from './GiftMetadata.styles';
import { PlacementOptions } from './GiftMetadata.types';
import { GiftMetadataSelectors } from './GiftMetadata.selectors';
import { useTranslation } from '@shared/core';

export interface GiftMetadataProps {
  paymentMethod?: DonationFundPaymentMethodEnum;
  paymentMethodUsername?: Maybe<string>;
  productType?: CustomItemDataType;
  title: string;
  giftAmount: Maybe<string>;
  currencyCode?: string;
  /**
   * @default 'static'
   */
  placement?: PlacementOptions;
}

export const GiftMetadata = (props: GiftMetadataProps) => {
  const { paymentMethod, paymentMethodUsername, productType, title, giftAmount, currencyCode, placement = 'static' } = props;
  const { t2 } = useTranslation('guestRegistry');
  const { charityLabel } = t2('checkoutDialog', 'giftMethodBadge');

  const isCharity = productType === CustomItemDataType.charity;

  return (
    <div>
      <StyledGiftMetadataRoot __css={styles.giftMetadataRoot(placement)}>
        {paymentMethod && <GiftMethodBadge paymentMethod={paymentMethod} productType={productType} paymentMethodUsername={paymentMethodUsername} />}
        {isCharity && (
          <StyledCharityBadge __css={styles.giftMetadataCharityBadge}>
            <TextV2 typographyVariant="label2">{charityLabel}</TextV2>
          </StyledCharityBadge>
        )}
        <StyledGiftMetadataTextContainer stack="horizontal" __css={styles.giftMetadataTextContainer(isCharity)}>
          <ClampedText {...GiftMetadataSelectors.TitleText} typographyVariant="hed2" clampAt={2} wordBreak="break-word" disableTooltip>
            {title}
          </ClampedText>
          {giftAmount && (
            <TextV2 {...GiftMetadataSelectors.GiftAmountText} typographyVariant="hed6">
              <FormattedCurrency priceFloatingPointDecimalString={giftAmount} priceCurrencyCode={currencyCode} formatForm="short" />
            </TextV2>
          )}
        </StyledGiftMetadataTextContainer>
      </StyledGiftMetadataRoot>
    </div>
  );
};
