import React from 'react';
import { InCartProduct } from '../../ShoppingCart.types';
import { SummaryItemList } from './SummaryItemList';
import { ContentContainer, ContentWrapper, HeaderFlex, StyledBox, styles } from './SummaryItemListContainer.styles';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Box, TextV2 } from '@withjoy/joykit';
import { ChevronDown } from '@withjoy/joykit/icons';
import { useTranslation } from '@shared/core';

type SummaryItemListContainerProps = Readonly<{
  inCartProductList: Array<InCartProduct>;
  inCartProductTotal: number;
  deliveryTotal?: number;
  salesTaxTotal?: number;
  registryCurrencyCode?: string;
  isOpen?: boolean;
  hasECard?: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>;

export const SummaryItemListContainer: React.FC<SummaryItemListContainerProps> = props => {
  const isMobileOrTablet = useIsMobileOrTablet();
  const { t } = useTranslation('guestRegistry');
  const { orderSummary } = t('shoppingCart');

  return (
    <StyledBox isOpen={props.isOpen} isMobileOrTablet={isMobileOrTablet} display="flex" flexDirection="column">
      {isMobileOrTablet && (
        <HeaderFlex __css={styles.headerFlexStyles} onClick={() => props.setIsOpen(!props.isOpen)}>
          <TextV2 typographyVariant="hed1">{orderSummary()}</TextV2>
          <Box __css={styles.styledIconV2Styles(props.isOpen)}>
            <ChevronDown />
          </Box>
        </HeaderFlex>
      )}
      {(!isMobileOrTablet || props.isOpen) && (
        <ContentContainer __css={styles.contentContainerStyles}>
          <ContentWrapper __css={styles.contentWrapperStyles}>
            <SummaryItemList {...props} />
          </ContentWrapper>
        </ContentContainer>
      )}
    </StyledBox>
  );
};
