/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Add Circle',
  definitions: {
    '16': {
      viewBox: '0 0 14 14',
      path: (
        <>
          <g clip-path="url(#clip0_8073_52095)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9 7C12.9 10.2585 10.2585 12.9 7 12.9C3.74152 12.9 1.1 10.2585 1.1 7C1.1 3.74152 3.74152 1.1 7 1.1C10.2585 1.1 12.9 3.74152 12.9 7ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM6.99999 2.75001C7.30375 2.75001 7.54999 2.99626 7.54999 3.30001V6.44999H10.7C11.0038 6.44999 11.25 6.69623 11.25 6.99999C11.25 7.30375 11.0038 7.54999 10.7 7.54999H7.54999V10.7C7.54999 11.0038 7.30375 11.25 6.99999 11.25C6.69624 11.25 6.44999 11.0038 6.44999 10.7V7.54999H3.29999C2.99624 7.54999 2.74999 7.30375 2.74999 6.99999C2.74999 6.69623 2.99624 6.44999 3.29999 6.44999H6.44999V3.30001C6.44999 2.99626 6.69624 2.75001 6.99999 2.75001Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_8073_52095">
              <rect width="14" height="14" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8 12C21.8 17.4124 17.4124 21.8 12 21.8C6.58761 21.8 2.2 17.4124 2.2 12C2.2 6.58761 6.58761 2.2 12 2.2C17.4124 2.2 21.8 6.58761 21.8 12ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM12 5.70001C12.3314 5.70001 12.6 5.96863 12.6 6.3V11.4H17.7C18.0314 11.4 18.3 11.6686 18.3 12C18.3 12.3314 18.0314 12.6 17.7 12.6H12.6V17.7C12.6 18.0314 12.3314 18.3 12 18.3C11.6686 18.3 11.4 18.0314 11.4 17.7V12.6H6.3C5.96863 12.6 5.7 12.3314 5.7 12C5.7 11.6686 5.96863 11.4 6.3 11.4H11.4V6.3C11.4 5.96863 11.6686 5.70001 12 5.70001Z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3 16C29.3 23.3454 23.3454 29.3 16 29.3C8.65461 29.3 2.7 23.3454 2.7 16C2.7 8.65461 8.65461 2.7 16 2.7C23.3454 2.7 29.3 8.65461 29.3 16ZM30.5 16C30.5 24.0081 24.0081 30.5 16 30.5C7.99187 30.5 1.5 24.0081 1.5 16C1.5 7.99187 7.99187 1.5 16 1.5C24.0081 1.5 30.5 7.99187 30.5 16ZM16 7.79998C16.3314 7.79998 16.6 8.06861 16.6 8.39998V15.4H23.6C23.9314 15.4 24.2 15.6686 24.2 16C24.2 16.3314 23.9314 16.6 23.6 16.6H16.6V23.6C16.6 23.9314 16.3314 24.2 16 24.2C15.6686 24.2 15.4 23.9314 15.4 23.6V16.6H8.4C8.06863 16.6 7.8 16.3314 7.8 16C7.8 15.6686 8.06863 15.4 8.4 15.4H15.4V8.39998C15.4 8.06861 15.6686 7.79998 16 7.79998Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
