import React from 'react';
import { Flex, Box } from '@withjoy/joykit';
import { StyledCompletePurchaseImage, StyledColumnsContribution } from '../Purchase.styles';
import { UndoContent } from '@apps/registry/guest/routes/Purchase/components/UndoContent';
import { UndoneContent } from '@apps/registry/guest/routes/Purchase/components/UndoneContent';

interface UndoProps
  extends Readonly<{
    handleUndo: (methodText: string) => void;
    handleCancelUndo: () => void;
    methodText: string;
    isUndoConfirmationShown: boolean;
    onBackClick: () => void;
    isMobile: boolean;
    productImage: string;
  }> {}

export const Undo: React.FC<UndoProps> = ({ handleUndo, handleCancelUndo, methodText, isUndoConfirmationShown, onBackClick, isMobile, productImage }) => {
  return (
    <StyledColumnsContribution isMobile={isMobile} isInModal={false}>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={'mono1'} padding={[7, null, 3]}>
        <Box width={['205px', null, '290px']} height={['205px', null, '290px']}>
          <StyledCompletePurchaseImage productImage={productImage} />
        </Box>
      </Flex>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={[6, null, '0px 48px']}>
        {isUndoConfirmationShown ? (
          <UndoneContent onBackClick={onBackClick} />
        ) : (
          <UndoContent methodText={methodText} handleCancelUndo={handleCancelUndo} handleUndo={handleUndo} />
        )}
      </Flex>
    </StyledColumnsContribution>
  );
};
