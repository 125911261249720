import React from 'react';
import { LineItemFragment, RegistryFulfillmentFragment } from '@graphql/generated';
import { CookedProductList } from '@apps/registry/common/selectors/ProductListSelector';
import { ShoppingCartCommonProps } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';

import { ElsewherePurchasedItem } from '../PurchasedItem';

export type PurchasedOrderProps = Readonly<
  {
    productList: CookedProductList['products'];
    orderId?: string;
    isDropship?: boolean;
    orderLineItems?: Array<LineItemFragment>;
    note: Maybe<string>;
    fulfillment: Maybe<RegistryFulfillmentFragment>;
  } & Pick<ShoppingCartCommonProps, 'paths' | 'eventId'>
>;

export const ElsewherePurchasedOrder: React.FC<PurchasedOrderProps> = ({ productList, orderId, isDropship, orderLineItems, eventId, paths, note, fulfillment }) => {
  const lineItem = orderLineItems?.filter(lineItem => lineItem.registryItem)[0];
  const product = productList.find(product => product.id === lineItem?.registryItem?.id);
  const orderItem = orderLineItems?.find(lineItem => {
    return lineItem.quantity;
  });

  return (
    <>
      {product && orderId && (
        <ElsewherePurchasedItem
          paths={paths}
          orderId={orderId}
          isDropship={!!isDropship}
          lineItem={lineItem}
          itemQuantity={orderItem ? orderItem.quantity : 1}
          product={product}
          eventId={eventId}
          note={note}
          fulfillment={fulfillment}
        />
      )}
    </>
  );
};
