import React, { useState } from 'react';
import { TextV2, Flex, TextArea, ButtonV2 } from '@withjoy/joykit';
import { usePurchaseDialogTranslations } from '../../../../hooks';

interface GiftNoteProps {
  isLoading: boolean;
  handleOnUpgradeMyGiftNoteClick: () => void;
  handleOnAddGiftNoteClick: (note: string) => void;
}

export const GiftNoteView = ({ isLoading, handleOnUpgradeMyGiftNoteClick, handleOnAddGiftNoteClick }: GiftNoteProps) => {
  const { fieldNoteLabel, fieldNotePlaceholder, upgradeGiftNote, giftNoteTitle, next } = usePurchaseDialogTranslations();
  const [note, setNote] = useState('');

  const handleUpgradeClicked = () => {
    handleOnUpgradeMyGiftNoteClick();
  };

  const handleOnAddGiftNote = () => {
    handleOnAddGiftNoteClick(note);
  };

  return (
    <>
      <Flex flexDirection="column" alignItems="center" marginX={6}>
        <TextV2 typographyVariant="hed5" marginBottom={6} marginTop={[9, null, 0]}>
          {giftNoteTitle()}
        </TextV2>
        <Flex flexDirection="column" width="100%" maxWidth="520px" marginBottom={7}>
          <TextV2 as="label" htmlFor="note" typographyVariant="label3" marginBottom={3}>
            {fieldNoteLabel()}
          </TextV2>
          <TextArea value={note} onChange={e => setNote(e.target.value)} placeholder={fieldNotePlaceholder()} resize="vertical" minRows={9} />
        </Flex>
        <ButtonV2 intent="productive" variant="outline" onClick={handleUpgradeClicked} marginBottom={6} width={['100%', '214px']}>
          {upgradeGiftNote()}
        </ButtonV2>
        <ButtonV2 intent="productive" loading={isLoading} onClick={handleOnAddGiftNote} width={['100%', '214px']}>
          {next()}
        </ButtonV2>
      </Flex>
    </>
  );
};
