import { useCheckoutFunnel } from '../../hooks/useCheckoutFunnel';
import { useCheckoutInitDataContext } from '@apps/registry/guest/components/CheckoutDialog/hooks/useCheckoutInitData';
import { useTranslation } from '@shared/core';
import { CustomItemDataType } from '@graphql/generated';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useGuestRegistryState } from '@apps/registry/guest/state';
import { useCallback } from 'react';
import { useCheckoutDialogContext } from '../../CheckoutDialog.provider';
import { addAction } from '@shared/utils/logger';

export const usePaperCheckoutInstructionsController = () => {
  const {
    toNext,
    formValues,
    context: { isGroupGifting }
  } = useCheckoutFunnel(({ toNext, formValues, context }) => [toNext, formValues.amount, context.isGroupGifting]);
  const { registryState } = useCheckoutDialogContext();
  const { registryItem } = useCheckoutInitDataContext('paperCheckoutInstructions');
  const { productId } = useGuestRegistryState();
  const { eventInfo } = useEventInfo();
  const { reserveAndPurchase } = useGuestRegistryTelemetry();
  const { t } = useTranslation('guestRegistry');

  const translations = t('checkoutDialog', 'screens', 'paperCheckoutInstructions');
  const isCashFundType = registryItem?.donationFund?.fundType === CustomItemDataType.cash;

  const handleGotItButton = useCallback(() => {
    const reserveAndPurchaseData = {
      eventId: eventInfo?.eventFirebaseId,
      productId,
      registryItemId: registryItem?.id,
      reservedQty: 1,
      priceValueInMinorUnits: (formValues.amount || 0) * 100,
      destinationUrl: registryItem?.productData.externalUrl,
      typeOfItem: registryItem?.donationFund?.fundType,
      buttonLabel: translations.cta(),
      productTitle: registryItem?.productData.title || eventInfo?.eventDisplayName || 'cash fund',
      isGroupGiftingEnabled: isGroupGifting
    };
    reserveAndPurchase(reserveAndPurchaseData);
    addAction('reserveAndPurchase', reserveAndPurchaseData);
    toNext();
  }, [
    eventInfo?.eventDisplayName,
    eventInfo?.eventFirebaseId,
    formValues.amount,
    isGroupGifting,
    productId,
    registryItem?.donationFund?.fundType,
    registryItem?.id,
    registryItem?.productData.externalUrl,
    registryItem?.productData.title,
    reserveAndPurchase,
    toNext,
    translations
  ]);

  return {
    toNext: handleGotItButton,
    registryItem,
    translations,
    isCashFundType,
    shippingAddress: registryState?.shippingAddress
  };
};
