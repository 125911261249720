import { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import debounce from 'lodash-es/debounce';
import {
  AddressSearchType,
  AddressResponseType,
  InternationalAddressSearchType,
  InternationalAddressResponseType,
  useAddressAutocomplete
} from '@apps/registry/common/utils/smartyClient';
import { SelectV1, OptionType } from '@withjoy/joykit';
import { ShippingAddressFragment } from '@graphql/generated';
import { TranslateFunction } from '@locales/generated';
import { AddressDialogFields, AddressUsedEnum } from '../AddressDialog/types';

const MIN_CHARACTERS_ADDRESS_VALIDATION = 3;
const DEBOUNCE_TIMEOUT = 200;
export const DISABLE_ADDRESS_VALIDATION_KEY = 'DISABLE_ADDRESS_VALIDATION_KEY';

const PO_BOXES_ENTRIES = ['PO Box', 'POBox', 'P.O. Box', 'P.O.Box'];

type AddressSuggestionList = ReadonlyArray<AddressResponseType | InternationalAddressResponseType>;

type AddressAutocompleteFieldControllerProps = Readonly<{
  addressWithMoreThanOneSuggestion: TranslateFunction;
  firstOptionAddress1Field: TranslateFunction;
  lastOptionAddress1Field: TranslateFunction;
  required: TranslateFunction;
  placeholderError: string;
  registryShippingAddress: ShippingAddressFragment | null;
  formik: FormikProps<AddressDialogFields>;
  formikKey: string;
  isNationalAddress: boolean;
  countryDropdownValue: string;
  clearAddressPressed: boolean;
  setSuggestedAddress?: (value: AddressDialogFields | null) => void;
  onCallSuggestionApi?: (isNationalAddress: boolean) => void;
  setAddressUsed?: (addressUsed: AddressUsedEnum) => void;
  onDisableAddressSuggestions?: () => void;
}>;

export const useAddressAutocompleteFieldController = (props: AddressAutocompleteFieldControllerProps) => {
  const {
    addressWithMoreThanOneSuggestion,
    firstOptionAddress1Field,
    lastOptionAddress1Field,
    required,
    placeholderError,
    registryShippingAddress,
    formik,
    formikKey,
    isNationalAddress,
    countryDropdownValue,
    clearAddressPressed,
    setSuggestedAddress,
    onCallSuggestionApi,
    setAddressUsed,
    onDisableAddressSuggestions
  } = props;

  const { Lookup, handleAddressSearch, InternationalLookup, handleInternationalAddressSearch } = useAddressAutocomplete();

  const address1FieldRef = useRef<SelectV1<OptionType>>(null);

  const [addressSuggestion, setAddressSuggestion] = useState<AddressSuggestionList>([]);
  const [showAddressLoading, setShowAddressLoading] = useState<boolean>(false);

  const [showAddress1DropdownSuggestionList, setShowAddress1DropdownSuggestionList] = useState<boolean>(false);
  const [isAddressValidationEnabled, setIsAddressValidationEnabled] = useState<boolean>(isNationalAddress);
  const [isAddressManuallyOptionSelected, setIsAddressManuallyOptionSelected] = useState<boolean>(false);

  const addressSuggestionList: OptionType[] = useMemo(() => {
    const partialList: OptionType[] = addressSuggestion.map((suggestion, i) => {
      let label = '';
      if (isNationalAddress) {
        const nationalSuggestion = suggestion as AddressResponseType;
        let streetLine = `${nationalSuggestion.streetLine} ${nationalSuggestion.secondary}`;
        if (nationalSuggestion.entries > 1) {
          streetLine += ` (${addressWithMoreThanOneSuggestion({ entries: nationalSuggestion.entries })})`;
        }
        const stateWithZipCode = [nationalSuggestion.state, nationalSuggestion.zipcode].filter(x => !!x).join(' ');
        label = [streetLine, nationalSuggestion.city, stateWithZipCode].filter(x => !!x).join(', ');
      } else {
        const internationalSuggestion = suggestion as InternationalAddressResponseType;
        const administrativeArea = !!internationalSuggestion.administrativeArea ? internationalSuggestion.administrativeArea : null;
        label = [internationalSuggestion.street, internationalSuggestion.locality, administrativeArea, internationalSuggestion.countryIso3].filter(x => !!x).join(', ');
      }

      return {
        value: i.toString(),
        label
      };
    });
    if (partialList.length) {
      partialList.unshift({
        value: '-1',
        disabled: true,
        label: firstOptionAddress1Field()
      });
      partialList.push({
        value: DISABLE_ADDRESS_VALIDATION_KEY,
        label: lastOptionAddress1Field()
      });
    }

    return partialList;
  }, [addressSuggestion, addressWithMoreThanOneSuggestion, firstOptionAddress1Field, isNationalAddress, lastOptionAddress1Field]);

  const [address1Dropdown, setAddress1Dropdown] = useState<OptionType>({
    label: registryShippingAddress?.address1 || '',
    value: registryShippingAddress?.address1 || ''
  });

  const error = useMemo(() => {
    const labelForRegex = PO_BOXES_ENTRIES.map(entry => `(\\b${entry.toLocaleLowerCase()}\\b)`).join('|');
    const regex = new RegExp(`\(${labelForRegex}\)`, 'g');

    const value = formik.values[formikKey as keyof AddressDialogFields];
    if (typeof value === 'string' && regex.test(value.toLowerCase())) {
      return placeholderError;
    }

    if (isAddressValidationEnabled) {
      return formik.touched.address1 && !formik.values.address1 ? required() : undefined;
    }
    return formik.touched.address1 && formik.errors.address1 ? formik.errors.address1 : undefined;
  }, [formik.errors.address1, formik.touched.address1, formik.values, formikKey, isAddressValidationEnabled, placeholderError, required]);

  const handleNationalAddressAutocomplete = useCallback(
    async (value: string, selected?: string) => {
      const lookup: AddressSearchType = new Lookup(value);
      if (selected) {
        lookup.selected = selected;
      }

      const response = await handleAddressSearch(lookup);
      return response;
    },
    [Lookup, handleAddressSearch]
  );

  const fetchInternationalSuggestions = useCallback(
    async (value: string, countryCode: string) => {
      const lookup: InternationalAddressSearchType = new InternationalLookup(value, countryCode);
      const response = await handleInternationalAddressSearch(lookup);
      return response;
    },
    [InternationalLookup, handleInternationalAddressSearch]
  );

  const fetchSuggestionsDebounced = useMemo(() => {
    const fetchSuggestions = async (text: string) => {
      !addressSuggestion.length && setShowAddressLoading(true);
      setShowAddress1DropdownSuggestionList(true);
      onCallSuggestionApi && onCallSuggestionApi(isNationalAddress);

      const suggestions = await (isNationalAddress ? handleNationalAddressAutocomplete(text) : fetchInternationalSuggestions(text, countryDropdownValue));
      setAddressSuggestion(suggestions);
      !addressSuggestion.length && setShowAddressLoading(false);
    };

    return debounce(fetchSuggestions, DEBOUNCE_TIMEOUT);
  }, [addressSuggestion.length, onCallSuggestionApi, isNationalAddress, handleNationalAddressAutocomplete, fetchInternationalSuggestions, countryDropdownValue]);

  const getAddressWithMoreThanOneSuggestion = useCallback(
    async (option: AddressResponseType) => {
      const search = `${option.streetLine} ${option.secondary}`;
      const selected = `${search} (${option.entries}) ${option.city} ${option.state} ${option.zipcode}`;
      const suggestions = await handleNationalAddressAutocomplete(search, selected);

      setAddressSuggestion(suggestions);
    },
    [handleNationalAddressAutocomplete]
  );

  const handleAddressSelection = useCallback(
    (option: OptionType | null) => {
      if (option?.value === DISABLE_ADDRESS_VALIDATION_KEY) {
        setIsAddressValidationEnabled(false);
        setShowAddress1DropdownSuggestionList(false);
        setIsAddressManuallyOptionSelected(true);
        onDisableAddressSuggestions && onDisableAddressSuggestions();
        return;
      }
      if (!option || !addressSuggestion[option.value as keyof typeof addressSuggestion]) {
        setShowAddress1DropdownSuggestionList(false);
        return;
      }

      // AddressSuggestion incompatible with OptionType
      // @ts-ignore
      const address = addressSuggestion[option.value];
      if (address.entries > 1) {
        setShowAddress1DropdownSuggestionList(true);
        getAddressWithMoreThanOneSuggestion(address);
        return;
      }

      address1FieldRef?.current?.inputRef?.blur();
      setShowAddress1DropdownSuggestionList(false);
      setAddress1Dropdown({ value: option.value, label: isNationalAddress ? address.streetLine : address.street });
      formik.setFieldValue('address1', isNationalAddress ? address.streetLine : address.street);
      formik.setFieldValue('address2', isNationalAddress ? address.secondary : '');
      formik.setFieldValue('postalCode', isNationalAddress ? address.zipcode : address.postalCode);
      formik.setFieldValue('city', isNationalAddress ? address.city : address.locality);
      formik.setFieldValue('state', isNationalAddress ? address.state : address.administrativeArea || '');

      setAddressUsed && setAddressUsed(AddressUsedEnum.SUGGESTED_ORIGINAL);
      if (setSuggestedAddress) {
        setSuggestedAddress({
          name: formik.values.name,
          address1: isNationalAddress ? address.streetLine : address.street,
          address2: isNationalAddress ? address.secondary : '',
          postalCode: isNationalAddress ? address.zipcode : address.postalCode,
          city: isNationalAddress ? address.city : address.locality,
          state: isNationalAddress ? address.state : address.administrativeArea || '',
          country: formik.values.country,
          countryCode: formik.values.countryCode || '',
          validated: false
        });
      }
    },
    [addressSuggestion, isNationalAddress, formik, setAddressUsed, setSuggestedAddress, onDisableAddressSuggestions, getAddressWithMoreThanOneSuggestion]
  );

  const handleAddressInputChange = useCallback(
    (value: string) => {
      const inputValueEmpty: boolean = !value && !!address1Dropdown.value;
      if (inputValueEmpty || value.length < MIN_CHARACTERS_ADDRESS_VALIDATION) {
        inputValueEmpty && setAddress1Dropdown({ value: '', label: '' });
        setAddressSuggestion([]);
        if (setSuggestedAddress) setSuggestedAddress(null);
        setShowAddress1DropdownSuggestionList(false);

        formik.setFieldValue('address1', value);
        !formik.touched.address1 && !clearAddressPressed && formik.setTouched({ ...formik.touched, address1: true });
        return;
      }

      const labelForRegex = addressWithMoreThanOneSuggestion({ entries: '([1-9])+' });
      const regex = new RegExp(`\(${labelForRegex}\)`, 'g');
      if (regex.test(value)) {
        // it has been selected an address with two or more entries
        return;
      }

      if (value === lastOptionAddress1Field()) {
        // it has been selected disable address validation
        return;
      }

      formik.setFieldValue('address1', value);
      !formik.touched.address1 && !clearAddressPressed && formik.setTouched({ ...formik.touched, address1: true });

      fetchSuggestionsDebounced(value);
    },
    [address1Dropdown.value, addressWithMoreThanOneSuggestion, lastOptionAddress1Field, formik, clearAddressPressed, fetchSuggestionsDebounced, setSuggestedAddress]
  );

  useEffect(() => {
    if (clearAddressPressed) {
      setAddress1Dropdown({
        label: '',
        value: ''
      });
      formik.setFieldValue(formikKey, '');
      setAddressSuggestion([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAddressPressed]);

  // DROP-1040
  // Autocomplete functionality for international address is disabled for the time being
  // In case we want to restore it, the state isAddressManuallyOptionSelected is not longer necessary
  useEffect(() => {
    if (isAddressValidationEnabled && !isNationalAddress) {
      setIsAddressValidationEnabled(false);
    } else if (!isAddressManuallyOptionSelected && !isAddressValidationEnabled && isNationalAddress) {
      setIsAddressValidationEnabled(true);
    }
  }, [isAddressManuallyOptionSelected, isAddressValidationEnabled, isNationalAddress]);

  useEffect(() => {
    if (formik.errors.address1 !== placeholderError && error === placeholderError) {
      formik.setErrors({ address1: placeholderError });
    }
  }, [placeholderError, error, formik]);

  useEffect(() => {
    return () => {
      fetchSuggestionsDebounced.cancel();
    };
  }, [fetchSuggestionsDebounced]);

  return {
    error,
    isInvalid: !!error,
    address1FieldRef,
    showAddressLoading,
    showAddress1DropdownSuggestionList,
    isAddressValidationEnabled,
    addressSuggestionList,
    address1Dropdown,
    handleAddressSelection,
    handleAddressInputChange
  };
};
