import { Box, styled, LinkV1, Radio, ButtonV1, Flex } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const ReservedItemBox = styled(Box)`
  padding-bottom: ${pxToRem(8)};
  border-radius: ${pxToRem(8)};
  overflow: hidden;
`;

export const StyledItemBox = styled(Box)`
  background: ${props => props.theme.colors.white};
  padding: ${pxToRem(15)};
  button {
    margin: ${pxToRem(8)} 0;
  }
`;

export const StyleCheckbox = styled(Radio)`
  margin: ${pxToRem(8)} 0;
  & > span {
    border-radius: 100%;
  }
`;

export const StyleRemoveButton = styled(ButtonV1)`
  width: 100%;
  background-color: ${props => props.theme.colors.buttonDestructiveActive};
  :hover {
    background-color: ${props => props.theme.colors.buttonDestructiveHover} !important;
  }
`;

export const StyleLink = styled(LinkV1)<{ isCancel?: boolean }>`
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    margin-right: ${pxToRem(4)};
    path {
      fill: ${({ theme, isCancel }) => (isCancel ? theme.colors.typeSecondaryDark : theme.colors.green7)};
    }
  }
`;

export const StyledFlex = styled(Flex)`
  margin-top: ${pxToRem(8)};
  justify-content: space-between;
  align-items: center;
`;
