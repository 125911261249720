import React from 'react';
import { StyledGridContainer, styles } from './ProductListView.styles';
import { Flex, SpacingStack } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';

export const ProductListViewSkeleton: React.FC = () => {
  return (
    <Flex flexDirection="column" alignItems="flex-end">
      <SkeletonThumbnail width={pxToRem(180)} height={48} marginBottom={10} />
      <StyledGridContainer numberOfItems={8} __css={styles.nonZeroProductListStyles} width="100%">
        {[1, 2, 3, 4, 5, 6, 7, 8].map(item => {
          return (
            <Flex
              key={item}
              height={[pxToRem(389), null, null, pxToRem(438), pxToRem(354)]}
              position="relative"
              alignItems="center"
              paddingTop={pxToRem(37)}
              flexDirection="column"
            >
              <SkeletonThumbnail width="100%" paddingTop="100%" height={187} />
              <SpacingStack spacing={2} paddingTop={5} alignItems="center">
                <SkeletonText rows={1} skeletonHeight={pxToRem(18)} width={pxToRem(50)} />
                <SkeletonText rows={1} skeletonHeight={pxToRem(20)} width={pxToRem(120)} />
                <SkeletonText rows={1} skeletonHeight={pxToRem(20)} width={pxToRem(120)} />
                <SkeletonText rows={1} skeletonHeight={pxToRem(18)} width={pxToRem(50)} />
              </SpacingStack>
            </Flex>
          );
        })}
      </StyledGridContainer>
    </Flex>
  );
};
