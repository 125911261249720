import { LinkV2, styled, StyleSystemProps, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledDefaultEmailText = styled(TextV2)``;
export const StyledDefaultEmailLink = styled(LinkV2)``;

const defaultEmailLinkStyles: StyleSystemProps = {
  display: 'inline-block',
  textDecoration: 'none',
  marginBottom: pxToRem(22),
  marginTop: 1,
  fontWeight: 600
};

export const styles = {
  defaultEmailLink: defaultEmailLinkStyles
};
