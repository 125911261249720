import { greetingCardBaseUrl } from '@apps/greetingCard/GreetingCard.controller';
import { useSaveGreetingCardAndEmailDraftMutation, useSavePostOfficeGiftWrapEmailDraftMutation } from '@graphql/generated';
import { EGiftWrapType, getGiftWrapThemeByID, IGiftWrapPayload, IGreetingCardPayload } from '@shared/components';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useCallback } from 'react';

// Extracted from GuestRegistry.telemetry.ts.
type GiftWrapDraftSaveError = ({
  thrownError,
  draft
}: {
  thrownError: unknown;
  draft: object;
}) => {
  category: string;
  action: string;
  label: string;
  extraInfo: {
    thrownError: unknown;
    draft: object;
  };
};

type GiftWrapPayload = {
  message: string;
  themeId: string;
  image: string;
  store?: string | undefined;
  title: string;
  giftLink: string;
};

export const useSaveCardDraft = (giftWrapDraftSaveError?: GiftWrapDraftSaveError) => {
  const { value: greetingCardsEnabled } = useFeatureValue('greetingCardsEnabled');
  const { eventInfo } = useEventInfo();
  const isGreetingCardsEnabled = greetingCardsEnabled === 'on';
  const [saveGreetingCardDraft, { loading: savingGreetingCardDraft }] = useSaveGreetingCardAndEmailDraftMutation();
  const [saveGiftWrapDraft, { loading: savingGiftWrapDraft }] = useSavePostOfficeGiftWrapEmailDraftMutation();
  const isSaving = isGreetingCardsEnabled ? savingGreetingCardDraft : savingGiftWrapDraft;

  const saveCardDraft = useCallback(
    async (eventId: string | undefined, payload: GiftWrapPayload): Promise<string | undefined> => {
      const message = payload.message || '';

      const isPremium = getGiftWrapThemeByID(payload.themeId)?.type === EGiftWrapType.PREMIUM;

      const draftValuesGreetingCard: IGreetingCardPayload = {
        type: 'giftWrap',
        baseUrl: greetingCardBaseUrl,
        eventId: eventId || '',
        cardDetails: {
          message,
          name1: isPremium ? eventInfo?.fianceeFirstName ?? undefined : undefined,
          name2: isPremium ? eventInfo?.ownerFirstName ?? undefined : undefined,
          product: {
            title: payload?.title ?? '',
            image: payload?.image,
            link: payload.giftLink
          }
        },
        theme: payload.themeId ?? 'none'
      };

      const draftValuesGiftWrap: IGiftWrapPayload = {
        eventId: eventId || '',
        message,
        theme: payload.themeId ?? 'none', // Why theme?
        giftTitle: payload?.title ?? '',
        giftImage: payload?.image,
        giftLink: payload.giftLink
      };

      try {
        if (isGreetingCardsEnabled) {
          const draftResponse = await saveGreetingCardDraft({
            variables: {
              payload: draftValuesGreetingCard
            }
          });
          return draftResponse.data?.saveGreetingCardAndEmailDraft?.id;
        } else {
          const draftResponse = await saveGiftWrapDraft({
            variables: draftValuesGiftWrap
          });
          return draftResponse.data?.savePostOfficeGiftWrapEmailDraft.id;
        }
      } catch (e) {
        giftWrapDraftSaveError?.({ thrownError: e, draft: isGreetingCardsEnabled ? draftValuesGreetingCard : draftValuesGiftWrap });
      }
      return undefined;
    },
    [eventInfo, isGreetingCardsEnabled, saveGreetingCardDraft, saveGiftWrapDraft, giftWrapDraftSaveError]
  );

  return [saveCardDraft, isSaving] as const;
};
