import { Box, Flex, IconV2, styled, StyleSystemProps } from '@withjoy/joykit';
import { keyframes, pxToRem } from '@withjoy/joykit/theme';

const slideIn = keyframes`
  100% { transform: translateY(0%); }
`;

export const StyledBox = styled(Box)<{ isOpen?: boolean; isMobileOrTablet?: boolean }>`
  width: ${props => (props.isMobileOrTablet ? '100%' : '41.7%')};
  background-color: ${props => props.theme.colors.mono1};
  flex-grow: ${props => (props.isMobileOrTablet ? 1 : 0)};
  overflow: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  height: ${props => (props.isOpen ? '100%' : 'auto')};
  transform: ${props => (props.isOpen ? 'translateY(90%)' : 'none')};
  -webkit-transform: ${props => (props.isOpen ? 'translateY(90%)' : 'none')};
  transition: ${props => (props.isOpen ? 'width 0.5s ease-in-out' : 'none')};
  animation: ${slideIn} 0.5s forwards;
`;

export const ContentContainer = styled(Flex)``;
export const ContentWrapper = styled(Flex)``;
export const HeaderFlex = styled(Flex)``;
export const StyledIconV2 = styled(IconV2)``;

const styledIconV2Styles = (isOpen?: boolean): StyleSystemProps => ({
  transition: 'transform 500ms',
  transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'
});

const contentContainerStyles: StyleSystemProps = {
  paddingX: { _: 0, md: 10 },
  paddingTop: {
    _: 0,
    md: 10
  },
  justifyContent: { _: 'start', md: 'center' },
  flex: 1
};

const contentWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  width: { _: '100%', md: pxToRem(320) }
};

const headerFlexStyles: StyleSystemProps = {
  borderTop: '1px solid',
  borderTopColor: 'mono3',
  borderBottom: '1px solid',
  borderBottomColor: 'mono3',
  backgroundColor: 'mono1',
  paddingX: pxToRem(24),
  paddingY: pxToRem(16),
  justifyContent: 'space-between',
  cursor: 'pointer'
};

export const styles = {
  contentWrapperStyles,
  contentContainerStyles,
  headerFlexStyles,
  styledIconV2Styles
};
