/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Card Check',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M2.757 3.334c-.318 0-.592.225-.654.537L.385 12.538c-.082.412.234.796.654.796h10.658c.318 0 .592-.225.654-.537l.158-.796h2.453c.423 0 .739-.388.653-.802L14.103 3.92c-.021-.099-.061-.187-.116-.261-.115-.193-.326-.325-.572-.325H2.757zm.356 1.1l-1.547 7.8h9.774l1.547-7.8H3.113zm10.445 2.272l-.832 4.194h1.703l-.871-4.194zm-8.11 2.747a.6.6 0 0 1 .6-.6h1.584a.6.6 0 1 1 0 1.2H6.048a.6.6 0 0 1-.6-.6zm-.104-2.817a.6.6 0 0 0 0 1.2H9.16a.6.6 0 0 0 0-1.2H5.345z"
            fill="#176f69"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
