import React from 'react';
import { ContentHeader, CtaGroup, IntegratedGiftCard, LayoutShell, PrimaryCta, SecondaryCta } from '../../components';
import { useCancelGiftWarningController } from './CancelGiftWarning.controller';
import { CancelGiftWarningSelectors } from './CancelGiftWarning.selectors';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';

export const CancelGiftWarning = () => {
  const { confirmGiftCancel, abortGiftCancel, translations, isAffiliate, productImageSrc, product, currency } = useCancelGiftWarningController();

  const { title, subtitle, affirmativeButton, negativeButton } = translations;

  return (
    <LayoutShell {...CancelGiftWarningSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement="float" />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} currency={currency} />
          ) : (
            <IntegratedGiftCard mobileRenderBehavior="fullBleed" />
          )}
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <ContentHeader>
            <ContentHeader.Hed>{title}</ContentHeader.Hed>
            <ContentHeader.Dek>{subtitle}</ContentHeader.Dek>
          </ContentHeader>
          <CtaGroup marginTop={7}>
            <PrimaryCta {...CancelGiftWarningSelectors.AffirmativeButton} onClick={confirmGiftCancel}>
              {affirmativeButton}
            </PrimaryCta>
            <SecondaryCta {...CancelGiftWarningSelectors.NegativeButton} onClick={abortGiftCancel}>
              {negativeButton}
            </SecondaryCta>
          </CtaGroup>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
