import React from 'react';
import { useTranslation } from '@shared/core';
import { useShoppingCart } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart/state';
import { CartCheckoutSteps } from '@apps/registry/guest/routes/GuestRegistry/components/ShoppingCart';
import { ButtonV2 } from '@withjoy/joykit';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useFeatureValue } from '@shared/core/featureFlags';
import { getGiftWrapThemeListByEventType } from '@shared/components';

type CartFooterSubmitButtonProps = { saveData?: () => Promise<void> | void | boolean; disabled?: boolean };

export const CartFooterSubmitButton = ({ saveData, disabled }: CartFooterSubmitButtonProps) => {
  const { value: giftWrapOn } = useFeatureValue('registryGiftWrapExperimentEnabled');
  const registryGiftWrapExperimentEnabled = giftWrapOn === 'on';
  const { value: gwRegCartEnabled } = useFeatureValue('registryGiftWrapCartEnabled');
  const registryGiftWrapCartEnabled = gwRegCartEnabled === 'on';
  const { eventInfo } = useEventInfo();
  const themes = getGiftWrapThemeListByEventType(eventInfo?.eventType);
  const eventTypeHasThemes = themes && themes.length > 0;
  // Verify the registry item is gift wrap experiment is enabled, and gift wrap for cart is enabled
  const isGiftWrapEnabled = registryGiftWrapExperimentEnabled && registryGiftWrapCartEnabled && eventTypeHasThemes;
  const { t } = useTranslation('guestRegistry');
  const { checkOutButton, continueToDetailsButton, continueToMessageButton, continueToPaymentButton } = t('shoppingCart');
  const { shoppingCartCheckoutClick, shoppingCartAddShippingClick, shoppingCartAddDetailsClick } = useGuestRegistryTelemetry();
  const {
    state: { currentCheckoutStep, itemList, itemListCount },
    mutators: { updateCurrentCheckoutStep }
  } = useShoppingCart();

  const telemArgs = {
    eventId: eventInfo?.eventId,
    itemListCount,
    cartTotalValueInMinorUnits: itemList.reduce((prev, curr) => curr.priceInMinorUnits * curr.quantity + prev, 0)
  };

  const getSubmitButtonText = () => {
    if (currentCheckoutStep === CartCheckoutSteps.Cart) {
      return checkOutButton();
    }
    if (currentCheckoutStep === CartCheckoutSteps.Shipping) {
      return continueToDetailsButton();
    }
    if (currentCheckoutStep === CartCheckoutSteps.Details) {
      if (isGiftWrapEnabled) {
        return continueToMessageButton();
      }
      return continueToPaymentButton();
    }
    if (currentCheckoutStep === CartCheckoutSteps.Message) {
      return continueToPaymentButton();
    }
    return checkOutButton();
  };

  const handleCheckoutClick = async () => {
    if (currentCheckoutStep === CartCheckoutSteps.Cart) {
      shoppingCartCheckoutClick(telemArgs);
      updateCurrentCheckoutStep(CartCheckoutSteps.Shipping);
    }
    if (currentCheckoutStep === CartCheckoutSteps.Shipping) {
      shoppingCartAddShippingClick(telemArgs);
      updateCurrentCheckoutStep(CartCheckoutSteps.Details);
    }
    if (currentCheckoutStep == CartCheckoutSteps.Details) {
      if (saveData) {
        await saveData();
      }
      shoppingCartAddDetailsClick(telemArgs);
      updateCurrentCheckoutStep(isGiftWrapEnabled ? CartCheckoutSteps.Message : CartCheckoutSteps.Payment);
    }
    if (currentCheckoutStep == CartCheckoutSteps.Message) {
      if (saveData) {
        const shouldContinue = await saveData();
        if (shouldContinue === false) return;
      }
      shoppingCartAddDetailsClick(telemArgs);
      updateCurrentCheckoutStep(CartCheckoutSteps.Payment);
    }
  };

  return (
    <ButtonV2 intent="neutral" marginTop={6} fullWidth onClick={handleCheckoutClick} disabled={itemList.length === 0 || disabled}>
      {getSubmitButtonText()}
    </ButtonV2>
  );
};
