/**
 * Return css to clamps line
 * e.g. www.withjoy.com/assets/image.jpg ->
 * clampsText(2)
 * @param {number} lines - The number of lines.
 */
export const clampsText = (lines: number) => {
  if (lines > 0) {
    return `
      display: -webkit-box;
      line-clamp: ${lines};
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;`;
  }
  return;
};
