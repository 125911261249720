import { createContext } from '@shared/utils/createContext';
import React, { useCallback, useState } from 'react';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';
import { getAllowedDissmisalAttemptsSum } from '../../../GuestRegistry/components/ShoppingCart/utils/trackPurchaseClicks';

type CurrentOrder = {
  orderId: string;
  productId: string;
  modalStateToBeOpened?: ModalStates;
};

type CurrentOrderOrEmpty = CurrentOrder | {};

interface PurchaseClickHistoryData {
  remainingPurchaseClickHistoryAttempts?: number;
  setRemainingPurchaseClickHistoryAttempts: (val?: number) => void;
  attemptToShowPurchaseClickHistory: boolean;
  setAttemptToShowPurchaseClickHistory: (val: boolean) => void;
  currentPurchaseHistoryItem: number;
  setCurrentPurchaseHistoryItem: (val: number) => void;
}

type PurchaseConfirmationContext = {
  // State
  isOpen: boolean;
  modalData: CurrentOrderOrEmpty;
  // Operations
  openModal: (modalData: CurrentOrderOrEmpty) => void;
  closeModal: () => void;
  purchaseHistoryData: PurchaseClickHistoryData;
  isOutOfStock: boolean;
  setIsOutOfStock: (val: boolean) => void;
};

const [Provider, usePurchaseConfirmationProvider] = createContext<PurchaseConfirmationContext>({ name: 'PurchaseConfirmationContextProvider' });

interface PurchaseConfirmationProviderProps
  extends Readonly<{
    children: React.ReactNode;
  }> {}

const PurchaseConfirmationModalProvider = (props: PurchaseConfirmationProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CurrentOrderOrEmpty>({});
  const [remainingPurchaseClickHistoryAttempts, setRemainingPurchaseClickHistoryAttempts] = useState(getAllowedDissmisalAttemptsSum());
  const [attemptToShowPurchaseClickHistory, setAttemptToShowPurchaseClickHistory] = useState(false);
  const [currentPurchaseHistoryItem, setCurrentPurchaseHistoryItem] = useState(0);
  const [isOutOfStock, setIsOutOfStock] = useState<boolean>(false);

  const onOpen = useCallback(
    (modalData: CurrentOrderOrEmpty) => {
      setModalData(modalData);
      setIsOpen(true);
    },
    [setIsOpen, setModalData]
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
    setModalData({});
  }, [setIsOpen, setModalData]);

  return (
    <Provider
      value={{
        isOpen,
        closeModal: onClose,
        openModal: onOpen,
        modalData,
        isOutOfStock,
        setIsOutOfStock,
        purchaseHistoryData: {
          remainingPurchaseClickHistoryAttempts,
          setRemainingPurchaseClickHistoryAttempts,
          attemptToShowPurchaseClickHistory,
          setAttemptToShowPurchaseClickHistory,
          currentPurchaseHistoryItem,
          setCurrentPurchaseHistoryItem
        }
      }}
    >
      {props.children}
    </Provider>
  );
};

PurchaseConfirmationModalProvider.displayName = 'PurchaseConfirmationModalProvider';

export { PurchaseConfirmationModalProvider, usePurchaseConfirmationProvider, CurrentOrder };
