import React, { useMemo } from 'react';
import { useInterpret } from '@xstate/react';
import { CheckoutMachineInterpreterObserver, createCheckoutMachine } from './machines/externalCheckout';
import { Provider } from './hooks/useCheckoutFunnel';
import { CheckoutDialogFields, CheckoutFunnelProviderProps } from './CheckoutDialog.types';
import useCheckoutDialogController from './CheckoutDialog.controller';
import { useFeatureValue } from '@shared/core/featureFlags';
import { parseGiftWrapPayload } from '@shared/components';

/**
 * TODO:
 *  - handle authenticated email session (should use useStoredGiftReservationFieldsContext)
 *  - handle calling `updateDataProvider`
 */
export function CheckoutFunnelProvider({
  children,
  onClose,
  registryItemId,
  currentOrderId,
  registryState,
  onReserveGift,
  onPurchaseGift,
  isAdmin,
  isAffiliate,
  isGiftWrapPurchased,
  isGroupGifting,
  donationAmount,
  eventId
}: CheckoutFunnelProviderProps): React.ReactElement {
  const { value: registryGiftWrapCashOn } = useFeatureValue('registryGiftWrapCashEnabled');
  const { value: registryGiftWrapAffiliateOn } = useFeatureValue('registryGiftWrapAffiliateEnabled');
  const {
    handleAddToCart,
    handleCompletePurchase,
    handleRemoveFromCart,
    handleAnnotateRegistryOrder,
    handleCreatePurchaseContext,
    currentOrderIdState,
    giftWrapLocationInfo,
    handleCreditCardPurchase,
    creditCardError
  } = useCheckoutDialogController({
    registryState,
    registryItemId,
    currentOrderId,
    isAdmin,
    isAffiliate,
    isGroupGifting,
    eventId
  });

  const observer = useMemo<CheckoutMachineInterpreterObserver>(() => {
    return {
      next: state => {
        // console.log('>>> next', { state });
      },
      error: error => {
        // console.log('>>> error', error);
      },
      complete: () => {
        // console.log('>>> complete');
      }
    };
  }, []);

  const getMachine = () =>
    createCheckoutMachine(
      isAffiliate,
      isGroupGifting,
      donationAmount,
      registryGiftWrapCashOn === 'on',
      registryGiftWrapAffiliateOn === 'on',
      isGiftWrapPurchased,
      giftWrapLocationInfo.allow
    );

  const service = useInterpret(
    getMachine,
    {
      actions: {
        onCloseDialog: (context, event, { state }) => {
          onClose();
        },

        reserveAndAddToCart: async ({ formValues, flowVariant }, event, machine) => {
          switch (event.type) {
            case 'SUBMIT_GUEST_DETAILS': {
              const { name, email } = formValues;
              if (name && email && isAffiliate) {
                await handleAddToCart(formValues as CheckoutDialogFields);
              }
              return;
            }
            case 'SELECT_PAYMENT_METHOD': {
              const { name, email, paymentMethod } = formValues;
              if (name && email && paymentMethod) {
                await handleAddToCart(formValues as CheckoutDialogFields);
              }
              return;
            }
            case 'SUBMIT_GIFT_NOTE': {
              const { name, email, paymentMethod } = formValues;
              if (name && email && (paymentMethod || isAffiliate)) {
                await handleAddToCart(formValues as CheckoutDialogFields);
              }
              return;
            }
            case 'SUBMIT_GIFT_WRAP': {
              const { name, email, paymentMethod, note } = formValues;
              const payload = parseGiftWrapPayload(note || '');

              if (name && email && (paymentMethod || isAffiliate)) {
                await handleAddToCart({ ...(formValues as CheckoutDialogFields), note: payload?.message || note || '' });
              }
              return;
            }
          }
        },

        removeFromCart: async (context, event) => {
          switch (event.type) {
            case 'CONFIRM_CANCEL': {
              handleRemoveFromCart();
              return;
            }
          }
        },

        markGiftAsPurchased: async ({ formValues }) => {
          handleCompletePurchase(formValues as CheckoutDialogFields, onPurchaseGift);
          onPurchaseGift?.();
        },
        annotateRegistryOrder: async ({ formValues }) => {
          handleAnnotateRegistryOrder(formValues as CheckoutDialogFields);
        },
        createPurchaseContext: async state => {
          handleCreatePurchaseContext(
            state.formValues as CheckoutDialogFields,
            state.registryItemId!,
            state.isAffiliate,
            state.registryItemName || '',
            (state.registryItemPrice ? state.registryItemPrice / 100 : state.formValues.amount) || 0
          );
        },
        createCreditCardPurchase: async state => {
          handleCreditCardPurchase(state.formValues as CheckoutDialogFields, state.registryItemId!);
        }
      }
    },
    observer
  );

  const ctx = useMemo(() => {
    return {
      service,
      currentOrderIdState,
      creditCardError
    };
  }, [service, currentOrderIdState, creditCardError]);

  return <Provider value={ctx}>{children}</Provider>;
}
