import { CookedProduct, isCustomDonationFundItem } from '@apps/registry/common/selectors/ProductListSelector';
import { OrderList, RegistryList } from '@apps/registry/common/state/RegistryProducts';
import { ShippingAddressFragment } from '@graphql/aliases';
import { DonationFundPaymentMethodEnum } from '@graphql/generated';
import React, { useState } from 'react';
import { GuestRegistryContext } from './';

type Order = {
  orderId: string;
  donationFundPlatformType?: DonationFundPaymentMethodEnum;
};

type Guest = {
  name: string;
  email: string;
};

interface DataProps {
  type: '' | 'reserve' | 'purchased' | 'initial' | 'undo' | 'error' | 'thanks' | 'markAsPurchased';
  productId?: string;
  guest: Guest | null;
  order: Order | null;
  quantity: number;
  shippingAddress: ShippingAddressFragment | null;
}

interface StateProps extends DataProps {
  registry: RegistryList;
  orders: OrderList;
  shippingAddress: ShippingAddressFragment | null;
}

export interface ProviderProps {
  type: DataProps['type'];
  productId?: string;
  guest: Guest | null;
  order: Order | null;
  shippingAddress: ShippingAddressFragment | null;
  quantity: number;
  registry: RegistryList;
  orders: OrderList;
  updateDataProvider: (data: Partial<DataProps>) => void;
  updateOrdersAndRegistry: (orders: OrderList, registry: RegistryList) => void;
  updateOrderDataFromOrderId: (orderId: string, productId: string, products: Readonly<CookedProduct[]>) => void;
}

export const GUEST_REGISTRY_INITIAL_STATE: ProviderProps = {
  type: '',
  productId: undefined,
  guest: null,
  order: null,
  quantity: 0,
  shippingAddress: null,
  registry: [],
  orders: [],
  updateDataProvider: () => {},
  updateOrdersAndRegistry: () => {},
  updateOrderDataFromOrderId: () => {}
};

interface GuestRegistryProviderProps {
  eventId?: string;
}
export const GuestRegistryProvider: React.FC<GuestRegistryProviderProps> = ({ eventId, children }) => {
  const [state, setState] = useState<StateProps>(GUEST_REGISTRY_INITIAL_STATE);
  const { Provider } = GuestRegistryContext;

  const updateDataProvider = (newState: Partial<DataProps>) => {
    setState(prevState => ({
      ...prevState,
      ...newState
    }));
  };

  const updateOrdersAndRegistry = (newOrders: OrderList, newRegistry: RegistryList) => {
    setState(prevState => ({
      ...prevState,
      orders: newOrders,
      registry: newRegistry
    }));
  };

  const updateOrderDataFromOrderId = (orderId: string, productId: string, products: Readonly<CookedProduct[]>) => {
    const order = state.orders?.find(order => order.id === orderId);
    const quantity =
      order?.lineItems.find(lineItem => {
        return lineItem.quantity;
      })?.quantity || 1;
    const product = products.find(product => product.id === productId);
    const isDonationFundItem = isCustomDonationFundItem(product);
    updateDataProvider({
      ...state,
      type: isDonationFundItem ? 'initial' : 'purchased',
      productId: productId,
      quantity: Math.max(quantity ? quantity / 100 : 0, 0),
      order: {
        orderId
      },
      guest: {
        name: '',
        email: ''
      }
    });
  };

  return (
    <Provider
      value={{
        type: state.type,
        productId: state.productId,
        guest: state.guest,
        order: state.order,
        quantity: state.quantity,
        orders: state.orders,
        registry: state.registry,
        updateDataProvider,
        updateOrdersAndRegistry,
        updateOrderDataFromOrderId,
        shippingAddress: state.shippingAddress
      }}
    >
      {children}
    </Provider>
  );
};
