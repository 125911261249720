import React from 'react';
import { Flex, Stack } from '@withjoy/joykit';
import { useRegistryGuestTranslations } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.i18n';
import { StyledHeadText, StyledSuccessGuestButton } from '../Purchase.styles';
import { ReactComponent as CheckListIcon } from '@assets/icons/icon-check-circle.svg';
import { wrapperUndoStyles } from './UndoContent';

interface UndoneContentProps
  extends Readonly<{
    onBackClick: () => void;
    isInModal?: boolean;
  }> {}

export const UndoneContent: React.FC<UndoneContentProps> = ({ onBackClick, isInModal }) => {
  const { getUndoPurchaseTranslations } = useRegistryGuestTranslations();
  const { undoPurchaseConfirmationTitle, undoPurchaseBackText } = getUndoPurchaseTranslations();
  return (
    <Stack __css={wrapperUndoStyles}>
      <Flex alignSelf="center">
        <CheckListIcon />
      </Flex>
      <StyledHeadText textAlign="center">{undoPurchaseConfirmationTitle}</StyledHeadText>
      {!isInModal && (
        <StyledSuccessGuestButton intent="secondary" fill={true} onClick={onBackClick}>
          {undoPurchaseBackText}
        </StyledSuccessGuestButton>
      )}
    </Stack>
  );
};
