import { Box, Flex, styled, StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const GRID_CONTENT_WIDTH = 1024;
const SHOPPING_CART_BUTTON_PADDING = 16;

export const IconWrapper = styled(Box)``;

export const StyledCountContainer = styled(Box)`
  display: grid;
  place-content: center;
`;

export const StyledButtonContainer = styled.button`
  position: fixed;
  top: ${pxToRem(96)};
  z-index: 5;
  right: calc((100vw - ${pxToRem(GRID_CONTENT_WIDTH)}) / 2 - ${pxToRem(SHOPPING_CART_BUTTON_PADDING)});

  @media (max-width: ${pxToRem(GRID_CONTENT_WIDTH + SHOPPING_CART_BUTTON_PADDING)}) {
    right: calc((100vw - ${pxToRem(GRID_CONTENT_WIDTH)}) / 2 + ${pxToRem(SHOPPING_CART_BUTTON_PADDING)});
  }

  &:hover {
    ${IconWrapper} {
      transform: rotate(-9.58deg);
      transition: transform 200ms ease-in-out;
    }
    ${StyledCountContainer} {
      background-color: #5500c1;
    }
  }

  &:focus {
    ${StyledCountContainer} {
      background-color: #3b0086;
    }
  }
`;

export const StyledButtonWrapper = styled(Flex)``;

const buttonWrapperStyles: StyleSystemProps = {
  height: pxToRem(56),
  padding: 5,
  borderRadius: pxToRem(32),
  border: '1px solid',
  borderColor: 'mono3',
  columnGap: 4,
  alignItems: 'center',
  backgroundColor: 'white',

  _hover: {
    boxShadow: ['0px 4px 12px -2px rgba(44, 41, 37, 0.15)', '0px 3px 7px -3px rgba(0, 0, 0, 0.2)']
  },
  _pressed: {
    boxShadow: ['0px 1px 5px -1px rgba(44, 41, 37, 0.25)', '0px 1px 3px -1px rgba(0, 0, 0, 0.3)']
  }
};

const countContainerStyles: StyleSystemProps = {
  height: pxToRem(24),
  paddingX: 4,
  backgroundColor: '#7000FF',
  color: 'white',
  borderRadius: pxToRem(12)
};

export const styles = {
  buttonWrapperStyles: buttonWrapperStyles,
  countContainerStyles: countContainerStyles
};
