import React, { FC } from 'react';
import { Box, DialogV2, OnCloseArg } from '@withjoy/joykit';

type ModalBaseProps = {
  onCloseModal: (args: OnCloseArg) => void;
  isOpen: boolean;
};

export const ModalBase: FC<ModalBaseProps> = ({ onCloseModal, isOpen, children }) => (
  <DialogV2 onClose={onCloseModal} isOpen={isOpen} enableDividers>
    <DialogV2.CloseButton />
    <DialogV2.Body paddingX={[24, null, 80]} paddingY={40}>
      <Box>{children}</Box>
    </DialogV2.Body>
  </DialogV2>
);
