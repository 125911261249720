import React from 'react';
import { Flex, Box } from '@withjoy/joykit';
import { StyledColumnsContribution, StyledCompletePurchaseImage } from './../Purchase.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { ContributionConfirmation } from './PurchaseConfirmationModal/ContributionConfirmation';

interface ThanksProps
  extends Readonly<{
    reservationState: string;
    isDonationFund: boolean;
    showUndoConfirmationScreen: () => void;
    productImage: string;
    handleUndo: (methodText: string) => void;
    handleCancelUndo: () => void;
    onBackClick: () => void;
  }> {}

export const Thanks: React.FC<ThanksProps> = ({ onBackClick, reservationState, isDonationFund, showUndoConfirmationScreen, productImage }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  const renderLeftPane = () => {
    if (isDonationFund) {
      return (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={'mono1'}>
          <Box width={['100%', null, '100%']} height={['254px', null, '100%']}>
            <StyledCompletePurchaseImage backgroundSize="cover" productImage={productImage} />
          </Box>
        </Flex>
      );
    } else {
      return (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" backgroundColor={'mono1'} padding={[7, null, 3]}>
          <Box width={['205px', null, '290px']} height={['205px', null, '290px']}>
            <StyledCompletePurchaseImage productImage={productImage} />
          </Box>
        </Flex>
      );
    }
  };

  return (
    <StyledColumnsContribution isMobile={isMobile} isInModal={false}>
      {renderLeftPane()}
      <Flex alignItems="center" justifyContent="center" flexDirection="column" padding={[6, null, '0px 48px']}>
        {reservationState === 'purchased' && <ContributionConfirmation onBackClick={onBackClick} handleUndoClick={showUndoConfirmationScreen} isDonationFund={isDonationFund} />}
      </Flex>
    </StyledColumnsContribution>
  );
};
