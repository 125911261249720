import { EventType } from '@graphql/generated';
import { Colors } from '@withjoy/joykit/theme';
import { EGiftWrapType, IGiftWrapTheme } from '../GiftWrapTypes';

const weddingThemes: IGiftWrapTheme[] = [
  {
    id: 'best_wishes',
    gif: 'https://withjoy.com/media/raw/gift-wrap/best-wishes.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/best-wishes.gif?h=144',
    background: '#F9EEE9',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/best-wishes_accent.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'cheers',
    gif: 'https://withjoy.com/media/raw/gift-wrap/cheers_700_v2.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/cheers.gif?h=144',
    background: 'rgb(254, 214, 195)',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/cheers_accent.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC,
    allowAsFirst: true
  },
  {
    id: 'happily_ever',
    gif: 'https://withjoy.com/media/raw/gift-wrap/happily-ever-after_700_v2.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/ever-after.gif?h=144',
    background: 'rgb(37, 144, 215)',
    text: 'brandWarmGray1' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/ever-after_accent.gif',
    giftBrand: 'mono4',
    type: EGiftWrapType.BASIC,
    giftTitle: 'white'
  },
  {
    id: 'lovebirds',
    gif: 'https://withjoy.com/media/raw/gift-wrap/lovebirds-card_700_v2.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/lovebirds.gif?h=144',
    background: 'rgb(236, 232, 218)',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/lovebirds_accent.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'happy_couple',
    gif: 'https://withjoy.com/media/raw/gift-wrap/gift.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/gift.gif?h=144',
    background: '#F9F5EB',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/gift_accent.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'love_bloom',
    gif: 'https://withjoy.com/media/raw/ecard-congrats/love-bloom.gif',
    thumbnail: 'https://withjoy.com/media/raw/ecard-congrats/love-bloom_thumb.jpg',
    background: 'rgb(248, 234, 226)',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/ecard-congrats/love-bloom-footer_SM_v2.gif',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'gettin_hitched',
    gif: 'https://withjoy.com/media/raw/ecard-congrats/gettin-hitched_700_v2.gif',
    thumbnail: 'https://withjoy.com/media/raw/ecard-congrats/gettin-hitched_thumb.jpg',
    background: 'rgb(255, 182, 183)',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/ecard-congrats/gettin-hitched_footer_SM.gif',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'eucalyptus_wreath',
    gif: 'https://withjoy.com/media/raw/ecard-congrats/eucalyptus-wreath.gif',
    thumbnail: 'https://withjoy.com/media/raw/ecard-congrats/eucalyptus-wreath_thumb.jpg',
    background: '#E7E5DF',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/ecard-congrats/eucalyptus_wreath_footer_SM.gif',
    type: EGiftWrapType.BASIC,
    allowAsFirst: true
  },
  {
    id: 'love_sweet_love',
    gif: 'https://withjoy.com/media/raw/ecard-congrats/love-sweet-love_700_v2.gif',
    thumbnail: 'https://withjoy.com/media/raw/ecard-congrats/love-sweet-love_thumb.jpg',
    background: 'rgb(64, 147, 194)',
    text: 'white' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/ecard-congrats/love-sweet-love_footer_SM.gif',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'happy_wedding_day',
    gif: 'https://withjoy.com/media/raw/ecard-congrats/happy-wedding-day_700_v2.gif',
    thumbnail: 'https://withjoy.com/media/raw/ecard-congrats/happy-wedding-day_thumb.jpg',
    background: 'rgb(21, 24, 38)',
    text: 'white' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/ecard-congrats/happy-wedding-day_footer_SM.gif',
    type: EGiftWrapType.BASIC,
    allowAsFirst: true
  },
  {
    id: 'getaway_car',
    gif: 'https://withjoy.com/media/raw/gift-wrap/getaway-car_thumb.jpg',
    thumbnail: 'https://withjoy.com/media/raw/gift-wrap/getaway-car_thumb.jpg?h=144',
    background: 'white',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/getaway-car-footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.PREMIUM,
    allowAsFirst: true
  },
  {
    id: 'growing_florals',
    gif: 'https://withjoy.com/media/raw/gift-wrap/growing-florals_thumb.jpg',
    thumbnail: 'https://withjoy.com/media/raw/gift-wrap/growing-florals_thumb.jpg?h=144',
    background: 'white',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/growing-florals-footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.PREMIUM,
    allowAsFirst: true
  },
  {
    id: 'beach',
    gif: 'https://withjoy.com/media/raw/gift-wrap/beach_thumb.jpg',
    thumbnail: 'https://withjoy.com/media/raw/gift-wrap/beach_thumb.jpg?h=144',
    background: 'white',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/beach-footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.PREMIUM,
    allowAsFirst: true
  }
];

const babyThemes: IGiftWrapTheme[] = [
  {
    id: 'welcome-baby',
    gif: 'https://withjoy.com/media/raw/gift-wrap/welcome-baby-mobile_v2.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/welcome-baby-mobile_thumb.jpg?h=144',
    background: 'rgb(245, 246, 248)',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/welcome-baby-mobile-accent-footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC,
    allowAsFirst: true
  },
  {
    id: 'woodland',
    gif: 'https://withjoy.com/media/raw/gift-wrap/woodland-animals_v2.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/woodland-animals_thumb.jpg?h=144',
    background: 'rgb(247, 247, 239)',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/animals-accent_footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC,
    allowAsFirst: true
  },
  {
    id: 'stroller',
    gif: 'https://withjoy.com/media/raw/gift-wrap/stroller.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/stroller_thumb.jpg?h=144',
    background: '#C6DBC3',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/stroller-accent_footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'oh-baby',
    gif: 'https://withjoy.com/media/raw/gift-wrap/oh-baby.gif',
    thumbnail: 'https://withjoy.com/media/gift-wrap/oh-baby_thumb.jpg?h=144',
    background: '#F5F6F8',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/oh-baby-accent_footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC
  },
  {
    id: 'stork',
    gif: 'https://withjoy.com/media/raw/gift-wrap/stork.gif',
    thumbnail: 'https://withjoy.com/media/raw/gift-wrap/stork_thumb.jpg?h=144',
    background: '#B4C9C0',
    text: 'brandWarmGray6' as keyof Colors,
    accent: 'https://withjoy.com/media/raw/gift-wrap/stork-accent_footer_SM.gif',
    giftBrand: 'mono12',
    giftTitle: 'mono14',
    type: EGiftWrapType.BASIC
  }
];

export const getGiftWrapThemeListByEventType = (eventType: EventType | undefined): IGiftWrapTheme[] | undefined => {
  switch (eventType) {
    case EventType.babyRegistry:
      return babyThemes;
    case EventType.wedding:
      return weddingThemes;
    default:
      return undefined;
  }
};

export const getGiftWrapThemeByID = (themeID: string): IGiftWrapTheme | undefined => {
  let theme = weddingThemes.find(theme => theme.id === themeID);

  if (!theme) {
    theme = babyThemes.find(theme => theme.id === themeID);
  }
  return theme;
};
