import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@withjoy/joykit';
import { createContext } from '@shared/utils/createContext';
import { CheckoutDialog } from './CheckoutDialog';
import { CheckoutDialogProviderProps } from './CheckoutDialog.types';
import { GuestRegistryState } from '../../GuestRegistry.controller';
import { useAuth } from '@shared/components/AuthProvider';
import { UserFragment } from '@graphql/generated';
import { CheckoutServiceFacade } from '@apps/registry/guest/components/CheckoutDialog/machines';
import { ToastVariableProps } from '../../routes/GuestRegistry/GuestRegistry.controller';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useGuestRegistryTelemetry } from '../../GuestRegistry.telemetry';
import { useGiftWrapECardPrice } from '@shared/components';

type OpenCheckoutDialogArgs = {
  registryItemId: string;
  currentOrderId?: string;
  isAffiliate?: boolean;
  isGiftWrapPurchased?: boolean;

  // Group Gifting
  isGroupGiftingEnabled?: boolean;
  donationAmount?: number;
};

interface CheckoutDialogContext {
  registryItemId: Maybe<string>;
  currentOrderId: Maybe<string>;
  isAffiliate: boolean;
  isGroupGifting: boolean;
  registryState?: GuestRegistryState;
  profile: Maybe<UserFragment>;
  mySessionEmail: Maybe<string>;
  openCheckoutDialog: (args: OpenCheckoutDialogArgs) => void;
  disableCloseOnOutsideClick: boolean;
  setCurrentStep: (step: CheckoutServiceFacade['route']) => void;
  openToast?: (toast: ToastVariableProps) => void;
}

const [Provider, useCheckoutDialogContext] = createContext<CheckoutDialogContext>({ name: 'CheckoutDialog' });

const CheckoutDialogProvider = ({ children, openToast, ...dialogProps }: CheckoutDialogProviderProps) => {
  const { ecardPurchaseConfirmationViewed } = useGuestRegistryTelemetry();
  const { getValueString } = useQueryParamHelper();
  const eCardPrice = useGiftWrapECardPrice();
  const [registryItemId, setRegistryItemId] = useState<string | undefined>('');
  const [currentOrderId, setCurrentOrderId] = useState<string | undefined>('');
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [isGiftWrapPurchased, setIsGiftWrapPurchased] = useState(false);
  const [isGroupGifting, setIsGroupGifting] = useState(false);
  const [currentStep, setCurrentStep] = useState<CheckoutServiceFacade['route']>('setup');
  const [donationAmount, setDonationAmount] = useState<number>(0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    currentUser: { profile }
  } = useAuth();

  const { registryState, eventId } = dialogProps;
  const standaloneRegistriesList = useMemo(() => {
    return registryState?.registry?.filter(registry => registry.id === registryState?.eventId)[0];
  }, [registryState?.registry, registryState?.eventId]);

  const mySessionEmail = standaloneRegistriesList?.mySession?.email;

  const handleOnOpenCheckoutDialog = useCallback<CheckoutDialogContext['openCheckoutDialog']>(
    ({ registryItemId, currentOrderId, isAffiliate, isGroupGiftingEnabled, donationAmount, isGiftWrapPurchased }) => {
      setRegistryItemId(registryItemId);
      setCurrentOrderId(currentOrderId);
      setIsAffiliate(!!isAffiliate);
      setIsGiftWrapPurchased(!!isGiftWrapPurchased);
      setIsGroupGifting(!!isGroupGiftingEnabled);
      setDonationAmount(donationAmount || 0);
      onOpen();
    },
    [onOpen]
  );

  useEffect(() => {
    const pcId = getValueString('pcId');
    const orderId = getValueString('order_id');
    const itemId = getValueString('item_id');
    const itemName = getValueString('item_name');
    const itemPrice = getValueString('item_price');
    const isAffiliate = getValueString('is_affiliate') === 'true';

    if (orderId && itemId && !!registryState?.registry) {
      handleOnOpenCheckoutDialog({
        isAffiliate: isAffiliate,
        isGiftWrapPurchased: true,
        currentOrderId: orderId,
        registryItemId: itemId
      });
      ecardPurchaseConfirmationViewed({
        label: isAffiliate ? 'affiliate' : 'cash',
        pcId: pcId!,
        giftName: itemName || '',
        price: parseFloat(itemPrice || ''),
        eCardPrice
      });
    }
  }, [onOpen, handleOnOpenCheckoutDialog, registryState?.registry, getValueString, ecardPurchaseConfirmationViewed, eCardPrice]);

  const disableCloseOnOutsideClick = ['paperCheckoutConfirmAndNotify', 'externalCheckoutConfirmAndNotify'].includes(currentStep ?? 'setup');

  const ctx = useMemo<CheckoutDialogContext>(
    () => ({
      registryState,
      registryItemId,
      currentOrderId,
      profile,
      mySessionEmail,
      isAffiliate,
      isGroupGifting,
      openCheckoutDialog: handleOnOpenCheckoutDialog,
      disableCloseOnOutsideClick,
      setCurrentStep,
      openToast
    }),
    [currentOrderId, disableCloseOnOutsideClick, handleOnOpenCheckoutDialog, isAffiliate, isGroupGifting, mySessionEmail, profile, registryItemId, registryState, openToast]
  );

  return (
    <Provider value={ctx}>
      {children}

      <CheckoutDialog
        eventId={eventId || ''}
        isOpen={isOpen && !!registryItemId}
        onClose={onClose}
        registryItemId={registryItemId}
        currentOrderId={currentOrderId}
        isAffiliate={isAffiliate}
        isGiftWrapPurchased={isGiftWrapPurchased}
        isGroupGifting={isGroupGifting}
        donationAmount={donationAmount}
        {...dialogProps}
      />
    </Provider>
  );
};

export { CheckoutDialogProvider, useCheckoutDialogContext };
