import { styled, StyleSystemProps, Box, TextV2, Flex, ButtonV2 } from '@withjoy/joykit';
import { ButtonVariant } from '@withjoy/joykit/components/ButtonV2/Button.types';

export const StyledGiftNoteWrapper = styled(Box)``;
const giftNoteWrapperStyles: StyleSystemProps = {
  margin: 5,
  textAlign: 'center'
};

export const StyledGiftNoteCard = styled(Box)``;
const giftNoteCardStyles: StyleSystemProps = {
  background: 'linear-gradient(180deg, #FAFAFA 0%, #FDFBF9 100%)',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.03), 0px 10px 60px rgba(44, 41, 37, 0.03), 0px 9px 36px rgba(0, 0, 0, 0.04)',
  borderRadius: 1,
  padding: 6,
  marginBottom: 4
};

export const StyledInputError = styled(TextV2)``;
const inputErrorStyles: StyleSystemProps = {
  color: 'red6',
  minHeight: '22px'
};

export const StyledEditNoteWrapper = styled(Flex)``;
const editNoteWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  marginBottom: 4
};

export const StyledEditButton = styled(ButtonV2)``;
export const StyledAddNoteButton = styled(ButtonV2)<{ variant?: ButtonVariant }>`
  width: 100%;
  svg {
    path {
      fill: ${props => (props.variant === 'outline' ? 'initial' : props.theme.colors.white)};
    }
  }
`;

export const styles = {
  giftNoteWrapper: giftNoteWrapperStyles,
  giftNoteCard: giftNoteCardStyles,
  inputError: inputErrorStyles,
  editNoteWrapper: editNoteWrapperStyles
};
