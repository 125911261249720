import { ButtonV1, styled } from '@withjoy/joykit';

export const StyledSuccessGuestButton = styled(ButtonV1)<{ intent?: 'primary' | 'secondary'; color?: 'string' }>`
  border-color: ${props => (props.intent !== 'secondary' && props.color ? props.color : undefined)};
  max-width: 394px;
  ${({ intent, theme }) =>
    intent !== 'secondary' ? `background-color: ${theme.colors.green7};` : `background-color: transparent; color: ${theme.colors.green7}; border-color: ${theme.colors.green7};`}

  &:hover {
    ${({ intent, theme }) =>
      intent !== 'secondary'
        ? `background-color: ${theme.colors.green6} !important;`
        : `background-color: transparent !important; color: ${theme.colors.green6} !important; border-color: ${theme.colors.green6} !important;`}
  }
  &:active {
    ${({ intent, theme }) =>
      intent !== 'secondary'
        ? `background-color: ${theme.colors.green8} !important;`
        : `background-color: transparent !important; color: ${theme.colors.green8} !important; border-color: ${theme.colors.green8}!important;`}
  }
`;
