import { Box, ButtonV2, DialogV2, OnCloseArg, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { ReactComponent as ShippingIcon } from '@assets/icons/shipping.svg';
import { RegistryItemFragment } from '@graphql/generated';
import { ShippingAddressFragment } from '@graphql/aliases';
import {
  StyledNote,
  blockStyles,
  boxIconWithNoteStyles,
  StyledBoxIconWithNote,
  titleStyled,
  boxWidthStyled
} from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/components/ConfirmationModalContent.styles';
import { ShippingAddressQuickView } from '@apps/registry/guest/components/ShippingAddressQuickView/ShippingAddressQuickView';

type PurchaseConfirmationModalContentProps = {
  onClose: (args: OnCloseArg) => void;
  item?: RegistryItemFragment;
  handleConfirm: () => void;
  shippingAddress: ShippingAddressFragment | null;
  retailer: string;
};

export const AddressConfirmationModalContent = ({ retailer, item, onClose, shippingAddress, handleConfirm }: PurchaseConfirmationModalContentProps) => {
  const { t } = useTranslation('guestRegistry');

  return (
    <Box position="relative">
      <DialogV2.Header>
        <Box>
          <DialogV2.CloseButton color="black" />
        </Box>
        <Box __css={boxWidthStyled}>
          <TextV2 __css={titleStyled} typographyVariant="hed5" paddingTop="8">
            {t('confirmationPurchaseModal', 'title')({ retailer })}
          </TextV2>
        </Box>
      </DialogV2.Header>
      <DialogV2.Body minHeight={300}>
        <Box __css={{ ...blockStyles, ...boxWidthStyled }}>
          <StyledBoxIconWithNote __css={boxIconWithNoteStyles}>
            <Box>
              <ShippingIcon />
            </Box>
            <StyledNote typographyVariant="hed3" textAlign="left">
              {t('confirmationPurchaseModal', 'addressNote')()}
            </StyledNote>
          </StyledBoxIconWithNote>
          <ShippingAddressQuickView shippingAddress={shippingAddress} showBox={false} />
        </Box>
      </DialogV2.Body>
      <DialogV2.Footer minHeight={122}>
        <Box __css={boxWidthStyled} width="100%">
          <ButtonV2 intent="productive" onClick={handleConfirm} width="100%">
            {t('confirmationPurchaseModal', 'buttonGotIt')({ retailer })}
          </ButtonV2>
        </Box>
      </DialogV2.Footer>
    </Box>
  );
};
