import React from 'react';
import { Flex, IconV2, TextV2, ButtonV2, DialogV2 } from '@withjoy/joykit';
import { Close } from '@withjoy/joykit/icons';
import { PageNote } from '@apps/registry/common/components';
import { SpacingStack } from '@withjoy/joykit';
import { StyledText } from './PageNoteDialog.styles';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useSharedRegistryTranslations } from '@apps/registry/SharedRegistry.i18n';
import { useGetRegistryPageIdQuery } from '@graphql/generated';

export interface PageNoteDialogProps {
  pageNote?: string | null;
  isAdmin: boolean;
  eventHandle: string;
  isOpen: boolean;
  onClose: () => void;
  onEditPageNote?: () => void;
}

const DialogInternal = (props: PageNoteDialogProps) => {
  const { onClose, pageNote, isAdmin, eventHandle, onEditPageNote } = props;
  const { data } = useGetRegistryPageIdQuery({ variables: { eventHandle }, batchMode: 'fast' });
  const registryPageId = data?.eventByName?.registryPage?.[0]?.id;
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { getPageNoteTranslations } = useSharedRegistryTranslations();
  const { title } = getPageNoteTranslations();

  return (
    <DialogV2.Body>
      <SpacingStack spacing={[32]} padding="16px 8px">
        <Flex alignItems="center" justifyContent={isAdmin ? 'space-between' : 'flex-end'}>
          {isAdmin && (
            <TextV2 typographyVariant="hed6" fontSize={24}>
              {title}
            </TextV2>
          )}
          <ButtonV2 onClick={onClose} variant="ghost" size="sm" padding={0}>
            <IconV2 color={'mono14'} width={'32px !important'} height={'32px !important'}>
              <Close />
            </IconV2>
          </ButtonV2>
        </Flex>
        {isAdmin ? (
          <PageNote
            onEditPageNote={onEditPageNote}
            eventHandle={eventHandle}
            onClose={onClose}
            pageNote={pageNote}
            inDialog={true}
            isMobile={isMobile}
            registryPageId={registryPageId}
          />
        ) : (
          <StyledText typographyVariant="body1">{pageNote}</StyledText>
        )}
      </SpacingStack>
    </DialogV2.Body>
  );
};

export const PageNoteDialog: React.FC<PageNoteDialogProps> = (props: PageNoteDialogProps) => {
  const { isOpen, onClose } = props;

  return (
    <DialogV2 id="PageNoteDialog" onClose={onClose} isOpen={isOpen}>
      <DialogInternal {...props} />
    </DialogV2>
  );
};
