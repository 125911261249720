import { useCallback, useMemo, useState } from 'react';

export function useWidthObserver() {
  const [width, setWidth] = useState(0);

  const resizeObserver = useMemo(
    () =>
      // Ignoring compat ResizeObserver seems sufficiently supported.
      // eslint-disable-next-line compat/compat
      new ResizeObserver(entries => {
        const width = entries[0]?.contentRect.width;
        if (!width) return;
        setWidth(entries[0].contentRect.width);
      }),
    []
  );

  const elementRef = useCallback<(instance: HTMLElement | null) => void>(
    instance => {
      if (instance) {
        resizeObserver.observe(instance);
      } else {
        resizeObserver.disconnect();
      }
    },
    [resizeObserver]
  );
  return [width, elementRef] as const;
}
