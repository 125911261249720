import { GiftWrapPremiumDetails, IGiftWrapTheme } from '@shared/components';
import { styled } from '@withjoy/joykit';
import React, { useEffect, useRef, useState } from 'react';
import { PremiumCardTemplate } from './PremiumCardTemplate';
import { useLoadGoogleFont } from './useLoadGoogleFont';
import { useSceneTimer } from './useSceneTimer';

const Video = styled.video`
  width: 100%;
`;

const CenterCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    position: static !important;
  }

  & > * + * {
    margin-top: 40px;
  }

  transform: translate(0, -20px);
  @keyframes SlideFadeUp {
    from {
      transform: translate(0, 20px);
      opacity: 1;
    }
    to {
      transform: translate(0, 0px);
      opacity: 1;
    }
  }
  animation: SlideFadeUp 3s;

  opacity: 0;
  transform: translate(0, 60px);
  filter: blur(60px);
  &.t1,
  &.t2 {
    opacity: 1;
    transform: translate(0, 0);
    filter: blur(0);
    transition: all 3.5s ease-out;
  }
`;

const Text = styled.div`
  color: #70473a;
  font-size: 120px;
  font-family: 'Cormorant Garamond';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 12px;
  word-wrap: break-word;
`;
const Names120Measurer = styled(Text)`
  visibility: hidden;
  font-weight: 400;
  letter-spacing: 12px;
`;
const Names120 = styled(Text)`
  font-size: 120px;
  font-weight: 400;
  letter-spacing: 12px; // Redundant?
`;
const Names60 = styled(Names120)`
  font-size: 60px;
  letter-spacing: 6px;
`;
const Secondary = styled(Text)`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 2.8px;
`;
const Heart = styled.img`
  width: 26px;
  height: 32px;
`;

export const FloralCard: React.FC<{ theme: IGiftWrapTheme; details: GiftWrapPremiumDetails }> = ({ details, theme }) => {
  const isFontLoaded = useLoadGoogleFont('Cormorant Garamond');

  const nameMeasurer1 = useRef<HTMLDivElement>(null);
  const nameMeasurer2 = useRef<HTMLDivElement>(null);
  const [largeText, setLargeText] = useState(true);
  useEffect(() => {
    if (!nameMeasurer1.current) return;
    if (!nameMeasurer2.current) return;
    setLargeText(nameMeasurer1.current.offsetWidth <= 700 && nameMeasurer2.current.offsetWidth <= 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameMeasurer1.current, nameMeasurer2.current, isFontLoaded]);

  const [scene, restart] = useSceneTimer(3584);

  const videoA = useRef<HTMLVideoElement>(null);
  const videoB = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoA.current) return;
    if (!videoB.current) return;
    if (scene === 1) {
      videoA.current.style.visibility = 'visible';
      videoA.current.play();
      videoB.current.style.visibility = 'hidden';
      videoB.current.pause();
      videoB.current.currentTime = 0;
    }
    if (scene === 2) {
      videoA.current.style.visibility = 'hidden';
      videoA.current.pause();
      videoA.current.currentTime = 0;
      videoB.current.style.visibility = 'visible';
      videoB.current.play();
    }
  }, [scene]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => restart(), []);

  const Names = largeText ? Names120 : Names60;

  return (
    <PremiumCardTemplate cardId="floral" details={details} theme={theme} showRestartDelay={6000} restart={restart}>
      <div style={{ width: '100%', height: '100%', background: 'hsl(35, 24%, 93%)' }}></div>
      <Video muted playsInline className={`t${scene}`} ref={videoA} style={{ visibility: 'visible' }}>
        <source src="https://withjoy.blob.core.windows.net/card/floral_bg-a.mp4" />
      </Video>
      <Video muted playsInline loop className={`t${scene}`} ref={videoB} style={{ visibility: 'hidden' }}>
        <source src="https://withjoy.blob.core.windows.net/card/floral_bg-b.mp4" />
      </Video>
      <Names120Measurer ref={nameMeasurer1}>{details.name1}</Names120Measurer>
      <Names120Measurer ref={nameMeasurer2}>{details.name2}</Names120Measurer>
      <CenterCenter className={`t${scene}`}>
        <img alt="congratulations" src="https://withjoy.blob.core.windows.net/card/floral_congratulations.png" />
        <Secondary>On your wedding day</Secondary>
        <Names>{details.name1}</Names>
        <img alt="and" src="https://withjoy.blob.core.windows.net/card/floral_and.png" />
        <Names>{details.name2}</Names>
        <Heart src="https://withjoy.blob.core.windows.net/card/floral_heart.svg" />
      </CenterCenter>
    </PremiumCardTemplate>
  );
};
