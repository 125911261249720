import { DonationFundPaymentMethodEnum, DonationFundPlatformTypeEnum } from '@graphql/generated';

export const MAPPING: { [key in DonationFundPlatformTypeEnum]: DonationFundPaymentMethodEnum } = {
  [DonationFundPlatformTypeEnum.venmo]: DonationFundPaymentMethodEnum.venmo,
  [DonationFundPlatformTypeEnum.paypal]: DonationFundPaymentMethodEnum.paypal,
  [DonationFundPlatformTypeEnum.cashapp]: DonationFundPaymentMethodEnum.cashapp,
  [DonationFundPlatformTypeEnum.cashOrCheck]: DonationFundPaymentMethodEnum.cashOrCheck,
  [DonationFundPlatformTypeEnum.other]: DonationFundPaymentMethodEnum.other
};

export const getDonationPaymentMethodFromPlatformType = (value?: DonationFundPlatformTypeEnum | null): DonationFundPaymentMethodEnum | null => {
  if (!value) {
    return null;
  }
  return MAPPING[value];
};
