/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Reset',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <g clip-path="url(#clip0_52539_26631)">
            <path
              d="M3.40381 12.5962C4.37179 13.5642 5.62067 14.2018 6.97239 14.4183C8.32411 14.6347 9.70965 14.4188 10.9315 13.8014C12.1533 13.184 13.149 12.1967 13.7766 10.9801C14.4042 9.76351 14.6317 8.37983 14.4267 7.02634C14.2216 5.67285 13.5945 4.41866 12.6346 3.44258C11.6748 2.4665 10.4313 1.81837 9.08148 1.5906C7.73163 1.36284 6.34433 1.56706 5.11737 2.17416C3.89042 2.78125 2.88646 3.76023 2.24864 4.97149"
              stroke="currentColor"
              strokeWidth="1.1"
              strokeLinecap="round"
            />
            <path d="M1.59961 1.5V5.09333C1.59961 5.31793 1.78168 5.5 2.00628 5.5H5.59961" stroke="currentColor" strokeWidth="1.1" strokeLinecap="round" />
            <path d="M8 5V7.63756C8 7.86963 8.09219 8.09219 8.25628 8.25628L9.5 9.5" stroke="currentColor" strokeWidth="1.1" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_52539_26631">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <g clip-path="url(#clip0_52539_26629)">
            <path
              d="M5.88604 18.6765C7.21571 20.0061 8.92847 20.8859 10.7838 21.1923C12.6391 21.4986 14.5438 21.2161 16.2303 20.3845C17.9168 19.5529 19.3005 18.2139 20.1871 16.5555C21.0736 14.8972 21.4184 13.0028 21.1731 11.1384C20.9278 9.27405 20.1047 7.53335 18.8193 6.16077C17.534 4.78819 15.851 3.85268 14.0068 3.48563C12.1625 3.11857 10.2496 3.3384 8.53669 4.11425C6.82377 4.8901 5.39689 6.18299 4.45643 7.81136"
              stroke="currentColor"
              strokeWidth="1.2"
              strokeLinecap="round"
            />
            <path d="M3.80078 3.3125V7.90583C3.80078 8.13043 3.98285 8.3125 4.20745 8.3125H8.80078" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M12 7.3125V12.3125L14.5 14.8125" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_52539_26629">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <g clip-path="url(#clip0_52539_26628)">
            <path
              d="M7.86827 24.1942C9.55706 25.883 11.7298 27.0041 14.0847 27.4019C16.4397 27.7997 18.86 27.4543 21.0098 26.4139C23.1596 25.3735 24.9321 23.6895 26.0813 21.5959C27.2304 19.5022 27.6992 17.1027 27.4226 14.7304C27.146 12.3582 26.1376 10.131 24.5375 8.35789C22.9374 6.58483 20.825 5.35393 18.4935 4.83608C16.162 4.31823 13.7271 4.53913 11.5269 5.4681C9.32665 6.39707 7.47023 7.98803 6.21535 10.0201"
              stroke="currentColor"
              strokeWidth="1.2"
              strokeLinecap="round"
            />
            <path d="M5.5 4.5625V10.1558C5.5 10.3804 5.68207 10.5625 5.90667 10.5625H11.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
            <path d="M16 9.0625V16.0625L19.5 19.5625" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_52539_26628">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <g clip-path="url(#clip0_52539_26630)">
            <path
              d="M10.565 37.435C13.3347 40.2048 16.8928 42.0508 20.7521 42.7203C24.6115 43.3899 28.5834 42.8503 32.1243 41.1755C35.6651 39.5006 38.6018 36.7723 40.5323 33.364C42.4627 29.9558 43.2926 26.0342 42.9084 22.1361C42.5241 18.238 40.9444 14.5539 38.3856 11.5882C35.8269 8.62247 32.414 6.52008 28.6143 5.56882C24.8145 4.61755 20.8137 4.8639 17.1594 6.27416C13.505 7.68441 10.3759 10.1896 8.20028 13.4469"
              stroke="currentColor"
              strokeWidth="1.25"
              strokeLinecap="round"
            />
            <path d="M7.5 5V13.5933C7.5 13.8179 7.68207 14 7.90667 14H16.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M24 11.5V24.5L30.5 31" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_52539_26630">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <g clip-path="url(#clip0_52539_26627)">
            <path
              d="M14.3223 49.6777C17.974 53.3294 22.667 55.7606 27.7563 56.6372C32.8456 57.5138 38.0815 56.7928 42.7445 54.5733C47.4075 52.3538 51.2687 48.7447 53.7975 44.242C56.3264 39.7393 57.3987 34.5639 56.8672 29.4271C56.3358 24.2902 54.2265 19.444 50.8293 15.5544C47.4322 11.6649 42.9138 8.92277 37.8952 7.705C32.8765 6.48723 27.6039 6.85354 22.8019 8.7536C17.9999 10.6537 13.9041 13.9942 11.0774 18.3162"
              stroke="currentColor"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
            <path d="M10 7V18.5933C10 18.8179 10.1821 19 10.4067 19H22" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
            <path d="M32 15V32L40.5 40.5" stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" />
          </g>
          <defs>
            <clipPath id="clip0_52539_26627">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
