export type AddressDialogStep = 1 | 2;
export enum AddressUsedEnum {
  CUSTOM = 'custom',
  SUGGESTED_ORIGINAL = 'suggestedOriginal',
  SUGGESTED_MODIFIED = 'suggestedModified'
}

export interface AddressDialogFields
  extends Readonly<{
    name: string;
    email?: string;
    phone?: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    countryCode?: string;
    validated: boolean;
  }> {}
