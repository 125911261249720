import { CookedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { useStoredGiftReservationFieldsContext } from '../components/StoredGiftReservationFieldsProvider';
import { useEffect, useState } from 'react';

type UseManagedGiftReservationArgs = {
  product: Maybe<CookedProduct>;
};
export const useManagedGiftReservation = (args: UseManagedGiftReservationArgs) => {
  const { product } = args;
  const context = useStoredGiftReservationFieldsContext();
  const [recentGiftStatus, setRecentGiftStatus] = useState<{ alreadyReserved: boolean; usedEmail: string } | null>(null);

  useEffect(() => {
    return () => {
      // Reset because product has changed
      setRecentGiftStatus(null);
    };
  }, [product]);

  return { recentGiftStatus, setRecentGiftStatus, ...context };
};
