import React from 'react';
import { MinusIcon, PlusIcon } from '@assets/icons';
import { IconWrapper, StyledQuantityStepperInput, StyledQuantityToggleContainer, styles } from './QuantityStepper.styles';
import { DEFAULT_CURRENCY } from '@shared/utils/currency/constants';
import { useCurrencyFormatter } from '@shared/utils/currency';

type QuantityStepperProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any> | Promise<void>;
  qty: number;
  minValue: number;
  step?: number;
  hasError?: boolean;
  currencyCode?: string;
};

export const QuantityStepper = ({ setFieldValue, qty, minValue, step = 10, hasError, currencyCode = DEFAULT_CURRENCY }: QuantityStepperProps) => {
  const onMinusClick = () => {
    if (qty > minValue) {
      if (qty - step > minValue) {
        setFieldValue('qty', qty - step);
      } else {
        setFieldValue('qty', minValue);
      }
    }
  };

  const onPlusClick = () => {
    setFieldValue('qty', qty + step);
  };

  const { formatCurrency } = useCurrencyFormatter();
  const formattedValue = formatCurrency({
    priceFloatingPointDecimalString: qty.toString(),
    priceCurrencyCode: currencyCode,
    formatForm: 'short',
    formatPrecision: 0
  });

  return (
    <StyledQuantityToggleContainer hasError={hasError}>
      <IconWrapper __css={styles.iconWrapperStyles} onClick={onMinusClick} cursor={qty <= minValue ? 'not-allowed' : 'pointer'}>
        <MinusIcon />
      </IconWrapper>
      {/*<PriceWrapper __css={styles.priceWrapperStyles}>{formattedValue}</PriceWrapper>*/}
      <StyledQuantityStepperInput
        as="input"
        value={formattedValue}
        type="string"
        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
        min="1"
        __css={styles.quantityStepperInputStyles}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('qty', Number(e.target.value.replace(/\D/g, '')))}
      ></StyledQuantityStepperInput>
      <IconWrapper onClick={onPlusClick} __css={styles.iconWrapperStyles} cursor="pointer">
        <PlusIcon />
      </IconWrapper>
    </StyledQuantityToggleContainer>
  );
};
