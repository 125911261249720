/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Help',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99996 1.76602C4.56271 1.76602 1.76663 4.5621 1.76663 7.99935C1.76663 11.4366 4.56271 14.2327 7.99996 14.2327C11.4372 14.2327 14.2333 11.4366 14.2333 7.99935C14.2333 4.5621 11.4372 1.76602 7.99996 1.76602ZM0.666626 7.99935C0.666626 3.95459 3.9552 0.666016 7.99996 0.666016C12.0447 0.666016 15.3333 3.95459 15.3333 7.99935C15.3333 12.0441 12.0447 15.3327 7.99996 15.3327C3.9552 15.3327 0.666626 12.0441 0.666626 7.99935Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99996 4.98256C7.19915 4.98256 6.54996 5.63174 6.54996 6.43256C6.54996 6.73631 6.30372 6.98256 5.99996 6.98256C5.6962 6.98256 5.44996 6.73631 5.44996 6.43256C5.44996 5.02423 6.59163 3.88256 7.99996 3.88256C9.40829 3.88256 10.55 5.02423 10.55 6.43256C10.55 7.40472 9.90431 8.01951 9.44252 8.45923C9.42133 8.4794 9.40053 8.49921 9.38019 8.51867C8.86022 9.01619 8.54996 9.35453 8.54996 9.88103C8.54996 10.1848 8.30372 10.431 7.99996 10.431C7.6962 10.431 7.44996 10.1848 7.44996 9.88103C7.44996 8.84245 8.11753 8.2041 8.59623 7.74635C8.60412 7.73881 8.61195 7.73132 8.61972 7.72388C9.15622 7.21055 9.44996 6.90325 9.44996 6.43256C9.44996 5.63174 8.80077 4.98256 7.99996 4.98256Z"
            fill="currentColor"
          />
          <path
            d="M8.54151 11.6494C8.54151 11.9485 8.29905 12.1909 7.99995 12.1909C7.70086 12.1909 7.4584 11.9485 7.4584 11.6494C7.4584 11.3503 7.70086 11.1078 7.99995 11.1078C8.29905 11.1078 8.54151 11.3503 8.54151 11.6494Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M12 7.24981C10.6745 7.24981 9.6 8.32433 9.6 9.64981C9.6 9.98118 9.33137 10.2498 9 10.2498C8.66863 10.2498 8.4 9.98118 8.4 9.64981C8.4 7.66159 10.0118 6.04981 12 6.04981C13.9882 6.04981 15.6 7.66159 15.6 9.64981C15.6 11.0092 14.6982 11.8692 13.9974 12.5375L13.9148 12.6164C13.143 13.3549 12.6 13.9245 12.6 14.8225C12.6 15.1539 12.3314 15.4225 12 15.4225C11.6686 15.4225 11.4 15.1539 11.4 14.8225C11.4 13.3615 12.3386 12.4636 13.064 11.7696L13.0852 11.7494C13.875 10.9937 14.4 10.4676 14.4 9.64981C14.4 8.32433 13.3255 7.24981 12 7.24981Z"
            fill="currentColor"
          />
          <path
            d="M12 17.9127C12.3452 17.9127 12.625 17.6329 12.625 17.2877C12.625 16.9425 12.3452 16.6627 12 16.6627C11.6548 16.6627 11.375 16.9425 11.375 17.2877C11.375 17.6329 11.6548 17.9127 12 17.9127Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12C1 5.93286 5.93286 1 12 1C18.0671 1 23 5.93286 23 12C23 18.0671 18.0671 23 12 23C5.93286 23 1 18.0671 1 12ZM12 2.2C6.5956 2.2 2.2 6.5956 2.2 12C2.2 17.4044 6.5956 21.8 12 21.8C17.4044 21.8 21.8 17.4044 21.8 12C21.8 6.5956 17.4044 2.2 12 2.2Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
