import { Box, styled } from '@withjoy/joykit';

export const CheckboxContainer = styled(Box)`
  input:checked + label .checkbox_svg__check-circle {
    fill: ${props => props.theme.colors.mono14};
    stroke: ${props => props.theme.colors.mono14};
  }
`;

const basicGiftNoteContainer = { border: '1px solid', borderColor: 'mono3', borderRadius: '4px', paddingTop: 1 };

export const styles = { basicGiftNoteContainer };
