import { Box, ButtonV2, DialogV2, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { titleStyled, boxWidthStyled } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/components/ConfirmationModalContent.styles';
import {
  howToListStyled,
  numberStyled,
  StyledHowToList
} from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/components/IntentToPurchaseModalContent/IntentToPurchaseModalContent.styles';
import { ToastVariableProps } from '@apps/registry/guest/routes/GuestRegistry/GuestRegistry.controller';

type IntentToPurchaseModalContentProps = {
  handleUndo: () => void;
  handleConfirm: () => void;
  openToast: (toast: ToastVariableProps) => void;
  donationPlatformDomain: Maybe<string>;
  isDonation: boolean;
};

export const IntentToPurchaseModalContent = ({ openToast, handleUndo, handleConfirm, donationPlatformDomain, isDonation }: IntentToPurchaseModalContentProps) => {
  const { t } = useTranslation('guestRegistry');
  const { title, ctaNo, ctaYes, titleHowTo, howTo1, howTo2, titleHowToDonation, howTo2Donation } = t('intentToPurchaseModal');
  const { reservedSuccess } = t('shoppingCart');

  return (
    <>
      <Box position="relative">
        <DialogV2.Header>
          <Box __css={boxWidthStyled}>
            <TextV2 __css={{ ...titleStyled, paddingTop: 10, paddingLeft: 4, paddingRight: 4 }} typographyVariant="hed4">
              {title()}
            </TextV2>
          </Box>
        </DialogV2.Header>
        <DialogV2.Body>
          <Box __css={boxWidthStyled} marginTop={6}>
            <ButtonV2 intent="productive" variant="outline" onClick={handleUndo} width="100%" display="block">
              {ctaNo()}
            </ButtonV2>
            <ButtonV2
              intent="productive"
              onClick={() => {
                openToast({ notificationText: reservedSuccess(), isPositionTop: true, noAnimation: true });
                handleConfirm();
              }}
              width="100%"
              display="block"
              marginTop={5}
            >
              {ctaYes()}
            </ButtonV2>
          </Box>
          <Box __css={boxWidthStyled} width="100%">
            <TextV2 __css={titleStyled} typographyVariant="hed2">
              {isDonation ? titleHowToDonation() : titleHowTo()}
            </TextV2>
            <StyledHowToList __css={howToListStyled}>
              <Box __css={numberStyled}>1</Box>
              <TextV2>{isDonation ? t('intentToPurchaseModal', 'howTo1Donation')({ platform: donationPlatformDomain }) : howTo1()}</TextV2>
              <Box __css={numberStyled}>2</Box>
              <TextV2>{isDonation ? howTo2Donation() : howTo2()}</TextV2>
            </StyledHowToList>
          </Box>
        </DialogV2.Body>
      </Box>
    </>
  );
};
