import { Flex, styled } from '@withjoy/joykit';
import { OptionSelectDown, Add } from '@withjoy/joykit/icons';
import { Colors } from '@withjoy/joykit/theme';
import { animationTransition } from '@shared/utils/animationTransition';

export const ExpandArrow = styled(OptionSelectDown)<{ isOpen: boolean }>`
  transition: transform 200ms ease-in-out;
  transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
  margin: auto;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  transition: background-color 1s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const ExpandPlus = styled(Add)<{ isOpen: boolean }>`
  transition: transform 200ms ease-in-out;
  transform: ${({ isOpen }) => isOpen && 'rotate(45deg)'};
  margin: auto;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  transition: background-color 1s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const CardContainer = styled(Flex)`
  width: 100%;
  max-width: 650px;
  box-shadow: 0px 7px 27px rgba(44, 41, 37, 0.06), 0px 4px 16px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  position: relative;
  padding-bottom: 0;
`;

export const CardImage = styled.img`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const MessageTextArea = styled.textarea<{ fontSize: string; lineHeight: string; background?: string; color: keyof Colors }>`
  min-height: 300px;
  height: auto;
  width: ${({ theme }) => `calc(100% - ${theme.space[8]})`};
  margin: 1.25rem;
  margin-top: 2rem; // Extra spacing to stay away from the replay button.
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 400;
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: 0.03em;
  padding: 1.25rem;
  padding-bottom: 0;
  white-space: pre-line;
  border: 1px solid ${({ theme, color }) => theme.colors[color]};
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  resize: none;
  outline: none;
  background-color: ${({ background }) => background};
  font-family: 'Shadows Into Light Two', cursive;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const GiftImage = styled.img<{ size?: string }>`
  object-fit: cover;
  object-position: center;
  width: 200px;
  height: 200px;
  border-radius: 8px;

  @media (max-width: 475px) {
    width: 160px;
    height: 160px;
  }

  ${({ size }) => (size ? `width: ${size} !important; height: ${size} !important;` : '')}
`;

export const ThemeThumbnail = styled.div<{ image: string; isActive: boolean; width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  border: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.colors.mono14}` : '1px solid transparent')};
  transition: ${animationTransition('border')};
  cursor: pointer;
  border-radius: 2px;
  margin: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[1]};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.mono14};
  }
`;
