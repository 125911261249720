import { Box, styled, StyleSystemProps } from '@withjoy/joykit';

export const StyledProductPhoto = styled(Box)<{ isBackgroundColorEnabled?: boolean }>`
  ${props => (props.isBackgroundColorEnabled ? 'mix-blend-mode: multiply;' : '')}
`;
export const StyledPhotoSpotlightContainer = styled(Box)``;

const photoSpotlightContainerStyles: StyleSystemProps = {
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center'
};

const productPhotoStyles: StyleSystemProps = {
  width: '100%',
  paddingTop: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
};
export const styles = {
  photoSpotlightContainer: photoSpotlightContainerStyles,
  productPhoto: productPhotoStyles
};
