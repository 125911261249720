import { useWindowResizeSubscriber } from '@shared/utils/hooks/useWindowResize';
import { addRendition } from '@shared/utils/photoRendition';
import { Box } from '@withjoy/joykit';
import { ChevronLeftSquare, Play } from '@withjoy/joykit/icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import Slider from 'react-slick';
import { StyledSliderContainer, StyledThumbnailContainer, StyledThumbnailIcon, StyledThumbnailWrapper, styles } from './GalleryThumbnails.styles';
import { getMediaAssetThumbnailUrl, isVideoMediaAsset } from '../ProductImageGallery/utils';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';

interface GalleryLightboxThumbnail
  extends Readonly<{
    assets: Array<CatalogProductMediaAsset>;
    handleClickThumbnail: (image: CatalogProductMediaAsset, index: number) => void;
    activeThumbnailIndex: number;
    isMobile?: boolean;
    enablePeek?: boolean;
    parentRef?: React.RefObject<HTMLDivElement>;
  }> {}

export const GalleryThumbnails = ({ assets, handleClickThumbnail, activeThumbnailIndex, isMobile, enablePeek, parentRef }: GalleryLightboxThumbnail) => {
  const handleThumbnailClick = useCallback(
    (image: CatalogProductMediaAsset, index: number) => {
      handleClickThumbnail(image, index);
    },
    [handleClickThumbnail]
  );

  const [thumbnailsToShow, setThumbnailsToShow] = React.useState(5);

  const computeTumbnailsToShow = useCallback(
    windowSize => {
      const { width } = windowSize;
      const thumbnailWidth = isMobile ? 66 : 72; // 54px/60px width + 12px margin
      const maxThumbnailsToShow = Math.floor(width / thumbnailWidth);
      setThumbnailsToShow(maxThumbnailsToShow);
    },
    [isMobile]
  );

  useEffect(() => {
    computeTumbnailsToShow({ width: parentRef?.current ? parentRef.current?.offsetWidth : window.innerWidth });
  }, [computeTumbnailsToShow, parentRef]);

  useWindowResizeSubscriber(computeTumbnailsToShow);

  const sliderSettings = useMemo(() => {
    return {
      dots: false,
      arrows: true,
      swipe: true,
      draggable: true,
      swipeToSlide: true,
      infinite: false,
      slidesToShow: thumbnailsToShow,
      initialSlide: activeThumbnailIndex >= thumbnailsToShow - 1 ? activeThumbnailIndex - thumbnailsToShow + 2 : 0,
      variableWidth: true,
      useTransform: false, // necessary for Safari. If set to true the overlapping text will be rendered too slow
      nextArrow: <ChevronLeftSquare size="md" />,
      prevArrow: <ChevronLeftSquare size="md" />
    };
  }, [activeThumbnailIndex, thumbnailsToShow]);

  return (
    <StyledSliderContainer {...styles.sliderContainer}>
      <Slider {...sliderSettings}>
        {assets.length > 1 &&
          assets.map((asset, index) => {
            return (
              <StyledThumbnailWrapper
                key={index}
                as="button"
                type="button"
                aria-label={`Thumbnail ${index + 1}`}
                aria-selected={activeThumbnailIndex === index}
                __css={styles.thumbnailWrapper(enablePeek && index === 0, index === activeThumbnailIndex)}
                onClick={() => handleThumbnailClick(asset, index)}
              >
                <StyledThumbnailContainer
                  as="span"
                  __css={styles.productThumbnail}
                  backgroundImage={`url(${addRendition({ url: getMediaAssetThumbnailUrl(asset), renditionSize: 'small', isProduct: true })})`}
                />
                {isVideoMediaAsset(asset) && (
                  <StyledThumbnailIcon __css={styles.thumbnailIcon}>
                    <Play size={'sm'} />
                  </StyledThumbnailIcon>
                )}
              </StyledThumbnailWrapper>
            );
          })}
        {/*  this is a hack to make the slider scroll all elements to the left when the last element is selected */}
        <Box width={'1px !important'}></Box>
      </Slider>
    </StyledSliderContainer>
  );
};
