import React from 'react';
import { StyledColumnsContribution } from '../Purchase.styles';
import { ShippingAddressFragment } from '@graphql/aliases';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { PurchaseConfirmationInner } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationInner';

interface PurchaseConfirmationProps
  extends Readonly<{
    handleUndoClick: () => void;
    onBackClick: () => void;
    shippingAddress: ShippingAddressFragment | null;
    productImage: string;
    handleConfirmationClick: () => void;
  }> {}

export const PurchaseConfirmation: React.FC<PurchaseConfirmationProps> = ({ onBackClick, shippingAddress, handleUndoClick, productImage, handleConfirmationClick }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  return (
    <StyledColumnsContribution isMobile={isMobile} isInModal={false}>
      <PurchaseConfirmationInner
        productImage={productImage}
        onBackClick={onBackClick}
        shippingAddress={shippingAddress}
        handleUndoClick={handleUndoClick}
        handleConfirmationClick={handleConfirmationClick}
        isInModal={false}
        productTitle={null}
        handleNotPurchased={() => {}}
        isDonation={false}
      />
    </StyledColumnsContribution>
  );
};
