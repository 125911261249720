import React from 'react';
import { LayoutShell, PrimaryCta } from '../../components';
import { useCancelGiftConfirmationController } from './CancelGiftConfirmation.controller';
import { CheckOutline } from '@withjoy/joykit/icons';
import { Box, SpacingStack, TextV2 } from '@withjoy/joykit';
import { CancelGiftConfirmationSelectors } from './CancelGiftConfirmation.selectors';

export const CancelGiftConfirmation = () => {
  const { toNext, translations } = useCancelGiftConfirmationController();

  const { title, subtitle, buttonLabel } = translations;

  return (
    <LayoutShell {...CancelGiftConfirmationSelectors.ContainerElement}>
      <LayoutShell.Body>
        <LayoutShell.SingleColumnPanel>
          <Box paddingBottom={5}>
            <CheckOutline size="xxl" />
          </Box>
          <SpacingStack spacing={7}>
            <SpacingStack spacing={5} alignItems="center">
              <TextV2 typographyVariant="display2" textAlign="center">
                {title}
              </TextV2>
              <TextV2 typographyVariant="body1" textAlign="center">
                {subtitle}
              </TextV2>
            </SpacingStack>
            <PrimaryCta {...CancelGiftConfirmationSelectors.DoneButton} onClick={toNext}>
              {buttonLabel}
            </PrimaryCta>
          </SpacingStack>
        </LayoutShell.SingleColumnPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
