import React from 'react';
import { TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

import { MetadataBase } from './MetadaBase';
import { OrderNumber } from './icons';

type Props = {
  orderNumber: string;
};

export const OrderNumberMetadata = ({ orderNumber }: Props) => {
  const { t2 } = useTranslation('guestRegistry');
  const { orderNumber: orderNumberTitle } = t2('shoppingCart', 'metadataAtoms');
  return <MetadataBase icon={<OrderNumber />} text={orderNumberTitle} content={<TextV2 typographyVariant="body1">{orderNumber}</TextV2>} />;
};
