import { Box, styled } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { anatomy, PartsStyleFunction, StyleSystemFn, MultiPartStyleConfig } from '@withjoy/joykit/utils';
import { AlertDefaultProps, AlertProps } from './Alert.types';

export const StyledContainer = styled(Box)``;

const baseStyles: StyleSystemFn<AlertProps> = props => ({
  display: 'inline-flex',
  flexDirection: 'column',
  padding: 6,
  backgroundColor: 'info1',
  border: `${pxToRem(1)} solid`,
  borderColor: 'warning3',
  borderRadius: 3,
  textAlign: props.isCentered ? 'center' : 'left'
});

export const StyledTitle = styled(Box)``;

const titleStyles: StyleSystemFn<AlertProps> = props => {
  return props.isCentered
    ? {
        typographyVariant: 'hed4',
        paddingBottom: 3
      }
    : {
        typographyVariant: 'button1'
      };
};

export const StyledDescription = styled(Box)``;

const descriptionStyles: StyleSystemFn<AlertProps> = props => ({
  typographyVariant: props.isCentered ? 'body2' : 'body1'
});

const alertBaseStyle: PartsStyleFunction<AlertProps, typeof alertAnatomy> = props => ({
  root: baseStyles(props),
  title: titleStyles(props),
  description: descriptionStyles(props)
});

const alertAnatomy = anatomy('Alert').parts('root', 'title', 'description');

export const alertStyles: MultiPartStyleConfig<AlertProps & AlertDefaultProps, typeof alertAnatomy> = {
  parts: alertAnatomy.keys,
  baseStyle: alertBaseStyle
};
