import React from 'react';
import { Flex, IconV2, SpacingStack, TextV2 } from '@withjoy/joykit';
import { Shipping, EasyReturns, PriceMatchPromise } from './icons/idnex';
import { usePurchaseDialogTranslations } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/hooks';

const RegistryValue = ({ icon, title }: { icon: React.ReactNode; title: string }) => {
  return (
    <Flex alignItems="center" columnGap={[0, 0, 5]} rowGap={[4, 4, 0]} flexDirection={['column', 'column', 'row']}>
      <IconV2 size={48}>{icon}</IconV2>
      <TextV2 typographyVariant={['hed2', 'hed2', 'hed3']} textAlign={['center', 'center', 'left']}>
        {title}
      </TextV2>
    </Flex>
  );
};

export const RegistryValues = () => {
  const { everyJoyRegistryDeserves, freeShipping, priceMatchPromise, easyReturns } = usePurchaseDialogTranslations();
  return (
    <Flex flexDirection="column" rowGap={7} alignItems="center" paddingBottom={6} paddingX={[7, 0]}>
      <TextV2 typographyVariant="hed4">{everyJoyRegistryDeserves()}</TextV2>
      <SpacingStack spacing={9} stack="horizontal">
        <RegistryValue icon={<Shipping />} title={freeShipping()} />
        <RegistryValue icon={<PriceMatchPromise />} title={priceMatchPromise()} />
        <RegistryValue icon={<EasyReturns />} title={easyReturns()} />
      </SpacingStack>
    </Flex>
  );
};

const RegistryValueV2 = ({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) => {
  return (
    <Flex alignItems="center" justifyContent="center" columnGap={3} rowGap={3} flexDirection="column">
      <IconV2 size={48}>{icon}</IconV2>
      <TextV2 typographyVariant="hed1" textAlign="center" color="mono14">
        {title}
      </TextV2>
      <TextV2 textAlign="center" typographyVariant="label2" color="mono12">
        {description}
      </TextV2>
    </Flex>
  );
};

export const RegistryValuesV2 = () => {
  const {
    everyJoyRegistryDeserves,
    freeShipping,
    freeShippingDescription,
    priceMatchPromise,
    priceMatchPromiseDescription,
    easyReturns,
    easyReturnsDescription
  } = usePurchaseDialogTranslations();

  return (
    <Flex flexDirection="column" rowGap={7} paddingBottom={6} paddingX={[7, 0]} width="100%">
      <TextV2 textAlign="center" typographyVariant="hed4">
        {everyJoyRegistryDeserves()}
      </TextV2>
      <Flex justifyContent="space-evenly" flex={1}>
        <RegistryValueV2 icon={<Shipping />} title={freeShipping()} description={freeShippingDescription()} />
        <RegistryValueV2 icon={<PriceMatchPromise />} title={priceMatchPromise()} description={priceMatchPromiseDescription()} />
        <RegistryValueV2 icon={<EasyReturns />} title={easyReturns()} description={easyReturnsDescription()} />
      </Flex>
    </Flex>
  );
};
