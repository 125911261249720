import React from 'react';
import { Box, TooltipV2, TextV2, Flex, DialogV2, ButtonV2 } from '@withjoy/joykit';
import { CookedProduct, isPricedProduct } from '@apps/registry/common/selectors/ProductListSelector';
import { StyledProductPhoto, StyledProductTitle, styles } from './SyncedProductDialog.styles';
import { useGuestRegistryTelemetry } from '@apps/registry/guest/GuestRegistry.telemetry';
import { ReactComponent as HelpIcon } from '@assets/icons/help.svg';
import { DEFAULT_CURRENCY_SYMBOL } from '@shared/utils/currency/constants';
import { addRendition } from '@shared/utils/photoRendition';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { config } from '@static/js/env.config';
import queryString from 'query-string';
import { getCurrencySymbolFromCurrencyCode } from '@shared/utils/currency/utils';

export interface ProductDialogProps
  extends Readonly<{
    product?: CookedProduct;
    eventId?: string;
    isOpen: boolean;
    onClose: () => void;
  }> {}

export const SyncedProductDialog: React.FC<ProductDialogProps> = ({ product, isOpen, eventId, onClose }) => {
  const { clickToBuy, externalRegistry } = useGuestRegistryTelemetry();

  const { t } = useTranslation('guestRegistry');
  const { clickToBuy: clickToBuyText, availableToPurchaseOn: availableToPurchaseOnText, markAsPurchased, markAsPurchasedTitle, markAsPurchasedSubtitle } = t('productDialog');

  const buildRedirectUrl = (url: string) => {
    return queryString.stringifyUrl({
      url: config.redirectServiceUri ? `${config.redirectServiceUri}/redirect` : 'https://dev.withjoy.com/services/redirect/redirect',
      query: {
        url
      }
    });
  };

  const handlePurchaseClick = () => {
    if (product) {
      const redirectUrl = buildRedirectUrl(product.registry.url!);
      withWindow(window => {
        window.open(redirectUrl, '__blank');
      });
      clickToBuy(product, clickToBuyText(), eventId);
      if (product?.registry.id) {
        const isPriced = isPricedProduct(product);
        externalRegistry({
          id: product.registry.id,
          priceValueInMinorUnits: isPriced ? product.valueInMinorUnits : 0,
          priceCurrencyCode: isPriced ? getCurrencySymbolFromCurrencyCode(product.currencyCode) : DEFAULT_CURRENCY_SYMBOL,
          reservedQty: product.reserved,
          destinationUrl: product.externalUrl,
          typeOfItem: product.registryItemType
        });
      }
    }
  };

  if (!product) {
    return null;
  }

  let priceMarkup: React.ReactNode;
  if (isPricedProduct(product) && !product?.donationFund?.fund.hideFundingGoal) {
    priceMarkup = product.price && (
      <TextV2 typographyVariant="body2" component="p" marginBottom={7}>
        {product.price}
      </TextV2>
    );
  }

  return (
    <DialogV2 isOpen={isOpen} size="3xl" onClose={onClose}>
      <DialogV2.CloseButton />
      <DialogV2.Body>
        <Flex color="mono14" flexDirection={['column', null, 'row']} justifyContent="center" marginTop={9} alignItems={['center', null, 'flex-start']}>
          <Box marginRight={[0, null, 9]}>
            <Box marginBottom={[7, null, 3]} backgroundColor={'mono2'} width={[280, null, 328]} height={[280, null, 328]}>
              <StyledProductPhoto
                __css={styles.productPhoto}
                backgroundImage={`url(${addRendition({ url: product.image, renditionSize: 'medium', isProduct: true })})`}
                width={[280, null, 328]}
                height={[280, null, 328]}
              />
            </Box>
          </Box>
          <Box maxWidth="320px">
            <StyledProductTitle typographyVariant="hed4" component="p" marginBottom={3} textAlign="left">
              {product.title}
            </StyledProductTitle>
            {priceMarkup}
            <TextV2 typographyVariant="body1" textAlign={'left'} marginBottom={3} marginTop={[8, null, 0]}>
              {availableToPurchaseOnText()}
            </TextV2>
            <StyledProductPhoto __css={styles.productPhoto} backgroundImage={`url(${product.registry.logo})`} width={110} height={50} marginBottom={9} />
            <Flex display="flex" alignItems="center" marginBottom={9}>
              <TextV2 color="mono12" typographyVariant="body1" marginRight={3}>
                {markAsPurchased()}
              </TextV2>
              <TooltipV2
                triggerType="hover"
                content={
                  <Box maxWidth="256px">
                    <TextV2 color="white" typographyVariant="label3" marginBottom={1}>
                      {markAsPurchasedTitle({ storeName: product?.storeName })}
                    </TextV2>
                    <TextV2 color="mono4" typographyVariant="label2">
                      {markAsPurchasedSubtitle()}
                    </TextV2>
                  </Box>
                }
                placement={'topEnd'}
              >
                <Box display="flex" color="mono12">
                  <HelpIcon />
                </Box>
              </TooltipV2>
            </Flex>
            {product?.registry?.url && (
              <Box marginBottom={6}>
                <ButtonV2 variant="solid" intent="productive" fullWidth={true} onClick={handlePurchaseClick}>
                  {clickToBuyText()}
                </ButtonV2>
              </Box>
            )}
          </Box>
        </Flex>
      </DialogV2.Body>
    </DialogV2>
  );
};

SyncedProductDialog.displayName = 'SyncedProductDialog';
