import React from 'react';
import { ReactComponent as JoyLogo } from '@assets/joy-logo-updated.svg';
import { StyledBox, StyledFlex } from './PageHeader.styles';
import { Box } from '@withjoy/joykit';
import { Layout } from '@graphql/generated';

interface Props
  extends Readonly<{
    website: string;
    isAdmin: boolean;
    hideLogo?: boolean;
    isNative?: boolean;
    layout?: Layout;
  }> {}

export const PageHeader: React.FC<Props> = ({ website, isAdmin, hideLogo, isNative, layout }) => {
  const containsSingleItem = hideLogo && isAdmin;
  if ((hideLogo && !isAdmin) || isNative) {
    return null;
  }
  return (
    <Box marginBottom={[7, null, 84]}>
      <StyledBox paddingTop={[9, null, 8]} paddingX={[8]} hideBorder={true}>
        <StyledFlex alignItems="center" flexDirection={['column', null, 'row']} justifyContent={containsSingleItem ? 'flex-end' : 'space-between'}>
          {!hideLogo && <JoyLogo />}
        </StyledFlex>
      </StyledBox>
    </Box>
  );
};
