import React from 'react';
import { noop } from 'lodash-es';
import { pxToRem } from '@withjoy/joykit/theme';

import { SkeletonText } from '@shared/components/Skeleton';

import { useCheckoutFunnel } from '@apps/registry/guest/components/CheckoutDialog/hooks';
import { LayoutShell, TopNav } from '@apps/registry/guest/components/CheckoutDialog/components';
import { SkeletonImage, styles } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog/components/LayoutShell/LayoutShell.styles';
import { GifterDetailsSelectors } from '@apps/registry/guest/components/CheckoutDialog/routes/GifterDetails/GifterDetails.selectors';
import { StyledHeaderRoot, styles as headerStyles } from '@apps/registry/guest/components/CheckoutDialog/components/LayoutShell/LayoutShell.styles';

const AffiliateLayout = () => (
  <LayoutShell {...GifterDetailsSelectors.ContainerElement}>
    <StyledHeaderRoot __css={headerStyles.headerRoot('static')}>
      <TopNav>
        <TopNav.CloseButton onClick={noop} />
      </TopNav>
    </StyledHeaderRoot>
    <LayoutShell.Body paddingTop={6}>
      <LayoutShell.MediaPanel>
        <SkeletonImage __css={styles.skeletonImage} margin="0 auto" />
      </LayoutShell.MediaPanel>
      <LayoutShell.ContentPanel>
        <SkeletonText marginBottom={5} width="100%" skeletonHeight={pxToRem(31)} rows={1} />
        <SkeletonText marginBottom={6} width="100%" skeletonHeight={pxToRem(48)} rows={1} />
        <SkeletonText marginBottom={6} width="100%" skeletonHeight={pxToRem(48)} rows={1} />
        <SkeletonText marginBottom={6} width="100%" skeletonHeight={pxToRem(48)} rows={1} />
        <SkeletonText marginBottom={7} width="100%" skeletonHeight={pxToRem(100)} rows={1} />
        <SkeletonText marginBottom={7} width="100%" skeletonHeight={pxToRem(48)} rows={1} />
      </LayoutShell.ContentPanel>
    </LayoutShell.Body>
  </LayoutShell>
);

export const Setup = () => {
  const {
    context: { isAffiliate, isGroupGifting }
  } = useCheckoutFunnel(({ context }) => [context.isAffiliate, context.isGroupGifting]);

  return isAffiliate || isGroupGifting ? <AffiliateLayout /> : null;
};
