import { Box, Flex, TextV2, ButtonV2, styled, StyleSystemProps, FormControl } from '@withjoy/joykit';

export const StyledContentWrapper = styled(Box)``;

export const StyledIconRow = styled(Flex)``;
const iconRowStyles: StyleSystemProps = {
  justifyContent: 'center',
  marginBottom: 6
};

export const StyledIconWrapperBox = styled(Box)``;
const iconWrapperBoxStyles: StyleSystemProps = {
  width: '64px',
  height: '64px'
};

export const StyledTitleText = styled(TextV2)``;
const titleTextStyles: StyleSystemProps = {
  textAlign: 'center',
  marginBottom: 6
};

export const StyledQuantityRowWrapper = styled(Flex)``;
export const StyledQuantityTextWrapper = styled(Flex)``;
const quantityTextWrapperStyles: StyleSystemProps = {
  flexDirection: 'column',
  marginRight: 6
};

export const StyledInputWrapperColumn = styled(Flex)``;
const inputWrapperColumnStyles: StyleSystemProps = {
  flexDirection: 'column',
  marginBottom: 6
};

export const StyledDoneButton = styled(ButtonV2)``;
export const StyledCancelButton = styled(ButtonV2)``;

export const StyledCancelReservationWrapper = styled(Flex)``;
const cancelReservationWrapperStyles: StyleSystemProps = {
  flexDirection: 'column'
};

export const StyledCancelReservationTitle = styled(TextV2)``;
const cancelReservationTitleStyles: StyleSystemProps = {
  color: 'monoOne',
  marginBottom: 4,
  textAlign: 'center'
};

export const StyledFormControl = styled(FormControl)`
  > label {
    font-weight: 700;
  }
`;

export const StyledInputError = styled(TextV2)``;
const inputErrorStyles: StyleSystemProps = {
  color: 'red6',
  minHeight: '22px'
};

export const styles = {
  iconWrapperBox: iconWrapperBoxStyles,
  iconRow: iconRowStyles,
  titleText: titleTextStyles,
  quantityTextWrapper: quantityTextWrapperStyles,
  inputWrapperColumn: inputWrapperColumnStyles,
  cancelReservationWrapper: cancelReservationWrapperStyles,
  cancelReservationTitle: cancelReservationTitleStyles,
  inputError: inputErrorStyles
};
