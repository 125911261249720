import React, { useCallback } from 'react';
import { Box, BoxProps, Flex } from '@withjoy/joykit';
import { StyledContainer, StyledDragContainer, StyledPhotoSpotlightContainer, StyledPrimaryPhotoControls, StyledProductPhoto, styles } from './ProductImageGallery.styles';
import { addRendition } from '@shared/utils/photoRendition';
import { animated } from 'react-spring';
import { UseCarouselGalleryArgs, useInlineGallery, UseInlineGalleryArgs, useProductGalleryCarouselController } from './ProductImageGallery.controller';
import { DEFAULT_CUSTOM_ITEM_IMAGE } from '../../../../Catalog.constants';
import { SHOULD_LOOP } from './ProductImageGallery.constants';
import { ProductPhoto } from '../ProductPhoto';
import {
  areEqualMediaAssets,
  getGridAreaConfiguration,
  getMediaAssetImgUrl,
  isPhotoMediaAsset,
  isVideoMediaAsset
} from '@apps/registry/common/components/Catalog/components/ProductDetails/components/ProductImageGallery/utils';
import { SkeletonGroup, SkeletonThumbnail } from '@shared/components/Skeleton';
import { PdpGalleryLightbox } from '../PdpGalleryLightbox/PdpGalleryLightbox';
import { CatalogProductMediaAsset } from '../../../../Catalog.types';
import { ProductCatalogAssetType } from '@graphql/generated';
import { ProductVideo } from '../ProductVideo/ProductVideo';
import { GalleryThumbnails } from '../GalleryThumbnails';
import CustomCursor from '../GalleryLightbox/assets/custom-cursor-plus.svg';

interface ProductImageGalleryProps
  extends Readonly<
    {
      mediaAssets: CatalogProductMediaAsset[];
      selectedMediaAsset: CatalogProductMediaAsset | null;
      handleClickMediaAsset: (mediaAsset: CatalogProductMediaAsset) => void;
      isCoverImage?: boolean;
      maxAmountOfStepsVisible?: number;
      isDialog?: boolean;
      isInlineVariant?: boolean;
      galleryLightboxQuickAddBarRef?: React.RefObject<HTMLDivElement>;
    } & BoxProps
  > {}

const GalleryContainer: React.FC<BoxProps> = props => <StyledContainer __css={styles.container} {...props} />;

const GalleryPrimaryControl: React.FC<BoxProps> = props => {
  return <StyledPrimaryPhotoControls {...props} />;
};

const PrimaryControlPhotoSpotlight = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return <Box ref={ref} gridArea="primaryPhoto" __css={styles.photoGrid} {...props} />;
});

const GallerySecondaryControl: React.FC<BoxProps> = props => {
  return <Flex justifyContent="center" marginTop={[7, 6]} {...props} />;
};

interface InlineGalleryProps
  extends Readonly<{
    activeImageIndex: number;
    mediaAssets: CatalogProductMediaAsset[];
    isCoverImage: boolean;
    stepToImage: UseInlineGalleryArgs['stepToImage'];
    onClickMediaAsset?: (image: CatalogProductMediaAsset) => void;
  }> {}

const InlineGallery: React.FC<InlineGalleryProps> = ({ activeImageIndex, mediaAssets, isCoverImage, stepToImage, onClickMediaAsset }) => {
  const mediaAssetsCount = mediaAssets.length;
  const shouldSwipe = mediaAssetsCount > 1;
  const { dragContainerRef, handlers, stopScroll, trackedIndex, wrapperRef, transitions } = useInlineGallery({
    imageCount: mediaAssetsCount,
    stepToImage,
    activeImageIndex,
    mediaAssets
  });
  const leadImage: CatalogProductMediaAsset = mediaAssets[0] || { type: ProductCatalogAssetType.photo, photo: { url: DEFAULT_CUSTOM_ITEM_IMAGE } };
  const tailImage: CatalogProductMediaAsset = mediaAssets[mediaAssetsCount - 1] || { type: ProductCatalogAssetType.photo, photo: { url: DEFAULT_CUSTOM_ITEM_IMAGE } };
  const leftToStyle = SHOULD_LOOP ? `${(trackedIndex + 1) * -100}%` : `${trackedIndex * -100}%`;
  const withToStyle = SHOULD_LOOP ? `${(mediaAssetsCount + 2) * 100}%` : `${mediaAssetsCount * 100}%`;

  return (
    <PrimaryControlPhotoSpotlight
      {...handlers}
      role="group"
      aria-label="Gallery"
      overflow="hidden"
      display="block"
      ref={wrapperRef}
      style={{
        touchAction: stopScroll ? 'none' : 'pan-y'
      }}
    >
      {!shouldSwipe && <InlineGalleryPhoto mediaAsset={leadImage} isCoverImage={isCoverImage} onClick={onClickMediaAsset} isVisibile={activeImageIndex === 0} />}
      {shouldSwipe &&
        transitions.map(({ key, props, item }) => {
          return (
            item && (
              <>
                <Box as={animated.div} style={props} key={key}>
                  <StyledDragContainer
                    display="flex"
                    position={'relative'}
                    ref={dragContainerRef}
                    style={{
                      transition: 'none',
                      transform: `translateX(0px)`,
                      left: leftToStyle,
                      width: withToStyle
                    }}
                  >
                    {SHOULD_LOOP && (
                      <InlineGalleryPhoto
                        key={-1}
                        mediaAsset={tailImage}
                        isCoverImage={isCoverImage}
                        onClick={onClickMediaAsset}
                        isVisibile={activeImageIndex === mediaAssetsCount - 1}
                      />
                    )}
                    {mediaAssets.map((asset, idx) => {
                      return <InlineGalleryPhoto key={idx} mediaAsset={asset} isCoverImage={isCoverImage} onClick={onClickMediaAsset} isVisibile={activeImageIndex === idx} />;
                    })}
                    {SHOULD_LOOP && (
                      <InlineGalleryPhoto
                        key={mediaAssetsCount}
                        mediaAsset={leadImage}
                        isCoverImage={isCoverImage}
                        onClick={onClickMediaAsset}
                        isVisibile={activeImageIndex === 0}
                      />
                    )}
                  </StyledDragContainer>
                </Box>
              </>
            )
          );
        })}
    </PrimaryControlPhotoSpotlight>
  );
};

interface InlineGalleryPhotoProps
  extends Readonly<{
    mediaAsset: CatalogProductMediaAsset;
    isCoverImage: boolean;
    isVisibile?: boolean;
    onClick?: (image: CatalogProductMediaAsset) => void;
  }> {}

const InlineGalleryPhoto: React.FC<InlineGalleryPhotoProps> = ({ mediaAsset, isCoverImage, isVisibile, onClick }) => {
  return (
    <Box
      __css={{ size: '100%' }}
      onClick={onClick ? () => onClick(mediaAsset) : undefined}
      cursor={onClick ? `url( '${CustomCursor}'),pointer` : undefined}
      visibility={isVisibile ? 'visible' : 'hidden'}
    >
      {isPhotoMediaAsset(mediaAsset) ? (
        <StyledProductPhoto
          __css={styles.productPhoto}
          style={{
            backgroundImage: `url(${addRendition({ url: getMediaAssetImgUrl(mediaAsset), renditionSize: 'large' })})`,
            backgroundSize: isCoverImage ? 'cover' : 'contain'
          }}
        />
      ) : (
        <>
          {isVideoMediaAsset(mediaAsset) ? (
            <Flex
              style={{
                aspectRatio: '1/1'
              }}
            >
              <ProductVideo
                videoUrl={mediaAsset.video?.url}
                fallbackImageUrl={mediaAsset.video?.fallbackImage.url}
                shouldPlay
                useBackgroundMode
                style={{ width: '100%', height: '100%' }}
              />
            </Flex>
          ) : (
            <StyledProductPhoto
              __css={styles.productPhoto}
              style={{
                backgroundImage: `url(${addRendition({ url: getMediaAssetImgUrl(mediaAsset), renditionSize: 'large' })})`,
                backgroundSize: isCoverImage ? 'cover' : 'contain'
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

interface CarouselGalleryProps
  extends Readonly<
    UseCarouselGalleryArgs & {
      mediaAssets: CatalogProductMediaAsset[];
      onClickMediaAsset?: (mediaAsset: CatalogProductMediaAsset) => void;
    }
  > {}

const GridPhoto: React.FC<{ asset: CatalogProductMediaAsset; onClick?: (mediaAsset: CatalogProductMediaAsset) => void; assetsCount: number; index: number }> = ({
  asset,
  onClick,
  assetsCount,
  index
}) => {
  return (
    <ProductPhoto
      style={{
        gridArea: getGridAreaConfiguration(index, assetsCount),
        backgroundColor: 'rgba(0,0,0, .03)'
      }}
      key={index}
      photoUrl={isPhotoMediaAsset(asset) ? asset.photo?.url : asset.video?.thumbnail.url || DEFAULT_CUSTOM_ITEM_IMAGE}
      isBackgroundColorEnabled={true}
      onClick={onClick ? () => onClick(asset) : undefined}
    />
  );
};

const GridGallery: React.FC<CarouselGalleryProps> = props => {
  const { mediaAssets, onClickMediaAsset } = props;

  return (
    <>
      <PrimaryControlPhotoSpotlight>
        {mediaAssets.map((asset, index) => {
          return (
            <>
              {isPhotoMediaAsset(asset) ? (
                <GridPhoto asset={asset} onClick={onClickMediaAsset} assetsCount={mediaAssets.length} index={index} />
              ) : (
                <>
                  {isVideoMediaAsset(asset) ? (
                    <Flex
                      onClick={onClickMediaAsset ? () => onClickMediaAsset(asset) : undefined}
                      style={{
                        backgroundColor: 'rgba(0,0,0, .03)',
                        mixBlendMode: 'multiply',
                        aspectRatio: '1/1',
                        gridArea: getGridAreaConfiguration(index, mediaAssets.length),
                        cursor: onClickMediaAsset ? `url( '${CustomCursor}'),pointer` : undefined
                      }}
                    >
                      <ProductVideo
                        videoUrl={asset.video?.url}
                        fallbackImageUrl={asset.video?.fallbackImage.url}
                        shouldPlay
                        useBackgroundMode
                        style={{ width: '100%', height: '100%' }}
                      />
                    </Flex>
                  ) : (
                    <GridPhoto asset={asset} onClick={onClickMediaAsset} assetsCount={mediaAssets.length} index={index} />
                  )}
                </>
              )}
            </>
          );
        })}
      </PrimaryControlPhotoSpotlight>
    </>
  );
};

export const ProductImageGallery: React.FC<ProductImageGalleryProps> & { Skeleton: typeof ImageGallerySkeleton } = props => {
  const { selectedMediaAsset, handleClickMediaAsset, isCoverImage, maxAmountOfStepsVisible, isInlineVariant, galleryLightboxQuickAddBarRef, ...restProps } = props;

  const { activeThumbnailIndex, handleClickThumbnail, isMobile, mediaAssetsList, stepToImage, imageGalleryContainerRef } = useProductGalleryCarouselController({
    handleClickMediaAsset,
    mediaAssets: props.mediaAssets,
    selectedMediaAsset
  });
  const [isOpenGalleryLightbox, setIsOpenGalleryLightbox] = React.useState(false);

  const handleOpenGalleryLightbox = useCallback(
    (mediaAsset: CatalogProductMediaAsset) => {
      handleClickMediaAsset(mediaAsset);
      setIsOpenGalleryLightbox(true);
      if (galleryLightboxQuickAddBarRef?.current?.style) {
        galleryLightboxQuickAddBarRef.current.style.display = 'flex';
      }
    },
    [galleryLightboxQuickAddBarRef, handleClickMediaAsset]
  );

  const handleCloseGalleryLightbox = useCallback(() => {
    setIsOpenGalleryLightbox(false);
    if (galleryLightboxQuickAddBarRef?.current?.style) {
      galleryLightboxQuickAddBarRef.current.style.display = 'none';
    }
  }, [galleryLightboxQuickAddBarRef]);

  return (
    <>
      <PdpGalleryLightbox
        isMobile={isMobile}
        isOpen={isOpenGalleryLightbox}
        onClose={handleCloseGalleryLightbox}
        onClickAsset={handleClickMediaAsset}
        assets={mediaAssetsList}
        selectedAssetIndex={selectedMediaAsset ? mediaAssetsList.findIndex(asset => areEqualMediaAssets(asset, selectedMediaAsset)) : 0}
      />
      <GalleryContainer {...restProps}>
        <GalleryPrimaryControl>
          {selectedMediaAsset ? (
            <>
              {isMobile || isInlineVariant ? (
                <InlineGallery
                  activeImageIndex={activeThumbnailIndex}
                  mediaAssets={mediaAssetsList}
                  isCoverImage={!!isCoverImage}
                  stepToImage={stepToImage}
                  onClickMediaAsset={handleOpenGalleryLightbox}
                />
              ) : (
                <GridGallery mediaAssets={mediaAssetsList} selectedMediaAsset={selectedMediaAsset} stepToImage={stepToImage} onClickMediaAsset={handleOpenGalleryLightbox} />
              )}
            </>
          ) : (
            <ProductPhoto as={animated.div} photoUrl={null} />
          )}
        </GalleryPrimaryControl>

        {(isMobile || isInlineVariant) && (
          <GallerySecondaryControl>
            <Box width="100%" ref={imageGalleryContainerRef}>
              <GalleryThumbnails
                assets={mediaAssetsList}
                handleClickThumbnail={handleClickThumbnail}
                parentRef={imageGalleryContainerRef}
                activeThumbnailIndex={activeThumbnailIndex}
                isMobile={isMobile}
              />
            </Box>
          </GallerySecondaryControl>
        )}
      </GalleryContainer>
    </>
  );
};

const ImageGallerySkeleton = (props: BoxProps & { isMobile?: boolean }) => {
  return (
    <SkeletonGroup
      {...props}
      placeholder={
        <GalleryContainer>
          <GalleryPrimaryControl>
            <PrimaryControlPhotoSpotlight>
              {props.isMobile ? (
                <StyledPhotoSpotlightContainer as="div" __css={styles.photoSpotlightContainer}>
                  <SkeletonThumbnail variant="square" width="400px" paddingTop={'100%'} height="0" flexGrow={1} />
                </StyledPhotoSpotlightContainer>
              ) : (
                <>
                  <StyledPhotoSpotlightContainer as="div" __css={styles.photoSpotlightContainer}>
                    <SkeletonThumbnail variant="square" width="100%" paddingTop={'100%'} height="0" flexGrow={1} />
                  </StyledPhotoSpotlightContainer>
                  <StyledPhotoSpotlightContainer as="div" __css={styles.photoSpotlightContainer}>
                    <SkeletonThumbnail variant="square" width="100%" paddingTop={'100%'} height="0" flexGrow={1} />
                  </StyledPhotoSpotlightContainer>
                  <StyledPhotoSpotlightContainer as="div" __css={styles.photoSpotlightContainer}>
                    <SkeletonThumbnail variant="square" width="100%" paddingTop={'100%'} height="0" flexGrow={1} />
                  </StyledPhotoSpotlightContainer>
                  <StyledPhotoSpotlightContainer as="div" __css={styles.photoSpotlightContainer}>
                    <SkeletonThumbnail variant="square" width="100%" paddingTop={'100%'} height="0" flexGrow={1} />
                  </StyledPhotoSpotlightContainer>
                </>
              )}
            </PrimaryControlPhotoSpotlight>
          </GalleryPrimaryControl>
        </GalleryContainer>
      }
    />
  );
};

ProductImageGallery.Skeleton = ImageGallerySkeleton;
