import React from 'react';
import { AddCircle, Reset, Trash } from '@withjoy/joykit/icons';
import { PhotoMenuItem, PhotoOperation } from './PhotoMenu.types';

export const MENUS: PhotoMenuItem[] = [
  {
    value: PhotoOperation.update,
    icon: <AddCircle />,
    intent: 'default'
  },
  {
    value: PhotoOperation.reset,
    icon: <Reset fill={'white'} />,
    intent: 'default'
  },
  {
    value: PhotoOperation.delete,
    icon: <Trash />,
    intent: 'destructive'
  }
];
