import React from 'react';
import { SpacingStack, TextV2 } from '@withjoy/joykit';
import { LayoutShell } from '../../components/LayoutShell';
import { IntegratedGiftCard, PrimaryCta, SecondaryCta } from '@apps/registry/guest/components/CheckoutDialog/components';
import { usePaperCheckoutConfirmAndNotifyController } from '@apps/registry/guest/components/CheckoutDialog/routes/PaperCheckoutConfirmAndNotify/PaperCheckoutConfirmAndNotify.controller';
import { PaperCheckoutConfirmAndNotifySelectors } from './PaperCheckoutConfirmAndNotify.selectors';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

export const PaperCheckoutConfirmAndNotify = () => {
  const { toNext, initiateCancelProcess, translations } = usePaperCheckoutConfirmAndNotifyController();
  const { title, cta, description, cancelButton } = translations;
  const isMobile = useIsMobileScreen();

  return (
    <LayoutShell {...PaperCheckoutConfirmAndNotifySelectors.ContainerElement}>
      <LayoutShell.Header />
      <LayoutShell.Body>
        {!isMobile ? (
          <LayoutShell.MediaPanel>
            <IntegratedGiftCard mobileRenderBehavior="card" />
          </LayoutShell.MediaPanel>
        ) : null}
        <LayoutShell.ContentPanel>
          <SpacingStack spacing={7} marginTop={[6, 'unset']}>
            <SpacingStack spacing={5}>
              <TextV2 typographyVariant="display2">{title()}</TextV2>
              <TextV2 typographyVariant="hed3">{description()}</TextV2>
            </SpacingStack>
            {isMobile ? <IntegratedGiftCard mobileRenderBehavior="card" margin={0} /> : null}
            <SpacingStack spacing={5}>
              <PrimaryCta {...PaperCheckoutConfirmAndNotifySelectors.ConfirmButton} onClick={toNext}>
                {cta()}
              </PrimaryCta>
              <SecondaryCta {...PaperCheckoutConfirmAndNotifySelectors.CancelButton} onClick={initiateCancelProcess}>
                {cancelButton()}
              </SecondaryCta>
            </SpacingStack>
          </SpacingStack>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
