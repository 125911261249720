import { getLocalStorage } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';

export interface PurchaseClickHistory {
  reservedOrderId: Maybe<string>;
  productId: string;
  clickedPurchaseAt: number;
  eventPath: string;
  allowedDismissals: number;
  attemptedToGetResponse: boolean;
}

interface AddItemToHistoryArgs {
  reservedOrderId: Maybe<string>;
  productId: string;
}

function assertTypeNumber(value: unknown) {
  return typeof value === 'number';
}

function assertTypeString(value: unknown) {
  return typeof value === 'string';
}

function assertTypeBoolean(value: unknown) {
  return typeof value === 'boolean';
}

function assertTypeNull(value: unknown) {
  return value === null;
}

function assertPurchaseClickHistoryType(item: Maybe<PurchaseClickHistory>) {
  return (
    (assertTypeString(item?.reservedOrderId) || assertTypeNull(item?.reservedOrderId)) &&
    assertTypeString(item?.productId) &&
    assertTypeNumber(item?.clickedPurchaseAt) &&
    assertTypeString(item?.eventPath) &&
    assertTypeNumber(item?.allowedDismissals) &&
    assertTypeBoolean(item?.attemptedToGetResponse)
  );
}

function getEventPath() {
  let eventPath = '';
  withWindow(window => (eventPath = window.location.pathname.split('/')?.[1]));
  return eventPath;
}

function setClickPurchaseHistory(updatedClickHistory: Array<PurchaseClickHistory>) {
  const storage = getLocalStorage();
  storage.setItem('clickPurchaseHistory', JSON.stringify(updatedClickHistory));
}

function getClickPurchaseHistory() {
  const storage = getLocalStorage();
  const clickPurchaseHistory = storage.getItem('clickPurchaseHistory');
  if (clickPurchaseHistory) {
    const parsedPurchaseHistory: Array<PurchaseClickHistory> = JSON.parse(clickPurchaseHistory);
    if (Array.isArray(parsedPurchaseHistory)) {
      return parsedPurchaseHistory.filter(assertPurchaseClickHistoryType);
    }
  }
  return null;
}

function foundItem({ item, reservedOrderId, productId }: { item: PurchaseClickHistory } & AddItemToHistoryArgs) {
  // there can be multiple items with reservedOrderId = null, so we don't want to identify those as the same item
  return (item.reservedOrderId !== null && item.reservedOrderId === reservedOrderId) || (item.productId === productId && item.reservedOrderId === null);
}

// By checking if the item already exists we will be preventing duplicates from these cases:
// 1. clicking an external link more than once
// 2. clicking continue to retailer more than once
// 3. clicking an external link when an order already exists - not possible in current UI but handling in case UI changes
function itemExists(clickPurchaseHistory: Array<PurchaseClickHistory>, reservedOrderId: AddItemToHistoryArgs['reservedOrderId'], productId: AddItemToHistoryArgs['productId']) {
  return !!clickPurchaseHistory.find(item => foundItem({ item, reservedOrderId, productId }));
}

function itemExistsWithoutOrderId(clickPurchaseHistory: Array<PurchaseClickHistory>, productId: AddItemToHistoryArgs['productId']) {
  return !!clickPurchaseHistory.find(item => !item.reservedOrderId && item.productId === productId);
}

export const addItemToPurchaseClickHistory = ({ reservedOrderId, productId }: AddItemToHistoryArgs) => {
  const clickPurchaseHistory = getClickPurchaseHistory();
  const now = new Date();
  const eventPath = getEventPath();
  const item = { reservedOrderId, productId, clickedPurchaseAt: now.getTime(), eventPath, allowedDismissals: 2, attemptedToGetResponse: false };
  if (clickPurchaseHistory) {
    // if the external link has been clicked, and later they reserve that same item with the form, we want to replace the existing item so a reservedOrderId exists
    if (reservedOrderId && productId && itemExistsWithoutOrderId(clickPurchaseHistory, productId)) {
      removeItemFromPurchaseClickHistory({ reservedOrderId: null, productId });
      setClickPurchaseHistory(clickPurchaseHistory.concat(item));
    } else if (!itemExists(clickPurchaseHistory, reservedOrderId, productId)) {
      setClickPurchaseHistory(clickPurchaseHistory.concat(item));
    }
  } else {
    // first item
    setClickPurchaseHistory([item]);
  }
};

export const removeItemFromPurchaseClickHistory = ({ reservedOrderId, productId }: AddItemToHistoryArgs) => {
  const clickPurchaseHistory = getClickPurchaseHistory();
  if (clickPurchaseHistory) {
    if (reservedOrderId !== null) {
      const filteredPurchaseHistory = clickPurchaseHistory.filter(item => item.reservedOrderId !== reservedOrderId);
      setClickPurchaseHistory(filteredPurchaseHistory);
    } else {
      const filteredPurchaseHistory = clickPurchaseHistory.filter(item => item.productId !== productId);
      setClickPurchaseHistory(filteredPurchaseHistory);
    }
  }
};

export const getSortedClickPurchaseHistory = () => {
  const clickPurchaseHistory = getClickPurchaseHistory();
  if (clickPurchaseHistory) {
    const eventPath = getEventPath();
    return clickPurchaseHistory.filter(item => item.eventPath === eventPath).sort((itemA, itemB) => itemA.clickedPurchaseAt - itemB.clickedPurchaseAt);
  } else {
    return null;
  }
};

export const decrementAllowedDismissals = ({ reservedOrderId, productId }: AddItemToHistoryArgs) => {
  const clickPurchaseHistory = getClickPurchaseHistory();
  if (clickPurchaseHistory) {
    let removeItem = false;
    const updatedClickHistory = clickPurchaseHistory.map(item => {
      if (foundItem({ item, reservedOrderId, productId })) {
        const { allowedDismissals } = item;
        if (allowedDismissals === 1) {
          removeItem = true;
        }
        return { ...item, allowedDismissals: allowedDismissals - 1 };
      } else {
        return item;
      }
    });
    if (removeItem) {
      removeItemFromPurchaseClickHistory({ reservedOrderId, productId });
      return true;
    } else {
      setClickPurchaseHistory(updatedClickHistory);
      return false;
    }
  }
  return false;
};

export const getAllowedDissmisalAttemptsSum = () => {
  return getSortedClickPurchaseHistory()?.reduce((a, b) => {
    return a + b.allowedDismissals;
  }, 0);
};

export const setAttemptedToGetResponseOnThisLoad = ({ reservedOrderId, productId }: AddItemToHistoryArgs) => {
  const clickPurchaseHistory = getClickPurchaseHistory();
  if (clickPurchaseHistory) {
    const updatedClickHistory = clickPurchaseHistory.map(item => {
      if (foundItem({ item, reservedOrderId, productId })) {
        return {
          ...item,
          attemptedToGetResponse: true
        };
      }
      return item;
    });
    setClickPurchaseHistory(updatedClickHistory);
  }
};

export const resetAttemptToGetResponse = () => {
  const clickPurchaseHistory = getClickPurchaseHistory();
  if (clickPurchaseHistory) {
    const updatedClickHistory = clickPurchaseHistory.map(item => {
      return {
        ...item,
        attemptedToGetResponse: false
      };
    });
    setClickPurchaseHistory(updatedClickHistory);
  }
};

export const getClickPurchaseHistoryLength = () => {
  return getClickPurchaseHistory()?.length || 0;
};
