import React from 'react';

import { LineItemFragment, RegistryOrderFragment } from '@graphql/generated';

import { CookedProductList } from '@apps/registry/common/selectors/ProductListSelector';
import { useRoutePaths } from '@apps/registry/guest/GuestRegistry.routes';
import { ModalStates } from '@apps/registry/guest/routes/Purchase/components/PurchaseConfirmationModal/PurchaseConfirmationModal.controller';

import { ReservedItemV2 } from './ReservedItemV2';

interface ReservedOrderProps
  extends Readonly<{
    productList: CookedProductList['products'];
    orderLineItems: Array<LineItemFragment>;
    eventId: string;
    paths: ReturnType<typeof useRoutePaths>;
    order: RegistryOrderFragment;
    openModal: (productId: string, modalStateToBeOpened?: ModalStates) => void;
  }> {}

export const ReservedOrder: React.FC<ReservedOrderProps> = ({ eventId, productList, orderLineItems, paths, order, openModal }) => {
  const registryItemId = orderLineItems?.filter(lineItem => lineItem.registryItem)[0].registryItem?.id;
  const product = productList.find(product => product.id === registryItemId);

  const orderItem = orderLineItems.find(lineItem => lineItem.quantity);

  if (!product) return <></>;

  return <ReservedItemV2 order={order} paths={paths} product={product} itemQuantity={orderItem ? orderItem.quantity : 1} eventId={eventId} openModal={openModal} />;
};
