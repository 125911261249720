import React from 'react';
import { ContentHeader, CtaGroup, IntegratedGiftCard, LayoutShell, PrimaryCta, SecondaryCta } from '../../components';
import { useCancelGiftIntentController } from './CancelGiftIntent.controller';
import { CancelGiftIntentSelectors } from './CancelGiftIntent.selectors';
import { ProductSummaryWithImage } from '@apps/registry/guest/routes/GuestRegistry/components/PurchaseDialog';

export const CancelGiftIntent = () => {
  const { isCashFundType, sendGiftLater, noIntentToSendLater, translations, isAffiliate, product, productImageSrc, currency } = useCancelGiftIntentController();

  const { title, subtitle, affirmativeButton, negativeButton } = translations;

  return (
    <LayoutShell {...CancelGiftIntentSelectors.ContainerElement}>
      <LayoutShell.Header mobilePlacement={isCashFundType ? 'float' : 'static'} />
      <LayoutShell.Body>
        <LayoutShell.MediaPanel>
          {isAffiliate ? (
            <ProductSummaryWithImage product={product} productImageSrc={productImageSrc} currency={currency} />
          ) : (
            <IntegratedGiftCard mobileRenderBehavior="fullBleed" />
          )}
        </LayoutShell.MediaPanel>
        <LayoutShell.ContentPanel>
          <ContentHeader>
            <ContentHeader.Hed>{title}</ContentHeader.Hed>
            <ContentHeader.Dek>{subtitle}</ContentHeader.Dek>
          </ContentHeader>
          <CtaGroup marginTop={7}>
            <PrimaryCta {...CancelGiftIntentSelectors.AffirmativeButton} onClick={sendGiftLater}>
              {affirmativeButton}
            </PrimaryCta>
            <SecondaryCta {...CancelGiftIntentSelectors.NegativeButton} onClick={noIntentToSendLater}>
              {negativeButton}
            </SecondaryCta>
          </CtaGroup>
        </LayoutShell.ContentPanel>
      </LayoutShell.Body>
    </LayoutShell>
  );
};
