import { StyleSystemProps } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

const notificationBarWrapperFlexStyles: StyleSystemProps = {
  border: '1px solid',
  borderColor: 'negative2',
  borderRadius: pxToRem(8),
  backgroundColor: 'warning1',
  padding: pxToRem(16),
  flexDirection: { _: 'column', md: 'row' },
  top: pxToRem(39),
  left: 0,
  right: 0,
  margin: '0 auto',
  position: 'absolute',
  width: { _: '100%', md: pxToRem(850) },
  zIndex: 'banner',
  justifyContent: 'center',
  alignItems: 'center'
};

const notificationBarTextStyles: StyleSystemProps = {
  marginY: { _: pxToRem(16), md: 0 },
  marginX: { _: 0, md: pxToRem(16) },
  color: 'mono14',
  textAlign: 'center'
};

export const styles = {
  notificationBarWrapperFlex: notificationBarWrapperFlexStyles,
  notificationBarText: notificationBarTextStyles
};
