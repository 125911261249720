import { styled, StyleSystemProps, Box, ButtonV2 } from '@withjoy/joykit';
import { ButtonVariant } from '@withjoy/joykit/components/ButtonV2/Button.types';
import { pxToRem } from '@withjoy/joykit/theme';

export const StyledAddGiftNoteWrapperV2 = styled(Box)``;
const addGiftNoteWrapperStyles: StyleSystemProps = {
  marginX: 5,
  marginY: 4,
  textAlign: 'center'
};

export const StyledGiftNoteWrapperV2 = styled(Box)``;
const giftNoteWrapperStyles: StyleSystemProps = {
  margin: `0 ${pxToRem(16)} ${pxToRem(24)} ${pxToRem(16)}`,
  textAlign: 'center'
};

export const StyledAddNoteButtonV2 = styled(ButtonV2)<{ variant?: ButtonVariant }>`
  width: 100%;
  svg {
    path {
      fill: ${props => (props.variant === 'outline' ? 'initial' : props.theme.colors.white)};
    }
  }
`;

export const styles = {
  giftNoteWrapper: giftNoteWrapperStyles,
  addGiftNoteWrapper: addGiftNoteWrapperStyles
};
